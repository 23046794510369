import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {LegalEntity} from "../_models/legal-entity";
import {LegalEntitySelectorComponent} from "../legal-entity-selector/legal-entity-selector.component";

@Component({
  selector: 'legal-entity-selector-dialog',
  templateUrl: './legal-entity-selector-dialog.component.html',
  styleUrls: ['./legal-entity-selector-dialog.component.css']
})
export class LegalEntitySelectorDialogComponent implements OnInit, OnDestroy {
  @Output() selected = new EventEmitter<LegalEntity>();

  @ViewChild('legalEntitiesDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(LegalEntitySelectorComponent, { static: true }) selector: LegalEntitySelectorComponent;

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  showDialog() {
    $(this.dialogEl.nativeElement).modal('show');
    this.selector.init();

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  private hideDialog() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  onSelected(entity: LegalEntity): void {
    this.hideDialog();
    this.selected.emit(entity);
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
