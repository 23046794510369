import { Router, NavigationStart } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';
import {Injectable} from "@angular/core";

@Injectable()
export class AlertService {
  private subject: Subject<any> = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next({});
        }
      }
    });
  }

  success(message: string|string[], keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(this.createMessage('success', message));
  }

  error(message: string|string[], keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(this.createMessage('error', message));
  }

  warning(message: string|string[], keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(this.createMessage('warning', message));
  }

  info(message: string|string[], keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(this.createMessage('info', message));
  }

  clear() {
    this.subject.next({});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  private createMessage(type: string, message: string|string[]): Object {
    let msg = { type: type, text: '', texts: [] };

    if(Array.isArray(message))
      msg['texts'] = message;
    else
      msg['text'] = message;

    return msg;
  }
}
