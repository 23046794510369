export class Insurance {
  id: number;
  section: string;
  provider: string;
  cargo_description: string;
  cargo_cost: number;
  insurance_price: number;
  client_insurance_price: number;
  invoice_id: string;
  provider_invoice_id: string;
  is_insured: boolean;
}
