import {Injectable} from "@angular/core";
import {Title} from "@angular/platform-browser";

const TITLE_SUFFIX = ' - MOVER';

@Injectable()
export class TitleService {
  constructor(private title: Title) {
  }

  changeTitle(title: string): void {
    this.title.setTitle(`${title}${TITLE_SUFFIX}`);
  }
}
