<div class="modal fade" tabindex="-1" role="dialog" #modal>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Выбор тарифа</h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="loader" *ngIf="isLoading"></div>
        <div *ngIf="!isLoading" class="list-group">
          <button *ngFor="let schema of deliverySchemas" class="list-group-item schema" type="button" (click)="onSelectSchema(schema)">
            <div class="name">{{ schema.name }}</div>
            <div class="description" *ngIf="schema.description">{{ schema.description }}</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>