import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mToKm'
})
export class MeasurementsConvertPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (typeof value != 'undefined')
    {
      return value/1000;
    }
    else
    {
      return '-';
    }
  }

}

@Pipe({
  name: 'hourToSec'
})
export class hoursToSecConvertPipe implements PipeTransform {
  transform(value:any) {
    if (value){
    let date = new Date(null)
    date.setSeconds(value);
    return date.toISOString().substr(11, 8);
    }
    else
    {
      return '';
    }
  }
}
