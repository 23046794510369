<div class="modal fade" tabindex="-1" role="dialog" #editUserDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Изменение пользователя</h4>
        </div>
      </div>
      <div class="modal-body">
        <user-form
          (onSaveAccount)="onSaveAccount($event)"
          (onSaveRole)="onSaveRole($event)"
          (onAllSaved)="onSavedAll()"
          [account]="account"
          [phoneEnabled]="phoneEnabled"
          [role]="role"
          [roleEnabled]="roleEnabled"
        ></user-form>
      </div>
    </div>
  </div>
</div>
