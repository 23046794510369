<div *ngIf="areasForView.length > 0">
  <table class="table tbl-order">
    <thead>
    <tr>
      <td>Зона</td>
      <td>Расстояние</td>
      <td>Продолжительность</td>
      <td>Скорость</td>
      <td class="bool-val">Точка внутри</td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let area of areasForView">
      <td>{{ area.area.name }}</td>
      <td>{{ area.distance / 1000 }} км</td>
      <td>{{ area.duration }} мин</td>
      <td>{{ area.speed }} км/ч</td>
      <td class="bool-val">
        <span *ngIf="area.point_in" class="glyphicon glyphicon-ok"></span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
