<div class="modal fade" tabindex="-1" role="dialog" id="new-complex-delivery" #newComplexDelivery>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Создать новый заказ</h4>
          <div class="subtitle" *ngIf="optimizationTask.delivery_schema">{{ optimizationTask.delivery_schema.name }}</div>
        </div>
        <div class="controls city">
          <select [(ngModel)]="cityId" name="city" (ngModelChange)="onSelectCity()">
            <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
          </select>
        </div>
<!--        <div class="controls">-->
<!--          <order-panel-datepicker-->
<!--                  [(value)]="date"-->
<!--                  [datepicker]="datePickerOptions"-->
<!--                  [timepicker]="false"-->
<!--                  (valueChange)="onTaskUpdated()"-->
<!--                  name="date"-->
<!--                  required-->
<!--                  ngDefaultControl-->
<!--          ></order-panel-datepicker>-->
<!--        </div>-->
        <div class="controls optimization-type" *ngIf="isOptimizationTypeEnabled">
          <select [(ngModel)]="optimizationTask.optimization_type" name="optimization_type" (ngModelChange)="onTaskUpdated()">
            <option value="from_storehouse">со склада</option>
            <option value="to_storehouse">на склад</option>
          </select>
        </div>
        <div class="controls">
          <button type="button" class="save-btn import-btn pull-right" (click)="onChangeMode()">
            <span>
              <span *ngIf="mode == 'edit'">Импорт</span>
              <span *ngIf="mode == 'import'">Редактор</span>
              <span class="glyphicon glyphicon-refresh"></span>
            </span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="column storehouse">
            <div class="fields-row select">
              <div class="label">
                <label>Склад</label>
              </div>
              <div class="input">
                <select name="storehouse" [(ngModel)]="storehouseId" (ngModelChange)="onChangeStorehouse()">
                  <option [value]="storehouse.id" *ngFor="let storehouse of storehouses">{{ storehouse.city.name }}, {{ storehouse.address }}</option>
                  <option value="-1">- добавить новый склад -</option>
                </select>
              </div>
            </div>
            <storehouse-creator *ngIf="storehouseId == -1" (changed)="onChangeStorehouse()"></storehouse-creator>
          </div>
        </div>
        <complex-delivery-form
          *ngIf="mode == 'edit'"
          [optimizationTask]="optimizationTask"
          [saveEvent]="saveEvent"
          [registerFormEvent]="registerFormListener"
          [geoContext]="city && city.geo_context"
          [storehouseId]="storehouseId"
          (saved)="onSaved($event)"
          (taskChanged)="onTaskUpdated()"
        ></complex-delivery-form>
        <complex-delivery-import-form
          *ngIf="mode == 'import'"
          [optimizationTask]="optimizationTask"
          [registerFormEvent]="registerFormListener"
          (saved)="onSaved($event)"
          (saveFailed)="onSaveFailed($event)"
        ></complex-delivery-import-form>
        <div class="summary-separator"></div>
        <div class="form-row date-form">
          <div class="column">
            <date-timeslot
                    *ngIf="enabledDesiredArrival && !isExpressMode"
                    label="Отгрузка перевозчику"
                    info="Желаемое время отгрузки перевозчику"
                    storagePrefix="desired_arrival_"
                    [taskToRestore]="optimizationTask"
                    [(dateTimeslot)]="desiredArrival"
                    (dateTimeslotChange)="onChangeDesiredArrival()"
            ></date-timeslot>
            <div class="storehouse-arrival" *ngIf="!isRouteOptimizationAvailable && isExpressMode">
              <div class="label">
                <div class="text"><span>Отгрузка перевозчику</span><tooltip class="info" title="Время, до которого перевозчик заберёт заказ"><span class="glyphicon glyphicon-info-sign"></span></tooltip></div>
              </div>
              <div *ngIf="isCalculationLoading" class="value">
                <img ngSrc="/assets/images/loader-dots-gray.gif" alt="рассчитывается..." height="16" width="16">
              </div>
              <div *ngIf="!arrivalDateTime && !isCalculationLoading" class="value">...</div>
              <div *ngIf="arrivalDateTime && !isCalculationLoading" class="value">{{ arrivalDateTime|dt:"dd.MM.yyyy HH:mm" }}</div>
              <div class="blank"></div>
            </div>
            <date-timeslot
                    *ngIf="!isRouteOptimizationAvailable && isExpressMode"
                    label="Доставка клиенту"
                    info="Дата и время, когда планируется доставка груза клиенту"
                    storagePrefix="delivery_date_time_"
                    [taskToRestore]="optimizationTask"
                    [(dateTimeslot)]="deliveryDateTime"
                    [fixedTimeSlots]="expressDeliveryTimeSlots"
                    (dateTimeslotChange)="onChangeDeliveryDateTime()"
            ></date-timeslot>
            <div class="delivery-date" *ngIf="!isExpressMode">
              <div class="label">
                <div class="text"><span>Доставка клиенту</span><tooltip class="info" title="Дата, когда планируется доставка груза клиенту"><span class="glyphicon glyphicon-info-sign"></span></tooltip></div>
              </div>
              <div class="value">
                <order-panel-datepicker
                        *ngIf="isRouteOptimizationAvailable"
                        [(value)]="date"
                        [datepicker]="datePickerOptions"
                        [timepicker]="false"
                        (valueChange)="onTaskUpdated()"
                        name="date"
                        required
                        ngDefaultControl
                ></order-panel-datepicker>
                <span *ngIf="!isRouteOptimizationAvailable">{{ date|dt:"dd.MM.yyyy" }}</span>
              </div>
              <div class="blank"></div>
              <div class="blank"></div>
            </div>
            <div *ngIf="isDeliveryLate" class="alert alert-warning time-alert">Внимание! Доставки не будут выполнены в срок. Попробуйте выбрать другую дату или слот или уменьшите количество доставок.</div>
          </div>
        </div>
        <div class="summary">
          <div class="controls" *ngIf="!isRouteOptimizationAvailable && optimizationTask.delivery_schema?.is_multipoint_allowed && (optimizationTask.destinations.length > 1 || mode == 'import')">
            <label><input type="checkbox" name="route_optimization_enabled" [(ngModel)]="isRouteOptimizationEnabled" (ngModelChange)="onTaskUpdated()"> Разрешаю менять порядок доставки</label>
          </div>
          <div class="delivery-cost" *ngIf="!isRouteOptimizationAvailable && !isCalculationLoading && !requestCalculationResult">
            ...
          </div>
          <div class="delivery-cost" *ngIf="isCalculationLoading">
            Стоимость доставки:
            <img ngSrc="/assets/images/loader-dots.gif" alt="рассчитывается..." height="16" width="16">
          </div>
          <div class="delivery-cost" *ngIf="!isCalculationLoading && requestCalculationResult && !requestCalculationResult.hasErrors && !requestCalculationResult.unavailableCalculation">
            Стоимость доставки: <span class="value">{{ requestCalculationResult.calculation?.total_cost|formatCost }}</span>
          </div>
          <div class="delivery-cost" *ngIf="!isCalculationLoading && requestCalculationResult && requestCalculationResult.hasErrors">
            {{ requestCalculationResult.errorMessage }}
          </div>
          <div *ngIf="isRouteOptimizationAvailable"></div><!-- этот div нужен, чтобы кнопка "Подтвердить" всегда была справа -->
          <div class="controls">
            <button type="button" class="save-btn" (click)="onAccept()" [disabled]="requestCalculationResult?.hasErrors || isCalculationLoading">Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

