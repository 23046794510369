<form (submit)="onSave()">
  <div class="flex-form">
    <select class="full-width mar8top" [(ngModel)]="payMethod" name="payMethod" (ngModelChange)="onChangePayMethod()">
      <option *ngFor="let method of payMethods | mapToIterable" value="{{ method.key }}">{{ method.val }}</option>
    </select>
  </div>
  <div class="flex-form" *ngIf="optionsEnabled">
    <div class="option-title">Опция</div>
    <select class="full-width mar8top" name="payMethodOption" [(ngModel)]="payMethodOption">
      <option *ngFor="let option of availableOptions" [value]="option">{{ option|payMethodOption }}</option>
    </select>
  </div>
  <div class="flex-form" *ngIf="isCostEditable">
    <div class="option-title">Стоимость</div>
    <input class="mar8top" name="cost" [(ngModel)]="cost">
  </div>
  <div class="flex-form" *ngIf="isPaymentDistributionEditable">
    <div class="option-title">Распределение средств</div>
    <select class="mar8top" name="paymentDistributionSchema" [(ngModel)]="paymentDistributionSchemaId">
      <option value="0">по умолчанию</option>
      <option *ngFor="let schema of paymentDistributionSchemas" [value]="schema.id">{{ schema.name }}</option>
    </select>
  </div>
  <button type="submit" class="save-btn">Сохранить</button>
</form>
