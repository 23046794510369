import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";

@Component({
  selector: 'draft-comment-editor',
  templateUrl: './draft-comment-editor.component.html',
  styleUrls: ['./draft-comment-editor.component.css']
})
export class DraftCommentEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  comment: string;

  constructor() { }

  ngOnInit() {
    this.comment = this.draft.comment;
  }

  onSave() {
    this.draft.comment = this.comment;

    this.onUpdated.emit(this.draft);
  }
}
