import {Injectable} from "@angular/core";
import {Account} from "../_models/account";

@Injectable()
export class IntercomService {

  appId: string ='p8jhuell';

  boot(account: Account)
  {
    console.log('intercom boot')
    let userName = [account.surname, account.name, account.patronymic].filter(v => v != null && v != '').join(' ');
    let config = {
      app_id:this.appId,
      user_id: account.phone,
      name: [account.name, account.surname].filter(v => v != null && v != '').join(' '),
      phone:account.phone,
      email: account.email ? account.email : '',
      'appType':'orders',
      'user_name':userName
    };

    if(account.company_client) {
      config['company'] = {
        id: account.company_client.id,
        name: account.company_client.name
      }
    }

    (<any>window).Intercom('boot', config);
  }

  shutdown()
  {
    (<any>window).Intercom('shutdown');
  }
}
