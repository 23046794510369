import {Clusterer} from "./clusterer";
import {MarkersCollection} from "./markers-collection";
import {Marker} from "./marker";
import {GeoUtils} from "../../_utils/geo-utils";

/**
 * Длина диагонали видимой области в км, используемая для расчёта размера кластера
 */
const BOUNDS_DIAGONAL_LENGTH = 90;
/**
 * Радиус кластера в км при заданной длине диагонали видимой области
 */
const CLUSTER_RADIUS = 25;
/**
 * Минимальный радиус в км, с которого начинается кластеризация
 */
const CLUSTER_MIN_RADIUS_TRIGGER = 5;
/**
 * Минимальный размер кластера
 */
const CLUSTER_MIN_SIZE = 10;
/**
 * Максимальный размер кластера
 */
const CLUSTER_MAX_SIZE = 20;
/**
 * Базовое количество точек для вычисления количества видимых кластеров
 */
const BASE_POINTS_COUNT = 1000;
/**
 * Базовое количество видимых кластеров для
 */
const BASE_VISIBLE_CLUSTERS = 100;

class MarkerDistance {
    constructor(public marker: Marker, public id: number, public distance: number) {}
}

export class BasicClusterer implements Clusterer {
  doClusterization(markers: MarkersCollection, viewBounds: google.maps.LatLngBounds): MarkersCollection {
    const ne = viewBounds.getNorthEast();
    const sw = viewBounds.getSouthWest();
    const center = viewBounds.getCenter();

    // Округляем, т.к. при перемещении карты возвращаются границы с немного различающимся расстояниями (~200м).
    // Возможно, причина в том, что земля - кривая.
    // TODO: Можно попробовать выполнять предварительный расчёт основных параметров кластеризации и пересчитывать их только при изменении зума.
    let distance = Math.floor(GeoUtils.calcDistance(ne.lat(), ne.lng(), sw.lat(), sw.lng()));
    console.log(`Distance: ${distance}`);

    const clusterRadius = distance * CLUSTER_RADIUS / BOUNDS_DIAGONAL_LENGTH;
    console.log(`Cluster radius: ${clusterRadius}`);

    if(clusterRadius < CLUSTER_MIN_RADIUS_TRIGGER) {
      console.log('Too small cluster radius');
      return markers;
    }

    let baseClusterSize = markers.markers.size * BASE_VISIBLE_CLUSTERS / BASE_POINTS_COUNT;
    console.log(`Count of visible clusters: ${baseClusterSize}`);
    let clusterMaxSize = markers.markers.size / Math.min(CLUSTER_MAX_SIZE, baseClusterSize);
    console.log(`Cluster max size: ${clusterMaxSize}`);

    let markersCenter = markers.calcCenter();
    let markerIds = this.sortMarkersByDistanceFromCenter(markers, markersCenter.lat, markersCenter.lon).map(distance => distance.id)

    let clusters: MarkersCollection[] = [];
    while(markerIds.length > 0) {
      let currentCluster = new MarkersCollection();
      clusters.push(currentCluster);

      let baseMarkerId = markerIds.pop();
      let baseMarker = markers.getMarker(baseMarkerId);
      markers.remove(baseMarkerId);
      currentCluster.add(baseMarkerId, baseMarker);
      markerIds = this.sortMarkersByDistanceFromCenter(markers, baseMarker.lat, baseMarker.lon).map(distance => distance.id)

      let externalMarkerIds = [];
      while(markerIds.length > 0) {
        let currentMarkerId = markerIds.pop();
        let currentMarker = markers.getMarker(currentMarkerId);

        const distanceToBaseMarker = GeoUtils.calcDistance(baseMarker.lat, baseMarker.lon, currentMarker.lat, currentMarker.lon);
        if(distanceToBaseMarker <= clusterRadius && currentCluster.markers.size < clusterMaxSize) {
          markers.remove(currentMarkerId);
          currentCluster.add(currentMarkerId, currentMarker)
        } else {
          externalMarkerIds.push(currentMarkerId);
        }
      }
      markerIds = externalMarkerIds;

      console.log(`Cluster ${clusters.length} size: ${currentCluster.markers.size}`);
    }

    let clusterNum = 10000;
    for(const cluster of clusters) {
      if(cluster.markers.size <= CLUSTER_MIN_SIZE) {
        cluster.markers.forEach((marker, id) => markers.add(id, marker));
      } else {
        let clusterMarker = new Marker();
        clusterMarker.applyCluster(cluster);
        markers.add(clusterNum ++, clusterMarker);
      }
    }

    return markers;
  }

  private sortMarkersByDistanceFromCenter(markers: MarkersCollection, centerLat: number, centerLon: number): MarkerDistance[] {
    let distances: MarkerDistance[] = [];

    markers.forEach((marker, id) => {
      distances.push(new MarkerDistance(marker, id, GeoUtils.calcDistance(centerLat, centerLon, marker.lat, marker.lon)));
    });

    distances.sort((a, b) => {
      return b.distance - a.distance;
    });

    return distances;
  }
}
