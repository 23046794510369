import {Pipe} from "@angular/core";
import {City} from "../_models/city";
import {DateUtils} from "../_utils/date-utils";
import {TzPipe} from "./tz-pipe";

@Pipe({name: 'cityTz'})
export class CityTzPipe extends TzPipe {
  transform(city: City|null, hideUserTz = true): string {
    if(!city)
      return '';

    if(hideUserTz && city.time_zone == this.userCityTz)
      return '';

    let timeZoneDiff = DateUtils.offsetToCityTimeZone(DateUtils.cityTimeZoneToOffset(city.time_zone) - this.userTzOffset);

    let sign = timeZoneDiff >= 0 ? '+' : '-';
    let hours = Math.floor(timeZoneDiff / 100);
    let minutes = timeZoneDiff % 100;
    // (<any>(v.toString())) - это хак, т.к. angular 2 не поддерживает метод padStart
    return sign + [ hours, minutes ].map(v => (<any>(v.toString())).padStart(2, '0')).join(':');
  }
}
