import {Account} from "../account";
import {DestinationContact} from "./destination-contact";
import {Order} from "../order";
import {OrderDraft} from "../order-draft";

export class UserLastData {
  account: Account;
  destination_contact: DestinationContact;
  is_driver: boolean;
  last_orders: Order[];
  last_drafts: OrderDraft[];
}
