import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements AfterViewInit {
  @Input() title: string;
  @Input() isDiv = false;
  @ViewChild('withTooltip') tooltipEl: ElementRef;

  ngAfterViewInit(): void {
    $(this.tooltipEl.nativeElement).tooltip({
      delay: 500
    });
  }
}
