import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {OrderReport} from "../_models/order-report";
import {Destination} from "../_models/destination";

const SUPPORTED_LOADERS_CALCULATIONS = [ 'by_points' ];

@Component({
  selector: 'calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.css']
})
export class CalculationComponent implements OnInit, OnChanges {
  @Input() calculation: any;
  @Input() report: OrderReport;
  @Input() perMinuteBilling: boolean = false
  @Input() isFreightersOrder = false;
  @Input() destinations: Destination[] = [];

  calculationExtensionType: string;
  calculationExtensionVersion: number;
  calculationExtension: any;

  transportTariffExtensionType: string;
  transportTariffExtensionVersion: number;
  transportTariffExtension: any;

  /**
   * Период тарификации транспорта в минутах
   */
  transportTarifficationPeriod = 1;
  /**
   * Стоимость одного периода транспорта
   */
  transportTarifficationPeriodPrice = 0;
  /**
   * Период тарификации грузчиков в минутах
   */
  loadersTarifficationPeriod = 1;
  /**
   * Стоимость одного периода тарификации грузчиков
   */
  loadersTarifficationPeriodPrice = 0;
  /**
   * Период тарификации грузчиков в пути в минутах
   */
  loadersOnTheWayTarifficationPeriod = 1;
  /**
   * Стоимость одного периода тарификации грузчиков в пути
   */
  loadersOnTheWayTarifficationPeriodPrice = 0;

  loadersCalculation: any;
  loadersCalculationExtension: any;
  loadersCalculationType: string;
  isLoadersCalculationExtensionSupported = false;
  loadersTariffExtension: any;

  constructor() {}

  ngOnInit() {
    this.initCalculation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initTransportTariffExtension();
    this.initCalculation();
    this.initLoadersCalculation();
  }

  private initTransportTariffExtension(): void {
    let tariffExtension = this.calculation.transport_tariff && this.calculation.transport_tariff.extension;
    this.transportTariffExtensionType = null;
    this.transportTariffExtensionVersion = null;
    this.transportTariffExtension = null;

    if(!tariffExtension)
      return;

    this.transportTariffExtensionType = tariffExtension['type'] || null;
    this.transportTariffExtensionVersion = tariffExtension['version'] || null;

    if(tariffExtension['tariff'])
      this.transportTariffExtension = JSON.parse(tariffExtension['tariff']);
  }

  private initCalculation(): void {
    this.initTarifficationPeriods();
    this.initCalculationExtension();
  }

  private initLoadersCalculation(): void {
    this.isLoadersCalculationExtensionSupported = false;
    this.loadersTariffExtension = null;

    if(!this.calculation.loaders_tariff || !this.calculation.loaders_tariff.extension)
      return;

    this.loadersCalculation = this.calculation.loaders_calculation;
    if(!this.loadersCalculation)
      return;

    this.loadersCalculationExtension = this.loadersCalculation.extension;
    this.loadersCalculationType = this.loadersCalculationExtension && this.loadersCalculationExtension.type;

    this.isLoadersCalculationExtensionSupported = this.loadersCalculationType
      && SUPPORTED_LOADERS_CALCULATIONS.indexOf(this.loadersCalculationType) != -1;

    if(!this.isLoadersCalculationExtensionSupported)
      return;

    this.loadersTariffExtension = JSON.parse(this.calculation.loaders_tariff.extension.tariff);
  }

  private initCalculationExtension(): void {
    if(this.calculation['extension'] && this.calculation['extension']['calculation']) {
      this.calculationExtensionType = this.calculation['extension']['type'];
      this.calculationExtensionVersion = this.calculation['extension']['version'];
      this.calculationExtension = JSON.parse(this.calculation['extension']['calculation']);
    } else {
      this.calculationExtensionType = undefined;
      this.calculationExtensionVersion = undefined;
      this.calculationExtension = undefined;
    }
  }

  private initTarifficationPeriods(): void {
    this.initTransportTarifficationPeriod();
    this.initLoadersTarifficationPeriod();
    this.initLoadersOnTheWayTarifficationPeriod();
  }

  private initTransportTarifficationPeriod(): void {
    [ this.transportTarifficationPeriod, this.transportTarifficationPeriodPrice ] = this.calculation.transport_tariff
      ? this.extractTarifficationPeriodFromTariff(this.calculation.transport_tariff)
      : [ 1, 0 ];
  }


  private initLoadersTarifficationPeriod(): void {
    [ this.loadersTarifficationPeriod, this.loadersTarifficationPeriodPrice ] = this.calculation.loaders_tariff
      ? this.extractTarifficationPeriodFromTariff(this.calculation.loaders_tariff)
      : [ 1, 0 ];
  }

  private initLoadersOnTheWayTarifficationPeriod(): void {
    [ this.loadersOnTheWayTarifficationPeriod, this.loadersOnTheWayTarifficationPeriodPrice ] = this.calculation.loaders_tariff
      ? this.extractTarifficationPeriodFromTariff(this.calculation.loaders_tariff, 'wait_period_tariffication', 'wait_period_price')
      : [ 1, 0 ];
  }

  private extractTarifficationPeriodFromTariff(tariff: any, periodProp = 'additional_period_tariffication', periodPriceProp = 'additional_period_price'): [number, number] {
    if(tariff.extension && tariff.extension.tariff) {
      const tariffExtension = JSON.parse(tariff.extension.tariff);
      return [ tariffExtension[periodProp], tariffExtension[periodPriceProp] ];
    } else {
      return [1, 0];
    }
  }
}
