/**
 * Калькуляция доставки по заказу
 */
import {DestinationCalculation} from "./destination-calculation";
import {Order} from "../order";
import {DeliveryHistoryRecord} from "../delivery-history-record";

export class OrderCalculation {
  /**
   * Заказ
   */
  order: Order;
  /**
   * Калькуляции по каждой точке
   */
  destinationCalculations: DestinationCalculation[];
  /**
   * Итог по заказу
   */
  total: DeliveryHistoryRecord;
}
