
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CompanyClient} from "../_models/company-client";
import {CompanyClientProduct} from "../_models/company-client-product";
import {CompanyClientProductService} from "../_services/company-client-product.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";

@Component({
  selector: 'add-product-issue-form',
  templateUrl: './add-product-issue-form.component.html',
  styleUrls: ['./add-product-issue-form.component.css']
})
export class AddProductIssueFormComponent implements OnInit, OnChanges {
  @Input() companyClient: CompanyClient;
  @Output() onApplyProduct = new EventEmitter<CompanyClientProduct>();

  products: CompanyClientProduct[] = [];
  productId: number;
  productCode: string;
  productName: string;
  priceForClient: string = '0';
  priceForDriver: string = '0';
  productsCount: number = 1;

  constructor(private companyClientProductProduct: CompanyClientProductService, private loaderService: LoaderService, private alertService: AlertService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initProducts();
  }

  getMetadata(): any {
    return {
      code: this.productCode,
      name: this.productName,
      delivery_cost_for_client: this.filterSum(this.priceForClient),
      delivery_cost_for_freighter: this.filterSum(this.priceForDriver),
      count: this.productsCount
    };
  }

  isValid(): boolean {
    let errors: string[] = [];

    if(!this.productCode || this.productCode.trim() == '')
      errors.push("не указан артикул");
    if(!this.productName || this.productName.trim() == '')
      errors.push("не указано наименование");
    if(this.productsCount < 1)
      errors.push("количество услуг должно быть больше одной");

    if(errors.length > 0)
      this.alertService.warning("Обнаружены ошибки: " + errors.join(', '));

    return errors.length == 0;
  }

  private initProducts(): void {
    this.products = [];
    if(!this.companyClient)
      return;

    this.loaderService.show();
    this.companyClientProductProduct
      .getProducts(this.companyClient).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        products => this.products = products,
        () => {}
      )
    ;
  }

  private applyProduct(product: CompanyClientProduct): void {
    this.productCode = product.code;
    this.productName = product.name;
    this.priceForClient = product.price + "";
    this.priceForDriver = "0";
    this.productsCount = 1;

    this.onApplyProduct.emit(product);
  }

  private applyProductId(productId: number): void {
    let product = this.products.find(s => s.id == productId);
    if(product)
      this.applyProduct(product);
  }

  private filterSum(sum: string): number {
    sum = sum.trim().replace(' ', '').replace(',', '.');
    return parseFloat(sum) || 0;
  }

  onChangeProductId(): void {
    this.applyProductId(this.productId);
  }
}
