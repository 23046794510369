<div class="employee-form-container">
  <!-- Nav tabs -->
  <form (submit)="onSave()">
    <h1 *ngIf="employee.isNew">Добавление водителя</h1>
    <h1 *ngIf="!employee.isNew">{{ employee.record.account|fullName }}</h1>

    <div class="panel panel-default">
      <div class="panel-body">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active"><a href="#contacts" aria-controls="files" role="tab" data-toggle="tab">Контакты</a>
          </li>
          <li role="presentation"><a href="#private-data" aria-controls="data" role="tab" data-toggle="tab">Личные
            данные</a></li>
          <li role="presentation"><a href="#passport" aria-controls="files" role="tab" data-toggle="tab">Удостоверение
            личности</a></li>
          <li role="presentation"><a href="#driver-license" aria-controls="files" role="tab"
                                     data-toggle="tab">Водительское
            удостоверение</a></li>
          <li role="presentation"><a href="#taxpayer-certificate" aria-controls="files" role="tab"
                                     data-toggle="tab">Справка налогоплательщика</a></li>
          <li role="presentation"><a href="#device" aria-controls="files" role="tab" data-toggle="tab">Мобильное
            устройство</a></li>
          <li role="presentation"><a
            href="#legal-entity" aria-controls="files" role="tab"
            data-toggle="tab">
            <span class="glyphicon glyphicon-ok is-legal-entity" *ngIf="employee.record.legal_entity.id"></span>
            Юр. лицо
          </a>
          </li>
          <li role="presentation"><a href="#individual" aria-controls="files" role="tab"
                                     data-toggle="tab">
            <span class="glyphicon glyphicon-ok is-legal-entity" *ngIf="!employee.record.legal_entity.id"></span>
            Физ. лицо
          </a>
          </li>
          <li role="presentation"><a href="#transport" aria-controls="files" role="tab" data-toggle="tab">Транспорт</a></li>
          <li role="presentation"><a href="#payment-distribution" aria-controls="files" role="tab" data-toggle="tab">Распределение платежей</a></li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane active" id="contacts">
            <h2>E-mail</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="email" name="email" class="form-control" placeholder="E-mail"
                         [(ngModel)]="employee.record.email">
                  <input-error [formErrors]="employee.errors" field="email"></input-error>
                </div>
              </div>
            </div>
            <h2>Основной телефон</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="phone" class="form-control" placeholder="Телефон в формате 7ХХХХХХХХХХ"
                         pattern="[0-9]+"
                         [(ngModel)]="employee.record.main_phone.phone" required>
                  <input-error [formErrors]="employee.errors" field="phone"></input-error>
                </div>
                <div class="checkbox">
                  <label><input type="checkbox" name="whatsapp" [(ngModel)]="employee.record.main_phone.whatsapp"> Есть
                    WhatsApp</label>
                </div>
                <div class="checkbox">
                  <label><input type="checkbox" name="telegram" [(ngModel)]="employee.record.main_phone.telegram"> Есть
                    Telegram</label>
                </div>
                <div class="checkbox">
                  <label><input type="checkbox" name="viber" [(ngModel)]="employee.record.main_phone.viber"> Есть Viber</label>
                </div>
              </div>
            </div>
            <h2>Дополнительный телефон</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="addPhone" class="form-control" placeholder="Телефон в формате 7ХХХХХХХХХХ"
                         pattern="[0-9]+"
                         [(ngModel)]="employee.record.additional_phone.phone">
                </div>
                <div class="checkbox">
                  <label><input type="checkbox" name="addWhatsapp"
                                [(ngModel)]="employee.record.additional_phone.whatsapp"> Есть WhatsApp</label>
                </div>
                <div class="checkbox">
                  <label><input type="checkbox" name="addTelegram"
                                [(ngModel)]="employee.record.additional_phone.telegram"> Есть Telegram</label>
                </div>
                <div class="checkbox">
                  <label><input type="checkbox" name="addViber" [(ngModel)]="employee.record.additional_phone.viber">
                    Есть Viber</label>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="private-data">
            <div class="row">
              <div class="col-lg-3">
                <chained-photo-field
                  #employeePhoto
                  [title]="'Фото водителя'"
                  [identifier]="'employee_photo'"
                  [uploadChain]="employeePhotoUploadChain"
                  [photo]="employee.record.account.picture_sizes?.small"
                  (removeFile)="onDeleteEmployeePhoto()"
                ></chained-photo-field>
              </div>
            </div>
            <h2>ФИО</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="surname" class="form-control" placeholder="Фамилия"
                         [(ngModel)]="employee.record.account.surname">
                  <input-error [formErrors]="employee.errors" field="account.surname"></input-error>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="name" class="form-control" placeholder="Имя"
                         [(ngModel)]="employee.record.account.name">
                  <input-error [formErrors]="employee.errors" field="account.name"></input-error>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="patronymic" class="form-control" placeholder="Отчество"
                         [(ngModel)]="employee.record.account.patronymic">
                  <input-error [formErrors]="employee.errors" field="account.patronymic"></input-error>
                </div>
              </div>
            </div>
            <h2>Рождение</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="date" name="birthday" class="form-control"
                         [(ngModel)]="employee.record.account.birthday">
                  <input-error [formErrors]="employee.errors" field="account.birthday"></input-error>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <select name="gender" class="form-control" [(ngModel)]="employee.record.account.gender">
                    <option value="">Пол</option>
                    <option value="male">Муж</option>
                    <option value="female">Жен</option>
                  </select>
                  <input-error [formErrors]="employee.errors" field="account.gender"></input-error>
                </div>
              </div>
            </div>
            <h2>Место проживания</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="city" class="form-control" placeholder="Город"
                         [(ngModel)]="employee.record.account.city">
                  <input-error [formErrors]="employee.errors" field="account.city"></input-error>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="district" class="form-control" placeholder="Округ"
                         [(ngModel)]="employee.record.account.district">
                  <input-error [formErrors]="employee.errors" field="account.district"></input-error>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="vicinity" class="form-control" placeholder="Район"
                         [(ngModel)]="employee.record.account.vicinity">
                  <input-error [formErrors]="employee.errors" field="account.vicinity"></input-error>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <input type="text" name="subwayStation" class="form-control" placeholder="Метро"
                         [(ngModel)]="employee.record.account.subway_station">
                  <input-error [formErrors]="employee.errors" field="account.subwayStation"></input-error>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="passport">
            <div class="row">
              <div class="col-lg-3 passport-type">
                <div class="radio" [class.active]="employee.record.passport.type == 'rf'">
                  <label>
                    <input type="radio" name="passportType" value="rf" [(ngModel)]="employee.record.passport.type">
                    Паспорт РФ
                  </label>
                </div>
                <div class="radio" [class.active]="employee.record.passport.type == 'uamut'">
                  <label>
                    <input type="radio" name="passportType" value="uamut" [(ngModel)]="employee.record.passport.type">
                    Паспорт ИГ (Украина, Азербайджан, Молдова, Узбекистан, Таджикистан)
                  </label>
                </div>
                <div class="radio" [class.active]="employee.record.passport.type == 'other'">
                  <label>
                    <input type="radio" name="passportType" value="other" [(ngModel)]="employee.record.passport.type">
                    Паспорт ИГ (другие страны)
                  </label>
                </div>
                <div class="radio" [class.active]="employee.record.passport.type == 'tempreg'">
                  <label>
                    <input type="radio" name="passportType" value="tempreg" [(ngModel)]="employee.record.passport.type">
                    Временная регистрация
                  </label>
                </div>
              </div>
              <div class="col-lg-8 passport-data">
                <div class="row" *ngIf="isPassportTypeOf(['rf', 'uamut', 'other'])">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <input type="text" name="series" class="form-control" placeholder="Серия"
                             [(ngModel)]="employee.record.passport.series">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <input type="text" name="number" class="form-control" placeholder="Номер"
                             [(ngModel)]="employee.record.passport.number">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <input type="date" name="issuedDate" class="form-control" title="Дата выдачи"
                             [(ngModel)]="employee.record.passport.issued_date">
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="isPassportTypeOf(['rf', 'uamut', 'other'])">
                  <div class="col-lg-9">
                    <div class="form-group">
                      <input type="text" name="issuedBy" class="form-control" placeholder="Кем выдан"
                             [(ngModel)]="employee.record.passport.issued_by">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3" *ngIf="isPassportTypeOf(['rf', 'uamut', 'other'])">
                    <chained-photo-field
                      #passportFirstPage
                      [title]="'Первая страница'"
                      [identifier]="'first_page'"
                      [uploadChain]="passportUploadChain"
                      [photo]="employee.record.passport.first_page"
                      (removeFile)="onDeletePassportPhoto($event)"
                    ></chained-photo-field>
                  </div>
                  <div class="col-lg-3" *ngIf="isPassportTypeOf(['uamut', 'other'])">
                    <chained-photo-field
                      #passportSecondPage
                      [title]="'Вторая страница'"
                      [identifier]="'second_page'"
                      [uploadChain]="passportUploadChain"
                      [photo]="employee.record.passport.second_page"
                      (removeFile)="onDeletePassportPhoto($event)"
                    ></chained-photo-field>
                  </div>
                  <div class="col-lg-3" *ngIf="isPassportTypeOf(['rf'])">
                    <chained-photo-field
                      #passportRegistrationPage
                      [title]="'Прописка'"
                      [identifier]="'registration_page'"
                      [uploadChain]="passportUploadChain"
                      [photo]="employee.record.passport.registration_page"
                      (removeFile)="onDeletePassportPhoto($event)"
                    ></chained-photo-field>
                  </div>
                </div>
                <div class="row" *ngIf="isPassportTypeOf(['tempreg'])">
                  <div class="col-lg-3">
                    <chained-photo-field
                      #passportTempRegistrationPhoto
                      [title]="'Временная регистрация'"
                      [identifier]="'temp_registration_photo'"
                      [uploadChain]="passportUploadChain"
                      [photo]="employee.record.passport.temp_registration_photo"
                      (removeFile)="onDeletePassportPhoto($event)"
                    ></chained-photo-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <chained-photo-field
                      *ngIf="isPassportTypeOf(['rf', 'uamut', 'other', 'tempreg'])"
                      #selfieFirstPage
                      [title]="'Селфи с первой страницей'"
                      [identifier]="'selfie_with_first_page'"
                      [uploadChain]="passportUploadChain"
                      [photo]="employee.record.passport.selfie_with_first_page"
                      (removeFile)="onDeletePassportPhoto($event)"
                    ></chained-photo-field>
                  </div>
                  <div class="col-lg-3">
                    <chained-photo-field
                      *ngIf="isPassportTypeOf(['rf', 'other'])"
                      #selfieSecondPage
                      [title]="'Селфи со второй страницей'"
                      [identifier]="'selfie_with_second_page'"
                      [uploadChain]="passportUploadChain"
                      [photo]="employee.record.passport.selfie_with_second_page"
                      (removeFile)="onDeletePassportPhoto($event)"
                    ></chained-photo-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-9" *ngIf="isPassportTypeOf(['uamut', 'other'])">
                    <div class="form-group">
                      <label for="residency" class="control-label">Вид на жительство</label>
                      <input id="residency" type="text" name="residency" class="form-control"
                             [(ngModel)]="employee.record.passport.residency">
                    </div>
                  </div>
                  <div class="col-lg-9" *ngIf="isPassportTypeOf(['uamut', 'other'])">
                    <div class="form-group">
                      <label for="residencePermit" class="control-label">Разрешение на проживание</label>
                      <input id="residencePermit" type="text" name="residencePermit" class="form-control"
                             [(ngModel)]="employee.record.passport.residence_permit">
                    </div>
                  </div>
                  <div class="col-lg-9" *ngIf="isPassportTypeOf(['uamut', 'other'])">
                    <div class="form-group">
                      <label for="specialistVisa" class="control-label">Виза высококвалифицированного
                        специалиста</label>
                      <input id="specialistVisa" type="text" name="specialistVisa" class="form-control"
                             [(ngModel)]="employee.record.passport.specialist_visa">
                    </div>
                  </div>
                  <div class="col-lg-9" *ngIf="isPassportTypeOf(['uamut', 'other'])">
                    <div class="form-group">
                      <label for="workPermit" class="control-label">Разрешение на работу</label>
                      <input id="workPermit" type="text" name="workPermit" class="form-control"
                             [(ngModel)]="employee.record.passport.work_permit">
                    </div>
                  </div>
                  <div class="col-lg-9" *ngIf="isPassportTypeOf(['other'])">
                    <div class="form-group">
                      <label for="patent" class="control-label">Патент</label>
                      <input id="patent" type="text" name="patent" class="form-control"
                             [(ngModel)]="employee.record.passport.patent">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="driver-license">
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <select name="driverLicenseType" class="form-control"
                          [(ngModel)]="employee.record.driver_license.type">
                    <option value="">Тип</option>
                    <option value="rf">РФ</option>
                    <option value="foreign">Иностранное</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <chained-photo-field
                  #driverLicenseFront
                  [title]="'Лицевая сторона'"
                  [identifier]="'front'"
                  [uploadChain]="driverLicenseUploadChain"
                  [photo]="employee.record.driver_license.front"
                  (removeFile)="onDeleteDriverLicensePhoto($event)"
                ></chained-photo-field>
              </div>
              <div class="col-lg-3">
                <chained-photo-field
                  #driverLicenseBack
                  [title]="'Обратная сторона'"
                  [identifier]="'back'"
                  [uploadChain]="driverLicenseUploadChain"
                  [photo]="employee.record.driver_license.back"
                  (removeFile)="onDeleteDriverLicensePhoto($event)"
                ></chained-photo-field>
              </div>
            </div>
            <h2>Категория</h2>
            <div class="row">
              <div class="col-lg-1">
                <div class="checkbox">
                  <label><input type="checkbox" name="driverLicenseA" [(ngModel)]="driverLicenseCategories.a"> A</label>
                </div>
              </div>
              <div class="col-lg-1">
                <div class="checkbox">
                  <label><input type="checkbox" name="driverLicenseB" [(ngModel)]="driverLicenseCategories.b"> B</label>
                </div>
              </div>
              <div class="col-lg-1">
                <div class="checkbox">
                  <label><input type="checkbox" name="driverLicenseC" [(ngModel)]="driverLicenseCategories.c"> C</label>
                </div>
              </div>
              <div class="col-lg-1">
                <div class="checkbox">
                  <label><input type="checkbox" name="driverLicenseD" [(ngModel)]="driverLicenseCategories.d"> D</label>
                </div>
              </div>
              <div class="col-lg-1">
                <div class="checkbox">
                  <label><input type="checkbox" name="driverLicenseE" [(ngModel)]="driverLicenseCategories.e"> E</label>
                </div>
              </div>
            </div>
            <h2>Стаж вождения</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <input id="drivingExperience" type="text" name="drivingExperience" class="form-control"
                         [(ngModel)]="employee.record.driving_experience">
                  <input-error [formErrors]="employee.errors" field="account.employer.drivingExperience"></input-error>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="taxpayer-certificate">
            <h2>Справка о постановке на учёт физического лица в качестве налогоплательщика налога на профессиональный доход</h2>
            <div class="row">
              <div class="col-lg-3">
                <chained-photo-field
                  #taxpayerCertificateFront
                  [title]="'Лицевая сторона'"
                  [identifier]="'front'"
                  [uploadChain]="taxpayerCertificateUploadChain"
                  [photo]="employee.record.taxpayer_certificate.front"
                  (removeFile)="onDeleteTaxpayerCertificateFile($event)"
                ></chained-photo-field>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="device">
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <select name="deviceType" class="form-control" [(ngModel)]="employee.record.device.type">
                    <option value="">Тип</option>
                    <option value="tablet">Планшет</option>
                    <option value="phone">Телефон</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <input id="deviceBrand" type="text" name="deviceBrand" class="form-control" placeholder="Марка"
                         [(ngModel)]="employee.record.device.brand">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <input id="deviceModel" type="text" name="deviceModel" class="form-control" placeholder="Модель"
                         [(ngModel)]="employee.record.device.model">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <select name="operator" class="form-control" [(ngModel)]="employee.record.device.operator">
                    <option value="">Оператор</option>
                    <option value="megafon">Мегафон</option>
                    <option value="beeline">Билайн</option>
                    <option value="mts">МТС</option>
                    <option value="tele2">Теле2</option>
                    <option value="yota">Yota</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="legal-entity">
            <div class="row" *ngIf="employee.record.legal_entity.id">
              <div class="col-lg-12">
                <div class="panel panel-info">
                  <div class="panel-body">
                    <span class="glyphicon glyphicon-hand-right info-icon"></span> Водитель является юридическим лицом.
                    Чтобы сделать его физическим лицом, уберите юридическое лицо.
                  </div>
                </div>
              </div>
            </div>
            <div class="row inn-row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityInn" class="control-label">ИНН</label>
                  <input id="legalEntityInn" type="text" name="legalEntityInn" class="form-control"
                         [(ngModel)]="employee.record.legal_entity.inn" readonly (click)="onShowInnDialog()">
                </div>
              </div>
              <div class="col-lg-3 inn-col visible-lg">
                <div class="inn-control">
                  <button class="btn btn-default" type="button" (click)="onShowInnDialog()">Выбрать</button>
                  <button class="btn btn-danger" type="button" title="Убрать" (click)="onUnlinkInn()"
                          *ngIf="employee.record.legal_entity.inn && employee.record.legal_entity.inn != ''"><span
                    class="glyphicon glyphicon-remove"></span></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityType" class="control-label">Тип</label>
                  <select name="legalEntityType" id="legalEntityType" class="form-control"
                          [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.type">
                    <option value="ltd">ООО</option>
                    <option value="pe">ИП</option>
                    <option value="self_employed">Самозанятый</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityName" class="control-label">Официальное название организации</label>
                  <input class="form-control" id="legalEntityName" name="legalEntityName"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.name" required>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityShortName" class="control-label">Краткое наименование</label>
                  <input class="form-control" id="legalEntityShortName" name="legalEntityShortName"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.short_name">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntitySignatoryAuthority" class="control-label">Лицо с правом подписи</label>
                  <input class="form-control" id="legalEntitySignatoryAuthority" name="legalEntitySignatoryAuthority"
                         [disabled]="legalEntityDisabled"
                         [(ngModel)]="employee.record.legal_entity.signatory_authority">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="legalEntityAddress" class="control-label">Юридический адрес</label>
                  <input class="form-control" id="legalEntityAddress" name="legalEntityAddress"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.legal_address">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="legalEntityActualAddress" class="control-label">Фактический адрес</label>
                  <input class="form-control" id="legalEntityActualAddress" name="legalEntityActualAddress"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.actual_address">
                </div>
              </div>
            </div>
            <h2>Платежные реквизиты</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityKpp" class="control-label">КПП</label>
                  <input class="form-control" id="legalEntityKpp" name="legalEntityKpp" [disabled]="legalEntityDisabled"
                         [(ngModel)]="employee.record.legal_entity.kpp">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityOgrn" class="control-label">ОГРН</label>
                  <input class="form-control" id="legalEntityOgrn" name="legalEntityOgrn"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.ogrn">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityBankBik" class="control-label">БИК банка</label>
                  <input class="form-control" id="legalEntityBankBik" name="legalEntityBankBik"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.bik">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityCorrespondentAccount" class="control-label">Корреспондентский счёт</label>
                  <input class="form-control" id="legalEntityCorrespondentAccount"
                         name="legalEntityCorrespondentAccount" [disabled]="legalEntityDisabled"
                         [(ngModel)]="employee.record.legal_entity.correspondent_account">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-9">
                <div class="form-group">
                  <label for="legalEntityBankName" class="control-label">Наименование банка</label>
                  <input class="form-control" id="legalEntityBankName" name="legalEntityBankName"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.bank">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityBankInn" class="control-label">ИНН банка</label>
                  <input class="form-control" id="legalEntityBankInn" name="legalEntityBankInn"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.bank_inn">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="legalEntityCheckingAccount" class="control-label">Расчётный счёт</label>
                  <input class="form-control" id="legalEntityCheckingAccount" name="legalEntityCheckingAccount"
                         [disabled]="legalEntityDisabled" [(ngModel)]="employee.record.legal_entity.checking_account">
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="individual">
            <div class="row" *ngIf="!employee.record.legal_entity.id">
              <div class="col-lg-12">
                <div class="panel panel-info">
                  <div class="panel-body">
                    <span class="glyphicon glyphicon-hand-right info-icon"></span> Водитель является физическим лицом.
                    Чтобы сделать его юридическим, добавьте юридическое лицо.
                  </div>
                </div>
              </div>
            </div>
            <h2>Идентификация</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="individualInn" class="control-label">ИНН</label>
                  <input id="individualInn" type="text" name="individualInn" class="form-control"
                         [(ngModel)]="employee.record.individual.inn">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="individualSnils" class="control-label">СНИЛС</label>
                  <input id="individualSnils" type="text" name="individualSnils" class="form-control"
                         [(ngModel)]="employee.record.individual.snils">
                </div>
              </div>
            </div>
            <h2>Данные для выплат</h2>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="individualBik" class="control-label">БИК банка</label>
                  <input id="individualBik" type="text" name="individualBik" class="form-control"
                         [(ngModel)]="employee.record.individual.bik">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="individualCheckingAccount" class="control-label">Расчётный счёт банковской карты</label>
                  <input id="individualCheckingAccount" type="text" name="individualCheckingAccount"
                         [(ngModel)]="employee.record.individual.checking_account"
                         class="form-control">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="individualBankCard" class="control-label">Номер карты</label>
                  <input id="individualBankCard" type="text" name="individualBankCard"
                         [(ngModel)]="employee.record.individual.bank_card"
                         class="form-control">
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="transport">
            <div class="row main-transport-row">
              <div class="col-lg-5">
                <div class="form-group">
                  <label for="mainWorkTransport" class="control-label">Основная рабочая машина</label>
                  <input id="mainWorkTransport" type="text" name="mainWorkTransport" class="form-control" [value]="employee.record.mainWorkTransport | transportDescription" readonly>
                </div>
              </div>
              <div class="col-lg-3 main-transport-col">
                <div class="main-transport-control">
                  <button class="btn btn-default" type="button" (click)="onSelectMainWorkTransport()">Выбрать</button>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="payment-distribution">
            <h2>Схема распределения средств</h2>
            <div class="row">
              <div class="col-lg-5">
                <div class="form-group">
                  <select name="paymentDistributionSchema" class="form-control" [(ngModel)]="paymentDistributionSchema.id">
                    <option value="0">- использовать схему по умолчанию -</option>
                    <option *ngFor="let schema of paymentDistributionSchemas" [value]="schema.id">{{ schema.name }}</option>
                  </select>
                  <input-error [formErrors]="employee.errors" field="account.employer.payment_distribution_schema"></input-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-body">
        <label>Комментарий к водителю</label>
        <textarea class="form-control" rows="5" name="comment" placeholder="Комментарий"
                  [(ngModel)]="employee.record.comment"></textarea>
        <div class="checkbox">
          <label><input type="checkbox" name="active" [(ngModel)]="employee.record.active"> Активирован</label>
        </div>
      </div>
    </div>

    <div class="form-controls">
      <button type="button" class="btn btn-default" (click)="onBack()"><span
        class="glyphicon glyphicon-chevron-left"></span> К списку
      </button>
      <button type="submit" class="btn btn-primary" [disabled]="employee.isSubmitting" *ngIf="isEditAllowed">Сохранить</button>
    </div>
  </form>
</div>
<div>&nbsp;</div>
<inn-dialog #innDialog [inn]="employee.record.legal_entity.inn" (innChange)="onSelectInn($event)" enableUnlink="true"></inn-dialog>
<transport-selector-dialog #transportsDialog [freighter]="freighter"
                           (selected)="onMainWorkTransportSelected($event)"
                           [employer]="employee.record.account.employer"></transport-selector-dialog>
