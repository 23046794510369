import {TaxiMessage} from "./taxi-message";

export class TaxiNotFound implements TaxiMessage {
  constructor(public draftId: number) {}

  getDraftId(): number {
    return this.draftId;
  }

}
