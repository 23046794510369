export const EXECUTION_STATUSES = {
  none: 'статус не установлен',
  moved_to_order: 'выехал на заказ',
  on_start_place: 'прибыл на заказ',
  loading: 'погрузка',
  moving: 'перевозка',
  on_end_place: 'в точке назначения',
  unloading: 'разгрузка',
  completed: 'заказ завершён',
  canceled: 'заказ отменён',
  cash_accepted: 'наличные приняты',
  request_card_payment: 'запросить оплату по карте'
};
