import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TransportTariff} from "../_models/transport-tariff";
import {LoaderTariff} from "../_models/loader-tariff";
import {AssemblerTariff} from "../_models/assembler-tariff";
import {LiftingTariff} from "../_models/lifting-tariff";
import {FreighterTransportTariff} from "../_models/freighter-transport-tariff";
import {FreighterLoaderTariff} from "../_models/freighter-loader-tariff";
import {UserInfoService} from "../_services/user-info.service";
import {FreighterTransportTariffService} from "../_services/freighter-transport-tariff.service";
import {FreighterLoaderTariffService} from "../_services/freighter-loader-tariff.service";
import {OrderDraft} from "../_models/order-draft";

@Component({
  selector: 'draft-tariff-editor',
  templateUrl: './draft-tariff-editor.component.html',
  styleUrls: ['./draft-tariff-editor.component.css']
})
export class DraftTariffEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Output() updated = new EventEmitter<void>();

  transportTariff: TransportTariff;
  loaderTariff: LoaderTariff;
  assemblerTariff: AssemblerTariff;
  liftingTariff: LiftingTariff;

  transportTariffs: FreighterTransportTariff[] = [];
  loaderTariffs: FreighterLoaderTariff[] = [];

  transportTariffId: string = "";
  loaderTariffId: string = "";

  constructor(
    private freighterTransportTariffService: FreighterTransportTariffService,
    private freighterLoaderTariffService: FreighterLoaderTariffService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit() {
    this.transportTariff = new TransportTariff();
    this.loaderTariff = new LoaderTariff();
    this.assemblerTariff = new AssemblerTariff();
    this.liftingTariff = new LiftingTariff();

    Object.assign(this.transportTariff, this.draft.tariffs && this.draft.tariffs[0] || new TransportTariff());
    Object.assign(this.loaderTariff, this.draft.loader_tariffs && this.draft.loader_tariffs[0] || new LoaderTariff());
    Object.assign(this.assemblerTariff, this.draft.assembler_tariffs && this.draft.assembler_tariffs[0] || new AssemblerTariff());
    Object.assign(this.liftingTariff, this.draft.lifting_tariffs && this.draft.lifting_tariffs[0] || new LiftingTariff());

    this.initTariffLists();
  }

  private initTariffLists() {
    if(!this.userInfoService.isPrivilegedUser()) {
      this.initTransportTariffList();
      this.initLoaderTariffList();
    }
  }

  private initTransportTariffList() {
    this.freighterTransportTariffService
      .getTariffs()
      .subscribe(
        tariffs => this.transportTariffs = tariffs,
        () => {}
      );
  }

  private initLoaderTariffList() {
    this.freighterLoaderTariffService
      .getTariffs()
      .subscribe(
        tariffs => this.loaderTariffs = tariffs,
        () => {}
      );
  }

  private applyTransportTariff(tariff: FreighterTransportTariff) {
    this.transportTariff.min_price = tariff.min_price;
    this.transportTariff.min_hours = tariff.min_hours;
    this.transportTariff.additional_hour_price = tariff.additional_hour_price;
    this.transportTariff.after_mkad_km_price = tariff.after_mkad_km_price;
    this.transportTariff.ttk_price = tariff.ttk_price;
    this.transportTariff.hydroelevator_hour_price = tariff.hydroelevator_hour_price;
  }

  private applyLoaderTariff(tariff: FreighterLoaderTariff) {
    this.loaderTariff.min_price = tariff.min_price;
    this.loaderTariff.min_hours = tariff.min_hours;
    this.loaderTariff.additional_hour_price = tariff.additional_hour_price;
    this.loaderTariff.on_the_way_hour_price = tariff.on_the_way_hour_price;

    this.assemblerTariff.min_price = tariff.assembler_min_price;
    this.assemblerTariff.min_hours = tariff.assembler_min_hours;
    this.assemblerTariff.additional_hour_price = tariff.assembler_additional_hour_price;

    this.liftingTariff.min_price = tariff.lifting_min_price;
    this.liftingTariff.min_hours = tariff.lifting_min_hours;
    this.liftingTariff.additional_hour_price = tariff.lifting_additional_hour_price;
  }

  onSelectTransportTariff() {
    console.log(`Selected #${this.transportTariffId} transport tariff`);

    if(this.transportTariffId === '')
      return;

    let id = +this.transportTariffId;
    let tariff = this.transportTariffs.find(tariff => tariff.id === id);

    if(tariff)
      this.applyTransportTariff(tariff);
  }

  onSelectLoaderTariff() {
    console.log(`Selected #${this.loaderTariffId} loader tariff`);

    if(this.loaderTariffId === '')
      return;

    let id = +this.loaderTariffId;
    let tariff = this.loaderTariffs.find(tariff => tariff.id === id);

    if(tariff)
      this.applyLoaderTariff(tariff);
  }

  onSave() {
    this.draft.tariffs = [this.transportTariff];
    this.draft.loader_tariffs = [this.loaderTariff];
    this.draft.assembler_tariffs = [this.assemblerTariff];
    this.draft.lifting_tariffs = [this.liftingTariff];

    this.updated.emit();
  }
}
