import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Order} from "../_models/order";
import {LoaderService} from "../_services/loader.service";
import {OrderService} from "../_services/order.service";

@Component({
  selector: 'order-parameters-editor',
  templateUrl: './order-parameters-editor.component.html',
  styleUrls: ['./order-parameters-editor.component.css']
})
export class OrderParametersEditorComponent implements OnInit {
  @Input() order: Order;
  @Output() onUpdated = new EventEmitter<Order>();

  loaders: number;
  assembly: boolean;

  constructor(
    private loaderService: LoaderService,
    private orderService: OrderService
  ) { }

  ngOnInit() {
    this.loaders = this.order.draft.loaders;
    this.assembly = this.order.draft.assembly;
  }

  onSave() {
    this.loaderService.show();
    this.orderService
      .updateOrderLoaders(this.order, this.loaders)
      .toPromise()
      .then(() => this.orderService.updateOrderAssembly(this.order, this.assembly).toPromise())
      .then(() => {
          this.order.draft.loaders = this.loaders;
          this.order.draft.assembly = this.assembly;
          this.loaderService.hide();
          this.onUpdated.emit(this.order);
        }
      )
      .catch(() => this.loaderService.hide());
  }
}
