import {Pipe, PipeTransform} from "@angular/core";
import {SEARCH_STATUSES_GROUPS} from "../_maps/search-statuses";

@Pipe({name: "taxiSearchStatus"})
export class TaxiSearchStatusPipe implements PipeTransform {
  transform(status: string): any {
    for(let key in SEARCH_STATUSES_GROUPS) {
      let group = SEARCH_STATUSES_GROUPS[key];
      if(group.statuses.indexOf(status) > -1)
        return group.name;
    }
    return 'неизвестно';
  }
}
