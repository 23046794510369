
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {from, Observable} from "rxjs";
import {Invoice} from "../_models/invoice";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Account} from "../_models/account";
import {InvoicePresentersList} from "../_models/invoice-presenters-list";
import {InvoicePresenter} from "../_models/invoice-presenter";

const PAGE_SIZE = 10;

@Injectable()
export class InvoiceService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getOrdersPayments(ordersId: number[]): Observable<Invoice[]> {
    if(ordersId.length == 0)
      return from([]);

    return this._requestService
      .get('/payments.json', {
        ordersId: ordersId.join(',')
      }).pipe(
      map(r => r.body as Invoice[]))
    ;
  }

  getOrdersPayouts(ordersId: number[]): Observable<Invoice[]> {
    if(ordersId.length == 0)
      return from([]);

    return this._requestService
      .get('/payouts.json', {
        ordersId: ordersId.join(',')
      }).pipe(
      map(r => r.body as Invoice[]))
    ;
  }

  getLastAccountInvoices(account: Account, page: number): Observable<InvoicePresentersList> {
    let searchParams = {
      offset: (page || 0) * PAGE_SIZE,
      size: PAGE_SIZE
    };

    return this._requestService
      .get(`/accounts/${account.id}/invoices.json`, searchParams).pipe(
      map(r => new InvoicePresentersList(page || 0, PAGE_SIZE, parseInt(r.headers.get('X-Total-Count')), r.body as InvoicePresenter[])))
    ;
  }
}
