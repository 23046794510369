
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {ParcelInfo} from "../_models/ozon/parcel-info";
import {Storehouse} from "../_models/storehouse";
import {OptimizationTaskImportResult} from "../_models/ozon/optimization-task-import-result";
import {DateTime} from "date-time-js";
import {OptimizationTaskTransport} from "../_models/optimization-task-transport";

@Injectable()
export class OzonService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getNewParcels(): Observable<ParcelInfo[]> {
    return this._requestService
      .get('/ozon/new_parcels.json').pipe(
      map(r => r.body as ParcelInfo[]))
      ;
  }

  importToOptimizationTask(storehouse: Storehouse, arrivalStart: Date, arrivalEnd, postingNumbers: string[]): Observable<OptimizationTaskImportResult[]> {
    return this._requestService
      .post('/ozon/parcels/import.json', {
        arrivalStart: new DateTime(arrivalStart).format('yyyy-MM-dd HH:mm:ss'),
        arrivalEnd: new DateTime(arrivalEnd).format('yyyy-MM-dd HH:mm:ss'),
        storehouseId: storehouse.id,
        postingNumbers: postingNumbers
      })
      .pipe(
        map(r => r.body.import_results as OptimizationTaskImportResult[])
      )
      ;
  }

  importToOptimizationTaskTransport(transport: OptimizationTaskTransport, postingNumbers: string[]): Observable<OptimizationTaskImportResult[]> {
    return this._requestService
      .post(`/ozon/parcels/import/transports/${transport.id}.json`, {
        postingNumbers: postingNumbers
      })
      .pipe(
        map(r => r.body.import_results as OptimizationTaskImportResult[])
      )
      ;
  }
}
