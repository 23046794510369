import {SupportNotification} from "./support-notification";
import {List} from "../pager/list";

export class SupportNotificationsList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public notifications: SupportNotification[]) {
  }

  static empty(): SupportNotificationsList {
    return new SupportNotificationsList(0, 0, 0, []);
  }

  concat(array) {
    this.notifications = array.concat(this.notifications);
    return this;
  }

  hasMore(): boolean {
    if(this.notifications.length < this.pageSize)
      return false;

    let lastNumOnPage = this.page * this.pageSize + this.pageSize - 1;
    return lastNumOnPage < this.totalCount - 1;
  }

}
