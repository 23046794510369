<div class="modal fade" tabindex="-1" role="dialog" #userDetailDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Информация о пользователе</h4>
        </div>
      </div>
      <div class="modal-body">
        <user-detail [user]="user" (onOpenOrder)="hide()"></user-detail>
      </div>
    </div>
  </div>
</div>
