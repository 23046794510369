import {List} from "../pager/list";
import {FreighterClient} from "./freighter-client";

export class FreighterClientsList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public clients: FreighterClient[]) {
  }

  concat(array): FreighterClientsList {
    this.clients = array.concat(this.clients);
    return this;
  }

  hasMore(): boolean {
    if(this.clients.length < this.pageSize)
      return false;

    let lastNumOnPage = this.page * this.pageSize + this.pageSize - 1;
    return lastNumOnPage < this.totalCount - 1;
  }

  static empty(): FreighterClientsList {
    return new FreighterClientsList(0, 0, 0, []);
  }

}
