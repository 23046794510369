export class IntervalFilter {
  constructor(
    public from?: number,
    public to?: number,
    public including?: boolean
  ) {}

  equals(filter: IntervalFilter): boolean {
    return this.from === filter.from
      && this.to === filter.to
      && (this.including || false) === (filter.including || false)
    ;
  }

  clone(): IntervalFilter {
    return new IntervalFilter(this.from, this.to, this.including);
  }

  forState(): any {
    return {
      from: this.from,
      to: this.to,
      including: this.including
    }
  }

  static fromState(state?: any): IntervalFilter {
    if(!state)
      state = {};

    return new IntervalFilter(state['from'], state['to'], state['including']);
  }
}
