import {DestinationPoint} from "./destination-point";

export class Point {
  lat: number;
  t: number|undefined;
  private _lon: number;
  private _lng: number;

  set lon(value: number) {
    this._lon = this._lng = value;
  }

  set lng(value: number) {
    this.lon = value;
  }

  get lng(): number {
    return this._lng || this._lon;
  }

  get lon(): number {
    return this._lon || this._lng;
  }

  static clone(src: Point): Point {
    let dst = new Point();
    dst.lat = parseFloat(src.lat + '');
    dst.lng = parseFloat(src.lng + '');
    dst.t = src.t;

    return dst;
  }

  static cloneWithOffset(src: Point, offsetLat: number, offsetLon: number): Point {
    let dst = Point.clone(src);
    dst.lat += offsetLat;
    dst.lng += offsetLon;

    return dst;
  }

  static createFromDestinationPoint(d: DestinationPoint): Point {
    let p = new Point();
    p.lat = d.lat;
    p.lon = d.lon;

    return p;
  }
}
