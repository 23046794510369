<div class="modal fade" tabindex="-1" role="dialog" #promoCodeDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">ПРОМОКОД</h4>
        </div>
      </div>
      <div class="modal-body">
        <promo-code-form (enter)="onEnterCode()" [error]="error"></promo-code-form>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col col-6">
            <button class="apply-btn btn-block" type="button" (click)="onEnterCode()">Применить</button>
          </div>
          <div class="col col-6">
            <button class="close-btn btn-block" type="button" data-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
