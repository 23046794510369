
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {firstValueFrom, Observable} from "rxjs";
import {Issue} from "../_models/issue";
import {Destination} from "../_models/destination";
import {DeliveryProductIssue} from "../_models/delivery-product-issue";
import {Order} from "../_models/order";
import {UserInfoService} from "./user-info.service";
import {HttpResponse} from "@angular/common/http";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

@Injectable()
export class IssueService {
  private issuesPromise: Promise<Issue[]>;

  constructor(private _requestService: RequestWithErrorHandlerService, private userInfoService: UserInfoService) { }

  getIssues(): Observable<Issue[]> {
    if(this.issuesPromise == null) {
      this.issuesPromise = firstValueFrom(this._requestService
        .get('/delivery/issues.json').pipe(
        map(r => r.body as Issue[]))
      );
    }

    return fromPromise(this.issuesPromise);
  }

  getAvailableIssuesForDestination(destination: Destination): Observable<Issue[]> {
    const uri = this.userInfoService.isDeliveryManager()
      ? `/delivery/issues/period_destinations/${destination.id}/available.json`
      : `/support/me/period_destinations/${destination.id}/issues/available.json`

    return this._requestService.get(uri).pipe(map(r => r.body as Issue[]));
  }

  createIssueAtDestination(destination: Destination, issueIdentifier: string, description: string, metadata: Object = {}): Observable<HttpResponse<any>> {
    let data = {
      identifier: issueIdentifier,
      description: description
    };

    for(let metakey in metadata || {}) {
      data[metakey] = metadata[metakey];
    }

    const uri = this.userInfoService.isDeliveryManager()
      ? `/delivery/issues/period_destinations/${destination.id}.json`
      : `/support/me/period_destinations/${destination.id}/issues.json`;

    return this._requestService.post(uri, data);
  }

  listIssuesAtDestination(destination: Destination): Observable<DeliveryProductIssue[]> {
    const uri = this.userInfoService.isDeliveryManager()
        ? `/delivery/issues/period_destinations/${destination.id}.json`
        : `/support/me/period_destinations/${destination.id}/issues.json`

    return this._requestService.get(uri).pipe(map(r => r.body as DeliveryProductIssue[]));
  }

  listIssuesByOrder(order: Order): Observable<DeliveryProductIssue[]> {
    const uri = this.userInfoService.isDeliveryManager()
        ? `/delivery/issues/orders/${order.id}.json`
        : `/support/me/orders/${order.id}/issues.json`;

    return this._requestService.get(uri).pipe(map(r => r.body as DeliveryProductIssue[]));
  }
}
