import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from "@angular/router";
import {environment} from "../environments/environment";
import {VERSION} from "./version";
import {LogoutService} from "./_services/logout.service";
import {AlertService} from "./_services/alert.service";
import {LoaderService} from "./_services/loader.service";
import {UserInfoService} from "./_services/user-info.service";
import {NotificationService} from './_services/notification.service';
import {IntercomService} from './_services/intercom.service';
import {Account} from "./_models/account";
import {SoundNotificationService} from "./_services/sound-notification.service";
import {TitleService} from "./_services/title.service";
import {CityService} from "./_services/city.service";
import {City} from "./_models/city";
import {DraftSpecialDialogComponent} from "./draft-special-dialog/draft-special-dialog.component";
import {DraftSpecial} from "./_models/draft-special";
import {DeliverySchemasDialogComponent} from "./delivery-schemas-dialog/delivery-schemas-dialog.component";
import {DeliverySchema} from "./_models/delivery-schema";
import {NewComplexDeliveryMethodDialogComponent} from "./new-complex-delivery-method-dialog/new-complex-delivery-method-dialog.component";
import {OzonImportDialogComponent} from "./ozon-import-dialog/ozon-import-dialog.component";
import {NewOrderService} from "./_services/new-order.service";

type NewComplexDeliveryAction = {[key: string]: () => void};

@Component({
  selector: 'main-app',
  styleUrls: ['./app2.component.css'],
  templateUrl: './app2.component.html'
})
export class App2Component implements OnInit, OnDestroy {
  @ViewChild(DraftSpecialDialogComponent) draftSpecialDialog: DraftSpecialDialogComponent;
  @ViewChild(DeliverySchemasDialogComponent) deliverySchemasDialog: DeliverySchemasDialogComponent;
  @ViewChild(NewComplexDeliveryMethodDialogComponent) newComplexDeliveryMethodDialog: NewComplexDeliveryMethodDialogComponent;
  @ViewChild(OzonImportDialogComponent) ozonImportDialog: OzonImportDialogComponent;

  hideMenu = false;
  fullWidth = true;
  environment = environment;
  version = VERSION;
  userName: string = '';
  balance: number;
  showNewComplexDeliveryFormEvent = new EventEmitter<DeliverySchema|null>();
  complexDeliveryFormModeEvent = new EventEmitter<string>();
  scrolled = false;
  accountPicture: string;
  visibleCitySelector = false;
  cities: City[] = [];
  ozonImportInitialized = false;

  private newComplexDeliveriesActions: NewComplexDeliveryAction;
  private newComplexDeliveryMethod: string;

  constructor(private router: Router,
              public userInfo: UserInfoService,
              public _notificationService: NotificationService,
              public soundNotificationService: SoundNotificationService,
              private titleService: TitleService,
              public cityService: CityService,
              private newOrderService: NewOrderService
  ) {
    this.initNewComplexDelivery();
  }

  ngOnInit() {
    const $window = $(window);

    this.router.events
      .subscribe(event => {
        if(event instanceof NavigationEnd) {
          this.hideMenu = this.findBooleanRouteProperty(this.router.routerState.snapshot.root, 'hideMenu');
          this.fullWidth = this.findBooleanRouteProperty(this.router.routerState.snapshot.root, 'fullWidth');
          let listPage = this.findBooleanRouteProperty(this.router.routerState.snapshot.root, 'listPage');
          $('body').css('min-height', listPage ? '101vh' : 0);
          $window.scrollTop(0);
          let title = this.findStringRouteProperty(this.router.routerState.snapshot.root, 'title');
          if(title !== null)
            this.titleService.changeTitle(title);
        }
      });

    this.userInfo.getUserLoginSubject().subscribe(
      msg => {
        if(msg) {
          console.log('setup notifications from subscribe');
          this.setupNotifications();
          this.userName = msg.account.name;
          this.balance = msg.account.sum;
          this.applyAccountPicture(msg.account);
        }
      }
    );


    if(this.userInfo.isPresent()) {
      this.userName = this.userInfo.getUserName();
      this.balance = this.userInfo.userInfo.account.sum;
      this.applyAccountPicture(this.userInfo.userInfo.account);

      this.setupNotifications();
      console.log('Is customer ->', this.userInfo.isCustomer());
    }


    this._notificationService.requestDesktopNotificationPermission();

    $window.scroll(() => {
      this.scrolled = $window.scrollTop() > 0;
    });

    this.newOrderService.getObservable().subscribe(() => this.showNewComplexDeliveryMethodDialog());
  }

  private setupNotifications() {
    this._notificationService.initTaxiConnection();
    this._notificationService.initOrdersConnection();
    this._notificationService.getListener().next({command: 'setupOrderCounter'});
  }

  private initNewComplexDelivery(): void {
    this.newComplexDeliveriesActions = {
      ozon: () => this.startOzonImport(),
      file: () => this.startImportComplexDeliveryFromFile(),
      manually: () => this.startManuallyAddingOfComplexDelivery()
    }
  }

  ngOnDestroy() {
    this._notificationService.closeTaxiConnection();
  }

  private findBooleanRouteProperty(root: ActivatedRouteSnapshot, property: string) {
    if(root.data && root.data[property] === true)
      return true;

    return root.firstChild ? this.findBooleanRouteProperty(root.firstChild, property) : false;
  }

  private findStringRouteProperty(root: ActivatedRouteSnapshot, property: string): string | null {
    console.log(root);
    if(root.data && root.data[property] != null)
      return root.data[property];

    return root.firstChild ? this.findStringRouteProperty(root.firstChild, property) : null;
  }

  private startOzonImport(): void {
    this.ozonImportInitialized = true;
    let _this = this;
    setTimeout(() => _this.ozonImportDialog.show());
  }

  private startImportComplexDeliveryFromFile(): void {
    this.deliverySchemasDialog.showDialog();

  }

  private startManuallyAddingOfComplexDelivery(): void {
    this.deliverySchemasDialog.showDialog();
  }

  private startNewComplexDelivery(method: string): void {
    let action = this.newComplexDeliveriesActions[method];
    if(action) {
      this.newComplexDeliveryMethod = method;
      action();
    }
  }

  showNewOrderModal() {
    $('#new-order').modal('show');
    return false;
  }

  showNewComplexDeliveryModal() {
    this.showNewComplexDeliveryFormEvent.emit(null);
    return false;
  }

  showDeliverySchemasDialog() {
    this.deliverySchemasDialog.showDialog();
    return false;
  }

  showNewComplexDeliveryMethodDialog() {
    this.newComplexDeliveryMethodDialog.showDialog();
    return false;
  }

  private applyAccountPicture(account: Account): void {
    if(account.picture_sizes && account.picture_sizes["320"]) {
      this.accountPicture = `url(${account.picture_sizes["320"]})`;
    } else {
      this.accountPicture = null;
    }
  }

  onClickCity(): void {
    this.visibleCitySelector = !this.visibleCitySelector;

    if(this.visibleCitySelector) {
      this.cityService
        .getCities()
        .subscribe(cities => this.cities = cities)
      ;
    }
  }

  onChangeCity(city?: City): void {
    this.visibleCitySelector = false;

    this.cityService.activeCity = city;
  }

  onShowDraftSpecialDialog(): void {
    this.draftSpecialDialog.showDialog();
  }

  onSelectDraftSpecial(draftSpecial: DraftSpecial): void {
    this.router.navigate(['/drafts/new/special', draftSpecial.special, draftSpecial.schema, draftSpecial.draftTypeIdentifier])
  }

  onSelectDeliverySchema(schema: DeliverySchema): void {
    this.showNewComplexDeliveryFormEvent.emit(schema);

    if(this.newComplexDeliveryMethod === 'file')
      this.complexDeliveryFormModeEvent.emit('import');
    else if(this.newComplexDeliveryMethod == 'manually')
      this.complexDeliveryFormModeEvent.emit('edit');
  }

  onNewComplexDelivery(method: string): void {
    this.startNewComplexDelivery(method);
  }
}
