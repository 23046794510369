import {Pipe, PipeTransform} from "@angular/core";
import {OrderDraft} from "../_models/order-draft";
import {DraftUtils} from "../_utils/draft-utils";

@Pipe({name: "draftHydroElevator"})
export class DraftHydroElevatorPipe implements PipeTransform {
  transform(draft: OrderDraft): string {
    return DraftUtils.getHydroElevator(draft) ? 'да' : 'нет';
  }
}
