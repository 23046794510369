import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../environments/environment";
import {Uploader} from "../_upload/uploader";
import {ComplexDeliveryForm} from "../complex-delivery-form/complex-delivery-form";
import {UploadError} from "../_upload/upload-error";
import {LoaderService} from "../_services/loader.service";
import {OptimizationTask} from "../_models/optimization-task";
import {CompleteAllUploadsHandler} from "../_upload/complete-all-uploads-handler";
import {FileErrorHandler} from "../_upload/file-error-handler";
import {OptimizationTaskService} from "../_services/optimization-task.service";
import {UploadErrorMessageBuilder} from "../_upload/upload-error-message-builder";
import {AlertService} from "../_services/alert.service";
import {UploaderService} from "../_services/uploader.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'complex-delivery-import-form',
  templateUrl: './complex-delivery-import-form.component.html',
  styleUrls: ['./complex-delivery-import-form.component.css']
})
export class ComplexDeliveryImportFormComponent implements OnInit, ComplexDeliveryForm, CompleteAllUploadsHandler, FileErrorHandler {
  @Input() optimizationTask: OptimizationTask;
  @Input() registerFormEvent: EventEmitter<ComplexDeliveryForm>;
  @Output() saved = new EventEmitter<number>();
  @Output() saveFailed = new EventEmitter<number>();

  templateUrl = `${environment.apiEndpoint}/file-templates/import-template.xlsx`

  uploader: Uploader;
  private uploadErrors: UploadError[] = [];

  private fileSelected = false;

  fileFieldVisible = true;

  constructor(
    private loaderService: LoaderService,
    private optimizationTaskService: OptimizationTaskService,
    private alertService: AlertService,
    private uploaderService: UploaderService,
  ) {
  }

  ngOnInit() {
    this.registerForm();
    this.initUploader();
  }

  private registerForm() {
    if(this.registerFormEvent != null)
      this.registerFormEvent.emit(this);
  }

  private initUploader(): void {
    this.uploader = new Uploader({
      allowedMimeType: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      itemAlias: 'destinations',
      url: `${environment.apiEndpoint}/delivery/tasks/${this.optimizationTask.id}/import.json`
    });
    this.uploader.subscribeOnCompleteAll(this);
    this.uploader.subscribeOnFileError(this);
  }

  validate(): boolean {
    return this.fileSelected;
  }

  validateForCalculation(): boolean {
    return false;
  }

  submit(): void {
    this.loaderService.show();
    this.alertService.clear();

    if(this.optimizationTask.id) {
      this.upload();
    } else {
      this.optimizationTask.destinations = [];
      this.optimizationTaskService
        .addTask(this.optimizationTask)
        .subscribe(
          id => {
            this.optimizationTask.id = id;
            this.upload();
          },
          () => {}
        );
    }
  }

  private upload() {
    this.uploadErrors = [];

    this.uploaderService.prepareToUpload(
      `${environment.apiEndpoint}/delivery/tasks/${this.optimizationTask.id}/import.json`,
      this.uploader
    );
    this.uploader.uploadAll();
  }

  private refreshUploadField(): void {
    this.fileFieldVisible = false;
    setTimeout(() => { this.fileFieldVisible = true }, 500);
  }

  private checkUploadResult(): void {
    this.loaderService.show();
    this.loaderService.showText('Проверка результата импорта...');
    this.optimizationTaskService
      .getTask(this.optimizationTask.id, 'view', true)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: t => {
          if(t.fail_destinations.length > 0) {
            this.saveFailed.emit(this.optimizationTask.id);
          } else {
            this.saved.emit(this.optimizationTask.id);
          }
        },
        error: () => {

        }
      })
  }

  hasUploadErrors() {
    return this.uploadErrors.length > 0;
  }

  onFileSelected() {
    console.log('selected file');
    this.fileSelected = true;
  }

  onCompleteAllUploads() {
    if(this.hasUploadErrors()) {
      this.loaderService.hide();
      this.uploader.clearQueue();
    } else {
      this.loaderService.hide()
      this.checkUploadResult();
    }

    this.refreshUploadField();
  }

  onFileUploadError(error: UploadError) {
    console.log('upload error');
    console.log(error);

    this.uploadErrors.push(error);
    let errors = ['Ошибка загрузки:'].concat(this.uploadErrors.map(UploadErrorMessageBuilder.build));
    this.alertService.error(errors);
  }
}
