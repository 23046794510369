<table class="table">
  <tbody>
  <tr>
    <th>Вид документа</th>
    <td>{{ passport.type|passportType }}</td>
  </tr>
  <ng-template [ngIf]="isPassportTypeOf(['rf', 'uamut', 'other'])" ngIf>
    <tr>
      <th>Серия</th>
      <td>{{ passport.series }}</td>
    </tr>
    <tr>
      <th>Номер</th>
      <td>{{ passport.number }}</td>
    </tr>
    <tr>
      <th>Кем выдан</th>
      <td>{{ passport.issued_by }}</td>
    </tr>
    <tr>
      <th>Дата выдачи</th>
      <td>{{ passport.issued_date|dt:'dd.MM.yy' }}</td>
    </tr>
  </ng-template>
  <ng-template [ngIf]="isPassportTypeOf(['uamut', 'other'])" ngIf>
    <tr *ngIf="passport.residency && passport.residency != ''">
      <th>Вид на жительство</th>
      <td>{{ passport.residency }}</td>
    </tr>
    <tr *ngIf="passport.residence_permit && passport.residence_permit != ''">
      <th>Разрешение на проживание</th>
      <td>{{ passport.residence_permit }}</td>
    </tr>
    <tr *ngIf="passport.specialist_visa && passport.specialist_visa != ''">
      <th>Виза высококвалифицированного специалиста</th>
      <td>{{ passport.specialist_visa }}</td>
    </tr>
    <tr *ngIf="passport.work_permit && passport.work_permit != ''">
      <th>Разрешение на работу</th>
      <td>{{ passport.work_permit }}</td>
    </tr>
  </ng-template>
  </tbody>
</table>

<div *ngIf="photos.length > 0" class="photos">
  <div *ngFor="let photo of photos" class="img-box">
    <a href="{{ photo.photo }}" data-lightbox="passport-pages" [attr.data-title]="photo.name">
      <img class="img-thumbnail" src="{{ photo.photo }}" [alt]="photo.name" [title]="photo.name">
    </a>
  </div>
</div>
