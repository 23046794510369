export const ORDER_STATUSES = {
  'new': 'новый',
  'edit': 'заказ редактируется',
  checking: 'заказ проверяется водителем',
  negotiation: 'заказ согласовывается с клиентом',
  ready: 'заказ запланирован к выполнению',
  agree: 'компания согласна выполнить заказ',
  not_agree: 'компания не согласна выполнить заказ',
  accepted: 'клиент подтвердил заказ',
  declined: 'клиент не подтвердил заказ',
  no_answer: 'компания не ответила',
  completed: 'заказ завершён',
  canceled_by_freighter: 'заказ отменён компанией',
  canceled_by_employer: 'заказ отменён водителем',
  canceled_by_client: 'заказ отменён клиентом',
  canceled_by_system: 'заказ отменён системой'
};
