import {Injectable} from "@angular/core";
import {RequestService} from "./request.service";
import {Observable, Subject} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class LogoutService {
  private onUserLogout = new Subject<void>();

  constructor(private requestService: RequestService) {}

  getUserLogoutObservable(): Observable<void> {
    return this.onUserLogout.asObservable();
  }

  logout(): Observable<HttpResponse<any>> {
    this.onUserLogout.next();
    return this.requestService.post('/logout/device.json', {});
  }
}
