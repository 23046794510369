
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {CancelReason} from "../_models/cancel-reason";
import {Destination} from "../_models/destination";
import {Issue} from "../_models/issue";
import {UserInfoService} from "./user-info.service";

@Injectable()
export class CancelReasonService {
  constructor(private _requestService: RequestWithErrorHandlerService, private userInfoService: UserInfoService) { }

  getIssueCancelReasons(destination: Destination, issue: Issue): Observable<CancelReason[]> {
    const uri = this.userInfoService.isDeliveryManager()
      ? `/delivery/issues/${issue.identifier}/period_destinations/${destination.id}/cancel_reasons.json`
      : `/support/me/period_destinations/${destination.id}/issues/${issue.identifier}/cancel_reasons.json`;

    return this._requestService.get(uri).pipe(map(r => r.body as CancelReason[]));
  }
}
