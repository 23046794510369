import {Component, Input, OnInit} from '@angular/core';
import {DeliveryProductIssue} from "../_models/delivery-product-issue";

@Component({
  selector: 'delivery-issues',
  templateUrl: './delivery-issues.component.html',
  styleUrls: ['./delivery-issues.component.css']
})
export class DeliveryIssuesComponent implements OnInit {
  @Input() issues: DeliveryProductIssue[] = [];
  @Input() loading = false;
  @Input() productVisible = false;

  constructor() { }

  ngOnInit() {
  }

}
