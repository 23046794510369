<div class="pager">
  <pager [list]="invoices" (onPage)="onPaginationPage($event)"></pager>
</div>

<table class="table invoices" *ngIf="invoices.totalCount > 0">
  <tbody>
  <tr *ngFor="let invoice of invoices.invoices" class="payment-{{ invoice.status }}" [class.credit]="invoice.amount > 0" [class.debit]="invoice.amount < 0">
    <td class="date">{{ invoice.created_at|dt:"dd.MM.yy HH:mm" }}</td>
    <td class="amount">{{ invoice.amount|humanNumber }}</td>
    <td class="status">
      <img
        *ngIf="invoice.invoice.method != 'account'"
        src="/assets/images/pay-methods/ic-24_20-paymethod-{{ invoice.invoice.method }}.svg"
        alt="{{ invoice.invoice.method|payMethod }}"
        title="{{ invoice.invoice.method|payMethod }}"
        height="16"
      >
      {{ invoice.status|paymentStatus }}
    </td>
    <td>
      <span *ngIf="!invoice.invoice.order">{{ invoice.comment }}</span>
      <a *ngIf="invoice.invoice.order" [routerLink]="[ '/orders', invoice.invoice.order.freighter.id, invoice.invoice.order.id ]" (click)="onClickOrderLink(invoice.invoice.order)">{{ invoice.comment }}</a>
    </td>
  </tr>
  </tbody>
</table>

<div class="info" *ngIf="loading">
  <p>Загружаются...</p>
</div>

<div class="info" *ngIf="!loading && invoices.totalCount == 0">
  <p>Отсутствуют</p>
</div>
