import {InvoicePresenter} from "./invoice-presenter";

export class InvoicePresentersList {
  constructor(public page: number, public pageSize: number, public totalCount: number, public invoices: InvoicePresenter[]) {
  }

  static empty(): InvoicePresentersList {
    return new InvoicePresentersList(0, 0, 0, []);
  }

}
