<table class="table table-hover table-condensed destinations-list">
  <thead>
  <tr>
    <th><input type="checkbox" [(ngModel)]="allSelected" name="allSelected" (click)="onClickAllSelected()"></th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let destination of selectableDestinations" class="destination-row" (click)="onClickDestination(destination)">
    <td><input type="checkbox" [(ngModel)]="destination.selected" [name]="'s' + destination.v.id"></td>
    <td>{{ destination.v.listPos }} - {{ destination.v.destination.addr }}</td>
  </tr>
  </tbody>
</table>
