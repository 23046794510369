import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserStat} from "../_models/user-stat";
import {DateTime} from "date-time-js";
import {ReportService} from "../_services/report.service";
import {Observable} from "rxjs";
import {Account} from "../_models/account";
import {AlertService} from "../_services/alert.service";
import {HttpResponse} from "@angular/common/http";

class SendResult {
  status = 'wait';
  error: string;

  constructor(public user: UserStat) {
  }

  init(): void {
    this.status = 'wait';
    this.error = null;
  }

  setSendingStatus(): void {
    this.status = 'sending';
  }

  isSending(): boolean {
    return this.status === 'sending';
  }

  setCanceledStatus(): void {
    this.status = 'canceled';
  }

  isCanceled(): boolean {
    return this.status === 'canceled';
  }

  setSentStatus(): void {
    this.status = 'sent';
  }

  isSent(): boolean {
    return this.status === 'sent';
  }

  setErrorStatus(error: string): void {
    this.status = 'error';
    this.error = error;
  }

  isError(): boolean {
    return this.status === 'error';
  }
}

const statusTranslates = {
  wait: 'ожидание отправки',
  sending: 'отправка...',
  sent: 'отправлено',
  error: 'ошибка',
  canceled: 'отменено'
};

@Component({
  selector: 'send-balance-form',
  templateUrl: './send-balance-form.component.html',
  styleUrls: ['./send-balance-form.component.css']
})
export class SendBalanceFormComponent implements OnInit, OnChanges {
  @Input() users: UserStat[] = [];
  @Output() onCancel = new EventEmitter<void>();

  periodStart = new DateTime().subtract(1, 'day').format('yyyy-MM-dd');
  periodEnd = new DateTime().subtract(1, 'day').format('yyyy-MM-dd');
  forceRecipient: string;
  results: SendResult[];
  sendQueue: SendResult[] = [];
  sending = false;

  constructor(private reportService: ReportService, private alertService: AlertService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareSendResults();
  }

  private prepareSendResults(): void {
    this.results = (this.users || []).map(u => new SendResult(u));
  }

  private send(): void {
    for(let result of this.results)
      result.init();

    this.sending = true;
    this.sendQueue = [].concat(this.results);
    this.sendNext();
  }

  private sendNext(): void {
    if(this.sendQueue.length == 0) {
      this.sending = false;
      return;
    }

    let result = this.sendQueue.shift();

    if(result.user.account.employer == null) {
      result.setErrorStatus('не водитель');
      this.sendNext();
      return;
    }

    if((result.user.account.email == null || result.user.account.email == '') && (this.forceRecipient == null || this.forceRecipient == '')) {
      result.setErrorStatus('не указан email');
      this.sendNext();
      return;
    }

    result.setSendingStatus();

    this.sendForUser(result.user.account)
      .subscribe(
        () => {
          result.setSentStatus();
          this.sendNext();
        },
        e => {
          result.setErrorStatus(e.message || null);
          this.alertService.clear();
          console.error('Sending balance error');
          console.error(e);
          this.sendNext();
        }
      )
  }

  private sendForUser(account: Account): Observable<HttpResponse<any>> {
    let [ start, end ] = this.getDates();

    return this.reportService.sendBalanceReport(account, start, end, this.forceRecipient);
  }

  translateSendStatus(status: string): string {
    return statusTranslates[status] || 'неизвестный';
  }

  private getDates(): Date[] {
    return [ this.toValidDate(this.periodStart, true), this.toValidDate(this.periodEnd, false) ];
  }

  private validate(): boolean {
    let errors: string[] = [];

    let periodStart = this.toValidDate(this.periodStart, true);
    let periodEnd = this.toValidDate(this.periodEnd, false);

    if(periodStart === null)
      errors.push('некорректная дата начала периода');
    if(periodEnd === null)
      errors.push('некорректная дата окончания периода');

    if(periodStart !== null && periodEnd !== null && periodStart.getTime() > periodEnd.getTime())
      errors.push('дата окончания должна идти после даты начала');

    if(errors.length > 0) {
      alert("Форма заполнена неправильно:\n- " + errors.join("\n- "));
      return false;
    }

    return true;
  }

  /**
   * Преобразует дату, представленную в виде строки, в объект
   * @param stringDate
   * @param isStart true, если дата является началом периода
   * @return объект даты или null, если строка не является валидной датой
   */
  private toValidDate(stringDate: string, isStart: boolean): Date {
    if(!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(stringDate))
      return null;

    let time = isStart ? '00:00:00' : '23:59:59';
    return new Date(`${stringDate} ${time}`);
  }

  onSubmit() {
    if(this.validate())
      this.send();
  }

  onClickCancel() {
    let sending = this.sendQueue.length > 0;

    for(let result of this.sendQueue) {
      result.setCanceledStatus();
    }
    this.sendQueue = [];

    if(!sending)
      this.onCancel.emit();
  }
}
