import {finalize} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";
import {UserInfoService} from "../_services/user-info.service";
import {Account} from "../_models/account";
import {ActivatedRoute} from "@angular/router";
import {LoaderService} from "../_services/loader.service";
import {OrderDraftService} from "../_services/order-draft.service";
import {CityService} from "../_services/city.service";
import {DraftSpecial} from "../_models/draft-special";
import {DeliveryService} from "../_services/delivery.service";
import {PayMethodsService} from "../_services/pay-methods.service";

@Component({
  selector: 'app-new-draft',
  templateUrl: './new-draft.component.html',
  styleUrls: ['./new-draft.component.css']
})
export class NewDraftComponent implements OnInit {
  draft: OrderDraft = new OrderDraft();
  special: DraftSpecial;

  constructor(
    private draftsService: OrderDraftService,
    private userInfo: UserInfoService,
    private cityService: CityService,
    private deliveryService: DeliveryService,
    private payMethodsService: PayMethodsService,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        params => {
          if(params['id'] != null)
            this.loadDraft(+params['id'])
          if(params['special']) {
            this.special = new DraftSpecial(params['special'], params['schema'], params['identifier'])
          } else {
            this.special = null;
          }
        },
        e => console.error(e)
      );
    this.draft.client = new Account();
    this.draft.destinations = [];
    this.draft.city = this.cityService.activeCity;
    this.draft.auto_accept_taxi = true;
    this.draft.insurances = [];

    if(this.userInfo.isDeliveryManager()) {
      this.draft.delivery = true;
      this.initDeliverySchemas();
    } else {
      this.initPayMethods();
    }
  }

  private initDeliverySchemas(): void {
    this.deliveryService
      .getDefaultSchemaForNewDraft()
      .subscribe(
        schema => this.draft.delivery_schema = schema,
        () => {}
      )
    ;
  }

  private initPayMethods(): void {
    if(this.draft.delivery) {
      if(this.draft.delivery_schema) {
        this.payMethodsService
          .getMyAvailablePayMethodsForDeliverySchema(this.draft.delivery_schema.id)
          .subscribe(
            methods => {
              if(methods.length > 0) {
                let method = methods[0];
                this.draft.pay_method = method.method;
                this.draft.pay_method_option = method.option;
              }
            },
            () => {}
          );
      }
    } else if(this.userInfo.isCustomer()) {
      this.payMethodsService
        .getMyAvailablePayMethods()
        .subscribe(
          methods => {
            if(methods.length > 0) {
              let method = methods[0];
              this.draft.pay_method = method.method;
              this.draft.pay_method_option = method.option;
            }
          },
          () => {}
        );
    }
  }

  private loadDraft(id) {
    this.loaderService.show();
    this.draftsService
      .getDraft(id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        draft => {
          draft.id = null;
          draft.taxi_search = null;
          this.draft = draft;
        },
        () => {}
      );
  }
}
