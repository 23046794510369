import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Freighter} from "../_models/freighter";
import {Transport} from "../_models/transport";
import {TransportSelectorComponent} from "../transport-selector/transport-selector.component";
import {Employer} from "../_models/employer";

@Component({
  selector: 'transport-selector-dialog',
  templateUrl: './transport-selector-dialog.component.html',
  styleUrls: ['./transport-selector-dialog.component.css']
})
export class TransportSelectorDialogComponent implements OnInit, OnDestroy {
  @Input() freighter: Freighter;
  @Input() date: Date;
  @Input() employer: Employer;
  @Output() selected = new EventEmitter<Transport>();

  @ViewChild('transportDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(TransportSelectorComponent, { static: true }) selector: TransportSelectorComponent;

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  showDialog() {
    $(this.dialogEl.nativeElement).modal('show');
    this.selector.reloadTransports();

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  private hideDialog() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  onSelected(transport: Transport): void {
    this.hideDialog();
    this.selected.emit(transport);
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
