export class Passport {
  type: string;
  series: string;
  number: string;
  issued_date: string;
  issued_by: string;
  first_page: string;
  second_page: string;
  registration_page: string;
  temp_registration_photo: string;
  selfie_with_first_page: string;
  selfie_with_second_page: string;
  residency: string;
  residence_permit: string;
  specialist_visa: string;
  work_permit: string;
  patent: string;

  static isValid(passport: Passport): boolean {
    if(!passport.type || passport.type == '')
      return false;

    let validatableFields = passport.type == 'tempreg'
      ? [ 'temp_registration_photo' ]
      : [ 'series', 'number', 'first_page' ];

    for(let field of validatableFields) {
      let value = passport[field];
      if(value && value != '')
        return true;
    }

    return false;
  }
}
