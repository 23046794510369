import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserStat} from "../_models/user-stat";
import {Order} from "../_models/order";
import {Employer} from "../_models/employer";

export enum DetailTab { Balance, DriverPaymentDetails, TransportDetails, PassportDetails }

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  @Input() user: UserStat;
  @Output() onOpenOrder = new EventEmitter<Order>();
  @ViewChild('balanceTab', { static: true }) balanceTab: ElementRef;
  @ViewChild('driverPaymentDetailsTab', { static: true }) driverPaymentDetailsTab: ElementRef;
  @ViewChild('transportDetailsTab', { static: true }) transportDetailsTab: ElementRef;
  @ViewChild('passportDetailsTab', { static: true }) passportDetailsTab: ElementRef;

  currentTab: DetailTab = DetailTab.Balance;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    $(this.balanceTab.nativeElement).on('shown.bs.tab', () => this.onShowBalanceTab());
    $(this.driverPaymentDetailsTab.nativeElement).on('shown.bs.tab', () => this.onShowDriverPaymentDetailsTab());
    $(this.transportDetailsTab.nativeElement).on('shown.bs.tab', () => this.onShowTransportDetailDetailsTab());
    $(this.passportDetailsTab.nativeElement).on('shown.bs.tab', () => this.onShowPassportDetailDetailsTab());
  }

  isBalanceTabActive(): boolean {
    return this.currentTab === DetailTab.Balance;
  }

  isDriverPaymentDetailsTabActive(): boolean {
    return this.currentTab === DetailTab.DriverPaymentDetails;
  }

  isTransportDetailsTabActive(): boolean {
    return this.currentTab === DetailTab.TransportDetails;
  }

  isPassportDetailsTabActive(): boolean {
    return this.currentTab === DetailTab.PassportDetails;
  }

  hasUserPassport(user: UserStat): boolean {
    return user.account.employer && Employer.hasPassport(user.account.employer);
  }

  onShowBalanceTab(): void {
    this.currentTab = DetailTab.Balance;
    this.changeDetectorRef.detectChanges();
  }

  onShowDriverPaymentDetailsTab(): void {
    this.currentTab = DetailTab.DriverPaymentDetails;
    this.changeDetectorRef.detectChanges();
  }

  onShowTransportDetailDetailsTab(): void {
    this.currentTab = DetailTab.TransportDetails;
    this.changeDetectorRef.detectChanges();
  }

  onShowPassportDetailDetailsTab(): void {
    this.currentTab = DetailTab.PassportDetails;
    this.changeDetectorRef.detectChanges();
  }

  _onOpenOrder(order: Order): void {
    this.onOpenOrder.emit(order);
  }
}
