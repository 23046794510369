import {Component, Input,OnInit} from '@angular/core';
import {noticeInterface} from './notice.interface';
import {noticeTypeInterface} from  './notice.type.interface';
import {buttonInterface} from './button.type';
import {NotificationService} from '../_services/notification.service';
import {Router} from "@angular/router";
import {VoximplantService} from "../_services/voximplant.service";

const CHECK_VISIBLE_TIME = 500;

@Component({
    selector: 'notice',
    templateUrl: './notice.component.html',
    styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit{
  @Input() public item: noticeInterface;

  hidden = true;
  private autoHideTimer: any;

  constructor(private _notificationService: NotificationService, private router: Router, private voximplantService: VoximplantService) {
  }

  ngOnInit(){
    setTimeout(() => {
      this.hidden = false;
      this.initAutoHide();
    }, 250);
  }

  private initAutoHide(): void {
    this.stopAutoHide();

    this.autoHideTimer = setTimeout(() => {
      if (this.item.remove)
        this.remove();
      else
        this.initAutoHide();
    }, CHECK_VISIBLE_TIME);
  }

  private stopAutoHide(): void {
    if(this.autoHideTimer !== null)
      clearTimeout(this.autoHideTimer);

    this.autoHideTimer = null;
  }

  remove() {
    this.hidden = true;
    this.stopAutoHide();
    setTimeout(() => this._notificationService.set(this.item, false), 500);
  }

  acceptOrder($event,orderId: number, freighterId: number)
  {
    $event.stopPropagation();
    this.item.ajax = true;
    this._notificationService.changeOrderStatus(orderId,freighterId,'accepted',this.item);
    this._notificationService.getListener().next({command:'updateListDraftGroupStatuses',orderId:orderId});
  }

  onClickRouteButton(link: string[]) {
    this.remove();
    this.router.navigate(link);
  }

  onCall(phone: string) {
    this.voximplantService.call(phone);
  }
}
