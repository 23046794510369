import {Pipe, PipeTransform} from "@angular/core";
import {SEARCH_TYPES} from "../_maps/search-types";

@Pipe({name: 'searchType'})
export class SearchTypesPipe implements PipeTransform {
  transform(type: string): string {
    return type && SEARCH_TYPES[type] || type;
  }

}
