import {Pipe, PipeTransform} from "@angular/core";
import {OrderDraft} from "../_models/order-draft";
import {DraftUtils} from "../_utils/draft-utils";

@Pipe({name: "draftTransportParameter"})
export class DraftTransportParameterPipe implements PipeTransform {
  transform(draft: OrderDraft, identifier: string): any {
    let value = DraftUtils.getFeatureValue(draft, identifier);
    return value === '' ? null : value;
  }
}
