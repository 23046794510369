import {Component, Input, OnInit} from '@angular/core';

class DriveRow {
  label: string;
  distance: number;
}

class ZoneRow {
  zone: number;
  cost: number;
}

@Component({
  selector: 'driving-stat',
  templateUrl: './driving-stat.component.html',
  styleUrls: ['./driving-stat.component.css']
})
export class DrivingStatComponent implements OnInit {
  @Input() stat: Object;
  driveRows: DriveRow[] = [];
  zoneRows: ZoneRow[] = [];
  sumDistance: number = 0;

  constructor() { }

  ngOnInit() {
    if(this.stat != null) {
      this.initDriveRows();
      this.initZoneRows();

      this.sumDistance = this.stat['sum_distance'] || 0;
    }
  }

  private initZoneRows() {
    if(this.stat['zones'] == null)
      return;

    for(let i in this.stat['zones']) {
      this.addZoneRow(parseInt(i), this.stat['zones'][i]);
    }
  }

  private initDriveRows() {
    if(this.stat['any'])
      this.addDriveRow('За МКАД', this.stat['any']);
    if(this.stat['mkad'])
      this.addDriveRow('По Москве', this.stat['mkad']);
    if(this.stat['ttk'])
      this.addDriveRow('Внутри ТТК', this.stat['ttk']);

    if(this.stat['in_piter_kad'])
      this.addDriveRow('Внутри КАД', this.stat['in_piter_kad']);
    if(this.stat['out_piter_kad'])
      this.addDriveRow('За КАД', this.stat['out_piter_kad']);

    if(this.stat['in_ufa'])
      this.addDriveRow('В пределах Уфы', this.stat['in_ufa']);
    if(this.stat['out_ufa'])
      this.addDriveRow('За пределами Уфы', this.stat['out_ufa']);
  }

  private addDriveRow(label: string, distance: number) {
    let row = new DriveRow();
    row.label = label;
    row.distance = this.distanceForView(distance);

    this.driveRows.push(row);
  }

  private addZoneRow(zone: number, cost: number) {
    let row = new ZoneRow();
    row.zone = zone;
    row.cost = cost;

    this.zoneRows.push(row);
  }

  private distanceForView(distance: number): number {
    return Math.round(distance * 100) / 100;
  }
}
