<div class="modal fade" tabindex="-1" role="dialog" #freightersDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Выбор перевозчиков</h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="filter">
          <form>
            <input type="text" name="searchString" [(ngModel)]="searchString" (ngModelChange)="onChangeSearchString()"
                   class="string" [placeholder]="searchPlaceholder" #searchStringInput>
          </form>
        </div>
        <div class="selected" *ngIf="selectedFreighters.size > 0 || selectedEmployers.size > 0 || selectedCrews.size > 0">
          <ng-template [ngIf]="enabledFreighters">
            <div class="freighter item" *ngFor="let f of selectedFreighters|mapToIterable">
              <span class="name">{{ f.val.name }}</span>
              <span class="remove"><i class="material-icons button" (click)="onDeselectFreighter(f.val)">clear</i></span>
            </div>
          </ng-template>
          <ng-template [ngIf]="enabledEmployers">
            <div class="employer item" *ngFor="let e of selectedEmployers|mapToIterable">
              <span class="name">{{ e.val.account|fullName }}</span>
              <span class="remove" *ngIf="!singleSelectMode"><i class="material-icons button" (click)="onDeselectEmployer(e.val)">clear</i></span>
            </div>
          </ng-template>
          <div class="crew item" *ngFor="let c of selectedCrews|mapToIterable">
            <span class="name">{{ c.val.employer.account.surname }} / {{ c.val.transport|transportDescription }}</span>
            <span class="remove" *ngIf="!singleSelectMode"><i class="material-icons button" (click)="onDeselectCrew(c.val)">clear</i></span>
          </div>
        </div>
        <div class="lists">
          <div class="freighters" *ngIf="freighters.length > 0">
            <div class="title">Компании</div>
            <table class="table table-condensed table-hover table-striped">
              <tr *ngFor="let freighter of freighters" (click)="onSelectFreighter(freighter)">
                <td><input type="checkbox" [checked]="isSelectedFreighter(freighter)"></td>
                <td>{{ freighter.name }}</td>
              </tr>
            </table>
          </div>
          <div class="employers" *ngIf="employers.length > 0">
            <div class="title">Водители</div>
            <table class="table table-condensed table-hover table-striped">
              <tr *ngFor="let employer of employers" (click)="onSelectEmployer(employer)">
                <td><input type="checkbox" [checked]="isSelectedEmployer(employer)"></td>
                <td>
                  <div class="name">{{ employer.account|fullName }}</div>
                  <div class="phone">{{ employer.account.phone|formatPhone }}</div>
                </td>
                <td>{{ employer.freighter.name }}</td>
                <td class="status-{{ employer.status }}">{{ employer.status|employerStatus }}</td>
              </tr>
            </table>
          </div>
          <div class="crews" *ngIf="crews.length > 0">
            <div class="title">Экипажи</div>
            <table class="table table-condensed table-hover table-striped">
              <tr *ngFor="let crew of crews" (click)="onSelectCrew(crew)">
                <td><input type="checkbox" [checked]="isSelectedCrew(crew)"></td>
                <td>
                  <div class="freighter" *ngIf="enabledFreighters">[{{ crew.freighter.name }}]</div>
                  <div class="name">{{ crew.employer.account|fullName }}</div>
                  <div class="phone">{{ crew.employer.account.phone|formatPhone }}</div>
                </td>
                <td class="status-{{ crew.employer.status }}">{{ crew.employer.status|employerStatus }}</td>
                <td>{{ crew.transport|transportDescription }}</td>
                <td>грузчиков: {{ crew.loaders }}<span *ngIf="crew.self_loader" title="сам грузчик">+1</span></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <button class="save-btn" (click)="onSave()">Сохранить</button>
        <button class="reject-btn" (click)="hideDialog()">Отменить</button>
      </div>
    </div>
  </div>
</div>
