
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {firstValueFrom, Observable} from "rxjs";
import {TrackingService} from "../_models/tracking-service";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

@Injectable()
export class TrackingServiceService {
  private availableTrackingServicesPromise: Promise<TrackingService[]>;

  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getAvailableTrackingServices(reload: boolean = false): Observable<TrackingService[]> {
    if(this.availableTrackingServicesPromise == null || reload) {
      this.availableTrackingServicesPromise = firstValueFrom(this._requestService
        .get('/customer/me/tracking_services.json').pipe(
        map(r => r.body as TrackingService[]))
      );
    }
    return fromPromise(this.availableTrackingServicesPromise);
  }
}
