<div class="full-width crew-search-box">
  <input type="text" class="crew-search" name="search" placeholder="Поиск" [ngModel]="crews.filter.search"
         (ngModelChange)="onChangeSearch($event)">
</div>
<div class="options-box">
  <div class="full-width">
    <select class="full-width" name="status" [(ngModel)]="crews.filter.status" (change)="onChangeFilter()">
      <option value="">Любой статус</option>
      <option *ngFor="let v of employerStatuses | mapToIterable" value="{{ v.key }}">{{ v.val }}</option>
    </select>
  </div>
  <div class="full-width options-row mar8top">
    <div>
      <select class="options-select" name="transportBody" [(ngModel)]="crews.filter.transportBody"
              (change)="onChangeFilter()">
        <option value="">Любой кузов</option>
        <option *ngFor="let v of transportBodies | mapToIterable" value="{{ v.key }}">{{ v.val }}</option>
      </select>
    </div>
    <div>
      <select class="options-select" name="transportCapacities" [(ngModel)]="crews.filter.transportCapacity"
              (change)="onChangeFilter()">
        <option value="">Любая грузоподъемность</option>
        <option *ngFor="let v of capacities | mapToIterable" value="{{ v.key }}">{{ v.val }}</option>
      </select>
    </div>
  </div>
</div>
<div class="list" id="list-scrollbar">
  <table class="table">
    <tr *ngFor="let crew of crews.crews;let i = index" (click)="onSelectCrew(crew)"
        [class.selected-driver]="employer && crew.employer.id == employer.id"
    >
      <td class="status-border"
          [class.status-free]="crew.employer.status == 'free'"
          [class.status-work]="crew.employer.status == 'work'"
      >
        <div>{{ crew.employer.account | fullName }}</div>
      </td>
      <td>{{ crew.transport | transportDescription }}</td>
    </tr>
  </table>
</div>
<show-more [list]="crews" (onPage)="onPage($event);saveFilterState()" #showMore></show-more>
