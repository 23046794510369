import {Pipe, PipeTransform} from "@angular/core";
import {ROLES} from "../_maps/roles";

@Pipe({name: "role"})
export class RolePipe implements PipeTransform {
  transform(role: string, unknown: string = 'неизвестно'): string {
    return ROLES[role] || unknown;
  }

}
