<div class="profile__wrapper">
  <div class="profile-container">
    <div class="profile">
      <div class="profile-head">
        <span class="profile__title">Профиль</span>
      </div>
      <div class="profile-items">
        <div class="profile-item">
          <div class="profile-item__title">
            <div *ngIf="company">
              <span class="title">Юр. Лицо</span>
              <div class="profile-item__add legal-entities">
                <div *ngIf="legalEntities.length > 0">
                  <div *ngFor="let entity of legalEntities; let i = index" class="edit" [class.active]="i == 0" [routerLink]="['/legal-entities', entity.id]">
                    <i class="icon icon-abs" *ngIf="userInfoService.isCompanyClientManager() || userInfoService.isPrivilegedUser()"></i>
                    <span class="glyphicon glyphicon-eye-open view" *ngIf="!userInfoService.isCompanyClientManager() && !userInfoService.isPrivilegedUser()"></span>
                    {{ getLegalEntityName(entity.legal_entity) }}
                  </div>
                </div>
                <div class="add" routerLink="/legal-entities/new" *ngIf="userInfoService.isCompanyClientManager() || userInfoService.isPrivilegedUser()">
                  <i class="icon icon-abs icon-profile-item-add"></i>
                  Добавить юр. лицо
                </div>
              </div>
            </div>
            <div *ngIf="company == null">
              <span class="title">Физ. Лицо</span>
              <div class="profile-item__add legal-entities">
                <div class="add" routerLink="/legal-entities/new">
                  <i class="icon icon-abs icon-profile-item-add"></i>
                  Стать юр. лицом
                </div>
              </div>
            </div>
          </div>
          <div class="profile-item__cnt">
            <div class="info-cnt border right">
              <div class="profile-item__company">
                <div class="item-company" *ngIf="company">
                  <span class="label-item">КОМПАНИЯ</span>
                  <span class="info-item">{{ company.name }}</span>
                </div>
                <div class="item-company">
                  <span class="label-item">Баланс</span>
                  <span class="info-item">{{ account.sum|humanNumber }} ₽</span>
                </div>
              </div>
<!--              <span class="profile-item__edit">Редактировать</span>-->
              <div class="profile-item__hint">
                <i class="glyphicon glyphicon-info-sign info-icon"></i>
                <span class="title">
                  <span *ngIf="company">
                    Основная информация о вашем юр. лице, а так же актуальный баланс вашего личного счета.
                  </span>
                  <span *ngIf="!company">
                    Актуальный баланс вашего личного счета.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-table" *ngIf="company && (userInfoService.isCompanyClientManager() || userInfoService.isPrivilegedUser())">
        <table class="profile__table">
          <thead>
          <tr>
            <th>
<!--              <div class="inp-checkbox empty">-->
<!--                <input type="checkbox" name="form_checkbox" id="form_checkbox3">-->
<!--                <label for="form_checkbox3">-->
<!--                  <span class="checkbox__box"></span>-->
<!--                </label>-->
<!--              </div>-->
            </th>
            <th>РОЛЬ</th>
            <th>НОМЕР</th>
            <th>ИМЯ</th>
            <th>EMAIL</th>
            <th>
              ЗАКАЗЫ
            </th>
            <th>
              РЕЙТИНГ
            </th>
            <th>ПОЛУЧАЕТ ЗАКАЗЫ</th>
            <th>АКТИВАЦИЯ</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of companyUsers">
            <td (click)="onEditUser(user)">
<!--              <div class="inp-checkbox empty">-->
<!--                <input type="checkbox" name="form_checkbox" id="form_checkbox4">-->
<!--                <label for="form_checkbox4">-->
<!--                  <span class="checkbox__box"></span>-->
<!--                </label>-->
<!--              </div>-->
            </td>
            <td [ngSwitch]="user.role" (click)="onEditUser(user)">
              <span class="label-table label-1" *ngSwitchCase="'ROLE_DRIVER'">Водитель</span>
              <span class="label-table label-2" *ngSwitchCase="'ROLE_PARTNER_MANAGER'">Менеджер компании</span>
              <span class="label-table label-2" *ngSwitchCase="'ROLE_ADMIN'">Админ</span>
              <span class="label-table label-2" *ngSwitchCase="'ROLE_SUPPORT'">Техподдержка</span>
              <span class="label-table label-2" *ngSwitchCase="'ROLE_DELIVERY_MANAGER'">Менеджер доставки</span>
              <span class="label-table label-2" *ngSwitchCase="'ROLE_COMPANY'">Компания</span>
              <span class="label-table label-2" *ngSwitchDefault>Клиент</span>
            </td>
            <td (click)="onEditUser(user)">
              {{ user.account.phone }}
            </td>
            <td (click)="onEditUser(user)">
              {{ user.account|fullName }}
            </td>
            <td (click)="onEditUser(user)">
              {{ user.account.email }}
            </td>
            <td (click)="onEditUser(user)">
              {{ user.orders_count }}
            </td>
            <td (click)="onEditUser(user)">
              {{ user.account.rating }}
            </td>
            <td>
              <i class="icon icon-profile-table-yes" *ngIf="user.account.main_company_client"></i>
            </td>
            <td (click)="onSwitchUserActivation(user)">
              <div class="edit-table">
                <i class="icon icon-profile-table-yes" *ngIf="user.active" title="Запретить доступ пользователю"></i>
                <div class="glyphicon glyphicon-remove not-active" *ngIf="!user.active" title="Разрешить доступ пользователю"></div>
<!--                <i class="icon icon-profile-table-edit" *ngIf="!user.active"></i>-->
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="profile-item__add border">
                            <span class="add" (click)="onOpenNewUserDialog()">
                                <i class="icon icon-abs icon-profile-item-add"></i>
                                Добавить сотрудника
                            </span>
        </div>
      </div>
      <div class="profile-info" *ngIf="userInfoService.isCompanyClientManager() && marketplaces.length > 0">
        <span class="profile__title">Маркетплейсы</span>
        <div class="profile-table">
          <table class="profile__table">
            <thead>
            <tr>
              <th></th>
              <th>МАРКЕТПЛЕЙС</th>
              <th>ПОДКЛЮЧЕНИЕ</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let market of marketplaces">
              <td></td>
              <td>
                <img [src]="market.logoUrl" width="48" [alt]="market.name" [title]="market.name" *ngIf="market.logoUrl">
                <span *ngIf="!market.logoUrl">{{ market.name }}</span>
              </td>
              <td>
                <span *ngIf="market.isActive">Подключён</span>
                <span *ngIf="!market.isActive" class="connect-to-market" (click)="onConnectToMarketplace(market)">Подключить</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="profile-info">
        <span class="profile__title">Общее</span>
        <div class="profile-items">
          <div class="profile-item">
            <div class="profile-item__title">
              <span class="title">Фотография</span>
            </div>
            <div class="profile-item__cnt">
              <div class="info-cnt border">
                <div class="profile-item__photo">
                  <div class="img-photo" *ngIf="!accountPicture"></div>
                  <div class="img-photo" *ngIf="accountPicture" [style.background-image]="accountPicture"></div>
                  <span class="edit-photo" *ngIf="!uploadPhotoActive" (click)="uploadPhotoActive = !uploadPhotoActive">Изменить</span>
                  <input
                    type="file"
                    *ngIf="uploadPhotoActive"
                    ng2FileSelect
                    [uploader]="uploader"
                    (onFileSelected)="onFileSelected()"
                    accept="image/gif,image/jpeg,image/png"
                  >
                </div>
              </div>
              <div class="profile-item__hint">
                <i class="glyphicon glyphicon-info-sign info-icon"></i>
                <span class="title">
                                            Личные данные пользователя вашей компании в системе MOVER
                                        </span>
              </div>
            </div>
          </div>
          <form (submit)="onSaveProfile()">
          <div class="profile-item">
            <div class="profile-item__title">
              <span class="title">Личные данные</span>
            </div>
            <div class="profile-item__cnt">
              <div class="info-cnt border margin">
                <div class="row">
                  <div class="col col-6">
                    <div class="form-group">
                      <input class="form-control" placeholder="ФИО" name="name" [(ngModel)]="userName">
                    </div>
                  </div>
                  <div class="col col-3">
                    <div class="form-group">
                      <div class="date-control__wrapper form-control date-control">
<!--                        <input placeholder="День рождения" name="birthday" [ngModel]="account.birthday|dt:'dd.MM.yy'">-->
                        <input
                          placeholder="День рождения"
                          name="birthday"
                          [(ngModel)]="account.birthday"
                          type="date"
                        >
<!--                        <i class="icon icon-abs icon-date"></i>-->
                      </div>
                    </div>
                  </div>
                  <div class="col col-3">
                    <div class="form-group">
                      <select class="form-control" [(ngModel)]="account.gender" name="gender">
                        <option value="">Пол</option>
                        <option value="male">Муж</option>
                        <option value="female">Жен</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-item">
            <div class="profile-item__title">
              <span class="title">Номер телефона</span>
            </div>
            <div class="profile-item__cnt">
              <div class="info-cnt border">
                <div class="row">
                  <div class="col col-6">
                    <div class="form-group">
                      <input type="tel" class="form-control" placeholder="Номер телефона" name="phone" [(ngModel)]="account.phone" readonly>
                    </div>
<!--                    <div class="form-group">-->
<!--                      <div class="inp-checkbox">-->
<!--                        <input type="checkbox" name="form_checkbox" id="form_checkbox" checked>-->
<!--                        <label for="form_checkbox">-->
<!--                          <span class="checkbox__box"></span>-->
<!--                          Получать статусы заказов по SMS-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
              <div class="profile-item__hint">
                <i class="glyphicon glyphicon-info-sign info-icon"></i>
                <span class="title">
                                            Ваш контактный номер мобильного телефона. Используется для уведомлений о заказе.
                                        </span>
              </div>
            </div>
          </div>
<!--          <div class="profile-item">-->
<!--            <div class="profile-item__title">-->
<!--              <span class="title">Email</span>-->
<!--            </div>-->
<!--            <div class="profile-item__cnt">-->
<!--              <div class="info-cnt border">-->
<!--                <div class="row">-->
<!--                  <div class="col col-6">-->
<!--                    <div class="form-group">-->
<!--                      <input type="email" class="form-control" placeholder="Ваш Email" name="email" [(ngModel)]="account.email">-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                    <div class="form-group">&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <div class="inp-checkbox">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <input type="checkbox" name="form_checkbox" id="form_checkbox2" checked>&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <label for="form_checkbox2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                          <span class="checkbox__box"></span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                          Получать новостную рассылку&ndash;&gt;-->
<!--                    &lt;!&ndash;                        </label>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="profile-item__hint">-->
<!--                <i class="glyphicon glyphicon-info-sign info-icon"></i>-->
<!--                <span class="title">-->
<!--                                            Основной электронный адрес, служит для отчетов, чеков и закрывающих документов по заказам.-->
<!--                                        </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="profile-item" *ngIf="isEmailsAllowed">
            <div class="profile-item__title">
              <span class="title">Email</span>
            </div>
            <div class="profile-item__cnt">
              <div class="info-cnt border emails">
                <div class="row" *ngFor="let email of emails; let i = index">
                  <div class="col col-6">
                    <div class="form-group" [class.has-error]="emailErrors.has(email.email)">
                      <input type="email" class="form-control" [tabIndex]="i + 100" placeholder="Email" name="email[{{i}}]" [(ngModel)]="email.email" (ngModelChange)="onChangeEmail()">
                    </div>
                  </div>
                  <div class="col col-6" *ngIf="i < emails.length - 1">
                    <button type="button" class="btn btn-remove" title="Удалить e-mail" (click)="onRemoveEmail(i)"><span class="glyphicon glyphicon-trash"></span></button>
                  </div>
                </div>
              </div>
              <div class="profile-item__hint">
                <i class="glyphicon glyphicon-info-sign info-icon"></i>
                <span class="title">
                                          Электронные адреса, служат для отчетов, чеков и закрывающих документов по заказам.
                                      </span>
              </div>
            </div>
          </div>
          <div class="profile-item">
            <div class="profile-item__title">
              <span class="title">Способы оплаты</span>
            </div>
            <div class="profile-item__cnt">
              <div class="info-cnt border">
                <div class="row">
                  <div class="cards">
                    <div class="card" *ngFor="let card of bankCards" [class.expired]="isCardExpired(card)" [class.active]="card.active" [title]="card.active ? 'Активная карта' : ''">
                      <div class="mask">•••• {{ card.mask }}</div>
                      <div class="expiration">{{ card.expire_date }}</div>
                      <div class="icon active-flag" *ngIf="card.active"></div>
                      <div class="icon activate-card" *ngIf="!card.active" (click)="onActivateCard(card)" title="Сделать карту активной"></div>
                      <div class="glyphicon glyphicon-trash remove-card" (click)="onRemoveBankCard(card)" title="Открепить карту"></div>
                    </div>
                    <div class="card new-card" (click)="onAddCard()" title="Прикрепить новую банковскую карту">
                      <div class="add-card"><span class="glyphicon glyphicon-plus"></span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="profile-item__hint">
                <i class="glyphicon glyphicon-info-sign info-icon"></i>
                <span class="title">
                                        Банковские карты. Используются для оплаты исполненных заказов.
                                    </span>
              </div>
            </div>
          </div>
          <div class="profile-item">
            <div class="profile-item__title">
              <span class="title">Промокод</span>
            </div>
            <div class="profile-item__cnt">
              <div class="info-cnt border">
                <div class="row">
                  <div class="col col-6">
                    <promo-code-form
                            (enter)="onEnterPromocode($event)"
                            [autoEnter]="true"
                            [showLoader]="promocodeApplying"
                            [success]="promocodeSuccess"
                            [error]="promocodeError"
                            [promocode]="activePromoCode?.code"
                    ></promo-code-form>
                  </div>
                </div>
              </div>
              <div class="profile-item__hint">
                <i class="glyphicon glyphicon-info-sign info-icon"></i>
                <span class="title">
                                     Введите код, чтобы получить скидку.
                                  </span>
              </div>
            </div>
          </div>
          <div class="profile-item" *ngIf="account.referral_promo">
            <div class="profile-item__title">
              <span class="title">Реферальный промокод</span>
            </div>
            <div class="profile-item__cnt">
              <div class="info-cnt border">
                <div class="row">
                  <div class="col col-3">
                    <span class="referral-promo promo-code" (click)="onClickPromoCode()">{{ account.referral_promo }}</span>
                  </div>
                </div>
              </div>
              <div class="profile-item__hint">
                <i class="glyphicon glyphicon-info-sign info-icon"></i>
                <span class="title">
                                     Делитесь кодом, получайте скидки
                                  </span>
              </div>
            </div>
          </div>
          <div class="profile-controls">
            <div class="profile-save">
              <button class="btn btn-primary" type="submit">Сохранить</button>
            </div>
            <div class="profile-exit">
              <a href="#" (click)="onLogout()" class="link">Выйти из аккаунта</a>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<new-user *ngIf="company && (userInfoService.isCompanyClientManager() || userInfoService.isPrivilegedUser())" (onUserAdded)="onUsersUpdated()"></new-user>
<user-editor *ngIf="company && (userInfoService.isCompanyClientManager() || userInfoService.isPrivilegedUser())"
             (onUserUpdated)="onUsersUpdated()"></user-editor>
