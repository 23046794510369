
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Freighter} from "../_models/freighter";
import {Editable} from "../_models/editable";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {PaymentDistributionSchemaType} from "../_models/payment-distribution-schema-type";
import {PaymentDistributionSchemaService} from "../_services/payment-distribution-schema.service";
import {EXECUTOR_ENTITY_TYPES} from "../_maps/executor-entity-types";
import {HttpErrorResponse} from "@angular/common/http";
import {FormErrors} from "../_models/form-errors";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";

@Component({
  selector: 'payment-distribution-schema-form',
  templateUrl: './payment-distribution-schema-form.component.html',
  styleUrls: ['./payment-distribution-schema-form.component.css']
})
export class PaymentDistributionSchemaFormComponent implements OnInit {
  @Input() freighter: Freighter;
  @Input() schema: Editable<PaymentDistributionSchema>;

  @Output() back = new EventEmitter<void>();
  @Output() saved = new EventEmitter<PaymentDistributionSchema>();

  schemaTypes: PaymentDistributionSchemaType[] = [];
  executorTypes = EXECUTOR_ENTITY_TYPES;

  constructor(private paymentDistributionSchemaService: PaymentDistributionSchemaService,
              private alertService: AlertService,
              private loaderService: LoaderService) { }

  ngOnInit() {
    this.initSchemaTypes();
  }

  private initSchemaTypes(): void {
    this.paymentDistributionSchemaService
      .getSchemaTypes(this.freighter).pipe(
      finalize(() => {
        if(this.schema.isNew && this.schemaTypes.length > 0 && !this.schema.record.schema_type)
          this.schema.record.schema_type = this.schemaTypes[0].identifier;
      }))
      .subscribe(types => {
        this.schemaTypes = types;
      })
    ;
  }

  private add() {
    this.loaderService.show();
    this.schema.isSubmitting = true;

    this.paymentDistributionSchemaService
      .addSchema(this.freighter, this.schema.record).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.schema.isSubmitting = false;
      }))
      .subscribe(
        r => {
          this.alertService.success(`Схема "${this.schema.record.name}" добавлена`, true);
          this.schema.record.id = +r.body.id;
          this.onSaved();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.schema.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  private update() {
    this.loaderService.show();
    this.schema.isSubmitting = true;

    this.paymentDistributionSchemaService
      .updateSchema(this.freighter, this.schema.record).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.schema.isSubmitting = false;
      }))
      .subscribe(
        () => {
          this.alertService.success(`Схема "${this.schema.record.name}" обновлена`, true);
          this.onSaved();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.schema.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  onSubmit(): void {
    if(this.schema.isNew)
      this.add();
    else
      this.update();
  }

  onBack(): void {
    this.back.emit();
  }

  private onSaved(): void {
    this.saved.emit(this.schema.record);
  }
}
