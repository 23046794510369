import {Component, OnDestroy, OnInit} from '@angular/core';
import {noticeInterface} from './notice.interface';
import {taxiNotice} from './types/taxi-notice'
import {NotificationService} from '../_services/notification.service'
import {OrderService} from '../_services/order.service';
import {NotificationEvent} from './notification-event'
import {Subscription} from "rxjs";
import {Order} from "../_models/order";
import {UserInfoService} from "../_services/user-info.service";

const AUTO_HIDE_DELAY = 5000;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notifications: noticeInterface[];
  isSupportNotificationsEnabled: boolean;

  private listener: Subscription;
  private autoHideTimer: any;

  constructor(public _notificationService: NotificationService, private _orderService: OrderService, userInfoService: UserInfoService) {
    this.isSupportNotificationsEnabled = userInfoService.isPrivilegedUser();
  }

  ngOnInit() {
    this.notifications = this._notificationService.getNotificationsList();
    this.listener = this._notificationService.getListener()
      .subscribe(item => {
        switch (item.command) {
          case 'set':
            if (item.add) {
              this.add(item.notice);
            } else {
              this.notifications.splice(this.notifications.indexOf(item.notice), 1);
              this._notificationService.saveNotifications();
            }
            break;
          case 'changeOrderStatus':
            this.changeOrderStatus(item);
            break;
          case 'markAsNoAnswer':
            this.markAsNoAnswer(item);
        }
        this._notificationService.refreshAttentions();
      });

    this.initAutoHide();
  }

  private initAutoHide() {
    this.stopAutoHide();

    this.autoHideTimer = setInterval(() => this.doAutoHide(), AUTO_HIDE_DELAY);
  }

  private stopAutoHide() {
    if(this.autoHideTimer !== null)
      clearInterval(this.autoHideTimer);

    this.autoHideTimer = null;
  }

  private doAutoHide() {
    for (let n = this.notifications.length - 1; n >= 0; n --) {
      let notice = this.notifications[n];
      if(notice.autoHide && new Date().getTime() - notice.dateCreated.getTime() >= AUTO_HIDE_DELAY) {
        notice.remove = true;
        break;
      }
    }
  }

  clearAll() {
    this.notifications.length = 0;
  }

  add(item: noticeInterface) {
    item.dateCreated = new Date();

    let temp = [];
    temp.push(...this.notifications);

    let inserted = false;
    for(let n = 0, m = 0, l = temp.length; m < l; n ++, m ++) {
      let currentNotice = temp[m];
      if(!currentNotice.important && !inserted) {
        this.notifications[n ++] = item;
        inserted = true;
      }
      this.notifications[n] = currentNotice;
    }

    if (!inserted) {
      if(item.important)
        this.notifications.unshift(item);
      else
        this.notifications.push(item);
    }

    this._notificationService.saveNotifications();
  }

  changeOrderStatus(item: NotificationEvent) {
    this._orderService.getOrder(item.freighterId, item.orderId)
      .subscribe(order => {
        let notificationList = this.notifications;
        let noticePosition = notificationList.indexOf(item.notice);
        let notice = notificationList[noticePosition];
        console.log(order.status);
        if (order.status != 'agree') {
          notice.statusError = true;
          notice.bodyText = 'Заказ не находится в согласовании';
          setTimeout(() => {
            notificationList.splice(notificationList.indexOf(item.notice), 1);
            this._notificationService.saveNotifications();
          }, 3000)

        }
        else {
          this.updateOrderStatusAction(item, order);
        }
        this._notificationService.getListener().next({command: 'removeDraftGroup', notice: item.notice})
      })
  }

  updateOrderStatusAction(item: NotificationEvent, order: Order) {
    this._orderService.updateOrderStatus(order, item.status)
      .subscribe(update => {
        let notificationList = this.notifications;
        let noticePosition = notificationList.indexOf(item.notice);
        let notice = notificationList[noticePosition];
        notice.ajax = false;
        notice.ajaxDone = true;
        setTimeout(() => {
          notificationList.splice(notificationList.indexOf(item.notice), 1)
          this._notificationService.saveNotifications();
        }, 500)
      });
  }

  markAsNoAnswer(item: NotificationEvent) {
    var notice: noticeInterface;
    var noticeArr = this.notifications.filter(notice => notice.order.id == item.orderId);
    if (noticeArr.length > 0) {
      notice = noticeArr[0];
    }
    else {
      return false
    }
    notice.bodyText = 'Вы не подтвердили заказ. Заказ отменён.';
    notice.noAnswer = true;
    this._notificationService.getListener().next({command:'removeDraftGroup',notice: notice})
  }

  private hideAll(): void {
    for(let notice of this.notifications)
      notice.remove = true;
  }

  onHideAll(): void {
    this.hideAll();
  }

  ngOnDestroy(): void {
    this.stopAutoHide();
  }
}
