import {PipeTransform, Pipe} from "@angular/core";

@Pipe({name: 'fullName'})
export class FullNamePipe implements PipeTransform {
  transform(value: any, defaultValue: string = ''): string {
    if(!value)
      return '-';

    let name = [ value.surname, value.name, value.patronymic ];

    let strName = name.filter(name => name != null).join(' ').trim();
    return strName === '' ? defaultValue : strName;
  }
}
