
import {of as observableOf, Subject, Subscription, asyncScheduler, firstValueFrom} from 'rxjs';

import {catchError, switchMap, distinctUntilChanged, debounceTime, finalize, map, throttleTime} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { OrderDraft } from "../_models/order-draft";
import { OrderDraftService } from "../_services/order-draft.service";
import { LoaderService } from "../_services/loader.service";
import { UserInfoService } from "../_services/user-info.service";
import { WebSocketService } from "../_services/web-socket.service";
import { OrdersConnection } from "../_websocket/connections/orders-connection";
import { Message } from "../_websocket/messages/message";
import { OrderChanged } from "../_websocket/messages/order-changed";
import { DraftsList } from "../_models/drafts-list";
import { Page } from "../pager/page";
import { SEARCH_STATUSES_GROUPS } from "../_maps/search-statuses";
import { DraftListFilter } from "../_models/draft-list-filter";
import { ObjectComparator } from "../_utils/ObjComparator";
import { State } from "../_storage/state";
import {DraftUtils} from "../_utils/draft-utils";
import {CityService} from "../_services/city.service";
import {CompanyClient} from "../_models/company-client";
import {CompanyClientService} from "../_services/company-client.service";
import {TrackingServiceService} from "../_services/tracking-service.service";
import {TrackingService} from "../_models/tracking-service";
import {HttpResponse} from "@angular/common/http";

const PAGE_STORAGE = "drafts_list_page";
const FILTER_STORAGE = "drafts_list_filter";

class ListCommand {
  constructor(
    private _filter?: DraftListFilter,
    private _page?: number,
    private _source?: string,
    private _force?: boolean
  ) {}

  get filter(): DraftListFilter {
    return this._filter;
  }

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  get force(): boolean {
    return this._force;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: "app-order-drafts",
  templateUrl: "./order-drafts.component.html",
  styleUrls: ["./order-drafts.component.css"]
})
export class OrderDraftsComponent implements OnInit, OnDestroy {
  drafts: DraftsList = DraftsList.empty();
  searchStatusGroups = Object.assign(
    {
      all: {
        name: "Все",
        statuses: []
      }
    },
    SEARCH_STATUSES_GROUPS
  );
  searchStatusGroupValues = Object.keys(this.searchStatusGroups);

  private listCommands = new Subject<ListCommand>();
  private oldFilter = new DraftListFilter();
  private ordersConnection: OrdersConnection;
  searchStatusGroup = "all";
  after: Date;
  before: Date;
  scheduledAfter: Date;
  scheduledBefore: Date;
  client: string;
  private updateDraftsStream = new Subject<number>();
  draftId: number;
  search: string;
  companyClientFilter = '0';
  companyClients: CompanyClient[] = [];
  trackNumberFilter: string;
  cargoCodeFilter: string;
  isFreighter = false;

  public countRepeater: number;
  public showCopyButton = false;
  public rowIndexCount: number;

  rolledUp = false;

  afterDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'от'
  };

  beforeDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'до'
  };

  scheduledAfterDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'от'
  };

  scheduledBeforeDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'до'
  };

  private changeCitySubscription: Subscription;

  private documentVisibilityHandler: any;

  private hiddenDocumentDraftsBuffer: number[] = [];

  private trackingServicesMap = new Map<string, TrackingService>();

  constructor(
    private draftsService: OrderDraftService,
    public userInfo: UserInfoService,
    private webSocketService: WebSocketService,
    private loaderService: LoaderService,
    private cityService: CityService,
    private companyClientService: CompanyClientService,
    private trackingServiceService: TrackingServiceService
  ) {}

  ngOnInit() {
    this.isFreighter = this.userInfo.isFreighter();

    this.initCityFilter();
    this.restoreFilter();
    this.restorePage();
    this.initDrafts();
    this.initUpdateDraftsStream();
    this.initCompanyClients();
    this.initTrackingServices();
    if(this.cityService.isActiveCityLoaded)
      this.loadDrafts(this.drafts.page, this.drafts.filter);

    this.initOrdersConnection();

    this.initDocumentVisibilityChange();
  }

  private initTrackingServices(): void {
    this.trackingServicesMap.clear();

    this.loaderService.show();
    this.trackingServiceService
      .getAvailableTrackingServices().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        s => {
          for(let service of s)
            this.trackingServicesMap.set(service.identifier, service);
        },
        () => {}
      )
    ;
  }

  private initCompanyClients() {
    if(!this.userInfo.isPrivilegedUser())
      return;

    this.loaderService.show();
    this.companyClientService
      .getCompanyClients().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        companies => this.companyClients = companies,
        () => {}
      )
    ;
  }

  initDocumentVisibilityChange() {
    this.documentVisibilityHandler = () => {
      if(!document.hidden)
        this.onDocumentVisible();
    }

    document.addEventListener('visibilitychange', this.documentVisibilityHandler);
  }

  isScheduled(draft: OrderDraft) {
    return draft.destinations.length > 0
      && (draft.destinations[0].arrival_time != null || draft.destinations[0].arrival_time_end != null)
      || draft.start_delivery_search_at != null
      ;
  }

  getSchedule(draft: OrderDraft): string | null {
    if(draft.start_delivery_search_at)
      return draft.start_delivery_search_at;

    if (draft.destinations.length < 1) return null;

    return draft.destinations[0].arrival_time || draft.destinations[0].arrival_time_end;
  }

  private initOrdersConnection() {
    this.ordersConnection = this.webSocketService.createOrdersConnection();
    this.ordersConnection.start();
    this.ordersConnection.message.subscribe(m =>
      this.onOrdersConnectionMessage(m)
    );
  }

  private initDrafts() {
    this.listCommands = new Subject<ListCommand>();
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        return this.draftsService
          .getDrafts(command.page, command.filter)
          .pipe(
            map(list => [list, command]),
            finalize(() => this.loaderService.hide())
          );
      })
    ).subscribe({
      next: listAndCommand => {
        let list = listAndCommand[0] as DraftsList;
        let command = listAndCommand[1] as ListCommand;

        if (
          command?.source == "button" &&
          ObjectComparator.Compare(this.oldFilter, list.filter)
        ) {
          this.drafts = list.concat(this.drafts.drafts);
        } else {
          this.drafts = list;
        }

        this.saveFilter();
        this.savePage();
        // this.refreshFilterFields();
      },
      error: () => this.initDrafts()
    });
  }

  private initUpdateDraftsStream(): void {
    this.updateDraftsStream = new Subject<number>();
    this.updateDraftsStream.pipe(
      throttleTime(10000, asyncScheduler, {
        leading: false,
        trailing: true
      }),
      switchMap(draftId => {
        this.loaderService.show();
        return this.draftsService.getDraft(draftId).pipe(finalize(() => this.loaderService.hide()));
      })
    ).subscribe({
      next: draft => {
        for (let num in this.drafts.drafts) {
          if (this.drafts.drafts[num].id == draft.id) {
            this.drafts.drafts[num] = draft;
          }
        }
      },
      error: () => this.initUpdateDraftsStream()
    });
  }

  private loadDrafts(page?: number, filter?: DraftListFilter, source?: string, force?: boolean) {
    this.listCommands.next(new ListCommand(filter?.clone(), page, source, force));
  }

  private initCityFilter(): void {
    this.changeCitySubscription = this.cityService.createChangeActiveCityObservable()
      .subscribe(
        () => this.listCommands.next(new ListCommand(this.drafts.filter.clone(), null, null, true))
      );
  }

  private onOrdersConnectionMessage(message: Message) {
    if (message instanceof OrderChanged) {
      this.loadDraftForUpdateIf(message.draftId);
    }
  }

  private loadDraftForUpdateIf(id: number) {
    for (let draft of this.drafts.drafts) {
      if (draft.id == id) {
        if(document.hidden)
          this.hiddenDocumentDraftsBuffer.push(id)
        else
          this.updateDraftsStream.next(id);
      }
    }
  }

  isTest(draft: OrderDraft): boolean {
    return DraftUtils.isTest(draft);
  }

  trackingServiceIdentifierToName(trackingServiceIdentifier: string): string {
    return this.trackingServicesMap.has(trackingServiceIdentifier)
      ? this.trackingServicesMap.get(trackingServiceIdentifier).name
      : trackingServiceIdentifier;
  }

  ngOnDestroy(): void {
    this.changeCitySubscription.unsubscribe();

    if (!this.userInfo.isDeliveryManager()) return;

    this.ordersConnection.close();

    document.removeEventListener('visibilitychange', this.documentVisibilityHandler);
  }

  onPaginationPage(page: Page) {
    this.loadDrafts(page.num, this.drafts.filter, "pager");
  }

  onShowMorePage(page: Page) {
    this.loadDrafts(page.num, this.drafts.filter, "button");
  }

  onChangeFilter() {
    // console.log(this.client);

    let filter = new DraftListFilter(
      this.searchStatusGroup,
      this.after,
      this.before,
      this.client,
      this.draftId,
      this.search,
      this.companyClientFilter == '0' ? undefined : parseInt(this.companyClientFilter),
      this.scheduledAfter,
      this.scheduledBefore,
      this.trackNumberFilter,
      this.cargoCodeFilter
    );
    if(!filter.equals(this.oldFilter))
      this.loadDrafts(0, filter);
  }

  onCancelScheduledDraft(draft: OrderDraft) {
    this.loaderService.show();
    this.draftsService
      .getDraft(draft.id).pipe(
        finalize(() => this.loaderService.hide()),
        map(draft => {
          if (draft.destinations.length > 0)
            draft.destinations[0].arrival_time = null;

          return draft;
        }),
        switchMap(draft => this.draftsService.updateDraft(draft))
      )
      .subscribe({
        next: () => {
          draft.destinations[0].arrival_time = null;
        },
        error: () => {}
      });
  }


  showCopy(i) {
    this.rowIndexCount = i;
    this.showCopyButton = true;
    this.countRepeater = 1;
  }

  onMultiCopy(draft) {
    if(this.countRepeater == 0)
      return;

    this.loaderService.show();
    firstValueFrom(this.draftsService.getDraft(draft.id))
      .then(draft => {
        let draftPromise: Promise<HttpResponse<any>>;
        for(let i = 0; i < this.countRepeater; i ++) {
          let promise = firstValueFrom(this.draftsService.addDraft(draft));
          draftPromise = i == 0 ? promise : draftPromise.then(() => promise);
        }
        draftPromise
          .then(() => this.loaderService.hide())
          .then(() => { this.countRepeater = 0; this.showCopyButton = false; })
          .then(() => this.loadDrafts(null, null, null, true))
          .catch(() => {})
      })
    ;
  }

  private onDocumentVisible(): void {
    console.log('document is visible');
    for (let draftId of this.hiddenDocumentDraftsBuffer)
      this.loadDraftForUpdateIf(draftId);

    this.hiddenDocumentDraftsBuffer = [];
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.drafts.page.toString());
  }

  private restorePage() {
    this.drafts.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  private saveFilter() {
    sessionStorage.setItem(FILTER_STORAGE, this.drafts.filter.getState().state);

    this.oldFilter = this.drafts.filter;
  }

  private restoreFilter() {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if (state) this.drafts.filter = DraftListFilter.fromState(new State(state));

    this.refreshFilterFields();

    this.oldFilter = this.drafts.filter;
  }

  private refreshFilterFields(): void {
    this.searchStatusGroup = this.drafts.filter.searchStatus || "all";
    this.after = this.drafts.filter.after;
    this.before = this.drafts.filter.before;
    this.client = this.drafts.filter.client;
    this.draftId = this.drafts.filter.draftId;
    this.search = this.drafts.filter.search;
    this.companyClientFilter = this.drafts.filter.companyClient ? this.drafts.filter.companyClient + '' : '0';
    this.scheduledAfter = this.drafts.filter.scheduledAfter;
    this.scheduledBefore = this.drafts.filter.scheduledBefore;
    this.trackNumberFilter = this.drafts.filter.trackNumber;
    this.cargoCodeFilter = this.drafts.filter.cargoCode;
  }

  private cleanFilter(): void {
    this.drafts = DraftsList.empty();
    this.refreshFilterFields();
    this.listCommands.next(new ListCommand(new DraftListFilter(), 0));
  }

  onCleanFilter() {
    this.cleanFilter();
  }
}
