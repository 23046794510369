import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "formatCost"})
export class FormatCostPipe implements PipeTransform {
  transform(value?: number): string {
    if(value == null)
      return '';

    let cost = Math.round(value * 100) / 100;
    return cost + ' р.';
  }
}
