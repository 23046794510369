export class GeoUtils {
  static calcDistance(p1Lat: number, p1Lng: number,  p2Lat: number, p2Lng: number): number {
    if(GeoUtils.isEqual(p1Lat, p1Lng, p2Lat, p2Lng))
      return 0;

    const rad2deg = (rad:number):number => rad * 180 / Math.PI;

    const deg2rad = (deg:number):number => deg * Math.PI / 180

    const getDistance = (p1Lat: number, p1Lng: number,  p2Lat: number, p2Lng: number):number => {
      const degrees = rad2deg(Math.acos((Math.sin(deg2rad(p1Lat)) * Math.sin(deg2rad(p2Lat))) + (Math.cos(deg2rad(p1Lat)) * Math.cos(deg2rad(p2Lat)) * Math.cos(deg2rad(p1Lng - p2Lng)))));
      return isNaN(degrees) ? 0: degrees * 111.13384;
    }

    return getDistance(p1Lat, p1Lng, p2Lat, p2Lng);
  }

  private static isEqual(p1Lat: number, p1Lng: number, p2Lat: number, p2Lng: number, roundTo: number = 8): boolean {
    let multiplier = 10 ** roundTo;
    return (
      Math.round(p1Lat * multiplier) / multiplier == Math.round(p2Lat * multiplier) / multiplier &&
      Math.round(p1Lng * multiplier) / multiplier == Math.round(p2Lng * multiplier) / multiplier
    );
  }
}
