import {Pipe} from "@angular/core";
import {City} from "../_models/city";
import {DateUtils} from "../_utils/date-utils";
import {DateTime} from "date-time-js";
import {TzPipe} from "./tz-pipe";

/**
 * Преобразует дату с учётом часового пояса города
 */
@Pipe({name: "withCityTz"})
export class WithCityTzPipe extends TzPipe {
  transform(dateTime: string|null, city: City|null, disable = false): string {
    if(disable || !city || !dateTime || city.time_zone == this.userCityTz)
      return dateTime;

    let cityOffset = DateUtils.cityTimeZoneToOffset(city.time_zone);
    let offsetDiff = cityOffset - this.userTzOffset;
    return new DateTime(new Date(dateTime)).add(offsetDiff, 'minute').toDate().toString();
  }
}
