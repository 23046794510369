export class UploadStatus {
  constructor(
    private _identifier: string,
    private _currentUploadNumber: number,
    private _allUploadCount: number
  ) {
  }

  get currentUploadNumber(): number {
    return this._currentUploadNumber;
  }

  get allUploadCount(): number {
    return this._allUploadCount;
  }

  get identifier(): string {
    return this._identifier;
  }
}
