import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompanyClient} from "../_models/company-client";
import {LegalEntity} from "../_models/legal-entity";
import {LegalEntityService} from "../_services/legal-entity.service";
import {LoaderService} from "../_services/loader.service";
import {CompanyClientLegalEntity} from "../_models/company-client-legal-entity";

@Component({
  selector: 'legal-entity-editor',
  templateUrl: './legal-entity-editor.component.html',
  styleUrls: ['./legal-entity-editor.component.css']
})
export class LegalEntityEditorComponent implements OnInit {
  @Input() company: CompanyClient;
  @Input() entity: LegalEntity;
  @Output() entityChange = new EventEmitter<LegalEntity>();

  entities: LegalEntity[] = [];
  selected: number;

  constructor(private legalEntityService: LegalEntityService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.selected = this.entity ? this.entity.id : null;
    this.loadEntities();
  }

  private loadEntities() {
    this.legalEntityService
      .getCompanyLegalEntities(this.company.id)
      .subscribe(
        e => {
          this.entities = this.swapEntities(e);
          this.initSelection();
        },
        () => {}
      )
    ;
  }

  private initSelection() {
    if(this.selected != null || this.entities.length == 0)
      return;

    let activeEntity = this.entities.find(e => e.company_client_legal_entity.active);
    if(activeEntity)
      this.selected = activeEntity.id;
    else
      this.selected = this.entities[0].id;
  }

  private swapEntities(companyClientEntities: CompanyClientLegalEntity[]): LegalEntity[] {
    let result: LegalEntity[] = [];
    for(let cce of companyClientEntities) {
      result.push(cce.legal_entity);
      cce.legal_entity.company_client_legal_entity = cce;
      cce.legal_entity = null;
    }
    return result;
  }

  onSave() {
    if(!this.selected) {
      this.entityChange.emit(null);
      return;
    }

    let selected = this.entities.find(e => e.id == this.selected);
    if(selected)
      this.entityChange.emit(selected);
  }
}
