import {Pipe, PipeTransform} from "@angular/core";
import {OrderDraft} from "../_models/order-draft";
import {DraftUtils} from "../_utils/draft-utils";
import {TRANSPORT_BODIES} from "../_maps/transport-bodies";

@Pipe({name: "draftBodyTypes"})
export class DraftBodyTypesPipe implements PipeTransform {
  transform(draft: OrderDraft): string {
    return DraftUtils.getBodyTypes(draft).map(i => TRANSPORT_BODIES[i] || i).join(', ') || 'любые';
  }
}
