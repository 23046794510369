import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AudioPlayerService} from "./service/audio-player.service";
import {PlayData} from "./model/play-data";
import {Subscription} from "rxjs";
import {Track} from "./model/track";

declare var APlayer: any;

@Component({
  selector: 'audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css']
})
export class AudioPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('audioPlayerDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild('audioPlayer', { static: true }) playerEl: ElementRef;

  private playSubscription: Subscription;
  private initialized = false;
  private player: any;

  constructor(private audioPlayerService: AudioPlayerService) {
  }

  ngOnInit() {
    this.playSubscription = this.audioPlayerService.getPlayObservable().subscribe(d => this.onPlayFromService(d));
  }

  private showDialog() {
    $(this.dialogEl.nativeElement).modal('show');
  }

  private initPlayerIf(): void {
    if(!this.initialized)
      this.initPlayer();
  }

  private initPlayer(): void {
    this.player = new APlayer({
      container: this.playerEl.nativeElement,
      loop: 'none'
    });

    $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => this.onHideDialog());

    this.initialized = true;
  }

  private setupPlayList(tracks: Track[]): void {
    this.player.list.clear();
    this.player.list.add(tracks.map(t => {
      return {
        name: t.name,
        artist: t.artist,
        url: t.url,
        cover: t.cover
      }
    }));
  }

  private onPlayFromService(playData: PlayData): void {
    this.initPlayerIf();
    this.showDialog();
    this.setupPlayList(playData.tracks);
    this.player.list.switch(playData.trackNum);
    this.player.play();
  }

  private onHideDialog(): void {
    this.player.pause();
    this.player.list.clear();
  }

  ngOnDestroy(): void {
    this.playSubscription.unsubscribe();
  }
}
