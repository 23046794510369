export const TRANSPORT_BODIES = {
  "Tent":"Тент",
  "Evrotent":"Евротент",
  "Furgon":"Фургон",
  "Bortovaya":"Бортовая",
  "Fermer_tent":"Фермер тент",
  "Fermer_furgon":"Фермер фургон",
  "Izotermicheskij":"Изотермический",
  "Czel_nometallicheskij":"Цельнометаллический",
  "Refrezherator":"Рефрижератор",
  "Piramida":"Пирамида",
  "Katyusha":"Катюша",
  "Udlinennaya":"Удлиненная",
  "Manipulyator":"Манипулятор",
  "Fermer_bort":"Фермер борт",
  "Kabluk": "Каблук",
  "Fermer_Czel_nometallicheskij": "Фермер цельнометаллический"
};
