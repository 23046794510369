<form (submit)="onSave()">
  <div class="form-group">
    <label for="name">Название тарифа</label>
    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="tariff.name" required>
  </div>
  <div class="form-group">
    <label for="min_price">Минимальная стоимость</label>
    <input type="text" class="form-control" id="min_price" name="min_price" [(ngModel)]="tariff.min_price" required>
  </div>
  <div class="form-group">
    <label for="min_hours">Минимальное количество часов</label>
    <input type="text" class="form-control" id="min_hours" name="min_hours" [(ngModel)]="tariff.min_hours" required>
  </div>
  <div class="form-group">
    <label for="after_mkad_km_price">Стоимость километра за МКАД</label>
    <input type="text" class="form-control" id="after_mkad_km_price" name="after_mkad_km_price" [(ngModel)]="tariff.after_mkad_km_price" required>
  </div>
  <div class="form-group">
    <label for="additional_hour_price">Стоимость дополнительного часа</label>
    <input type="text" class="form-control" id="additional_hour_price" name="additional_hour_price" [(ngModel)]="tariff.additional_hour_price" required>
  </div>
  <div class="form-group">
    <label for="ttk_price">Стоимость въезда в ТТК</label>
    <input type="text" class="form-control" id="ttk_price" name="ttk_price" [(ngModel)]="tariff.ttk_price" required>
  </div>
  <div class="form-group">
    <label for="hydroelevator_hour_price">Стоимость гидролифта в час</label>
    <input type="text" class="form-control" id="hydroelevator_hour_price" name="hydroelevator_hour_price" [(ngModel)]="tariff.hydroelevator_hour_price" required>
  </div>
  <button type="submit" class="btn btn-primary">Сохранить</button>
  <a class="btn btn-link" routerLink="/tariffs/transport">Отменить</a>
</form>
