import { Component, OnInit } from '@angular/core';
import {Editable} from "../_models/editable";
import {Employee} from "../_models/employees/employee";
import {FormErrors} from "../_models/form-errors";
import {Freighter} from "../_models/freighter";
import {UserInfoService} from "../_services/user-info.service";
import {Router} from "@angular/router";
import {Phone} from "../_models/employees/phone";
import {Passport} from "../_models/passport";
import {DriverLicense} from "../_models/driver-license";
import {LegalEntity} from "../_models/legal-entity";
import {EmployeeIndividual} from "../_models/employees/employee-individual";
import {EmployeeDevice} from "../_models/employees/employee-device";
import {Account} from "../_models/account";
import {Employer} from "../_models/employer";
import {TaxpayerCertificate} from "../_models/taxpayer-certificate";

@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.css']
})
export class NewEmployeeComponent implements OnInit {
  employee = new Editable(new Employee(), new FormErrors(), true);
  freighter: Freighter;

  constructor(
    private userInfoService: UserInfoService,
    private router: Router
  ) {
    this.initEmployee();
  }

  private initEmployee(): void {
    let e = this.employee.record;

    e.main_phone = new Phone();
    e.additional_phone = new Phone();
    e.passport = new Passport();
    e.driver_license = new DriverLicense();
    e.legal_entity = new LegalEntity();
    e.individual = new EmployeeIndividual();
    e.device = new EmployeeDevice();
    e.taxpayer_certificate = new TaxpayerCertificate();
    e.account = new Account();
    e.account.employer = new Employer();
    e.account.employer.features = {};

    e.active = true;
  }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();
    this.employee.record.freighter = this.freighter;
  }

  onSaved(employee: Employee): void {
    this.router.navigate([`/employees`, employee.account.employer.id]);
  }

  onBackToList(): void {
    this.router.navigate([`/employees`]);
  }
}
