import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {MapPosition} from "../map/map-position";
import {Order} from "../_models/order";
import {MapTransport} from "../_models/map-transport";
import {Point} from "../_models/point";
import {OrderDraft} from "../_models/order-draft";
import {SubsearchState} from "../_models/subsearch-state";
import {Crew} from "../_models/search-state/crew";
import {DisplayedMarker} from "../_models/map/displayed.marker";
import {DisplayedClusterArea} from "../_models/map/displayed-cluster-area";
import {Route} from "../_models/map/route";
import {GOOGLE_MAP_API_KEY} from "../../environments/configuration";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class MapService {
  private visibilityStream = new Subject<boolean>();
  private mapPositionStream = new Subject<MapPosition>();
  private ordersStream = new Subject<Order>();
  private draftsStream = new Subject<OrderDraft>();
  private cleanStream = new Subject<void>();
  private transportsStream = new Subject<MapTransport[]>();
  private selectTransportStream = new Subject<MapTransport>();
  private showTrackStream = new Subject<boolean>();
  private mapClickStream = new Subject<Point>();
  private mapBoundsChangeStream = new Subject<google.maps.LatLngBounds>();
  private searchStateStream = new Subject<SubsearchState | null>()
  private foundCrewsStream = new Subject<Crew | null>();
  private displayedMarkersStream = new Subject<DisplayedMarker[]>();
  private displayedClusterAreasStream = new Subject<DisplayedClusterArea[]>();
  private routesStream = new Subject<Route[]>();
  private displayedMarkersClicksStream = new Subject<number>();

  private mapBounds: google.maps.LatLngBounds;

  private isMapLoading = false;
  private _isInit = false;

  constructor(private httpClient: HttpClient) {
  }

  initMap(): void {
    if(this._isInit || this.isMapLoading)
      return;

    this.isMapLoading = true;
    let mapApiUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
    console.log(`Google maps api (${mapApiUrl}) is loading...`);
    this.httpClient
      .jsonp(mapApiUrl, 'callback')
      .subscribe(
        () => {
          console.log('Google maps api is loaded');
          this.isMapLoading = false;
          this._isInit = true;
        }
      );
  }

  get isInit(): boolean {
    return this._isInit;
  }

  getVisibilityObservable(): Observable<boolean> {
    return this.visibilityStream.asObservable();
  }

  getMapPositionObservable(): Observable<MapPosition> {
    return this.mapPositionStream.asObservable();
  }

  getCleanObservable(): Observable<void> {
    return this.cleanStream.asObservable();
  }

  getOrderObservable(): Observable<Order> {
    return this.ordersStream.asObservable();
  }

  getDraftObservable(): Observable<OrderDraft> {
    return this.draftsStream.asObservable();
  }

  getTransportsObservable(): Observable<MapTransport[]> {
    return this.transportsStream.asObservable();
  }

  getSelectTransportObservable(): Observable<MapTransport> {
    return this.selectTransportStream.asObservable();
  }

  getShowTrackObservable(): Observable<boolean> {
    return this.showTrackStream.asObservable();
  }

  getMapClickObservable(): Observable<Point> {
    return this.mapClickStream.asObservable();
  }

  getMapBoundsChangeObservable(): Observable<google.maps.LatLngBounds> {
    return this.mapBoundsChangeStream.asObservable();
  }

  getSearchStateObservable(): Observable<SubsearchState | null> {
    return this.searchStateStream.asObservable();
  }

  getFoundCrewsObservable(): Observable<Crew | null> {
    return this.foundCrewsStream.asObservable();
  }

  getDisplayedMarkersObservable(): Observable<DisplayedMarker[]> {
    return this.displayedMarkersStream.asObservable();
  }

  getDisplayedClusterAreasObservable(): Observable<DisplayedClusterArea[]> {
    return this.displayedClusterAreasStream.asObservable();
  }

  getRoutesObservable(): Observable<Route[]> {
    return this.routesStream.asObservable();
  }

  getDisplayedMarkersClicksObservable(): Observable<number> {
    return this.displayedMarkersClicksStream.asObservable();
  }

  getMapBounds(): google.maps.LatLngBounds {
    return this.mapBounds;
  }

  showMap(): void {
    this.visibilityStream.next(true);
  }

  hideMap(): void {
    this.visibilityStream.next(false);
  }

  clean(): void {
    this.cleanStream.next();
  }

  setMapPosition(lat: number, lon: number, zoom?: number): void {
    this.mapPositionStream.next(new MapPosition(lat, lon, zoom));
  }

  displayOrder(order: Order): void {
    this.ordersStream.next(order);
  }

  displayDraft(draft: OrderDraft): void {
    this.draftsStream.next(draft);
  }

  displayTransports(transports: MapTransport[]): void {
    this.transportsStream.next(transports);
  }

  removeTransports(): void {
    this.displayTransports([]);
  }

  showTrack(): void {
    this.showTrackStream.next(true);
  }

  hideTrack(): void {
    this.showTrackStream.next(false);
  }

  dispatchMapClick(point: Point): void {
    this.mapClickStream.next(point);
  }

  dispatchMapBoundsChange(bounds: google.maps.LatLngBounds): void {
    this.mapBounds = bounds;
    this.mapBoundsChangeStream.next(bounds);
  }

  dispatchDisplayedMarkerClick(id: number): void {
    this.displayedMarkersClicksStream.next(id);
  }

  displaySearchState(state: SubsearchState): void {
    this.searchStateStream.next(state);
  }

  hideSearchState(): void {
    this.searchStateStream.next(null);
  }

  addFoundCrew(crew: Crew): void {
    this.foundCrewsStream.next(crew);
  }

  selectTransport(car: MapTransport): void {
    this.selectTransportStream.next(car);
  }

  cleanFoundCrews(): void {
    this.foundCrewsStream.next(null);
  }

  displayMarkers(markers: DisplayedMarker[]): void {
    this.displayedMarkersStream.next(markers);
  }

  displayClusterAreas(areas: DisplayedClusterArea[]): void {
    this.displayedClusterAreasStream.next(areas);
  }

  displayRoutes(routes: Route[]): void {
    this.routesStream.next(routes);
  }
}
