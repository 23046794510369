import { Component, OnInit } from '@angular/core';
import {Editable} from "../_models/editable";
import {CompanyClientLegalEntity} from "../_models/company-client-legal-entity";
import {FormErrors} from "../_models/form-errors";

@Component({
  selector: 'app-new-legal-entity',
  templateUrl: './new-legal-entity.component.html',
  styleUrls: ['./new-legal-entity.component.css']
})
export class NewLegalEntityComponent implements OnInit {
  legalEntity = new Editable(new CompanyClientLegalEntity(), new FormErrors(), true);

  constructor() { }

  ngOnInit() {
  }

}
