import {finalize} from 'rxjs/operators';
import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DestinationPoint} from "../_models/destination-point";
import {StorehouseService} from "../_services/storehouse.service";
import {AlertService} from "../_services/alert.service";
import {LoaderService} from "../_services/loader.service";
import {Storehouse} from "../_models/storehouse";
import {GoogleMap} from "@angular/google-maps";
import {MapService} from "../_services/map.service";

const INITIAL_ZOOM = 11;
const COORDINATES_REGEXP = /^(-?\d+\.?\d*),\s*(-?\d+\.?\d*)$/;
const TIME_REGEXP = /^(\d{2}):(\d{2})$/;
const DURATION_REGEXP = /^(\d{1,3})$/;

@Component({
  selector: 'storehouse-creator',
  templateUrl: './storehouse-creator.component.html',
  styleUrls: ['./storehouse-creator.component.css']
})
export class StorehouseCreatorComponent implements OnInit, AfterViewInit {
  @Output() changed = new EventEmitter<void>();
  @ViewChild(GoogleMap) map: GoogleMap;

  lat: number = 55.753040;
  lng: number = 37.622002;
  coordinates: string;
  addr: string;
  zoom: number = INITIAL_ZOOM;
  openAfter: string;
  openBefore: string;
  loadingUnloadingDuration: string = '15';
  isAddressValid = true;
  isCoordinatesValid = true;
  isOpenAfterValid = true;
  isOpenBeforeValid = true;
  isLoadingUnloadingDurationValid = true;
  isCenterMarkerVisible = true;

  private isCoordinatesChanging = false;

  constructor(private storehouseService: StorehouseService,
              private alertService: AlertService,
              private loaderService: LoaderService,
              public mapService: MapService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.mapService.initMap();
  }

  isValid(): boolean {
    return this.isAddressValid
      && this.isCoordinatesValid
      && this.isOpenAfterValid
      && this.isOpenBeforeValid
      && this.isLoadingUnloadingDurationValid
    ;
  }

  private validate(): void {
    this.isAddressValid = this.addr && this.addr.trim().length > 0;
    this.isCoordinatesValid = COORDINATES_REGEXP.test(this.coordinates);
    this.isOpenAfterValid = TIME_REGEXP.test(this.openAfter);
    this.isOpenBeforeValid = TIME_REGEXP.test(this.openBefore);
    this.isLoadingUnloadingDurationValid = DURATION_REGEXP.test(this.loadingUnloadingDuration) && parseInt(this.loadingUnloadingDuration) >= 15;
  }

  private setCoordinates(lat: number, lng: number): void {
    this.coordinates = `${lat},${lng}`;
    this.onChangeInput();
  }

  save(savedCallback?: (storehouse: Storehouse) => void|null): void {
    this.validate();
    if(!this.isValid()) {
      this.alertService.error('Не все поля заполнены корректно');
      return;
    }

    this.loaderService.show();
    this.loaderService.showText('Добавление склада');

    let storehouse = new Storehouse();
    storehouse.address = this.addr;
    storehouse.lat = this.lat;
    storehouse.lon = this.lng;
    storehouse.open_after = this.openAfter;
    storehouse.open_before = this.openBefore;
    storehouse.loading_unloading_duration = parseInt(this.loadingUnloadingDuration);

    this.storehouseService
      .createStorehouse(storehouse).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        s => {
          if(savedCallback)
            savedCallback(s);
          else
            this.alertService.success('Склад добавлен');
        }
      )
    ;
  }

  onAddressFound(point: DestinationPoint): void {
    console.log(point);
    if(!point.lat || !point.lon)
      return;

    this.lat = point.lat;
    this.lng = point.lon;
    this.addr = point.addr;
    this.isCenterMarkerVisible = true;
    this.setCoordinates(point.lat, point.lon)

    if(this.zoom == INITIAL_ZOOM)
      this.zoom = 17;
  }

  onCenterChange(): void {
    if(!this.isCoordinatesChanging) {
      let mapCenter = this.map.getCenter();
      this.setCoordinates(mapCenter.lat(), mapCenter.lng())
    }

    this.isCoordinatesChanging = false;
  }

  onChangeInput(): void {
    this.validate();
    this.changed.emit();
  }

  onChangeCoordinates(): void {
    this.onChangeInput();

    if(this.isCoordinatesValid) {
      let matches = COORDINATES_REGEXP.exec(this.coordinates);

      let lat = parseFloat(matches[1]);
      let lng = parseFloat(matches[2]);

      this.isCoordinatesChanging = lat != this.lat || lng != this.lng;

      this.lat = lat;
      this.lng = lng;
    }
  }

  onSearchFieldFocused(): void {
    this.isCenterMarkerVisible = false;
  }

  onSearchFieldBlurred(): void {
    this.isCenterMarkerVisible = true;
  }

  onSearchFieldKeyUp(): void {
    this.isCenterMarkerVisible = false;
  }
}
