import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';
import {DeliveryService} from "../_services/delivery.service";
import {DeliverySchema} from "../_models/delivery-schema";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'delivery-schemas-dialog',
  templateUrl: './delivery-schemas-dialog.component.html',
  styleUrls: ['./delivery-schemas-dialog.component.scss']
})
export class DeliverySchemasDialogComponent implements AfterViewInit, OnDestroy {
  @Output() schemaSelected = new EventEmitter<DeliverySchema>();
  @ViewChild('modal', { static: true }) dialogEl: ElementRef;

  deliverySchemas: DeliverySchema[] = [];
  isLoading = false;
  private selectedSchema: DeliverySchema = null;

  private wasShown = false;
  private modalWasInit = false;

  constructor(private deliveryService: DeliveryService) { }

  showDialog(): void {
    this.deliverySchemas = [];
    this.isLoading = true;
    $(this.dialogEl.nativeElement).modal('show');
    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  ngAfterViewInit(): void {
    $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
      if(this.selectedSchema)
        this.schemaSelected.next(this.selectedSchema);
    });
    $(this.dialogEl.nativeElement).on('shown.bs.modal', () => {
      this.loadSchemas();
    });
  }

  private selectSchema(schema: DeliverySchema): void {
    this.selectedSchema = schema;
    this.hideDialog();
  }

  private loadSchemas(): void {
    this.deliverySchemas = [];
    this.isLoading = true;
    this.deliveryService
      .getSchemas()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: s => {
          this.deliverySchemas = s;
          if(s.length == 1)
            this.selectSchema(s[0]);
        },
        error: () => {}
      })
    ;
  }

  onSelectSchema(schema: DeliverySchema): void {
    this.selectSchema(schema);
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
