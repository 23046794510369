export class PaymentDistributionSchema {
  id: number;
  name: string;
  order_source: string;
  schema_type: string;
  schema_values: any;
  default_schema: boolean;
  description: string;
  executor_type: string;
  is_removable: boolean;
}
