export class DeliveryProductLine {
  name: string;
  code: string;
  count: number;
  cost: number;
  weight: number;
  length: number;
  width: number;
  height: number;
}
