import { Component, OnInit } from '@angular/core';
import {Freighter} from "../_models/freighter";
import {Editable} from "../_models/editable";
import {FormErrors} from "../_models/form-errors";
import {FreighterClient} from "../_models/freighter-client";
import {UserInfoService} from "../_services/user-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../_services/alert.service";

@Component({
  selector: 'app-new-freighter-client',
  templateUrl: './new-freighter-client.component.html',
  styleUrls: ['./new-freighter-client.component.css']
})
export class NewFreighterClientComponent implements OnInit {
  freighter: Freighter;

  client = new Editable(new FreighterClient(), new FormErrors(), true);

  constructor(
    private userInfoService: UserInfoService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();
  }

  onBack(): void {
    this.router.navigate([`/freighter-clients`]);
  }

  onSaved(client: FreighterClient): void {
    this.router.navigate([`/freighter-clients`]);
  }

}
