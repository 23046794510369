
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BankCard} from "../_models/bank-card";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Account} from "../_models/account";
import {AddBankCardState} from "../_models/add-bank-card-state";
import {environment} from "../../environments/environment";
import {AlertService} from "./alert.service";
import {LoaderService} from "./loader.service";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class BankCardService {
  private isAddCardActive = false;
  private addCardRequestId: string;
  constructor(private _requestService: RequestWithErrorHandlerService, private alertService: AlertService, private loaderService: LoaderService) { }

  getMyCards(): Observable<BankCard[]> {
    return this._requestService
      .get(`/me/cards.json`).pipe(
      map(r => r.body.cards as BankCard[]))
    ;
  }

  addFakeCard(account: Account): Observable<HttpResponse<any>> {
    return this._requestService.post(`/accounts/${account.id}/cards/fake.json`, {});
  }

  /**
   * Регистрация запроса на добавление карты
   *
   * @return request id
   */
  doAddCardRequest(): Observable<string> {
    return this._requestService
      .post('/me/cards/request.json', { provider: 'alfa_bank' }).pipe(
      map(r => r.body.request_id as string))
      ;
  }

  requestAddCardState(requestId: string): Observable<AddBankCardState> {
    return this._requestService
      .get(`/me/cards/request/${requestId}.json`).pipe(
      map(r => r.body as AddBankCardState))
      ;
  }

  openAddCardPage(accessToken: string): void {
    window.location.href = `${environment.apiEndpoint}/cards/request/${accessToken}/start?returnMode=${environment.addBankCardReturnMode}`
  }

  removeCard(card: BankCard): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/me/cards/${card.id}.json`);
  }

  addCard(): void {
    if(this.isAddCardActive)
      return;

    this.isAddCardActive = true;
    this.loaderService.show();
    this.doAddCardRequest()
      .subscribe(
        requestId => {
          this.addCardRequestId = requestId;
          this.checkAddCardStatus();
        },
        e => {
          this.isAddCardActive = false;
          throw e;
        }
      );
  }

  activateCard(card: BankCard): Observable<HttpResponse<any>> {
    return this._requestService.put(`/me/cards/${card.id}/active.json`, {});
  }

  private checkAddCardStatus(): void {
    if(this.addCardRequestId === undefined)
      return;

    this.requestAddCardState(this.addCardRequestId)
      .subscribe(
        state => {
          switch(state.status) {
            case 'declined':
              this.isAddCardActive = false;
              this.alertService.error('Запрос на добавление карты отклонён');
              break;
            case 'wait_open_page':
              this.openAddCardPage(state.access_token);
              break;
            default:
              setTimeout(() => this.checkAddCardStatus(), 3000)
          }
        },
        e => {
          this.isAddCardActive = false;
          throw e;
        }
      );
  }
}
