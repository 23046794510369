import {Component, Input, OnInit} from '@angular/core';
import {Employer} from "../_models/employer";

@Component({
  selector: 'driver-payment-details',
  templateUrl: './driver-payment-details.component.html',
  styleUrls: ['./driver-payment-details.component.css']
})
export class DriverPaymentDetailsComponent implements OnInit {
  @Input() employer: Employer;

  constructor() { }

  ngOnInit() {
  }

}
