import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UploadChain} from "../_upload/upload-chain";
import {PhotoFieldComponent} from "../photo-field/photo-field.component";

@Component({
  selector: 'chained-photo-field',
  templateUrl: './chained-photo-field.component.html',
  styleUrls: ['./chained-photo-field.component.css']
})
export class ChainedPhotoFieldComponent implements OnInit {
  @Input() uploadChain: UploadChain;
  @Input() title: string;
  @Input() identifier: string;
  @Input() photo: string;

  @Output() removeFile = new EventEmitter<string>();

  @ViewChild(PhotoFieldComponent, { static: true }) photoField: PhotoFieldComponent;

  constructor() { }

  reset(): void {
    this.photoField.reset();
  }

  ngOnInit() {
  }

  onSelectFile(): void {
    this.uploadChain.getUnit(this.identifier).readyForUpload = true;
  }

  onRemoveFile(): void {
    this.removeFile.emit(this.identifier);
  }
}
