
import {map} from 'rxjs/operators';
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {ApiUtilsService} from "./api-utils.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {TariffTier} from "../_models/tariff-tier";
import {Point} from "../_models/point";

@Injectable()
export class TariffService {
  constructor(private _requestService: RequestWithErrorHandlerService, private apiUtilsService: ApiUtilsService) { }

  getTier(point?: Point): Observable<Object[]> {
    return this._requestService
      .get(`/tariff/tier.json`, {
        includeUnavailable: 'true',
        lat: point && point.lat,
        lon: point && point.lon
      }).pipe(
      map(r => r.body.tiers))
    ;
  }

  getTaxiTiers(hidden?: boolean): Observable<TariffTier[]> {
    return this._requestService
      .get(`/taxi/tiers.json`, {
        hide: hidden == null ? undefined : (hidden ? 1 : 0)
      }).pipe(
      map(r => r.body as TariffTier[]))
    ;
  }
}
