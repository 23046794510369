<div class="modal fade" tabindex="-1" role="dialog" #balanceDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Отправка отчёта по балансу</h4>
        </div>
      </div>
      <div class="modal-body">
        <send-balance-form [users]="users" (onCancel)="onCancel()"></send-balance-form>
      </div>
    </div>
  </div>
</div>
