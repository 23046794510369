
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";
import {Destination} from "../_models/destination";
import {Subject} from "rxjs";
import {DestinationPoint} from "../_models/destination-point";
import {UserInfoService} from "../_services/user-info.service";
import {ImportData} from "./import-data";
import {OrderDraftService} from "../_services/order-draft.service";
import {LoaderService} from "../_services/loader.service";
import {DELIVERY_TO} from "../_maps/delivery-to";
import {DestinationOptionsPipe} from "../_pipes/destination-options.pipe";
import {
  LegalEntitySelectorDialogComponent
} from "../legal-entity-selector-dialog/legal-entity-selector-dialog.component";
import {LegalEntity} from "../_models/legal-entity";
import {FreighterClientService} from "../_services/freighter-client.service";
import {StorehouseService} from "../_services/storehouse.service";
import {Storehouse} from "../_models/storehouse";
import {DeliveryProduct} from "../_models/delivery-product";
import {DateUtils} from "../_utils/date-utils";
import {GeoService} from "../_services/geo.service";
import {Point} from "../_models/point";

@Component({
  selector: 'draft-route-editor',
  templateUrl: './draft-route-editor.component.html',
  styleUrls: ['./draft-route-editor.component.css']
})
export class DraftRouteEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Input() legalEntitySelectorDialog: LegalEntitySelectorDialogComponent;
  @Input() isTimeOffsetRequired: boolean;
  @Output() onFocusedAddr = new EventEmitter<Subject<DestinationPoint>>();
  @Output() onUpdated = new EventEmitter<OrderDraft>();
  @Output() onImported = new EventEmitter<OrderDraft>();
  @Output() onRequestDeliveryProductDialog = new EventEmitter<DeliveryProduct>();

  arrivalDatePickerOptions = {};
  arrivalTimePickerOptions = {};

  destinations: Destination[];
  arrivalTimes: Date[] = [];
  arrivalTimeEnds: Date[] = [];
  arrivalTimeValid: boolean[] = [];
  visibleImportForms = {};
  enabledImport = false;
  importForms: ImportData[] = [];
  geoContext: string;
  enabledLegalEntities = false;
  storehouses: Storehouse[] = [];
  isValid = true;

  deliveryVariants = DELIVERY_TO;

  private destinationOptionsPipe: DestinationOptionsPipe;
  private showOptionsEditor = new WeakMap<Destination, boolean>();
  private activeDestination: Destination;

  constructor(
    private userInfoService: UserInfoService,
    private orderDraftService: OrderDraftService,
    private freighterClientService: FreighterClientService,
    private storehouseService: StorehouseService,
    private loaderService: LoaderService,
    private geoService: GeoService
  ) {
    this.destinationOptionsPipe = new DestinationOptionsPipe();
    this.enabledLegalEntities = userInfoService.isPrivilegedUser()
      || userInfoService.isFreighter()
      || userInfoService.isDeliveryManager();
  }

  ngOnInit() {
    this.initGeoContext();
    this.cloneDestinations();
    this.prepareArrivalTimes();
    this.initImports();
    this.initDeliveryTo();
    this.initStorehouses();
  }

  getOptionsAsString(destination: Destination, empty: string): string {
    return this.destinationOptionsPipe.transform(destination, empty);
  }

  isVisibleOptionsEditor(destination: Destination): boolean {
    return this.showOptionsEditor.has(destination) && this.showOptionsEditor.get(destination);
  }

  toggleOptionEditorVisibility(destination): void {
    if(!this.showOptionsEditor.has(destination))
      this.showOptionsEditor.set(destination, false);

    this.showOptionsEditor.set(destination, !this.showOptionsEditor.get(destination));
  }

  getDeliveryProductsCount(destination: Destination): number {
    if(!destination.delivery_product)
      return 0;

    let deliveryProduct = destination.delivery_product;

    let count = (deliveryProduct.lines || []).length;
    if(count > 0)
      return count;

    return (deliveryProduct.length || deliveryProduct.width || deliveryProduct.height || deliveryProduct.weight) ? 1 : 0;
  }

  private initStorehouses(): void {
    this.loaderService.show();
    this.storehouseService
      .getAvailableStorehouses().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        s => this.storehouses = s,
        () => {}
      )
    ;
  }

  private initGeoContext() {
    this.geoContext = this.draft.city ? this.draft.city.geo_context : 'moscow';
  }

  private initImports(): void {
    if(this.userInfoService.isPrivilegedUser() && this.draft.id > 0) {
      this.enabledImport = true;
      this.initImportForms();
    } else {
      this.enabledImport = false;
    }
  }

  private initImportForms() {
    this.importForms = [];
    for(let i = 0, l = this.draft.destinations.length; i <= l; i ++) {
      this.importForms[i] = new ImportData();
    }
  }

  private initDeliveryTo(): void {
    for (let destination of this.destinations) {
      if(!destination.delivery_to) {
        destination.delivery_to = '';
      }
    }
  }

  private cloneDestinations() {
    this.destinations = [];

    for(let destination of this.draft.destinations) {
      this.destinations.push(JSON.parse(JSON.stringify(destination)) as Destination);
    }
  }

  private prepareArrivalTimes() {
    if(!this.draft.city) {
      for(let i in this.destinations) {
        let destination = this.destinations[i];
        this.arrivalTimes[i] = destination.arrival_time ? new Date(destination.arrival_time) : null;
        this.arrivalTimeEnds[i] = destination.arrival_time_end ? new Date(destination.arrival_time_end) : null;
      }
    } else {
      for(let i in this.destinations) {
        let destination = this.destinations[i];
        this.arrivalTimes[i] = destination.arrival_time
          ? DateUtils.offsetTimeByCityTimeZone(new Date(destination.arrival_time), this.draft.city)
          : null;
        this.arrivalTimeEnds[i] = destination.arrival_time_end
          ? DateUtils.offsetTimeByCityTimeZone(new Date(destination.arrival_time_end), this.draft.city)
          : null;
      }
    }

    for(let i in this.destinations) {
      this.arrivalTimeValid[i] = true;
    }
  }

  private applyArrivalTimes() {
    for (let i in this.arrivalTimes) {
      let arrivalTime = this.arrivalTimes[i];
      if(arrivalTime && this.draft.city)
        arrivalTime = DateUtils.offsetTimeByCityTimeZone(arrivalTime, this.draft.city, -1);

      this.destinations[i].arrival_time = arrivalTime ? arrivalTime.toString() : null;

      let arrivalTimeEnd = this.arrivalTimeEnds[i];
      if(arrivalTimeEnd && this.draft.city)
        arrivalTimeEnd = DateUtils.offsetTimeByCityTimeZone(arrivalTimeEnd, this.draft.city, -1);

      this.destinations[i].arrival_time_end = arrivalTimeEnd ? arrivalTimeEnd.toString() : null;
    }
  }

  private move(pos: number, offset: number) {
    let newPos = pos + offset;
    let t = this.destinations[newPos];
    this.destinations[newPos] = this.destinations[pos];
    this.destinations[pos] = t;
  }

  private showDeliveryProductDialog(destination: Destination): void {
    this.activeDestination = destination;
    if(!destination.delivery_product) {
      destination.delivery_product = new DeliveryProduct();
      destination.delivery_product.lines = [];
    }
    this.onRequestDeliveryProductDialog.emit(destination.delivery_product);
  }

  private validate(): boolean {
    this.isValid = true;
    for(let i in this.arrivalTimes) {
      let arrivalTime = this.arrivalTimes[i];
      if(!arrivalTime) {
        this.arrivalTimeValid[i] = true;
        continue;
      }
      let arrivalTimeEnd = this.arrivalTimeEnds[i];
      if(!arrivalTimeEnd) {
        this.arrivalTimeValid[i] = true;
        continue;
      }
      let isValidTime = arrivalTime.getTime() <= arrivalTimeEnd.getTime();
      this.arrivalTimeValid[i] = isValidTime;

      this.isValid &&= isValidTime;
    }
    return this.isValid;
  }

  private optimizeRoute(): void {
    if(this.destinations.length < 3)
      return;

    let points = this.destinations.map(d => Point.createFromDestinationPoint(d.destination));
    this.loaderService.show();
    this.geoService
      .optimizeRoute(points)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: (tripPoints) => {
          let sortedDestinations: Destination[] = [];
          for(const i in tripPoints)
            sortedDestinations[tripPoints[i].index] = this.destinations[i];

          this.applyArrivalTimes();
          this.destinations = sortedDestinations;
          this.prepareArrivalTimes();
        },
        error: () => {}
      })
    ;
  }

  onFocusAddr(focusedAddr: Subject<DestinationPoint>) {
    this.onFocusedAddr.emit(focusedAddr);
  }

  onUp(i: number) {
    this.move(i, -1);
  }

  onDown(i: number) {
    this.move(i, 1);
  }

  onDelete(i: number) {
    this.destinations.splice(i, 1);
    this.arrivalTimes.splice(i, 1);
    this.arrivalTimeValid.splice(i, 1);
  }

  onAdd() {
    let destination = new Destination();
    destination.destination = new DestinationPoint();
    destination.destination.addr = '';
    this.arrivalTimeValid.push(true);
    this.destinations.push(destination);
  }

  onSave() {
    if(!this.validate()) {
      return;
    }

    this.applyArrivalTimes();
    this.draft.destinations = this.destinations;
    this.onUpdated.emit(this.draft);
  }

  onShowImportForm(i: number) {
    this.visibleImportForms[i] = true;
  }

  onImport(i: number) {
    let importData = this.importForms[i];
    let draftDestination: Destination = i == 0 ? null : this.draft.destinations[i - 1];

    this.loaderService.show();
    this.orderDraftService.importComplexDeliveryOrder(
      this.draft,
      importData.complexDeliveryId,
      importData.complexDeliveryOrderId,
      draftDestination === null ? null : draftDestination.id,
      importData.after
    ).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onImported.emit(this.draft),
        () => {}
      )
  }

  onOpenLegalEntitySelectorFor(destination: Destination): void {
    this.activeDestination = destination;
    this.legalEntitySelectorDialog.showDialog();
  }

  onSelectLegalEntity(entity: LegalEntity): void {
    this.activeDestination.client_legal_entity = entity;

    if(this.userInfoService.isFreighter()) {
      let destination = this.activeDestination;

      this.loaderService.show();
      this.freighterClientService
        .getClientsByLegalEntity(this.userInfoService.getFreighter(), entity).pipe(
        finalize(() => this.loaderService.hide()))
        .subscribe(
          clients => {
            if(clients.length > 0) {
              let client = clients[0];
              destination.contact_phone = client.contact_phone;
              destination.contact_name = client.contact_name;
            }
          }
        )
    }

    this.activeDestination = null;
  }

  onUnselectLegalEntity(destination: Destination): void {
    destination.client_legal_entity = null;
  }

  onClickStorehouse(storehouse: Storehouse, destinationNum: number): void {
    let oldDestination = this.destinations[destinationNum];
    // клонируем точку, чтобы обновился объект
    let newDestination = JSON.parse(JSON.stringify(oldDestination)) as Destination;
    newDestination.destination.addr = storehouse.address;
    newDestination.destination.lat = storehouse.lat;
    newDestination.destination.lon = storehouse.lon;
    this.destinations[destinationNum] = newDestination;
  }

  onShowDeliveryProductDialog(destination: Destination): void {
    this.showDeliveryProductDialog(destination);
  }

  onUpdateDeliveryProduct(product: DeliveryProduct): void {
    this.activeDestination.delivery_product = product;
  }

  onReverse(): void {
    this.destinations = this.destinations.reverse();
  }

  onOptimizeRoute(): void {
    this.optimizeRoute();
  }
}
