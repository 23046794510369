import {Invoice} from "./invoice";
import {OrderPaymentStatus} from "../_enumerations/order-payment-status";

export class OrderPaymentState {
  orderId: number;
  invoices: Invoice[] = [];
  paymentStatus: OrderPaymentStatus;
  feeStatus: OrderPaymentStatus;
  payoutStatus: OrderPaymentStatus;
  payoutAmount = 0;
  feeAmount = 0;
  paymentDate: Date;
  feeDate: Date;
  declinePaymentReason: string;
  declineFeeReason: string;
  declinePayoutReason: string;

  /**
   * Проверка на наличие задолженности по платежу
   */
  hasPaymentDebt(): boolean {
    if(this.paymentStatus === OrderPaymentStatus.COMPLETED)
      return false;

    if(!this.paymentDate)
      return false;

    return OrderPaymentState.getDebtDays(this.paymentDate) > 0;
  }

  getPaymentDebtDays(): number {
    if(!this.hasPaymentDebt())
      return 0;

    return OrderPaymentState.getDebtDays(this.paymentDate);
  }

  /**
   * Проверка на наличие задолженности по комиссии
   */
  hasFeeDebt(): boolean {
    if(this.feeStatus === OrderPaymentStatus.COMPLETED)
      return false;

    if(!this.feeDate)
      return false;

    return OrderPaymentState.getDebtDays(this.feeDate) > 0;
  }

  getFeeDebtDays(): number {
    if(!this.hasFeeDebt())
      return 0;

    return OrderPaymentState.getDebtDays(this.feeDate);
  }

  private static getDebtDays(payDate: Date): number {
    return Math.round(((new Date()).getTime() - payDate.getTime()) / 1000 / 60 / 60 / 24);
  }
}
