import {Pipe, PipeTransform} from "@angular/core";
import {Destination} from "../_models/destination";
import {DELIVERY_TO} from "../_maps/delivery-to";

@Pipe({name: "destinationOptions"})
export class DestinationOptionsPipe implements PipeTransform {
  transform(destination: Destination, empty?: string): any {
    let map = {
      elevator: 'грузовой лифт',
      lifting: 'требуется подъём/спуск',
      loading: 'погрузка',
      unloading: 'разгрузка',
      unloading_internet_shop_order: 'разгрузка заказа из интернет-магазина',
      required_additional_time: 'требуется доп. время'
    }

    let active: string[] = [];
    for (const field in map) {
      if(destination[field])
        active.push(map[field]);
    }

    if(destination.delivery_to && DELIVERY_TO[destination.delivery_to]) {
      active.push(DELIVERY_TO[destination.delivery_to] || '-')
    }

    return active.length == 0 ? empty : active.join(', ');
  }
}
