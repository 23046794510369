<div>
  <div *ngIf="!enabled">
    Для выполнения заказа должен быть указан маршрут и исполнитель.
  </div>
  <div *ngIf="enabled">
    <table class="table tbl-order taximeter">
      <thead>
      <tr>
        <td>
          Время работы
        </td>
        <td>
          Расстояние
        </td>

        <td>
          За МКАД
        </td>

        <td>
          Сумма заказа
        </td>

        <td>

        </td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ time }}</td>
        <td>{{ distance }}</td>
        <td>{{ outMkadDistance }}</td>
        <td>{{ cost }} Р</td>
        <td>
          <span *ngFor="let period of order.periods">
            <span *ngIf="period.no_points">
              <img src="/assets/images/no-gps.png" alt="Отсутствуют точки" title="Отсутствуют точки">
            </span>
          </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="expandBriefPanel" class="status-list-box">

    <div class="status-list" *ngIf="history.length > 0">
      <div class="status-element">
        <div class="flex-centered header">
Статус
        </div>
        <div class="time pull-right header">
Время
        </div>
      </div>
      <div *ngFor="let row of history; let last=last" class="status-element">
        <div class="flex-centered">
          <a class="clickable mar16right" title="Вернуть предыдущий статус"
             *ngIf="last && period.execution_status != 'canceled' && !userService.isCustomer() && order.payment_status == 'none'"
             (click)="onRevertExecutionStatus()" [attr.disabled]="!enabledRevert">
            <i class="material-icons">replay</i>
          </a>
          <div class="status-box">
            {{ row.execution_status | executionStatus }}
            <div *ngIf="row.destination" class="history-destination">{{ row.destinationNum }}. {{ row.destination.destination.addr }}</div>
          </div>
        </div>
        <div class="time pull-right">{{ row.created_at | withCityTz:order.draft.city | dt:'H:mm' }}</div>
      </div>

    </div>

    <div>
      <div class="flex-row flex-space-between mar16top" *ngIf="!(userService.isCustomer())">
        <div class="flex-child">
          <label>Статус</label><br>
          <select [(ngModel)]="executionStatus">
            <option value="moved_to_order">выехал на заказ</option>
            <option value="on_start_place">прибыл на заказ</option>
            <option value="loading">погрузка</option>
            <option value="moving">перевозка</option>
            <option value="on_end_place">в точке назначения</option>
            <option value="unloading">разгрузка</option>
            <option value="cash_accepted">наличные приняты</option>
            <option value="request_card_payment">запросить оплату по карте</option>
            <option value="completed">заказ завершён</option>
          </select>
        </div>
        <div class="flex-child"  *ngIf="!(userService.isCustomer())">
          <input type="checkbox" [(ngModel)]="withStatusDateControl"><label>Изменить дату и время</label>
          <div [class.disabled-controls]="withStatusDateControl == false">
            <order-panel-datepicker [(value)]="statusDate" [datepicker]="datePickerOptions"
                                    [timepicker]="timePickerOptions"
                                    name="startDate" ngDefaultControl></order-panel-datepicker>
          </div>
        </div>
      </div>
      <div class="flex-row flex-space-between mar16top" *ngIf="!userService.isCustomer()">
        <a class="order-control-btn"
           (click)="onExecute()">Запустить <i class="material-icons">play_arrow</i></a>
      </div>

    </div>

  </div>
  <div *ngIf="enabledUseCalculatedPath" class="sub-row row-padding">
    <a type="button" class="calc-path-btn" [class.use-calc-path]="useCalculatedPath"
       (click)="onUpdateUsingCalculationPath(!useCalculatedPath)"><input type="checkbox" class="hidden"
                                                                         [ngModel]="useCalculatedPath">
      Использовать расчётный путь
    </a>
  </div>
  <div class="sub-row row-padding" *ngIf="periodStatus == 'finished' && order.payment_status != 'paid'">
    <div>
      <a class="order-control-btn" *ngIf="order.payment_status == 'none'" (click)="onBill()">Выставить счёт
      </a>
    </div>
    <div class="sub-row row-padding" *ngIf="order.pay_method == 'cash' && order.payment_status == 'wait_payment'">
      <a class="order-control-btn"
         (click)="onBillAccept()">Подтвердить
        получение денег
      </a>
    </div>

  </div>
  <div class="row-padding">

  </div>
  <div class=" row-padding" *ngIf="order.draft.delivery_status == 'wait_hand'">
    <a class="order-control-btn"

       (click)="onBillMoneyReturn()"
    >Подтвердить передачу денег водителем
    </a>
  </div>
  <div class="row-padding" *ngIf="order.draft.delivery_status == 'wait_hand_accept'">
    <a class="order-control-btn"
       *ngIf="order.draft.delivery_status == 'wait_hand_accept'"
       (click)="onBillMoneyReturnAccept()"
    >Подтвердить получение денег менеджером
    </a>
  </div>

</div>
