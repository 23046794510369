<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row">
  <div class="no-padding orders-forms orders-form-box">
    <div class="no-padding calls-forms calls-form-box">
      <form class="form-inline calls-forms">
        <div class="form-group search-box">
          <order-panel-datepicker class="date" [(value)]="fromDate" [datepicker]="fromDatePickerOptions"
                                  [timepicker]="false" (dateChange)="onChangeFilter()" name="from"
                                  ngDefaultControl></order-panel-datepicker>
        </div>
        <div class="form-group search-box">
          <order-panel-datepicker class="date" [(value)]="toDate" [datepicker]="toDatePickerOptions"
                                  [timepicker]="false" (dateChange)="onChangeFilter()" name="to"
                                  ngDefaultControl></order-panel-datepicker>
        </div>
        <div class="form-group search-box">
          <input
            type="text"
            class="calls-search calls-control"
            name="phone"
            [(ngModel)]="number"
            (ngModelChange)="onChangeFilter()"
            placeholder="Телефоны, через запятую"
            size="11">
        </div>
        <div class="form-group search-box">
          <input
            type="text"
            class="calls-search calls-control"
            name="surname"
            [(ngModel)]="surname"
            (ngModelChange)="onChangeFilter()"
            placeholder="Фамилии, через запятую"
            size="20">
        </div>
      </form>
      <pager [list]="calls" (onPage)="onPaginationPage($event)" [verticalStretch]="true"></pager>
    </div>
    <div class="calls-list">
      <table class="calls-table">
        <thead>
        <tr class="calls-table-header">
          <th>Время звонка</th>
          <th>Номер</th>
          <th>ФИО</th>
          <th>Тип</th>
          <th>Компания</th>
          <th>Продолжительность</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let call of calls.calls">
          <td>{{ call.time|dt:"dd.MM.yy HH:mm" }}</td>
          <td [class.fail-call]="!call.successful" [attr.title]="call.successful ? null : 'неудачный звонок'">
            <span [ngSwitch]="call.type">
              <span *ngSwitchCase="'incoming'"><img src="/assets/images/call/incoming.png" alt="" width="24" height="24"></span>
              <span *ngSwitchCase="'outgoing'"><img src="/assets/images/call/outgoing.png" alt="" width="24" height="24"></span>
            </span>
            <div class="result-icon glyphicon glyphicon-remove" *ngIf="!call.successful"></div>
            <a class="phone" title="Позвонить" (click)="onCall(call)">{{ call.number|formatPhone }}</a>
          </td>
          <td>{{ call.account|fullName }}</td>
          <td><span *ngIf="call.account">{{ call.account.auth.role|role }}</span></td>
          <td><span *ngIf="call.account && call.account.company_client">{{ call.account.company_client.name }}</span></td>
          <td>{{ asDuration(call.duration) }}</td>
          <td>

<!--            <audio *ngFor="let record of call.records"-->
<!--              controls-->
<!--              [src]="record.url">-->
<!--              Your browser does not support the-->
<!--              <code>audio</code> element.-->
<!--            </audio>-->
            <button *ngFor="let record of call.records" class="btn btn-default btn-xs play-record"
                    (click)="onPlayCallRecord(record)" title="Прослушать запись"><img
              src="/assets/images/call/play-record.png" alt=""></button>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="show-more-btn" *ngIf="showMore">
        <show-more [list]="calls" (onPage)="onShowMorePage($event)"></show-more>
      </div>
    </div>
  </div>
</div>

