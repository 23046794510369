<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row">
  <div class="no-padding orders-forms orders-form-box">
    <div class="notifications-list">
      <pager [list]="notifications" (onPage)="onPaginationPage($event)"></pager>
      <table class="notifications-table">
        <thead>
        <tr class="notifications-table-header">
          <th>Дата</th>
          <th>Сообщение</th>
          <th>Заявка</th>
          <th>Заказ</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let notification of notifications.notifications" [class.alert]="notification.payload.alert">
          <td>{{ notification.updated_at|dt:"dd.MM.yy HH:mm:ss" }}</td>
          <td>{{ notification.message }}</td>
          <td>
            <a [routerLink]="[ '/drafts', notification.payload.draft ]" *ngIf="notification.payload.draft">{{ notification.payload.draft }}</a>
          </td>
          <td>
            <a [routerLink]="[ '/orders', notification.payload.freighter, notification.payload.order ]" *ngIf="notification.payload.order">{{ notification.payload.order }}</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="show-more-btn" *ngIf="showMore">
        <show-more [list]="notifications" (onPage)="onShowMorePage($event)"></show-more>
      </div>
    </div>
  </div>
</div>
