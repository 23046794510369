import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {OptimizationTask} from "../_models/optimization-task";
import {Observable} from "rxjs";
import {OptimizationTaskList} from "../_models/optimization-task-list";
import {OrderDraft} from "../_models/order-draft";
import {CityService} from "./city.service";
import {OptimizationTaskTransport} from "../_models/optimization-task-transport";
import {OptimizationTaskDestination} from "../_models/optimization-task-destination";
import {DeliverySchema} from "../_models/delivery-schema";
import {TariffTier} from "../_models/tariff-tier";
import {DateTime} from "date-time-js";
import {HttpResponse} from "@angular/common/http";
import {OptimizationTaskCalculationRequest} from "../_models/optimization-task-calculation-request";
import {OptimizationTaskAutoBuildTransportsRequest} from "../_models/optimization-task-auto-build-transports-request";
import {Order} from "../_models/order";
import {OptimizationTaskListFilter} from "../_models/optimization-task-list-filter";

const PAGE_SIZE = 12;
const MAX_DESTINATIONS_COUNT_WITHOUT_OPTIMIZATION = 10;

@Injectable()
export class OptimizationTaskService {
  constructor(private _requestService: RequestWithErrorHandlerService, private cityService: CityService) { }

  getMaxDestinationsCountWithoutOptimization(): number {
    return MAX_DESTINATIONS_COUNT_WITHOUT_OPTIMIZATION;
  }

  getTasks(page?: number, filter?: OptimizationTaskListFilter): Observable<OptimizationTaskList> {
    let filterClone = filter && filter.clone() || new OptimizationTaskListFilter();

    let [ arrivalTimeStart, arrivalTimeEnd ] = this.prepareArrivalDateFilter(filter.arrivalStartDate, filter.arrivalEndDate);

    return this._requestService
      .get('/delivery/tasks.json', {
        city: this.cityService.activeCity && this.cityService.activeCity.id,
        status: filter.status,
        executionStatus: filter.executionStatus,
        trackNumber: filter.trackNumber,
        orderCode: filter.orderCode,
        arrivalTimeStart,
        arrivalTimeEnd,
        cargo: filter.cargo,
        offset: (page || 0) * PAGE_SIZE,
        size: PAGE_SIZE
      }).pipe(
      map(r => new OptimizationTaskList(
        page || 0,
        PAGE_SIZE,
        parseInt(r.headers.get('X-Total-Count')),
        filterClone,
        r.body.tasks as OptimizationTask[]
      )))
    ;
  }

  getTask(id: number, viewMode: string = 'view', primaryData = false): Observable<OptimizationTask> {
    return this._requestService.get(`/delivery/tasks/${id}.json`, {
      mode: viewMode,
      primaryData
    }).pipe(map(r => r.body.task as OptimizationTask))
  }

  getTaskDrafts(taskId: number): Observable<OrderDraft[]> {
    return this._requestService
      .get(`/delivery/tasks/${taskId}/drafts.json`).pipe(
        map(r => r.body as OrderDraft[]))
      ;
  }

  getTaskOrders(taskId: number): Observable<Order[]> {
    return this._requestService
      .get(`/delivery/tasks/${taskId}/orders.json`).pipe(
        map(r => r.body as Order[]))
      ;
  }

  getTomorrowTask(viewMode: string = 'view'): Observable<OptimizationTask> {
    return this._requestService.get(`/delivery/tasks/tomorrow.json`, {
      mode: viewMode
    }).pipe(map(r => r.body.task as OptimizationTask))
  }

  addTask(task: OptimizationTask): Observable<number> {
    return this._requestService.post('/delivery/tasks.json', task).pipe(map(r => r.body.taskId));
  }

  updateTaskDeliverySchema(task: OptimizationTask, schema: DeliverySchema): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/delivery_schema.json`, {
      schema: schema.id
    });
  }

  optimizeTask(task: OptimizationTask, strategy?: string): Observable<HttpResponse<any>> {
    return this._requestService.post(`/delivery/tasks/${task.id}/optimizer.json`, {
      strategy: strategy
    });
  }

  editTask(task: OptimizationTask): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}.json`, task);
  }

  autoBuildTaskTransports(task: OptimizationTask, request: OptimizationTaskAutoBuildTransportsRequest): Observable<HttpResponse<any>> {
    return this._requestService.post(`/delivery/tasks/${task.id}/transports/build/auto.json`, request);
  }

  executeTask(task: OptimizationTask, ...startOnly: number[]): Observable<HttpResponse<any>> {
    if(startOnly.length == 0)
      startOnly = null;

    return this._requestService.post(`/delivery/tasks/${task.id}/executor.json`, { startOnly });
  }

  requestCalculation(task: OptimizationTask): Observable<HttpResponse<any>> {
    return this._requestService.post(`/delivery/tasks/${task.id}/optimizer/request.json`, {});
  }

  calculate(request: OptimizationTaskCalculationRequest): Observable<HttpResponse<any>> {
    return this._requestService.post(`/delivery/tasks/calculate.json`, request);
  }

  updateOptimizationType(task: OptimizationTask, type: string): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/optimizer/type.json`, { type });
  }

  updateConnectingOfNotOptimizedPoints(task: OptimizationTask, value: boolean): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/optimizer/connect_not_optimized_points.json`, {
      value: value ? 1 : 0
    });
  }

  updateLockForExternal(task: OptimizationTask, value: boolean): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/optimizer/lock_for_external.json`, {
      value: value ? 1 : 0
    });
  }

  updateTransportCost(task: OptimizationTask, transport: OptimizationTaskTransport): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/transports/${transport.id}/cost.json`, {
      cost: transport.cost
    });
  }

  updateTransportEmployer(task: OptimizationTask, transport: OptimizationTaskTransport): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/transports/${transport.id}/employer.json`, {
      id: transport.employer ? transport.employer.id : null
    });
  }

  addTransport(task: OptimizationTask): Observable<HttpResponse<any>> {
    return this._requestService.post(`/delivery/tasks/${task.id}/transports.json`, {});
  }

  removeTransport(task: OptimizationTask, transport: OptimizationTaskTransport): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/delivery/tasks/${task.id}/transports/${transport.id}.json`);
  }

  addDestinationsToTransport(task: OptimizationTask, transport: OptimizationTaskTransport, ...destinations: OptimizationTaskDestination[]): Observable<HttpResponse<any>> {
    return this._requestService.post(`/delivery/tasks/${task.id}/transports/${transport.id}/destinations.json`, {
      destinations: destinations.map(d => d.id)
    });
  }

  removeDestinationsFromTransport(task: OptimizationTask, transport: OptimizationTaskTransport, ...destinations: OptimizationTaskDestination[]): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/delivery/tasks/${task.id}/transports/${transport.id}/destinations.json`, {
      destinations: destinations.map(d => d.id).join(',')
    });
  }

  sortTransportDestinations(task: OptimizationTask, transport: OptimizationTaskTransport, ...destinations: OptimizationTaskDestination[]): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/transports/${transport.id}/destinations/sort.json`, {
      destinations: destinations.map(d => d.id)
    });
  }

  optimizeTransportRoute(task: OptimizationTask, transport: OptimizationTaskTransport): Observable<HttpResponse<any>> {
    return this._requestService.post(`/delivery/tasks/${task.id}/transports/${transport.id}/destinations/optimize.json`, {});
  }

  selectTransportTier(task: OptimizationTask, transport: OptimizationTaskTransport, tier: TariffTier): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/transports/${transport.id}/tier.json`, {
      tier: tier.id
    });
  }

  updateTransportArrivalToStorehouse(task: OptimizationTask, transport: OptimizationTaskTransport, date?: Date, startDate?: Date, endDate?: Date): Observable<HttpResponse<any>> {
    return this._requestService.put(`/delivery/tasks/${task.id}/transports/${transport.id}/arrival_to_storehouse.json`, {
      date: OptimizationTaskService.formatDate(date),
      arrivalStart: OptimizationTaskService.formatDate(startDate),
      arrivalEnd: OptimizationTaskService.formatDate(endDate)
    });
  }

  removeTaskDestination(destination: OptimizationTaskDestination): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/delivery/tasks/destinations/${destination.id}.json`);
  }

  removeTaskDestinations(...destinations: OptimizationTaskDestination[]): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/delivery/tasks/destinations.json`, {
      ids: destinations.map(d => d.id).join(',')
    });
  }

  /**
   * Проверка на возможность автоматического создания рейса.
   * Результатом проверки будет true (создать рейс разрешается) или false (создание рейса не рекомендуется).
   *
   * @param task
   */
  checkTaskForAutoBuildTransports(task: OptimizationTask): Observable<boolean> {
    return this.getTask(task.id, 'view', true)
      .pipe(
        map(task => {
          return task.destinations.length > 1
            && (task.destinations.length - 1) <= MAX_DESTINATIONS_COUNT_WITHOUT_OPTIMIZATION
            && task.transports.length == 0;
        })
      )
      ;
  }

  private prepareArrivalDateFilter(start?: Date, end?: Date): (string|null)[] {
    return [
      this.prepareArrivalFilter('00:00:00', start),
      this.prepareArrivalFilter('23:59:59', end)
    ];
  }

  private prepareArrivalFilter(time: string, date?: Date): string|null {
    if(!date)
      return null;

    let dateTime = new DateTime(date).offset(-date.getTimezoneOffset());
    let timeZone = dateTime.format('K');
    return  dateTime.format('yyyy-MM-dd') + 'T' + time + (timeZone === 'Z' ? '+00:00' : timeZone);
  }

  static formatDate(date?: Date): string|null {
      return date && (new DateTime(date)).format('yyyy-MM-dd HH:mm:ss') || null;
  }

  get requestService(): RequestWithErrorHandlerService {
    return this._requestService;
  }
}
