import {debounceTime, finalize} from 'rxjs/operators';
import {Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {OptimizationTaskDestination} from "../_models/optimization-task-destination";
import {Validator} from "./validator";
import {ValidateState} from "./validate-state";
import {TrackingService} from "../_models/tracking-service";
import {LoaderService} from "../_services/loader.service";
import {TrackingServiceService} from "../_services/tracking-service.service";
import {ClipboardService} from "../_services/clipboard.service";
import {DELIVERY_TO} from "../_maps/delivery-to";
import {Subject, Subscription} from "rxjs";
import {DestinationPoint} from "../_models/destination-point";
import {DeliveryProduct} from "../_models/delivery-product";
import {DeliveryProductLine} from "../_models/delivery-product-line";

const PRODUCT_FIELDS = ['name', 'code', 'count', 'cost', 'length', 'width', 'height', 'weight'];

@Component({
  selector: 'complex-delivery-form-row',
  templateUrl: './complex-delivery-form-row.component.html',
  styleUrls: ['./complex-delivery-form-row.component.css']
})
export class ComplexDeliveryFormRowComponent implements OnInit, DoCheck, OnDestroy {
  @Input() destination: OptimizationTaskDestination;
  @Input() saveEvent: EventEmitter<void>;
  @Input() editable: boolean;
  @Input() executed: boolean;
  @Input() geoContext: string;
  @Input() slotEnabled: boolean;
  @Input() contactsRequired: boolean;
  @Input() assemblyEnabled: boolean;
  @Output() onDelete = new EventEmitter<OptimizationTaskDestination>();
  @Output() onUpdate = new EventEmitter<OptimizationTaskDestination>();

  timeSlotBeginPickerOptions = {
    label: 'С'
  };

  timeSlotEndPickerOptions = {
    label: 'по'
  };

  validateState: ValidateState;

  timeSlotBegin: Date;
  timeSlotEnd: Date;
  payMethod: string;
  deliveryTo: string;
  deliveryVariants = DELIVERY_TO;
  phonePlaceholder: string;
  emailPlaceholder: string;
  isCargoOpened = false;
  isCargoClosing = true;
  cargoCount = 0;

  deliveryProduct = new DeliveryProduct();

  private validator: Validator = new Validator(this);
  private trackingServicesMap = new Map<string, TrackingService>();
  private saveSubscription: Subscription|null = null;
  private cargoOpenHideStream = new Subject<boolean>();

  constructor(
    private trackingServiceService: TrackingServiceService,
    private clipboardService: ClipboardService,
    private loaderService: LoaderService
  ) {
    this.deliveryProduct.lines = [];
    this.initCargoOpenHideStream();
  }

  ngOnInit() {
    this.initTrackingServices();
    this.updateCargoCount();

    this.unsubscribeSaveEvent();
    if(this.saveEvent !== null) {
      this.saveEvent.subscribe(() => this.onSave());
    }

    this.timeSlotBegin = this.destination.time_slot_begin == null ? null : new Date(this.destination.time_slot_begin);
    this.timeSlotEnd = this.destination.time_slot_end == null ? null : new Date(this.destination.time_slot_end);

    this.payMethod = this.destination.pay_method || "";
    this.deliveryTo = this.destination.delivery_to || "";

    if(this.destination.is_contacts_hidden) {
      this.phonePlaceholder = 'Телефон клиента скрыт';
      this.emailPlaceholder = 'E-mail клиента скрыт';
    } else {
      this.phonePlaceholder = 'Телефон клиента';
      this.emailPlaceholder = 'E-mail клиента';
    }

    this.validate();
  }

  private updateCargoCount(): void {
    this.cargoCount = this.getOrderDescriptionLines().length;
  }

  private initCargoOpenHideStream(): void {
    this.cargoOpenHideStream
      .pipe(debounceTime(1000))
      .subscribe(state => this.isCargoOpened = state)
    ;
  }

  ngOnDestroy(): void {
    this.unsubscribeSaveEvent();
  }

  private unsubscribeSaveEvent(): void {
    if(this.saveSubscription) {
      this.saveSubscription.unsubscribe();
      this.saveSubscription = null;
    }
  }

  private initTrackingServices(): void {
    this.trackingServicesMap.clear();

    this.loaderService.show();
    this.trackingServiceService
      .getAvailableTrackingServices().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        s => {
          for(let service of s)
            this.trackingServicesMap.set(service.identifier, service);
        },
        () => {}
      )
    ;
  }

  validate(): boolean {
    this.validateState = this.validator.validate();

    return this.validateState.isValid();
  }

  validateForCalculation(): boolean {
    this.validateState = this.validator.validateForCalculation();

    return this.validateState.isValid();
  }

  private editCargo(): void {
    this.prepareCargoToEdit();

    this.isCargoOpened = true;
  }

  private getOrderDescriptionLines(): string[] {
    let description = this.destination.order_description || "";
    return description.split(/[\n\r]/).map(l => l.trim()).filter(l => l.length > 0);
  }

  private prepareCargoToEdit(): void {
    let fieldParsers = {
      count: parseInt,
      cost: parseFloat,
      weight: parseInt,
      length: parseInt,
      width: parseInt,
      height: parseInt,
    };
    let descriptionLines = this.getOrderDescriptionLines();
    let lines: DeliveryProductLine[] = descriptionLines.map(l => {
      let line = new DeliveryProductLine();
      let splittedLine = l.split(',').map(l => l.trim());
      for(let i = 0; i < PRODUCT_FIELDS.length; i ++) {
        if(i >= splittedLine.length)
          continue;

        let value = splittedLine[i];
        let field = PRODUCT_FIELDS[i];
        let parser = fieldParsers[field];
        line[field] = value && parser ? parser(value) : value;
      }
      return line;
    });

    this.deliveryProduct = new DeliveryProduct();
    this.deliveryProduct.lines = lines;
  }

  private applyCargoEditResult(): void {
    let descriptionLines = this.deliveryProduct.lines.map(line => {
      let descriptionLine: string[] = [];
      for(let i = 0; i < PRODUCT_FIELDS.length; i++) {
        let field = PRODUCT_FIELDS[i];
        let value = line[field];
        if(typeof value === 'string')
          value = value.replace(/,/g, ' ');

        descriptionLine.push(value);
      }
      let lastValue = null;
      do {
        lastValue = descriptionLine.pop();
      } while(!lastValue && descriptionLine.length > 0);
      if(lastValue)
        descriptionLine.push(lastValue);
      return descriptionLine.join(',');
    });
    this.destination.order_description = descriptionLines.join('\r\n');

    this.updateCargoCount();
    this.onChange();
  }

  ngDoCheck(): void {
    if(this.validateState != null)
      this.validator.validateState(this.validateState);
  }

  onSave() {
    this.destination.time_slot_begin = this.timeSlotBegin ? this.timeSlotBegin.toString() : null;
    this.destination.time_slot_end = this.timeSlotEnd ? this.timeSlotEnd.toString() : null;
    this.destination.pay_method = this.payMethod;
    this.destination.delivery_to = this.deliveryTo;
  }

  onRemove() {
    this.onDelete.emit(this.destination);
  }

  onChangeAssembly() {
    if(this.destination.assembly) {
      if(!this.destination.assembly_count)
        this.destination.assembly_count = 1;
    }
    this.onChange();
  }

  onChangeAssemblyCount() {
    this.destination.assembly = this.destination.assembly_count > 0;
    this.onChange();
  }

  trackingServiceIdentifierToName(trackingServiceIdentifier: string): string {
    return this.trackingServicesMap.has(trackingServiceIdentifier)
      ? this.trackingServicesMap.get(trackingServiceIdentifier).name
      : trackingServiceIdentifier;
  }

  onClickTrackNumber(trackNumber: string): void {
    this.clipboardService.saveToClipboard(trackNumber, `Трек-номер "${trackNumber}" скопирован в буфер обмена`);
  }

  onChange(): void {
    this.onUpdate.emit(this.destination);
  }

  onChangeAddress(point: DestinationPoint): void {
    this.destination.destination = point;
    this.onChange();
  }

  onEditCargo() {
    this.isCargoClosing = !this.isCargoClosing;
    this.cargoOpenHideStream.next(!this.isCargoClosing);

    if(!this.isCargoOpened)
      this.editCargo();
  }

  onCargoChanged(lines: DeliveryProductLine[]): void {
    this.deliveryProduct.lines = lines;
    this.applyCargoEditResult();
  }
}
