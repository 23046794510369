import {Injectable} from "@angular/core";
import {Subject, Observable} from "rxjs";

@Injectable()
export class ClipboardService {
  private clipboardMessageStream = new Subject<string>();

  getClipboardMessageObservable(): Observable<string> {
    return this.clipboardMessageStream.asObservable();
  }

  saveToClipboard(value: string, message?: string): void {
    if(!navigator['clipboard'])
      return;

    navigator['clipboard'].writeText(value);

    if(!message)
      message = `"${value}" скопировано в буфер обмена`;

    this.clipboardMessageStream.next(message);
  }
}
