import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {OptimizationTask} from "../_models/optimization-task";

@Component({
  selector: 'complex-delivery-compact-destinations',
  templateUrl: './complex-delivery-compact-destinations.component.html',
  styleUrls: ['./complex-delivery-compact-destinations.component.css']
})
export class ComplexDeliveryCompactDestinationsComponent implements OnInit, OnChanges {
  @Input() task: OptimizationTask;

  showDestinations = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['task']) {
      if(!this.showDestinations && this.task.destinations.length < 3) {
          this.showDestinations = true;
      }
    }
  }

  onSwitchView(event: MouseEvent) {
    this.showDestinations = !this.showDestinations;
    event.stopPropagation();
  }
}
