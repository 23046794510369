import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AdjustmentCost} from "../_models/adjustment-cost";

class AdjustmentRow {
  description: string;
  client: number = 0;
  freighter: number = 0;
  mover: number = 0;
}

@Component({
  selector: 'cost-adjustment',
  templateUrl: './cost-adjustment.component.html',
  styleUrls: ['./cost-adjustment.component.css']
})
export class CostAdjustmentComponent implements OnInit, OnChanges {
  @Input() costAdjustments: AdjustmentCost[] = [];

  rows: AdjustmentRow[] = [];
  totals = new AdjustmentRow();
  enabledColumns = new Set<string>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rebuildRows();
    this.rebuildTotals();
  }

  private rebuildRows(): void {
    let rowsMap = new Map<string, AdjustmentRow>();
    this.enabledColumns.clear();
    for(let costAdjustment of this.costAdjustments) {
      let row = rowsMap.get(costAdjustment.adjustment_key);
      if(!row) {
        row = new AdjustmentRow();
        rowsMap.set(costAdjustment.adjustment_key, row);
      }
      row[costAdjustment.type] = costAdjustment.value;
      row.description = costAdjustment.description;

      if(costAdjustment.value)
        this.enabledColumns.add(costAdjustment.type);
    }

    this.rows = Array.from(rowsMap.values());
  }

  private rebuildTotals(): void {
    this.totals = new AdjustmentRow();
    for (let row of this.rows) {
      for (let key in row) {
        if (key !== 'description') {
          this.totals[key] = (this.totals[key] || 0) + row[key];
        }
      }
    }
  }
}
