import {CompanyClientLegalEntity} from "./company-client-legal-entity";

export class CompanyClient {
  id: number;
  identifier: string;
  name: string;
  legal_entities: CompanyClientLegalEntity[];
  available_route_optimization: boolean;
  available_custom_routes: boolean;
  tracking_services: string[];
}
