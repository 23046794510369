export class ObjectComparator {
  static Compare(Object1: any, Object2: any, loggable = false) {
    if(Object1 === Object2)
      return true;

    if(Object1 == null && Object2 == null)
      return true;
    if(Object1 == null || Object2 == null)
      return false;
    if(typeof Object1 !== 'object' || typeof Object2 !== 'object')
      return false;

    let obj1Keys = Object.keys(Object1);
    let obj2Keys = Object.keys(Object2);
    if (obj1Keys.length == 0 && obj2Keys.length == 0)
    {
      ObjectComparator.log('objects is empty', loggable);

      return true;
    }
    if (obj1Keys.length != obj2Keys.length)
    {
      ObjectComparator.log(`Lengths of objects is not match (${obj1Keys} <> ${obj2Keys})`, loggable);

      return false;
    }
    obj1Keys.forEach(key => {
      let obj1Type = typeof (Object1[key]);
      let obj2Type = typeof (Object2[key]);
      if(obj1Type != obj2Type) {
        ObjectComparator.log(`Types of values with '${key}' is not match (${obj1Type} <> ${obj2Type})`, loggable)
        return false;
      }

      if(obj1Type == 'object') {
        if(!ObjectComparator.Compare(Object1[key], Object2[key], loggable)) {
          ObjectComparator.log(`Objects with '${key}' is not match (${Object1} <> ${Object2})`, loggable)
          return false;
        }
      } else if (Object1[key] != Object2[key]){
        ObjectComparator.log(`Values with '${key}' is not match (${Object1[key]} <> ${Object2[key]})`, loggable)
        return false;
      }
    });
    return true;
  }

  private static log(message: string, loggable: boolean) {
    if(loggable)
      console.debug(message);
  }
}
