import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";

class VozovozService {
  name: string;
  price: number;
}

@Component({
  selector: '[vozovoz-calculation]',
  templateUrl: './vozovoz.calculation.component.html',
  styleUrls: ['./calculation.component.css']
})
export class VozovozCalculationComponent implements OnChanges {
  @Input() calculation: any;

  services: VozovozService[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['calculation']) {
      this.services = this.calculation['service'] || [];
    }
  }

}