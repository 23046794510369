import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DeliveryProduct} from "../_models/delivery-product";
import {UserInfoService} from "../_services/user-info.service";
import {Order} from "../_models/order";
import {OrderPeriod} from "../_models/order-period";
import {Destination} from "../_models/destination";
import {OrderService} from "../_services/order.service";

@Component({
  selector: 'delivery-product',
  templateUrl: './delivery-product.component.html',
  styleUrls: ['./delivery-product.component.css']
})
export class DeliveryProductComponent implements OnInit, OnChanges {
  @Input() order: Order;
  @Input() period: OrderPeriod;
  @Input() destination: Destination;
  @Input() product: DeliveryProduct;

  payMethodEdit = false;
  payMethod: string;
  enabledEdit: boolean;
  hasShipmentSize = false;
  hasShipmentWeight = false;

  constructor(private userInfoService: UserInfoService, private orderService: OrderService) { }

  ngOnInit() {
  }

  onSwitchPayMethodEdit() {
    this.payMethodEdit = !this.payMethodEdit;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.payMethod = this.product.pay_method || '';

    this.enabledEdit = this.userInfoService.isPrivilegedUser() && this.order != null && this.period != null && this.period.status !== 'finished';

    this.hasShipmentSize = !!(this.product.length || this.product.width || this.product.height);
    this.hasShipmentWeight = !!(this.product.weight);

    console.log(this.product);
  }

  onChangePayMethod() {
    this.orderService
      .updatePeriodDestinationPayMethod(
        this.order,
        this.period,
        this.destination,
        this.payMethod === '' ? null : this.payMethod,
        this.payMethod == 'card' && this.product.external_pay_link
      )
      .subscribe({
        next: () => {},
        error: () => {}
      })
  }
}
