
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {EmployeeSelectorDialogComponent} from "../employee-selector-dialog/employee-selector-dialog.component";
import {Freighter} from "../_models/freighter";
import {Employer} from "../_models/employer";
import {Editable} from "../_models/editable";
import {Crew} from "../_models/crew";
import {TransportSelectorDialogComponent} from "../transport-selector-dialog/transport-selector-dialog.component";
import {Transport} from "../_models/transport";
import {DateTime} from "date-time-js";
import {AlertService} from "../_services/alert.service";
import {CrewService} from "../_services/crew.service";
import {LoaderService} from "../_services/loader.service";
import {HttpErrorResponse} from "@angular/common/http";
import {EmployeeService} from "../_services/employer.service";
import {TariffTier} from "../_models/tariff-tier";
import {TransportService} from "../_services/transport.service";
import {UserInfoService} from "../_services/user-info.service";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {PaymentDistributionSchemaService} from "../_services/payment-distribution-schema.service";

class OptionalTier {
  constructor(public tier: TariffTier, public selected = false) {
  }
}

@Component({
  selector: 'crew-form',
  templateUrl: './crew-form.component.html',
  styleUrls: ['./crew-form.component.css']
})
export class CrewFormComponent implements OnInit, OnChanges {
  @Input() freighter: Freighter;
  @Input() date: Date;
  @Input() crew: Editable<Crew>;

  @Output() back = new EventEmitter<void>();
  @Output() saved = new EventEmitter<Crew>();
  @Output() statusUpdated = new EventEmitter<Crew>();

  @ViewChild('employeesDialog', { static: true }) employeesDialog: EmployeeSelectorDialogComponent;
  @ViewChild('transportsDialog', { static: true }) transportsDialog: TransportSelectorDialogComponent;

  startDate: Date;
  endDate: Date;

  private availableTiers: TariffTier[] = [];

  optionalTiers: OptionalTier[] = [];

  paymentDistributionSchemas: PaymentDistributionSchema[] = [];
  paymentDistributionSchema = new PaymentDistributionSchema();

  isEditAllowed = false;
  isChangeEmployerAllowed = false;

  constructor(
    private employerService: EmployeeService,
    private crewService: CrewService,
    private transportService: TransportService,
    private paymentDistributionSchemaService: PaymentDistributionSchemaService,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    console.log(this.date);
    this.initPeriod();
    this.initTiers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['crew']) {
      this.initPeriod();
      this.initTiers();
      this.initControls();
      this.initPaymentDistributionSchemas();
    }
  }

  private initControls(): void {
    this.isEditAllowed = this.crew.record.freighter.id == this.userInfoService.getFreighter().id;
    this.isChangeEmployerAllowed = this.crew.isNew || this.crew.record.employer.freighter.id === this.crew.record.freighter.id;
  }

  private initPeriod(): void {
    this.startDate = new Date(this.crew.record.start_date);
    this.endDate = new Date(this.crew.record.end_date);
  }

  private initTiers(): void {
    if(this.crew.record.transport)
      this.loadAvailableTiers();
  }

  private initPaymentDistributionSchemas(): void {
    this.paymentDistributionSchemaService
      .getFreighterPrivateSchemas(this.userInfoService.getFreighter())
      .subscribe(
        schemas => {
          this.paymentDistributionSchemas = schemas;
          this.preparePaymentDistributionSchemaForEdit();
        },
        () => {}
      )
    ;
  }

  private preparePaymentDistributionSchemaForEdit(): void {
    this.paymentDistributionSchema = this.crew.record.payment_distribution_schema;
    if(!this.paymentDistributionSchema) {
      this.paymentDistributionSchema = new PaymentDistributionSchema();
      this.paymentDistributionSchema.id = 0;
    }
  }

  private preparePaymentDistributionSchemaForSave(): void {
    this.crew.record.payment_distribution_schema = this.paymentDistributionSchema.id == 0 ? null : this.paymentDistributionSchema;
  }

  private loadAvailableTiers(): void {
    this.loaderService.show();

    this.transportService
      .getAvailableTiers(this.freighter, this.crew.record.transport.id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        tiers => {
          this.availableTiers = tiers;
          this.syncTiers();
        },
        () => {}
      );
  }

  private syncTiers(): void {
    this.optionalTiers = this.availableTiers.map(t => new OptionalTier(t));
    if(!this.crew.record.tariff_tiers || this.crew.record.tariff_tiers.length == 0)
      return;

    let tempTiers = new Map<number, OptionalTier>();
    for(let optionalTier of this.optionalTiers)
      tempTiers.set(optionalTier.tier.id, optionalTier);

    for(let tier of this.crew.record.tariff_tiers) {
      if(tempTiers.has(tier.id))
        tempTiers.get(tier.id).selected = true;
    }
  }

  private applyPeriod(): void {
    this.crew.record.start_date = this.startDate.toISOString();
    this.crew.record.end_date = this.endDate.toISOString();
  }

  private applySelectedTiers(): void {
    this.crew.record.tariff_tiers = this.optionalTiers.filter(ot => ot.selected).map(ot => ot.tier);
  }

  private add(): void {
    this.loaderService.show();
    this.crew.isSubmitting = true;

    this.crewService
      .addCrew(this.freighter, this.crew.record).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.crew.isSubmitting = false;
      }))
      .subscribe(
        r => {
          this.alertService.success('Экипаж добавлен', true);
          this.crew.record.id = +r.body.id;
          this.onSaved();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 409) {
            this.alertService.error('Такой экипаж в этом периоде уже есть');
          }
        }
      );
  }

  private update(): void {
    this.loaderService.show();
    this.crew.isSubmitting = true;

    this.crewService
      .updateCrew(this.freighter, this.crew.record).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.crew.isSubmitting = false;
      }))
      .subscribe(
        r => {
          this.alertService.success('Экипаж изменён', true);
          this.crew.record.id = +r.body.id;
          this.onSaved();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 409) {
            this.alertService.error('Такой экипаж в этом периоде уже есть');
          }
        }
      );
  }

  private disband(): void {
    this.loaderService.show();
    this.crewService
      .disbandCrew(this.freighter, this.crew.record).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success('Экипаж расформирован');
          this.onBack();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status == 405) {
            this.alertService.success('Экипаж расформирован');
            this.onBack();
          }
        }
      );
  }

  private switchStatus(): void {
    this.loaderService.show();

    this.employerService
      .updateStatusByFreighter(this.freighter, this.crew.record.employer, this.crew.record.employer.status === 'rest' ? 'free' : 'rest').pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onStatusUpdated(),
        () => this.onStatusUpdated()
      );
  }

  private isValidCrew(): boolean {
    return this.validateEmployer()
      && this.validateTransport()
      && this.validateLoaders()
      && this.validatePeriod()
    ;
  }

  private validateEmployer(): boolean {
    if(!this.crew.record.employer) {
      this.alertService.error('Укажите водителя');
      return false;
    }
    return true;
  }

  private validateTransport(): boolean {
    if(!this.crew.record.transport) {
      this.alertService.error('Укажите машину');
      return false;
    }
    return true;
  }

  private validateLoaders(): boolean {
    if(this.crew.record.loaders < 0) {
      this.alertService.error('Количество грузчиков должно быть больше и равно нулю');
      return false;
    }
    return true;
  }

  private validatePeriod(): boolean {
    let start = new DateTime(this.startDate);
    let end = new DateTime(this.endDate);
    let curDate = new DateTime();

    if(start.isGreaterOrEqual(end)) {
      this.alertService.error('Дата окончания периода должна идти после даты начала');
      return false;
    }

    if(start.isLess(curDate) && end.isLess(curDate)) {
      this.alertService.error('Период действия не может быть в прошлом');
      return false;
    }

    return true;
  }

  onSelectEmployee(): void {
    this.employeesDialog.showDialog();
  }

  onSelectTransport(): void {
    this.transportsDialog.showDialog();
  }

  onEmployeeSelected(employer: Employer): void {
    this.crew.record.employer = employer;
  }

  onTransportSelected(transport: Transport): void {
    this.crew.record.transport = transport;
    this.initTiers();
  }

  onSubmit(): void {
    this.applyPeriod();
    this.applySelectedTiers();
    this.preparePaymentDistributionSchemaForSave();

    if(!this.isValidCrew())
      return;

    if(this.crew.isNew)
      this.add();
    else
      this.update();
  }

  onBack(): void {
    this.back.emit();
  }

  private onSaved(): void {
    this.saved.emit(this.crew.record);
  }

  onDisband(): void {
    if(confirm('Подтверждаете расформирование экипажа?'))
      this.disband();
  }

  onSwitchStatus(): void {
    this.switchStatus();
  }

  onStatusUpdated(): void {
    this.statusUpdated.emit(this.crew.record);
  }
}
