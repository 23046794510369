import {Point} from "./point";
import {StringPoint} from "./string-point";
import {MapUtils} from "../_utils/map-utils";

declare var polyline: any;

export class Route {
  points: Point[];
  mapPoints: google.maps.LatLngLiteral[];
  milestones: Point[] = [];
  private nextMilestone: number;

  /**
   * Подготовка промежуточных точек маршрута
   *
   * @param interval временной интервал между точками в минутах
   */
  prepareMilestones(interval: number): void {
    if(this.points.length == 0)
      return;

    const intervalInSec = interval * 60;

    this.nextMilestone = this.points[0].t + intervalInSec;
    this.nextMilestone -= this.nextMilestone % intervalInSec;
    for(let i = 0, l = this.points.length; i < l; i++) {
      let point = this.points[i];
      if(point.t >= this.nextMilestone) {
        this.milestones.push(point);
        this.nextMilestone = point.t + intervalInSec - point.t % intervalInSec;
        // console.log('milestone created', point.t, this.nextMilestone)
      }
    }

    // console.log(this.points);
    // console.log(this.milestones);
  }

  /**
   * Обновление промежуточных точек маршрута, начиная с указанной точки от конца
   *
   * @param interval временной интервал между точками в минутах
   * @param numPointFromLast номер точки от конца
   */
  updateMilestonesFromLastPoints(interval: number, numPointFromLast: number): void {
    if(numPointFromLast == 0 || this.points.length == 0)
      return;

    if(!this.nextMilestone) {
      this.prepareMilestones(interval);
      return;
    }

    const intervalInSec = interval * 60;

    for(let i = this.points.length - numPointFromLast, l = this.points.length; i < l; i++) {
      let point = this.points[i];
      if(point.t >= this.nextMilestone) {
        this.milestones.push(point);
        this.nextMilestone = point.t + intervalInSec - point.t % intervalInSec;
      }
    }
  }

  static createFromPoints(points: Point[]): Route {
    let route = new Route();
    route.points = points;
    route.mapPoints = MapUtils.points2Map(points);

    return route;
  }

  static createFromStringPoints(points: StringPoint[]): Route {
    let route = new Route();
    route.points = [];
    route.mapPoints = [];

    for(let sourcePoint of points) {
      let point = new Point();
      point.lat = parseFloat(sourcePoint.lat);
      point.lon = parseFloat(sourcePoint.lon);

      route.points.push(point);
      route.mapPoints.push(MapUtils.point2Map(point));
    }

    return route;
  }

  static createFromString(path: String): Route {
    let route = new Route();
    if(path == '')
    {
      route.points = [];
      route.mapPoints = [];
      return route;
    }

    route.points = polyline.decode(path).map((coords: number[]) => {
      let point = new Point();
      point.lat = coords[0];
      point.lon = coords[1];

      return point;
    });
    route.mapPoints = MapUtils.points2Map(route.points);

    return route;
  }
}
