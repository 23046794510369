<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row" (click)="onClickComponent()">
<div class="no-padding orders-forms orders-form-box">
  <div>
  <form class="form-inline orders-forms">
    <div class="form-group search-box" *ngIf="!userService.isCustomer()">
      <input
        type="text"
        class="orders-search orders-control"
        name="search"
        [(ngModel)]="search"
        (ngModelChange)="onChangeFilter()"
        placeholder="Поиск"
        title="Для поиска по заказу используйте 'o номер заказа'"
        size="50">
    </div>
    <div class="form-group date" *ngIf="!userService.isCustomer()">
      <order-panel-datepicker [(value)]="after" [datepicker]="afterDatePickerOptions" [timepicker]="false" (dateChange)="onChangeFilter()" name="after" ngDefaultControl verticalStretch="true"></order-panel-datepicker>
    </div>
    <div class="form-group date" *ngIf="!userService.isCustomer()">
      <order-panel-datepicker [(value)]="before" [datepicker]="beforeDatePickerOptions" [timepicker]="false" (dateChange)="onChangeFilter()" name="before" ngDefaultControl verticalStretch="true"></order-panel-datepicker>
    </div>
    <div class="form-group">
      <div class="labeled-select" *ngIf="userService.isPrivilegedUser()">
        <label for="client-status">Статус оплаты заказа</label>
        <select class="form-control" id="client-status" [(ngModel)]="clientStatus" (change)="onChangeFilter()" name="client-status">
          <option value="" selected="selected">Любой</option>
          <option value="has_payment" class="opt-lost">Оплачен</option>
          <option value="has_not_payment" class="opt-lost">Не оплачен</option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="userService.isPrivilegedUser()">
      <div class="labeled-select">
        <label for="driver-status">Статус выплат/комиссий водителя</label>
        <select class="form-control" id="driver-status" [(ngModel)]="driverStatus" (change)="onChangeFilter()" name="driver-status">
          <option value="" selected="selected">Любой</option>
          <option value="has_fee" class="opt-lost">Комиссия списана</option>
          <option value="has_not_fee" class="opt-lost">Комиссия не списана</option>
          <option value="has_payout" class="opt-lost">Выплачено</option>
          <option value="has_not_payout" class="opt-lost">Не выплачено</option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="!userService.isCustomer()">
      <div class="labeled-select">
        <label for="order-status">Статус заказа</label>
        <select name="status"
                [(ngModel)]="orderStatus"
                (change)="onChangeFilter()"
                class="orders-control orders-status-select"
                id="order-status"
        >
          <option value="" selected="selected">любой</option>
          <option value="accepted" class="active">активные</option>
          <option value="negotiation,checking,agree" class="active">в поиске</option>
          <option value="ready,new,edit" class="active">редактируются</option>
          <option value="completed" class="opt-closed">завершённые</option>
          <option value="not_agree,declined,canceled_by_freighter,canceled_by_employer,canceled_by_client" class="opt-canceled">отменённые</option>
          <option value="no_answer,canceled_by_system" class="opt-lost">пропущенные</option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="userService.isPrivilegedUser()">
      <div class="labeled-select">
        <label for="company-client">Компания-клиент</label>
        <select name="companyFilter"
                [(ngModel)]="companyClientFilter"
                (change)="onChangeFilter()"
                id="company-client"
        >
          <option value="0" selected="selected">Любой</option>
          <option *ngFor="let company of companyClients" [value]="company.id">{{ company.name }}</option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="userService.isPrivilegedUser()">
      <div class="labeled-select">
        <label for="debt">Задолженность</label>
        <select name="debtFilter"
                [(ngModel)]="debtFilter"
                (change)="onChangeFilter()"
                id="debt"
        >
          <option value="" selected="selected">Игнорировать</option>
          <option value="0">Есть</option>
          <option value="5">Более 5 дней</option>
          <option value="10">Более 10 дней</option>
          <option value="15">Более 15 дней</option>
          <option value="30">Более 30 дней</option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="userService.isPrivilegedUser()">
      <div class="labeled-select">
        <label for="payMethod">Тип оплаты</label>
        <select name="payMethodFilter"
                [(ngModel)]="payMethodFilter"
                (change)="onChangeFilter()"
                id="payMethod"
        >
          <option value="" selected="selected">Любой</option>
          <option value="card">Банковская карта</option>
          <option value="cash">Наличные</option>
          <option value="bank">Банковский перевод</option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="userService.isCustomer()">
      <div class="labeled-select">
        <label for="statusFilter">Статус заказа</label>
        <select name="status" [(ngModel)]="orderStatus" (change)="onChangeFilter()" id="statusFilter">
          <option value="accepted,completed,agree" selected="selected">Любой</option>
          <option value="accepted">Активные</option>
          <option value="completed">Завершённые</option>
          <option value="agree">На согласовании</option>
        </select>
      </div>
    </div>
    <div class="separator" *ngIf="userService.isPrivilegedUser()"></div>
    <div class="form-group search-box" *ngIf="userService.isPrivilegedUser()">
      <input
              type="text"
              class="orders-search orders-control"
              name="trackNumber"
              [(ngModel)]="trackNumberFilter"
              (ngModelChange)="onChangeFilter()"
              placeholder="Трек-номер"
              size="50">
    </div>
    <div class="form-group search-box" *ngIf="userService.isPrivilegedUser()">
      <input
              type="text"
              class="orders-search orders-control"
              name="cargoCode"
              [(ngModel)]="cargoCodeFilter"
              (ngModelChange)="onChangeFilter()"
              placeholder="Артикул товара"
              size="50">
    </div>
    <div class="form-group" *ngIf="userService.isPrivilegedUser()">
      <div class="freighters">
        <div class="title">Перевозчики</div>
        <div class="selected">
          <div class="freighter item" *ngFor="let f of freightersFilter">
            <span class="name">{{ f.name }}</span>
          </div>
          <div class="employer item" *ngFor="let e of employersFilter">
            <span class="name">{{ e.account|fullName }}</span>
          </div>
          <div class="edit item">
            <span class="action" (click)="onShowFreightersDialog()">Выбрать</span>
          </div>

        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="!orders.filter.isEmpty()">
      <div class="filter-controls-box">
        <span class="clean-filter" (click)="onCleanFilter()">очистить фильтр</span>
      </div>
    </div>
  </form>
  </div>
</div>

<!--<hr class="form-list-divider">-->

<div class="no-padding actions" *ngIf="userService.isPrivilegedUser()">
  <div class="selected-count" *ngIf="userService.isPrivilegedUser()">
    <pager [list]="orders" (onPage)="onPaginationPage($event)" [verticalStretch]="true"></pager>
    <div class="title">Выбрано:</div>
    <div class="value">{{ selectedOrders }}</div>
  </div>
  <div class="action" *ngIf="userService.isSuperUser()">
    <div class="btn-group">
      <button type="button" [class.btn-disabled]="!hasSelection" class="actions-menu-button" [disabled]="!hasSelection" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Действия</button>
      <ul class="dropdown-menu">
        <li (click)="onAcceptOrdersPayment()" class="item">
          <div class="icon"><img src="/assets/images/order-actions/ic-24_20-payments.svg" alt=""></div>
          <div class="text">Подтвердить оплату заказов</div>
        </li>
        <li (click)="onCancelOrdersPayment()" class="item">
          <div class="icon"><img src="/assets/images/order-actions/ic-24_20-payments.svg" alt=""></div>
          <div class="text">Отменить оплату заказов</div>
        </li>
        <li role="separator" class="divider"></li>
        <li (click)="onAcceptOrdersPayout()" class="item">
          <div class="icon"><img src="/assets/images/order-actions/ic-24_20-payouts.svg" alt=""></div>
          <div class="text">Подтвердить выплату водителям</div>
        </li>
        <li (click)="onCancelOrdersPayout()" class="item">
          <div class="icon"><img src="/assets/images/order-actions/ic-24_20-payouts.svg" alt=""></div>
          <div class="text">Отменить выплату водителям</div>
        </li>
        <li role="separator" class="divider"></li>
        <li (click)="onSendRouteSheets()" class="item">
          <div class="icon"><img src="/assets/images/order-actions/route.png" alt=""></div>
          <div class="text">Отправить маршрутные листы</div>
        </li>
      </ul>
    </div>
  </div>
  <div class="action" *ngIf="!userService.isSuperUser() && (userService.isSupportUser() || userService.isFreighter())">
    <div class="btn-group">
      <button type="button" [class.btn-disabled]="!hasSelection" class="actions-menu-button" [disabled]="!hasSelection" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Действия</button>
      <ul class="dropdown-menu">
        <li (click)="onSendRouteSheets()" class="item">
          <div class="icon"><img src="/assets/images/order-actions/route.png" alt=""></div>
          <div class="text">Отправить маршрутные листы</div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="summary" *ngIf="userService.isPrivilegedUser()">
  <div class="payments block">
    <div class="title">Заказы</div>

    <div class="by-payments-status">
      <div class="total">
        {{ summaryReportV2.orders_report.total|humanNumber }} <span class="currency">₽</span>
        <adjustments-sum [sum]="summaryReportV2.adjustments_report.client_adjustments"></adjustments-sum>
        <div class="sub-total">
          <div class="count">{{ summaryReportV2.orders_report.total_count }} {{ getOrdersCountPlural(summaryReportV2.orders_report.total_count) }}</div>
        </div>
      </div>

      <div class="force-calc">
        <button type="button" class="calc-btn" title="Посчитать по фильтру" (click)="onForceCalc()"><span class="glyphicon glyphicon-flash"></span></button>
      </div>
    </div>

    <div class="by-pay-method">

      <div class="sub-total" title="Наличные">
        <div class="icon"><img src="/assets/images/summary/ic-24_20-paymethod-cash.svg" alt="Наличные"></div>
        <div class="values">
          <div class="sum">{{ summaryReportV2.orders_report.cash_pay_sum|humanNumber }} <span class="currency">₽</span></div>
          <div class="count">{{ summaryReportV2.orders_report.cash_pay_count }} {{ getOrdersCountPlural(summaryReportV2.orders_report.cash_pay_count) }}</div>
        </div>
      </div>

      <div class="sub-total" title="Банковской картой">
        <div class="icon"><img src="/assets/images/summary/ic-24_20-paymethod-card.svg" alt="Банковской картой"></div>
        <div class="values">
          <div class="sum">{{ summaryReportV2.orders_report.card_pay_sum|humanNumber }} <span class="currency">₽</span></div>
          <div class="count">{{ summaryReportV2.orders_report.card_pay_count }} {{ getOrdersCountPlural(summaryReportV2.orders_report.card_pay_count) }}</div>
        </div>
      </div>

      <div class="sub-total" title="Банковский перевод">
        <div class="icon"><img src="/assets/images/summary/ic-24_20-paymethod-bank.svg" alt="Банковской перевод"></div>
        <div class="values">
          <div class="sum">{{ summaryReportV2.orders_report.bank_pay_sum|humanNumber }} <span class="currency">₽</span></div>
          <div class="count">{{ summaryReportV2.orders_report.bank_pay_count }} {{ getOrdersCountPlural(summaryReportV2.orders_report.bank_pay_count) }}</div>
        </div>
      </div>

    </div>

<!--    <div class="controls">-->
<!--      <button type="button" class="btn btn-sm btn-default">Выставить счета</button>-->
<!--    </div>-->
  </div>

  <div class="payouts block">
    <div class="title">Заявки</div>
    <div class="total">
      {{ summaryReportV2.drafts_report.all_cost_sum|humanNumber }} <span class="currency">₽</span>
      <div class="sub-total">
        <div class="count">{{ summaryReportV2.drafts_report.all_count }} {{ getDraftsCountPlural(summaryReportV2.drafts_report.all_count) }}</div>
      </div>
    </div>

    <div class="sub-payments">
      <div class="sub-total" title="Отменено">
        <div class="icon"><img src="/assets/images/summary/ic-24_20-cancel.svg" alt="Отменено"></div>
        <div class="values">
          <div class="sum">{{ summaryReportV2.drafts_report.cancelled_cost_sum|humanNumber }} <span class="currency">₽</span></div>
          <div class="count">{{ summaryReportV2.drafts_report.cancelled_count }} {{ getDraftsCountPlural(summaryReportV2.drafts_report.cancelled_count) }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="payouts block">
    <div class="title">Комиссии и скидки</div>
    <div class="total">
      {{ summaryReportV2.orders_report.mover_fee_sum|humanNumber }} <span class="currency">₽</span>
      <adjustments-sum [sum]="summaryReportV2.adjustments_report.mover_adjustments"></adjustments-sum>
      <div class="sub-total"><div class="count">комиссия</div></div>
    </div>

    <div class="sub-payments">
      <div class="sub-total" title="Бонусные баллы">
        <div class="icon"><img src="/assets/images/summary/ic-24_20-paymethod-promo.svg" alt="Бонусные баллы"></div>
        <div class="values">
          <div class="sum">{{ summaryReportV2.orders_report.bonus_sum|humanNumber }} <span class="currency">₽</span></div>
          <div class="count">бонусные баллы</div>
        </div>
      </div>
      <div class="sub-total" title="Скидки и промокоды">
        <div class="icon"><img src="/assets/images/summary/ic-24_20-paymethod-promo.svg" alt="Скидки и промокоды"></div>
        <div class="values">
          <div class="sum">{{ summaryReportV2.orders_report.promo_discount|humanNumber }} <span class="currency">₽</span></div>
          <div class="count">скидки и промокоды</div>
        </div>
      </div>
    </div>
  </div>

  <div class="payouts block">
    <div class="title">Деб. задолженность</div>
    <div class="total">
      {{ summaryReportV2.client_negative_balance_report.negative_balance_sum|humanNumber }} <span class="currency">₽</span>
    </div>
  </div>

  <div class="payouts block">
    <div class="title">Водители</div>
    <div class="total">
      {{ summaryReportV2.executor_earnings_report.executor_earnings_sum|humanNumber }} <span class="currency">₽</span>
      <adjustments-sum [sum]="summaryReportV2.adjustments_report.freighter_adjustments"></adjustments-sum>
      <div class="sub-total"><div class="count">заработано</div></div>
    </div>

    <div class="sub-payments">
      <div class="sub-total" title="Сумма положительных балансов">
        <div class="icon"><img src="/assets/images/summary/ic-24_20-driver.svg" alt="Сумма положительных балансов"></div>
        <div class="values">
          <div class="sum">{{ summaryReportV2.executor_positive_balance_report.positive_balance_sum|humanNumber }} <span class="currency">₽</span></div>
          <div class="count">положительные балансы</div>
        </div>
      </div>
      <!--      <div class="sub-total" title="Водители">-->
      <!--        <div class="icon"><img src="/assets/images/summary/ic-24_20-driver.svg" alt="Водители"></div>-->
      <!--        <div class="values">-->
      <!--          <div class="sum">{{ summaryReport.employee_payouts_sum|humanNumber }} <span class="currency">₽</span></div>-->
      <!--          <div class="count">Водители</div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>

</div>

<div #ordersContainer class="orders-container">
<div class="no-padding actions" *ngIf="(userService.isCustomer() || userService.isFreighter()) && orders.totalCount > orders.pageSize">
  <div class="selected-count">
    <pager [list]="orders" (onPage)="onPaginationPage($event)" [verticalStretch]="true"></pager>
  </div>
</div>
<table class="orders-table">
  <thead>
  <tr class="orders-table-header">
    <td class="select-all detail" (click)="onClickSelectAll()">
      <div class="pretty selector" *ngIf="userService.isPrivilegedUser()">
        <input type="checkbox" name="selectAll" [ngModel]="selectAll">
        <label><i class="g-mdi" data-icon="done"></i></label>
      </div>
    </td>
    <th>Номер</th>
    <th>Период</th>
    <th>Клиент</th>
    <th>Схема работы</th>
    <th>Водитель</th>
    <th>Транспорт</th>
    <th class="detail">Тип оплаты</th>
    <th>Стоимость</th>
    <th>Статус заказа</th>
    <th></th>
  </tr>
  </thead>
  <tbody *ngFor="let order of orders.orders" class="order-table-row">
  <tr [class.selected-order]="orderSelects.get(order.id)">
    <td
        rowspan="4"
        class="order-select detail"
        [class.border-complete]="statuses['complete'].indexOf(order.status) > -1"
        [class.border-canceled]="statuses['canceled'].indexOf(order.status) > -1"
        [class.border-new]="statuses['new'].indexOf(order.status) > -1"
        [class.border-pending]="statuses['pending'].indexOf(order.status) > -1"
        [class.border-agreement]="statuses['agreement'].indexOf(order.status) > -1"
        (click)="onSelectOrder(order)"
    >
      <div class="pretty selector" *ngIf="userService.isPrivilegedUser()">
        <input type="checkbox" name="orderSelector_{{ order.id }}" [ngModel]="orderSelects.get(order.id)">
        <label><i class="g-mdi" data-icon="done"></i></label>
      </div>
    </td>
    <td rowspan="2">
      <div class="order-number" (click)="onClickOrder(order)">
        <a [routerLink]="['/orders', order.freighter.id, order.id]">{{ order.draft.id }}</a>
        <div class="sub-data">(<a [routerLink]="['/orders', order.freighter.id, order.id]">{{ order.id }}</a>)</div>
      </div>
      <div class="sub-data">{{ order.created_at | dt:'dd.MM.yy' }} / {{ order.created_at | dt:'H:mm' }}</div>
      <div *ngIf="isTest(order)" class="test-order">[тест]</div>
      <div class="order-tags" *ngIf="order.tags">
        <span class="tag" *ngFor="let tag of order.tags">#{{ tag }} </span>
      </div>
    </td>
    <td rowspan="2">
      <div *ngFor="let period of order.periods">
        <div class="text-nowrap">{{ period.start | dt:'H:mm' }} - {{ period.end | dt:'H:mm' }}</div>
        <div *ngIf="!isPeriodDateEqualsOrderDate(order)" class="sub-data">{{ period.start | dt:'dd.MM.yy' }}</div>
        <div *ngIf="!(order.draft.city|myTz)" class="city-time" title="местное время">
          <div class="text-nowrap">{{ period.start | withCityTz:order.draft.city | dt:'H:mm' }} - {{ period.end | withCityTz:order.draft.city | dt:'H:mm' }}</div>
          <div *ngIf="!isPeriodDateEqualsOrderDate(order)" class="sub-data">{{ period.start | withCityTz:order.draft.city | dt:'dd.MM.yy' }}</div>
        </div>
      </div>
    </td>
    <td>
      {{ order.draft.client | fullName }}
      <div class="sub-data">{{ order.draft.client.phone|formatPhone }}</div>
    </td>
    <td>
      <span *ngIf="order.draft.delivery">{{ order.draft.delivery_schema?.name }}</span>
      <span *ngIf="!order.draft.delivery">Грузовое такси</span>
      <div class="cargo-storage" *ngIf="order.draft.cargo_storage"></div>
    </td>
    <td rowspan="3">
      <div class="freighter" *ngIf="userService.isPrivilegedUser()" title="компания">{{ order.freighter.name }}</div>
      <div *ngFor="let period of order.periods">
        <div>{{ period?.employer?.account | fullName }}</div>
        <div class="sub-data">{{ period.employer?.account.phone|formatPhone }}</div>
      </div>
    </td>
    <td rowspan="3">
      <div *ngFor="let period of order.periods">
        {{ period?.transport | transportDescription }}
      </div>
    </td>
    <td rowspan="4" class="detail">
      {{ order.pay_method|payMethod }}
      <div *ngIf="order.promo_code_instances && order.promo_code_instances.length > 0">
        <div class="promo-code" title="Промокод" *ngFor="let codeInstance of order.promo_code_instances">{{ codeInstance.promo_code.code }}</div>
      </div>
    </td>
    <td rowspan="4">
      <div class="sum">
        <span *ngIf="order.status != 'completed'">{{ order.cost|humanNumber }}</span>
        <span *ngIf="order.status == 'completed'">{{ order.for_payment|humanNumber }}</span>
        <span class="currency">₽</span>
      </div>
      <div *ngIf="order.status == 'completed'">
        <div *ngIf="order.cost != order.for_payment">
          <div class="sub-data">Стоимость заказа:</div>
          <div class="sub-data">{{ order.cost|humanNumber }} <span class="currency">₽</span></div>
        </div>
        <div *ngIf="order.discount > 0 || order.fixed_discount > 0">
          <div class="sub-data">Применена скидка:</div>
          <div *ngIf="order.discount > 0" class="sub-data">{{ order.discount }}%</div>
          <div *ngIf="order.fixed_discount > 0" class="sub-data">{{ order.fixed_discount|humanNumber }} <span class="currency">₽</span></div>
        </div>
        <div *ngIf="order.payed_from_account_sum > 0">
          <div class="sub-data">Оплачено со счёта клиента:</div>
          <div class="sub-data">{{ order.payed_from_account_sum|humanNumber }} <span class="currency">₽</span></div>
        </div>
        <div *ngIf="orderPaymentReports.has(order.id) && orderPaymentReports.get(order.id).employer_fee_sum > 0">
          <div class="sub-data">Комиссия сервиса:</div>
          <div class="sub-data">{{ orderPaymentReports.get(order.id).employer_fee_sum|humanNumber }} <span class="currency">₽</span></div>
        </div>
        <div *ngIf="orderPaymentReports.has(order.id) && orderPaymentReports.get(order.id).employer_tax_sum > 0">
          <div class="sub-data">Налог:</div>
          <div class="sub-data">{{ orderPaymentReports.get(order.id).employer_tax_sum|humanNumber }} <span class="currency">₽</span></div>
        </div>
        <div *ngIf="paymentStates.get(order.id).feeAmount">
          <div class="sub-data">Комиссия к оплате:</div>
          <div class="sub-data">{{ paymentStates.get(order.id).feeAmount|humanNumber }} <span class="currency">₽</span></div>
        </div>
        <div *ngIf="paymentStates.get(order.id).payoutAmount">
          <div class="sub-data">К выплате:</div>
          <div class="sub-data">{{ paymentStates.get(order.id).payoutAmount|humanNumber }} <span class="currency">₽</span></div>
        </div>
        <div *ngIf="hasAdjustments(order)" class="adjustments">
          <div>
            <div class="sub-data">Корректировки:</div>
          </div>
          <div *ngFor="let state of [getAdjustmentsState(order)]">
            <div *ngIf="state.clientSum != 0" [class.plus]="state.clientSum > 0" [class.minus]="state.clientSum < 0">
              <div class="sub-data">Клиент:</div>
              <div class="sub-data">{{ state.clientSum|humanNumber:0:true }} <span class="currency">₽</span></div>
            </div>
            <div *ngIf="state.freighterSum != 0" [class.plus]="state.freighterSum > 0" [class.minus]="state.freighterSum < 0">
              <div class="sub-data">Перевозчик:</div>
              <div class="sub-data">{{ state.freighterSum|humanNumber:0:true }} <span class="currency">₽</span></div>
            </div>
            <div *ngIf="state.moverSum != 0" [class.plus]="state.moverSum > 0" [class.minus]="state.moverSum < 0">
              <div class="sub-data">Mover:</div>
              <div class="sub-data">{{ state.moverSum|humanNumber:0:true }} <span class="currency">₽</span></div>
            </div>
          </div>

        </div>
      </div>
    </td>
    <td rowspan="4">
      <div>{{ order.status | orderStatus }}</div>
      <div *ngIf="paymentStates.get(order.id).hasPaymentDebt()" class="has-debt">Задолженность клиента</div>
      <div *ngIf="paymentStates.get(order.id).hasFeeDebt()" class="has-debt">Задолженность водителя</div>
      <div *ngFor="let period of order.periods">
        <span *ngIf="period.no_points">
          <img src="/assets/images/no-gps.png" alt="Отсутствуют точки" title="Отсутствуют точки">
        </span>
      </div>
    </td>
    <td (click)="onClickOrder(order)" class="open-order" rowspan="4">
      <a [routerLink]="['/orders', order.freighter.id, order.id]"><img src="/assets/images/order-actions/route.png" alt=""></a>
    </td>
  </tr>
  <tr class="inner-subrow" [class.selected-order]="orderSelects.get(order.id)">
    <td colspan="2">
      <div *ngIf="order.draft.delivery">
        <div class="company">[{{ order.draft.delivery_company_client?.name }}]</div>
        <div *ngIf="order.draft.client.company_client && order.draft.client.company_client.id != order.draft.delivery_company_client.id" class="company">[{{ order.draft.client.company_client.name }}]</div>
      </div>
      <div *ngIf="!order.draft.delivery">
        <div *ngIf="order.draft.client.company_client" class="company">[{{ order.draft.client.company_client.name }}]</div>
      </div>
    </td>
  </tr>
  <tr class="inner-subrow" [class.selected-order]="orderSelects.get(order.id)">
    <td class="additional" colspan="2">
      <div class="comment-wrapper detail" *ngIf="order.notes">
        <div class="notes">
          <div class="header">Заметки:</div>
          <div class="text">{{ order.notes }}</div>
        </div>
      </div>
    </td>
    <td colspan="2">
      <div class="comment-wrapper detail" *ngIf="order.draft.comment != ''">
        <div class="comment">
          <div class="header">Коментарии:</div>
          <div class="text">{{ order.draft.comment }}</div>
        </div>
      </div>
    </td>
  </tr>
  <tr class="last-subrow" [class.selected-order]="orderSelects.get(order.id)">
    <td colspan="2"></td>
    <td class="additional" colspan="2">
      <div *ngFor="let send of getRouteSheetSends(order)" class="route-sheet-send" [class.sent]="isRouteSheetSent(send)">
        Маршрутный лист №{{ send.full_number }} от {{ send.registered_at|dt:"dd.MM.yy" }}
        <div class="scheduled">Отправка запланирована на {{ send.scheduled_at|dt:"HH:mm" }}</div>
      </div>
      <div *ngIf="order.draft.legal_entity" class="legal-entity payment-status-{{ paymentStates.get(order.id).paymentStatus }}">
        <strong>Юридическое лицо:</strong>
        <br>
        {{ order.draft.legal_entity.name }}
      </div>
      <div
        class="payment-status payment-status-{{ paymentStates.get(order.id).paymentStatus }}"
        *ngIf="paymentStates.get(order.id).paymentStatus != null"
        (click)="onClickClientPaymentStatus(order, $event)"
        [class.changable]="userService.isPrivilegedUser() && order.pay_method == 'bank' && refreshStatesCounter"
      >
        {{ paymentStates.get(order.id).paymentStatus|orderPaymentStatus:"entity" }}
        <div class="decline-reason" *ngIf="paymentStates.get(order.id).declinePaymentReason">({{ paymentStates.get(order.id).declinePaymentReason }})</div>
      </div>
      <div class="bill-info payment-status-{{ paymentStates.get(order.id).paymentStatus }}" *ngFor="let bill of order.bills" title="Время выставления: {{ bill.created_at|dt:'HH:mm' }}">
        Счёт №М-{{ order.draft.id }}<span *ngIf="bill.version > 0">-{{ bill.version }}</span> на сумму {{ bill.cost|humanNumber }} <span class="currency">₽</span>
        выставлен {{ bill.created_at|dt:'dd.MM.yy' }}
        <br>
        и отправлен на {{ formatEmails(bill.emails) }}
      </div>
      <div class="act-info payment-status-{{ paymentStates.get(order.id).paymentStatus }}" *ngFor="let act of order.acts" title="Время выставления: {{ act.created_at|dt:'HH:mm' }}">
        Акт №М-{{ order.draft.id }}<span *ngIf="act.version > 0">-{{ act.version }}</span> на сумму {{ act.cost|humanNumber }} <span class="currency">₽</span>
        выставлен {{ act.created_at|dt:'dd.MM.yy' }}
        <br>
        и отправлен на {{ formatEmails(act.emails) }}
      </div>
      <div class="debt-info" *ngIf="paymentStates.get(order.id).hasPaymentDebt()">
        <span class="glyphicon glyphicon-exclamation-sign"></span> Задолженность • {{ paymentStates.get(order.id).getPaymentDebtDays()|days }}
      </div>
    </td>
    <td colspan="2">
      <div class="no-fee-warn" *ngIf="!order.write_off_employer_fee">Без комиссии</div>
      <div
        class="payment-status payment-status-{{ paymentStates.get(order.id).feeStatus }}"
        *ngIf="paymentStates.get(order.id).feeStatus != null"
      >
        {{ paymentStates.get(order.id).feeStatus|orderPaymentStatus:"employee_fee" }}
        <div class="decline-reason" *ngIf="paymentStates.get(order.id).declineFeeReason">({{ paymentStates.get(order.id).declineFeeReason }})</div>
      </div>
      <div class="debt-info" *ngIf="paymentStates.get(order.id).hasFeeDebt()">
        <span class="glyphicon glyphicon-exclamation-sign"></span> Задолженность • {{ paymentStates.get(order.id).getFeeDebtDays()|days }}
      </div>
      <div
        class="payment-status payment-status-{{ paymentStates.get(order.id).payoutStatus }}"
        *ngIf="paymentStates.get(order.id).payoutStatus != null"
      >
        {{ paymentStates.get(order.id).payoutStatus|orderPaymentStatus:"employee" }}
        <div class="decline-reason" *ngIf="paymentStates.get(order.id).declinePayoutReason">({{ paymentStates.get(order.id).declinePayoutReason }})</div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
<div class="show-more-btn">
  <show-more [list]="orders" (onPage)="onShowMorePage($event)" #showMoreOrders></show-more>
</div>
<div class="payment-menu" [style.left.px]="clientPaymentMenuOffset.left" [style.top.px]="clientPaymentMenuOffset.top" [class.visible]="clientPaymentMenuVisible">
  <ul>
    <li class="menu-item" (click)="onAcceptOrderPayment()" *ngIf="userService.isSuperUser()"><div class="marker paid"></div> Оплачено</li>
    <li class="menu-item" (click)="onCancelOrderPayment()" *ngIf="userService.isSuperUser()"><div class="marker not-paid"><div class="inner-circle"></div></div> Не оплачено</li>
    <li class="menu-item" (click)="onSendBill()"><div class="marker bill"><img src="/assets/images/summary/ic-24_20-paymethod-bank.svg" alt=""></div> Отправить счёт и акт</li>
  </ul>
</div>
<div class="payment-menu" [style.left.px]="driverPayoutMenuOffset.left" [style.top.px]="driverPayoutMenuOffset.top" [class.visible]="driverPayoutMenuVisible">
  <ul>
    <li class="menu-item" (click)="onAcceptOrderPayout()"><div class="marker paid"></div> Выплачено</li>
    <li class="menu-item" (click)="onCancelOrderPayout()"><div class="marker not-paid"><div class="inner-circle"></div></div> Не выплачено</li>
  </ul>
</div>
</div>

</div>

<freighters-fast-list-dialog (savedFreighters)="onChangedFreightersFilter($event)"
                             (savedEmployers)="onChangesEmployersFilter($event)"
                             ></freighters-fast-list-dialog>
