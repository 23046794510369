
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {DeliveryProductTrackingStat} from "../_models/delivery-product-tracking-stat";

@Injectable()
export class DeliveryTrackingService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getDeliveryProductsTrackingStat(trackingService: string, externalTrackNumbers: string[]): Observable<DeliveryProductTrackingStat[]> {
    let trackNumbersParams = {};
    for(let i = 0; i < externalTrackNumbers.length; i++) {
      trackNumbersParams[`externalTrackNumbers[${i}]`] = externalTrackNumbers[i];
    }

    return this._requestService
      .get(`/delivery/tracking/products/${trackingService}/stat.json`, trackNumbersParams).pipe(
      map(res => res.body as DeliveryProductTrackingStat[]))
    ;
  }
}
