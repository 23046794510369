import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DateTime} from "date-time-js";

const DATE_PATTERN = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
const TIME_PATTERN = /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/;

@Component({
  selector: 'date-time',
  templateUrl: './date-time-field.component.html',
  styleUrls: ['./date-time-field.component.css']
})
export class DateTimeFieldComponent implements OnInit, OnChanges {
  @Input() value: Date;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;

  @Output() valueChange = new EventEmitter<Date>();

  date: string;
  time: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyInput();
  }

  private applyInput(): void {
    if(this.value) {
      let dateTime = new DateTime(this.value);
      this.date = dateTime.format('yyyy-MM-dd');
      this.time = dateTime.format('HH:mm:ss');
    } else {
      this.date = '';
      this.time = '';
    }
  }

  private applyOutput(): void {
    if(this.isDateValid() && this.isTimeValid()) {
      this.valueChange.emit(new Date(`${this.date}T${this.time}`));
    }
  }

  private isDateValid(): boolean {
    return DATE_PATTERN.test(this.date);
  }

  private isTimeValid(): boolean {
    return TIME_PATTERN.test(this.time);
  }

  onChangeValue(): void {
    this.applyOutput();
  }
}
