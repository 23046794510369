<div class="col-xs-4 col-md-4 col-centered col-slider no-padding row">
  <h1 *ngIf="client">{{ client.name }}</h1>
  <h2>Юр. лица</h2>
  <div class="list-controls">
    <div class="control" routerLink="/freighter-clients">
      <div><span class="glyphicon glyphicon-chevron-left"></span> Вернуться к списку
      </div>
    </div>
    <div class="control" (click)="onAdd()">
      <div class="add-entity"><span class="glyphicon glyphicon-plus"></span> Добавить юр. лицо</div>
    </div>
  </div>
  <div class="entities-container">
    <table class="entities-table">
      <thead class="entities-table-header">
      <tr>
        <th width="25%">ИНН</th>
        <th>Наименование компании</th>
        <th width="10%"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let entity of entities">
        <td class="content"
            [routerLink]="['/freighter-clients', clientId, 'legal-entities', entity.id]">{{ entity.inn }}</td>
        <td class="content"
            [routerLink]="['/freighter-clients', clientId, 'legal-entities', entity.id]">{{ entity.short_name || entity.name }}</td>
        <td class="text-nowrap controls">
          <a [routerLink]="['/freighter-clients', clientId, 'legal-entities', entity.id]"
             class="btn btn-default btn-xs"><span class="glyphicon glyphicon-pencil"></span></a>
          <button (click)="onUnlink(entity)" type="button" class="btn btn-default btn-xs"><img
            src="/assets/images/unlink.png" alt="" title="Удалить юр. лицо из компании"></button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<inn-dialog #innDialog (innChange)="onEnteredInn($event)"></inn-dialog>
