<legal-entity-detail [legalEntity]="employer.legal_entity" [connectionType]="'Юридическое лицо'" *ngIf="employer && employer.legal_entity"></legal-entity-detail>

<div class="details" *ngIf="employer && !employer.legal_entity && employer.features">
  <table class="table">
    <tbody>
    <tr>
      <td>Тип подключения</td>
      <td>Физическое лицо</td>
    </tr>
    <tr *ngIf="employer.features.INN">
      <td>ИНН</td>
      <td>{{ employer.features.INN }}</td>
    </tr>
    <tr *ngIf="employer.features.SNILS">
      <td>СНИЛС</td>
      <td>{{ employer.features.SNILS }}</td>
    </tr>
    <tr *ngIf="employer.features.Nomer_raschetnogo_scheta_kartyi_">
      <td>Номер расчетного счета карты</td>
      <td>{{ employer.features.Nomer_raschetnogo_scheta_kartyi_ }}</td>
    </tr>
    <tr *ngIf="employer.features.BIK_banka">
      <td>БИК банка</td>
      <td>{{ employer.features.BIK_banka }}</td>
    </tr>
    <tr *ngIf="employer.features.Sberbank">
      <td>Номер карты</td>
      <td>{{ employer.features.Sberbank }}</td>
    </tr>
    </tbody>
  </table>
</div>
