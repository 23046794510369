<div class="form-inline filter-controls">
  <div class="form-group search-box">
    <input
      type="text"
      class="interval-search filter-control"
      name="value"
      [(ngModel)]="value"
      (ngModelChange)="onChangeFilter()"
      placeholder="количество"
      size="5">
  </div>
  <div class="form-group search-box">
    <div class="labeled-select">
      <label for="unit">Единицы измерения</label>
      <select class="form-control" id="unit" name="unit" [(ngModel)]="unit" (ngModelChange)="onChangeFilter()">
        <option [value]="u.key" class="opt-lost" *ngFor="let u of units|mapToIterable">{{ u.val }}</option>
      </select>
    </div>
  </div>
</div>
