import {Component, Input, OnInit} from '@angular/core';
import {LegalEntity} from "../_models/legal-entity";

@Component({
  selector: 'legal-entity-detail',
  templateUrl: './legal-entity-detail.component.html',
  styleUrls: ['./legal-entity-detail.component.css']
})
export class LegalEntityDetailComponent implements OnInit {
  @Input() legalEntity: LegalEntity;
  @Input() connectionType: string;
  @Input() compact: boolean;

  constructor() { }

  ngOnInit() {
  }

}
