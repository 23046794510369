import {Component, OnInit, ViewChild} from '@angular/core';
import {Freighter} from "../_models/freighter";
import {UserInfoService} from "../_services/user-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Editable} from "../_models/editable";
import {Crew} from "../_models/crew";
import {FormErrors} from "../_models/form-errors";
import {DateTime} from "date-time-js";

const DEFAULT_CREW_PERIOD = 5;

@Component({
  selector: 'app-new-crew',
  templateUrl: './new-crew.component.html',
  styleUrls: ['./new-crew.component.css']
})
export class NewCrewComponent implements OnInit {
  freighter: Freighter;
  date: Date;

  crew = new Editable(new Crew(), new FormErrors(), true);

  constructor(
    private userInfoService: UserInfoService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.initPeriod();
    this.crew.record.loaders = 0;
  }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();
    this.crew.record.freighter = this.freighter;

    this.route.queryParams
      .subscribe(params => {
        if(params['date'] && /^[0-9]+$/.test(params['date'])) {
          console.log('use query date');
          this.date = new Date(parseInt(params['date']));
        } else {
          console.log('use current date');
          this.date = new Date();
        }
      });
  }

  private initPeriod(): void {
    this.crew.record.start_date = new Date().toISOString();
    let endDate = new DateTime();
    endDate.add(DEFAULT_CREW_PERIOD, 'year');
    this.crew.record.end_date = endDate.toDate().toISOString();
  }

  onBack(): void {
    this.router.navigate([`/crews`]);
  }

  onSaved(crew: Crew): void {
    this.router.navigate([`/crews`, crew.id], {
      queryParams: { date: this.date.getTime() }
    });
  }
}
