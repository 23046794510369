import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Passport} from "../_models/passport";

class Photo {
  constructor(public name, public photo) {
  }
}

@Component({
  selector: 'passport-view',
  templateUrl: './passport-view.component.html',
  styleUrls: ['./passport-view.component.css']
})
export class PassportViewComponent implements OnInit, OnChanges {
  @Input() passport: Passport;

  photos: Photo[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initPhotos();
  }

  private initPhotos(): void {
    const descriptors = [
      [ 'Первая страница', 'first_page' ],
      [ 'Вторая страница', 'second_page' ],
      [ 'Прописка', 'registration_page' ],
      [ 'Временная регистрация', 'temp_registration_photo' ],
      [ 'Селфи с первой страницей', 'selfie_with_first_page' ],
      [ 'Селфи с второй страницей', 'selfie_with_second_page' ],
    ];

    this.photos = [];
    for(let [name, field] of descriptors) {
      let photo = this.passport[field];
      if(!photo || photo == '')
        continue;

      this.photos.push(new Photo(name, photo));
    }
  }

  isPassportTypeOf(types: string[]): boolean {
    return types.indexOf(this.passport.type) != -1;
  }
}
