<div *ngIf="photos.length > 0" class="images-container mar8top">
  <div *ngFor="let photo of photos" class="img-box">
    <div class="del-image" title="Удалить" (click)="onDelete(photo)"><i class="material-icons">delete</i></div>
    <a href="{{ photo.url }}" [attr.data-lightbox]="'order-'+order.id"><img class="img-rounded" src="{{ photo.image_sizes.small.path }}" alt=""></a>

  </div>
</div>

<div class="clearfix"></div>

<div class="upload">
<!--  <input type="file"-->
<!--         ngFileSelect-->
<!--         [options]="options"-->
<!--         (onUpload)="handleUpload($event)"-->
<!--         (beforeUpload)="onBeforeUpload()"-->
<!--  >-->
</div>

<form (submit)="onSave()">
  <div class="form-group">
    <label>Комментарий</label>
    <textarea class="comment-area" name="comment" [(ngModel)]="comment" rows="4"></textarea>
  </div>
  <button type="submit" class="save-btn">Сохранить</button>
</form>
