
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {FreighterTransportTariff} from "../_models/freighter-transport-tariff";
import {Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class FreighterTransportTariffService {
  constructor(private _requestService: RequestWithErrorHandlerService) {
  }

  getTariff(id: number): Observable<FreighterTransportTariff> {
    return this._requestService
      .get(`/freighter/me/transport_tariffs/${id}.json`).pipe(
      map(r => r.body.freighter_transport_tariff as FreighterTransportTariff));
  }

  getTariffs(): Observable<FreighterTransportTariff[]> {
    return this._requestService
      .get('/freighter/me/transport_tariffs.json').pipe(
      map(r => r.body.freighter_transport_tariffs as FreighterTransportTariff[]));
  }

  addTariff(tariff: FreighterTransportTariff): Observable<HttpResponse<any>> {
    return this._requestService.post('/freighter/me/transport_tariffs.json', this.prepareTariffForRequest(tariff));
  }

  updateTariff(tariff: FreighterTransportTariff): Observable<HttpResponse<any>> {
    return this._requestService.put(`/freighter/me/transport_tariffs/${tariff.id}.json`, this.prepareTariffForRequest(tariff));
  }

  deleteTariff(tariff: FreighterTransportTariff): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/freighter/me/transport_tariffs/${tariff.id}.json`);
  }

  private prepareTariffForRequest(tariff: FreighterTransportTariff): Object {
    return {
      freighter_transport_tariff: {
        name: tariff.name,
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        afterMkadKmPrice: tariff.after_mkad_km_price,
        additionalHourPrice: tariff.additional_hour_price,
        ttkPrice: tariff.ttk_price,
        hydroelevatorHourPrice: tariff.hydroelevator_hour_price
      }
    }
  }
}
