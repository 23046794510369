
import {finalize, switchMap, debounceTime, map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {NotificationService} from "../_services/notification.service";
import {Subject} from "rxjs";
import {LoaderService} from "../_services/loader.service";
import {SupportNotificationService} from "../_services/support-notification.service";
import {SupportNotificationsList} from "../_models/support-notifications-list";
import {Page} from "../pager/page";

class ListCommand {
  constructor(
    private _page?: number,
    private _source?: string,
  ) {}

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }
}

@Component({
  selector: 'app-support-notifications',
  templateUrl: './support-notifications.component.html',
  styleUrls: ['./support-notifications.component.css']
})
export class SupportNotificationsComponent implements OnInit {
  notifications = SupportNotificationsList.empty();
  showMore: boolean;

  private notificationsStream = new Subject<ListCommand>();

  constructor(
    private notificationService: NotificationService,
    private supportNotificationService: SupportNotificationService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.notificationService.activeNotificationsList = false;
    this.initNotificationsStream();
    this.reloadNotifications();
  }

  initNotificationsStream(): void {
    this.notificationsStream = new Subject<ListCommand>();
    this.notificationsStream.pipe(
      debounceTime(500),
      switchMap(command => {
        this.loaderService.show();
        return this.supportNotificationService
          .getNotifications(command.page).pipe(
            finalize(() => this.loaderService.hide()),
            map(notifications => [ notifications, command ])
          )
        ;
      }))
      .subscribe({
        next: listAndCommand => {
          const list = listAndCommand[0] as SupportNotificationsList;
          const command = listAndCommand[1] as ListCommand;

          if(command.source == 'button')
            this.notifications = list.concat(this.notifications.notifications);
          else
            this.notifications = list;

          this.initMoreButton();
        },
        error: () => this.initNotificationsStream()
      })
    ;
  }

  private loadNotifications(page: number, source?: string): void {
    this.notificationsStream.next(new ListCommand(page, source));
  }

  private reloadNotifications(): void {
    this.notificationsStream.next(new ListCommand(this.notifications.page));
  }

  private initMoreButton(): void {
    this.showMore = this.notifications.hasMore();
  }

  onPaginationPage(page: Page) {
    this.loadNotifications(page.num);
  }

  onShowMorePage(page: Page) {
    this.loadNotifications(page.num, "button");
  }
}
