import {TaxiMessage} from "./taxi-message";

export class SearchDuration implements TaxiMessage {
  constructor(private draftId: number, private _duration: number, private _restTime: number, private _message: string) {
  }

  getDraftId(): number {
    return this.draftId;
  }

  get duration(): number {
    return this._duration;
  }

  get restTime(): number {
    return this._restTime;
  }

  get message(): string {
    return this._message;
  }
}
