import {ComplexDeliveryFormRowComponent} from "./complex-delivery-form-row.component";
import {ValidateState} from "./validate-state";

export class Validator {


  constructor(private row: ComplexDeliveryFormRowComponent) {}

  validate(): ValidateState {
    let state = new ValidateState();

    this.validateCommonState(state);
    this.validateContacts(state);

    return state;
  }

  validateForCalculation(): ValidateState {
    let state = new ValidateState();

    this.validateCommonState(state);
    state.clientName = true;
    state.clientPhone = true;

    return state;
  }

  validateState(state: ValidateState) {
    this.validateCommonState(state);
    this.validateContacts(state);
  }

  validateCommonState(state: ValidateState) {
    this.validateTimeSlotBegin(state);
    this.validateTimeSlotEnd(state);
    this.validateDestination(state);
    this.validateTimeSlot(state);
  }

  private validateTimeSlotBegin(state: ValidateState) {
    if(this.row.timeSlotBegin == null && this.row.slotEnabled) {
      state.timeSlotBegin = false;
      state.timeSlotBeginMsg = 'Обязательное поле';
    } else {
      state.timeSlotBegin = true;
    }
  }

  private validateTimeSlotEnd(state: ValidateState) {
    if(this.row.timeSlotEnd == null && this.row.slotEnabled) {
      state.timeSlotEnd = false;
      state.timeSlotEndMsg = 'Обязательное поле';
    } else {
      state.timeSlotEnd = true;
    }
  }

  private validateDestination(state: ValidateState) {
    if(this.row.destination.destination == null || this.row.destination.destination.addr == null) {
      state.destination = false;
      state.destinationMsg = 'Обязательное поле';
    } else {
      state.destination = true;
    }
  }

  private validateTimeSlot(state: ValidateState) {
    if(!this.row.slotEnabled || !state.timeSlotBegin || !state.timeSlotEnd)
      return;

    let begin = this.row.timeSlotBegin.getHours() * 60 + this.row.timeSlotBegin.getMinutes();
    let end = this.row.timeSlotEnd.getHours() * 60 + this.row.timeSlotEnd.getMinutes();

    if(end < begin) {
      state.timeSlotEnd = false;
      state.timeSlotEndMsg = 'Время окончания не должно быть меньше времени начала';
    }
  }

  private validateContacts(state: ValidateState): void {
    if(this.row.contactsRequired) {
      this.validateClientName(state);
      this.validateClientPhone(state);
    } else {
      state.clientName = true;
      state.clientPhone = true;
    }
  }

  private validateClientName(state: ValidateState): void {
    let nameComponents = (this.row.destination.client_name || '').split(' ');
    nameComponents = nameComponents.map(c => c.trim()).filter(c => c.length > 0);
    if(nameComponents.length < 2) {
      state.clientName = false;
      state.clientNameMsg = 'Нужно обязательно указать имя и фамилию контактного лица';
    } else {
      state.clientName = true;
    }
  }

  private validateClientPhone(state: ValidateState): void {
    let phone = this.row.destination.client_phone || '';
    if(/^\d{11}$/.test(phone)) {
      state.clientPhone = true;
    } else {
      state.clientPhone = false;
      state.clientPhoneMsg = 'Должен быть указан корректный номер телефона';
    }
  }
}
