<div class="modal fade" tabindex="-1" role="dialog" id="new-order">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
        <h4 class="modal-title">Новый заказ</h4>
      </div>
      <div class="modal-body">
        <form (submit)="onAdd()">
          <div class="form-group">
            <select name="city" [(ngModel)]="selectedCity" class="form-control">
              <option [value]="c.id" *ngFor="let c of cities">{{ c.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="phone">Номер телефона клиента</label>
            <input type="text" class="input" id="phone" placeholder="700000000" [(ngModel)]="clientPhone"
                   name="clientPhone" required>
          </div>
          <div class="form-group">
            <label for="name">Имя клиента</label>
            <input type="text" class="input" id="name" placeholder="Имя Отчество Фамилия"
                   [(ngModel)]="clientName" name="clientName">
          </div>
          <div class="form-group">
            <label for="phone">Начало заказа</label>
            <order-panel-datepicker [(value)]="startDate" [datepicker]="datePickerOptions" [timepicker]="timePickerOptions"
                                    name="startDate" ngDefaultControl></order-panel-datepicker>
          </div>
          <div class="form-group">
            <label for="phone">Окончание заказа</label>
            <order-panel-datepicker [(value)]="endDate" [datepicker]="datePickerOptions" [timepicker]="timePickerOptions"
                                    name="endDate" ngDefaultControl></order-panel-datepicker>
          </div>
          <button class="save-btn" type="submit">Добавить</button>
        </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
