
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestService} from "./request.service";
import {Observable} from "rxjs";
import {Taximeter} from "../_models/taximeter";
import {Order} from "../_models/order";
import {ApiUtilsService} from "./api-utils.service";

@Injectable()
export class TaximeterService {
  constructor(private requestService: RequestService, private apiUtilsService: ApiUtilsService) {}

  getTaximeter(order: Order): Observable<Taximeter> {
    return this.requestService
      .get(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/taximeter.json`).pipe(
      map(r => r.body as Taximeter));
  }
}
