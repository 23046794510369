import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UserInfoService} from "../_services/user-info.service";
import {DraftSpecial} from "../_models/draft-special";

export const AVAILABLE_DRAFT_TYPES = [{
  identifier: 'fix',
  name: 'ФИКС',
  privileged: false,
  special: 'common',
  schema: 'fix'
},{
  identifier: 'none_fix',
  name: 'НЕ_ФИКС',
  privileged: false,
  special: 'common',
  schema: 'none_fix'
},{
  identifier: 'avito',
  name: 'Avito',
  img: '/assets/images/avito/logo.png',
  privileged: true,
  special: 'avito',
  schema: 'first',
  description: 'Заказ с Avito'
}];

@Component({
  selector: 'draft-special-dialog',
  templateUrl: './draft-special-dialog.component.html',
  styleUrls: ['./draft-special-dialog.component.css']
})
export class DraftSpecialDialogComponent implements OnInit, OnDestroy {
  @ViewChild('draftSpecialDialog', { static: true }) dialogEl: ElementRef;
  @Output() selectSpecial = new EventEmitter<DraftSpecial>();

  activeDraftTypes: any[] = [];
  activeDraftType: string;

  private wasShown = false;
  private modalWasInit = false;

  constructor(private userInfoService: UserInfoService) { }

  ngOnInit() {
    this.initDraftTypes();
  }

  private initDraftTypes(): void {
    const isPrivileged = this.userInfoService.isPrivilegedUser();
    this.activeDraftTypes = AVAILABLE_DRAFT_TYPES.filter(d => !d.privileged || isPrivileged);
  }

  showDialog(): void {
    $(this.dialogEl.nativeElement).modal('show');
    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  onHoverDraftType(draftType: string): void {
    this.activeDraftType = draftType;
  }

  onMouseOutFromDraftType(): void {
    this.activeDraftType = null;
  }

  onSelectDraftType(draftType: any): void {
    this.selectSpecial.emit(new DraftSpecial(draftType.special, draftType.schema, draftType.identifier));
    this.hideDialog();
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
