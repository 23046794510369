
import {of as observableOf, throwError as observableThrowError, Observable, Subject, zip} from 'rxjs';

import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {CrewsListFilter} from "../_models/crews-list-filter";
import {CrewsList} from "../_models/crews-list";
import {CrewService} from "../_services/crew.service";
import {UserInfoService} from "../_services/user-info.service";
import {LoaderService} from "../_services/loader.service";
import {Page} from "../pager/page";
import {Auth} from "../_models/auth";
import {EMPLOYER_STATUSES} from "../_maps/employer-statuses";
import {TRANSPORT_BODIES} from "../_maps/transport-bodies";
import {CAPACITIES} from "../_maps/capacities";
import {State} from "../_storage/state";
import {DateTime} from "date-time-js";

const PAGE_STORAGE = "crews_list_page";
const FILTER_STORAGE = "crews_list_filter";
const SYNC_TIME_STATE_STORAGE = "crews_sync_time";
const DATE_REGEXP = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
const TIME_REGEXP = /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/;
/**
 * Время (в милисекундах), в течение которого считается, что водитель был онлайн
 */
const ONLINE_TIME_BOUNDARY = 20 * 60 * 1000;
const PAGE_SIZE = 12;

class ListCommand {
  constructor(
    private _filter?: CrewsListFilter,
    private _page?: number,
    private _source?: string,
    private _force?: boolean
  ) {}

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  get force(): boolean {
    return this._force;
  }

  get filter(): CrewsListFilter {
    return this._filter;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'app-crews',
  templateUrl: './crews.component.html',
  styleUrls: ['./crews.component.css']
})
export class CrewsComponent implements OnInit {
  crews = CrewsList.empty();
  private listCommands: Subject<ListCommand>;
  private oldFilter = new CrewsListFilter();
  showMore: boolean;

  employerStatuses = EMPLOYER_STATUSES;
  transportBodies = TRANSPORT_BODIES;
  capacities = CAPACITIES;

  filter = new CrewsListFilter();
  dateFilter: string;
  timeFilter: string;
  syncTime: boolean;

  countOfEmployerWithoutCrews: number = 0;

  freighterId: number;

  constructor(
    private crewService: CrewService,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    let freighter = this.userInfoService.getFreighter();
    this.freighterId = freighter && freighter.id;

    this.initCrews();
    this.restorePage();
    this.restoreSyncTimeState();
    this.restoreFilter();
    this.syncTimeFilterIf();
    this.loadCrews(this.crews.page, this.crews.filter);
  }

  private initDateFilter(): void {
    let d = new DateTime(this.filter.date);
    this.dateFilter = d.format('yyyy-MM-dd');
    this.timeFilter = d.format('HH:mm:ss');
  }

  private applyDateFilter(): void {
    if(DATE_REGEXP.test(this.dateFilter) && TIME_REGEXP.test(this.timeFilter))
      this.filter.date = new Date(`${this.dateFilter}T${this.timeFilter}`);
  }

  private syncTimeFilterIf(): void {
    if(!this.syncTime)
      return;

    let date = new DateTime();
    date.add(1, 'minute');

    this.filter.date = date.toDate();
    this.crews.filter.date = date.copy().toDate();
    this.initDateFilter();
  }

  private initCrews(): void {
    this.listCommands = new Subject<ListCommand>();
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        let listObservable = this.crewService
          .getCrews(this.userInfoService.getFreighter(), command.page, command.filter, PAGE_SIZE).pipe(
          finalize(() => this.loaderService.hide()))
        ;

        this.loaderService.show();
        let countEmployersWithoutCrewsObservable = this.crewService
          .countEmployersWithoutCrews(this.userInfoService.getFreighter(), command.filter.date).pipe(
          finalize(() => this.loaderService.hide()))
        ;

        return zip(
          listObservable,
          countEmployersWithoutCrewsObservable,
          observableOf(command),
          (list: CrewsList, count: number, command: ListCommand) => { return { list, count, command }}
        );
      }),
      catchError(e => {
        this.initCrews();
        return observableThrowError(e);
      }),)
      .subscribe((result: any) => {
        let list: CrewsList = result.list;
        let command: ListCommand = result.command;
        this.countOfEmployerWithoutCrews = result.count;

        if(command.source === 'button') {
          this.crews = list.concat(this.crews.crews)
        } else {
          this.crews = list;
        }

        this.initMoreButton();
        this.savePage();
        this.saveFilter();
      });
  }

  private loadCrews(page?: number, filter?: CrewsListFilter, source?: string, force?: boolean) {
    this.listCommands.next(new ListCommand(filter?.clone(), page, source, force));
  }

  private initMoreButton(): void {
    this.showMore = this.crews.hasMore();
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.crews.page.toString());
  }

  private saveFilter() {
    this.applyDateFilter();
    sessionStorage.setItem(FILTER_STORAGE, this.crews.filter.getState().state);

    this.oldFilter = this.crews.filter.clone();
  }

  private saveSyncTimeState(): void {
    sessionStorage.setItem(SYNC_TIME_STATE_STORAGE, this.syncTime ? '1' : '0');
  }

  private restorePage() {
    this.crews.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  private restoreFilter() {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if(state)
      this.crews.filter = CrewsListFilter.fromState(new State(state));

    this.filter = this.crews.filter.clone();
    this.oldFilter = this.crews.filter.clone();

    this.initDateFilter();
  }

  private restoreSyncTimeState() {
    let state = sessionStorage.getItem(SYNC_TIME_STATE_STORAGE);
    this.syncTime = state !== '0';
  }

  private activateTimeSync(): void {
    this.syncTime = true;
    this.saveSyncTimeState();
    this.syncTimeFilterIf();
    this.onChangeFilter();
  }

  private deactivateTimeSync(): void {
    this.syncTime = false;
    this.saveSyncTimeState();
  }

  private reloadCrews(): void {
    this.loadCrews(this.crews.page, this.crews.filter);
  }

  isOnline(user: Auth): boolean {
    if(!user.online_at)
      return false;

    let onlineAt = new Date(user.online_at);
    let curTime = new Date();
    let timeDiff = curTime.getTime() - onlineAt.getTime();

    return timeDiff < ONLINE_TIME_BOUNDARY;
  }

  onShowMorePage(page: Page) {
    this.loadCrews(page.num, this.crews.filter, "button");
  }

  onPaginationPage(page: Page) {
    this.loadCrews(page.num, this.crews.filter, "pager");
  }

  onChangeFilter(isTimeChanged = false): void {
    this.applyDateFilter();
    if(!this.filter.equals(this.oldFilter))
      this.listCommands.next(new ListCommand(this.filter.clone()));

    if(isTimeChanged)
      this.deactivateTimeSync();
  }

  onTimeSyncToggle(): void {
    if(this.syncTime)
      this.deactivateTimeSync();
    else
      this.activateTimeSync();
  }
}
