import {UserInfoService} from "./user-info.service";
import {Order} from "../_models/order";
import {Injectable} from "@angular/core";
import {OrderDraft} from "../_models/order-draft";

@Injectable()
export class ApiUtilsService {
  constructor(private userInfoService: UserInfoService) {}

  getPrefixForList() {
    if (this.userInfoService.isCustomer())
    {
      return '/customer/me'
    }
    return this.userInfoService.isPrivilegedUser() ? '/freighter/all' : '/freighter/me';
  }

  getPrefixForDraft(draft: OrderDraft): string {
    if(draft.client.freighter)
      return this.getPrefixByFreighterId(draft.client.freighter.id);

    if(draft.crew_filters.length == 1 && draft.employer_filters.length == 0)
      return this.getPrefixByFreighterId(draft.crew_filters[0].crew.freighter.id);

    return this.getPrefixForDrafts();
  }

  getPrefixForOrder(order: Order): string {
    return this.getPrefixByFreighterId(order.freighter.id);
  }

  getPrefixByFreighterId(freighterId: number, useSupportPrefix = false): string {
    if(useSupportPrefix && this.userInfoService.isPrivilegedUser())
      return '/support/me';

    if (this.userInfoService.isCustomer())
    {
      return '/customer/me'
    }
    return this.userInfoService.isPrivilegedUser()
      ? `/freighter/${freighterId}`
      : '/freighter/me';
  }

  getPrefixForDrafts(): string {
    if (this.userInfoService.isPrivilegedUser()) {
      return `/support/me`;
    } else {
      return `/customer/me`;
    }
  }
}
