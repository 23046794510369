
import {of as observableOf, Observable, Subject} from 'rxjs';

import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Freighter} from "../_models/freighter";
import {EmployeesFilter} from "../_models/employees/employees-filter";
import {EmployeesList} from "../_models/employees-list";
import {EmployeeService} from "../_services/employer.service";
import {LoaderService} from "../_services/loader.service";
import {Page} from "../pager/page";
import {Employer} from "../_models/employer";
import {Auth} from "../_models/auth";

class ListCommand {
  constructor(
    private _filter?: EmployeesFilter,
    private _page?: number,
    private _force?: boolean
  ) {}

  get page(): number {
    return this._page;
  }

  get force(): boolean {
    return this._force;
  }

  get filter(): EmployeesFilter {
    return this._filter;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'employee-selector',
  templateUrl: './employee-selector.component.html',
  styleUrls: ['./employee-selector.component.css']
})
export class EmployeeSelectorComponent implements OnInit, OnChanges {
  @Input() freighter: Freighter;
  @Input() date: Date;
  @Output() selected = new EventEmitter<Employer>();

  employees = EmployeesList.empty();
  filter = new EmployeesFilter();
  private listCommands: Subject<ListCommand>;
  private oldFilter = new EmployeesFilter();

  constructor(
    private employeeService: EmployeeService,
    private loaderService: LoaderService
  ) {
    this.employees.filter = this.filter.clone();
  }

  ngOnInit() {
    this.initEmployees();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter.crewDate = this.date || new Date();
    this.employees.filter.crewDate = new Date(this.filter.crewDate.toString());
  }

  private initEmployees(): void {
    this.listCommands = new Subject<ListCommand>();
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        console.log('load employees...');
        this.loaderService.show();
        return this.employeeService
          .getFreighterEmployees(this.freighter, command.page, command.filter).pipe(
          finalize(() => this.loaderService.hide()))
          ;
      }),
      catchError(e => {
        console.log(e);
        this.initEmployees();
        return observableOf<EmployeesList>(EmployeesList.empty())
      }),)
      .subscribe(list => {
        this.employees = list
        this.oldFilter = list.filter.clone();
      });
  }

  private loadEmployees(page?: number, filter?: EmployeesFilter, force?: boolean) {
    this.listCommands.next(new ListCommand(filter?.clone(), page, force));
  }

  reloadEmployees(): void {
    this.loadEmployees(this.employees.page, this.employees.filter, true);
  }

  onPaginationPage(page: Page) {
    this.loadEmployees(page.num, this.employees.filter);
  }

  onChangeFilter(): void {
    if(!this.filter.equals(this.oldFilter)) {
      console.log('filter changed');
      this.listCommands.next(new ListCommand(this.filter.clone()));
    }
  }

  onSwitchWithoutCrewFilter(): void {
    this.filter.withoutCrew = !this.filter.withoutCrew;
    this.onChangeFilter();
  }

  onSelect(employee: Auth): void {
    employee.account.employer.account = employee.account;
    employee.account.employer.account.auth = employee;
    this.selected.emit(employee.account.employer);
  }
}
