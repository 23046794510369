
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {CompanyDetails} from "../_models/company-details";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {BankDetails} from "../_models/bank-detail";

@Injectable()
export class CompanyDetailsService {
  constructor(private _requestService: RequestWithErrorHandlerService) {}

  getCompanyDetails(query: string): Observable<CompanyDetails[]> {
    return this._requestService
      .get('/details/company/suggestions.json', {query}).pipe(
      map(r => r.body as CompanyDetails[]))
      ;
  }

  getBankDetails(query: string): Observable<BankDetails[]> {
    return this._requestService
      .get('/details/bank/suggestions.json', {query}).pipe(
      map(r => r.body as BankDetails[]))
      ;
  }
}

