import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "orderGroupStatus"})
export class OrderGroupStatusPipe implements PipeTransform {
    _groupStatuses = {
        'new':'Новые',
        'canceled':'Отклонённые',
        'pending':'В ожидании',
        'complete':'Завершённые',
        'agreement':'На согласовании'
    }
    transform(status: string): any {
        return this._groupStatuses[status] || '';
    }
}
