<div class="row">
  <div class="col-lg-12">
    <form (submit)="onSubmit()">
    <div class="form-group">
      <div class="input-group">
        <input type="text" class="form-control promocode" name="promocode" [(ngModel)]="promocode" (ngModelChange)="onChangePromocode()" [disabled]="disabled">
        <div class="loader" *ngIf="showLoader"><img ngSrc="assets/images/loader-promo.gif" alt="применение промокода..." height="30"
                                  width="30"></div>
      </div>
      <div class="text-warning" *ngIf="error">{{error}}</div>
      <div class="text-success" *ngIf="success">Промо-код успешно применён</div>
    </div>
    </form>
  </div>
</div>
