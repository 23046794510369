import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClipboardService} from "../_services/clipboard.service";

@Component({
  selector: 'clipboard-notify-container',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.css']
})
export class ClipboardComponent implements OnInit {
  @ViewChild('snakbar', { static: true }) snakbarEl: ElementRef;

  message: string;

  private closeTimer: any;
  private hideTimer: any;

  constructor(clipboardService: ClipboardService) {
    clipboardService.getClipboardMessageObservable().subscribe(message => this.showMessage(message));
  }

  ngOnInit() {
  }

  private showMessage(message: string): void {
    this.message = message;

    $(this.snakbarEl.nativeElement).addClass('opened').removeClass('closed');
    this.initHideTimer();
  }

  private initHideTimer(): void {
    if(this.closeTimer) {
      clearTimeout(this.closeTimer);
      clearTimeout(this.hideTimer);
    }

    this.closeTimer = setTimeout(() => {
      $(this.snakbarEl.nativeElement).removeClass('opened').addClass('closed');
      this.closeTimer = null;
    }, 3000);

    this.hideTimer = setTimeout(() => {
      this.message = null;
      this.hideTimer = null;
      $(this.snakbarEl.nativeElement).removeClass('closed');
    }, 4000);
  }
}
