import {List} from "../pager/list";
import {Order} from "./order";
import {OrderListFilter} from "./order-list-filter";

export class OrderList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public filter: OrderListFilter, public orders: Order[]) {
  }

  static empty(): OrderList {
    return new OrderList(0, 0, 0, new OrderListFilter, []);
  }

  concat(array) {
    this.orders = array.concat(this.orders);
    return this;
  }
}
