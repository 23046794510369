
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Order} from "../_models/order";
import {InsuranceEditorComponent} from "../insurance-editor/insurance-editor.component";
import {Insurance} from "../_models/insurance";
import {OrderService} from "../_services/order.service";
import {LoaderService} from "../_services/loader.service";

@Component({
  selector: 'order-insurance-editor',
  templateUrl: './order-insurance-editor.component.html',
  styleUrls: ['./order-insurance-editor.component.css']
})
export class OrderInsuranceEditorComponent implements OnInit {
  @Input() order: Order;
  @Output() onUpdated = new EventEmitter<Order>();

  @ViewChild(InsuranceEditorComponent, { static: true }) insuranceEditor: InsuranceEditorComponent;

  insurances: Insurance[] = [];

  constructor(private orderService: OrderService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.insurances = JSON.parse(JSON.stringify(this.order.insurances)) as Insurance[];
  }

  private insure(): void {
    this.order.insurances = this.insuranceEditor.insurances;

    this.loaderService.show();
    this.orderService
      .addInsurances(this.order).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.onUpdated.emit(this.order);
        },
        () => {}
      )
    ;
  }

  onSave() {
    if(this.insuranceEditor.validate())
      this.insure();
  }
}
