<div class="modal fade" tabindex="-1" role="dialog" #passportDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Паспорт {{ account|fullName }}</h4>
        </div>
      </div>
      <div class="modal-body">
        <passport-view *ngIf="passport" [passport]="passport"></passport-view>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col col-6">
            <button class="close-btn btn-block" type="button" data-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
