<tr class="header">
  <th colspan="2">Грузчики</th>
</tr>
<tr>
  <th>Количество грузчиков</th>
  <td>{{ loadersCount }}</td>
</tr>
<ng-template ngFor let-point [ngForOf]="calculation.points" let-i="index">
  <tr class="header">
    <th colspan="2">{{ i + 1 }}. {{ point.addr }}</th>
  </tr>
  <tr>
    <td>Действие</td>
    <td>
      <span *ngIf="point.loading">погрузка</span>
      <span *ngIf="point.unloading">разгрузка</span>
      <span *ngIf="!point.loading && !point.unloading">-</span>
    </td>
  </tr>
  <ng-template ngIf [ngIf]="point.products && point.products.length > 0">
    <ng-template ngFor let-product [ngForOf]="point.products" let-pi="index">
    <tr class="header">
      <th colspan="2" class="cargo-title">{{ i + 1 }}.{{ pi + 1 }}) {{ product.product.name }} ({{ product.product.count || 1 }} шт. до {{ product.tariff.item_weight }} кг)</th>
    </tr>
    <tr *ngIf="product.calculation.entrance_cost">
      <td class="cargo-title">
        <span *ngIf="point.unloading">Доставка до подъезда</span>
        <span *ngIf="point.loading">Забор от подъезда</span>
      </td>
      <td>
        <span *ngIf="product.tariff.entrance_cost != product.calculation.entrance_cost">
          {{ product.tariff.entrance_cost|formatCost }} x {{ product.product.count || 1 }} шт. =
        </span>
        {{ product.calculation.entrance_cost|formatCost }}
      </td>
    </tr>
    <tr *ngIf="product.calculation.apartment_cost">
      <td class="cargo-title">
        <span *ngIf="point.unloading">Доставка до квартиры</span>
        <span *ngIf="point.loading">Забор от квартиры</span>
      </td>
      <td>
        <span *ngIf="product.tariff.apartment_base_cost != product.calculation.apartment_cost">
        ({{ product.tariff.apartment_base_cost|formatCost }}<span *ngIf="product.calculation.paid_floors">
          + {{ product.calculation.paid_floors }} доп. эт. х {{ product.tariff.floor_cost|formatCost }}</span>)
          x {{ product.product.count || 1 }} шт.
          =
        </span>
        {{ product.calculation.apartment_cost|formatCost }}
      </td>
    </tr>
    <tr *ngIf="product.calculation.base_area && product.calculation.base_area.distance > 0">
      <td>Подача за пределы базовой зоны ({{ product.calculation.base_area.distance }} км)</td>
      <td>
        ({{ product.calculation.base_area.steps }} по {{ product.tariff.from_base_area_step }} км х {{ product.tariff.from_base_area_step_price|formatCost }})
        x {{ product.product.count || 1 }}
        =
        {{ product.calculation.base_area.cost|formatCost }}
      </td>
    </tr>
    <tr *ngIf="product.calculation.actions && product.calculation.actions.cost > 0">
      <td class="cargo-title">
        <span *ngIf="product.calculation.actions.unloading">Разгрузка из машины</span>
        <span *ngIf="product.calculation.actions.loading">Погрузка в машину</span>
      </td>
      <td>
        {{ product.calculation.actions.items_count }} шт. х {{ product.tariff.item_action_cost|formatCost }}
        =
        {{ product.calculation.actions.cost|formatCost }}
      </td>
    </tr>
    </ng-template>
  </ng-template>
  <ng-template ngIf [ngIf]="point.calculated_cost && point.cost != point.calculated_cost">
    <tr class="sum">
      <td>Рассчитанная стоимость</td>
      <td>{{ point.calculated_cost|formatCost }}</td>
    </tr>
    <tr>
      <td>Минимальная стоимость</td>
      <td>{{ point.cost|formatCost }}</td>
    </tr>
  </ng-template>
  <tr class="sum" *ngIf="point.cost">
    <td>Итоговая стоимость точки</td>
    <td>{{ point.cost|formatCost }}</td>
  </tr>
</ng-template>
<tr class="header">
  <th colspan="2">Итого за работу грузчиков</th>
</tr>
<tr [class.sum]="calculation.cost == calculation.min_cost">
  <td>Итоговая стоимость</td>
  <td>{{ calculation.cost|formatCost }}</td>
</tr>
