export class OnlineFilter {
  constructor(private _from?: Date, private _to?: Date, private _never?: boolean) {}

  get from(): Date {
    return this._from;
  }

  get to(): Date {
    return this._to;
  }

  get never(): boolean {
    return this._never;
  }

  isEmpty(): boolean {
    return this._from === undefined && this._to === undefined && this._never === undefined;
  }
}
