import {Connection} from "../connection";
import {Subject} from "rxjs";
import {Message} from "../messages/message";
import {OrderChanged} from "../messages/order-changed";
import {OrderCrewChanged} from "../messages/order-crew-changed";
import {OrderExternalStatusChanged} from "../messages/order-external-status-changed";
import {OrderExternalExecutionStatusChanged} from "../messages/order-external-execution-status-changed";
import {NoPoints} from "../messages/no-points";
import {ExternalCancel} from "../messages/external-cancel";
import {ArrivalTimeChanged} from "../messages/arrival-time-changed";

export class OrdersConnection extends Connection {
  public message: Subject<Message> = new Subject();

  protected init() {
    console.log('Init orders connection...');

    this.sendMessage(JSON.stringify({
      "action": "listen_order"
    }));
  }

  protected onMessage(data: any) {
    if(data['type'] !== 'order')
      return;

    let mData = data['data'];
    switch(data['action']) {
      case 'changed':
        this.message.next(new OrderChanged(mData.order, mData.draft));
        break;
      case 'changed_crew':
        this.message.next(new OrderCrewChanged(
          mData.order, mData.draft, mData.freighter, mData.employer, mData.transport, mData.employerData, mData.transportData
        ));
        break;
      case 'external_status':
        this.message.next(new OrderExternalStatusChanged(mData.order, mData.freighter, mData.period, mData.status, data['text']));
        break;
      case 'external_execution_status':
        this.message.next(new OrderExternalExecutionStatusChanged(mData.order, mData.freighter, mData.period, mData.status, data['text']));
        break;
      case 'no_points':
        this.message.next(new NoPoints(mData.order, mData.freighter, data['text']));
        break;
      case 'external_cancel':
        this.message.next(new ExternalCancel(mData.order, mData.freighter, data['text']));
        break;
      case 'arrival_time_changed':
        this.message.next(new ArrivalTimeChanged(mData.freighter, mData.order, mData.draft, mData.arrival_time, data['text']));
        break;
    }
  }
}
