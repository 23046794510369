import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";
import {DeliverySchema} from "../_models/delivery-schema";
import {DeliveryService} from "../_services/delivery.service";
import {AlertService} from "../_services/alert.service";
import {UserInfoService} from "../_services/user-info.service";

@Component({
  selector: 'draft-delivery-schema-editor',
  templateUrl: './draft-delivery-schema-editor.component.html',
  styleUrls: ['./draft-delivery-schema-editor.component.css']
})
export class DraftDeliverySchemaEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  deliverySchemas: DeliverySchema[] = [];
  deliverySchemaId: number;
  isMultiPoint: boolean;

  constructor(
    private userInfoService: UserInfoService,
    private deliveryService: DeliveryService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.deliverySchemaId = this.draft.delivery_schema && this.draft.delivery_schema.id || 0;
    this.loadDeliverySchemas();
    this.initMultipoint();
  }

  private initMultipoint():void {
    this.isMultiPoint = this.draft.destinations.length > 2;
  }

  private loadDeliverySchemas() {
    if(this.userInfoService.isPrivilegedUser()) {
      this.deliveryService
        .getSchemasByDraft(this.draft)
        .subscribe(
          schemas => this.deliverySchemas = schemas,
          () => this.alertService.error('Ошибка загрузки схем доставки')
        )
    } else {
      this.deliveryService
        .getSchemas()
        .subscribe(
          schemas => this.deliverySchemas = schemas,
          () => this.alertService.error('Ошибка загрузки схем доставки')
        )
    }
  }

  onSave() {
    this.draft.delivery_schema = this.deliverySchemas.find(s => s.id == this.deliverySchemaId);

    this.onUpdated.emit(this.draft);
  }
}
