import {Crew} from "./crew";
import {Auth} from "./auth";
import {Employer} from "./employer";
import {EmployeesFilter} from "./employees/employees-filter";

export class EmployeesList {
  private employeeCrewsMap = new Map<number, Crew>();

  constructor(public page: number, public pageSize: number, public totalCount: number, public filter: EmployeesFilter, public employees: Auth[], public employeeCrews: Crew[]) {
    this.applyCrews(employeeCrews);
  }

  static empty(): EmployeesList {
    return new EmployeesList(0, 0, 0, new EmployeesFilter(), [], []);
  }

  concat(list: EmployeesList): EmployeesList {
    this.employees = list.employees.concat(this.employees);
    this.employeeCrews = list.employeeCrews.concat(this.employeeCrews);

    this.applyCrews(list.employeeCrews);

    return this;
  }

  hasMore(): boolean {
    if(this.employees.length < this.pageSize)
      return false;

    let lastEmployeeNumOnPage = this.page * this.pageSize + this.pageSize - 1;
    return lastEmployeeNumOnPage < this.totalCount - 1;
  }

  hasCrewForEmployee(employee: Auth): boolean {
    return this.employeeCrewsMap.has(employee.account.employer.id);
  }

  getCrewForEmployee(employee: Employer): Crew|undefined {
    return this.employeeCrewsMap.get(employee.account.employer.id);
  }

  private applyCrews(crews: Crew[]): void {
    for(let crew of crews) {
      this.employeeCrewsMap.set(crew.employer.id, crew);
    }
  }
}
