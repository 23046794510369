import {State} from "../_storage/state";
import {DateUtils} from "../_utils/date-utils";

export class OptimizationTaskListFilter {
  private static emptyFilter: OptimizationTaskListFilter;

  constructor(
    public status = '',
    public executionStatus = '',
    public trackNumber = '',
    public orderCode = '',
    public arrivalStartDate?: Date,
    public arrivalEndDate?: Date,
    public cargo: string = ''
  ) {}

  getState(): State {
    return new State(JSON.stringify({
      status: this.status,
      executionStatus: this.executionStatus,
      trackNumber: this.trackNumber,
      orderCode: this.orderCode,
      arrivalStartDate: this.arrivalStartDate,
      arrivalEndDate: this.arrivalEndDate,
      cargo: this.cargo
    }));
  }

  equals(filter: OptimizationTaskListFilter): boolean {
    return this.status === filter.status
      && this.executionStatus === filter.executionStatus
      && this.trackNumber === filter.trackNumber
      && this.orderCode === filter.orderCode
      && DateUtils.isEqualDates(this.arrivalStartDate, filter.arrivalStartDate)
      && DateUtils.isEqualDates(this.arrivalEndDate, filter.arrivalEndDate)
      && this.cargo === filter.cargo
      ;
  }

  isEmpty(): boolean {
    return this.equals(OptimizationTaskListFilter.getEmptyFilter());
  }

  private static getEmptyFilter(): OptimizationTaskListFilter {
    OptimizationTaskListFilter.emptyFilter ||= new OptimizationTaskListFilter();
    return OptimizationTaskListFilter.emptyFilter;
  }

  clone(): OptimizationTaskListFilter {
    return new OptimizationTaskListFilter(
      this.status,
      this.executionStatus,
      this.trackNumber,
      this.orderCode,
      this.arrivalStartDate && new Date(this.arrivalStartDate),
      this.arrivalEndDate && new Date(this.arrivalEndDate),
      this.cargo
    );
  }

  static fromState(state: State): OptimizationTaskListFilter {
    let data = JSON.parse(state.state);
    let status = data['status'] || '';
    let executionStatus = data['executionStatus'] || '';
    let trackNumber = data['trackNumber'] || '';
    let orderCode = data['orderCode'] || '';
    let arrivalStartDate = data['arrivalStartDate'] && new Date(data['arrivalStartDate']) || null;
    let arrivalEndDate = data['arrivalEndDate'] && new Date(data['arrivalEndDate']) || null;
    let cargo = data['cargo'] || '';

    return new OptimizationTaskListFilter(
      status,
      executionStatus,
      trackNumber,
      orderCode,
      arrivalStartDate,
      arrivalEndDate,
      cargo
    );
  }
}