import {Order} from "../_models/order";
import {OrderCalculation} from "../_models/calculation.delivery/order-calculation";
import {Destination} from "../_models/destination";

export class ViewSession {
  loading = true;
  loadingProgress: number = 0;
  interrupted = false;

  private visibleDestinationDetails = new Map<number, boolean>();

  constructor(private _calculation: OrderCalculation) {
    this.loadingProgress = _calculation.destinationCalculations.length;
  }

  get calculation(): OrderCalculation {
    return this._calculation;
  }

  isDestinationDetailVisible(destination: Destination): boolean {
    return this.visibleDestinationDetails.has(destination.id) && this.visibleDestinationDetails.get(destination.id);
  }

  showDestinationDetails(destination: Destination): void {
    this.visibleDestinationDetails.set(destination.id, true);
  }

  hideDestinationDetails(destination: Destination): void {
    this.visibleDestinationDetails.set(destination.id, false);
  }
}
