import {Pipe, PipeTransform} from "@angular/core";
import {Transport} from "../_models/transport";
import {TRANSPORT_BODIES} from "../_maps/transport-bodies";
import {CAPACITIES} from "../_maps/capacities";
import {TransportUtils} from "../_utils/transport-utils";

@Pipe({name: "transportDescription"})
export class TransportDescriptionPipe implements PipeTransform {
  transform(transport: Transport, extended = false): any {
    return extended
      ? TransportUtils.getTransportExtendedDescription(transport)
      : TransportUtils.getTransportDescription(transport);
  }
}
