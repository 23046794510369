<form>
  <div class="form-group">
    <label>Выберите проблему, которую хотите создать</label>
    <select class="form-control" [(ngModel)]="issueIdentifier" (ngModelChange)="onChangeIssue()" name="issueIdentifier">
      <option *ngFor="let i of issues" [value]="i.identifier">{{ i.name }}</option>
    </select>
  </div>
  <add-service-issue-form
    *ngIf="issueIdentifier == 'add_service'"
    [companyClient]="order.draft.delivery_company_client || order.draft.client.company_client"
    (onApplyService)="onApplyCompanyService($event)"
  ></add-service-issue-form>
  <add-product-issue-form
    *ngIf="issueIdentifier == 'add_product'"
    [companyClient]="order.draft.delivery_company_client || order.draft.client.company_client"
    (onApplyProduct)="onApplyCompanyProduct($event)"
  ></add-product-issue-form>
  <div class="form-group" *ngIf="(issueGroup == 'cancel_delivery' || issueGroup == 'reject_delivery') && cancelReasons.length > 0">
    <label>Выберите причину отмены</label>
    <select class="form-control" name="cancelReason" [(ngModel)]="cancelReason">
      <option *ngFor="let r of cancelReasons" [value]="r.identifier">{{ r.description }}</option>
    </select>
  </div>
  <div class="form-group" *ngIf="issueIdentifier == 'common.cancel_delivery' || issueIdentifier == 'common.reject_delivery'" [class.has-error]="!storehouseId">
    <label>Выберите склад для возврата груза</label>
    <select class="form-control" name="storehouse" [(ngModel)]="storehouseId">
      <option *ngFor="let h of storehouses" [value]="h.id">{{ h.address }}</option>
    </select>
  </div>
  <ng-template [ngIf]="issueIdentifier == 'return_products'">
    <div class="form-group">
      <div class="checkbox">
        <label><input type="checkbox" name="returnTomorrow" [(ngModel)]="returnTomorrow"> возврат на следующий день</label>
      </div>
    </div>
    <div class="form-group" *ngIf="returnTomorrow">
      <label>Причина</label>
      <select class="form-control" name="cargoStorageReason" [(ngModel)]="cargoStorageReason">
        <option value="driver_wants">водитель не может доставить</option>
        <option value="storehouse_cant_accept">склад не может принять груз</option>
      </select>
    </div>
  </ng-template>
  <div class="form-group" *ngIf="['reject_service_line', 'reject_product_line', 'remove_product_line', 'replace_product'].indexOf(issueIdentifier) >= 0 && destination">
    <label class="section-label">
      Выберите
      <span [ngSwitch]="issueIdentifier">
        <span *ngSwitchCase="'reject_product_line'">товар</span>
        <span *ngSwitchCase="'remove_product_line'">товар</span>
        <span *ngSwitchCase="'replace_product'">товар</span>
        <span *ngSwitchCase="'reject_service_line'">услугу</span>
      </span>,
      от которого нужно отказаться, и его количество
    </label>
    <table class="table table-striped table-condensed table-responsive" *ngIf="destination.delivery_product && destination.delivery_product.lines">
      <thead>
      <tr>
        <th></th>
        <th>Название</th>
        <th>Артикул</th>
        <th>Количество</th>
        <th>Сумма</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let line of destination.delivery_product.lines">
        <!-- строку с кодом 0 игнорируем - это доставка -->
        <ng-template
          [ngIf]="line.code != '0' && (((['reject_product_line', 'remove_product_line', 'replace_product'].indexOf(issueIdentifier) >= 0) && isProduct(line)) || (issueIdentifier == 'reject_service_line' && !isProduct(line)))">
          <td><input type="radio" name="product-code" [(ngModel)]="productCode" (ngModelChange)="onSelectProduct()" [value]="line.code"></td>
          <td>{{ line.name }}</td>
          <td>{{ line.code }}</td>
          <td>
            <div *ngIf="line.code != productCode">{{ line.count }}</div>
            <div *ngIf="line.code == productCode">
              <input type="number" class="form-control input-sm" name="count" [(ngModel)]="productCount">
            </div>
          </td>
          <td>{{ line.cost|humanNumber }}</td>
        </ng-template>
      </tr>
      </tbody>
    </table>
    <div class="form-group" *ngIf="issueIdentifier == 'remove_product_line'">
      <label>Выплата перевозчику</label>
      <input type="text" name="delivery_cost_for_freighter" [(ngModel)]="freighterSum" class="form-control">
    </div>
    <label class="section-label" *ngIf="issueIdentifier == 'replace_product'">Укажите замену</label>
    <add-product-issue-form
      *ngIf="issueIdentifier == 'replace_product'"
      [companyClient]="order.draft.delivery_company_client || order.draft.client.company_client"
      (onApplyProduct)="onApplyCompanyProduct($event)"
    ></add-product-issue-form>
  </div>
  <ng-template [ngIf]="issueIdentifier == 'change_delivery_cost'">
    <div class="form-group">
      <label>Стоимость для клиента</label>
      <input type="text" name="delivery_cost_for_client" [(ngModel)]="clientSum" class="form-control">
    </div>
    <div class="form-group">
      <label>Изменение стоимости для перевозчика</label>
      <input type="text" name="delivery_cost_for_freighter" [(ngModel)]="freighterSum" class="form-control">
    </div>
  </ng-template>
  <ng-template [ngIf]="issueIdentifier == 'other'">
    <div class="form-group">
      <label>Стоимость для магазина</label>
      <input type="text" name="delivery_cost_for_shop" [(ngModel)]="shopSum" class="form-control">
    </div>
    <div class="form-group">
      <label>Выплата перевозчику</label>
      <input type="text" name="delivery_cost_for_freighter" [(ngModel)]="freighterSum" class="form-control">
    </div>
  </ng-template>
  <ng-template [ngIf]="issueIdentifier == 'change_slot'">
    <div class="form-group">
      <label>Начала слота доставки</label>
      <input type="number" name="slot_start" [(ngModel)]="slotStart" class="form-control" required>
    </div>
    <div class="form-group">
      <label>Конец слота доставки</label>
      <input type="number" name="slot_end" [(ngModel)]="slotEnd" class="form-control" required>
    </div>
  </ng-template>
  <ng-template [ngIf]="issueIdentifier == 'adjustment_order_cost'">
    <div class="form-group">
      <label>Прибавить к сумме на счету для клиента</label>
      <input type="text" name="delivery_cost_for_client" [(ngModel)]="clientSum" class="form-control">
    </div>
    <div class="form-group">
      <label>Прибавить к сумме на счету перевозчика</label>
      <input type="text" name="delivery_cost_for_freighter" [(ngModel)]="freighterSum" class="form-control">
    </div>
    <div class="form-group">
      <label>Прибавить к сумме на счету Mover</label>
      <input type="text" name="delivery_cost_for_mover" [(ngModel)]="moverSum" class="form-control">
    </div>
  </ng-template>
  <div class="form-group">
    <label>Описание проблемы</label>
    <textarea class="form-control" [(ngModel)]="issueDescription" name="issueDescription" rows="8"></textarea>
  </div>
</form>
