<div class="detail-container" *ngIf="order">
  <div class="order-content">
    <div class="cargo-storage" *ngIf="order.draft.cargo_storage"></div>
    <div class="panel panel-default order-detail no-bottom-border">
      <div
        class="panel-heading meta-heading"
        [class.header-complete]="orderGroup == 'complete'"
        [class.header-canceled]="orderGroup == 'canceled'"
        [class.header-new]="orderGroup == 'new'"
        [class.header-pending]="orderGroup == 'pending'"
        [class.header-agreement]="orderGroup == 'agreement'"
      >
        <div class="flex-centered back-box" *ngIf="!userService.isDeliveryManager()"><a routerLink="/orders" class="flex-centered" role="button" title="Назад к списку заказов">
          <i class="material-icons nav-icon">keyboard_arrow_left
          </i>
        </a>
        </div>
        <div class="flex-centered back-box" *ngIf="userService.isDeliveryManager() && order.draft.optimization_task"><a [routerLink]="['/complex-deliveries', order.draft.optimization_task.id]" class="flex-centered" role="button" title="Назад к списку заказов">
          <i class="material-icons nav-icon">keyboard_arrow_left
          </i>
        </a>
        </div>
        <div class="heading-order-info">
          <div>
            Заказ <strong>#<a [routerLink]="['/drafts', order.draft.id]" title="Перейти к заявке">{{ order.draft.id }}</a> (<span class="order-id">{{ order.id }}</span>)</strong>
            от {{ order.created_at | dt:'dd.MM.yy H:mm' }} <span *ngIf="isTest" class="test-order">[тест]</span>
          </div>
        </div>
        <div class="btn-box" *ngIf="order.status == 'agree'">
          <button class="save-btn cancel-btn"
          (click)="declineDriverOrder()"
          >Отклонить</button>
          <button class="save-btn"
          (click)="acceptDriverOrder()"
          >Подтвердить</button>
        </div>
        <div *ngIf="order.status != 'agree' && (userService.isPrivilegedUser() || userService.isFreighter())">
        <order-controls
          [order]="order"
          (onUpdated)="onUpdated()"
        ></order-controls>
        </div>
      </div>
      <div class="panel-body meta-body">
        <ng-scrollbar autoHeightDisabled="false" viewClass="scrolled-content">

        <div class="panel-body">
          <div class="panel-row">
            <div class="pr-title">Город</div>
          </div>
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body city">
                <div class="city-name">{{ order.draft.city.name }}</div>
                <div class="city-time-zone">{{ order.draft.city|cityTz }}</div>
              </div>
              <div class="time-comment shake-horizontal" *ngIf="!(order.draft.city|myTz)">ниже всё время приведено в часовом поясе города {{ order.draft.city.name }}</div>
            </div>
          </div>
        </div>

        <div class="panel-body" *ngIf="order.draft.delivery_tracks && order.draft.delivery_tracks.length > 0">
          <div class="panel-row">
            <div class="pr-title">Трек-номер</div>
          </div>
          <div class="panel-row">
            <div class="br-box">
              <div class="pr-body">
                <div *ngFor="let track of order.draft.delivery_tracks" class="track-number-container">
                  {{ trackingServiceIdentifierToName(track.service) }}:
                  <span class="track-number" title="трек-номер Mover`а" (click)="onClickTrackNumber(track.internal_track_number)">{{ track.internal_track_number }}</span>
                  <span *ngIf="track.external_track_number">
                    <span class="glyphicon glyphicon-link"></span>
                    <span class="track-number" title="трек-номер '{{ trackingServiceIdentifierToName(track.service) }}'" (click)="onClickTrackNumber(track.external_track_number)">{{ track.external_track_number }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <div class="panel-row ">
            <div class="br-box panel-click"
                 (click)="expandPanel()">
              <div class="pr-header">Статус заказа</div>
              <div class="pr-body"> {{ order.status | orderStatus }}   <div class="pull-right">
                <i class="material-icons" *ngIf="expandBriefPanel==false">expand_more</i>
                <i class="material-icons" *ngIf="expandBriefPanel==true">expand_less</i>
              </div></div>

            </div>
          </div>
          <div class="panel-row">
            <order-status-panel [order]="order" [expandBriefPanel]="expandBriefPanel" (onUpdated)="onUpdated()"

                                class="full-width"
            ></order-status-panel>
          </div>
        </div>

        <div class="panel-body" *ngIf="enabledExternalStatuses">
          <div class="panel-row ">
            <div class="br-box panel-click" (click)="externalStatusesExpanded = !externalStatusesExpanded">
              <div class="pr-header">Внешний статус заказа</div>
              <div class="pr-body">
                {{ externalStatus }}
                <span
                  *ngIf="externalOrderStatus"
                  [className]="'external-status-' + externalOrderStatus"
                >({{ externalOrderStatusDescription }})</span>
                <div class="pull-right">
                  <i class="material-icons" *ngIf="!externalStatusesExpanded">expand_more</i>
                  <i class="material-icons" *ngIf="externalStatusesExpanded">expand_less</i>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-row" *ngIf="externalStatusesExpanded">
            <div class="status-list">
              <div class="status-element">
                <div class="flex-centered header">
                  Статус
                </div>
                <div class="time pull-right header">
                  Время
                </div>
              </div>
              <div *ngFor="let row of order.periods[0].external_history" class="status-element">
                <div class="flex-centered">
                  <div class="status-box">
                    {{ row.description }}
                  </div>
                </div>
                <div class="time pull-right">{{ row.created_at | withCityTz:order.draft.city | dt:'H:mm' }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body" *ngFor="let period of order.periods">
          <div class="panel-row">
            <div class="pr-title">Период</div>
            <div class="period-info" *ngIf="!editPeriod">
              <strong>
                {{ period.start | withCityTz:order.draft.city | dt:'dd.MM' }}
                {{ period.start | withCityTz:order.draft.city | dt:'H:mm' }}
                -
                <span *ngIf="(period.start|dt:'dd.MM') != (period.end|dt:'dd.MM')">
                {{ period.end | withCityTz:order.draft.city | dt:'dd.MM' }}
                </span>
                {{ period.end | withCityTz:order.draft.city | dt:'H:mm' }}
              </strong>
            </div>

            <a
              class="edit-link"
              [class.edit-link-editing]="editPeriod"
              (click)="editPeriod = switchEditor(editPeriod)"
              *ngIf="canBeEdited()"
            >
              <span *ngIf="!editPeriod">Редактировать</span>
              <span *ngIf="editPeriod">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="editPeriod">
            <period-editor class="full-width" [period]="period" [order]="order"
                           (onUpdated)="onUpdated()"></period-editor>
          </div>
        </div>

        <div class="panel-body">
          <div class="panel-row ">
            <div class="pr-title">
              Оплата
              <a
                class="pull-right edit-link"
                (click)="editPayment = switchEditor(editPayment)"
                [class.edit-link-editing]="editPayment"
                *ngIf="canBeEdited()">
                <span *ngIf="!editPayment">Редактировать</span>
                <span *ngIf="editPayment">Отменить</span>
              </a>
            </div>
          </div>


          <div *ngIf="!editPayment" class="panel-row">
            <table class="table tbl-order data-table">
              <thead>
              <tr>
                <td>Тип оплаты</td>
                <td *ngIf="order.promo_code_instances && order.promo_code_instances.length > 0">Промокод</td>
                <td>Скидка фикс.</td>
                <td>Скидка %</td>
                <td>Сумма</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{ order.pay_method | payMethod }}
                  <div class="pay-method-option" *ngIf="order.pay_method_option">
                    <span *ngIf="order.pay_method_option == 'link'">
                      <span *ngIf="order.payment_link">(<a [href]="order.payment_link" target="_blank">{{ order.pay_method_option|payMethodOption }}</a>)</span>
                      <span *ngIf="!order.payment_link">({{ order.pay_method_option|payMethodOption }})</span>
                    </span>
                    <span *ngIf="order.pay_method_option != 'link'">({{ order.pay_method_option|payMethodOption }})</span>
                  </div>
                </td>
                <td *ngIf="order.promo_code_instances && order.promo_code_instances.length > 0">
                  <div class="promo-code" *ngFor="let codeInstance of order.promo_code_instances">{{ codeInstance.promo_code.code }}</div>
                </td>
                <td>{{ order.fixed_discount }}</td>
                <td>{{ order.discount }} %</td>
                <td>{{ order.total_cost }} Р</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="!editPayment && order.payment_status != 'none'" class="panel-row for-payment">
            <table class="table tbl-order data-table">
              <thead>
              <tr>
                <td *ngIf="order.payed_from_account_sum > 0">Списано бонусов</td>
                <td>К оплате</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td *ngIf="order.payed_from_account_sum > 0">{{ order.payed_from_account_sum }} Р</td>
                <td>{{ order.for_payment }} Р</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="panel-row" *ngIf="!editPayment && order.payment_status != 'none'">
            <div class="br-box">
              <div class="pr-title panel-click">
                История стоимости заказа
                <a
                  class="btn btn-xs btn-default"
                  (click)="showCostHistory = !showCostHistory"
                >
                  <span *ngIf="!showCostHistory" class="glyphicon glyphicon-chevron-up"></span>
                  <span *ngIf="showCostHistory" class="glyphicon glyphicon-chevron-down"></span>
                </a>
              </div>
              <div class="pr-body" *ngIf="showCostHistory">
                <table class="table tbl-order data-table">
                  <thead>
                  <tr>
                    <td>Черновик</td>
                    <td>Исходная стоимость</td>
                    <td>Таксометр</td>
                    <td>Окончательная стоимость</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ order.draft.cost }} Р</td>
                    <td>{{ order.first_cost }} Р</td>
                    <td>{{ order.taximeter }} Р</td>
                    <td>{{ order.cost }} Р</td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

          <div class="panel-row" *ngIf="!editPayment && order.payment_status != 'none' && userService.isPrivilegedUser()">
            <div class="br-box">
              <div class="pr-title panel-click">
                <span *ngIf="enabledExecutorSumHistory">История изменения баланса участников заказа</span>
                <span *ngIf="!enabledExecutorSumHistory">История изменения баланса клиента</span>
                <a
                  class="btn btn-xs btn-default"
                  (click)="onSwitchShowAccountSumHistory()"
                >
                  <span *ngIf="!showAccountSumHistory" class="glyphicon glyphicon-chevron-up"></span>
                  <span *ngIf="showAccountSumHistory" class="glyphicon glyphicon-chevron-down"></span>
                </a>
              </div>
              <div class="pr-body" *ngIf="showAccountSumHistory">
                <div class="account-sum-history">
                  <div class="pr-title">Баланс клиента</div>
                  <table class="table table-condensed" *ngIf="clientSumHistory !== null">
                    <thead>
                    <tr>
                      <th>Дата</th>
                      <th>До</th>
                      <th>После</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let r of clientSumHistory">
                      <td>{{ r.created_at|dt:"dd.MM.yy HH:mm" }}</td>
                      <td>{{ r.old_value|humanNumber }} Р</td>
                      <td>{{ r.new_value|humanNumber }} Р</td>
                    </tr>
                    <tr *ngIf="clientSumHistory.length == 0">
                      <td colspan="3" class="nothing">Изменения отсутствуют</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="loading" *ngIf="clientSumHistory === null">загружается...</div>
                </div>
                <div class="account-sum-history" *ngIf="executorSumHistory !== null && executorSumHistory.length > 0">
                  <div class="pr-title">Баланс перевозчика</div>
                  <table class="table table-condensed" *ngIf="executorSumHistory !== null">
                    <thead>
                    <tr>
                      <th>Дата</th>
                      <th>До</th>
                      <th>После</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let r of executorSumHistory">
                      <td>{{ r.created_at|dt:"dd.MM.yy HH:mm" }}</td>
                      <td>{{ r.old_value|humanNumber }} Р</td>
                      <td>{{ r.new_value| humanNumber }} Р</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="loading" *ngIf="executorSumHistory === null">загружается...</div>
                </div>
                <div class="account-sum-history" *ngIf="executorCompanySumHistory !== null && executorCompanySumHistory.length > 0">
                  <div class="pr-title">Баланс "{{ order.freighter.name }}"</div>
                  <table class="table table-condensed" *ngIf="executorCompanySumHistory !== null">
                    <thead>
                    <tr>
                      <th>Дата</th>
                      <th>До</th>
                      <th>После</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let r of executorCompanySumHistory">
                      <td>{{ r.created_at|dt:"dd.MM.yy HH:mm" }}</td>
                      <td>{{ r.old_value|humanNumber }} Р</td>
                      <td>{{ r.new_value| humanNumber }} Р</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="loading" *ngIf="executorCompanySumHistory === null">загружается...</div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel-row" *ngIf="!editPayment && order.calculation && order.calculation.length !== 0">
            <div class="br-box">
              <div class="pr-title panel-click">
                Калькуляция
                <a
                  class="btn btn-xs btn-default"
                  (click)="showCalculation = !showCalculation"
                >
                  <span *ngIf="!showCalculation" class="glyphicon glyphicon-chevron-up"></span>
                  <span *ngIf="showCalculation" class="glyphicon glyphicon-chevron-down"></span>
                </a>
              </div>
              <div class="pr-body" *ngIf="showCalculation">
                <calculation
                  [calculation]="order.calculation"
                  [destinations]="order.periods[0].destinations"
                  [report]="orderReport"
                  [isFreightersOrder]="order.draft.is_freighters_draft"
                  [perMinuteBilling]="order.calculation['per_minute_billing'] || false"></calculation>
              </div>
            </div>
          </div>

          <div class="panel-row" *ngIf="enabledCostAdjustments">
            <div class="br-box">
              <div class="pr-title panel-click">
                Корректировки
                <a
                  class="btn btn-xs btn-default"
                  (click)="showCostAdjustments = !showCostAdjustments"
                >
                  <span *ngIf="!showCostAdjustments" class="glyphicon glyphicon-chevron-up"></span>
                  <span *ngIf="showCostAdjustments" class="glyphicon glyphicon-chevron-down"></span>
                </a>
              </div>
              <div class="pr-body" *ngIf="showCostAdjustments">
                <cost-adjustment [costAdjustments]="costAdjustments"></cost-adjustment>
              </div>
            </div>
          </div>

          <div class="panel-row" *ngIf="editPayment">
            <payment-editor [order]="order" (onUpdated)="onUpdated()" class="full-width"></payment-editor>
          </div>
        </div>

        <div class="panel-body" *ngIf="enabledInsurance">
          <div class="panel-row ">
            <div class="pr-title">
              Страховка
              <a
                class="pull-right edit-link"
                (click)="editInsurance = switchEditor(editInsurance)"
                [class.edit-link-editing]="editInsurance"
                *ngIf="canBeInsured()">
                <span *ngIf="!editInsurance">Застраховать</span>
                <span *ngIf="editInsurance">Отменить</span>
              </a>
              <a class="pull-right edit-link cancel-link" (click)="onCancelInsuring()" *ngIf="canBeCanceledInsuring()">
                Отменить
              </a>
            </div>
          </div>
          <div *ngIf="!editInsurance" class="panel-row">
            <table class="table tbl-order data-table insurances">
              <caption *ngIf="order.insurances.length == 0">Груз не застрахован</caption>
              <thead *ngIf="order.insurances.length > 0">
              <tr>
                <td>Тип страховки</td>
                <td>Груз</td>
                <td>Стоимость груза</td>
                <td>Платёж</td>
              </tr>
              </thead>
              <tbody *ngFor="let insurance of order.insurances">
              <tr>
                <td>{{ insurance.section|insuranceSection }}</td>
                <td>{{ insurance.cargo_description }}</td>
                <td>{{ insurance.cargo_cost }}<span *ngIf="insurance.cargo_cost > 0"> Р</span></td>
                <td>
                  <span *ngIf="insurance.is_insured" class="glyphicon glyphicon-ok" title="застраховано"></span>
                  {{ insurance.client_insurance_price }}<span *ngIf="insurance.client_insurance_price > 0"> Р</span>
                </td>
              </tr>
              <tr class="sub-row">
                <td colspan="4" >
                  Накладная: {{ insurance.invoice_id }}
                  <span *ngIf="insurance.is_insured">(застраховано)</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="editInsurance">
            <order-insurance-editor [order]="order" (onUpdated)="onUpdated()" class="full-width"></order-insurance-editor>
          </div>
        </div>

        <div class="panel-body client">
          <div class="panel-row pr-title" *ngIf="!userService.isCustomer()">
            Клиент
            <a
              class="pull-right edit-link"
              (click)="editFreighterClient = switchEditor(editFreighterClient)"
              [class.edit-link-editing]="editFreighterClient"
              *ngIf="enabledFreighterClient">
              <span *ngIf="!editFreighterClient">Редактировать</span>
              <span *ngIf="editFreighterClient">Отменить</span>
            </a>
          </div>
          <div class="panel-row">
            <table class="table tbl-order" *ngIf="enabledClient">
              <thead>
              <tr>
                <td>Имя</td>
                <td>Телефон</td>
                <td *ngIf="order.draft.client.company_client">Компания</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ order.draft.client | fullName }}</td>
                <td>
                  <div *ngIf="order.draft.client.phone">
                    <a class="phone" (click)="voximplantService.call(order.draft.client.phone)" *ngIf="userService.isPrivilegedUser()">{{ order.draft.client.phone }}</a>
                    <span *ngIf="!userService.isPrivilegedUser()">{{ order.draft.client.phone|formatPhone }}</span>
                  </div>
                </td>
                <td *ngIf="order.draft.client.company_client">{{ order.draft.client.company_client.name }}</td>
              </tr>
              </tbody>
            </table>

            <table class="table tbl-order" *ngIf="enabledFreighterClient && !editFreighterClient">
              <thead>
              <tr>
                <td>Наименование</td>
                <td>Контактное лицо</td>
                <td>Контактный телефон</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ order.draft.freighter_client?.name }}</td>
                <td>{{ order.draft.freighter_client?.contact_name }}</td>
                <td><span *ngIf="order.draft.freighter_client?.contact_phone">{{ order.draft.freighter_client.contact_phone|formatPhone }}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="userService.isPrivilegedUser()" class="intercom">
            <div class="view panel-row" *ngIf="!editClientIntercomDialog">
              <div class="link">
                <div *ngIf="order.draft.intercom_dialog"><a href="{{ order.draft.intercom_dialog }}" target="_blank"><span class="glyphicon glyphicon-comment"></span> Диалог в интеркоме</a></div>
                <div *ngIf="!order.draft.intercom_dialog"><span class="glyphicon glyphicon-comment"></span> Нет ссылки на диалог</div>
              </div>
              <div class="control">
                <a (click)="editClientIntercomDialog = true">
                  <span *ngIf="!order.draft.intercom_dialog">Добавить</span>
                  <span *ngIf="order.draft.intercom_dialog">Изменить</span>
                </a>
              </div>
            </div>
            <div *ngIf="editClientIntercomDialog">
              <form (submit)="onSaveClientIntercomDialogLink()" class="panel-row">
                <div class="input">
                  <input name="intercomDialog" [(ngModel)]="order.draft.intercom_dialog" class="form-control" placeholder="Ссылка на диалог в интеркоме">
                </div>
                <div>
                  <button type="submit" class="btn btn-primary">Сохранить</button>
                </div>
                <div>
                  <button type="button" class="btn btn-default" title="Отменить" (click)="editClientIntercomDialog = false"><span class="glyphicon glyphicon-remove"></span></button>
                </div>
              </form>
            </div>
          </div>
          <div class="panel-row" *ngIf="editFreighterClient">
            <freighter-client-editor [(client)]="order.draft.freighter_client" [freighter]="order.freighter" (clientChange)="onUpdatedFreighterClient($event)"></freighter-client-editor>
          </div>
        </div>
        <div class="panel-body client" *ngIf="order.draft.legal_entity">
          <div class="panel-row">
            <div class="pr-title">Юридическое лицо</div>
          </div>
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body">
                <span *ngIf="!showLegalEntityDetails" class="legal-entity-name" (click)="showLegalEntityDetails = true" title="Показать платёжные реквизиты">{{ order.draft.legal_entity.name }}</span>
                <legal-entity-detail *ngIf="showLegalEntityDetails" [legalEntity]="order.draft.legal_entity" [compact]="true"></legal-entity-detail>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="panel-row pr-title">
            Параметры заказа
            <a
              [class.edit-link-editing]="editOrderParameters"
              class="pull-right edit-link"
              (click)="editOrderParameters = switchEditor(editOrderParameters)"
              *ngIf="canBeEdited()">
              <span *ngIf="!editOrderParameters">Редактировать</span>
              <span *ngIf="editOrderParameters">Отменить</span>
            </a>
          </div>
          <div *ngFor="let period of order.periods">


            <div class="panel-row">
              <div class="col-lg-12 no-padding">
                <order-areas-info *ngIf="period.areas" [areas]="period.areas"></order-areas-info>
                <table class="table tbl-order">
                  <thead>
                  <tr>
                    <td>Время работы</td>
                    <td>Суммарное расстояние</td>
                    <td *ngIf="period.out_mkad_distance > 0">За МКАД</td>
                    <td>Грузчики</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td title="ЧЧ:ММ">{{ period | periodDuration }}</td>
                    <td>{{ period.distance / 1000 || 0 }} км</td>
                    <td *ngIf="period.out_mkad_distance > 0">{{ period.out_mkad_distance / 1000 }}</td>
                    <td>{{ period.loaders }}<span *ngIf="period.assembly"> (сборка/разборка)</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="panel-row" *ngIf="editOrderParameters">
              <order-parameters-editor class="full-width" [order]="order"
                                       (onUpdated)="onUpdated()"></order-parameters-editor>
            </div>

          </div>
        </div>
        <div class="panel-body">
          <div class="panel-row pr-title">
            <div class="route-header">
              <div>
                Маршрут
                <span *ngIf="enabledTrackVisibilityControl" class="track-controls" (click)="onSwitchTrackShow()">
                  (<span *ngIf="showTrack">Скрыть трек</span><span *ngIf="!showTrack">Показать трек</span>)
                </span>
              </div>
              <div class="delivery-controls" *ngIf="enabledDeliveryIssues || enabledDeliveryHistory">
                <span *ngIf="enabledDeliveryIssues" class="glyphicon glyphicon-alert issue-button" title="Просмотр проблем на заказе" (click)="onShowOrderIssues()"></span>
                <span *ngIf="enabledDeliveryHistory" class="glyphicon glyphicon-piggy-bank issue-button" title="Просмотр калькуляции по заказу" (click)="onShowOrderDeliveryHistory()"></span>
              </div>
            </div>
            <a
              class="pull-right edit-link"
              (click)="editRoute = switchEditor(editRoute)"
              *ngIf="order.periods[0].status != 'finished' && canBeEdited()">
              <span *ngIf="!editRoute">Редактировать</span>
              <span *ngIf="editRoute">Отменить</span>
            </a>
          </div>
          <div *ngFor="let period of order.periods">
            <div *ngIf="!editRoute">
              <div class="panel-row address-row" *ngFor="let destination of period.destinations; let i = index"
              >
                <div class="caption full-width">
                  <div>
                    <span class="address-indicator" [class.visited]="i <= visitedDestination" [class.next]="i == nextDestination && i != visitedDestination"></span>
                    Адрес {{ i+1 }}
                  </div>
                  <div class="delivery-controls" *ngIf="(enabledDeliveryHistory || enabledDeliveryIssues)">
                    <span *ngIf="enabledDeliveryIssues && i > 0" class="glyphicon glyphicon-alert issue-button" title="Просмотр и создание проблем на точке" (click)="onShowDestinationDeliveryIssues(destination)"></span>
                    <span *ngIf="enabledDeliveryHistory" class="glyphicon glyphicon-piggy-bank issue-button" title="Просмотр калькуляции для адреса" (click)="onShowDestinationDeliveryHistory(destination)"></span>
                  </div>
                </div>
                <div class="full-width">
                  <span *ngIf="destination.time_slot_begin && destination.time_slot_end" title="Тайм-слот">[{{ destination.time_slot_begin | withCityTz:order.draft.city | dt:'H:mm' }}-{{ destination.time_slot_end | withCityTz:order.draft.city | dt:'H:mm' }}] - </span>
                  <span *ngIf="destination.arrival_time" title="Время прибытия">{{ destination.arrival_time | withCityTz:order.draft.city | dt:'H:mm' }} - </span>
                  <a href="#"
                                           (click)="focusMap(destination.destination.lat, destination.destination.lon)">
                  {{ destination.destination.addr }}
                  </a>
                </div>
                <div *ngIf="destination.client_legal_entity" class="legal-entity">
                  <div class="title">Юридическое лицо</div>
                  <span *ngIf="!isVisibleClientLegalEntityDetailsInDestination(destination)" class="legal-entity-name"
                        (click)="onShowClientLegalEntityDetailsInDestination(destination)"
                        title="Показать платёжные реквизиты">
                  {{ destination.client_legal_entity.name }}
                  </span>
                  <legal-entity-detail *ngIf="isVisibleClientLegalEntityDetailsInDestination(destination)"
                                       [legalEntity]="destination.client_legal_entity"
                                       [compact]="true"></legal-entity-detail>

                </div>
                <div class="address-info">
                  <div>
                    <span *ngIf="destination.floor">{{ destination.floor }} этаж </span>
                    <span *ngIf="destination|destinationOptions">, {{ destination|destinationOptions }}</span>
                    <span class="text-muted" *ngIf="destination.contact_name || destination.contact_phone || destination.is_contacts_hidden">
                      <span *ngIf="destination.contact_name">{{ destination.contact_name }}</span>
                      <span *ngIf="destination.contact_phone">
                        <a *ngIf="userService.isPrivilegedUser()" class="phone" (click)="voximplantService.call(destination.contact_phone)">{{ destination.contact_phone }}</a>
                        <span *ngIf="!userService.isPrivilegedUser()">{{ destination.contact_phone|formatPhone }}</span>
                      </span>
                      <span *ngIf="destination.is_contacts_hidden">телефон скрыт</span>
                      <span *ngIf="destination.contact_send_sms">, отправить смс-уведомление</span>
                    </span>
                  </div>
                  <delivery-product-wrapper *ngIf="destination.delivery_product" [product]="destination.delivery_product" [order]="order" [period]="period" [destination]="destination"></delivery-product-wrapper>
                  <div class="text-muted" *ngIf="destination.comment">

                    <em class="pre-wrap">{{ destination.comment }}</em>
                  </div>
                </div>
              </div>

            </div>
            <div class="panel-row no-bottom-border" *ngIf="editRoute">
              <route-editor class="full-width" [order]="order" [period]="period"
                            [legalEntitySelectorDialog]="legalEntitySelectorDialog"
                            (onFocusedAddr)="onFocusAddrField($event)"
                            (onUpdated)="onUpdated()"
                            (onRequestDeliveryProductDialog)="onShowDeliveryProductDialog($event)"
              ></route-editor>
            </div>
          </div>
        </div>
        <div class="panel-body driver" *ngFor="let period of order.periods; let i = index">
          <div class="panel-row pr-title">
            <span>Исполнитель</span>
            <span class="controls" [class.crews-on-map]="isCrewsOnMap" *ngIf="period.status != 'finished' &&  canBeEdited()">
              Выбрать
              <a class="edit-link" (click)="onShowFreighterSelectDialog()">из списка</a>
              или
              <a class="edit-link" [class.active]="isCrewsOnMap" (click)="onToggleCrewsOnMap()">на карте</a>
            </span>
          </div>
          <div class="panel-row">
            <table class="table data-table executor" cols="4">
              <thead>
              <tr>
                <th class="hard-table-half">
                  Водитель
                </th>
                <th>
                  Автомобиль
                </th>
                <th *ngIf="showEmployerPassport">Документы</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td *ngIf="period.employer" class="hard-table-half">
                  <div class="freighter" *ngIf="userService.isPrivilegedUser()" title="компания">{{ order.freighter.name }}</div>
                  <div class="employee">{{ period.employer.account | fullName }}</div>
                  <div *ngIf="period.employer.account.phone">
                    <a *ngIf="userService.isPrivilegedUser()" class="phone" (click)="voximplantService.call(period.employer.account.phone)">{{ period.employer.account.phone }}</a>
                    <span *ngIf="!userService.isPrivilegedUser()">{{ period.employer.account.phone|formatPhone }}</span>
                  </div>
                </td>
                <td *ngIf="period.transport">
                  {{ period.transport.car_model }}
                  <br>
                  {{ period.transport.features.number }}
                </td>
                <td *ngIf="showEmployerPassport">
                  <span class="passport-link" (click)="onShowPassportDialog()">Паспорт</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row">
            <table class="table data-table" *ngIf="period.transport">
              <thead>
              <tr>
                <th>
                  Тип кузова
                </th>
                <th>
                  Грузоподъёмность
                </th>
                <th>
                  Размеры (Д х Ш х В)
                </th>
                <th>
                  Въезд в ТТК
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ period.transport | transportBody }}</td>
                <td>{{ period.transport | transportCapacity }} т</td>
                <td>{{ period.transport | transportSize }}</td>
                <td *ngIf="period.transport.features.Razreshen_v_ezd_v_TTK">есть</td>
                <td *ngIf="!period.transport.features.Razreshen_v_ezd_v_TTK">нет</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="userService.isPrivilegedUser()" class="intercom">
            <div class="view panel-row" *ngIf="!editDriverIntercomDialog">
              <div class="link">
                <div *ngIf="order.driver_intercom_dialog"><a href="{{ order.driver_intercom_dialog }}" target="_blank"><span class="glyphicon glyphicon-comment"></span> Диалог в интеркоме</a></div>
                <div *ngIf="!order.driver_intercom_dialog"><span class="glyphicon glyphicon-comment"></span> Нет ссылки на диалог</div>
              </div>
              <div class="control">
                <a (click)="editDriverIntercomDialog = true">
                  <span *ngIf="!order.driver_intercom_dialog">Добавить</span>
                  <span *ngIf="order.driver_intercom_dialog">Изменить</span>
                </a>
              </div>
            </div>
            <div *ngIf="editDriverIntercomDialog">
              <form (submit)="onSaveDriverIntercomDialogLink()" class="panel-row">
                <div class="input">
                  <input name="intercomDialog" [(ngModel)]="order.driver_intercom_dialog" class="form-control" placeholder="Ссылка на диалог в интеркоме">
                </div>
                <div>
                  <button type="submit" class="btn btn-primary">Сохранить</button>
                </div>
                <div>
                  <button type="button" class="btn btn-default" title="Отменить" (click)="editDriverIntercomDialog = false"><span class="glyphicon glyphicon-remove"></span></button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="panel-row pr-title">
            Фотографии и комментарии
            <a
              [class.edit-link-editing]="editPhotoComment"
              *ngIf="canBeEdited()"
              class="pull-right edit-link"
              (click)="editPhotoComment = switchEditor(editPhotoComment)"
            >
              <span *ngIf="!editPhotoComment">Редактировать</span>
              <span *ngIf="editPhotoComment">Отменить</span>
            </a>
          </div>
          <div class="panel-row mar8top" *ngIf="!editPhotoComment">
            <div *ngIf="order.draft.photos.length > 0" class="images-container">
              <div class="img-box" *ngFor="let photo of order.draft.photos">
                <a href="{{ photo.url }}" [attr.data-lightbox]="'order-'+order.id"><img
                  class="img-rounded" src="{{ photo.image_sizes.small.path }}"></a>
              </div>
            </div>

          </div>
          <div class="panel-row comment-row mar16top" *ngIf="!editPhotoComment">
            <pre *ngIf="order.draft.comment && order.draft.comment != ''">{{ order.draft.comment }}</pre>
          </div>
          <div class="panel-row" *ngIf="editPhotoComment">
            <photo-comment-editor [order]="order" (onUpdated)="onUpdated()"
                                  (onUpdatedPhoto)="onUpdatePhoto()"></photo-comment-editor>
          </div>
        </div>

        <div class="panel-body no-bottom-border" *ngIf="showTariffs">
          <div class="panel-row pr-title">
            Тарифы
            <a
              [class.edit-link-editing]="editTariff"
              class="pull-right edit-link"
              (click)="editTariff = switchEditor(editTariff)"
              *ngIf="canBeEditedTariff()">
              <span *ngIf="!editTariff">Редактировать</span>
              <span *ngIf="editTariff">Отменить</span>
            </a>
          </div>
          <div class="panel-row" *ngIf="!editTariff">
            <div *ngIf="tariff">
              <table class="table tariff-table">
                <caption class="caption-in-table">Транспорт</caption>
                <thead>
                <tr>
                  <th>Минимум</th>
                  <th>Доп. час</th>
                  <th>км за МКАД</th>
                  <th>Въезд в ТТК</th>
                  <th>Гидролифт</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ tariff.min_price }} / {{ tariff.min_hours }} ч</td>
                  <td>{{ tariff.additional_hour_price }}</td>
                  <td>{{ tariff.after_mkad_km_price }}</td>
                  <td>{{ tariff.ttk_price }}</td>
                  <td>{{ tariff.hydroelevator_hour_price }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="panel-row" *ngIf="!editTariff">
            <table class="table tariff-table" *ngIf="loaderTariff" cols="5">
              <caption class="caption-in-table">Грузчики</caption>
              <thead>
              <tr>
                <th>Минимум</th>
                <th>Доп. час</th>
                <th>Час в пути</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ loaderTariff.min_price }} / {{ loaderTariff.min_hours }} ч
                </td>
                <td>{{ loaderTariff.additional_hour_price }}</td>
                <td>{{ loaderTariff.on_the_way_hour_price }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="!editTariff">
            <table class="table tariff-table" cols="5" *ngIf="assemblerTariff">
              <caption class="caption-in-table">Сборщики</caption>
              <thead>
              <tr>
                <th>Минимум</th>
                <th>Доп. час</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ assemblerTariff.min_price }} / {{ assemblerTariff.min_hours
                  }} ч
                </td>
                <td>{{ assemblerTariff.additional_hour_price }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="!editTariff">
            <table class="table tariff-table" *ngIf="liftingTariff" cols="5">
              <caption class="caption-in-table">Подъём/спуск</caption>
              <thead>
              <tr>
                <th>Минимум</th>
                <th>Доп. час</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ liftingTariff.min_price }} / {{ liftingTariff.min_hours }}
                  ч
                </td>
                <td>{{ liftingTariff.additional_hour_price }}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="panel-row" *ngIf="editTariff">
            <tariff-editor class="full-width mar16top" [order]="order" [period]="order.periods[0]"
                           (onUpdated)="onUpdated()"></tariff-editor>
          </div>

        </div>

        <div class="panel-body notes-panel" *ngIf="order.id != null && userService.isPrivilegedUser()">
          <div class="panel-row">
            <div class="pr-title">Заметки</div>
          </div>
          <div>
            <form (submit)="onSaveNotes()">
              <div class="form-group">
                <textarea class="notes-area form-control" name="notes" [(ngModel)]="order.notes" rows="4"></textarea>
              </div>
              <div class="notes-templates">
                <span *ngFor="let template of notesTemplates; let i = index;">
                  <span class="template" [title]="template.title" (click)="onClickNotesTemplate(i)">{{ template.template }}</span><span *ngIf="i < notesTemplates.length - 1"> </span>
                </span>
              </div>
              <button type="submit" class="save-btn" [disabled]="!isNotesUpdated()">Сохранить</button>
            </form>
          </div>
        </div>
        </ng-scrollbar>
      </div>
    </div>

  </div>
</div>

<new-issue *ngIf="enabledCreateIssue" (onCreatedIssue)="onCreatedIssue()"></new-issue>
<destination-delivery-issues *ngIf="enabledDeliveryIssues" (onCreateIssue)="onShowNewIssueDialog($event)"></destination-delivery-issues>
<order-delivery-issues *ngIf="enabledDeliveryIssues"></order-delivery-issues>
<destination-delivery-history *ngIf="enabledDeliveryHistory"></destination-delivery-history>
<order-delivery-history *ngIf="enabledDeliveryHistory"></order-delivery-history>
<legal-entity-selector-dialog #legalEntitySelectorDialog (selected)="routeEditor.onSelectLegalEntity($event)"></legal-entity-selector-dialog>
<freighters-fast-list-dialog [singleSelectMode]="true" (savedCrews)="onChangeCrew($event)"
                             *ngIf="order && canBeEdited()"></freighters-fast-list-dialog>
<passport-dialog *ngIf="showEmployerPassport"></passport-dialog>

<delivery-product-dialog (onLinesUpdated)="onUpdateDeliveryProduct($event)"></delivery-product-dialog>
