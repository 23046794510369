/**
 * Декодер упакованного в строку массива целочисленных значений
 */
export class IntArrayDecoder {
  private readonly MAGIC_END = 0x1;

  constructor(private readonly maskSize: number) {
    if (this.maskSize > 8) {
      throw new Error('Mask size is too big');
    }
  }

  unpack(packed: string): number[] {
    if (packed.length === 0) {
      return [];
    }

    const packedBin = this.base64ToBytes(packed);
    let binstr = '';
    for(const packedBinElement of packedBin) {
      binstr = packedBinElement.toString(2).padStart(8, '0') + binstr;
    }
    // console.log(binstr, binstr.length);
    // console.log(binstr.slice(0, -5), binstr.slice(0, -5).length);
    let n = 0;
    // console.log(binstr.slice(0, -5).split('').reverse().join('').match(/.{1,2}/g).map(v => '(' + (n++) * 2 + ')' + v).join('.'));

    const valueSize = packedBin[0] & (0xFF >> (8 - this.maskSize));
    // console.log('value size', valueSize, 'binary size', packedBin.byteLength, 'values count', (packedBin.byteLength * 8 - this.maskSize) / valueSize);
    const valueSizeInBytes = Math.ceil(valueSize / 8);
    let valueMask = 0;

    for (let i = 0; i < valueSize; i++) {
      valueMask = (valueMask << 1) | 1;
    }

    const valuesCount = Math.floor((packedBin.byteLength * 8 - this.maskSize) / valueSize);
    const values: number[] = [];

    for (let i = 0; i < valuesCount; i++) {
      values.push(this.getValueFromBuffer(packedBin, valueSize, valueSizeInBytes, valueMask, i));
    }

    let end = values.pop();

    // console.log('raw finally array size', values.length);

    while (end !== this.MAGIC_END) {
      // console.log(end);
      end = values.pop();
    }

    // console.log('finally array size', values.length);

    return values;
  }

  private base64ToBytes(base64) {
    const binString = atob(base64);
    return (<any>Uint8Array).from(binString, (m) => m.codePointAt(0));
  }

  private getValueFromBuffer(buffer: Uint8Array, valueSize: number, valueSizeInBytes: number, valueMask: number, numValue: number): number {
    const bitNum = numValue * valueSize + this.maskSize;
    const byteNum = bitNum >> 3;
    const valueContainer = buffer.slice(byteNum, byteNum + valueSizeInBytes + 1);
    let shift = 0;
    let value = 0;

    for (let i = 0, l = valueContainer.byteLength; i < l; i++) {
      value |= valueContainer[i] << shift;
      shift += 8;
    }

    const maskOffset = bitNum & 0x7;
    const mask = valueMask << maskOffset;

    // console.log('decoded bitNum', bitNum, 'byte num', byteNum, 'value', (value & mask) >> maskOffset, 'mask', mask.toString(2), 'mask offset', maskOffset);

    return (value & mask) >> maskOffset;
  }
}
