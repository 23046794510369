<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row">
  <div class="no-padding orders-forms orders-form-box">
    <div>
      <form class="form-inline orders-forms">
        <div class="form-group search-box">
          <input
            type="text"
            class="orders-search orders-control"
            name="search"
            [(ngModel)]="search"
            (ngModelChange)="onChangeFilter()"
            placeholder="Поиск"
            size="50">
        </div>
        <div class="form-group" *ngIf="userInfo.isPrivilegedUser()">
          <div class="labeled-select">
            <label for="company-client">Компания-клиент</label>
            <select name="companyFilter"
                    [(ngModel)]="companyClientFilter"
                    (change)="onChangeFilter()"
                    id="company-client"
            >
              <option value="0" selected="selected">Любой</option>
              <option *ngFor="let company of companyClients" [value]="company.id">{{ company.name }}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <select name="status"
                  [(ngModel)]="searchStatusGroup"
                  class="orders-control orders-status-select"
                  (change)="onChangeFilter()">
            <option *ngFor="let groupKey of searchStatusGroupValues" value="{{ groupKey }}">{{ searchStatusGroups[groupKey].name }}</option>
          </select>
        </div>
        <div class="separator"></div>
        <div class="form-group search-box" *ngIf="!userInfo.isSimpleCustomer()">
          <input
                  type="text"
                  class="orders-search orders-control"
                  name="trackNumber"
                  [(ngModel)]="trackNumberFilter"
                  (ngModelChange)="onChangeFilter()"
                  placeholder="Трек-номер"
                  size="50">
        </div>
        <div class="form-group search-box" *ngIf="!userInfo.isSimpleCustomer()">
          <input
                  type="text"
                  class="orders-search orders-control"
                  name="cargoCode"
                  [(ngModel)]="cargoCodeFilter"
                  (ngModelChange)="onChangeFilter()"
                  placeholder="Артикул товара"
                  size="50">
        </div>
        <div class="separator" *ngIf="!userInfo.isSimpleCustomer()"></div>
        <div class="controls-group">
          <div class="group-title">Дата создания</div>
          <div class="form-group">
            <order-panel-datepicker class="date" [(value)]="after" [datepicker]="afterDatePickerOptions" [timepicker]="false" (dateChange)="onChangeFilter()" name="after" ngDefaultControl></order-panel-datepicker>
          </div>
          <div class="form-group">
            <order-panel-datepicker class="date" [(value)]="before" [datepicker]="beforeDatePickerOptions" [timepicker]="false" (dateChange)="onChangeFilter()" name="before"></order-panel-datepicker>
          </div>
          <div class="splitter"></div>
        </div>
        <div class="controls-group">
          <div class="group-title">Запланировано</div>
          <div class="form-group">
            <order-panel-datepicker class="date" [(value)]="scheduledAfter" [datepicker]="scheduledAfterDatePickerOptions" [timepicker]="false" (dateChange)="onChangeFilter()" name="scheduledAfter"></order-panel-datepicker>
          </div>
          <div class="form-group">
            <order-panel-datepicker class="date" [(value)]="scheduledBefore" [datepicker]="scheduledBeforeDatePickerOptions" [timepicker]="false" (dateChange)="onChangeFilter()" name="scheduledBefore"></order-panel-datepicker>
          </div>
        </div>
        <div class="form-group" *ngIf="!drafts.filter.isEmpty()">
          <div class="filter-controls-box">
            <span class="clean-filter" (click)="onCleanFilter()">очистить фильтр</span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div>
    <pager [list]="drafts" (onPage)="onPaginationPage($event)"></pager>
  </div>
  <table class="orders-table" [class.rolled-up]="rolledUp">
    <thead>
    <tr class="orders-table-header">
      <th>Номер</th>
      <th>Заказ</th>
      <th>Клиент</th>
      <th *ngIf="userInfo.isDeliveryManager() || userInfo.isPrivilegedUser()">Тариф</th>
      <th>Маршрут</th>
      <th *ngIf="!userInfo.isDeliveryManager()">Стоимость</th>
      <th>Подбор</th>
      <th *ngIf="userInfo.isDeliveryManager() || userInfo.isPrivilegedUser()">Доставка</th>
      <th></th>
    </tr>
    </thead>
    <tbody *ngFor="let draft of drafts.drafts;let rowIndex = index">
    <tr class="order-table-row">
      <td
        [routerLink]="['/drafts', draft.id]"
        [class.search-stopped]="draft.taxi_search && searchStatusGroups['stopped'].statuses.indexOf(draft.taxi_search.status) > -1"
        [class.search-new]="!draft.taxi_search"
        [class.search-executing]="draft.taxi_search && searchStatusGroups['search'].statuses.indexOf(draft.taxi_search.status) > -1"
        [class.search-completed]="draft.taxi_search && searchStatusGroups['completed'].statuses.indexOf(draft.taxi_search.status) > -1"
        [class.search-accepted]="draft.taxi_search && searchStatusGroups['accepted'].statuses.indexOf(draft.taxi_search.status) > -1"
        [class.search-not-found]="draft.taxi_search && searchStatusGroups['notFound'].statuses.indexOf(draft.taxi_search.status) > -1"
      >
        <div class="order-number"><a [routerLink]="['/drafts', draft.id]">{{ draft.id }}</a></div>
        <div class="order-date">{{ draft.created_at | dt:'dd.MM.yyyy H:mm' }}</div>
        <div class="test-order" *ngIf="isTest(draft)">[тест]</div>
        <div class="order-tags" *ngIf="draft.tags">
          <span class="tag" *ngFor="let tag of draft.tags">#{{ tag }} </span>
        </div>
      </td>
      <td *ngIf="!draft.orders || draft.orders.length === 0" [routerLink]="['/drafts', draft.id]">&nbsp;</td>
      <td *ngIf="draft.orders && draft.orders.length > 0">
        <div *ngFor="let order of (draft.orders || [])">
          <div class="order-number"><a [routerLink]="['/orders', order.freighter.id, order.id]">{{ order.id }}</a></div>
          <div class="order-date">{{ order.created_at | dt:'dd.MM.yy H:mm' }}</div>
        </div>
      </td>
      <td [routerLink]="['/drafts', draft.id]" class="client">
        <ng-template ngIf [ngIf]="!isFreighter && draft.freighter_client">
          <div class="company-client">{{ draft.client.freighter.name }}</div>
        </ng-template>
        <ng-template ngIf [ngIf]="draft.freighter_client">
          <div *ngIf="draft.freighter_client.name" class="company-client">{{ draft.freighter_client.name }}</div>
          <div class="name">{{ draft.freighter_client.contact_name }}</div>
          <div class="phone">{{ draft.freighter_client.contact_phone|formatPhone }}</div>
        </ng-template>
        <ng-template ngIf [ngIf]="!isFreighter && !draft.freighter_client">
          <div *ngIf="draft.client.company_client" class="company-client">{{ draft.client.company_client.name }}</div>
          <div class="name">{{ draft.client | fullName }}</div>
          <div class="phone">{{ draft.client.phone }}</div>
        </ng-template>
      </td>
      <td [routerLink]="['/drafts', draft.id]" class="tariff text-nowrap" *ngIf="userInfo.isDeliveryManager() || userInfo.isPrivilegedUser()">
        <div class="name" *ngIf="!draft.delivery">{{ draft?.extra_search_params?.tariff_tier.name }}</div>
        <div *ngIf="draft.delivery">
          <div class="name">{{ draft.delivery_schema?.name }}</div>
          <div class="company">{{ draft.delivery_company_client?.name }}</div>
        </div>
        <div class="cargo-storage" *ngIf="draft.cargo_storage"></div>
      </td>
      <td [routerLink]="['/drafts', draft.id]" class="destination">
        <div class="rollable">
          <div class="city" *ngIf="draft.city">
            <div class="city-name">{{ draft.city.name }}</div>
            <div class="city-time-zone">{{ draft.city|cityTz }}</div>
          </div>
          <div *ngFor="let destination of draft.destinations; let n = index;">
            <span class="pre" *ngIf="n == 0">от:</span>
            <span class="pre" *ngIf="n == 1">до:</span>
            {{ destination.destination.addr }}
          </div>
        </div>
      </td>
      <td [routerLink]="['/drafts', draft.id]" *ngIf="!userInfo.isDeliveryManager()" class="payment">
        <div class="cost">{{ draft.cost }} р.</div>
        <div class="pay-method">{{ draft.pay_method | payMethod }}</div>
      </td>
      <td [routerLink]="['/drafts', draft.id]" class="search-status">
        <span
          *ngIf="draft.taxi_search"
          [class.search-stopped]="draft.taxi_search && searchStatusGroups['stopped'].statuses.indexOf(draft.taxi_search.status) > -1"
          [class.search-new]="!draft.taxi_search"
          [class.search-executing]="draft.taxi_search && searchStatusGroups['search'].statuses.indexOf(draft.taxi_search.status) > -1"
          [class.search-completed]="draft.taxi_search && searchStatusGroups['completed'].statuses.indexOf(draft.taxi_search.status) > -1"
          [class.search-accepted]="draft.taxi_search && searchStatusGroups['accepted'].statuses.indexOf(draft.taxi_search.status) > -1"
          [class.search-not-found]="draft.taxi_search && searchStatusGroups['notFound'].statuses.indexOf(draft.taxi_search.status) > -1"
        >{{ draft.taxi_search.status | taxiSearchStatus }}</span>
        <div *ngIf="draft.auto_accept_taxi && draft.taxi_search && searchStatusGroups['search'].statuses.indexOf(draft.taxi_search.status) > -1">(автоподтверждение)</div>
      </td>
      <td [routerLink]="['/drafts', draft.id]" class="text-nowrap search-status" *ngIf="userInfo.isDeliveryManager() || userInfo.isPrivilegedUser()">
        <div
          class="delivery-status"
          [class.search-stopped]="draft.taxi_search && searchStatusGroups['stopped'].statuses.indexOf(draft.taxi_search.status) > -1"
          [class.search-new]="!draft.taxi_search"
          [class.search-executing]="draft.taxi_search && searchStatusGroups['search'].statuses.indexOf(draft.taxi_search.status) > -1"
          [class.search-completed]="draft.taxi_search && searchStatusGroups['completed'].statuses.indexOf(draft.taxi_search.status) > -1"
          [class.search-accepted]="draft.taxi_search && searchStatusGroups['accepted'].statuses.indexOf(draft.taxi_search.status) > -1"
          [class.search-not-found]="draft.taxi_search && searchStatusGroups['notFound'].statuses.indexOf(draft.taxi_search.status) > -1"
        >{{ draft.delivery_status | deliveryStatus }}</div>
        <div *ngIf="draft.delivery_time_slot_begin && draft.delivery_time_slot_end" class="delivery-slot {{ draft.delivery_time_slot_end|timeStatusClass }}">
          Слот доставки: {{ draft.delivery_time_slot_begin|dt:"dd.MM H:mm" }}-{{ draft.delivery_time_slot_end|dt:"H:mm" }}
        </div>
        <div class="track-numbers" *ngIf="draft.delivery_tracks && draft.delivery_tracks.length > 0">
          <div *ngFor="let track of draft.delivery_tracks">
            <div>{{ trackingServiceIdentifierToName(track.service) }}:</div>
            <div class="track-number-container">
              <span class="track-number" title="трек-номер Mover`а">{{ track.internal_track_number }}</span>
              <span *ngIf="track.external_track_number">
                        <span class="glyphicon glyphicon-link"></span>
                        <span class="track-number" title="трек-номер '{{ trackingServiceIdentifierToName(track.service) }}'">{{ track.external_track_number }}</span>
                      </span>
            </div>
          </div>
        </div>
      </td>
      <td class="controls">
        <button (click)="showCopy(rowIndex)" class="copy_form_btn" >Копировать</button>
        <div class="number_for_copy_draft" *ngIf="showCopyButton === true && rowIndex === rowIndexCount" >
            <!-- <a [routerLink]="['/drafts/new', draft.id]" target="_blank"  class="btn btn-default glyphicon glyphicon-copy" title="Копировать"></a> -->
            <div class="quantity">
                <input type="number" min="1" step="1" value="1" title="Количество копий" [(ngModel)]="countRepeater">
            </div>
            <button type="button" class="btn btn-default glyphicon glyphicon-copy" title="Копировать" (click)="onMultiCopy(draft)"></button>

            <button type="button" class="btn btn-warning glyphicon glyphicon-remove-circle" title="Отменить запланированный запуск" *ngIf="isScheduled(draft)" (click)="onCancelScheduledDraft(draft); $event.stopPropagation()"></button>
        </div>
      </td>
    </tr>
    <tr *ngIf="draft.notes || draft.comment || isScheduled(draft)">
      <td
        class="additional"
        [attr.colspan]="userInfo.isDeliveryManager() || userInfo.isPrivilegedUser() ? 9 : 7"
        [routerLink]="['/drafts', draft.id]"
        [class.search-stopped]="draft.taxi_search && searchStatusGroups['stopped'].statuses.indexOf(draft.taxi_search.status) > -1"
        [class.search-new]="!draft.taxi_search"
        [class.search-executing]="draft.taxi_search && searchStatusGroups['search'].statuses.indexOf(draft.taxi_search.status) > -1"
        [class.search-completed]="draft.taxi_search && searchStatusGroups['completed'].statuses.indexOf(draft.taxi_search.status) > -1"
        [class.search-accepted]="draft.taxi_search && searchStatusGroups['accepted'].statuses.indexOf(draft.taxi_search.status) > -1"
        [class.search-not-found]="draft.taxi_search && searchStatusGroups['notFound'].statuses.indexOf(draft.taxi_search.status) > -1"
      >
        <div class="comment-wrapper">
          <div *ngIf="isScheduled(draft)" class="schedule">
            Запланирован на: <br>
            <span class="date">
              {{ getSchedule(draft) | dt:'dd.MM.yy H:mm' }}
              <span class="city-time" *ngIf="!(draft.city|myTz)" title="местное время">({{ getSchedule(draft) | withCityTz:draft.city | dt:'H:mm' }})</span>
            </span>
          </div>
          <div class="comment">
            <div class="header" *ngIf="draft.comment">Комментарий:</div>
            <div class="text comment rollable" *ngIf="draft.comment">{{ draft.comment }}</div>
            <div class="notes rollable" *ngIf="draft.notes">
              <div class="header">Заметки:</div>
              <div class="text">{{ draft.notes }}</div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>

  </table>
  <div class="show-more-btn">
    <show-more [list]="drafts" (onPage)="onShowMorePage($event)" #showMoreOrders></show-more>
  </div>
</div>

