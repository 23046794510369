import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "timeStatusClass"})
export class TimeStatusClassPipe implements PipeTransform {
  transform(time: string): any {
    let diff = this.toMinutes(new Date()) - this.toMinutes(new Date(time));

    if(diff >= -60) // one hour
      return 'alert';

    if(diff >= -120) // two hours
      return 'warning';

    return 'ok';
  }

  private toMinutes(date: Date): number {
    return Math.ceil(date.getTime() / 1000 / 60);
  }
}
