
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {FreighterLoaderTariff} from "../_models/freighter-loader-tariff";
import {Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class FreighterLoaderTariffService {
  constructor(private _requestService: RequestWithErrorHandlerService) {
  }

  getTariff(id: number): Observable<FreighterLoaderTariff> {
    return this._requestService
      .get(`/freighter/me/loader_tariffs/${id}.json`).pipe(
      map(r => r.body.freighter_loader_tariff as FreighterLoaderTariff));
  }

  getTariffs(): Observable<FreighterLoaderTariff[]> {
    return this._requestService
      .get('/freighter/me/loader_tariffs.json').pipe(
      map(r => r.body.freighter_loader_tariffs as FreighterLoaderTariff[]));
  }

  addTariff(tariff: FreighterLoaderTariff): Observable<HttpResponse<any>> {
    return this._requestService.post('/freighter/me/loader_tariffs.json', this.prepareTariffForRequest(tariff));
  }

  updateTariff(tariff: FreighterLoaderTariff): Observable<HttpResponse<any>> {
    return this._requestService.put(`/freighter/me/loader_tariffs/${tariff.id}.json`, this.prepareTariffForRequest(tariff));
  }

  deleteTariff(tariff: FreighterLoaderTariff): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/freighter/me/loader_tariffs/${tariff.id}.json`);
  }

  private prepareTariffForRequest(tariff: FreighterLoaderTariff): Object {
    return {
      freighter_loader_tariff: {
        name: tariff.name,
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price,
        onTheWayHourPrice: tariff.on_the_way_hour_price,
        assemblerMinPrice: tariff.assembler_min_price,
        assemblerMinHours: tariff.assembler_min_hours,
        assemblerAdditionalHourPrice: tariff.assembler_additional_hour_price,
        liftingMinPrice: tariff.lifting_min_price,
        liftingMinHours: tariff.lifting_min_hours,
        liftingAdditionalHourPrice: tariff.lifting_additional_hour_price,
      }
    }
  }
}
