import {finalize, switchMap, throttleTime} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {OrderDraft} from "../_models/order-draft";
import {OrderDraftService} from "../_services/order-draft.service";
import {LoaderService} from "../_services/loader.service";
import {OrdersConnection} from "../_websocket/connections/orders-connection";
import {WebSocketService} from "../_services/web-socket.service";
import {Message} from "../_websocket/messages/message";
import {OrderChanged} from "../_websocket/messages/order-changed";
import {TaxiConnection} from "../_websocket/connections/taxi-connection";
import {TaxiMessage} from "../_websocket/messages/taxi-message";
import {TaxiSearchState} from "../_websocket/messages/taxi-search-state";
import {TitleService} from "../_services/title.service";
import {asyncScheduler, Subject} from "rxjs";
import {UserInfoService} from "../_services/user-info.service";

@Component({
  selector: 'app-edit-draft',
  templateUrl: './edit-draft.component.html',
  styleUrls: ['./edit-draft.component.css']
})
export class EditDraftComponent implements OnInit, OnDestroy {
  draft: OrderDraft;
  private ordersConnection: OrdersConnection;
  private taxiConnection: TaxiConnection;
  private locked: boolean = false;
  private requireUpdate = false;
  private draftStream: Subject<number>;
  private changed = false;
  private requiredSearchState = false;

  constructor(
    private draftsService: OrderDraftService,
    private webSocketService: WebSocketService,
    private route: ActivatedRoute,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private titleService: TitleService
  ) {
    this.initDraftStream();
  }

  ngOnInit() {
    this.initOrdersConnection();
    this.initTaxiConnection();
    this.route.params
      .subscribe(
        params => this.loadDraft(+params['id'], this.userInfoService.isPrivilegedUser()),
        e => console.error(e)
      );
  }

  private initDraftStream(): void {
    this.draftStream = new Subject<number>();
    this.draftStream.pipe(
      throttleTime(5000, asyncScheduler, {
        leading: true,
        trailing: true
      }),
      switchMap(id => {
        this.loaderService.show();
        return this.draftsService
        .getDraft(id).pipe(
          finalize(() => this.loaderService.hide()));
      })
    ).subscribe({
      next: draft => {
        this.draft = draft;
        this.requireUpdate = false;

        if(this.requiredSearchState) {
          this.requestSearchStateIf();
          this.requiredSearchState = false;
        }
      },
      error: () => this.initDraftStream()
    });
  }

  private initOrdersConnection() {
    this.ordersConnection = this.webSocketService.createOrdersConnection();
    this.ordersConnection.start();
    this.ordersConnection.message.subscribe(m => this.onOrderConnectionMessage(m));
  }

  private initTaxiConnection() {
    this.taxiConnection = this.webSocketService.createTaxiConnection();
    this.taxiConnection.start();
    this.taxiConnection.message.subscribe(m => this.onTaxiConnectionMessage(m as TaxiMessage))
  }

  private loadDraft(id, requiredSearchState = false) {
    this.titleService.changeTitle(`#${id} - Заявка`);

    if(requiredSearchState)
      this.requiredSearchState = true;

    this.draftStream.next(id);
  }

  private reloadDraft() {
    this.loadDraft(this.draft.id);
  }

  private requestSearchStateIf(): void {
    setTimeout(() => {
      if(this.draft.taxi_search && this.draft.taxi_search.status === 'search')
        this.requestSearchState();
    }, 3000);
  }

  private requestSearchState(): void {
    this.draftsService
      .requestSearchState(this.draft)
      .subscribe(
        () => {},
        () => {}
      )
    ;
  }

  private onOrderConnectionMessage(message: Message) {
    if (message instanceof OrderChanged && this.draft !== null && message.draftId === this.draft.id) {
      console.log('draft changed');

      if(this.locked)
        this.requireUpdate = true;
      else
        this.reloadDraft();
    }
  }

  private onTaxiConnectionMessage(message: TaxiMessage) {
    if(this.draft == null || message.getDraftId() !== this.draft.id)
      return;

    let remoteChange = !(message instanceof TaxiSearchState);
    if(!remoteChange && message instanceof TaxiSearchState && message.state.status != 'search')
      remoteChange = true;

    if(remoteChange) {
      console.log('draft changed');

      if(this.locked || this.changed)
        this.requireUpdate = true;
      else
        this.reloadDraft();
    }
  }

  onSaved() {
    this.changed = false;
    this.loadDraft(this.draft.id);
  }

  onLockDraft() {
    console.log('lock draft');

    this.locked = true;
  }

  onUnlockDraft() {
    console.log('unlock draft');

    this.locked = false;
    if(this.requireUpdate && !this.changed)
      this.reloadDraft();
  }

  onDraftChanged(): void {
    this.changed = true;
  }

  onDraftChangesCanceled(): void {
    this.changed = false;
    if(this.requireUpdate)
      this.reloadDraft();
  }

  ngOnDestroy(): void {
    this.ordersConnection.close();
    this.taxiConnection.close();
  }
}
