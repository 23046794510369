import {Pipe, PipeTransform} from "@angular/core";
import {OptimizationTaskTransportDestination} from "../_models/optimization-task-transport-destination";
import {DateTime} from "date-time-js";

@Pipe({name: "destinationWithArrivalTime"})
export class DestinationWithArrivalTimePipe implements PipeTransform {
  transform(destination: OptimizationTaskTransportDestination): any {
    let time = new DateTime(new Date(destination.arrival_time));

    return `[${time.format('HH:mm')}] ${destination.destination.destination.addr}`;
  }
}
