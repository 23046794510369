import { Component, OnInit } from '@angular/core';
import {OrderStatusPanelComponent} from "../order-status-panel/order-status-panel.component";

@Component({
  selector: 'order-controls',
  templateUrl: './order-controls.component.html',
  styleUrls: ['./order-controls.component.css']
})
export class OrderControlsComponent extends OrderStatusPanelComponent{

}
