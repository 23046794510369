import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DateTimeslot} from "./date-timeslot";
import {OptimizationTask} from "../_models/optimization-task";
import {TimeSlot} from "../_models/time-slot";

@Component({
  selector: 'date-timeslot',
  templateUrl: './date-timeslot.component.html',
  styleUrls: ['./date-timeslot.component.scss']
})
export class DateTimeslotComponent implements OnChanges {
  @Input() dateTimeslot = new DateTimeslot();
  @Input() taskToRestore: OptimizationTask;
  @Input() label: string;
  @Input() info: string;
  @Input() storagePrefix: string;
  @Input() fixedTimeSlots: TimeSlot[];
  @Input() isDateDisabled = false
  @Output() dateTimeslotChange = new EventEmitter<DateTimeslot>();

  desiredArrivalDatePickerOptions = {
    label: null
  };
  desiredArrivalTimeBeginPickerOptions = {
    label: 'от'
  };
  desiredArrivalTimeEndPickerOptions = {
    label: 'до'
  };

  slotNumber: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['taskToRestore']) {
      this.restoreForTask(this.taskToRestore || new OptimizationTask());
    }
    if(changes['dateTimeslot']) {
      this.applyChangedTimeSlots();
    }
    if(changes['fixedTimeSlots']) {
      if(this.applySelectedTimeSlot())
        this.onChangeTimeSlot();
    }
  }

  saveForTask(task: OptimizationTask): void {
    if(this.storagePrefix) {
      localStorage.setItem(this.buildStorageKey(task), this.dateTimeslot.serialize());
      localStorage.setItem(this.buildStorageKey(task) + '_slot_number', this.slotNumber.toString());
    }
  }

  restoreForTask(task: OptimizationTask): void {
    if(!this.storagePrefix)
      return;

    let dateTimeslot = localStorage.getItem(this.buildStorageKey(task));
    this.dateTimeslot = dateTimeslot ? DateTimeslot.deserialize(dateTimeslot) : new DateTimeslot();

    this.slotNumber = parseInt(localStorage.getItem(this.buildStorageKey(task)  + '_slot_number') || '0') || 0;
    this.applySelectedTimeSlot();

    this.onChangeTimeSlot();
  }

  removeSavedForTask(task: OptimizationTask): void {
    localStorage.removeItem(this.buildStorageKey(task));
    localStorage.removeItem(this.buildStorageKey(task)  + '_slot_number');
  }

  private buildStorageKey(task: OptimizationTask): string {
    return this.storagePrefix + (task.id || '');
  }

  private applyTimeSlot(timeSlot: TimeSlot): void {
    let startTime = timeSlot.start.split(':').map(t => parseInt(t));
    let endTime  = timeSlot.end.split(':').map(t => parseInt(t));

    let startDate = new Date();
    startDate.setHours(startTime[0], startTime[1],  0);
    let endDate = new Date();
    endDate.setHours(endTime[0], endTime[1],  0);

    this.dateTimeslot.timeBegin = startDate;
    this.dateTimeslot.timeEnd = endDate;
  }

  private applySelectedTimeSlot(): boolean {
    if(!this.fixedTimeSlots)
      return false;

    let realNumber = this.slotNumber < this.fixedTimeSlots.length ? this.slotNumber : 0;
    let timeSlot = this.fixedTimeSlots[realNumber];
    if(timeSlot) {
      this.applyTimeSlot(timeSlot);
      return true;
    }

    return false;
  }

  private applyChangedTimeSlots() {
    console.log('applyChanged', DateTimeslot.deserialize(this.dateTimeslot.serialize()));

    if(!this.fixedTimeSlots || !this.dateTimeslot.isValid())
      return;

    let currentSlotText = `${this.dateTimeslot.getFormattedTimeBegin()}-${this.dateTimeslot.getFormattedTimeEnd()}`;
    for(let i = 0; i < this.fixedTimeSlots.length; i ++) {
      let fixedSlot = this.fixedTimeSlots[i];
      let fixedTimeSlotText = `${fixedSlot.start}-${fixedSlot.end}`;
      if(fixedTimeSlotText === currentSlotText) {
        this.slotNumber = i;
        this.applySelectedTimeSlot();
        break;
      }
    }
  }

  onChangeTimeSlot(): void {
    this.dateTimeslotChange.emit(this.dateTimeslot);
  }

  onSelectTimeSlot(): void {
    this.applySelectedTimeSlot();
    this.onChangeTimeSlot();
  }
}
