export class RelativeTimeFilter {
  constructor(
    public value?: number,
    public unit?: string
  ) {}

  equals(filter: RelativeTimeFilter): boolean {
    return this.value === filter.value && this.unit === filter.unit;
  }

  isEmpty(): boolean {
    return this.value == null && this.unit == null
  }

  clone(): RelativeTimeFilter {
    return new RelativeTimeFilter(this.value, this.unit);
  }

  forState(): any {
    return {
      value: this.value,
      unit: this.unit
    }
  }

  static fromState(state?: any): RelativeTimeFilter {
    if(!state)
      state = {};

    return new RelativeTimeFilter(state['value'], state['unit']);
  }
}
