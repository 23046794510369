<div class="full-width editor-caption">
  Транспорт
</div>
<div *ngIf="transportTariffs.length > 0" class="full-width mar8top">
  <label>Тариф</label>
  <select class="full-width mar8top" [(ngModel)]="transportTariffId" (change)="onSelectTransportTariff()">
    <option value="">Выберите тариф</option>
    <option *ngFor="let tariff of transportTariffs" value="{{ tariff.id }}">#{{ tariff.id }} {{ tariff.name }}</option>
  </select>
</div>

<form class="form-inline mar16top">
  <table class="table tariff-table">
    <thead>
    <tr>
      <th>Минимум</th>
      <th>Доп. час</th>
      <th>За МКАД</th>
      <th>ТТК</th>
      <th>Гидролифт</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>
        <div class="double-input">
          <div class="input-box input-box-price">
            <input [(ngModel)]="transportTariff.min_price" name="min_price" size="5" class="left" placeholder="0">
          </div>
          <div class="input-box input-box-right">
            <input [(ngModel)]="transportTariff.min_hours" name="min_hours" size="3" class="right" placeholder="0">
          </div>
        </div>
      </td>
      <td>
        <div class="input-box input-box-price">
          <input [(ngModel)]="transportTariff.additional_hour_price" name="additional_hour_price" size="5"
                 placeholder="0">
        </div>
      </td>
      <td>
        <div class="input-box input-box-distance">
          <input [(ngModel)]="transportTariff.after_mkad_km_price" name="after_mkad_km_price" size="3" placeholder="0">
        </div>
      </td>
      <td>
        <div class="input-box input-box-price">
          <input [(ngModel)]="transportTariff.ttk_price" name="ttk_price" size="4" placeholder="0">
        </div>
      </td>
      <td>
        <div class="input-box input-box-price">
          <input [(ngModel)]="transportTariff.hydroelevator_hour_price" name="hydroelevator_hour_price" size="4"
                 placeholder="0">
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</form>
<hr class="tariff-separator">
<div class="full-width editor-caption">
  Грузчики
</div>
<div *ngIf="loaderTariffs.length > 0" class="full-width mar8top">
  <label>Тариф</label>
  <select class="full-width mar8top" [(ngModel)]="loaderTariffId" (change)="onSelectLoaderTariff()">
    <option value="">Выберите тариф</option>
    <option *ngFor="let tariff of loaderTariffs" value="{{ tariff.id }}">#{{ tariff.id }} {{ tariff.name }}</option>
  </select>
</div>

<form class="form-inline mar16top">
  <table class="table tariff-table" cols="5">
    <thead>
    <tr>
      <th>Минимум</th>
      <th>Доп. час</th>
      <th>Час в пути</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td width="30%">
        <div class="double-input">
          <div class="input-box input-box-price">
            <input class="left" [(ngModel)]="loaderTariff.min_price" name="min_price" size="6" placeholder="0">
          </div>
          <div class=" input-box input-box-right">
            <input class="right" [(ngModel)]="loaderTariff.min_hours" name="min_hours" size="1" placeholder="0">
          </div>
        </div>
      </td>
      <td width="25%">
        <div class="input-box input-box-price">
          <input [(ngModel)]="loaderTariff.additional_hour_price" name="additional_hour_price" size="5" placeholder="0">
        </div>
      </td>
      <td>
        <div class="input-box input-box-price">
          <input [(ngModel)]="loaderTariff.on_the_way_hour_price" name="on_the_way_hour_price" size="4" placeholder="0">
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</form>
<hr class="tariff-separator">
<div class="full-width editor-caption">
  Сборщики
</div>
<form class="form-inline mar16top">
  <table class="table tariff-table">
    <thead>
    <tr>
      <th>Минимум</th>
      <th>Доп. час</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td width="30%">
        <div class="double-input">
          <div class="input-box input-box-price">
            <input class="left" [(ngModel)]="assemblerTariff.min_price" name="min_price" size="6" placeholder="0">
          </div>
          <div class="input-box input-box-right">
            <input class="right" [(ngModel)]="assemblerTariff.min_hours" name="min_hours" size="1" placeholder="0">
          </div>
        </div>
      </td>
      <td>
        <div class="input-box input-box-price">
          <input [(ngModel)]="assemblerTariff.additional_hour_price" name="additional_hour_price" size="5" placeholder="0">
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</form>
<hr class="tariff-separator">
<form class="form-inline mar16top">
  <div class="full-width editor-caption">
    Спуск / Подъём
  </div>
  <table class="table tariff-table">
    <thead>
    <tr>
      <th>Минимум</th>
      <th>Доп. час</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td width="30%">
        <div class="double-input">
          <div class="input-box input-box-price">
            <input class="left" [(ngModel)]="liftingTariff.min_price" name="min_price" size="6" placeholder="0">
          </div>
          <div class="input-box input-box-right">
            <input class="right" [(ngModel)]="liftingTariff.min_hours" name="min_hours" size="1" placeholder="0">
          </div>
        </div>
      </td>
      <td>
        <div class="input-box input-box-price">
          <input [(ngModel)]="liftingTariff.additional_hour_price" name="additional_hour_price" size="5" placeholder="0">
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</form>
<hr>
<button class="save-btn" type="button" (click)="onSave()">Сохранить</button>
