import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CrossAuthService} from "../_services/cross-auth.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {HttpErrorResponse} from "@angular/common/http";
import {UserInfoService} from "../_services/user-info.service";

@Component({
  selector: 'app-cross-auth',
  templateUrl: './cross-auth.component.html',
  styleUrls: ['./cross-auth.component.css']
})
export class CrossAuthComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private crossAuthService: CrossAuthService,
              private userInfoService: UserInfoService,
              private loaderService: LoaderService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        params => {
          let userId = parseInt(params['userId']);
          let token = params['token'];
          this.crossLogin(userId, token);
        },
        e => console.error(e)
      );
  }

  private crossLogin(userId: number, token: string): void {
    this.loaderService.show();
    this.loaderService.showText('Вход в панель управления...');

    this.crossAuthService
      .crossLogin(userId, token)
      .subscribe(
        () => {
          this.userInfoService.setup().then(() => {
            this.loaderService.hide();
            this.router.navigate(['/'])
          });
        },
        r => {
          if(r instanceof HttpErrorResponse) {
            switch(r.status) {
              case 404:
                this.alertService.error('Пользователя не существует. Выполните повторный вход в систему.', true);
                break;
              case 403:
                this.alertService.error('Токен авторизации просрочен. Выполните повторный вход в систему.', true);
                break;
              default:
                this.alertService.error('Возникла непредвиденная ошибка. Выполните повторный вход в систему.', true);
                break;
            }
            this.loaderService.hide();
            this.router.navigate(['/login']);
          }
        }
      )
    ;
  }
}
