import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Destination} from "../_models/destination";
import {Issue} from "../_models/issue";
import {IssueService} from "../_services/issue.service";
import {DeliveryProductLine} from "../_models/delivery-product-line";
import {Order} from "../_models/order";
import {AddServiceIssueFormComponent} from "../add-service-issue-form/add-service-issue-form.component";
import {CompanyClientService} from "../_models/company-client-service";
import {CompanyClientProduct} from "../_models/company-client-product";
import {AddProductIssueFormComponent} from "../add-product-issue-form/add-product-issue-form.component";
import {CancelReason} from "../_models/cancel-reason";
import {CancelReasonService} from "../_services/cancel-reason.service";
import {Storehouse} from "../_models/storehouse";
import {StorehouseService} from "../_services/storehouse.service";
import {UserInfoService} from "../_services/user-info.service";

const SERVICE_CODES = ['100200', '100201'];

@Component({
  selector: 'issue-form',
  templateUrl: './issue-form.component.html',
  styleUrls: ['./issue-form.component.css']
})
export class IssueFormComponent implements OnInit, OnChanges {
  @Input() destination: Destination;
  @Input() order: Order;
  @ViewChild(AddServiceIssueFormComponent) addServiceIssueForm: AddServiceIssueFormComponent;
  @ViewChild(AddProductIssueFormComponent) addProductIssueForm: AddProductIssueFormComponent;

  issues: Issue[] = [];
  cancelReasons: CancelReason[] = [];
  storehouses: Storehouse[] = [];

  issueIdentifier: string;
  issueDescription: string;
  issueGroup: string;
  storehouseId: string;
  productCode: string;
  productCount: number;
  shopSum: string = '';
  clientSum: string = '';
  freighterSum: string = '';
  moverSum: string = '';
  slotStart: number;
  slotEnd: number;
  cancelReason: string;
  returnTomorrow = false;
  cargoStorageReason = 'driver_wants';

  constructor(private issuesService: IssueService,
              private cancelReasonService: CancelReasonService,
              private userInfoService: UserInfoService,
              private storehouseService: StorehouseService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadIssues();
    this.loadStorehouses();
    this.productCode = null;
  }

  private loadCancelReasons(): void {
    this.cancelReasons = [];

    let issue = this.getSelectedIssue();
    if(!issue)
      return;

    this.cancelReasonService
      .getIssueCancelReasons(this.destination, issue)
      .subscribe(
        reasons => this.cancelReasons = reasons,
        () => {}
      )
    ;
  }

  private loadCancelReasonsIf(): void {
    if(this.issueGroup === 'cancel_delivery' || this.issueGroup === 'reject_delivery')
      this.loadCancelReasons();
  }

  private loadIssues() {
    this.issues = [];

    if(!this.destination)
      return;

    this.issuesService
      .getAvailableIssuesForDestination(this.destination)
      .subscribe(
        issues => this.issues = issues,
        () => {}
      )
    ;
  }

  private loadStorehouses(): void {
    this.storehouses = [];
    if(!this.order)
      return;

    if(this.userInfoService.isDeliveryManager()) {
      this.storehouseService
        .getAvailableStorehouses()
        .subscribe(
          storehouses => this.storehouses = storehouses,
          () => {}
        )
      ;
    } else {
      this.storehouseService
        .getAvailableStorehousesByOrder(this.order)
        .subscribe(
          storehouses => this.storehouses = storehouses,
          () => {}
        )
      ;
    }
  }

  private isValidRejectProductLine(): boolean {
    if(this.issueIdentifier !== 'reject_product_line' && this.issueIdentifier !== 'reject_service_line')
      return true;

    return this.productCode != null && this.productCount > 0 && this.productCount <= this.getProductLine().count;
  }

  private isValidSlots(): boolean {
    if(this.issueIdentifier !== 'change_slot')
      return true;

    if(this.slotStart == null || this.slotEnd == null)
      return false;

    let validHour = hour => hour >=0 && hour <= 23;

    return validHour(this.slotStart) && validHour(this.slotEnd) && (this.slotStart < this.slotEnd || this.slotEnd == 0);
  }

  private isValidStorehouse(): boolean {
    return !!((this.issueIdentifier !== 'common.cancel_delivery' && this.issueIdentifier !== 'common.reject_delivery') || this.storehouseId);
  }

  private isValidAddServiceData(): boolean {
    return this.issueIdentifier != 'add_service' || this.addServiceIssueForm.isValid();
  }

  private isValidAddProductData(): boolean {
    return (this.issueIdentifier != 'add_product' && this.issueIdentifier != 'replace_product') || this.addProductIssueForm.isValid();
  }

  private getProductLine(): DeliveryProductLine|null {
    for(let line of this.destination.delivery_product.lines) {
      if(line.code === this.productCode)
        return line;
    }
    return null;
  }

  getMetadata(): any|null {
    if(['reject_service_line', 'reject_product_line', 'remove_product_line', 'replace_product'].indexOf(this.issueIdentifier) >= 0) {
      let metadata = {
        code: this.productCode,
        count: this.productCount
      }
      if(this.issueIdentifier === 'remove_product_line') {
        metadata['delivery_cost_for_freighter'] = this.filterSum(this.freighterSum);
      } else if(this.issueIdentifier === 'replace_product') {
        let newProductMetadata = this.addProductIssueForm.getMetadata();
        metadata['new_code'] = newProductMetadata['code'];
        metadata['new_name'] = newProductMetadata['name'];
        metadata['new_count'] = newProductMetadata['count'];
        metadata['delivery_cost_for_client'] = newProductMetadata['delivery_cost_for_client'];
        metadata['delivery_cost_for_freighter'] = newProductMetadata['delivery_cost_for_freighter'];
      }

      return metadata;
    } else if(this.issueIdentifier === 'change_delivery_cost') {
      return {
        delivery_cost_for_client: this.filterSum(this.clientSum),
        delivery_cost_for_freighter: this.filterSum(this.freighterSum)
      };
    } else if(this.issueIdentifier === 'other') {
      return {
        delivery_cost_for_shop: this.filterSum(this.shopSum),
        delivery_cost_for_freighter: this.filterSum(this.freighterSum)
      };
    } else if(this.issueIdentifier === 'change_slot') {
      return {
        slot_start: this.slotStart,
        slot_end: this.slotEnd
      }
    } else if(this.issueIdentifier === 'add_service') {
      return this.addServiceIssueForm.getMetadata();
    } else if(this.issueIdentifier === 'add_product') {
      return this.addProductIssueForm.getMetadata();
    } else if(this.issueIdentifier === 'common.cancel_delivery' || this.issueIdentifier === 'common.reject_delivery') {
      return {
        reason: this.cancelReason,
        storehouse: this.storehouseId
      }
    } else if(this.issueGroup === 'cancel_delivery') {
      return {
        reason: this.cancelReason
      }
    } else if(this.issueIdentifier === 'adjustment_order_cost') {
      return {
        freighter: this.filterSum(this.freighterSum),
        client: this.filterSum(this.clientSum),
        mover: this.filterSum(this.moverSum),
      };
    } else if(this.issueIdentifier === 'return_products') {
      return {
        returnTomorrow: this.returnTomorrow,
        reason: this.cargoStorageReason,
      };
    }

    return null;
  }

  private filterSum(sum: string): number {
    sum = sum.trim().replace(' ', '').replace(',', '.');
    return parseFloat(sum) || 0;
  }

  private getSelectedIssue(): Issue|null {
    if(!this.issueIdentifier)
      return null;

    const issueIndex = this.issues.findIndex(i => i.identifier === this.issueIdentifier);
    return issueIndex == -1 ? null : this.issues[issueIndex];
  }

  isDataValid(): boolean {
    return this.isValidAddServiceData() && this.isValidAddProductData();
  }

  isValid(): boolean {
    return this.issueIdentifier != null && this.isValidRejectProductLine() && this.isValidSlots() && this.isValidStorehouse();
  }

  isProduct(line: DeliveryProductLine): boolean {
    return SERVICE_CODES.findIndex(code => line.code.toString() === code.toString()) == -1;
  }

  onSelectProduct() {
    let line = this.getProductLine();
    if(line != null)
      this.productCount = line.count;
  }

  onApplyCompanyService(service: CompanyClientService) {
    this.issueDescription = service.name;
  }

  onApplyCompanyProduct(product: CompanyClientProduct) {
    this.issueDescription = product.name;
  }

  onChangeIssue(): void {
    for(const issue of this.issues) {
      if(issue.identifier === this.issueIdentifier) {
        this.issueGroup = issue.group;
        this.loadCancelReasonsIf();
      }
    }
  }
}
