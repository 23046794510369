<div class="tier {{ tariff.tier.identifier }}" *ngIf="tariff.tier">
  <div class="tier-name">{{ tariff.tier.name }}</div>
  <div class="features" *ngIf="tariff.tariff">
    <div class="feature" *ngFor="let f of tariff.tariff.readable_features|mapToIterable">
      {{ f.key }}: {{ f.val }}
    </div>
  </div>
</div>

<div class="tier unknown" *ngIf="!tariff.tier">не определён</div>
