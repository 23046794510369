import {Pipe, PipeTransform} from "@angular/core";
import {Transport} from "../_models/transport";
import {TRANSPORT_BODIES} from "../_maps/transport-bodies";

@Pipe({name: "transportBody"})
export class TransportBodyPipe implements PipeTransform {
  transform(transport: Transport): string {
    if(transport.is_virtual)
      return 'данные не доступны';

    return TRANSPORT_BODIES[transport.features.Tip_kuzova] || transport.features.Tip_kuzova || '-';
  }
}
