import {Subject} from "rxjs";
import {Message} from "../messages/message";
import {CarFeed} from "../messages/car-feed";
import {FeedTransport} from "../../_models/feed-transport";
import {UpFeed} from "../messages/up-feed";
import {ScreenConnection} from "./screen-connection";

export class OrderScreenConnection extends ScreenConnection {

  constructor(url: string, private orderId: number) {
    super(url);
  }

  protected init() {
    console.log('Init order screen connection...');

    this.sendMessage(JSON.stringify({
      "action": "order_screen",
      "order_id": this.orderId,
      "compact":true
    }));
  }

}
