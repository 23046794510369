export class DeliverySchedule {
  type: string;
  start: string;
  end: string;
  storehouse_time: string;
  search_hours_before: number;
  mode: string;
  period: number;
  week_days: number;
  day: number;
  week_period: number;
  week_day: number;
}
