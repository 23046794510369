import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'inn-dialog',
  templateUrl: './inn-dialog.component.html',
  styleUrls: ['./inn-dialog.component.css']
})
export class InnDialogComponent implements OnInit, OnDestroy {
  @Input() inn: string;
  @Input() enableUnlink = false;
  @Output() innChange = new EventEmitter<string>();

  @ViewChild('innDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild('innField', { static: true }) innFieldEl: ElementRef;

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  showDialog() {
    $(this.dialogEl.nativeElement).modal('show');
    setTimeout(() => {
      this.innFieldEl.nativeElement.focus();
    }, 500);

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  onSelect(): void {
    this.hideDialog();
    this.innChange.emit(this.inn);
  }

  onUnlink(): void {
    if(confirm('Отсоединить юр. лицо от водителя и сделать его физ. лицом?')) {
      this.hideDialog();
      this.inn = '';
      this.innChange.emit(this.inn);
    }
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
