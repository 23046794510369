import {OrderDraft} from "../../_models/order-draft";
import {ScreenTransportParams} from "../../_models/screen-transport-params";
import {DraftUtils} from "../draft-utils";
import {ExtraSearchParams} from "../../_models/extra-search-params";

export class ScreenUtils {
  static buildTransportParams(draft: OrderDraft): ScreenTransportParams {
    let transportParams = new ScreenTransportParams();

    let extraSearchParams = DraftUtils.getExtraSearchParams(draft);

    if(extraSearchParams.tariff_tier)
      transportParams.tier = extraSearchParams.tariff_tier.identifier;

    transportParams.bodies = DraftUtils.getBodyTypes(draft);
    transportParams.lengths = extraSearchParams.length_groups;
    transportParams.loaders = draft.loaders || 0;
    transportParams.hydroElevator = DraftUtils.getHydroElevator(draft) || null;

    ScreenUtils.buildRanges(extraSearchParams, transportParams);

    return transportParams;
  }

  private static buildRanges(extraSearchParams: ExtraSearchParams, transportParams: ScreenTransportParams): void {
    const rangesMap = {
      capacity: 'min_capacity',
      length: 'min_length',
      width: 'min_width',
      height: 'min_height'
    };

    let ranges: any = {}
    for(let transportParam in rangesMap) {
      if(!rangesMap.hasOwnProperty(transportParam))
        continue;

      let value = extraSearchParams[rangesMap[transportParam]];
      if(value)
        ranges[transportParam] = `[${value},]`;
    }

    transportParams.ranges = ranges;
  }
}
