export class OrdersReport {
  total: number = 0;
  bonus_sum: number = 0;
  bank_pay_sum: number = 0;
  cash_pay_sum: number = 0;
  card_pay_sum: number = 0;
  mover_fee_sum: number = 0;
  discount: number = 0;
  promo_discount: number = 0;
  total_count: number = 0;
  bonus_count: number = 0;
  bank_pay_count: number = 0;
  cash_pay_count: number = 0;
  card_pay_count: number = 0;
  discount_count: number = 0;
  promo_discount_count: number = 0;
}
