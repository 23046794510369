<div class="modal fade" tabindex="-1" role="dialog" #innDialog>
  <div class="modal-dialog" role="document">
    <form (submit)="onSelect()">
      <div class="modal-content">
        <div class="modal-header">
          <div class="title">
            <h4 class="modal-title">ИНН</h4>
          </div>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input #innField type="text" class="form-control" placeholder="ИНН" name="inn" [(ngModel)]="inn">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Отмена</button>
          <button type="button" class="btn btn-danger" (click)="onUnlink()" *ngIf="enableUnlink && inn && inn != ''">Отсоединить юр. лицо</button>
          <button type="submit" class="btn btn-primary" [disabled]="!inn || inn == ''">Выбрать</button>
        </div>
      </div>
    </form>
  </div>
</div>
