<div class="delivery-form">
  <div class="header">
    <div class="column">Адреса</div>
    <div class="column">Отгрузки</div>
    <div class="column">Клиент</div>
  </div>
  <div class="separator"></div>
  <div class="body">
    <div *ngFor="let destination of destinationsList.destinations; let i = index;">
      <ng-container *ngIf="!destination.stock && (!destinationId || destination.id === destinationId)">
        <complex-delivery-form-row
          [destination]="destination"
          [saveEvent]="saveEvent"
          (onDelete)="onRemoveRow(destinationsList.getOriginalNumber(i))"
          (onUpdate)="onUpdateRow($event)"
          [editable]="editable"
          [geoContext]="geoContext"
          [slotEnabled]="isSlotsEnabled"
          [assemblyEnabled]="isAssemblyEnabled"
          [contactsRequired]="optimizationTask.delivery_schema?.is_contacts_required || false"
        ></complex-delivery-form-row>
        <div class="separator"></div>
      </ng-container>
    </div>
    <div class="controls" *ngIf="destinationsHasPages">
      <pager [list]="destinationsList" (onPage)="onChangePage($event)"></pager>
    </div>
    <div class="separator" *ngIf="destinationsHasPages"></div>
    <div class="summary">
      <div></div>
      <div class="controls" *ngIf="!destinationId && editable">
        <button type="button" class="add-btn" (click)="onAddDestination()" *ngIf="optimizationTask.status != 'executed'">Добавить адрес</button>
      </div>
    </div>
  </div>
</div>
