<!--<div class="controls" *ngIf="voximplantService.callIsActive">-->
<!--  <span class="phone">{{ voximplantService.callNumber }}</span>-->
<!--  <button class="btn btn-danger btn-xs glyphicon glyphicon-headphones" (click)="voximplantService.hangup()"></button>-->
<!--  <button class="btn btn-default btn-xs glyphicon glyphicon-volume-up" *ngIf="!voximplantService.muted" (click)="voximplantService.mute()"></button>-->
<!--  <button class="btn btn-default btn-xs glyphicon glyphicon-volume-off" *ngIf="voximplantService.muted" (click)="voximplantService.unmute()"></button>-->
<!--</div>-->

<div class="current-call" [class.no-call]="!callIsActive">

  <div class="client-ava">
      <div class="img" *ngIf="!accountPicture"></div>
      <div class="img" *ngIf="accountPicture" [style.background-image]="accountPicture"></div>
  </div>

  <div class="client-center">
    <div class="client-name">
      <div class="is-driver" *ngIf="userData.is_driver">водитель</div>
      <span *ngIf="!unknownUser && !loading">
        <span *ngIf="userData.account">
          {{ userData.account|fullName }}
          <span *ngIf="userData.account.company_client">[{{ userData.account.company_client.name }}]</span>
        </span>
        <span *ngIf="userData.destination_contact && userData.destination_contact.name">{{ userData.destination_contact.name }}</span>
      </span>
      <span *ngIf="unknownUser">Неизвестно</span>
      <span *ngIf="loading">Загружается...</span>
    </div>
    <div class="client-phone">+{{ voximplantService.callNumber }}</div>
  </div>

  <div class="btn btn-hangup" (click)="voximplantService.hangup()" *ngIf="['CONNECTED', 'PROGRESSING'].indexOf(voximplantService.callState) != -1"></div>
  <div class="btn btn-mute" (click)="voximplantService.mute()" *ngIf="['CONNECTED', 'PROGRESSING'].indexOf(voximplantService.callState) != -1 && !voximplantService.muted" title="Выключить микрофон"><span class="glyphicon glyphicon-volume-up"></span></div>
  <div class="btn btn-unmute" (click)="voximplantService.unmute()" *ngIf="['CONNECTED', 'PROGRESSING'].indexOf(voximplantService.callState) != -1 && voximplantService.muted" title="Включить микрофон"><span class="glyphicon glyphicon-volume-off"></span></div>
  <div class="btn btn-reject" (click)="voximplantService.decline()" *ngIf="voximplantService.callState === 'ALERTING'"></div>
  <div class="btn btn-answer" (click)="voximplantService.answer()" *ngIf="voximplantService.callState === 'ALERTING'"></div>
  <div class="last-info">
    <div class="block" *ngIf="userData.last_drafts && userData.last_drafts.length > 0">
      <div class="header">Последние заявки</div>
      <div class="body">
        <div class="item" *ngFor="let draft of userData.last_drafts">
          <a class="order-link" [routerLink]="['/drafts', draft.id]">№{{ draft.id }} от {{ draft.created_at | dt:'dd.MM.yyyy' }}</a>
        </div>
      </div>
    </div>
    <div class="block" *ngIf="userData.last_orders && userData.last_orders.length > 0">
      <div class="header">Последние заказы</div>
      <div class="body">
        <div class="item" *ngFor="let order of userData.last_orders">
          <a class="order-link" [routerLink]="['/orders', order.freighter.id, order.id]">№{{ order.draft.id }} <span class="order-num">({{ order.id }})</span> от {{ order.created_at | dt:'dd.MM.yyyy' }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
