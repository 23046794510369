<google-map [zoom]="zoom" [center]="mapCenter" [options]="mapOptions" *ngIf="mapService.isInit" [class.is-visible]="isVisible"
            width="100%" height="100%" (boundsChanged)="onBoundsChange()" (mapClick)="onClickMap($event)">
    <ng-template [ngIf]="order">
        <map-marker *ngFor="let marker of orderDestinations"
                    [position]="marker.position"
                    [label]="marker.label"
                    [title]="marker.title"></map-marker>
        <ng-template ngFor [ngForOf]="orderRoute" let-odd="odd" let-i="index">
            <map-polyline *ngIf="i < orderRoute.length - 1"
                          [options]="{ strokeColor: odd ? '#0f0' : '#000' }"
                          [path]="[{lat: orderRoute[i].lat, lng: orderRoute[i].lng}, {lat: orderRoute[i + 1].lat, lng: orderRoute[i + 1].lng}]"
            ></map-polyline>
        </ng-template>
        <map-polyline
                *ngIf="!showTrack"
                [options]="{ strokeColor: '#00f', strokeOpacity: 0.1, strokeWeight: 15 }"
                [path]="orderRoute"
                (polylineClick)="onClickOrderRoute($event)"
        >
        </map-polyline>
        <map-info-window #orderRouteActivePointWindow (closeclick)="isRoutePointInfoWindowActive = false">
            <div *ngIf="orderRouteActivePoint && orderRouteActivePoint.t">
                {{ orderRouteActivePoint.t|timestampToDate|dt:'HH:mm' }}
            </div>
        </map-info-window>
        <map-circle
                *ngIf="isRoutePointInfoWindowActive"
                [center]="{ lat: orderRouteActivePoint.lat, lng: orderRouteActivePoint.lng }"
                [options]="{ fillColor: '#fc921f', strokeColor: '#fc921f', fillOpacity: 0.75, zIndex: 5 }"
                [radius]="50"
        ></map-circle>
        <map-info-window #routeMilestoneWindow
                         *ngFor="let milestone of orderRouteMilestones"
                         [options]="{ disableAutoPan: true }"
                         [position]="{ lat: milestone.lat, lng: milestone.lng }"
        >
            <div>
                {{ milestone.t|timestampToDate|dt:'HH:mm' }}
            </div>
        </map-info-window>
    </ng-template>
    <ng-template ngFor let-i="index" let-car [ngForOf]="cars" [ngForTrackBy]="trackForCars">
        <ng-template [ngIf]="showTrack">
            <ng-template ngFor [ngForOf]="car.path.points" let-odd="odd" let-i="index">
                <map-polyline *ngIf="i < car.path.points.length - 1"
                              [options]="{ strokeColor: '#f00', zIndex: 5, strokeWeight: odd ? 5 : 10, strokeOpacity: 0.5 }"
                              [path]="[{lat: car.path.points[i].lat, lng: car.path.points[i].lon}, {lat: car.path.points[i + 1].lat, lng: car.path.points[i + 1].lon}]"
                ></map-polyline>
            </ng-template>
            <map-polyline
                    [options]="{ zIndex: 12, strokeColor: '#00f', strokeOpacity: 0.1, strokeWeight: 15 }"
                    [path]="car.path.mapPoints"
                    (polylineClick)="onClickTrack(car, $event)"
            >
            </map-polyline>
            <map-info-window
                #trackMilestoneWindow
                *ngFor="let milestone of car.path.milestones"
                [options]="{ disableAutoPan: true }"
                [position]="{ lat: milestone.lat, lng: milestone.lng }">
                <div>
                    {{ milestone.t|timestampToDate|dt:'HH:mm' }}
                    <!--          ({{ milestone.lat }}, {{ milestone.lng }})-->
                </div>
            </map-info-window>
            <map-info-window #trackActivePointWindow (closeclick)="isTrackActivePointInfoWindowActive = false">
                <div *ngIf="trackActivePoint">
                    {{ trackActivePoint.t|timestampToDate|dt:'HH:mm' }}
                    <!--          ({{ trackActivePoint.lat }}, {{ trackActivePoint.lng }})-->
                </div>
            </map-info-window>
            <map-circle
                    *ngIf="isTrackActivePointInfoWindowActive"
                    [options]="{ fillColor: '#fc921f', fillOpacity: 0.75, strokeColor: '#fc921f' }"
                    [center]="{ lat: trackActivePoint.lat, lng: trackActivePoint.lng }"
                    [radius]="50"
            ></map-circle>
        </ng-template>
        <map-info-window #carWindow data-car-num="{{i}}" *ngIf="car.selectable">
            <div *ngIf="!car.isDataLoaded">загружается...</div>
            <div *ngIf="car.isDataLoaded" class="car-info">
                <div>Водитель: {{ car.driverTitle }}</div>
                <div *ngIf="car.feedTransport.employer.account.phone">
                    Телефон:
                    <a *ngIf="isPrivilegedUser" class="phone"
                       (click)="voximplantService.call(car.feedTransport.employer.account.phone)">{{ car.feedTransport.employer.account.phone|formatPhone }}</a>
                    <span class="phone"
                          *ngIf="!isPrivilegedUser">{{ car.feedTransport.employer.account.phone|formatPhone }}</span>
                </div>
                <div>Транспорт: {{ car.transportTitle }}</div>
                <div class="order" *ngFor="let order of car.feedTransport.acceptedOrders">
                    <div class="order-title">
                        <div>
                            Выполняет заказ №<a
                                [routerLink]="['/orders', order.freighter.id, order.id]">{{ order.id }}</a>
                        </div>
                        <div class="execution-indicator">
                            <div *ngFor="let state of car.executionIndicator.get(order.id)"
                                 [class.arrived]="state"></div>
                        </div>
                    </div>
                    <div>Статус: {{ order.periods[0].execution_status|executionStatus }}</div>
                </div>
                <div>
                    <button class="btn btn-xs btn-default" type="button" (click)="onSelectCar(car)">выбрать</button>
                </div>
            </div>
        </map-info-window>
        <map-marker #carMarker
                    [position]="{ lat: car.feedTransport.point.lat | parseFloat, lng: car.feedTransport.point.lon | parseFloat }"
                    [icon]="car.marker"
                    [title]="car.title"
                    [label]="car.markerLabel"
                    [options]="{ opacity: (car.ignoreDriverStatus || car.feedTransport.employer.status == 'free') ? 1 : 0.70 }"
                    (mapClick)="onCarClick(car, i)"
        >
        </map-marker>
    </ng-template>
    <ng-template [ngIf]="draft">
        <map-marker [position]="destination.position" [label]="destination.label" [title]="destination.title"
                    *ngFor="let destination of draftDestinations"></map-marker>
        <map-polyline [options]="{ strokeColor: '#000' }" [path]="draftPoints"></map-polyline>
    </ng-template>

    <ng-template [ngIf]="searchState">
        <map-circle
                [center]="searchCenter"
                [radius]="searchState.radius * 1000"
                [options]="{ strokeColor: '#00000000', fillColor: '#700', strokeOpacity: 0.25 }"
        ></map-circle>
        <ng-template ngFor let-crew [ngForOf]="foundCrews" [ngForTrackBy]="trackForFoundCrew">
            <map-marker [position]="{ lat: crew.point.lat | parseFloat, lng: crew.point.lon | parseFloat }  "
                        [icon]="crew.icon"
                        [title]="crew.driver.name + (crew.order.status == 'not_agree' ? ' [отклонил]' : '')"
            ></map-marker>
        </ng-template>
    </ng-template>
    <map-marker *ngFor="let m of displayedMarkers"
                (mapClick)="onClickDestination(m.id)"
                [icon]="m.marker.icon"
                [position]="{ lat: m.marker.lat, lng: m.marker.lon }"
                [title]="m.marker.title"
                [label]="m.marker.label"
    ></map-marker>

    <map-circle *ngFor="let a of displayedClusterAreas"
                [options]="{ fillColor: '#fc921f', strokeColor: '#fc921f' }"
                [center]="{ lat: a.lat, lng: a.lon }"
                [radius]="a.radius"
    ></map-circle>

      <ng-template ngFor let-route [ngForOf]="routes">
        <map-polyline [options]="{ strokeWeight: 2, strokeColor: route.color }" *ngIf="route.visible" [path]="route.points"></map-polyline>
      </ng-template>
</google-map>