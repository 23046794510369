<form (submit)="onSave()">
<div class="block">
<div class="b-header">Начало перевозки</div>
    <div><order-panel-datepicker class="date-time" [(value)]="startDate" [datepicker]="datePickerOptions" [timepicker]="timePickerOptions" name="startDate" ngDefaultControl></order-panel-datepicker></div>
</div>
<div class="block">
<div class="b-header">Окончание перевозки</div>
    <div><order-panel-datepicker class="date-time" [(value)]="endDate" [datepicker]="datePickerOptions" [timepicker]="timePickerOptions" name="endDate" ngDefaultControl></order-panel-datepicker></div>
</div>
<div *ngIf="error != ''" class="text-warning">{{ error }}</div>
<div class="block"><button class="save-btn" type="submit">Сохранить</button></div>
</form>
