<div class="ng-autocomplete address">
  <ng-autocomplete
    [data]="addresses"
    searchKeyword="formated_address"
    (selected)="onSelectAddress($event)"
    [customFilter]="customFilter"
    [itemTemplate]="addressTemplate"
    [notFoundTemplate]="notFoundTemplate"
    [isLoading]="isSearching"
    [debounceTime]="600"
    [minQueryLength]="minAddrLengthToSearch"
    [class.is-accuracy]="isAccuracyAddress"
    [class.invalid]="!isValid"
    notFoundText="Адрес не найден"
    [placeholder]="placeholder"
    name="addressSearch"
    [(ngModel)]="selected"
    (ngModelChange)="onChangeAddressSearch($event)"
    (inputFocused)="onFocusField()"
    (blur)="onBlurField()"
    (keyup)="onKeyUpField($event)"
    (inputCleared)="onClearAddress()"
  >
  </ng-autocomplete>

  <ng-template #addressTemplate let-address>
    <img [src]="address.icon" height="10" *ngIf="address.icon">
    <a [innerHTML]="address.formated_address"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound" *ngIf="!isSearching"></div>
  </ng-template>
</div>
