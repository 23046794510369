import {Pipe, PipeTransform} from "@angular/core";
import {LOADER_SERVICES} from "../_maps/loader-services";

@Pipe({name: "loaderService"})
export class LoaderServicePipe implements PipeTransform {
  transform(service: string): string {
    return LOADER_SERVICES[service] || 'неизвестно';
  }

}
