import {Component, Input} from "@angular/core";

@Component({
  selector: '[by-zones-calculation]',
  templateUrl: './by-zones.calculation.component.html',
  styleUrls: ['./calculation.component.css']
})
export class ByZonesCalculationComponent {
  @Input() calculation: any;
}
