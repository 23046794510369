import {CompanyClientLegalEntity} from "./company-client-legal-entity";
import {Freighter} from "./freighter";

export class LegalEntity {
  id: number;
  name: string;
  short_name: string;
  type: string;
  inn: string;
  kpp: string;
  ogrn: string;
  oktmo: string;
  okpo: string;
  bik: string;
  bank: string;
  bank_inn: string;
  correspondent_account: string;
  checking_account: string;
  actual_address: string;
  legal_address: string;
  signatory_authority: string;
  director: string;
  email: string;
  company_client_legal_entity: CompanyClientLegalEntity;
  freighter: Freighter;
}
