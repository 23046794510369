<div class="col-xs-5 col-md-5 col-centered col-slider no-padding row">
  <div class="list-controls">
    <pager [list]="clients" (onPage)="onPaginationPage($event)"></pager>
    <div class="control" [routerLink]="['/freighter-clients/new']">
      <div class="add-client"><span class="glyphicon glyphicon-plus"></span> Добавить клиента</div>
    </div>
  </div>
  <div class="clients-container">
    <table class="clients-table">
      <thead class="clients-table-header">
      <tr>
        <th>Наименование клиента</th>
        <th>Контактное лицо</th>
        <th>Контактный телефон</th>
        <th width="10%"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let client of clients.clients">
        <td class="content" [routerLink]="['/freighter-clients', client.id]">{{ client.name }}</td>
        <td class="content" [routerLink]="['/freighter-clients', client.id]">{{ client.contact_name }}</td>
        <td class="content" [routerLink]="['/freighter-clients', client.id]">{{ client.contact_phone|formatPhone }}</td>
        <td class="text-nowrap">
          <a [routerLink]="['/freighter-clients', client.id]" class="btn btn-xs btn-default"><span class="glyphicon glyphicon-pencil"></span></a>
          <a [routerLink]="['/freighter-clients', client.id, 'legal-entities']" class="btn btn-xs btn-default">юр. лица</a>
          <button type="button" class="btn btn-xs btn-default" (click)="onDeleteClient(client)"><span class="glyphicon glyphicon-trash"></span></button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="show-more-btn" *ngIf="showMore">
    <show-more [list]="clients" (onPage)="onShowMorePage($event)"></show-more>
  </div>
</div>
