<form>
  <div class="list-controls" *ngIf="isFreighter">
    <div class="search-field form-group">
      <select class="form-control" name="client" [(ngModel)]="selectedClientId" (ngModelChange)="onChangeClient()">
        <option value="0">- выберите клиента -</option>
        <option *ngFor="let client of freighterClients" [value]="client.id">{{ client.name }}</option>
      </select>
    </div>
    <div class="search-field form-group">
      <select class="form-control" name="legalEntity" [(ngModel)]="selectedClientLegalEntityId"
              (ngModelChange)="onChangeLegalEntity()"
              [disabled]="selectedClientId == '0'">
        <option value="0">- выберите юридическое лицо -</option>
        <option *ngFor="let entity of clientLegalEntities" [value]="entity.id">{{ entity.name }}</option>
      </select>
    </div>
  </div>
  <div class="list-controls">
    <div class="search-field form-group">
      <input #searchField class="form-control" type="text" name="search" placeholder="ИНН или наименование юр. лица"
             [(ngModel)]="search" (ngModelChange)="onChangeSearchString()">
    </div>
  </div>
</form>

<div class="legal-entities-container">
  <table class="legal-entities-table">
    <thead class="legal-entities-table-header">
    <tr>
      <th>Наименование</th>
      <th>ИНН</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entity of foundLegalEntities" (click)="onSelectLegalEntity(entity)">
      <td>{{ entity.short_name || entity.name }}</td>
      <td>{{ entity.inn }}</td>
    </tr>
    </tbody>
  </table>
</div>
