
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Destination} from "../_models/destination";
import {IssueFormComponent} from "../issue-form/issue-form.component";
import {IssueService} from "../_services/issue.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {Order} from "../_models/order";

@Component({
  selector: 'new-issue',
  templateUrl: './new-issue.component.html',
  styleUrls: ['./new-issue.component.css']
})
export class NewIssueComponent implements OnInit {
  @ViewChild('issueDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(IssueFormComponent, { static: true }) issueForm: IssueFormComponent;
  @Output() onCreatedIssue = new EventEmitter<Destination>();

  destination: Destination;
  order: Order;
  creating = false;

  constructor(private issueService: IssueService, private loaderService: LoaderService, private alertService: AlertService) { }

  ngOnInit() {
  }

  show(destination: Destination, order: Order) {
    this.destination = destination;
    this.order = order;

    $(this.dialogEl.nativeElement).modal('show');
  }

  hide() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  createIssue() {
    this.creating = true;
    this.loaderService.show();
    this.issueService
      .createIssueAtDestination(this.destination, this.issueForm.issueIdentifier, this.issueForm.issueDescription, this.issueForm.getMetadata()).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.creating = false;
      }))
      .subscribe(
        () => {
          this.alertService.success("Проблема создана");
          this.hide();
          this.onCreatedIssue.emit(this.destination);
        },
        () => {}
      )
    ;
  }

  onCreateIssue() {
    if(this.issueForm.isDataValid())
      this.createIssue();
  }
}
