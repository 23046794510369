import {Account} from "./account";
import {LegalEntity} from "./legal-entity";
import {Freighter} from "./freighter";
import {EmployerContacts} from "./employer-contacts";
import {Passport} from "./passport";
import {DriverLicense} from "./driver-license";
import {EmployeeDevice} from "./employees/employee-device";
import {Transport} from "./transport";
import {TaxpayerCertificate} from "./taxpayer-certificate";
import {PaymentDistributionSchema} from "./payment-distribution-schema";

export class Employer {
  id: number;
  account: Account;
  status: string;
  legal_entity: LegalEntity;
  comment: string;
  features: any;
  freighter: Freighter;
  contacts: EmployerContacts[];
  passports: Passport[];
  driver_license: DriverLicense[];
  taxpayer_certificate: TaxpayerCertificate[];
  devices: EmployeeDevice[];
  driving_experience: string;
  main_work_transport: Transport;
  payment_distribution_schema: PaymentDistributionSchema;

  static hasPassport(employer: Employer): boolean {
    if(!employer.passports || employer.passports.length == 0)
      return false;

    return Passport.isValid(employer.passports[0]);
  }
}
