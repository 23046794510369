import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UserInfoService} from "../_services/user-info.service";
import {CompanyClientService} from "../_services/company-client.service";
import {finalize} from "rxjs/operators";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";

const SERVICES_WITH_IMPORT = [
  ['ozon', 'Импорт из Ozon', '/assets/images/ozon/ozon-logo-compact.svg', false],
  ['wb', 'Импорт из Wildberries (скоро)', '/assets/images/wb/logo.svg', true],
  ['ya', 'Импорт из Яндекс.Маркет (скоро)', '/assets/images/yandex/logo.svg', true],
];

class ServiceWithImport {
  activated = false;

  constructor(public identifier: string, public subscript: string, public logo: string, public comingSoon: boolean) {
  }
}

class MethodDescription {
  constructor(public title: string, public description: string) {
  }
}

@Component({
  selector: 'new-complex-delivery-method-dialog',
  templateUrl: './new-complex-delivery-method-dialog.component.html',
  styleUrls: ['./new-complex-delivery-method-dialog.component.scss']
})
export class NewComplexDeliveryMethodDialogComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('modal', { static: true }) dialogEl: ElementRef;
  @Output() selected = new EventEmitter<string|null>();

  availableServicesWithImport: ServiceWithImport[] = [];
  methodDescription: MethodDescription = null;

  private selectedMethod: string = null;

  private methodDescriptions = {
    ozon: ['Импорт заказов из Ozon', 'Импортируйте заказы, собранные в маркетплейсе Ozon, в систему MOVER и доставляйте грузы своим покупателям. Статусы доставки будут автоматически передаваться обратно в Ozon.'],
    wb: ['Импорт заказов из Wildberries', 'Импортируйте заказы, собранные в маркетплейсе Wildberries, в систему MOVER и доставляйте грузы своим покупателям. Статусы доставки будут автоматически передаваться обратно в Wildberries.'],
    ya: ['Импорт заказов из Яндекс.Маркет', 'Импортируйте заказы, собранные в маркетплейсе Яндекс.Маркет, в систему MOVER и доставляйте грузы своим покупателям. Статусы доставки будут автоматически передаваться обратно в Яндекс.Маркет.'],
    file: ['Импорт заказов из файла', 'Создавайте заказы в MOVER, импортировав их из файла, используя заданный шаблон. В файле могут быть указаны адреса, параметры груза и данные получателей.'],
    manually: ['Создание заказа вручную', 'Создайте заказы вручную, собрав рейсы из нескольких заказов. Укажите адреса, параметры груза и данные получателей для каждого заказа.']
  };

  private wasShown = false;
  private modalWasInit = false;

  constructor(
    private companyClientService: CompanyClientService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit(): void {
  }

  private initAvailableServicesWithImport(): void {
    let company = this.userInfoService.userInfo.account.company_client;
    let trackingServices = company && company.tracking_services || [];

    this.availableServicesWithImport = SERVICES_WITH_IMPORT
      .map(
        service => new ServiceWithImport(service[0] as string, service[1] as string, service[2] as string, service[3] as boolean)
      )
    ;

    for(let service of this.availableServicesWithImport) {
      if(trackingServices.indexOf(service.identifier) > -1)
        service.activated = true;
    }
  }

  showDialog(): void {
    this.selectedMethod = null;
    this.initAvailableServicesWithImport();

    $(this.dialogEl.nativeElement).modal('show');

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private selectMethod(method: string): void {
    let serviceWithImport  = this.getServiceWithImport(method);
    console.log(serviceWithImport);
    if(serviceWithImport) {
      if(serviceWithImport.comingSoon)
        return;

      if(!serviceWithImport.activated) {
        if(confirm(`Активировать ${serviceWithImport.subscript.toLowerCase()}?`))
          this.activateService(serviceWithImport);

        return;
      }
    }

    this.selectedMethod = method;
    this.hideDialog();
  }

  private activateService(service: ServiceWithImport): void {
    this.loaderService.show();
    this.companyClientService
      .addRegistrationParams(service.identifier)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => {
          this.loaderService.show();
          this.userInfoService
            .reload()
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe({
              next: () => {
                this.initAvailableServicesWithImport();
                this.alertService.success(`${service.subscript} успешно активирован.`);
              },
              error: () => {}
            })
          ;
        },
        error: () => {}
      })
    ;
  }

  private getServiceWithImport(identifier: string): ServiceWithImport|null  {
    for(let service of this.availableServicesWithImport) {
      if(service.identifier == identifier)
        return service;
    }
    return null;
  }

  ngAfterViewInit(): void {
    $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
      if(this.selectedMethod != null)
        this.selected.emit(this.selectedMethod);
    });
    $(this.dialogEl.nativeElement).on('shown.bs.modal', () => {
    });
  }

  onHoverMethod(method: string): void {
    this.methodDescription = this.methodDescriptions[method] && new MethodDescription(this.methodDescriptions[method][0], this.methodDescriptions[method][1]) || null;
  }

  onSelectMethod(method: string): void {
    this.selectMethod(method);
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
