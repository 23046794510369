export const OPTIMIZE_STATUSES = {
  no_calc: 'Редактирование',
  wait_calc: 'Ожидание оптимизации',
  calculating: 'Идёт оптимизация',
  no_transport: 'Для оптимизации не хватает транспорта',
  priority_point_unreachable: 'Приоритетная точка не достижима',
  cant_optimize: 'Не получается оптимизировать',
  error: 'При оптимизации произошла ошибка',
  success: 'Оптимизация успешно завершена',
  no_stock: 'Отсутствует склад',
  executed: 'Отправлен на исполнение',
  _executing: 'Исполняется',
  _completed: 'Завершен',
  _searching: 'Идёт подбор исполнителей',
  _found: 'Исполнитель назначен',
  _completed_partially: 'Завершен частично',
};
