import {FileItem} from "ng2-file-upload/file-upload/file-item.class";
import {ParsedResponseHeaders} from "ng2-file-upload/file-upload/file-uploader.class";

export class UploadError {
  constructor(
    private _file: FileItem,
    private _response: string,
    private _status: number,
    private _headers: ParsedResponseHeaders
  ) {}

  get file(): FileItem {
    return this._file;
  }

  get response(): string {
    return this._response;
  }

  get status(): number {
    return this._status;
  }

  get headers(): ParsedResponseHeaders {
    return this._headers;
  }
}
