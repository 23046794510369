import {FeedTransport} from "./feed-transport";
import {MapMarker} from "./map-marker";
import {Route} from "./route";
import {TransportUtils} from "../_utils/transport-utils";
import {UserNameUtils} from "../_utils/user-name-utils";
import {IntArrayDecoder} from "../_utils/int-array-decoder";

declare var google: any;

export class MapTransport {
  feedTransport: FeedTransport;
  marker: MapMarker;
  path: Route;
  version: number = 0;
  title: string;
  driverTitle: string;
  transportTitle: string;
  isDataLoaded = false;
  executionIndicator = new Map<number, boolean[]>();
  ignoreDriverStatus = false;
  selectable = false;
  private _markerLabel: any;

  buildTitle(): void {
    let driverName = this.feedTransport.employer.account && this.feedTransport.employer.account.surname || '';
    this.transportTitle = TransportUtils.getTransportDescription(this.feedTransport.transport);

    this.driverTitle = UserNameUtils.SNPToName(this.feedTransport.employer.account);

    this.title = `${driverName} / ${this.transportTitle}`;
  }

  buildExecutionIndicator(): void {
    this.executionIndicator.clear();
    for(let order of this.feedTransport.acceptedOrders) {
      let period = order.periods[0];
      let orderIndicator = period.destinations.map(() => false);
      let destinationNum = 0;
      for(let row of period.history) {
        if(row.execution_status == 'on_start_place' || row.execution_status == 'on_end_place') {
          orderIndicator[destinationNum ++] = true;
        }
      }
      this.executionIndicator.set(order.id, orderIndicator);
    }
  }

  updateFromFeedTransport(feedTransport: FeedTransport): void {
    this.feedTransport.point.lat = feedTransport.point.lat;
    this.feedTransport.point.lon = feedTransport.point.lon;

    this.marker = new MapMarker();
    this.marker.url = MapTransport.azimuthToPicture(feedTransport.azimuth);

    if(typeof google != "undefined" && typeof google.maps != "undefined") {
      this.marker.size = new google.maps.Size(72, 72);
      this.marker.anchor = new google.maps.Point(36, 36);
    }

    this._markerLabel = this.feedTransport.employer.status == 'work' ? {text: 'на заказе', className: 'cargo-label'} : null;
  }



  get markerLabel(): any {
    return this.ignoreDriverStatus ? null : this._markerLabel;
  }

  static createFromFeedTransport(feedTransport: FeedTransport, selectable = false): MapTransport {
    let mapTransport = new MapTransport();
    mapTransport.feedTransport = feedTransport;
    mapTransport.marker = new MapMarker();
    mapTransport.marker.url = MapTransport.azimuthToPicture(feedTransport.azimuth);
    mapTransport.path = feedTransport.path
      ? Route.createFromString(feedTransport.path)
      : Route.createFromPoints([]);

    if(feedTransport.pathTimes && feedTransport.pathTimeStart) {
      let pathTimestamps = new IntArrayDecoder(5).unpack(feedTransport.pathTimes).map(t => t * 60 + feedTransport.pathTimeStart);
      // console.log('points count', mapTransport.path.points.length, 'timestamps count', pathTimestamps.length);

      for(let i = 0; i < pathTimestamps.length; i++) {
        // console.log(new Date(pathTimestamps[i] * 1000));
        mapTransport.path.points[i].t = pathTimestamps[i];
      }
    }


    mapTransport.updateFromFeedTransport(feedTransport);

    mapTransport.selectable = selectable;

    return mapTransport;
  }

  private static azimuthToPicture(azimuth: number) {
    let angle = 360 - (azimuth + 360) % 360;
    let picture = Math.round((angle + 90) / 15) * 15;

    if(picture >= 360)
      picture -= 360;

    return '/assets/images/cars/' + picture + '.png';
  }
}
