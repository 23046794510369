import {TaxiMessage} from "./taxi-message";
import {SearchState} from "../../_models/search-state";

export class TaxiSearchState implements TaxiMessage {
  constructor(private _state: SearchState) {}

  getDraftId(): number {
    return this._state.draft;
  }

  get state(): SearchState {
    return this._state;
  }
}
