
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {Editable} from "../_models/editable";
import {Transport} from "../_models/transport";
import {FormErrors} from "../_models/form-errors";
import {TransportService} from "../_services/transport.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../_services/loader.service";
import {TitleService} from "../_services/title.service";
import {UserInfoService} from "../_services/user-info.service";
import {TransportUtils} from "../_utils/transport-utils";
import {Freighter} from "../_models/freighter";

@Component({
  selector: 'app-edit-transport',
  templateUrl: './edit-transport.component.html',
  styleUrls: ['./edit-transport.component.css']
})
export class EditTransportComponent implements OnInit {
  transport = new Editable(new Transport(), new FormErrors());
  freighter: Freighter;

  constructor(
    private transportService: TransportService,
    private userInfoService: UserInfoService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        params => this.loadTransport(+params['id']),
        e => console.error(e)
      );
  }

  private loadTransport(id: number) {
    this.loaderService.show();

    this.transportService
      .getTransport(this.userInfoService.getFreighter(), id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        t => {
          this.transport = new Editable(t, new FormErrors(), false, true);
          this.freighter = this.userInfoService.getFreighter();
          this.titleService.changeTitle(TransportUtils.getTransportDescription(t))
        },
        () => {}
      )
    ;
  }

  private reloadTransport(): void {
    this.loadTransport(this.transport.record.id);
  }

  onUpdated(): void {
    this.reloadTransport();
  }

  onBackToList(): void {
    this.router.navigate([`/transports`]);
  }
}
