<div class="form-container">
  <div class="section">
    <h2>СТС</h2>
    <div class="section-row">
      <chained-photo-field
        [title]="'Лицевая сторона'"
        [identifier]="'sts_front'"
        [photo]="getPhotoUrl('sts_front')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
      <chained-photo-field
        [title]="'Обратная сторона'"
        [identifier]="'sts_back'"
        [photo]="getPhotoUrl('sts_back')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
    </div>
  </div>
  <div class="section">
    <h2>Разрешения</h2>
    <div class="section-row">
      <chained-photo-field
        [title]="'Въезд в ТТК'"
        [identifier]="'ttk_permit'"
        [photo]="getPhotoUrl('ttk_permit')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
      <chained-photo-field
        [title]="'Въезд в СК'"
        [identifier]="'sk_permit'"
        [photo]="getPhotoUrl('sk_permit')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
    </div>
  </div>
  <div class="section">
    <h2>Фото машины</h2>
    <div class="section-row">
      <chained-photo-field
        [title]="'Спереди'"
        [identifier]="'transport_front'"
        [photo]="getPhotoUrl('transport_front')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
      <chained-photo-field
        [title]="'Сзади'"
        [identifier]="'transport_back'"
        [photo]="getPhotoUrl('transport_back')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
      <chained-photo-field
        [title]="'Слева'"
        [identifier]="'transport_left'"
        [photo]="getPhotoUrl('transport_left')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
      <chained-photo-field
        [title]="'Справа'"
        [identifier]="'transport_right'"
        [photo]="getPhotoUrl('transport_right')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
      <chained-photo-field
        [title]="'Грузовой отсек с открытыми дверями'"
        [identifier]="'transport_freight_bay_with_opened_door'"
        [photo]="getPhotoUrl('transport_freight_bay_with_opened_door')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
      <chained-photo-field
        [title]="'Грузовой отсек с закрытыми дверями'"
        [identifier]="'transport_freight_bay_with_closed_door'"
        [photo]="getPhotoUrl('transport_freight_bay_with_closed_door')"
        [uploadChain]="uploadChain"
        (removeFile)="onRemovePhoto($event)"
      ></chained-photo-field>
    </div>
  </div>
</div>
