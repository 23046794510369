import {OrderDraft} from "../_models/order-draft";
import {OrderFeature} from "../_models/order-feature";
import {Feature} from "../_models/feature";
import {ExtraSearchParams} from "../_models/extra-search-params";

export class DraftUtils {
  static isTest(draft: OrderDraft): boolean {
    return draft.test || draft.client && draft.client.is_test;
  }

  /**
   * Возвращает значения свойства черновика
   *
   * @param {OrderDraft} draft
   * @param {string} identifier
   * @return {string}
   */
  static getFeatureValue(draft: OrderDraft, identifier: string): string {
    let feature = DraftUtils.findFeature(draft, identifier);
    return feature && feature.value || '';
  }

  static getFeatureValueAsArray(draft: OrderDraft, identifier: string): string[] {
    let value = DraftUtils.getFeatureValue(draft, identifier);
    return value === '' ? [] : value.split(',').map(t => t.trim());
  }

  /**
   * Возвращает выбранные типы кузовов
   *
   * @param {OrderDraft} draft
   * @return {string[]}
   */
  static getCapacities(draft: OrderDraft): string[] {
    return DraftUtils.getFeatureValueAsArray(draft, 'capacity');
  }

  /**
   * Установка грузоподъёмностей
   *
   * @param {OrderDraft} draft
   * @param {string[]} value
   */
  static setCapacities(draft: OrderDraft, value: string[]) {
    DraftUtils.setFeatureValue(draft, 'capacity', value.join(','));
  }

  /**
   * Возвращает выбранные типы кузовов
   *
   * @param {OrderDraft} draft
   * @return {string[]}
   */
  static getBodyTypes(draft: OrderDraft): string[] {
    return DraftUtils.getFeatureValueAsArray(draft, 'Tip_kuzova');
  }

  /**
   * Установка типов кузова
   *
   * @param {OrderDraft} draft
   * @param {string[]} value
   */
  static setBodyTypes(draft: OrderDraft, value: string[]) {
    DraftUtils.setFeatureValue(draft, 'Tip_kuzova', value.join(','));
  }

  /**
   * Возвращает выбранные длины
   *
   * @param {OrderDraft} draft
   * @return {string}
   */
  static getLengths(draft: OrderDraft): number[] {
    return DraftUtils.getFeatureValueAsArray(draft, 'Dlina').map(t => parseFloat(t.trim()));
  }

  /**
   * Установка типов кузова
   *
   * @param {OrderDraft} draft
   * @param {number[]} value
   */
  static setLengths(draft: OrderDraft, value: number[]) {
    DraftUtils.setFeatureValue(draft, 'Dlina', value.join(','));
  }

  static getHydroElevator(draft: OrderDraft): boolean {
    return DraftUtils.getFeatureValue(draft, 'Nalichie_gidroborta') === '1';
  }

  static setHydroElevator(draft: OrderDraft, value: boolean) {
    if(value) {
      DraftUtils.setFeatureValue(draft, 'Nalichie_gidroborta', value ? '1' : '');
    } else {
      let i = DraftUtils.indexOfFeature(draft, 'Nalichie_gidroborta');
      if(i != -1)
        draft.features.splice(i, 1);
    }
  }

  /**
   * Установка значения свойства
   *
   * @param {OrderDraft} draft
   * @param {string} identifier
   * @param {string} value
   */
  static setFeatureValue(draft: OrderDraft, identifier: string, value: string) {
    let feature = this.findFeature(draft, identifier);
    if(feature == undefined)
      feature = DraftUtils.createFeature(draft, identifier);

    feature.value = value;
  }

  static findFeature(draft: OrderDraft, identifier: string): OrderFeature|undefined {
    if(!draft.features)
      return undefined;

    return draft.features.find(f => f.feature.identifier == identifier);
  }

  static indexOfFeature(draft: OrderDraft, identifier: string): number {
    if(!draft.features)
      return -1;

    return draft.features.findIndex(f => f.feature.identifier == identifier);
  }

  /**
   * Удаление свойства из черновика
   *
   * @param draft
   * @param identifier
   */
  static removeFeature(draft: OrderDraft, identifier: string) {
    let i = this.indexOfFeature(draft, identifier);
    if(i >= 0)
      draft.features.splice(i, 1);
  }

  /**
   * Возвращает дополнительные параметры поиска.
   * Если отсутствуют, то создаёт новый объект с параметрами.
   *
   * @param {OrderDraft} draft
   * @return {ExtraSearchParams}
   */
  static getExtraSearchParams(draft: OrderDraft): ExtraSearchParams {
    if(draft.extra_search_params == null)
      draft.extra_search_params = new ExtraSearchParams();

    return draft.extra_search_params;
  }

  private static createFeature(draft: OrderDraft, identifier: string): OrderFeature {
    let feature = new Feature();
    feature.identifier = identifier;

    let orderFeature = new OrderFeature();
    orderFeature.feature = feature;

    if(!draft.features)
      draft.features = [];

    draft.features.push(orderFeature);

    return orderFeature;
  }
}

