<div class="photo-block">
  <div class="title">{{ title }}</div>
  <div class="img img-rounded" *ngIf="!photo">
    <img src="/assets/images/placeholders/150.png" [alt]="title" [title]="title" class="img-thumbnail photo">
  </div>
  <div class="img img-rounded" *ngIf="photo">
    <div class="del-image" title="Удалить" (click)="onDelete()"><i class="material-icons">delete</i></div>
    <a [href]="photo" [attr.data-lightbox]="'transport-photo-'+identifier">
      <img [src]="photo" [alt]="title" [title]="title" class="img-thumbnail photo">
    </a>
  </div>
  <div class="controls">
    <div class="field">
      <input
        #fileInput
        type="file"
        ng2FileSelect
        [uploader]="uploader"
        (onFileSelected)="onFileSelected($event)"
        accept="image/gif,image/jpeg,image/png"
      >
    </div>
    <div class="error" *ngIf="uploadError">{{ getUploadErrorMessage() }}</div>
  </div>
</div>
