
import {finalize} from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {UploadChain} from "../_upload/upload-chain";
import {UploadUnit} from "../_upload/upload-unit";
import {Uploader} from "../_upload/uploader";
import {Freighter} from "../_models/freighter";
import {Transport} from "../_models/transport";
import {ApiUtilsService} from "../_services/api-utils.service";
import {TransportPhoto} from "../_models/transport-photo";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {CompleteAllUploadsHandler} from "../_upload/complete-all-uploads-handler";
import {UploadErrorMessageBuilder} from "../_upload/upload-error-message-builder";
import {UploadStatus} from "../_upload/upload-status";
import {UpdateUploadStatusHandler} from "../_upload/update-upload-status-handler";
import {environment} from "../../environments/environment";
import {TransportService} from "../_services/transport.service";
import {ChainedPhotoFieldComponent} from "../chained-photo-field/chained-photo-field.component";
import {UploaderService} from "../_services/uploader.service";

@Component({
  selector: 'transport-photos-form',
  templateUrl: './transport-photos-form.component.html',
  styleUrls: ['./transport-photos-form.component.css']
})
export class TransportPhotosFormComponent implements OnInit, OnChanges, CompleteAllUploadsHandler, UpdateUploadStatusHandler {
  @Input() freighter: Freighter;
  @Input() transport: Transport;

  @Output() successUpload = new EventEmitter<void>();
  @Output() updateUploadStatus = new EventEmitter<UploadStatus>();

  @ViewChildren(ChainedPhotoFieldComponent) photoFields: QueryList<ChainedPhotoFieldComponent>;

  uploadChain = new UploadChain();
  photos: Map<string, TransportPhoto> = new Map();

  private unitIdentifiers = [
    'sts_front', 'sts_back', 'transport_front', 'transport_back', 'transport_left', 'transport_right',
    'transport_freight_bay_with_opened_door', 'transport_freight_bay_with_closed_door',
    'ttk_permit', 'sk_permit'
  ];

  constructor(
    private transportService: TransportService,
    private apiUtilsService: ApiUtilsService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private uploaderService: UploaderService,
  ) {
    this.initUploadChain();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initUploaderUrls();
    this.initPhotos();
  }

  upload(): void {
    if(this.transport.id) {
      this.loaderService.show();
      this.uploadChain.upload(this, this);
    }
  }

  private removePhoto(photoType: string): void {
    this.loaderService.show();

    this.transportService
      .deletePhoto(this.freighter, this.transport, photoType).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.photos.get(photoType).picture = null,
        () => {}
      )
  }

  private initUploadChain(): void {
    for(let identifier of this.unitIdentifiers) {
      this.uploadChain.addUnit(identifier, new UploadUnit(new Uploader({
        allowedMimeType: ['image/gif', 'image/jpeg', 'image/png'],
        itemAlias: 'file',
        url: ''
      })))
    }
  }

  private initUploaderUrls(): void {
    for(let identifier of this.unitIdentifiers) {
      this.uploaderService.prepareToUpload(
        `${environment.apiEndpoint}${this.apiUtilsService.getPrefixByFreighterId(this.freighter.id)}/transports/${this.transport.id}/files/${identifier}.json`,
        this.uploadChain.getUnit(identifier).uploader
      );
    }
  }

  private initPhotos(): void {
    this.photos.clear();

    if(!this.transport.photos || this.transport.photos.length == 0)
      return;

    for(let photo of this.transport.photos)
      this.photos.set(photo.type, photo);
  }

  hasUploadError(identifier: string): boolean {
    return this.uploadChain.hasError(identifier);
  }

  getUploadErrorMessage(identifier: string): string {
    return UploadErrorMessageBuilder.build(this.uploadChain.getUploadError(identifier));
  }

  getPhoto(type: string): TransportPhoto|undefined {
    return this.photos.get(type);
  }

  getPhotoUrl(type: string): string|undefined {
    let photo = this.getPhoto(type);
    return photo && photo.picture;
  }

  private onUploadError(): void {
    this.alertService.error('При загрузке файлов произошла ошибка.');
  }

  private onUploadSuccess(): void {
    this.photoFields.forEach(f => f.reset());

    this.successUpload.emit();
  }

  onFileSelected(type: string): void {
    this.uploadChain.getUnit(type).readyForUpload = true;
    console.log(`selected ${type} file`);
  }

  onCompleteAllUploads() {
    this.loaderService.hide();

    if(this.uploadChain.hasErrors())
      this.onUploadError();
    else
      this.onUploadSuccess();
  }

  onUpdateUploadStatus(status: UploadStatus): void {
    this.updateUploadStatus.emit(status);
  }

  onRemovePhoto(photoType: string): void {
    this.removePhoto(photoType);
  }
}
