<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row">
  <div class="no-padding transports-forms transports-form-box">
    <form class="form-inline transports-forms">
      <div class="form-group search-box">
        <input
          type="text"
          class="transports-search transports-control"
          name="number"
          [(ngModel)]="filter.transportNumber"
          (ngModelChange)="onChangeFilter()"
          placeholder="Гос. номер"
          size="40">
      </div>
    </form>
  </div>

  <div class="list-controls">
    <pager [list]="transports" (onPage)="onPaginationPage($event)"></pager>
    <div class="control" [routerLink]="['/transports/new']">
      <div class="add-transport"><span class="glyphicon glyphicon-plus"></span> Добавить машину</div>
    </div>
  </div>

  <div class="transports-container">
    <table class="transports-table">
      <thead>
      <tr class="transports-table-header">
        <th>Марка</th>
        <th>Кузов</th>
        <th>Номер</th>
        <th class="text-center">Грузоподъёмность, т</th>
        <th class="text-center">Размеры, м х м х м</th>
        <th class="text-center">Объём, м<sup>3</sup></th>
        <th class="text-center">Въезд в центр</th>
        <th class="text-center">Наличие гидроборта</th>
      </tr>
      </thead>
      <tbody *ngFor="let transport of transports.transports" class="order-table-row" [routerLink]="['/transports', transport.id]">
      <tr>
        <td>{{ transport.car_model }}</td>
        <td>{{ transport|transportBody }}</td>
        <td>{{ transport.features.number }}</td>
        <td class="text-center">{{ transport|transportCapacity }}</td>
        <td class="text-center">{{ transport|transportSize }}</td>
        <td class="text-center">{{ transport.features.Ob_em_m3 }}</td>
        <td rowspan="2" class="text-center"><span *ngIf="transport.features.Razreshen_v_ezd_v_TTK" class="glyphicon glyphicon-ok enabled"></span></td>
        <td rowspan="2" class="text-center"><span *ngIf="transport.features.Nalichie_gidroborta" class="glyphicon glyphicon-ok enabled"></span></td>
      </tr>
      <tr class="last-subrow">
        <td colspan="6" class="comment">{{ transport.features.comment }}</td>
      </tr>
      </tbody>
    </table>
    <div class="show-more-btn" *ngIf="showMore">
      <show-more [list]="transports" (onPage)="onShowMorePage($event)"></show-more>
    </div>
  </div>
</div>
