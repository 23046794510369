<loader></loader>
<alert></alert>

<div class="main main-profile">

  <header class="profile-header" *ngIf="!hideMenu" [class.content-scrolled]="scrolled">
    <div class="profile-header__inner">
      <div class="row justify-content-between align-items-center">
        <div class="col col-abs profile-header__nav">
          <ul class="profile-header__menu">
            <li routerLinkActive="active" *ngIf="userInfo.isFreighter()">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Экипажи <span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active"><a routerLink="/employees">Водители</a></li>
                <li routerLinkActive="active"><a routerLink="/transports">Автопарк</a></li>
                <li routerLinkActive="active"><a routerLink="/crews">Экипажи</a></li>
              </ul>
            </li>
            <li routerLinkActive="active" *ngIf="userInfo.isFreighter()">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Финансы <span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li routerLinkActive="active"><a routerLink="/payment-distribution-schemas">Схемы распределения средств</a></li>
              </ul>
            </li>
            <li routerLinkActive="active" *ngIf="userInfo.isFreighter()">
              <a routerLink="/freighter-clients">Клиенты</a>
            </li>
            <li routerLinkActive="active" *ngIf="!userInfo.isDeliveryManager()"><a routerLink="/orders">Заказы</a></li>
            <li routerLinkActive="active" *ngIf="(userInfo.isCustomer() && !userInfo.isDeliveryManager()) || userInfo.isPrivilegedUser() || userInfo.isFreighterManager()">
              <a routerLink="/drafts">Заявки</a>
            </li>
            <li routerLinkActive="active" *ngIf="userInfo.isFreighter() && userInfo.isAvailableOwnTariffs()">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                 aria-expanded="false">Тарифы <span class="caret"></span></a>
              <ul class="dropdown-menu tariffs-dropdown">
                <li routerLinkActive="active" class="tariffs-dropdown"><a routerLink="/tariffs/transport">Транспорт</a></li>
                <li routerLinkActive="active" class="tariffs-dropdown"><a routerLink="/tariffs/loaders">Грузчики</a></li>
              </ul>
            </li>
            <li routerLinkActive="active" *ngIf="userInfo.isPrivilegedUser()" ><a routerLink="/message-distribution">Пользователи</a></li>
            <li routerLinkActive="active" *ngIf="userInfo.isPrivilegedUser()" ><a routerLink="/calls">Звонки</a></li>
            <li routerLinkActive="active" *ngIf="userInfo.isDeliveryManager() && userInfo.isAvailableRouteOptimization()"><a routerLink="/complex-deliveries">Маршрутизация</a></li>
            <li routerLinkActive="active" *ngIf="userInfo.isDeliveryManager() && !userInfo.isAvailableRouteOptimization()"><a routerLink="/complex-deliveries">Заказы</a></li>
            <li routerLinkActive="active" *ngIf="!userInfo.isFreighter()"><a routerLink="/user-profile">Профиль</a></li>
            <li routerLinkActive="active" *ngIf="!userInfo.isPrivilegedUser()" ><a href="https://help.mover24.ru" target="_blank">Помощь</a></li>
<!--            <li class="new-draft-menu-item" routerLinkActive="active" *ngIf="userInfo.isPrivilegedUser() || (userInfo.isCustomer() && !userInfo.isDeliveryManager())" ><a routerLink="/drafts/new" class="new-delivery-btn new-btn" title="Создать новую заявку">Новая заявка</a></li>-->
            <li class="new-draft-menu-item" routerLinkActive="active" *ngIf="userInfo.isPrivilegedUser() || (userInfo.isCustomer() && !userInfo.isDeliveryManager())" ><a (click)="onShowDraftSpecialDialog()" class="new-delivery-btn new-btn" title="Создать новую заявку">Новая заявка</a></li>
<!--            <li class="new-draft-menu-item" routerLinkActive="active" *ngIf="userInfo.isDeliveryManager()"><a routerLink="/drafts/new" class="new-delivery-btn new-btn" title="Создать новый заказ на доставку">Новая доставка</a></li>-->
            <li class="new-draft-menu-item" routerLinkActive="active" *ngIf="!userInfo.isDeliveryManager() && userInfo.isFreighterManager()" ><a routerLink="/drafts/new" class="new-delivery-btn new-btn" title="Создать новую заявку">Новая заявка</a></li>
            <li *ngIf="userInfo.isDeliveryManager() && userInfo.isAvailableRouteOptimization()"><a href="#" class="new-delivery-btn new-btn" (click)="showDeliverySchemasDialog()" title="Создать новую маршрутизацию">Новая маршрутизация</a></li>
            <li *ngIf="userInfo.isDeliveryManager() && !userInfo.isAvailableRouteOptimization()"><a href="#" class="new-delivery-btn new-btn" (click)="showNewComplexDeliveryMethodDialog()" title="Создать новый заказ">Новый заказ</a></li>
<!--            <li *ngIf="userInfo.isFreighter()"><a href="#" (click)="showNewOrderModal()" title="Создать новый заказ" class="new-delivery-btn new-btn">Новый заказ</a></li>-->
          </ul>
        </div>
        <div class="col">
          <div class="brand profile-header__brand">
            <a routerLink="/">
              <img src="/assets/images/app2/logo-dark.svg" alt="logo" class="logo">
            </a>
            <div class="city" [class.opened]="visibleCitySelector">
              <div *ngIf="!cityService.isActiveCityLoaded">...</div>
              <div (click)="onClickCity()" *ngIf="cityService.isActiveCityLoaded">{{ cityService.activeCity ? cityService.activeCity.name : 'Все города' }} <span class="glyphicon glyphicon-chevron-down"></span></div>
              <div class="selector" *ngIf="visibleCitySelector && cities.length > 0">
                <div class="item" (click)="onChangeCity()">Все города</div>
                <div class="item" *ngFor="let city of cities" (click)="onChangeCity(city)">{{ city.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col user-controls-col">
          <div class="row no-gutters align-items-center">
            <div class="col">
              <button class="btn btn-default mute-btn" [class.active]="soundNotificationService.isMuted()" (click)="soundNotificationService.isMuted() ? soundNotificationService.unmute() : soundNotificationService.mute()">
                <span class="glyphicon notification-icon" [class.glyphicon-volume-up]="!soundNotificationService.isMuted()" [class.glyphicon-volume-off]="soundNotificationService.isMuted()"></span>
              </button>
            </div>
            <div class="col">
              <button class="btn btn-default notification-btn" [class.has-notice]="_notificationService.getNotificationsCount() > 0" [class.active]="_notificationService.activeNotificationsList" [class.has-attentions]="_notificationService.existAttentions()" (click)="_notificationService.activeNotificationsList = !_notificationService.activeNotificationsList">
                <span class="glyphicon glyphicon-bell notification-icon" [class.infinite-shake-top]="_notificationService.existAttentions()"></span>
                <span class="badge">{{ _notificationService.getNotificationsCount() }}</span>
              </button>
            </div>
<!--            <div class="col">-->
<!--              <div class="profile-header__events">-->
<!--                <div class="profile-header__events-icon">-->
<!--                  <i class="icon icon-profile-event"></i>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col">
              <div class="profile-header__user" routerLink="/user-profile">
                <div class="profile-header__user-cnt">
                  <span class="title">{{ userName }}</span>
                  <span class="balance">{{ balance|humanNumber }} ₽</span>
                </div>
                <div class="profile-header__user-img">
                  <div class="img" *ngIf="!accountPicture"></div>
                  <div class="img" *ngIf="accountPicture" [style.background-image]="accountPicture"></div>
                  <span class="check"><i class="icon icon-abs icon-profile-user-check"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="main-wrapper">
    <map></map>
    <router-outlet></router-outlet>
  </div>

</div>


<app-notifications></app-notifications>
<!--<app-intercom></app-intercom>-->
<!--<app-new-order></app-new-order>-->
<jivo-site *ngIf="userInfo.isPresent() && !userInfo.isPrivilegedUser()"></jivo-site>
<voximplant-controls></voximplant-controls>
<new-complex-delivery [showEvent]="showNewComplexDeliveryFormEvent" [modeEvent]="complexDeliveryFormModeEvent" *ngIf="userInfo.isDeliveryManager() && !hideMenu"></new-complex-delivery>
<draft-special-dialog *ngIf="userInfo.isPresent() && !hideMenu" (selectSpecial)="onSelectDraftSpecial($event)"></draft-special-dialog>
<audio-player></audio-player>
<clipboard-notify-container></clipboard-notify-container>
<delivery-schemas-dialog (schemaSelected)="onSelectDeliverySchema($event)" *ngIf="!hideMenu"></delivery-schemas-dialog>
<new-complex-delivery-method-dialog *ngIf="userInfo.isPresent() && !hideMenu" (selected)="onNewComplexDelivery($event)"></new-complex-delivery-method-dialog>
<ozon-import-dialog *ngIf="ozonImportInitialized && !hideMenu"></ozon-import-dialog>
