import {Pipe, PipeTransform} from "@angular/core";
import {DELIVERY_TO} from "../_maps/delivery-to";

@Pipe({name: "deliveryTo"})
export class DeliveryToPipe implements PipeTransform {
  transform(value: string): string {
    return DELIVERY_TO[value] || '-';
  }

}
