<div class="field-container le-autocomplete">
  <ng-autocomplete
          [data]="bankDetails"
          searchKeyword="short_name"
          (selected)="onSelectCompanyDetails($event)"
          [itemTemplate]="detailsTemplate"
          [notFoundTemplate]="notFoundTemplate"
          [isLoading]="isSearching"
          [debounceTime]="600"
          [minQueryLength]="2"
          [customFilter]="customFilter"
          notFoundText="Организация не найдена"
          placeholder="Поиск банка"
          name="companySearch"
          [(ngModel)]="searchBankValue"
          (ngModelChange)="onChangeCompanySearch($event)"
          (opened)="onOpenAutocomplete()"
          (closed)="onCloseAutocomplete()"
  >
  </ng-autocomplete>

  <ng-template #detailsTemplate let-item>
    <a [innerHTML]="item.short_name"></a>
    <span class="inn">({{ item.inn }})</span>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound" *ngIf="!isSearching"></div>
  </ng-template>
</div>