import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "timer"})
export class TimerPipe implements PipeTransform {
  transform(value: number): string {
    let seconds = Math.round(value / 1000);
    let timerMinutes = Math.floor(seconds / 60);
    let timerSeconds = (seconds - timerMinutes * 60) + "";
    let timerHours = Math.floor(timerMinutes / 60);
    if(timerHours > 0)
      timerMinutes -= timerHours * 60;

    let daysTimer = Math.floor(timerHours / 24);
    if(daysTimer > 0)
      timerHours -= daysTimer * 24;

    if(timerSeconds.length == 1)
      timerSeconds = "0" + timerSeconds;

    let colon = value % 1000 > 500;

    let timerStr = timerMinutes + (colon ? ':' : ' ') + timerSeconds;
    if(timerHours > 0)
      timerStr = timerHours + (colon ? ':' : ' ') + timerStr;

    if(daysTimer > 0)
      timerStr = `${daysTimer} дн. ${timerStr}`;

    return timerStr;
  }
}
