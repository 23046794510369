
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Transport} from "../_models/transport";
import {Observable} from "rxjs";
import {TransportsList} from "../_models/transports-list";
import {Freighter} from "../_models/freighter";
import {ApiUtilsService} from "./api-utils.service";
import {Crew} from "../_models/crew";
import {TransportsFilter} from "../_models/transports-filter";
import {DateTime} from "date-time-js";
import {TariffTier} from "../_models/tariff-tier";
import {HttpResponse} from "@angular/common/http";

const PAGE_SIZE = 15;

@Injectable()
export class TransportService {
  constructor(private _requestService: RequestWithErrorHandlerService, private apiUtilsService: ApiUtilsService) { }

  getFreighterTransports(freighter: Freighter, page?: number, filter?: TransportsFilter): Observable<TransportsList> {
    let filterClone = filter && filter.clone() || new TransportsFilter();

    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/transports.json`, Object.assign({
        offset: (page || 0) * PAGE_SIZE,
        size: PAGE_SIZE
      }, filter && this.createParamsFromFilter(filter) || {})).pipe(
      map(r => new TransportsList(
        page || 0,
        PAGE_SIZE,
        parseInt(r.headers.get('X-Total-Count')),
        filterClone,
        r.body.transports as Transport[],
        r.body.crews as Crew[]
      )));
  }

  getTransport(freighter: Freighter, transportId: number): Observable<Transport> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/transports/${transportId}.json`).pipe(
      map(r => r.body.transport as Transport))
    ;
  }

  getAvailableTiers(freighter: Freighter, transportId: number): Observable<TariffTier[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/transports/${transportId}/tiers/available.json`).pipe(
      map(r => r.body as TariffTier[]))
      ;
  }

  addTransport(freighter: Freighter, transport: Transport, featuresFilter: string[] = []): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/transports.json`, {
        featuresFilter,
        transport: {
          carModel: transport.car_model,
          active: true,
          features: TransportService.applyFilterFeatures(transport.features, featuresFilter)
        }
      })
      ;
  }

  updateTransport(freighter: Freighter, transport: Transport, featuresFilter: string[] = []): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/transports/${transport.id}.json`, {
        featuresFilter,
        transport: {
          carModel: transport.car_model,
          active: true,
          features: TransportService.applyFilterFeatures(transport.features, featuresFilter)
        }
      })
    ;
  }

  deletePhoto(freighter: Freighter, transport: Transport, photoType: string): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/transports/${transport.id}/files/${photoType}.json`)
    ;
  }

  private createParamsFromFilter(filter: TransportsFilter): any {
    return {
      number: filter.transportNumber || null,
      withoutCrew: filter.withoutCrew ? 1 : 0,
      crewDate: new DateTime(filter.crewDate).format('yyyy-MM-dd HH:mm:ss'),
      ofEmployer: filter.ofEmployer
    };
  }

  private static applyFilterFeatures(features: any, featuresFilter: string[]): any {
    if(featuresFilter.length == 0)
      return features;

    let filteredFeatures = {};
    for(let name of featuresFilter)
      filteredFeatures[name] = features[name];

    return filteredFeatures;
  }
}
