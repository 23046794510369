
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PromoCodeFormComponent} from "../promo-code-form/promo-code-form.component";
import {PromoService} from "../_services/promo.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Account} from "../_models/account";

@Component({
  selector: 'promo-code-dialog',
  templateUrl: './promo-code-dialog.component.html',
  styleUrls: ['./promo-code-dialog.component.css']
})
export class PromoCodeDialogComponent implements OnInit, OnDestroy {
  @Input() account: Account;
  @Output() applied = new EventEmitter<string>();
  @ViewChild('promoCodeDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(PromoCodeFormComponent, { static: true }) form: PromoCodeFormComponent;

  error: string;

  private wasShown = false;
  private modalWasInit = false;

  constructor(private promoService: PromoService, private loaderService: LoaderService, private alertService: AlertService) { }

  ngOnInit() {
  }

  showDialog(promocode?: string, disabled: boolean = false) {
    $(this.dialogEl.nativeElement).modal('show');
    this.form.promocode = promocode;
    this.form.disabled = disabled;

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private applyPromoCode(): void {
    let promocode = this.form.promocode;
    if(!promocode)
      return;

    this.error = '';
    this.loaderService.show();

    let applyObservable = this.account
      ? this.promoService.applyForAccount(this.account, promocode)
      : this.promoService.apply(promocode);

    applyObservable.pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success('Промокод применён');
          this.applied.emit(promocode);
          this.hideDialog();
        },
        r => {
          if(r instanceof HttpErrorResponse) {
            switch(r.status) {
              case 404:
                this.error = 'Промокод не найден';
                this.alertService.clear();
                break;
              case 405:
                this.error = 'Промокод уже был использован';
                this.alertService.clear();
                break;
              case 409:
                this.error = 'Нельзя ввести промокод, пока активен текущий';
                this.alertService.clear();
                break;
            }
          }
        }
      )
    ;
  }

  onEnterCode(): void {
    this.applyPromoCode();
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
