
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Destination} from "../_models/destination";
import {DeliveryHistoryService} from "../_services/delivery-history.service";
import {LoaderService} from "../_services/loader.service";
import {DeliveryHistoryRecord} from "../_models/delivery-history-record";

@Component({
  selector: 'destination-delivery-history',
  templateUrl: './destination-delivery-history.component.html',
  styleUrls: ['./destination-delivery-history.component.css']
})
export class DestinationDeliveryHistoryComponent implements OnInit {
  @ViewChild('issuesDialog', { static: true }) dialogEl: ElementRef;

  destination: Destination;
  loading = false;
  history: DeliveryHistoryRecord[] = [];

  constructor(private deliveryHistoryService: DeliveryHistoryService, private loaderService: LoaderService) { }

  ngOnInit() {
  }

  private loadHistory(): void {
    this.loading = true;
    this.history = [];
    this.loaderService.show();
    this.deliveryHistoryService
      .listHistoryAtDestination(this.destination).pipe(
      finalize(() => {
        this.loading = false;
        this.loaderService.hide();
      }))
      .subscribe(
        history => this.history = history,
        () => {}
      )
    ;
  }

  show(destination: Destination) {
    this.destination = destination;

    $(this.dialogEl.nativeElement).modal('show');
    this.loadHistory();
  }

  hide() {
    $(this.dialogEl.nativeElement).modal('hide');
  }
}
