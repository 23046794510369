<div class="no-padding users-forms users-form-box">
  <form class="form-inline users-forms">
    <div class="form-group">
      <div class="labeled-select">
        <label for="account-type">Тип аккаунта</label>
        <select class="form-control" id="account-type" [(ngModel)]="accountType" (change)="onChangeFilter()" name="account-type">
          <option value="" selected="selected">Любой</option>
          <option value="customer" class="opt-lost">Все клиенты</option>
          <option value="driver" class="opt-lost">Все водители</option>
          <option value="freighter_company" class="opt-lost">Компании-перевозчики</option>
          <option value="customer_individual" class="opt-lost">Клиент физ. лицо</option>
          <option value="customer_legal" class="opt-lost">Клиент юр. лицо</option>
          <option value="driver_individual" class="opt-lost">Водитель физ. лицо</option>
          <option value="driver_legal" class="opt-lost">Водитель юр. лицо</option>
        </select>
      </div>
    </div>
    <div class="form-group search-box">
      <input
        type="text"
        class="users-search users-control"
        name="email"
        [(ngModel)]="email"
        (ngModelChange)="onChangeFilter()"
        placeholder="Email"
        size="50">
    </div>
    <div class="form-group search-box">
      <input
        type="text"
        class="users-search users-control"
        name="phone"
        [(ngModel)]="phone"
        (ngModelChange)="onChangeFilter()"
        placeholder="Телефон"
        size="11">
    </div>
    <div class="form-group search-box">
      <input
        type="text"
        class="users-search users-control"
        name="surname"
        [(ngModel)]="surname"
        (ngModelChange)="onChangeFilter()"
        placeholder="Фамилия"
        size="20">
    </div>
    <div class="form-group search-box">
      <input
        type="text"
        class="users-search users-control"
        name="company"
        [(ngModel)]="company"
        (ngModelChange)="onChangeFilter()"
        placeholder="Компания"
        size="20">
    </div>
    <div class="form-group">
      <div class="labeled-select">
        <label for="active">Активация аккаунта</label>
        <select class="form-control" id="active" [(ngModel)]="active" (change)="onChangeFilter()" name="active">
          <option value="" selected="selected">Любая</option>
          <option value="active" class="opt-lost">Активированные</option>
          <option value="no_active" class="opt-lost">Неактивированные</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <div class="labeled-select">
        <label for="deviceType">Тип устройства</label>
        <select class="form-control" id="deviceType" [(ngModel)]="deviceType" (change)="onChangeFilter()" name="deviceType">
          <option value="" selected="selected">Любое</option>
          <option value="android" class="opt-lost">Android</option>
          <option value="ios" class="opt-lost">iOS</option>
          <option value="other" class="opt-lost">Browser</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <div class="labeled-select">
        <label for="employerStatus">Статус водителя</label>
        <select class="form-control" id="employerStatus" [(ngModel)]="employerStatus" (change)="onChangeFilter()" name="employerStatus">
          <option value="" selected="selected">Любой</option>
          <option value="free" class="opt-lost">Свободен</option>
          <option value="rest" class="opt-lost">Не работает</option>
          <option value="work" class="opt-lost">На заказе</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <div class="labeled-select">
        <label for="taxiTariff">Тариф водителя</label>
        <select class="form-control" id="taxiTariff" [(ngModel)]="taxiTariff" (change)="onChangeFilter()" name="taxiTariff">
          <option value="0" selected="selected">Любой</option>
          <option [value]="tier.id" class="opt-lost" *ngFor="let tier of taxiTiers">{{ tier.name }}</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <div class="labeled-select">
        <label for="taxiTariff">Онлайн</label>
        <select class="form-control" id="online" [(ngModel)]="online" (change)="onChangeFilter()" name="online">
          <option [value]="serializeOnlineFilter(item.value)" class="opt-lost" *ngFor="let item of onlineFilters">{{ item.name }}</option>
        </select>
      </div>
    </div>
  </form>
</div>

<div class="search-blocks">
  <div class="no-padding users-form-box with-label folded" *ngIf="ordersCountFilterFolded">
    <div class="block-label"><span (click)="ordersCountFilterFolded = !ordersCountFilterFolded">
        Количество заказов {{ getOrdersCountFilterInfo() }}
    </span></div>
  </div>
  <div class="no-padding users-form-box with-label" *ngIf="!ordersCountFilterFolded">
    <div class="block-label">
      <span (click)="ordersCountFilterFolded = !ordersCountFilterFolded">
        Количество заказов
      </span>
    </div>
    <div class="users-forms">
      <interval-filter [(filter)]="ordersCount" (filterChange)="onChangeFilter()"></interval-filter>
    </div>
    <div class="all-time" *ngIf="lastOrderDate.isEmpty()"><span (click)="onAddLastOrderPeriod()">Выбрать период</span></div>
    <div class="relative-date" *ngIf="!lastOrderDate.isEmpty()">
      <relative-time-filter [(filter)]="lastOrderDate" (filterChange)="onChangeFilter()"></relative-time-filter>
    </div>
    <div class="all-time" *ngIf="!lastOrderDate.isEmpty()"><span (click)="onRemoveLastOrderPeriod()">за всё время</span></div>
  </div>
  <div class="no-padding users-form-box with-label folded" *ngIf="balanceFilterFolded">
    <div class="block-label"><span (click)="balanceFilterFolded = !balanceFilterFolded">
        Баланс {{ getBalanceFilterInfo() }}
    </span></div>
  </div>
  <div class="no-padding users-form-box with-label" *ngIf="!balanceFilterFolded">
    <div class="block-label"><span (click)="balanceFilterFolded = !balanceFilterFolded">
        Баланс
    </span></div>
    <div class="users-forms">
      <interval-filter [(filter)]="balance" (filterChange)="onChangeFilter()"></interval-filter>
    </div>
  </div>
  <div class="no-padding users-form-box with-label folded" *ngIf="registeredFilterFolded">
    <div class="block-label"><span (click)="registeredFilterFolded = !registeredFilterFolded">
        Зарегистрирован {{ getRegisteredFilterInfo() }}
    </span></div>
  </div>
  <div class="no-padding users-form-box with-label date-interval" *ngIf="!registeredFilterFolded">
    <div class="block-label"><span (click)="registeredFilterFolded = !registeredFilterFolded">
      Зарегистрирован
    </span></div>
    <div class="users-forms">
      <order-panel-datepicker [(value)]="registeredAfter" [datepicker]="registeredAfterDatePickerOptions" [timepicker]="false" (dateChange)="onChangeFilter()" name="registeredAfter" ngDefaultControl verticalStretch="true"></order-panel-datepicker>
      <order-panel-datepicker [(value)]="registeredBefore" [datepicker]="registeredBeforeDatePickerOptions" [timepicker]="false" (dateChange)="onChangeFilter()" name="registeredBefore" ngDefaultControl verticalStretch="true"></order-panel-datepicker>
    </div>
  </div>
  <div class="filter-controls-box" *ngIf="!users.filter.isEmpty()">
    <span class="clean-filter" (click)="onCleanFilter()">очистить фильтр</span>
  </div>
</div>

<div class="users-container">
  <div class="no-padding actions">
    <div class="selected-count">
      <pager [list]="users" (onPage)="onPaginationPage($event)" [verticalStretch]="true"></pager>
      <div class="title">Выбрано:</div>
      <div class="value">{{ selectedUsers }}</div>
    </div>
    <div class="action" *ngIf="hasActions">
      <div class="btn-group">
        <button type="button" class="actions-menu-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Действия</button>
        <ul class="dropdown-menu">
          <li class="item" *ngFor="let action of actionsForFiltered" (click)="action.callbackForFiltered(users.filter, users.totalCount)">
            <div class="icon"><img src="{{ action.icon }}" alt=""></div>
            <div class="text">{{ action.text }}</div>
          </li>
          <li class="item for-selected" *ngFor="let action of actionsForSelected" (click)="action.callbackForSelected(getSelectedUsers())">
            <div class="icon"><img src="{{ action.icon }}" alt=""></div>
            <div class="text">{{ action.text }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="users-list">
<table class="users-table">
  <thead>
  <tr class="users-table-header">
    <td class="select-all detail" (click)="onClickSelectAll()">
      <div class="pretty selector">
        <input type="checkbox" name="selectAll" [ngModel]="selectAll">
        <label><i class="g-mdi" data-icon="done"></i></label>
      </div>
    </td>
    <th>ФИО / Банковские карты</th>
    <th>E-mail</th>
    <th>Телефон</th>
    <th class="detail">Устройства</th>
    <th>ONLINE</th>
    <th class="account-type">Тип аккаунта</th>
    <th>Статус</th>
    <th class="date detail">Дата регистрации</th>
    <th>Баланс</th>
    <th class="orders-count detail">
      Количество заказов
      <br>
      Дата последнего заказа
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users.users" [class.selected-user]="userSelects.get(user.account.id)" (click)="onSelectUser(user)" class="user-table-row" [class.not-active-state]="!user.active">
    <td class="detail">
      <div class="pretty selector">
        <input type="checkbox" name="userSelector_{{ user.account.id }}" [ngModel]="userSelects.get(user.account.id)">
        <label><i class="g-mdi" data-icon="done"></i></label>
      </div>
    </td>
    <td>
      <div *ngIf="!user.active">[неактивный]</div>
      <span *ngIf="user.account.is_test" class="test">[тест]</span>
      <span class="user-name" [class.no-name]="(user.account|fullName) === ''" (click)="onShowUserDetail(user)">{{ user.account|fullName:"[Без имени]" }}</span>
      <div class="cards">
        <div class="card" *ngFor="let card of user.account.bank_cards || []" [class.expired]="isCardExpired(card)" [class.active]="card.active" [title]="card.active ? 'Активная карта' : ''">
          <span class="glyphicon glyphicon-credit-card card-icon" title="**** {{ card.mask }} {{ card.expire_date }}"></span>
        </div>
        <div class="card add-fake-card">
          <span class="glyphicon glyphicon-unchecked" *ngIf="isEnabledAddFakeCard" title="Добавить пустую карту" (click)="onAddFakeCard(user.account)"></span>
        </div>
      </div>
    </td>
    <td>{{ user.account.email }}</td>
    <td><a class="phone" title="Позвонить" (click)="onCall(user)" *ngIf="user.account.phone">{{ user.account.phone }}</a></td>
    <td class="device-type detail">
      <div *ngIf="user.devices && user.devices.length > 0">
        <span *ngFor="let device of user.devices; let odd = odd">
          <span *ngIf="odd">|</span>
          <span class="device-type">{{ device }}</span>
        </span>
      </div>
    </td>
    <td>{{ user.online_at|dt:"dd.MM.yy HH:mm" }}</td>
    <td class="account-type">
      <div *ngIf="isDriver(user)" class="label driver">
        <div>Водитель</div>
        <div *ngIf="user.account.employer.legal_entity">{{ user.account.employer.legal_entity.name }}</div>
      </div>
      <div class="taxi-tariffs" *ngIf="user.taxi_tariffs && user.taxi_tariffs.length > 0">
        <div class="tier {{ tariff.tier && tariff.tier.identifier || 'unknown' }}" *ngFor="let tariff of user.taxi_tariffs">{{ tariff.tier && tariff.tier.name || 'Неизвестный тариф' }}</div>
      </div>
      <div *ngIf="!isFreighter(user)" class="label client">
        <div>Клиент</div>
        <div *ngIf="user.account.company_client">{{ user.account.company_client.name }}</div>
        <div *ngIf="user.account.freighter">{{ user.account.freighter.name }}</div>
      </div>
      <div *ngIf="user.role === 'ROLE_COMPANY'" class="label freighter">
        <div>Компания-перевозчик</div>
        <div>{{ user.account.freighter.name }}</div>
      </div>
    </td>
    <td class="status">
      <span *ngIf="user.account.employer">{{ user.account.employer.status|employerStatus }}</span>
      <div *ngIf="user.account.employer && ['rest', 'free'].indexOf(user.account.employer.status) != -1" class="employer-actions">
        <span class="glyphicon glyphicon-retweet status-btn" title="Переключить статус" (click)="onSwitchEmployerStatus(user.account.employer)"></span>
      </div>
    </td>
    <td class="date detail">{{ user.account.created_at|dt:"dd.MM.yy" }}</td>
    <td class="balance">
      <div *ngIf="user.account.sum != 0">
        {{ user.account.sum|humanNumber }}
        <span class="currency">₽</span>
      </div>
    </td>
    <td class="orders-count detail">
      <div *ngIf="user.orders_count > 0">
        {{ user.orders_count }}
        <br>
        {{ user.last_order_date|dt:"dd.MM.yy HH:mm" }}
      </div>
      <div *ngIf="user.driver_orders_count > 0">
        {{ user.driver_orders_count }}
        <br>
        {{ user.driver_last_order_date|dt:"dd.MM.yy HH:mm" }}
      </div>
    </td>
  </tr>
  </tbody>
</table>

<div class="show-more-btn" *ngIf="showMore">
  <show-more [list]="users" (onPage)="onShowMorePage($event)"></show-more>
</div>
</div>

<user-detail-dialog></user-detail-dialog>
