import {Phone} from "./phone";
import {Account} from "../account";
import {Passport} from "../passport";
import {DriverLicense} from "../driver-license";
import {EmployeeDevice} from "./employee-device";
import {LegalEntity} from "../legal-entity";
import {EmployeeIndividual} from "./employee-individual";
import {Transport} from "../transport";
import {TaxpayerCertificate} from "../taxpayer-certificate";
import {Freighter} from "../freighter";
import {PaymentDistributionSchema} from "../payment-distribution-schema";

export class Employee {
  id: number;
  active: boolean;
  comment: string;
  email: string;
  driving_experience: string;
  main_phone: Phone;
  additional_phone: Phone;
  account: Account;
  passport: Passport;
  driver_license: DriverLicense;
  taxpayer_certificate: TaxpayerCertificate;
  device: EmployeeDevice;
  legal_entity: LegalEntity;
  individual: EmployeeIndividual;
  mainWorkTransport: Transport;
  freighter: Freighter;
  payment_distribution_schema: PaymentDistributionSchema;
}
