import {Storehouse} from "./storehouse";
import {OptimizationTask} from "./optimization-task";

/**
 * Запрос на расчёт стоимости доставки по маршрутизации
 */
export class OptimizationTaskCalculationRequest {
  storehouse: Storehouse;
  desiredArrivalDate: string;
  desiredArrivalTimeBegin: string;
  desiredArrivalTimeEnd: string;
  task: OptimizationTask;
  isRouteOptimizationEnabled: boolean;
}