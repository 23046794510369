<div class="list" *ngIf="history.length > 0">
  <table class="table table-condensed table-responsive table-striped">
    <thead>
    <tr>
      <th>Дата</th>
      <th>Причина</th>
      <th class="cost">Груз</th>
      <th class="cost">Скидка</th>
      <th class="cost">Доставка для клиента</th>
      <th class="cost">Доставка для магазина</th>
      <th class="cost">Доставка для перевозчика</th>
      <th *ngIf="productVisible" class="text-center">Заказ</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let record of history">
      <td class="date">{{ record.reason.created_at|dt:"dd.MM.yy HH:mm" }}</td>
      <td>
        <div class="title">{{ record.reason.title }}</div>
        <div class="comment">{{ record.reason.comment }}</div>
        <div *ngIf="record.reason.reason_code == 'setup'" class="setup">Исходная стоимость</div>
        <div class="meta-data" *ngIf="record.reason.meta_data">
          <div *ngIf="record.reason.reason_code != 'remove_product_lines'">
            <div class="code" *ngIf="record.reason.meta_data['code']">Заказ: {{ record.reason.meta_data['code'] }}</div>
            <div class="count" *ngIf="record.reason.meta_data['count']">Количество: {{ record.reason.meta_data['count'] }}</div>
          </div>
          <div *ngIf="record.reason.reason_code == 'remove_product_lines' && record.reason.meta_data">
            <div *ngFor="let item of record.reason.meta_data|mapToIterable">
              <div class="code">Артикул: {{ item.key }}</div>
              <div class="count">Количество: {{ item.val }}</div>
            </div>
          </div>
          <div *ngIf="record.reason.reason_code == 'return_cost' && record.reason.meta_data">
            <div *ngIf="record.reason.meta_data['returnTomorrow']">Доставка на следующий день.</div>
            <div *ngIf="record.reason.meta_data['reason'] == 'driver_wants'">Водитель не может доставить.</div>
            <div *ngIf="record.reason.meta_data['reason'] == 'storehouse_cant_accept'">Склад не может принять груз.</div>
          </div>
        </div>
      </td>
      <td class="cost">{{ record.cargo_cost|humanNumber }} ₽</td>
      <td class="cost">{{ record.cargo_discount|humanNumber }} ₽</td>
      <td class="cost">{{ record.delivery_cost_for_client|humanNumber }} ₽</td>
      <td class="cost">{{ record.delivery_cost_for_shop|humanNumber }} ₽</td>
      <td class="cost">{{ record.delivery_cost_for_freighter|humanNumber }} ₽</td>
      <td *ngIf="productVisible" class="text-center">{{ record.reason?.delivery_product.code }}</td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td></td>
      <td>Итого:</td>
      <td class="cost">{{ historySum.cargo_cost|humanNumber }} ₽</td>
      <td class="cost">{{ historySum.cargo_discount|humanNumber }} ₽</td>
      <td class="cost">{{ historySum.delivery_cost_for_client|humanNumber }} ₽</td>
      <td class="cost">{{ historySum.delivery_cost_for_shop|humanNumber }} ₽</td>
      <td class="cost">{{ historySum.delivery_cost_for_freighter|humanNumber }} ₽</td>
      <td *ngIf="productVisible"></td>
    </tr>
    </tfoot>
  </table>
</div>

<div *ngIf="history.length == 0" class="empty-list">
  <div class="message" *ngIf="loading">Загружается</div>
  <div class="message" *ngIf="!loading">Калькуляция пуста</div>
</div>
