import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from "./_guards/auth.guard";
import {OrdersComponent} from "./orders/orders.component";
import {OrderDetailComponent} from "./order-detail/order-detail.component";
import {TransportTariffsComponent} from "./transport-tariffs/transport-tariffs.component";
import {NewTransportTariffComponent} from "./new-transport-tariff/new-transport-tariff.component";
import {EditTransportTariffComponent} from "./edit-transport-tariff/edit-transport-tariff.component";
import {LoaderTariffsComponent} from "./loader-tariffs/loader-tariffs.component";
import {NewLoaderTariffComponent} from "./new-loader-tariff/new-loader-tariff.component";
import {EditLoaderTariffComponent} from "./edit-loader-tariff/edit-loader-tariff.component";
import {OrderDraftsComponent} from "./order-drafts/order-drafts.component";
import {NewDraftComponent} from "./new-draft/new-draft.component";
import {EditDraftComponent} from "./edit-draft/edit-draft.component";
import {ComplexDeliveriesComponent} from "./complex-deliveries/complex-deliveries.component";
import {ComplexDeliveryViewComponent} from "./complex-delivery-view/complex-delivery-view.component";
import {LegalEntitiesComponent} from "./legal-entities/legal-entities.component";
import {EditLegalEntityComponent} from "./edit-legal-entity/edit-legal-entity.component";
import {NewLegalEntityComponent} from "./new-legal-entity/new-legal-entity.component";
import {UserProfileComponent} from "./user-profile/user-profile.component";
import {MessageDistributionComponent} from "./message-distribution/message-distribution.component";
import {TransportsComponent} from "./transports/transports.component";
import {EditTransportComponent} from "./edit-transport/edit-transport.component";
import {NewTransportComponent} from "./new-transport/new-transport.component";
import {EmployeesComponent} from "./employees/employees.component";
import {EditEmployeeComponent} from "./edit-employee/edit-employee.component";
import {NewEmployeeComponent} from "./new-employee/new-employee.component";
import {CrewsComponent} from "./crews/crews.component";
import {NewCrewComponent} from "./new-crew/new-crew.component";
import {EditCrewComponent} from "./edit-crew/edit-crew.component";
import {CallsComponent} from "./calls/calls.component";
import {FreighterClientsComponent} from "./freighter-clients/freighter-clients.component";
import {NewFreighterClientComponent} from "./new-freighter-client/new-freighter-client.component";
import {EditFreighterClientComponent} from "./edit-freighter-client/edit-freighter-client.component";
import {FreighterClientLegalEntitiesComponent} from "./freighter-client-legal-entities/freighter-client-legal-entities.component";
import {NewFreighterClientLegalEntityComponent} from "./new-freighter-client-legal-entity/new-freighter-client-legal-entity.component";
import {EditFreighterClientLegalEntityComponent} from "./edit-freighter-client-legal-entity/edit-freighter-client-legal-entity.component";
import {SupportNotificationsComponent} from "./support-notifications/support-notifications.component";
import {PaymentDistributionSchemasComponent} from "./payment-distribution-schemas/payment-distribution-schemas.component";
import {NewPaymentDistributionSchemaComponent} from "./new-payment-distribution-schema/new-payment-distribution-schema.component";
import {EditPaymentDistributionSchemaComponent} from "./edit-payment-distribution-schema/edit-payment-distribution-schema.component";
import {CrossAuthComponent} from "./cross-auth/cross-auth.component";

const appRoutes: Routes = [
  { path: '',  redirectTo: '/orders', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { hideMenu: true, title: 'Вход' } },
  { path: 'cross-login/:userId/:token', component: CrossAuthComponent, data: {  hideMenu: true, title: 'Вход' } },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    children: [
      { path: ':freighterId/:id' , component: OrderDetailComponent, data: { fullWidth: true } },
      { path: '', component: OrdersComponent, data: { listPage: true, title: 'Заказы' } },
    ]
  },
  {
    path: 'drafts',
    canActivate: [AuthGuard],
    children: [
      { path: 'new/special/:special/:schema', component: NewDraftComponent, data: { fullWidth: true, title: 'Новая заявка' } },
      { path: 'new/special/:special/:schema/:identifier', component: NewDraftComponent, data: { fullWidth: true } },
      { path: 'new/:id', component: NewDraftComponent, data: { fullWidth: true, title: 'Новая заявка' } },
      { path: 'new', component: NewDraftComponent, data: { fullWidth: true, title: 'Новая заявка' } },
      { path: ':id', component: EditDraftComponent, data: { fullWidth: true } },
      { path: '', component: OrderDraftsComponent, data: { listPage: true, title: 'Заявки' } },
    ]
  },
  {
    path: 'tariffs',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'transport',
        children: [
          { path: 'new', component: NewTransportTariffComponent, data: { title: 'Новый тариф' } },
          { path: ':id', component: EditTransportTariffComponent, data: { title: 'Тариф' } },
          { path: '', component: TransportTariffsComponent, data: { title: 'Тарифы' } },
        ]
      },
      {
        path: 'loaders',
        children: [
          { path: 'new', component: NewLoaderTariffComponent, data: { title: 'Новый тариф' } },
          { path: ':id', component: EditLoaderTariffComponent, data: { title: 'Тариф' } },
          { path: '', component: LoaderTariffsComponent, data: { title: 'Тарифы' } },
        ]
      },
    ]
  },
  {
    path: 'complex-deliveries',
    canActivate: [AuthGuard],
    children: [
      { path: ':id', component: ComplexDeliveryViewComponent },
      { path: '', component: ComplexDeliveriesComponent, data: { listPage: true, title: 'Маршрутизация' } }
    ]
  },
  {
    path: 'legal-entities',
    canActivate: [AuthGuard],
    children: [
      { path: 'new', component: NewLegalEntityComponent, data: { title: 'Новое юр. лицо' } },
      { path: ':id', component: EditLegalEntityComponent },
      { path: '', component: LegalEntitiesComponent, data: { title: 'Юр. лица' } }
    ]
  },
  {
    path: 'transports',
    canActivate: [AuthGuard],
    children: [
      { path: 'new', component: NewTransportComponent, data: { title: 'Новая машина' } },
      { path: ':id', component: EditTransportComponent },
      { path: '', component: TransportsComponent, data: { title: 'Автопарк' } }
    ]
  },
  {
    path: 'employees',
    canActivate: [AuthGuard],
    children: [
      { path: 'new', component: NewEmployeeComponent, data: { listPage: true, title: 'Новый водитель' } },
      { path: ':id', component: EditEmployeeComponent, data: { listPage: true } },
      { path: '', component: EmployeesComponent, data: { listPage: true, title: 'Водители' } }
    ]
  },
  {
    path: 'crews',
    canActivate: [AuthGuard],
    children: [
      { path: 'new', component: NewCrewComponent, data: { title: 'Новый экипаж' } },
      { path: ':id', component: EditCrewComponent },
      { path: '', component: CrewsComponent, data: { listPage: true, title: 'Экипажи' } }
    ]
  },
  {
    path: 'freighter-clients',
    canActivate: [AuthGuard],
    children: [
      { path: 'new', component: NewFreighterClientComponent, data: { title: 'Новый клиент' } },
      { path: ':id/legal-entities/new/:inn', component: NewFreighterClientLegalEntityComponent },
      { path: ':id/legal-entities/:entityId', component: EditFreighterClientLegalEntityComponent },
      { path: ':id/legal-entities', component: FreighterClientLegalEntitiesComponent },
      { path: ':id', component: EditFreighterClientComponent },
      { path: '', component: FreighterClientsComponent, data: { listPage: true, title: 'Клиенты' } }
    ]
  },
  {
    path: 'user-profile',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    component: UserProfileComponent,
    data: { title: 'Профиль' }
  },
  {
    path: 'message-distribution',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    component: MessageDistributionComponent,
    data: { listPage: true, title: 'Пользователи' }
  },
  {
    path: 'calls',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    component: CallsComponent,
    data: { listPage: true, title: 'Звонки' }
  },
  {
    path: 'support-notifications',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    component: SupportNotificationsComponent,
    data: { listPage: true, title: 'Уведомления' }
  },
  {
    path: 'payment-distribution-schemas',
    canActivate: [AuthGuard],
    children: [
      { path: 'new', component: NewPaymentDistributionSchemaComponent, data: { title: 'Новая схема распределения средств' } },
      { path: ':id', component: EditPaymentDistributionSchemaComponent },
      { path: '', component: PaymentDistributionSchemasComponent, data: { listPage: true, title: 'Схемы распределения средств' }}
    ]
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, {});
