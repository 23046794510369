import {TransportPhoto} from "./transport-photo";
import {Freighter} from "./freighter";

export class Transport {
  id: number;
  car_model: string;
  features: any;
  photos: TransportPhoto[];
  freighter: Freighter;
  is_virtual: boolean;
}
