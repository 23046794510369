import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DatepickerOptions} from "./datepicker-options";
import {TimepickerOptions} from "./timepicker-options";
import {DateTime} from "date-time-js";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

const DATE_REGEXP = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
const TIME_REGEXP = /^([0-9]{2}):([0-9]{2})(?::([0-9]{2}))?$/;

@Component({
  selector: 'order-panel-datepicker',
  templateUrl: './order-panel-datepicker.component.html',
  styleUrls: ['./order-panel-datepicker.component.css']
})
export class OrderPanelDatepickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value: Date | null = null;
  @Input() name: string;
  @Input() isValid = true;
  @Input() verticalStretch = false;
  @Input() readonly = false;
  @Input() required = false;
  @Input() maskAlwaysVisible = false;
  @Input() datepicker: DatepickerOptions | boolean = false;
  @Input() timepicker: TimepickerOptions | boolean = false;
  /**
   * @deprecated
   */
  @Input() hasClearButton = false;
  @Output() valueChange = new EventEmitter<Date | null>();
  @Output() dateChange = new EventEmitter<Date | null>();

  dateModel: string = '';
  timeModel: string = '';
  isFocused = false;
  showCleanButton = false;
  isCleanButtonActivated = false;

  private changesStream = new Subject<Date | null>()

  private checkShowCleanButtonTimer: any;

  ngOnInit() {
    this.initChangesStream();
    this.applyInputModel();
    this.initCheckShowCleanButton();
  }

  ngOnDestroy(): void {
    this.stopCheckShowCleanButton();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['value']) {
      this.applyInputModel();
      this.initChangesStream();
    }
  }

  private initCheckShowCleanButton(): void {
    this.stopCheckShowCleanButton();
    this.checkShowCleanButtonTimer = setInterval(() => this.onCheckShowCleanButton(), 300);
  }

  private stopCheckShowCleanButton(): void {
    if(this.checkShowCleanButtonTimer) {
      clearInterval(this.checkShowCleanButtonTimer);
      this.checkShowCleanButtonTimer = null;
    }
  }

  private initChangesStream(): void {
    this.changesStream = new Subject<Date | null>();
    this.changesStream.pipe(
      debounceTime(1000),
      distinctUntilChanged(
        (d1: Date|null, d2: Date|null): boolean => {
          console.log(d1, d2);

          if(!d1 && !d2)
            return true;

          if(!d1 || !d2)
            return false;

          if(this.datepicker) {
            if(new DateTime(d1).format('yyyy-MM-dd') != new DateTime(d2).format('yyyy-MM-dd'))
              return false;
          }
          if(this.timepicker) {
            if(new DateTime(d1).format('HH:mm') != new DateTime(d2).format('HH:mm'))
              return false;
          }
          return true;
        }
      )
    ).subscribe(d => {
      this.valueChange.emit(d);
      this.dateChange.emit(d);
    });
  }

  private applyInputModel(): void {
    this.dateModel = this.value ? new DateTime(this.value).format('yyyy-MM-dd') : '';
    this.timeModel = this.value ? new DateTime(this.value).format('HH:mm') : '';
  }

  private validate(): void {
    this.isValid = this.isDateValid() && this.isTimeValid();
  }

  private isDateValid(): boolean {
    if(!this.datepicker)
      return true;

    if(!this.required && this.dateModel == '')
      return true;

    return DATE_REGEXP.test(this.dateModel);
  }

  private isTimeValid(): boolean {
    if(!this.timepicker)
      return true;

    if(!this.required && this.timeModel == '')
      return true;

    return TIME_REGEXP.test(this.timeModel);
  }

  private applyChanges(): void {
    let isDateCompleted = this.datepicker && this.dateModel;
    let isTimeCompleted = this.timepicker && this.timeModel;
    let isValueCompleted = this.datepicker && this.timepicker && isDateCompleted && isTimeCompleted
      || this.datepicker && !this.timepicker && isDateCompleted
      || this.timepicker && !this.datepicker && isTimeCompleted;

    if(!isValueCompleted) {
      if(this.value)
        this.changesStream.next(null);

      return;
    }

    let [year, month, day] = this.getDateComponents();
    let [hour, minutes, seconds] = this.getTimeComponents();
    let date = new Date(year, month - 1, day, hour, minutes, seconds);
    this.changesStream.next(date);
  }

  private getDateComponents(): number[] {
    let dateString = this.datepicker ? this.dateModel : new DateTime().format('yyyy-MM-dd');
    return dateString.split('-').map(c => parseInt(c));
  }

  private getTimeComponents(): number[] {
    let timeString = this.timepicker && this.timeModel || new DateTime().format('HH:mm');
    let timeComponents = TIME_REGEXP.exec(timeString);
    console.log(timeComponents);
    return [parseInt(timeComponents[1]), parseInt(timeComponents[2]), parseInt(timeComponents[3] || '0')];
  }

  onChangeInput(): void {
    this.validate();
    if(this.isValid)
      this.applyChanges();
  }

  clearModels() {
    this.dateModel = '';
    this.timeModel = '';
    this.applyChanges();
  }

  onFocus(): void {
    this.isFocused = true;
  }

  onBlur(): void {
    this.isFocused = false;
  }

  onCleanDateModel(): void {
    this.dateModel = '';
    this.showCleanButton = false;
    this.applyChanges();
  }

  onCleanTimeModel(): void {
    this.timeModel = '';
    this.showCleanButton = false;
    this.applyChanges();
  }

  onCheckShowCleanButton(): void {
    this.showCleanButton = (this.dateModel != '' || this.timeModel != '') && this.isFocused;
    if(this.showCleanButton)
      this.isCleanButtonActivated = true;
  }
}
