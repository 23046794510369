export class OrderReport {
  order_id: number;
  discount: number;
  sum: number;
  freighter_sum: number;
  mover_sum: number;
  employer_wage_sum: number;
  input_sum: number;
  payment_system_sum: number;
  employer_fee_sum: number;
  employer_tax_sum: number;
}
