<form (submit)="onSave()">
  <div class="form-flex">
    <div class="form-group">
      <select name="legalEntity" [(ngModel)]="selected">
        <option [value]="e.id" *ngFor="let e of entities" [class.active]="e.company_client_legal_entity && e.company_client_legal_entity.active">{{ e.name }}</option>
      </select>
      <p class="help-block">жирным отмечено текущее юр. лицо компании</p>
    </div>
  </div>
  <button type="submit" class="save-btn">Сохранить</button>
</form>
