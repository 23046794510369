
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {Editable} from "../_models/editable";
import {FormErrors} from "../_models/form-errors";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../_services/loader.service";
import {TitleService} from "../_services/title.service";
import {FreighterClientService} from "../_services/freighter-client.service";
import {UserInfoService} from "../_services/user-info.service";
import {FreighterClient} from "../_models/freighter-client";
import {Freighter} from "../_models/freighter";

@Component({
  selector: 'app-edit-freighter-client',
  templateUrl: './edit-freighter-client.component.html',
  styleUrls: ['./edit-freighter-client.component.css']
})
export class EditFreighterClientComponent implements OnInit {
  freighter: Freighter;

  client = new Editable(new FreighterClient(), new FormErrors());

  constructor(
    private freighterClientService: FreighterClientService,
    private router: Router,
    private route: ActivatedRoute,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();

    this.route.params
      .subscribe(
        params => this.loadClient(+params['id']),
        e => console.error(e)
      );
  }

  private loadClient(id: number) {
    this.loaderService.show();

    this.freighterClientService
      .getClient(this.userInfoService.getFreighter(), id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        c => {
          this.client = new Editable(c, new FormErrors(), false, true);
          this.titleService.changeTitle(`Клиенты - ${c.name}`)
        },
        () => {}
      )
    ;
  }

  onBack(): void {
    this.router.navigate([`/freighter-clients`]);
  }

  onSaved(client: FreighterClient): void {
    this.router.navigate([`/freighter-clients`]);
  }

}
