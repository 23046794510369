import {Injectable} from "@angular/core";
import {RequestService} from "./request.service";
import {HttpErrorResponse} from "@angular/common/http";

const PHONE_STORAGE = 'phone';

export enum LoginResult {
  Ok,
  NotFound,
  AccessDenied,
  IncorrectPassword,
  SystemError
}

export enum RestorePasswordResult {
  Ok,
  UnknownPhone,
  SystemError
}

@Injectable()
export class LoginService {
  constructor(private requestService: RequestService) {}

  /**
   * Сохранение номера телефона
   * @param phone
   */
  savePhone(phone: string) {
    localStorage.setItem(PHONE_STORAGE, phone);
  }

  /**
   * Восстановление номера телефона
   */
  restorePhone(): string {
    return localStorage.getItem(PHONE_STORAGE);
  }

  login(username: string, password: string): Promise<LoginResult> {
    return this.requestService
      .post('/login/device.json', {
        username: username,
        password: password
      })
      .toPromise()
      .then(r => LoginResult.Ok)
      .catch(e => {
        console.log(e);
        if(e instanceof HttpErrorResponse) {
          switch (e.status) {
            case 404:
              return LoginResult.NotFound;
            case 401:
              return LoginResult.AccessDenied;
            case 403:
              return LoginResult.IncorrectPassword;
            case 500:
              return LoginResult.SystemError;
          }
        }
        throw e;
      });
  }

  restorePassword(phone: string): Promise<RestorePasswordResult> {
    return this.requestService
      .post('/login/restore/by/phone.json', {
        phone: phone
      })
      .toPromise()
      .then(r => RestorePasswordResult.Ok)
      .catch(e => {
        console.log(e);
        if(e instanceof HttpErrorResponse) {
          switch (e.status) {
            case 404:
              return RestorePasswordResult.UnknownPhone;
            case 500:
              return RestorePasswordResult.SystemError;
          }
        }
        throw e;
      });
  }
}
