<form (submit)="onSubmit()">
  <div class="message__tabs-items">
    <div class="message__tabs-item">
      <div class="message__groups">
        <div class="message__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <label>Начало периода</label>
                <input class="form-control" name="start" type="date" [(ngModel)]="periodStart" required [disabled]="sending">
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <label>Конец периода</label>
                <input class="form-control" name="end" type="date" [(ngModel)]="periodEnd" required [disabled]="sending">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <div class="form-group">
                <label>Отправить все отчёты на указанный e-mail</label>
                <input class="form-control" name="forceRecipient" type="email" [(ngModel)]="forceRecipient" [disabled]="sending">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <div class="send-result">
                <div class="title">Статус отправки</div>
                <div class="result-rows">
                  <div class="result-row" *ngFor="let result of results">
                    {{ result.user.account|fullName }} ({{ result.user.account.phone }}) - <span class="status-{{ result.status }}">{{ translateSendStatus(result.status) }}<span *ngIf="result.error != null"> ({{ result.error }})</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="message__btn">
        <div class="row">
          <div class="col col-6">
            <button class="btn btn-primary btn-block message__btn-save" type="submit" [disabled]="sending">
              Отправить
            </button>
          </div>
          <div class="col col-6">
            <button class="message__btn-reset btn-block" type="button" (click)="onClickCancel()">Отменить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
