
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Transport} from "../_models/transport";
import {CAPACITIES} from "../_maps/capacities";
import {TRANSPORT_BODIES} from "../_maps/transport-bodies";
import {Editable} from "../_models/editable";
import {LoaderService} from "../_services/loader.service";
import {TransportService} from "../_services/transport.service";
import {UserInfoService} from "../_services/user-info.service";
import {Freighter} from "../_models/freighter";
import {AlertService} from "../_services/alert.service";
import {HttpErrorResponse} from "@angular/common/http";
import {FormErrors} from "../_models/form-errors";
import {TransportPhotosFormComponent} from "../transport-photos-form/transport-photos-form.component";
import {UploadStatus} from "../_upload/upload-status";

@Component({
  selector: 'transport-form',
  templateUrl: './transport-form.component.html',
  styleUrls: ['./transport-form.component.css']
})
export class TransportFormComponent implements OnInit {
  @Input() transport: Editable<Transport>;
  @Input() freighter: Freighter;

  @Output() saved = new EventEmitter<Transport>();
  @Output() back = new EventEmitter<void>();

  @ViewChild(TransportPhotosFormComponent, { static: true }) transportPhotosForm: TransportPhotosFormComponent;

  capacities = CAPACITIES;
  transportBodies = TRANSPORT_BODIES;

  private featuresFilter = [
    'Tip_kuzova', 'capacity', 'Nalichie_gidroborta', 'Dlina', 'Shirina', 'Vyisota', 'Ob_em_m3', 'Razreshen_v_ezd_v_TTK',
    'number', 'comment'
  ];

  private photoNames = {
    sts_front: 'Лицевая сторона СТС',
    sts_back: 'Обратная сторона СТС',
    transport_front: 'Машина спереди',
    transport_back: 'Машина сзади',
    transport_left: 'Машина слева',
    transport_right: 'Машина справа',
    transport_freight_bay_with_opened_door: 'Грузовой отсек с открытыми дверями',
    transport_freight_bay_with_closed_door: 'Грузовой отсек с закрытими дверями',
    ttk_permit: 'Разрешение на въезд в ТТК',
    sk_permit: 'Разрешение на въезд в СК'
  };

  isEditAllowed = false;

  constructor(
    private transportService: TransportService,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) {  }

  ngOnInit() {
    this.initControls();
  }

  private initControls(): void {
    this.isEditAllowed = this.transport.record.freighter.id == this.userInfoService.getFreighter().id;
  }

  private add() {
    this.loaderService.show();
    this.transport.isSubmitting = true;

    this.transportService
      .addTransport(this.freighter, this.transport.record, this.featuresFilter).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.transport.isSubmitting = false;
      }))
      .subscribe(
        r => this.loadTransportAndUploadPhotos(r.body.id),
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.transport.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  private loadTransportAndUploadPhotos(transportId: number): void {
    this.loaderService.show();
    this.transport.isSubmitting = true;

    this.loaderService.showText('Машина добавлена. Загружаем файлы...');

    this.transportService
      .getTransport(this.freighter, transportId).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.transport.isSubmitting = false;
      }))
      .subscribe(
        t => {
          this.transport.record = t;
          this.transport.isNew = false;

          setTimeout(() => this.uploadPhotos(), 500);
        },
        () => {}
      );
  }

  private update() {
    this.loaderService.show();
    this.transport.isSubmitting = true;

    this.transportService
      .updateTransport(this.freighter, this.transport.record, this.featuresFilter).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.transport.isSubmitting = false;
      }))
      .subscribe(
        () => {
          this.loaderService.showText('Данные машины обновлены. Загружаем файлы...');
          this.uploadPhotos();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.transport.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  private uploadPhotos(): void {
    this.transport.isSubmitting = true;
    this.transportPhotosForm.upload();
  }

  private prepareToSave(): void {
    delete this.transport.record.features.empty;
  }

  private completeSaving(): void {
    this.transport.isSubmitting = false;
    this.saved.emit(this.transport.record);
  }

  onBack(): boolean {
    this.back.emit();

    return false;
  }

  onSave(): void {
    this.prepareToSave();

    if(this.transport.isNew)
      this.add();
    else
      this.update();
  }

  onUploadSuccess(): void {
    this.alertService.success('Данные транспорта обновлены.', true);
    this.completeSaving();
  }

  onUpdateUploadStatus(status: UploadStatus): void {
    this.loaderService.showText(`Загрузка файла "${this.photoNames[status.identifier] || status.identifier}" (${status.currentUploadNumber} из ${status.allUploadCount})...`);
  }
}
