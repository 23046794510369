
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Destination} from "../_models/destination";
import {Observable} from "rxjs";
import {DeliveryHistoryRecord} from "../_models/delivery-history-record";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";

@Injectable()
export class DeliveryHistoryService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  listHistoryAtDestination(destination: Destination): Observable<DeliveryHistoryRecord[]> {
    return this._requestService
      .get(`/delivery/history/period_destinations/${destination.id}.json`).pipe(
      map(r => r.body as DeliveryHistoryRecord[]))
      ;
  }
}
