
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Order} from "../_models/order";
import {DeliveryProductIssue} from "../_models/delivery-product-issue";
import {IssueService} from "../_services/issue.service";
import {LoaderService} from "../_services/loader.service";

@Component({
  selector: 'order-delivery-issues',
  templateUrl: './order-delivery-issues.component.html',
  styleUrls: ['./order-delivery-issues.component.css']
})
export class OrderDeliveryIssuesComponent implements OnInit {
  @ViewChild('issuesDialog', { static: true }) dialogEl: ElementRef;

  order: Order;
  issues: DeliveryProductIssue[] = [];
  loading = false;

  constructor(private issueService: IssueService, private loaderService: LoaderService) { }

  ngOnInit() {
  }

  loadIssues() {
    this.loaderService.show();
    this.loading = true;
    this.issues = [];
    this.issueService
      .listIssuesByOrder(this.order).pipe(
      finalize(() => {
        this.loading = false;
        this.loaderService.hide();
      }))
      .subscribe(
        issues => this.issues = issues,
        () => {}
      )
  }

  show(order: Order) {
    this.order = order;

    $(this.dialogEl.nativeElement).modal('show');
    this.loadIssues();
  }

  hide() {
    $(this.dialogEl.nativeElement).modal('hide');
  }
}
