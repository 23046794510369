
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {LegalEntityService} from "../_services/legal-entity.service";
import {LoaderService} from "../_services/loader.service";
import {UserInfoService} from "../_services/user-info.service";
import {CompanyClientLegalEntity} from "../_models/company-client-legal-entity";

@Component({
  selector: 'app-legal-entities',
  templateUrl: './legal-entities.component.html',
  styleUrls: ['./legal-entities.component.css']
})
export class LegalEntitiesComponent implements OnInit {
  entities: CompanyClientLegalEntity[] = [];

  constructor(
    private legalEntityService: LegalEntityService,
    public userInfoService: UserInfoService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loadEntities();
  }

  private loadEntities() {
    if(!this.userInfoService.userInfo.account.company_client) {
      this.entities = [];
      return;
    }

    this.loaderService.show();
    this.legalEntityService
      .getCompanyLegalEntities(this.userInfoService.userInfo.account.company_client.id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        es => this.entities = es,
        () => {}
      )
    ;
  }
}
