
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Destination} from "../_models/destination";
import {IssueService} from "../_services/issue.service";
import {DeliveryProductIssue} from "../_models/delivery-product-issue";
import {LoaderService} from "../_services/loader.service";

@Component({
  selector: 'destination-delivery-issues',
  templateUrl: './destination-delivery-issues.component.html',
  styleUrls: ['./destination-delivery-issues.component.css']
})
export class DestinationDeliveryIssuesComponent implements OnInit {
  @ViewChild('issuesDialog', { static: true }) dialogEl: ElementRef;
  @Output() onCreateIssue = new EventEmitter<Destination>();

  destination: Destination;
  private enabledCreate = false;
  enabledCreateForDestination = false;
  issues: DeliveryProductIssue[] = [];
  loading = false;

  constructor(private issueService: IssueService, private loaderService: LoaderService) { }

  ngOnInit() {
  }

  loadIssues() {
    this.loading = true;
    this.issues = [];
    this.loaderService.show();
    this.issueService
      .listIssuesAtDestination(this.destination).pipe(
      finalize(() => {
        this.loading = false;
        this.loaderService.hide();
      }))
      .subscribe(
        issues => this.issues = issues,
        () => {}
      )
    ;
  }

  show(destination: Destination, enabledCreate: boolean) {
    this.destination = destination;
    this.enabledCreate = enabledCreate;
    this.initCreating();

    $(this.dialogEl.nativeElement).modal('show');
    this.loadIssues();
  }

  hide() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private initCreating(): void {
    if(!this.enabledCreate) {
      this.enabledCreateForDestination = false;
      return;
    }

    let destinationId = this.destination.id;
    this.issueService
      .getAvailableIssuesForDestination(this.destination)
      .subscribe(
        issues => {
          this.enabledCreateForDestination = this.enabledCreate && issues.length > 0 && this.destination.id === destinationId;
        },
        () => {}
      )
    ;
  }

  onCreate() {
    this.hide();
    this.onCreateIssue.emit(this.destination);
  }
}
