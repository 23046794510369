<div *ngIf="draft.id">
  <div *ngIf="photos.length > 0" class="images-container">
    <div *ngFor="let photo of photos" class="img-box">
      <div class="del-image" title="Удалить" (click)="onDelete(photo)"><i class="material-icons">delete</i></div>
      <a href="{{ photo.url }}" [attr.data-lightbox]="'draft-'+draft.id">
        <img class="img-thumbnail" src="{{ photo.image_sizes.small.path }}" *ngIf="photo.image_sizes && photo.image_sizes.small">
        <span *ngIf="!photo.image_sizes || !photo.image_sizes.small">[photo]</span>
      </a>
    </div>
  </div>
  <div>
    <div class="for-upload" *ngIf="uploader.queue.length > 0">
      <div class="header">Будут загружены:</div>
      <ul>
        <li *ngFor="let file of uploader.queue">{{ file.file.name }}</li>
      </ul>
    </div>
    <input type="file" name="photo" multiple ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected()" accept="image/gif,image/jpeg,image/png"/>
  </div>
  <button class="save-btn" (click)="onSave()" *ngIf="needSave">Сохранить</button>
</div>
<div *ngIf="!draft.id">Загрузка фото будет доступна после добавления заявки</div>
