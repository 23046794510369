import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Freighter} from "../_models/freighter";
import {EmployeeSelectorComponent} from "../employee-selector/employee-selector.component";
import {Employer} from "../_models/employer";

@Component({
  selector: 'employee-selector-dialog',
  templateUrl: './employee-selector-dialog.component.html',
  styleUrls: ['./employee-selector-dialog.component.css']
})
export class EmployeeSelectorDialogComponent implements OnInit, OnDestroy {
  @Input() freighter: Freighter;
  @Input() date: Date;
  @Output() selected = new EventEmitter<Employer>();

  @ViewChild('employeeDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(EmployeeSelectorComponent, { static: true }) selector: EmployeeSelectorComponent;

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  showDialog() {
    $(this.dialogEl.nativeElement).modal('show');
    this.selector.reloadEmployees();
    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  private hideDialog() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  onSelected(employer: Employer): void {
    this.hideDialog();
    this.selected.emit(employer);
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
