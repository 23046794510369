export const ORDER_PAYMENT_STATUS = [
  'Платежей не поступало',
  'Платёж захолдирован',
  'Оплачено',
  "Платёж отклонён"
];

export const CLIENT_PAYMENT_STATUS = [
  'Платежей не поступало',
  'Платёж захолдирован',
  'Платёж получен',
  'Платёж отклонён'
];

export const EMPLOYEE_PAYMENT_STATUS = [
  'Выплаты не производились',
  'Платёж захолдирован',
  'Зачислено на счёт',
  'Платёж отклонён'
];

export const EMPLOYEE_FEE_PAYMENT_STATUS = [
  'Комиссия не списана',
  'Комиссия захолдирована',
  'Комиссия списана',
  'Платёж отклонён'
];
