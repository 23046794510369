import {Pipe} from "@angular/core";
import {City} from "../_models/city";
import {TzPipe} from "./tz-pipe";

@Pipe({name: 'myTz'})
export class MyTzPipe extends TzPipe {
  transform(city: City): boolean {
    return city.time_zone == this.userCityTz;
  }
}
