import {UsersFilter} from "../_models/users/users-filter";
import {UserStat} from "../_models/user-stat";

export class UserListAction {
  constructor(
    public icon: string,
    public text: string,
    public callbackForFiltered?: (filter: UsersFilter, totalCount: number) => void,
    public callbackForSelected?: (users: UserStat[]) => void,
  ) {}
}
