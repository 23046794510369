import {State} from "../_storage/state";
import {DateUtils} from "../_utils/date-utils";

export class CallsListFilter {
  constructor(
    public fromDate?: Date,
    public toDate?: Date,
    public number?: string,
    public surname?: string
  ) {}

  getState(): State {
    return new State(JSON.stringify({
      fromDate: this.fromDate,
      toDate: this.toDate,
      number: this.number,
      surname: this.surname
    }));
  }

  equals(filter: CallsListFilter): boolean {
    return DateUtils.isEqualDates(this.fromDate, filter.fromDate)
      && DateUtils.isEqualDates(this.toDate, filter.toDate)
      && this.number == filter.number
      && this.surname == filter.surname
      ;
  }

  clone(): CallsListFilter {
    return new CallsListFilter(this.fromDate, this.toDate, this.number, this.surname);
  }

  static fromState(state: State): CallsListFilter {
    let data = JSON.parse(state.state);
    let fromDate = data.fromDate == null ? null : new Date(data.fromDate);
    let toDate = data.toDate == null ? null : new Date(data.toDate);

    return new CallsListFilter(fromDate, toDate, data.number, data.surname);
  }
}
