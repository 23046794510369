<div class="modal fade" tabindex="-1" role="dialog" #destinationsDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">{{ title }}</h4>
        </div>
      </div>
      <div class="modal-body">
        <complex-delivery-destinations-selector
          [destinations]="destinations"
          (selectedDestinations)="onSelectionChange($event)"
          [selectAllByDefault]="selectAllByDefault"
          *ngIf="destinations.length > 0"
        ></complex-delivery-destinations-selector>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Отменить</button>
        <button type="button" class="btn btn-primary" (click)="onSelect()">{{ actionName }}</button>
      </div>
    </div>
  </div>
</div>
