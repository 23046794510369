
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {Freighter} from "../_models/freighter";
import {Point} from "../_models/point";
import {ApiUtilsService} from "./api-utils.service";
import {DeliverySchema} from "../_models/delivery-schema";

@Injectable()
export class FreighterService {
  constructor(
    private _requestService: RequestWithErrorHandlerService,
    private apiUtilsService: ApiUtilsService,
  ) {}

  getFastList(search: string): Observable<Freighter[]> {
    return this._requestService
      .get('/support/me/fast_list/freighters.json', {s: search}).pipe(
      map(r => r.body as Freighter[]))
      ;
  }

  getTiers(freighter: Freighter, deliverySchemas?: DeliverySchema[], point?: Point): Observable<any[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForList()}/tariff/tier.json`, {
        includeUnavailable: 'true',
        schemas: deliverySchemas ? deliverySchemas.map(s => s.id).join(',') : null,
        lat: point && point.lat,
        lon: point && point.lon
      }).pipe(
      map(r => r.body.tiers))
      ;
  }
}
