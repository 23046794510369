import {Injectable} from "@angular/core";

@Injectable()
export class TokenService {
  private _token: string|undefined;

  constructor() {
    if(localStorage)
      this._token = localStorage.getItem('token') || undefined;
  }

  get token(): string | undefined {
    return this._token;
  }

  set token(value: string | undefined) {
    this._token = value;

    if(localStorage) {
      if(value === undefined)
        localStorage.removeItem('token');
      else
        localStorage.setItem('token', value);
    }
  }
}
