<form (submit)="onSave()">
  <div class="form-group">
    <label>Тариф</label>
    <div>
      <select class="form-control" [(ngModel)]="selectedTier" name="selectedTier" (change)="onChangeTier()">
        <option value="{{ tier.identifier }}" *ngFor="let tier of tiers">{{ tier.name }}</option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label>Кузова</label>
    <div>
      <label *ngFor="let bodyData of tierBodies" class="checkbox-inline">
        <input type="checkbox" [(ngModel)]="bodyData.selected" name="{{ bodyData.identifier }}" (change)="onChangeBodySelection()"> {{ bodyData.name }}
      </label>
    </div>
  </div>
  <div class="form-group">
    <label>Длины</label>
    <div>
      <label *ngFor="let length of lengths" class="checkbox-inline">
        <input type="checkbox" [(ngModel)]="length.selected" name="{{ length.length }}" (change)="onChangeLengthSelection()"> {{ length.length }} м.
      </label>
    </div>
  </div>
  <table class="min-transport-params">
    <caption>Минимальные параметры транспорта</caption>
    <thead>
    <tr>
      <th>Грузоподъёмность, т</th>
      <th>Длина, м</th>
      <th>Ширина, м</th>
      <th>Высота, м</th>
      <th>Объём, м<sup>3</sup></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>
        <div class="form-group">
          <input class="form-control" [(ngModel)]="minCapacity" name="minCapacity">
        </div>
      </td>
      <td>
        <div class="form-group">
          <input class="form-control" [(ngModel)]="minLength" name="minLength">
        </div>
      </td>
      <td>
        <div class="form-group">
          <input class="form-control" [(ngModel)]="minWidth" name="minWidth">
        </div>
      </td>
      <td>
        <div class="form-group">
          <input class="form-control" [(ngModel)]="minHeight" name="minHeight">
        </div>
      </td>
      <td>
        <div class="form-group">
          <input class="form-control" [(ngModel)]="volume" name="volume">
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="form-group">
    <label>
      <input type="checkbox" [(ngModel)]="hydroElevator" name="hydroElevator"> Требуется гидролифт
    </label>
  </div>
  <div class="form-group">
    <button class="save-btn" type="submit">Сохранить</button>
  </div>
</form>
