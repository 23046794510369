
import {switchMap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {VoximplantService} from "../_services/voximplant.service";
import {UserService} from "../_services/user.service";
import {Observable, Subject, Subscription} from "rxjs";
import {UserLastData} from "../_models/users/user-last-data";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";

@Component({
  selector: 'voximplant-controls',
  templateUrl: './voximplant-controls.component.html',
  styleUrls: ['./voximplant-controls.component.css']
})
export class VoximplantControlsComponent implements OnInit, OnDestroy {
  callIsActive = false;
  activePhone: string;
  userData: UserLastData = new UserLastData();
  loading = false;
  unknownUser = true;
  accountPicture: string;

  private phonesStream: Subject<string>;
  private phonesStreamSubscription: Subscription;

  private flashTimer: any;
  private flashCount = 0;

  constructor(public voximplantService: VoximplantService, private userService: UserService, private alertService: AlertService) {
  }

  ngOnInit() {
    this.initPhonesStream();
    this.initPhonesStreamSubscription();
  }

  private initPhonesStreamSubscription() {
    this.phonesStreamSubscription = this.voximplantService
      .getPhonesObservable()
      .subscribe(
        phone => {
          if(phone != null) {
            this.callIsActive = true;
            this.activePhone = phone;
            this.phonesStream.next(phone);
            this.startTabFlash();
          } else {
            this.callIsActive = false;
            this.stopTabFlash();
          }
        }
      );
  }

  private startTabFlash(): void {
    this.stopTabFlash();

    this.flashTimer = setInterval(() => {
      let image = `/assets/images/ring/ring${(this.flashCount ++) % 2}.png`;
      document.getElementById('favicon').setAttribute('href', image);
    }, 1000);
  }

  private stopTabFlash(): void {
    if(this.flashTimer) {
      clearInterval(this.flashTimer);
      this.flashTimer = null;

      let image = `/favicon.ico`;
      document.getElementById('favicon').setAttribute('href', image);
    }
  }

  private initPhonesStream() {
    this.phonesStream = new Subject<string>();
    this.phonesStream.pipe(
      switchMap(phone => {
        this.userData = new UserLastData();
        this.loading = true;
        this.unknownUser = false;
        this.accountPicture = null;
        return this.userService.getUserLastDataByPhone(phone);
      }))
      .subscribe(
        data => {
          this.loading = false;
          this.userData = data;
          if(data.account && data.account.picture_sizes && data.account.picture_sizes["320"])
            this.accountPicture = `url(${data.account.picture_sizes["320"]})`;
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.loading = false;
            this.unknownUser = true;
            this.alertService.clear();
          }
          this.initPhonesStream();
        }
      )
    ;
  }

  ngOnDestroy(): void {
    this.phonesStreamSubscription.unsubscribe();
  }
}
