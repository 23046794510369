<div class="modal fade" tabindex="-1" role="dialog" #issuesDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Калькуляция заказа</h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="list" *ngIf="viewSession && !viewSession.loading">
          <table class="table table-condensed table-responsive table-striped">
            <thead>
            <tr>
              <th>Дата</th>
              <th>Причина</th>
              <th class="cost">Груз</th>
              <th class="cost">Скидка</th>
              <th class="cost">Доставка для клиента</th>
              <th class="cost">Доставка для магазина</th>
              <th class="cost">Доставка для перевозчика</th>
            </tr>
            </thead>
            <tbody *ngFor="let destinationCalculation of viewSession.calculation.destinationCalculations">
            <tr>
              <th colspan="8" (click)="switchDestinationDetailsVisible(destinationCalculation.destination)" class="destination-header">
                <span
                  class="glyphicon"
                  [class.glyphicon-triangle-bottom]="isDestinationDetailsVisible(destinationCalculation.destination)"
                  [class.glyphicon-triangle-right]="!isDestinationDetailsVisible(destinationCalculation.destination)"
                ></span>
                <span *ngIf="destinationCalculation.destination?.delivery_product?.code">[{{ destinationCalculation.destination.delivery_product.code }}]</span> {{ destinationCalculation.destination.destination.addr }}
              </th>
            </tr>
            <ng-template [ngIf]="isDestinationDetailsVisible(destinationCalculation.destination)">
            <tr *ngFor="let record of destinationCalculation.historyRecords">
              <td class="date">{{ record.reason.created_at|dt:"dd.MM.yy HH:mm" }}</td>
              <td>
                <div class="title">{{ record.reason.title }}</div>
                <div class="comment">{{ record.reason.comment }}</div>
                <div *ngIf="record.reason.reason_code == 'setup'" class="setup">Исходная стоимость</div>
                <div class="meta-data" *ngIf="record.reason.meta_data">
                  <div *ngIf="record.reason.reason_code != 'remove_product_lines'">
                    <div class="code" *ngIf="record.reason.meta_data['code']">Заказ: {{ record.reason.meta_data['code'] }}</div>
                    <div class="count" *ngIf="record.reason.meta_data['count']">Количество: {{ record.reason.meta_data['count'] }}</div>
                  </div>
                  <div *ngIf="record.reason.reason_code == 'remove_product_lines' && record.reason.meta_data">
                    <div *ngFor="let item of record.reason.meta_data|mapToIterable">
                      <div class="code">Артикул: {{ item.key }}</div>
                      <div class="count">Количество: {{ item.val }}</div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="cost">{{ record.cargo_cost|humanNumber }} ₽</td>
              <td class="cost">{{ record.cargo_discount|humanNumber }} ₽</td>
              <td class="cost">{{ record.delivery_cost_for_client|humanNumber }} ₽</td>
              <td class="cost">{{ record.delivery_cost_for_shop|humanNumber }} ₽</td>
              <td class="cost">{{ record.delivery_cost_for_freighter|humanNumber }} ₽</td>
            </tr>
            </ng-template>
            <tr class="destination-total">
              <td></td>
              <td>Итого:</td>
              <td class="cost">{{ destinationCalculation.total.cargo_cost|humanNumber }} ₽</td>
              <td class="cost">{{ destinationCalculation.total.cargo_discount|humanNumber }} ₽</td>
              <td class="cost">{{ destinationCalculation.total.delivery_cost_for_client|humanNumber }} ₽</td>
              <td class="cost">{{ destinationCalculation.total.delivery_cost_for_shop|humanNumber }} ₽</td>
              <td class="cost">{{ destinationCalculation.total.delivery_cost_for_freighter|humanNumber }} ₽</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td></td>
              <td>Итого:</td>
              <td class="cost">{{ viewSession.calculation.total.cargo_cost|humanNumber }} ₽</td>
              <td class="cost">{{ viewSession.calculation.total.cargo_discount|humanNumber }} ₽</td>
              <td class="cost">{{ viewSession.calculation.total.delivery_cost_for_client|humanNumber }} ₽</td>
              <td class="cost">{{ viewSession.calculation.total.delivery_cost_for_shop|humanNumber }} ₽</td>
              <td class="cost">{{ viewSession.calculation.total.delivery_cost_for_freighter|humanNumber }} ₽</td>
            </tr>
            </tfoot>
          </table>
        </div>

        <div *ngIf="viewSession?.loading" class="empty-list">
          <div class="message">Загружается {{ viewSession.loadingProgress }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
