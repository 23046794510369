
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Message} from "../_models/message";
import {UsersFilter} from "../_models/users/users-filter";
import {UserStat} from "../_models/user-stat";
import {UserService} from "../_services/user.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit, OnDestroy {
  @ViewChild('messageDialog', { static: true }) dialogEl: ElementRef;

  title: string;
  message = new Message();

  private isFilterMode: boolean;
  private filter: UsersFilter;
  private users: UserStat[];

  private wasShown = false;
  private modalWasInit = false;

  constructor(
    private userService: UserService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
  }

  private showDialog(title: string) {
    this.title = title;
    $(this.dialogEl.nativeElement).modal('show');

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  showDialogForFilter(title: string, filter: UsersFilter, messageType: string) {
    this.isFilterMode = true;
    this.filter = filter;
    this.message.type = messageType;

    this.showDialog(title);
  }

  showDialogForUsers(title: string, users: UserStat[], messageType: string) {
    this.isFilterMode = false;
    this.users = users;
    this.message.type = messageType;

    this.showDialog(title);
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private sendMessageByFilter() {
    this.loaderService.show();
    this.hideDialog();
    this.userService
      .sendMessageByFilter(this.message, this.filter).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        r => {
          let recipientsCount = r.body.recipientsCount;
          this.alertService.success(`Рассылка создана для ${recipientsCount} пользователей`);
        },
        e => {
          if(e instanceof HttpErrorResponse) {
            let message = e.error.message;
            this.alertService.error(`Произошла ошибка при создании рассылки (${message})`)
          }
        }
      )
    ;
  }

  private sendMessageByUsers() {
    this.loaderService.show();
    this.hideDialog();
    this.userService
      .sendMessageByUsers(this.message, this.users).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        r => {
          let recipientsCount = r.body.recipientsCount;
          this.alertService.success(`Рассылка создана для ${recipientsCount} пользователей`);
        },
        e => {
          if(e instanceof HttpErrorResponse) {
            let message = e.error.message;
            this.alertService.error(`Произошла ошибка при создании рассылки (${message})`)
          }
        }
      )
    ;
  }

  private sendMessage() {
    if(this.isFilterMode)
      this.sendMessageByFilter();
    else
      this.sendMessageByUsers();
  }

  onSendMessage() {
    this.sendMessage();
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
