
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CompanyClientServiceService} from "../_services/company-client-service.service";
import {CompanyClient} from "../_models/company-client";
import {CompanyClientService} from "../_models/company-client-service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";

@Component({
  selector: 'add-service-issue-form',
  templateUrl: './add-service-issue-form.component.html',
  styleUrls: ['./add-service-issue-form.component.css']
})
export class AddServiceIssueFormComponent implements OnInit, OnChanges {
  @Input() companyClient: CompanyClient;
  @Output() onApplyService = new EventEmitter<CompanyClientService>();

  services: CompanyClientService[] = [];
  serviceId: number;
  serviceCode: string;
  serviceName: string;
  priceForClient: string = '0';
  priceForDriver: string = '0';
  servicesCount: number = 1;

  constructor(private companyClientServiceService: CompanyClientServiceService, private loaderService: LoaderService, private alertService: AlertService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initServices();
  }

  getMetadata(): any {
    return {
      code: this.serviceCode,
      name: this.serviceName,
      delivery_cost_for_client: this.filterSum(this.priceForClient),
      delivery_cost_for_freighter: this.filterSum(this.priceForDriver),
      count: this.servicesCount
    };
  }

  isValid(): boolean {
    let errors: string[] = [];

    if(!this.serviceCode || this.serviceCode.trim() == '')
      errors.push("не указан артикул");
    if(!this.serviceName || this.serviceName.trim() == '')
      errors.push("не указано наименование");
    if(this.servicesCount < 1)
      errors.push("количество услуг должно быть больше одной");

    if(errors.length > 0)
      this.alertService.warning("Обнаружены ошибки: " + errors.join(', '));

    return errors.length == 0;
  }

  private initServices(): void {
    this.services = [];
    if(!this.companyClient)
      return;

    this.loaderService.show();
    this.companyClientServiceService
      .getServices(this.companyClient).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        services => this.services = services,
        () => {}
      )
    ;
  }

  private applyService(service: CompanyClientService): void {
    this.serviceCode = service.code;
    this.serviceName = service.name;
    this.priceForClient = service.price_for_client + "";
    this.priceForDriver = service.price_for_driver + "";
    this.servicesCount = 1;

    this.onApplyService.emit(service);
  }

  private applyServiceId(serviceId: number): void {
    let service = this.services.find(s => s.id == serviceId);
    if(service)
      this.applyService(service);
  }

  private filterSum(sum: string): number {
    sum = sum.trim().replace(' ', '').replace(',', '.');
    return parseFloat(sum) || 0;
  }

  onChangeServiceId(): void {
    this.applyServiceId(this.serviceId);
  }
}
