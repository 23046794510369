/**
 * Параметры транспорта, используемые для фильтрации контента на "экране"
 */
export class ScreenTransportParams {
  tier: string;
  bodies: string[];
  lengths: number[];
  loaders: number;
  hydroElevator: boolean;
  ranges: any;
}
