import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from "../_models/account";

@Component({
  selector: 'client-editor',
  templateUrl: './client-editor.component.html',
  styleUrls: ['./client-editor.component.css']
})
export class ClientEditorComponent implements OnInit {
  @Input() client: Account;
  @Output() onUpdated = new EventEmitter<Account>();

  name: string;
  surname: string;
  patronymic: string;
  phone: string;

  phoneIsValid: boolean;
  nameIsValid: boolean;
  isValid: boolean;

  constructor() { }

  ngOnInit() {
    this.name = this.client.name;
    this.surname = this.client.surname;
    this.patronymic = this.client.patronymic;
    this.phone = this.client.phone;

    this.validate();
  }

  private validate() {
    this.phoneIsValid = this.validatePhone();
    this.nameIsValid = this.validateName();

    this.isValid = this.phoneIsValid && this.nameIsValid;
  }

  private validatePhone(): boolean {
    return this.phone && this.phone.match(/^7[0-9]{10}$/) != null;
  }

  private validateName(): boolean {
    return this.name && this.name.trim() !== '';
  }

  onSave() {
    this.client.name = this.name;
    this.client.surname = this.surname;
    this.client.patronymic = this.patronymic;
    this.client.phone = this.phone;

    this.onUpdated.emit(this.client);
  }

  onChangePhone(newVal: string) {
    this.validate();
  }

  onChangeName(newVal: string) {
    this.validate();
  }
}
