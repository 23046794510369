<div class="modal fade" tabindex="-1" role="dialog" #messageDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">{{ title }}</h4>
        </div>
      </div>
      <div class="modal-body">
        <message-form [message]="message" (onSendMessage)="onSendMessage()"></message-form>
      </div>
    </div>
  </div>
</div>
