import {Committable} from "./committable";

export class CommitCollection implements Committable {
  private committables: Committable[] = [];

  commit() {
    this.committables.forEach(committable => committable.commit());
  }

  register(committable: Committable) {
    this.committables.push(committable);
  }

  unregister(committable: Committable) {
    let index = this.committables.indexOf(committable);
    console.log(`unregister ${index} committer`);
    this.committables.splice(index, 1);
  }

  hasCommittables(): boolean {
    return this.countCommittables() > 0;
  }

  countCommittables(): number {
    return this.committables.length;
  }
}
