
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {OrderDraft} from "../_models/order-draft";
import {Observable} from "rxjs";
import {SearchEmployeeStatus} from "../_models/search-employee-status";

@Injectable()
export class SearchEmployeeStatusService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getStatuses(draft: OrderDraft): Observable<SearchEmployeeStatus[]> {
    return this._requestService
      .get(`/support/me/orders/drafts/${draft.id}/employee/search/status.json`).pipe(
      map(r => r.body.statuses as SearchEmployeeStatus[]))
    ;
  }
}
