import {Injectable} from "@angular/core";
import {RequestService} from "./request.service";
import {UserInfo} from "../_models/user-info";
import {Account} from "../_models/account";
import {Subject, Observable, of} from "rxjs";
import {IntercomService} from './intercom.service'
import {Freighter} from "../_models/freighter";
import {TokenService} from "./token.service";
import {map} from "rxjs/operators";

@Injectable()
export class UserInfoService {
  private _userInfo: UserInfo = null;
  private _token: string = null;

  private loaded = false;
  private onLoadResult = new Subject<boolean>();
  private onUserLogin = new Subject<UserInfo>();


  constructor(private tokenService: TokenService, private requestService: RequestService, private _intercomService: IntercomService) {
  }

  isPresent(): boolean {
    return this._userInfo !== null;
  }

  isPresentLazy(): Observable<boolean> {
    if (!this.loaded)
      console.log('User info not loaded');

    return this.loaded ? of(this.isPresent()) : this.onLoadResult.asObservable();
  }

  getUserLoginSubject(): Observable<UserInfo> {
    return this.onUserLogin.asObservable();
  }

  clear() {
    this._userInfo = null;
  }

  setup(): Promise<void> {
    console.log('Setup user info...');
    this.clear();
    this.loaded = false;
    return this.requestService.get('/token.json').toPromise()
      .then(r => r.body.token)
      .then(token => {
        this._token = token;
        this.tokenService.token = token;
      })
      .then(() => this.requestService.get('/me.json').toPromise())
      .then(r => {
        let data = r.body;
        let userInfo = new UserInfo();
        userInfo.role = data.role;
        userInfo.account = data.account as Account;

        console.log('User info loaded.');
        console.log(userInfo.role);
        return userInfo;
      })
      .then(user => {
        if (['ROLE_COMPANY_MANAGER', 'ROLE_COMPANY', 'ROLE_DRIVER_MANAGER', 'ROLE_SUPPORT', 'ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_DELIVERY_MANAGER', 'ROLE_PARTNER_MANAGER'].indexOf(user.role) === -1)
          throw new Error('У вас нет доступа.');
        console.log('User has access.');
        this._userInfo = user;
      })
      .then(() => {
        this.loaded = true;
        this.onLoadResult.next(true);
        console.log('user logged in ->',this.userInfo);
        this.onUserLogin.next(this.userInfo);
        // this._intercomService.boot(this.userInfo.account);
      })
      .catch(e => {
        this.clear();
        this.loaded = true;
        this.onLoadResult.next(false);

        console.log('Setup of user info has not success.');

        throw e;
      });
  }

  reload(): Observable<UserInfo> {
    return this
      .requestService.get('/me.json')
      .pipe(
        map(r => {
          let data = r.body;
          let userInfo = new UserInfo();
          userInfo.role = data.role;
          userInfo.account = data.account as Account;

          this._userInfo  = userInfo;

          return userInfo;
        })
      );

  }

  isPrivilegedUser(): boolean {
    return this.userInfo != null && ['ROLE_SUPPORT', 'ROLE_ADMIN'].indexOf(this.userInfo.role) >= 0;
  }

  isSupportUser(): boolean {
    return this.userInfo != null && this.userInfo.role == 'ROLE_SUPPORT';
  }

  isSuperUser(): boolean {
    return this.userInfo != null && this.userInfo.role == 'ROLE_ADMIN';
  }

  isFreighter(): boolean {
    return this.userInfo != null && ['ROLE_COMPANY', 'ROLE_COMPANY_MANAGER', 'ROLE_DRIVER_MANAGER'].indexOf(this.userInfo.role) >= 0;
  }

  isSimpleCustomer(): boolean {
    return this.userInfo?.role == 'ROLE_CUSTOMER';
  }

  isCustomer(): boolean {
    return this.userInfo != null && ['ROLE_CUSTOMER', 'ROLE_DELIVERY_MANAGER', 'ROLE_PARTNER_MANAGER'].indexOf(this.userInfo.role) > -1;
  }

  isDeliveryManager(): boolean {
    return this.userInfo != null && ['ROLE_DELIVERY_MANAGER', 'ROLE_COMPANY_MANAGER', 'ROLE_PARTNER_MANAGER'].indexOf(this.userInfo.role) > -1;
  }

  isFreighterManager(): boolean {
    return this.userInfo != null && ['ROLE_COMPANY_MANAGER', 'ROLE_DRIVER_MANAGER'].indexOf(this.userInfo.role) > -1;
  }

  isCompanyClientManager(): boolean {
    return this.userInfo != null && ['ROLE_PARTNER_MANAGER'].indexOf(this.userInfo.role) > -1;
  }

  isAvailableRouteOptimization(): boolean {
    return this.isDeliveryManager() && this.userInfo.account.company_client && this.userInfo.account.company_client.available_route_optimization;
  }

  isAvailableCustomRoutes(): boolean {
    return this.isDeliveryManager() && this.userInfo.account.company_client && this.userInfo.account.company_client.available_custom_routes;
  }

  isAvailableOwnTariffs(): boolean {
    return this.userInfo.account.freighter && this.userInfo.account.freighter.own_tariffs
      || this.userInfo.account.freighter_manager && this.userInfo.account.freighter_manager.freighter.own_tariffs;
  }

  areIncomingCallsAllowed(): boolean {
    return this.isPrivilegedUser() && this.userInfo.account.are_incoming_calls_allowed;
  }

  get userInfo(): UserInfo {
    return this._userInfo;
  }

  get token(): string {
    return this._token;
  }

  getUserId(): number {
    return this._userInfo.account.id;
  }

  getUserName() {
    return this._userInfo.account.name;
  }

  getFreighter(): Freighter|null {
    return this._userInfo.account.freighter
      || (this._userInfo.account.freighter_manager && this._userInfo.account.freighter_manager.freighter)
      || null;
  }
}
