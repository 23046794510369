import {Pipe, PipeTransform} from "@angular/core";
import {EXECUTOR_ENTITY_TYPES} from "../_maps/executor-entity-types";

@Pipe({name: "executorEntityType"})
export class ExecutorEntityTypePipe implements PipeTransform {
  transform(value: string): string {
    return EXECUTOR_ENTITY_TYPES[value] || '';
  }

}
