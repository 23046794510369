import {Component, Input, OnInit} from '@angular/core';
import {DeliverySchedule} from "../_models/delivery-schedule";
import {DateTime} from "date-time-js";
import {City} from "../_models/city";
import {DateUtils} from "../_utils/date-utils";

class Position {
  constructor(private _value: string|Date, private _highlight: boolean = false) {}

  get value(): string|Date {
    return this._value;
  }

  get highlight(): boolean {
    return this._highlight;
  }

  get isDate(): boolean {
    return this._value instanceof Date;
  }
}

@Component({
  selector: 'schedule-view',
  templateUrl: './schedule-view.component.html',
  styleUrls: ['./schedule-view.component.css']
})
export class ScheduleViewComponent implements OnInit {
  @Input() schedule: DeliverySchedule;
  @Input() city: City;

  positions: Position[] = [];

  constructor() { }

  ngOnInit() {
    this.addPosition('Повторять ');

    switch(this.schedule.type) {
      case 'once':
        this.prepareForOnce();
        break;
      case 'everyday':
        this.prepareForEveryday();
        break;
      case 'every_week':
        this.prepareForEveryWeek();
        break;
      case 'every_month':
        this.prepareForEveryMonth();
        break;
    }
    console.log(this.schedule.type);
  }

  private prepareForOnce() {
    this.addPosition('один раз ', true);
    this.addPosition(new Date(this.schedule.start), true);
    this.prepareCommonData(false);
  }

  private prepareForEveryday() {
    switch(this.schedule.mode) {
      case 'weekdays':
        this.addPosition('по будням', true);
        break;
      case 'every_day':
        if(this.schedule.period == 1)
          this.addPosition('каждый день', true);
         else
          this.addPosition(`каждый ${this.schedule.period}-й день`, true);
        break;
    }

    this.prepareCommonData();
  }

  private prepareForEveryWeek() {
    if(this.schedule.period == 1)
      this.addPosition('каждую неделю', true);
    else
      this.addPosition(`каждую ${this.schedule.period}-ю неделю`, true);

    let weekDays = [
      'воскресеньям',
      'понедельникам',
      'вторникам',
      'средам',
      'четвергам',
      'пятницам',
      'субботам'
    ];

    this.addPosition(' по ');

    let mask = 1;
    let first = true;
    for(let i = 0; i < 7; i ++) {
      if((this.schedule.week_days & mask) != 0) {
        if(!first)
          this.addPosition(', ');
        else
          first = false;

        this.addPosition(weekDays[i], true);
      }
      mask <<= 1;
    }

    this.prepareCommonData();
  }

  private prepareForEveryMonth() {
    if(this.schedule.period == 1)
      this.addPosition('каждый месяц', true);
    else
      this.addPosition(`каждый ${this.schedule.period}-й месяц`, true);

    let weekDays = [
      'воскресеньям',
      'понедельникам',
      'вторникам',
      'средам',
      'четвергам',
      'пятницам',
      'субботам'
    ];

    switch(this.schedule.mode) {
      case 'by_date':
        this.addPosition(` по ${this.schedule.day} числам`, true);
        break;
      case 'by_week_day':
        this.addPosition(` по ${this.schedule.week_period}-м ${weekDays[this.schedule.week_day]}`, true);
        break;
    }

    this.prepareCommonData();
  }

  private prepareCommonData(withStartDate: boolean = true) {
    if(withStartDate) {
      this.addPosition(', начиная с ');
      this.addPosition(new Date(this.schedule.start), true);
    }

    if(this.schedule.end != null) {
      this.addPosition(', до ');
      this.addPosition(new Date(this.schedule.end), true);
    }

    this.addPosition('. Прибытие на склад к ');
    this.addPosition(new DateTime(DateUtils.offsetTimeByCityTimeZone(new Date(this.schedule.storehouse_time), this.city)).format('HH:mm'), true);
    this.addPosition('. Поиск за ');
    this.addPosition(this.schedule.search_hours_before.toString(), true);
    this.addPosition(' час. до прибытия.');

  }

  private addSpace() {
    this.positions.push(new Position(' '));
  }

  private addPosition(value: string|Date, highlight: boolean = false) {
    this.positions.push(new Position(value, highlight));
  }
}
