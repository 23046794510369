
import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {FreighterService} from "../_services/freighter.service";
import {EmployeeService} from "../_services/employer.service";
import {Employer} from "../_models/employer";
import {Freighter} from "../_models/freighter";
import {UserInfoService} from "../_services/user-info.service";
import {CrewService} from "../_services/crew.service";
import {Crew} from "../_models/crew";

/**
 * Минимальный размер строки для инициации поиска
 */
const MIN_STR_LEN_TO_SEARCH = 3;

@Component({
  selector: 'freighters-fast-list-dialog',
  templateUrl: './freighters-fast-list-dialog.component.html',
  styleUrls: ['./freighters-fast-list-dialog.component.css']
})
export class FreightersFastListDialogComponent implements OnInit, OnDestroy {
  @Input() singleSelectMode = false;

  @ViewChild('freightersDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild('searchStringInput', { static: true }) searchStringEl: ElementRef;

  @Output() savedFreighters = new EventEmitter<Freighter[]>();
  @Output() savedEmployers = new EventEmitter<Employer[]>();
  @Output() savedCrews = new EventEmitter<Crew[]>();

  searchString: string;

  freighters: Freighter[] = [];
  employers: Employer[] = [];
  crews: Crew[] = [];

  selectedFreighters: Map<number, Freighter> = new Map();
  selectedEmployers: Map<number, Employer> = new Map();
  selectedCrews: Map<number, Crew> = new Map();

  enabledFreighters = true;
  enabledEmployers = true;
  enabledCrews = true;

  searchPlaceholder = '';

  private searchStream: Subject<string>;
  private searchFreightersStream: Subject<string>;
  private searchEmployersStream: Subject<string>;
  private searchCrewsStream: Subject<string>;

  private wasShown = false;
  private modalWasInit = false;

  constructor(
    private freighterService: FreighterService,
    private employeeService: EmployeeService,
    private crewService: CrewService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit() {
    this.initFreighters();
    this.initEmployers();
    this.initCrews();
    this.initPlaceholder();
    this.initSearchStream();
    this.initSearchFreightersStream();
    this.initSearchEmployersStream();
    this.initSearchCrewsStream();
  }

  private initFreighters(): void {
    this.enabledFreighters = !this.userInfoService.isFreighter() && !this.userInfoService.isDeliveryManager() && !this.singleSelectMode;
  }

  private initEmployers(): void {
    this.enabledEmployers = this.userInfoService.isFreighter() || this.userInfoService.isDeliveryManager() || this.userInfoService.isPrivilegedUser();
  }

  private initCrews(): void {
    this.enabledCrews = !this.userInfoService.isDeliveryManager();
  }

  private initPlaceholder(): void {
    this.searchPlaceholder = this.enabledFreighters
      ? 'Название компании, фамилия водителя, телефон'
      : 'Фамилия водителя, телефон';
  }

  private initSearchStream(): void {
    this.searchStream = new Subject<string>();
    this.searchStream.pipe(
      debounceTime(500),
      distinctUntilChanged(),)
      .subscribe(
        s => {
          if(s.length >= MIN_STR_LEN_TO_SEARCH) {
            this.searchFreightersStream.next(s);
            this.searchEmployersStream.next(s);
            this.searchCrewsStream.next(s);
          } else {
            this.freighters = [];
            this.employers = [];
            this.crews = [];
          }
        }
      )
    ;
  }

  private initSearchFreightersStream(): void {
    this.searchFreightersStream = new Subject<string>();

    if(this.enabledFreighters) {
      this.searchFreightersStream.pipe(
        switchMap(s => this.freighterService.getFastList(s)))
        .subscribe(
          freighters => this.freighters = freighters,
          () => this.initSearchFreightersStream()
        );
    }
  }

  private initSearchEmployersStream(): void {
    this.searchEmployersStream = new Subject<string>();

    if(this.enabledEmployers) {
      this.searchEmployersStream.pipe(
        switchMap(s => {
          if(this.userInfoService.isFreighter())
            return this.employeeService.getFastListForFreighter(this.userInfoService.getFreighter(), s);
          if(this.userInfoService.isPrivilegedUser())
            return this.employeeService.getFastList(s);

          return this.employeeService.getFastListForCustomer(s);
        }))
        .subscribe(
          employers => this.employers = employers,
          () => this.initSearchEmployersStream()
        );
    }
  }

  private initSearchCrewsStream(): void {
    this.searchCrewsStream = new Subject<string>();

    if(this.enabledCrews) {
      this.searchCrewsStream.pipe(
        switchMap(s => {
          if(this.userInfoService.isPrivilegedUser())
            return this.crewService.getFastList(s);

          return this.crewService.getFastListForFreighter(this.userInfoService.getFreighter(), s);
        }))
        .subscribe(
          crews => this.crews = crews,
          () => this.initSearchCrewsStream()
        );
    }
  }

  showDialog(freighters: Freighter[] = [], employers: Employer[] = [], crews: Crew[] = []) {
    $(this.dialogEl.nativeElement).modal('show');
    setTimeout(() => {
      this.searchStringEl.nativeElement.focus();
    }, 750);

    this.selectedFreighters = new Map();
    freighters.forEach(f => this.selectedFreighters.set(f.id, f));

    this.selectedEmployers = new Map();
    employers.forEach(e => this.selectedEmployers.set(e.id, e));

    this.selectedCrews = new Map();
    crews.forEach(e => this.selectedCrews.set(e.id, e));

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private addFreighter(freighter: Freighter): void {
    this.selectedFreighters.set(freighter.id, freighter);
    this.selectedFreighters = new Map(this.selectedFreighters);
  }

  private addEmployer(employer: Employer): void {
    if(this.singleSelectMode)
      this.selectedEmployers.clear();

    this.selectedEmployers.set(employer.id, employer);
    this.selectedEmployers = new Map(this.selectedEmployers);
  }

  private addCrew(crew: Crew): void {
    if(this.singleSelectMode)
      this.selectedCrews.clear();

    this.selectedCrews.set(crew.id, crew);
    this.selectedCrews = new Map(this.selectedCrews);
  }

  private removeFreighter(freighter: Freighter): void {
    this.selectedFreighters.delete(freighter.id);
    this.selectedFreighters = new Map(this.selectedFreighters);
  }

  private removeEmployer(employer: Employer): void {
    this.selectedEmployers.delete(employer.id);
    this.selectedEmployers = new Map(this.selectedEmployers);
  }

  private removeCrew(crew: Crew): void {
    this.selectedCrews.delete(crew.id);
    this.selectedCrews = new Map(this.selectedCrews);
  }

  private save(): void {
    this.saveFreighters();
    this.saveEmployers();
    this.saveCrews();
  }

  private saveFreighters(): void {
    let freighters: Freighter[] = [];
    this.selectedFreighters.forEach(f => freighters.push(f));
    this.savedFreighters.emit(freighters);
  }

  private saveEmployers(): void {
    let employers: Employer[] = [];
    this.selectedEmployers.forEach(e => employers.push(e));
    this.savedEmployers.emit(employers);
  }

  private saveCrews(): void {
    let crews: Crew[] = [];
    this.selectedCrews.forEach(e => crews.push(e));
    this.savedCrews.emit(crews);
  }

  isSelectedFreighter(freighter: Freighter): boolean {
    return this.selectedFreighters.has(freighter.id);
  }

  isSelectedEmployer(employer: Employer): boolean {
    return this.selectedEmployers.has(employer.id);
  }

  isSelectedCrew(crew: Crew): boolean {
    return this.selectedCrews.has(crew.id);
  }

  onChangeSearchString(): void {
    this.searchStream.next(this.searchString);
  }

  onSelectFreighter(freighter: Freighter): void {
    if(this.isSelectedFreighter(freighter))
      this.removeFreighter(freighter)
    else
      this.addFreighter(freighter);
  }

  onSelectEmployer(employer: Employer): void {
    if(this.isSelectedEmployer(employer) && !this.singleSelectMode)
      this.removeEmployer(employer)
    else
      this.addEmployer(employer);
  }

  onSelectCrew(crew: Crew): void {
    if(this.isSelectedCrew(crew) && !this.singleSelectMode)
      this.removeCrew(crew)
    else
      this.addCrew(crew);
  }

  onDeselectFreighter(freighter: Freighter): void {
    this.removeFreighter(freighter);
  }

  onDeselectEmployer(employer: Employer): void {
    this.removeEmployer(employer);
  }

  onDeselectCrew(crew: Crew): void {
    this.removeCrew(crew);
  }

  onSave(): void {
    this.save();
    this.hideDialog();
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
