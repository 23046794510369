import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Account} from "../_models/account";
import {Observable} from "rxjs";
import {DateTime} from "date-time-js";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class ReportService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  sendBalanceReport(account: Account, periodStart: Date, periodEnd: Date, forceRecipient?: string): Observable<HttpResponse<any>> {
    let start = new DateTime(periodStart).format('yyyy-MM-dd HH:mm:ss');
    let end = new DateTime(periodEnd).format('yyyy-MM-dd HH:mm:ss');

    return this._requestService
      .post(`/accounts/${account.id}/report/balance/send.json`, {
        start, end, forceRecipient
      });
  }
}
