<div class="modal fade"
     tabindex="-1" role="dialog" id="edit-crew-modal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">

        <span class="modal-title">Изменить экипаж</span>
        <span class="modal-sub-title"
        [class.modal-sub-title-active]="onlyActive == true">Только свободные </span>
          <label class="switch">
            <input type="checkbox" [(ngModel)]="onlyActive">
            <div class="slider round"></div>
          </label>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="material-icons">close</i></button>
      </div>

      <div class="modal-body">
        <crews-list [freighter]="order.freighter" [employer]="employer" (onSelect)="onSelectCrew($event)" [onlyActive]="onlyActive"></crews-list>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
