import {UploadUnit} from "./upload-unit";
import {UploadError} from "./upload-error";
import {CompleteAllUploadsHandler} from "./complete-all-uploads-handler";
import {FileErrorHandler} from "./file-error-handler";
import {UpdateUploadStatusHandler} from "./update-upload-status-handler";
import {UploadStatus} from "./upload-status";

export class UploadChain implements CompleteAllUploadsHandler, FileErrorHandler {
  private uploadUnits: Map<string, UploadUnit> = new Map();
  private uploadErrors: Map<string, UploadError> = new Map();
  private uploadQueue: string[];
  private currentUnit: string;
  private completeUploadHandler: CompleteAllUploadsHandler;
  private updateUploadStatusHandler: UpdateUploadStatusHandler;

  addUnit(identifier: string, unit: UploadUnit): void {
    this.uploadUnits.set(identifier, unit);
  }

  getUnit(identifier: string): UploadUnit {
    let unit = this.uploadUnits.get(identifier);

    if(unit == undefined)
      throw new Error(`Unit ${identifier} was not found`);

    return unit;
  }

  getUploadError(identifier: string): UploadError|undefined {
    return this.uploadErrors.get(identifier);
  }

  upload(completeUploadHandler: CompleteAllUploadsHandler, updateUploadStatusHandler?: UpdateUploadStatusHandler): void {
    this.completeUploadHandler = completeUploadHandler;
    this.updateUploadStatusHandler = updateUploadStatusHandler;

    this.uploadErrors.clear();
    this.uploadQueue = Array.from(this.uploadUnits.keys());
    this.uploadNext();
  }

  hasErrors(): boolean {
    return this.uploadErrors.size > 0;
  }

  hasError(identifier: string): boolean {
    return this.uploadErrors.has(identifier);
  }

  private uploadNext(): void {
    if(this.uploadQueue.length == 0) {
      this.completeUpload();
    } else {
      this.currentUnit = this.uploadQueue.shift();

      if(this.updateUploadStatusHandler) {
        this.updateUploadStatusHandler.onUpdateUploadStatus(
          new UploadStatus(this.currentUnit,this.uploadUnits.size - this.uploadQueue.length, this.uploadUnits.size)
        )
      }

      let unit = this.getUnit(this.currentUnit);
      console.log(`Unit ${this.currentUnit} is ` + (unit.readyForUpload ? '' : 'not ') + 'ready for upload');
      if(unit.readyForUpload)
        unit.upload(this, this);
      else
        this.uploadNext();
    }
  }

  private completeUpload(): void {
    if(this.completeUploadHandler)
      this.completeUploadHandler.onCompleteAllUploads();
  }

  onCompleteAllUploads() {
    if(!this.hasError(this.currentUnit)) {
      this.getUnit(this.currentUnit).readyForUpload = false;
      this.getUnit(this.currentUnit).uploader.clearQueue();
    }

    this.uploadNext();
  }

  onFileUploadError(error: UploadError) {
    this.uploadErrors.set(this.currentUnit, error);
  }
}
