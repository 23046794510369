<form (submit)="onSave()">
  <div class="form-flex">
    <div class="form-group">
      <select name="city" [(ngModel)]="selected">
        <option [value]="c.id" *ngFor="let c of cities">{{ c.name }}</option>
      </select>
    </div>
  </div>
  <button type="submit" class="save-btn">Сохранить</button>
</form>
