<div class="modal fade" tabindex="-1" role="dialog" #productDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Описание груза</h4>
        </div>
      </div>
      <form (submit)="onSave()">
      <div class="modal-body">
        <delivery-product-editor *ngIf="deliveryProduct" [deliveryProduct]="deliveryProduct"></delivery-product-editor>
      </div>
      <div class="panel-footer">
        <button class="save-btn" type="submit">Сохранить</button>
        <button class="reject-btn" type="button" data-dismiss="modal">Отменить</button>
      </div>
      </form>
    </div>
  </div>
</div>
