<div class="form-inline filter-controls">
  <div class="form-group search-box">
    <input
      type="text"
      class="interval-search filter-control"
      name="from"
      [(ngModel)]="from"
      (ngModelChange)="onChangeFilter()"
      placeholder="от"
      size="5">
  </div>
  <div class="form-group search-box">
    <input
      type="text"
      class="interval-search filter-control"
      name="to"
      [(ngModel)]="to"
      (ngModelChange)="onChangeFilter()"
      placeholder="до"
      size="5">
  </div>
  <div class="form-group search-box">
    <div class="pretty selector">
      <input type="checkbox" name="including" [(ngModel)]="including" (ngModelChange)="onChangeFilter()">
      <label><i class="g-mdi" data-icon="done"></i> включая</label>
    </div>
  </div>
</div>
