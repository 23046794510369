<div class="modal fade" tabindex="-1" role="dialog" #employeeDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Выбор водителя</h4>
        </div>
      </div>
      <div class="modal-body">
        <employee-selector [freighter]="freighter" [date]="date" (selected)="onSelected($event)"></employee-selector>
      </div>
    </div>
  </div>
</div>
