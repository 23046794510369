
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {CompanyClient} from "../_models/company-client";
import {Observable} from "rxjs";
import {CompanyClientService} from "../_models/company-client-service";

@Injectable()
export class CompanyClientServiceService {
  constructor(private _requestService: RequestWithErrorHandlerService) {
  }

  getServices(company: CompanyClient): Observable<CompanyClientService[]> {
    return this._requestService
      .get(`/company_clients/${company.id}/services.json`).pipe(
      map(r => r.body as CompanyClientService[]))
      ;

  }
}
