
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Order} from "../_models/order";
import {OrderPeriodHistoryRow} from "../_models/order-period-history-row";
import {EXECUTION_STATUSES} from "../_maps/execution-statuses";
import {TaximeterService} from "../_services/taximeter.service";
import {OrderService} from "../_services/order.service";
import {AlertService} from "../_services/alert.service";
import {LoaderService} from "../_services/loader.service";
import {OrderPeriod} from "../_models/order-period";
import {UserInfoService} from "../_services/user-info.service";
import {OrderDraftService} from "../_services/order-draft.service";
import {DateUtils} from "../_utils/date-utils";

const TAXIMETER_UPDATE_PERIOD = 30000;

@Component({
  selector: 'order-status-panel',
  templateUrl: './order-status-panel.component.html',
  styleUrls: ['./order-status-panel.component.css']
})
export class OrderStatusPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() order: Order;
  @Input() expandBriefPanel: boolean;
  @Output() onUpdated = new EventEmitter<Order>();

  status: string;
  periodStatus: string;
  period: OrderPeriod;
  time: string;
  distance: any;
  outMkadDistance: any;
  cost: string;
  history: OrderPeriodHistoryRow[];
  executionStatus: string = 'moved_to_order';
  enabled: boolean;
  enabledRevert = true;
  enabledUseCalculatedPath = false;
  enabledRollback = false;
  useCalculatedPath = false;
  statusDate: Date|null = null;
  withStatusDateControl = false;

  datePickerOptions = {};
  timePickerOptions = {};

  enabledButtons = {
    restart: false,
    stop: false,
    pause: false,
    play: false
  };

  activeButtons = {
    restart: false,
    stop: false,
    pause: false,
    play: false
  };

  visibleButtons = {
    stop: false,
    stopScheduled: false
  }

  private taximeterTimer: any;
  private taximeterState: boolean = true;

  constructor(private taximeterService: TaximeterService,
              public userService: UserInfoService,
              private orderService: OrderService,
              private draftService: OrderDraftService,
              private alertService: AlertService,
              private loaderService: LoaderService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyOrder();
  }

  private applyOrder() {
    this.period = this.order.periods[0];

    if(this.statusDate === null)
      this.statusDate = DateUtils.offsetTimeByCityTimeZone(new Date(), this.order.draft.city);

    this.applyStatus();
    this.applyTaximeter();
    this.updateControls();
    this.applyExecution();
    this.startUpdateTaximeter();
  }

  private applyExecution() {
    this.history = this.period && this.period.history;
    if(history)
      this.applyDestinationToHistory();
  }

  private applyDestinationToHistory() {
    let currentDestination = -1;
    for(let row of this.history) {
      if(row.execution_status == 'on_start_place' || row.execution_status == 'on_end_place') {
        currentDestination ++;
        row['destination'] = this.period.destinations[currentDestination];
        row['destinationNum'] = currentDestination + 1;
      }
    }
  }

  private applyStatus() {
    this.status = this.order.status;
    this.periodStatus = this.period && this.period.status;
  }

  private applyTaximeter() {
    this.updateTaximeter(this.order.taximeter, this.order.taximeter_duration, this.order.taximeter_distance, this.order.taximeter_out_mkad_distance);
  }

  private updateTaximeter(cost: number, duration: number, distance: number, outMkadDistance: number) {
    let hours = Math.floor(duration / 60 / 60);
    let minutes = Math.floor(duration / 60) - hours * 60;
    this.time = hours + ':' + OrderStatusPanelComponent.expandWithZero(minutes);
    this.distance = Math.round(distance / 1000)  || 0;
    this.outMkadDistance = Math.round(outMkadDistance / 1000) || 0;
    // ** Deprecated **
    // this.orderService.getOrderInfoBus().next({
    //   time: this.time,
    //   distance: this.distance,
    //   outMkadDistance: this.outMkadDistance
    // });
    this.cost = cost + '';
  }

  private updateControls() {
    if (!this.period || this.period.employer == null || this.period.destinations.length < 2) {
      this.enabled = false;
      this.enabledUseCalculatedPath = false;
      return;
    }

    this.enabled = true;
    this.enabledRevert = true;
    this.enabledUseCalculatedPath = this.periodStatus == 'accepted' && this.userService.isPrivilegedUser();
    this.useCalculatedPath = this.period.use_calculated_path;

    this.disableButtons();

    this.activeButtons = {
      restart: false,
      stop: false,
      pause: false,
      play: false
    };

    if(this.userService.isPrivilegedUser() && (this.order.status === 'completed' || (this.order.status === 'accepted' && this.order.payment_status === 'wait_payment')))
      this.enabledRollback = true;

    if (this.order.payment_status !== 'none')
      return;

    let isScheduled = this.order.draft.pending_search || this.order.draft.repeatable_search;
    this.visibleButtons.stop = !isScheduled;
    this.visibleButtons.stopScheduled = isScheduled;

    if (['new', 'edit', 'negotiation'].indexOf(this.status) >= 0) {
      this.enabledButtons.play = true;
      this.enabledButtons.stop = true;
    } else if (this.status == 'accepted') {
      this.enabledButtons.stop = true;
      this.enabledButtons.pause = ['none', 'moved_to_order'].indexOf(this.period.execution_status) < 0;
      this.activeButtons.play = true;
    } else if (['agree', 'ready', 'checking'].indexOf(this.status) >= 0) {
      if (this.order.pending)
        this.enabledButtons.restart = true;

      this.enabledButtons.stop = true;
      this.enabledButtons.play = true;
    } else if (this.order.pending && ['not_agree', 'declined', 'no_answer', 'canceled_by_freighter', 'canceled_by_employer', 'canceled_by_client'].indexOf(this.status) >= 0) {
      this.enabledButtons.restart = true;
    }
  }

  private startUpdateTaximeter() {
    this.stopUpdateTaximeter();

    if (this.status !== 'accepted') {
      console.log('Taximeter update disabled');
      return;
    }

    this.requestTaximeter();
  }

  private scheduleUpdateTaximeter() {
    if (this.status !== 'accepted') {
      console.log('Taximeter update disabled');
      return;
    }

    console.log(`Setup taximeter update in ${ TAXIMETER_UPDATE_PERIOD / 1000 } seconds...`);
    this.taximeterTimer = setTimeout(() => this.requestTaximeter(), TAXIMETER_UPDATE_PERIOD);
  }

  private requestTaximeter() {
    console.log('Updating taximeter...');

    this.taximeterService
      .getTaximeter(this.order).pipe(
      finalize(() => this.scheduleUpdateTaximeter()))
      .subscribe(
        t => {
          this.updateTaximeter(t.taximeter, t.duration, t.distance, t.out_mkad_distance);
          this.history = t.history;
          this.activeButtons.pause = t.paused;
          this.taximeterState = !t.paused;

          this.applyDestinationToHistory();
        },
        e => {
          console.log('Taximeter update error');
          console.log(e);
        }
      )
  }

  private stopUpdateTaximeter() {
    if (this.taximeterTimer) {
      clearTimeout(this.taximeterTimer);
    }

    this.taximeterTimer = null;
  }

  private disableButtons() {
    this.enabledButtons = {
      restart: false,
      stop: false,
      pause: false,
      play: false
    };
  }

  private updateStatus(status: string) {
    console.log(`Updating status to '${status}'...`);
    this.loaderService.show();
    this.disableButtons();
    this.orderService
      .updateOrderStatus(this.order, status).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.order.status = status;
          this.onUpdated.emit(this.order);
        },
        () => this.updateControls()
      )
    ;
  }

  private stopSearchAndUpdateStatus(status: string): void {
    this.loaderService.show();
    this.disableButtons();
    this.draftService
      .stopSearch(this.order.draft).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.updateStatus(status)
      )
    ;
  }

  private revertExecutionStatus() {
    this.loaderService.show();
    this.enabledRevert = false;
    this.orderService
      .revertExecutionStatus(this.order, this.period).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        () => {
          this.enabledRevert = true;
        }
      );
  }

  rollbackOrder() {
    this.loaderService.show();
    this.enabledRollback = false;
    this.orderService
      .rollbackOrder(this.order).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        () => {
          this.enabledRollback = true;
        }
      )
    ;
  }

  onPlay() {
    this.disableButtons();
    let status = '';
    if (this.status == 'edit')
      status = 'ready';
    else if (this.status == 'ready')
      status = 'checking';
    else if (this.status == 'checking')
      status = 'agree';
    else if (this.status == 'agree')
      status = 'accepted';
    else {
      console.log(`Incorrect status '${this.status}' to play`);
      return;
    }

    this.updateStatus(status);
  }

  onRestart() {
    this.disableButtons();
    if (!this.order.pending) {
      console.log('Can\'t restart a non pending order');
      return;
    }

    if (['ready', 'agree', 'not_agree', 'declined', 'no_answer', 'canceled_by_freighter', 'canceled_by_employer', 'canceled_by_client'].indexOf(this.status) < 0) {
      console.log(`Incorrect status '${this.status}' to restart`);
      return;
    }

    this.updateStatus('edit');
  }

  onStop(status: string) {
    this.disableButtons();
    this.updateStatus(status);

    return false;
  }

  onStopAndCancelSchedule(status: string): boolean {
    this.disableButtons();
    this.stopSearchAndUpdateStatus(status);

    return false;
  }

  onPause() {
    this.disableButtons();
    console.log(this.taximeterState ? 'pause' : 'resume');
    this.loaderService.show();
    this.orderService
      .updateTaximeterState(this.order, this.period, !this.taximeterState).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        () => {
        }
      );
  }

  onExecute() {
    console.log(`Updating execution status to '${this.executionStatus}'...`);
    this.loaderService.show();
    this.orderService
      .updateExecutionStatus(this.order, this.period, this.executionStatus, this.withStatusDateControl ? this.statusDate : null).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        () => {
        }
      );
  }

  onBill() {
    this.loaderService.show();
    this.orderService
      .bill(this.order, this.period.employer).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        () => {
        }
      );
  }

  onBillAccept() {
    this.loaderService.show();
    this.orderService
      .billAccept(this.order, this.period.employer).pipe(
      finalize(() => {
      this.loaderService.hide();
    }))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        () => {
        }
      );
  }

  onBillMoneyReturn() {
    this.loaderService.show();
    this.orderService
      .moneyReturned(this.order).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        error => console.log('Невозможно подвердить передачу денег', error)
      );

  }

  onBillMoneyReturnAccept() {
    this.loaderService.show();
    this.orderService
      .moneyReturnAccepted(this.order).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        error => console.log('Невозможно подвердить передачу денег', error)
      );

  }

  onRevertExecutionStatus() {
    if (confirm('Подтверждаете отмену статуса?'))
      this.revertExecutionStatus();
  }

  onUpdateUsingCalculationPath(state: boolean) {
    this.loaderService.show();
    this.orderService
      .updateUsingCalculatedPath(this.order, this.period, state).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.useCalculatedPath = state;
          this.onUpdated.emit(this.order);
        },
        () => {
        }
      );
  }

  onRollback() {
    if(confirm('Подтверждаете откат заказа и возврат средств?'))
      this.rollbackOrder();
  }

  ngOnDestroy(): void {
    this.stopUpdateTaximeter();
    console.log('Stop taximeter updating');
  }

  private static expandWithZero(val: number): string {
    let result: string = '' + val;
    if (result.length < 2)
      result = '0' + result;

    return result;
  }
}
