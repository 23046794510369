import {OptimizationTaskDestination} from "../../_models/optimization-task-destination";
import {Marker} from "./marker";
import {OptimizationTaskTransportDestination} from "../../_models/optimization-task-transport-destination";
import {GeoUtils} from "../../_utils/geo-utils";

declare var google: any;

export class MarkersCollection {
  markers = new Map<number, Marker>();

  add(id: number, marker: Marker): void {
    this.markers.set(id, marker);
  }

  addMarkerFromDestination(destination: OptimizationTaskDestination): Marker {
    let marker = this.getMarkerForDestination(destination);
    marker.applyDestination(destination);
    return marker;
  }

  addMarkerFromTransportDestination(destination: OptimizationTaskTransportDestination): Marker {
    let marker = this.getMarkerForDestination(destination.destination);
    marker.applyTransportDestination(destination);
    return marker;
  }

  remove(id: number): void {
    this.markers.delete(id);
  }

  select(destinationId: number): void {
    this.unselectAll();

    if(this.markers.has(destinationId)) {
      this.markers.get(destinationId).selected = true;
    }
  }

  private unselectAll(): void {
    this.markers.forEach(m => m.selected = false)
  }

  hide(destinationId: number): void {
    if(this.markers.has(destinationId)) {
      this.markers.get(destinationId).visible = false;
    }
  }

  show(destinationId: number): void {
    if(this.markers.has(destinationId)) {
      this.markers.get(destinationId).visible = true;
    }
  }

  hideAll(): void {
    this.markers.forEach(m => m.visible = false);
  }

  showAll(): void {
    this.markers.forEach(m => m.visible = true);
  }

  getMarker(destinationId: number): Marker|null {
    return this.markers.has(destinationId) ? this.markers.get(destinationId) : null;
  }

  clear(): void {
    this.markers = new Map<number, Marker>();
  }

  private getMarkerForDestination(destination: OptimizationTaskDestination): Marker {
    if (this.markers.has(destination.id)) {
      return this.markers.get(destination.id);
    }

    let marker = new Marker();
    this.markers.set(destination.id, marker);

    return marker;
  }

  getCopyWithVisibleMarkers(bounds?: google.maps.LatLngBounds, scale = 1): MarkersCollection {
    let copy = new MarkersCollection();

    if(bounds && scale > 1) {
      let sw = bounds.getSouthWest();
      let ne = bounds.getNorthEast();
      let center = bounds.getCenter();
      let halfScale = (scale - 1) / 2;

      console.log(`Old bounds: ${sw.lat()}, ${sw.lng()} / ${ne.lat()}, ${ne.lng()}`);
      console.log(`Center: ${center.lat()}, ${center.lng()}`);

      let relativeLat = sw.lat() - center.lat();
      let absRelativeLat = Math.abs(relativeLat);
      let relativeLon = sw.lng() - center.lng();
      let absRelativeLon = Math.abs(relativeLon);
      let newRelativeLat = (absRelativeLat + absRelativeLat * halfScale) * Math.sign(relativeLat);
      let newRelativeLon = (absRelativeLon + absRelativeLon * halfScale) * Math.sign(relativeLon);

      let newSW = new google.maps.LatLng(center.lat() + newRelativeLat, center.lng() + newRelativeLon);
      let newNE = new google.maps.LatLng(center.lat() - newRelativeLat, center.lng() - newRelativeLon);

      let newBounds = new google.maps.LatLngBounds(newSW, newNE);

      newBounds.extend(newSW);
      newBounds.extend(newNE);

      bounds = newBounds;

      console.log(`New bounds: ${bounds.getSouthWest().lat()}, ${bounds.getSouthWest().lng()} / ${bounds.getNorthEast().lat()}, ${bounds.getNorthEast().lng()}`);
    }

    this.markers.forEach((marker, id) => {
      if(marker.visible && (!bounds || marker.isInBounds(bounds)))
        copy.markers.set(id, marker);
    });

    console.log(`Visible markers count: ${copy.markers.size}`);

    return copy;
  }

  forEach(callback: (marker: Marker, destinationId: number) => void): void {
    this.markers.forEach((marker, destinationId) => callback(marker, destinationId));
  }

  calcCenter(): any {
    let sumLat = 0, sumLon = 0;
    this.forEach(marker => {
      sumLat += marker.lat;
      sumLon += marker.lon;
    });

    let centerLat = sumLat / this.markers.size;
    let centerLon = sumLon / this.markers.size;

    let maxDistance = 0;
    this.forEach(marker => {
      let distance = GeoUtils.calcDistance(marker.lat, marker.lon, centerLat, centerLon);
      if(distance > maxDistance)
        maxDistance = distance;
    });

    return {
      lat: sumLat / this.markers.size,
      lon: sumLon / this.markers.size,
      radius: maxDistance * 1000
    }
  }
}

