
import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime, map} from 'rxjs/operators';

import {throwError as observableThrowError, Subject} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {TransportsList} from "../_models/transports-list";
import {TransportService} from "../_services/transport.service";
import {LoaderService} from "../_services/loader.service";
import {UserInfoService} from "../_services/user-info.service";
import {Page} from "../pager/page";
import {TransportsFilter} from "../_models/transports-filter";
import {State} from "../_storage/state";

const PAGE_STORAGE = "transports_list_page";
const FILTER_STORAGE = "transports_list_filter";

class ListCommand {
  constructor(
    private _filter: TransportsFilter,
    private _page?: number,
    private _source?: string,
    private _force?: boolean
  ) {}

  get filter(): TransportsFilter {
    return this._filter;
  }

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  get force(): boolean {
    return this._force;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'app-transports',
  templateUrl: './transports.component.html',
  styleUrls: ['./transports.component.css']
})
export class TransportsComponent implements OnInit {
  transports: TransportsList = TransportsList.empty();
  private listCommands: Subject<ListCommand>;
  showMore: boolean;

  filter = new TransportsFilter();
  private oldFilter = new TransportsFilter();

  constructor(private transportService: TransportService, private userInfoService: UserInfoService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.initTransports();
    this.restorePage();
    this.restoreFilter();
    this.loadTransports(this.transports.page, this.transports.filter);
  }

  private initTransports(): void {
    this.listCommands = new Subject<ListCommand>();
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        return this.transportService
          .getFreighterTransports(this.userInfoService.getFreighter(), command.page, command.filter).pipe(
          map(list => [ list, command ])).pipe(
          finalize(() => this.loaderService.hide()))
        ;
      }),
      catchError(e => {
        console.log(e);
        this.initTransports();
        return observableThrowError(e);
      }),)
      .subscribe(listAndCommand => {
        let list = listAndCommand[0] as TransportsList;
        let command = listAndCommand[1] as ListCommand;

        if(command.source === 'button') {
          this.transports = list.concat(this.transports)
        } else {
          this.transports = list;
        }

        this.initMoreButton();
        this.savePage();
        this.saveFilter();
      });
  }

  private loadTransports(page?: number, filter?: TransportsFilter, source?: string, force?: boolean) {
    this.listCommands.next(new ListCommand(filter?.clone(), page, source, force));
  }

  private reloadTransports(): void {
    this.loadTransports(this.transports.page, this.transports.filter, null, true);
  }

  private initMoreButton(): void {
    this.showMore = this.transports.hasMore();
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.transports.page.toString());
  }

  private restorePage() {
    this.transports.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  private saveFilter() {
    sessionStorage.setItem(FILTER_STORAGE, this.transports.filter.getState().state);

    this.oldFilter = this.transports.filter.clone();
  }

  private restoreFilter() {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if(state)
      this.transports.filter = TransportsFilter.fromState(new State(state));

    this.filter = this.transports.filter.clone();
    this.oldFilter = this.transports.filter.clone();
  }

  onShowMorePage(page: Page) {
    this.loadTransports(page.num, this.transports.filter, "button");
  }

  onPaginationPage(page: Page) {
    this.loadTransports(page.num, this.transports.filter, "pager");
  }

  onChangeFilter(): void {
    if(!this.filter.equals(this.oldFilter)) {
      console.log('filter changed');
      this.listCommands.next(new ListCommand(this.filter.clone()));
    }
  }
}
