
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../_services/loader.service";
import {TitleService} from "../_services/title.service";
import {CrewService} from "../_services/crew.service";
import {UserInfoService} from "../_services/user-info.service";
import {Editable} from "../_models/editable";
import {Crew} from "../_models/crew";
import {FormErrors} from "../_models/form-errors";
import {Freighter} from "../_models/freighter";
import {UserNameUtils} from "../_utils/user-name-utils";
import {TransportUtils} from "../_utils/transport-utils";

@Component({
  selector: 'app-edit-crew',
  templateUrl: './edit-crew.component.html',
  styleUrls: ['./edit-crew.component.css']
})
export class EditCrewComponent implements OnInit {
  crew = new Editable(new Crew(), new FormErrors());
  freighter: Freighter;
  date: Date;

  constructor(
    private crewService: CrewService,
    private userInfoService: UserInfoService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.route.params
      .subscribe(
        params => this.loadCrew(+params['id']),
        e => console.error(e)
      );

    this.route.queryParams
      .subscribe(params => {
        if(params['date'] && /^[0-9]+$/.test(params['date'])) {
          console.log('use query date');
          this.date = new Date(parseInt(params['date']));
        } else {
          console.log('use current date');
          this.date = new Date();
        }
      });
  }

  private loadCrew(id: number): void {
    this.loaderService.show();

    this.crewService
      .getCrew(this.userInfoService.getFreighter(), id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        c => {
          this.crew = new Editable(c, new FormErrors(), false, true);
          this.freighter = this.userInfoService.getFreighter();

          let title: string[] = [
            UserNameUtils.getAccountFullName(c.employer.account),
            '/',
            TransportUtils.getTransportDescription(c.transport)
          ];
          this.titleService.changeTitle(title.join(' '));
        },
        () => {}
      )
  }

  onBack(): void {
    this.router.navigate([`/crews`]);
  }

  onSaved(crew: Crew): void {
    this.router.navigate([`/crews`, crew.id], {
      queryParams: { date: this.date.getTime() }
    });
  }

  onStatusUpdated(crew: Crew): void {
    this.loadCrew(crew.id);
  }
}
