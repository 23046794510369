<h4 *ngIf="title">{{ title }}</h4>
<h5 *ngIf="totalEnabled">Состав груза</h5>
<table class="table table-condensed table-striped">
  <thead>
  <tr>
    <th *ngIf="!readOnly"></th>
    <th>Наименование</th>
    <th>Артикул</th>
    <th>Количество</th>
    <th>Стоимость</th>
    <th>Вес, кг</th>
    <th>Длина, см</th>
    <th>Ширина, см</th>
    <th>Высота, см</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let line of lines; let i = index;">
    <th *ngIf="!readOnly"><button type="button" class="btn btn-default btn-xs" title="Удалить" (click)="onRemoveLine(i)"><span class="glyphicon glyphicon-minus"></span></button></th>
    <td [class.has-error]="(line.name || '').trim() == ''">
      <input type="text" [name]="'name' + i" [(ngModel)]="line.name" class="form-control input-sm" (ngModelChange)="onLinesChanged()" [readonly]="readOnly">
    </td>
    <td>
      <input type="text" [name]="'code' + i" [(ngModel)]="line.code" class="form-control input-sm" (ngModelChange)="onLinesChanged()" [readonly]="readOnly">
    </td>
    <td>
      <input type="number" [name]="'count' + i" [(ngModel)]="line.count" class="form-control input-sm" (ngModelChange)="onLinesChanged()" [readonly]="readOnly">
    </td>
    <td>
      <input type="text" [name]="'cost' + i" [(ngModel)]="line.cost" class="form-control input-sm" (ngModelChange)="onLinesChanged()" [readonly]="readOnly">
    </td>
    <td>
      <input type="text" [name]="'weight' + i" [(ngModel)]="line.weight" class="form-control input-sm" (ngModelChange)="onLinesChanged()" [readonly]="readOnly">
    </td>
    <td>
      <input type="number" [name]="'length' + i" [(ngModel)]="line.length" class="form-control input-sm" (ngModelChange)="onLinesChanged()" [readonly]="readOnly">
    </td>
    <td>
      <input type="number" [name]="'width' + i" [(ngModel)]="line.width" class="form-control input-sm" (ngModelChange)="onLinesChanged()" [readonly]="readOnly">
    </td>
    <td>
      <input type="number" [name]="'height' + i" [(ngModel)]="line.height" class="form-control input-sm" (ngModelChange)="onLinesChanged()" [readonly]="readOnly">
    </td>
  </tr>
  </tbody>
</table>

<h5 *ngIf="totalEnabled">Параметры отгрузки</h5>
<div class="form-inline" *ngIf="totalEnabled">
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-addon">Д</div>
      <input type="text" class="form-control" placeholder="длина" size="5" [(ngModel)]="deliveryProduct.length" [readonly]="readOnly">
      <div class="input-group-addon">см</div>
    </div>
  </div>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-addon">Ш</div>
      <input type="text" class="form-control" placeholder="ширина" size="5" [(ngModel)]="deliveryProduct.width" [readonly]="readOnly">
      <div class="input-group-addon">см</div>
    </div>
  </div>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-addon">В</div>
      <input type="text" class="form-control" placeholder="высота" size="5" [(ngModel)]="deliveryProduct.height" [readonly]="readOnly">
      <div class="input-group-addon">см</div>
    </div>
  </div>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-addon"><span class="glyphicon glyphicon-scale"></span></div>
      <input type="text" class="form-control" placeholder="вес" size="5" [(ngModel)]="deliveryProduct.weight" [readonly]="readOnly">
      <div class="input-group-addon">кг</div>
    </div>
  </div>
</div>
