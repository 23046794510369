
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderPeriod} from "../_models/order-period";
import {OrderService} from "../_services/order.service";
import {Order} from "../_models/order";
import {LoaderService} from "../_services/loader.service";
import {DateUtils} from "../_utils/date-utils";

@Component({
  selector: 'period-editor',
  templateUrl: './period-editor.component.html',
  styleUrls: ['./period-editor.component.css']
})
export class PeriodEditorComponent implements OnInit {
  @Input() period: OrderPeriod;
  @Input() order: Order;
  @Output() onUpdated = new EventEmitter<OrderPeriod>();

  startDate: Date;
  endDate: Date;
  error: string;
  datePickerOptions = {};
  timePickerOptions = {};

  constructor(private orderService: OrderService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.startDate = DateUtils.offsetTimeByCityTimeZone(new Date(this.period.start), this.order.draft.city);
    this.endDate = DateUtils.offsetTimeByCityTimeZone(new Date(this.period.end), this.order.draft.city);
  }

  protected validate(): boolean {
    this.error = "";

    if(this.startDate.getTime() > this.endDate.getTime())
    {
      this.error = 'Дата окончания должна быть позже даты начала';
      return false;
    }

    return true;
  }

  onSave() {
    if(!this.validate())
      return;

    this.period.start = this.startDate.toString();
    this.period.end = this.endDate.toString();

    console.log(this.period.start);
    console.log(this.period.end);

    this.loaderService.show();
    this.orderService
      .updatePeriod(this.order, this.period).pipe(
      finalize(() => this.loaderService.hide()))
      .toPromise()
      .then(() => this.onUpdated.emit(this.period))
      .catch(() => {});
  }

  private static convertDate(date: Date): string {
    return date.getFullYear() +
      '-' + PeriodEditorComponent.pad(date.getMonth() + 1) +
      '-' + PeriodEditorComponent.pad(date.getDate()) +
      'T' + PeriodEditorComponent.pad(date.getHours()) +
      ':' + PeriodEditorComponent.pad(date.getMinutes()) +
      ':' + PeriodEditorComponent.pad(date.getSeconds()) +
      '+' + date.getTimezoneOffset();
  }

  private static pad(number: number): string {
    if (number < 10) {
      return '0' + number;
    }
    return number + "";
  }
}
