import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {from, Observable} from "rxjs";
import {AdjustmentCost} from "../_models/adjustment-cost";

@Injectable()
export class AdjustmentService {
  constructor(private _requestService: RequestWithErrorHandlerService) {
  }

  getAdjustmentsForOrders(ordersId: number[]): Observable<AdjustmentCost[]> {
    if(ordersId.length == 0)
      return from([]);

    return this._requestService
      .get('/adjustments.json', {
        ordersId: ordersId.join(',')
      }).pipe(
        map(r => r.body as AdjustmentCost[])
      )
      ;
  }
}
