import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Destination} from "../_models/destination";

class AdditionalItems {
  weight: number;
  count: number;
  price: number;
  cost: number;
}

class Additional {
  destination: Destination;
  destinationNum: number;
  timeCost: number;
  additionalItemsCost: number;
  duration: number;
  loading: boolean;
  unloading: boolean;
  minuteCost: number;
  hasLifting: boolean;
  items: AdditionalItems[] = [];
}

@Component({
  selector: '[lgd2-calculation]',
  templateUrl: './lgd2.calculation.component.html',
  styleUrls: ['./calculation.component.css']
})
export class Lgd2CalculationComponent implements OnChanges {
  @Input() calculation: any;
  @Input() tariff: any;
  @Input() destinations: Destination[] = [];
  @Input() transportMode = true;

  transportAdditional: Additional[] = [];
  loadersAdditional: Additional[] = [];
  loadersAdditionalTotalCost: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['calculation'] || changes['transportMode'])
      this.refreshAdditional();
  }

  private refreshAdditional() {
    this.transportAdditional = [];
    this.loadersAdditional = [];
    let additionalCalculation = this.calculation['additional'] || [];
    for(const row of additionalCalculation) {
      if(!row.additional_items_cost && !row.time_cost)
        continue;

      let additional = new Additional();
      additional.destinationNum = row.destination_num;
      additional.destination = this.destinations[row.destination_num] || null;
      additional.timeCost = row.time_cost;
      additional.additionalItemsCost = row.additional_items_cost;
      additional.duration = row.duration;
      additional.loading = row.loading;
      additional.unloading = row.unloading;
      additional.minuteCost = row.minute_cost;
      additional.hasLifting = row.has_lifting;

      if(additional.destination) {
        additional.loading = (additional.destination.loading || additional.destination.unloading)
          ? additional.destination.loading
          : additional.destinationNum == 0;
        additional.unloading = (additional.destination.loading || additional.destination.unloading)
          ? additional.destination.unloading
          : additional.destinationNum != 0;
      }

      if(row.additional_item_calculations)
        additional.items = row.additional_item_calculations as AdditionalItems[];

      if(additional.timeCost)
        this.transportAdditional.push(additional);
      if(additional.additionalItemsCost)
        this.loadersAdditional.push(additional);

      this.transportAdditional.sort((a, b) => a.destinationNum - b.destinationNum);
    }
    this.loadersAdditionalTotalCost = this.transportAdditional.reduce((sum, add) => sum + add.additionalItemsCost, 0);
  }
}
