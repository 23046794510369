
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Order} from "../_models/order";
import {LoaderService} from "../_services/loader.service";
import {OrderService} from "../_services/order.service";

@Component({
  selector: 'order-status-editor',
  templateUrl: './order-status-editor.component.html',
  styleUrls: ['./order-status-editor.component.css']
})
export class OrderStatusEditorComponent implements OnInit {
  @Input() order: Order;
  @Output() onUpdated = new EventEmitter<Order>();

  status: string;

  constructor(
    private loaderService: LoaderService,
    private orderService: OrderService
  ) { }

  ngOnInit() {
    this.status = this.order.status;
  }

  onSave() {
    this.loaderService.show();
    this.orderService
      .updateOrderStatus(this.order, this.status).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.order.status = this.status;
          this.onUpdated.emit(this.order);
        },
        () => {}
      )
  }
}
