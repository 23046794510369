export class FreighterClient {
  id: number;
  name: string;
  contact_name: string;
  contact_phone: string;

  static isEmpty(client: FreighterClient): boolean {
    return (!client.id || client.id == 0) && !client.name && !client.contact_name && !client.contact_phone;
  }
}
