<div>
  <div class="checkbox" *ngIf="!forceInsure">
    <label>
      <input
        type="checkbox"
        name="enable_insurance"
        [(ngModel)]="insure"
        (ngModelChange)="onToggleInsure()"
      > Застраховать груз
    </label>
  </div>

  <div *ngIf="insure">
    <div class="input">
      <div class="option-title">Описание груза</div>
      <input name="cargoDescription" class="form-control" [(ngModel)]="insurance.cargo_description">
    </div>

    <div class="input">
      <div class="option-title">Тип страхования</div>
      <select class="full-width" name="section" [(ngModel)]="insurance.section">
        <option [value]="section.key" *ngFor="let section of insuranceSections|mapToIterable">{{ section.val }}</option>
      </select>
    </div>

    <div class="input" *ngIf="insurance.section != 'undefined'" [class.has-error]="cargoCostError">
      <div class="option-title">Стоимость груза</div>
      <input name="cargoCost" class="form-control" [(ngModel)]="insurance.cargo_cost">
      <div class="error">{{ cargoCostError }}</div>
    </div>
  </div>
</div>
