<table class="table table-condensed">
  <thead *ngIf="enabledColumns.size > 1">
  <tr>
    <th>Описание</th>
    <th *ngIf="enabledColumns.has('client')">клиент</th>
    <th *ngIf="enabledColumns.has('freighter')">перевозчик</th>
    <th *ngIf="enabledColumns.has('mover')">MOVER</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of rows">
    <td>{{ row.description }}</td>
    <td *ngIf="enabledColumns.has('client')" class="text-nowrap">{{ row.client|formatCost }}</td>
    <td *ngIf="enabledColumns.has('freighter')" class="text-nowrap">{{ row.freighter|formatCost }}</td>
    <td *ngIf="enabledColumns.has('mover')" class="text-nowrap">{{ row.mover|formatCost }}</td>
  </tr>
  </tbody>
  <tfoot *ngIf="rows.length > 1">
  <tr>
    <td>ИТОГО</td>
    <td *ngIf="enabledColumns.has('client')" class="text-nowrap">{{ totals.client|formatCost }}</td>
    <td *ngIf="enabledColumns.has('freighter')" class="text-nowrap">{{ totals.freighter|formatCost }}</td>
    <td *ngIf="enabledColumns.has('mover')" class="text-nowrap">{{ totals.mover|formatCost }}</td>
  </tr>
  </tfoot>
</table>
