<form (submit)="onSave()">
  <div class="flex-form">
    <select class="full-width mar8top" [(ngModel)]="deliverySchemaId" name="deliverySchema">
      <option value="0">Выберите схему</option>
      <option *ngFor="let schema of deliverySchemas" value="{{ schema.id }}" [disabled]="isMultiPoint && !schema.is_multipoint_allowed">
        {{ schema.name }}<span *ngIf="!schema.is_multipoint_allowed"> (только один адрес доставки)</span>
      </option>
    </select>
  </div>
  <button type="submit" class="save-btn">Сохранить</button>
</form>
