import {PipeTransform} from "@angular/core";
import {DateUtils} from "../_utils/date-utils";

export abstract class TzPipe implements PipeTransform {
  protected readonly userCityTz: number;
  protected readonly userTzOffset: number;

  constructor() {
    this.userTzOffset = -new Date().getTimezoneOffset();
    this.userCityTz = DateUtils.offsetToCityTimeZone(this.userTzOffset);
  }

  abstract transform(value: any, ...args: any[]): any;

}
