<form (submit)="onSave()">
  <div class="form-flex">
    <div class="form-group">
      <div>
        <label for="payMethod">Способ оплаты</label>
        <select id="payMethod" name="payMethod" [(ngModel)]="payMethod" (ngModelChange)="onChangePayMethod()">
          <option *ngFor="let method of payMethods | mapToIterable" value="{{ method.key }}">{{ method.val }}</option>
        </select>
      </div>
      <div  *ngIf="optionsEnabled && availableOptions.length > 0">
        <label for="payMethodOption">Опция</label>
        <select id="payMethodOption" name="payMethodOption" [(ngModel)]="payMethodOption">
          <option *ngFor="let option of availableOptions" [value]="option">{{ option|payMethodOption }}</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label for="discountFix">Скидка (фикс.)</label>
      <input type="text" class="" id="discountFix" name="fixedDiscount" [(ngModel)]="fixedDiscount">
    </div>
    <div class="form-group">
      <label for="discountPercent">Скидка,%</label>
      <input type="text" class="" id="discountPercent" name="discount" [(ngModel)]="discount">
    </div>
  </div>
  <div class="form-flex" *ngIf="isCostEditable">
    <div class="form-group">
      <label>Стоимость</label>
      <input name="cost" [(ngModel)]="cost">
    </div>
    <div class="form-group">
      <label>Распределение средств</label>
      <select name="paymentDistributionSchema" [(ngModel)]="paymentDistributionSchemaId">
        <option *ngFor="let schema of paymentDistributionSchemas" [value]="schema.id">{{ schema.name }}</option>
      </select>
    </div>
  </div>
  <button type="submit" class="save-btn">Сохранить</button>
</form>
