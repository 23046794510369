<tr>
  <th>Время в пути</th>
  <td>{{ calculation.time_at_way }} мин. * {{ calculation.time_at_way_minute_cost }} р. = {{ calculation.time_at_way_cost }} р.</td>
</tr>
<ng-template ngIf [ngIf]="calculation.distance_in_base_area > 0">
  <tr class="header">
    <th colspan="2">Внутри зоны "{{ calculation.base_area_name }}"</th>
  </tr>
  <tr>
    <th>Общее расстояние</th>
    <td>{{ calculation.distance_in_base_area }} км</td>
  </tr>
  <tr>
    <th>Оплачиваемое расстояние</th>
    <td>{{ calculation.distance_in_base_area }} км - {{ tariff.freeDestinationInBaseArea }} бесплатных км = {{ calculation.payable_distance_in_base_area }} км</td>
  </tr>
  <tr>
    <th>Стоимость</th>
    <td>{{ calculation.payable_distance_in_base_area }} км * {{ tariff.kmCostInBaseArea }} р. = {{ calculation.distance_in_base_area_cost }} р.</td>
  </tr>
</ng-template>
<ng-template ngIf [ngIf]="calculation.distance_beyond_base_area > 0">
  <tr class="header">
    <th colspan="2">За пределами зоны "{{ calculation.base_area_name }}"</th>
  </tr>
  <tr>
    <th>Общее расстояние</th>
    <td>{{ calculation.distance_beyond_base_area }} км</td>
  </tr>
  <tr>
    <th>Стоимость</th>
    <td>{{ calculation.distance_beyond_base_area }} км * {{ tariff.kmCostBeyondBaseArea }} р. = {{ calculation.distance_beyond_base_area_cost }} р.</td>
  </tr>
</ng-template>
<ng-template ngFor let-add [ngForOf]="additional">
  <tr class="header">
    <th colspan="2">Дополнительно на адресе "{{ add.destination && add.destination.destination.addr }}"</th>
  </tr>
  <tr>
    <th>
      Стоимость
      <span *ngIf="add.loading"> погрузки</span>
      <span *ngIf="add.unloading"> разгрузки</span>
      <span *ngIf="add.hasLifting"> с учётом подъёма/спуска</span>
    </th>
    <td>{{ add.duration }} мин. * {{ add.minuteCost }} р. = {{ add.cost }} р.</td>
  </tr>
</ng-template>
