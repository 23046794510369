<div class="modal fade" tabindex="-1" role="dialog" #issueDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Создание проблемы на адресе "{{ destination?.destination.addr }}"</h4>
        </div>
      </div>
      <div class="modal-body">
        <issue-form [destination]="destination" [order]="order"></issue-form>
      </div>
      <div class="modal-footer">
        <button type="button" class="save-btn pull-right" title="Создание проблемы" (click)="onCreateIssue()" [disabled]="!issueForm.isValid() || creating">Создать</button>
      </div>
    </div>
  </div>
</div>
