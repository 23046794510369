
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CompanyClient} from "../_models/company-client";
import {UserFormComponent} from "../user-form/user-form.component";
import {Account} from "../_models/account";
import {Editable} from "../_models/editable";
import {FormErrors} from "../_models/form-errors";
import {CompanyClientService} from "../_services/company-client.service";
import {LoaderService} from "../_services/loader.service";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {UserInfoService} from "../_services/user-info.service";

@Component({
  selector: 'new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {
  @ViewChild('newUserDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(UserFormComponent, { static: true }) userForm: UserFormComponent;

  @Output() onUserAdded = new EventEmitter<Account>();

  account = new Editable<Account>(new Account(), new FormErrors());
  role: string;
  roleEnabled: boolean;

  private company: CompanyClient;
  private accountToSave: Editable<Account>;
  private roleToSave: string;

  constructor(
    private companyClientService: CompanyClientService,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
  }

  showForCompany(company: CompanyClient) {
    this.company = company;
    this.account = new Editable<Account>(new Account(), new FormErrors());
    this.role = 'ROLE_DELIVERY_MANAGER';
    this.roleEnabled = this.userInfoService.isCompanyClientManager() || this.userInfoService.isPrivilegedUser();

    this.accountToSave = null;
    this.roleToSave = null;

    $(this.dialogEl.nativeElement).modal('show');
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private addAccount(account: Editable<Account>): void {
    this.loaderService.show();
    this.companyClientService
      .addUser(this.company, account.record).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        r => {
          this.alertService.success('Пользователь добавлен');
          console.log(this.roleEnabled && this.roleToSave != null);
          if(this.roleEnabled && this.roleToSave != null) {
            this.saveRole(r.body as Account, this.roleToSave);
          } else {
            this.hideDialog();
            this.onUserAdded.emit(account.record);
          }
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            account.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  private saveRole(account: Account, role: string): void {
    this.loaderService.show();
    this.companyClientService
      .updateUserRole(this.company, account, role).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.hideDialog();
        this.onUserAdded.emit(account);
      }))
      .subscribe(
        () => {
          this.alertService.success('Пользователь добавлен');
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 405) {
            this.alertService.error('Невозможно установить роль');
          }
        }
      );
  }

  onSaveAccount(account: Editable<Account>): void {
    this.accountToSave = account;
  }

  onSaveRole(role: string): void {
    this.roleToSave = role;
  }

  onAllSaved(): void {
    this.addAccount(this.accountToSave);
  }
}
