import {Pipe, PipeTransform} from "@angular/core";
import {Transport} from "../_models/transport";
import {CAPACITIES} from "../_maps/capacities";

@Pipe({name: "transportCapacity"})
export class TransportCapacityPipe implements PipeTransform {
  transform(transport: Transport): string {
    if(transport.is_virtual)
      return 'данные не доступны';

    return CAPACITIES[transport.features.capacity] || transport.features.capacity || '-';
  }
}
