import {Crew} from "./crew";
import {List} from "../../pager/list";

export class CrewsList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public crews: Crew[]) {
  }

  static empty(): CrewsList {
    return new CrewsList(0, 0, 0, []);
  }

  concat(array) {
    this.crews = array.concat(this.crews);
    return this;
  }

}
