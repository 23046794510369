import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export interface WeekEventMessage {
  day: Date;
  numberOfEvents: number;
}

export interface WeekEventBusMessage {
  message: string;
  day?: Date;
}

export interface PanelStatusMessage {
  collapsed: boolean;
}
@Injectable()
export class CalendarService {

  private weekEventsNotifier: Subject<WeekEventMessage> = new Subject<WeekEventMessage>();
  private weekEventsBus: Subject<WeekEventBusMessage> = new Subject<WeekEventBusMessage>();

  private panelStatusNotifier: Subject<PanelStatusMessage> = new Subject<PanelStatusMessage>();

  getWeekEventsNotifier(): Subject<WeekEventMessage> {
    return this.weekEventsNotifier;
  }

  getWeekEventsBus(): Subject<WeekEventBusMessage> {
    return this.weekEventsBus;
  }

  getPanelStatusNotifier(): Subject<PanelStatusMessage> {
    return this.panelStatusNotifier;
  }

}
