<div class="transport-form-container">

  <h1 *ngIf="!transport.isNew">{{ transport.record|transportDescription }}</h1>
  <h1 *ngIf="transport.isNew">Добавление машины</h1>

  <!-- Nav tabs -->
  <ul class="nav nav-tabs" role="tablist">
    <li role="presentation" class="active"><a href="#data" aria-controls="data" role="tab" data-toggle="tab">Данные
      машины</a></li>
    <li role="presentation"><a href="#files" aria-controls="files" role="tab" data-toggle="tab">Файлы</a></li>
  </ul>

  <!-- Tab panes -->
  <form class="transport-form" (submit)="onSave()">
    <div class="tab-content">
      <div role="tabpanel" class="tab-pane active" id="data">
        <h2>Основные параметры</h2>
        <div class="form-inline">
          <div class="form-group">
            <input type="text" name="car_model" class="form-control" [(ngModel)]="transport.record.car_model"
                   placeholder="Модель" title="Модель" required>
          </div>
          <div class="form-group">
            <select class="form-control" name="transportBody" [(ngModel)]="transport.record.features.Tip_kuzova"
                    required>
              <option value="">Тип кузова</option>
              <option *ngFor="let b of transportBodies|mapToIterable" [value]="b.key">{{ b.val }}</option>
            </select>
          </div>
          <div class="form-group">
            <div class="input-group">
              <select class="form-control" name="capacity" [(ngModel)]="transport.record.features.capacity" required>
                <option value="">Грузоподъёмность</option>
                <option *ngFor="let c of capacities|mapToIterable" [value]="c.key">{{ c.val }}</option>
              </select>
              <div class="input-group-addon">т</div>
            </div>
          </div>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" name="hydroelevator" [(ngModel)]="transport.record.features.Nalichie_gidroborta">
            Наличие
            гидроборта
          </label>
        </div>
        <h2>Размеры</h2>
        <div class="form-inline">
          <div class="form-group">
            <div class="input-group">
              <input type="text" name="length" class="form-control" [(ngModel)]="transport.record.features.Dlina"
                     placeholder="Длина" title="Длина">
              <div class="input-group-addon">м</div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input type="text" name="width" class="form-control" [(ngModel)]="transport.record.features.Shirina"
                     placeholder="Ширина" title="Ширина">
              <div class="input-group-addon">м</div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input type="text" name="height" class="form-control" [(ngModel)]="transport.record.features.Vyisota"
                     placeholder="Высота" title="Высота">
              <div class="input-group-addon">м</div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input type="text" name="volume" class="form-control" [(ngModel)]="transport.record.features.Ob_em_m3"
                     placeholder="Объём" title="Объём">
              <div class="input-group-addon">м<sup>3</sup></div>
            </div>
          </div>
        </div>
        <h2>Разрешения</h2>
        <div class="checkbox">
          <label>
            <input type="checkbox" name="center_permission"
                   [(ngModel)]="transport.record.features.Razreshen_v_ezd_v_TTK">
            Въезд в центр
          </label>
        </div>
        <h2>Дополнительно</h2>
        <div class="form-inline">
          <div class="form-group">
            <input type="text" name="number" class="form-control" [(ngModel)]="transport.record.features.number"
                   placeholder="Гос. номер">
          </div>
        </div>
        <textarea class="form-control" rows="5" name="comment" [(ngModel)]="transport.record.features.comment"
                  placeholder="Комментарий"></textarea>
      </div>
      <div role="tabpanel" class="tab-pane" id="files">
        <transport-photos-form
          [transport]="transport.record"
          [freighter]="freighter"
          (successUpload)="onUploadSuccess()"
          (updateUploadStatus)="onUpdateUploadStatus($event)"
        ></transport-photos-form>
      </div>
    </div>

    <div class="form-controls">
      <button type="button" class="btn btn-default" (click)="onBack()"><span
        class="glyphicon glyphicon-chevron-left"></span> К списку
      </button>
      <button type="submit" class="btn btn-primary" [disabled]="transport.isSubmitting">Сохранить</button>
    </div>
  </form>

</div>
