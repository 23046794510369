export const DELIVERY_STATUSES = {
  stopped: 'остановлен',
  pending: 'ожидание начала подбора',
  searching: 'идёт поиск',
  not_found: 'перевозчик не найден',
  canceled_by_freighter: 'отменено перевозчиком',
  canceled_by_client: 'отменено клиентом',
  rejected: 'заказ отклонён',
  accepted: 'заказ принят перевозчиком',
  moved_to_order: 'водитель выехал в точку погрузки',
  on_start_place: 'водитель на месте погрузки',
  loading: 'погрузка',
  moving: 'перевозка',
  on_end_place: 'машина прибыла к клиенту',
  unloading: 'разгрузка',
  completed: 'заказ завершён',
  wait_payment: 'ожидание оплаты',
  wait_hand: 'ожидание сдачи денег',
  wait_hand_accept: 'ожидание от менеджера подтверждения сдачи денег',
  money_handed: 'деньги сданы',
  success: 'заказ успешно завершён',
  money_sent: 'деньги отправлены',
  cash_accepted: 'наличные приняты',
  request_card_payment: 'запросить оплату по карте'
};

export const DELIVERY_STATUS_GROUPS = {
  "stopped": [
    "stopped",
    "canceled_by_client",
    "canceled_by_freighter",
    "rejected",
    "not_found"
  ],
  "search": [
    "pending",
    "searching"
  ],
  "execution": [
    "accepted",
    "moved_to_order",
    "on_start_place",
    "loading",
    "moving",
    "on_end_place",
    "unloading",
    "request_card_payment",
    "completed",
    "wait_payment",
    "wait_hand",
    "wait_hand_accept",
    "money_handed",
    "cash_accepted"
  ],
  "completed": [
    "success",
    "money_sent"
  ]
};
