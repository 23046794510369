<form>
  <div class="list-controls">
    <div class="btn-group list-type" role="group">
      <button type="button" class="btn btn-default" [class.active]="!filter.withoutCrew && !filter.ofEmployer"
              (click)="onClickAllFilter()">Все
      </button>
      <button type="button" class="btn btn-default" [class.active]="filter.withoutCrew"
              (click)="onSwitchWithoutCrewFilter()">Без экипажа
      </button>
      <button type="button" class="btn btn-default" *ngIf="employer" (click)="onSwitchEmployerFilter()"
              [class.active]="filter.ofEmployer">Машины водителя
      </button>
    </div>
    <div class="search-field form-group">
      <input class="form-control" type="text" name="number" placeholder="Гос. номер"
             [(ngModel)]="filter.transportNumber" (ngModelChange)="onChangeFilter()">
    </div>
  </div>
</form>

<div class="list-controls">
  <pager [list]="transports" (onPage)="onPaginationPage($event)"></pager>
</div>

<div class="transports-container">
  <table class="transports-table">
    <thead class="transports-table-header">
    <tr>
      <th>Марка</th>
      <th>Кузов</th>
      <th>Номер</th>
      <th class="text-center">Грузоподъёмность, т</th>
      <th class="text-center">Размеры, м х м х м</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let transport of transports.transports" (click)="onSelect(transport)">
      <td>{{ transport.car_model }}</td>
      <td>{{ transport|transportBody }}</td>
      <td>{{ transport.features.number }}</td>
      <td class="text-center">{{ transport|transportCapacity }}</td>
      <td class="text-center">{{ transport|transportSize }}</td>
    </tr>
    </tbody>
  </table>
</div>
