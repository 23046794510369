<div class="loader-box" *ngIf="show && !xmasMode">
  <div class="small loading-text">загрузка</div>
  <div class="loader">
    <div class="element-animation">
      <img src="/assets/images/loader.png" width="1180" height="70">
    </div>
  </div>
  <div class="loading-text sub-text" *ngIf="text">{{ text }}</div>
</div>

<div class="loader-box xmas" *ngIf="show && xmasMode">
  <div class="small loading-text">загрузка</div>
  <div class="loader">
    <div>
      <img src="/assets/images/tree-loader.gif" alt="" width="100%" height="100%" class="tree-img">
    </div>
  </div>
  <div class="loading-text sub-text" *ngIf="text">{{ text }}</div>
</div>
