export enum FilterType {
  NONE,
  WITHIN_15_MINUTES,
  WITHIN_30_MINUTES,
  WITHIN_1_HOUR,
  WITHIN_2_HOUR,
  WITHIN_4_HOUR,
  WITHIN_8_HOUR,
  WITHIN_12_HOUR,
  WITHIN_1_DAY,
  WITHIN_2_DAYS,
  WITHIN_4_DAYS,
  WITHIN_1_WEEK,
  WITHIN_2_WEEK,
  WITHIN_1_MONTH,
  WITHIN_2_MONTH,
  WITHIN_6_MONTH,
  WITHIN_1_YEAR,
  MORE_15_MINUTES,
  MORE_30_MINUTES,
  MORE_1_HOUR,
  MORE_2_HOUR,
  MORE_4_HOUR,
  MORE_8_HOUR,
  MORE_12_HOUR,
  MORE_1_DAY,
  MORE_2_DAYS,
  MORE_4_DAYS,
  MORE_1_WEEK,
  MORE_2_WEEK,
  MORE_1_MONTH,
  MORE_2_MONTH,
  MORE_6_MONTH,
  MORE_1_YEAR,
  NEVER
}

const filterTypes = [
  FilterType.NONE,
  FilterType.WITHIN_15_MINUTES,
  FilterType.WITHIN_30_MINUTES,
  FilterType.WITHIN_1_HOUR,
  FilterType.WITHIN_2_HOUR,
  FilterType.WITHIN_4_HOUR,
  FilterType.WITHIN_8_HOUR,
  FilterType.WITHIN_12_HOUR,
  FilterType.WITHIN_1_DAY,
  FilterType.WITHIN_2_DAYS,
  FilterType.WITHIN_4_DAYS,
  FilterType.WITHIN_1_WEEK,
  FilterType.WITHIN_2_WEEK,
  FilterType.WITHIN_1_MONTH,
  FilterType.WITHIN_2_MONTH,
  FilterType.WITHIN_6_MONTH,
  FilterType.WITHIN_1_YEAR,
  FilterType.MORE_15_MINUTES,
  FilterType.MORE_30_MINUTES,
  FilterType.MORE_1_HOUR,
  FilterType.MORE_2_HOUR,
  FilterType.MORE_4_HOUR,
  FilterType.MORE_8_HOUR,
  FilterType.MORE_12_HOUR,
  FilterType.MORE_1_DAY,
  FilterType.MORE_2_DAYS,
  FilterType.MORE_4_DAYS,
  FilterType.MORE_1_WEEK,
  FilterType.MORE_2_WEEK,
  FilterType.MORE_1_MONTH,
  FilterType.MORE_2_MONTH,
  FilterType.MORE_6_MONTH,
  FilterType.MORE_1_YEAR,
  FilterType.NEVER
];

export class FilterTypeUtils {
  static serialize(type: FilterType): string {
    return FilterType[type];
  }

  static deserialize(type: string): FilterType {
    for(let t of filterTypes) {
      if(FilterTypeUtils.serialize(t) === type)
        return t;
    }

    return FilterType.NONE;
  }
}
