import {Injectable} from "@angular/core";
import {RequestService} from "./request.service";
import {Observable, Subject} from "rxjs";
import {SoundNotificationService} from "./sound-notification.service";
import {UserInfoService} from "./user-info.service";

declare var VoxImplant: any;

const RECONNECT_DELAY = 5000;
const CHECK_STATUS_DELAY = 5000;

@Injectable()
export class VoximplantService {

  private voximplant: any;
  private currentCall: any;

  private sdkReady = false;

  private ringtone = new Audio('/assets/sounds/ringtone.mp3');
  private checkStatusTimer: any;
  private phonesStream = new Subject<string|null>();
  private answerStream = new Subject<string>();
  private incomingCallStream = new Subject<string>();

  incomingCall: boolean;
  muted: boolean = false;
  callState: string;
  callNumber: string = '79169859756';
  callIsActive: boolean = true;
  // callNumber: string;
  // callIsActive: boolean = false;

  constructor(
    private requestService: RequestService,
    private soundNotificationService: SoundNotificationService,
    private userInfoService: UserInfoService
  ) {
    this.ringtone.volume = 0.2;
    this.ringtone.loop = true;
  }

  init(): Promise<void> {
    if(this.voximplant != null)
      return;

    // setTimeout(() => {
    //   this.incomingCall = true;
    //   this.phoneToStream();
    // }, 2000);

    this.voximplant = VoxImplant.getInstance();
    // console.log("voximplant", voximplant);

    this.voximplant.addEventListener(VoxImplant.Events.SDKReady, () => {
      console.log("voximplant SDK initialized");
      this.sdkReady = true;
      this.connect();
    });


    this.voximplant.addEventListener(VoxImplant.Events.ConnectionEstablished, () => this.onConnectionEstablished());
    this.voximplant.addEventListener(VoxImplant.Events.AuthResult, e => this.onAuthResult(e));
    this.voximplant.addEventListener(VoxImplant.Events.ConnectionFailed, () => this.onConnectionFailed());
    this.voximplant.addEventListener(VoxImplant.Events.ConnectionClosed, () => this.onConnectionClosed());

    if(this.userInfoService.areIncomingCallsAllowed()) {
      console.log('Incoming calls was allowed');
      this.voximplant.addEventListener(VoxImplant.Events.IncomingCall, e => this.onIncomingCall(e));
    } else {
      console.log('Incoming calls was not allowed');
    }


    this.voximplant.init({micRequired: true, progressTone: true, progressToneCountry: "RU"});

    return Promise.resolve(null);
  }

  call(number: string) {
    if(this.voximplant == null)
      return;

    console.log("Call to: ", number);

    this.muted = false;
    this.callNumber = number;
    this.incomingCall = false;
    this.currentCall = this.voximplant.call("100" + number);
    this.callState = this.currentCall.state();
    this.soundNotificationService.unmute();
    this.soundNotificationService.notificationVolumeDown();
    console.log('Call state is', this.callState);

    this.phonesStream.next(this.callNumber);

    console.log('Current Call ->>', this.currentCall);
    this.callIsActive = true;
    /* Add event listeners */
    this.currentCall.addEventListener(VoxImplant.CallEvents.Connected, e => this.onCallConnected(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.Disconnected, e => this.onCallDisconnected(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.Failed, e => this.onCallFailed(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.ProgressToneStart, e => this.onProgressToneStart(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.ProgressToneStop, e => this.onProgressToneStop(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.StateUpdated, e => this.onCallStateUpdated(e));

  }

  hangup() {
    if (this.currentCall) {

      try {
        this.currentCall.hangup();
      } catch (e) {

      }
    }
  }

  answer() {
    if (this.currentCall) {

      try {
        this.answerStream.next(this.callNumber);
        this.ringtone.pause();
        this.currentCall.answer();
        this.soundNotificationService.unmute();
      } catch (e) {

      }
    }
  }

  decline() {
    if (this.currentCall) {

      try {
        this.ringtone.pause();
        this.currentCall.decline();
        this.deactivateCurrentCall();
      } catch (e) {

      }
    }
  }

  mute() {
    if (this.currentCall) {
      this.currentCall.muteMicrophone();
    }
    this.muted = true;
  }

  unmute() {
    if (this.currentCall) {
      this.currentCall.unmuteMicrophone();
    }
    this.muted = false;
  }

  getPhonesObservable(): Observable<string|null> {
    return this.phonesStream.asObservable();
  }

  getAnswerObservable(): Observable<string> {
    return this.answerStream.asObservable();
  }

  getIncomingObservable(): Observable<string> {
    return this.incomingCallStream.asObservable();
  }

  private connect(): void {
    this.voximplant.connect();
  }

  private checkStatus() {
    if(!this.userInfoService.isSupportUser())
      return;

    console.log("check status...");
    if(!this.voximplant.connected()) {
      console.log('Disconnected =(');
    } else {
      this.voximplant.getOperatorACDStatus().then(status => {
        console.log('voximplant current status is "' + status + '"');
        if(status === 'BANNED' || status === 'OFFLINE')
        {
          console.log('go to online...');
          this.voximplant.setOperatorACDStatus(VoxImplant.OperatorACDStatuses.Online).then(() => {
            console.log("switching to ready...");
            this.voximplant.setOperatorACDStatus(VoxImplant.OperatorACDStatuses.Ready).then(() => {
              console.log("switching to ready success");
            });
          });
        }
        this.checkStatusTimer = setTimeout(() => this.checkStatus(), CHECK_STATUS_DELAY);
      });
    }
  }

  private stopStatusChecking() {
    console.log("Stop status checking");
    if(this.checkStatusTimer)
      clearTimeout(this.checkStatusTimer);

    this.checkStatusTimer = null;
  }

  private deactivateCurrentCall() {
    this.ringtone.pause();
    this.callIsActive = false;
    this.currentCall = null;
    this.phonesStream.next(null);
    this.muted = false;
    this.soundNotificationService.unmute();
    this.soundNotificationService.notificationVolumeUp();
  }

  private phoneToStream() {
    if(this.callNumber != null) {
      this.phonesStream.next(this.callNumber);
      if(this.incomingCall)
        this.incomingCallStream.next(this.callNumber);
    }
  }

  private onCallConnected(event) {
    // event - the instance of VoxImplant.CallEvents.Connected class, use event.call to get the instance of VoxImplant.Call for this call
    // call was connected successfully
    console.log("onCallConnected: ", event, this.currentCall.state());
    document.getElementById('favicon').setAttribute('href', '/assets/images/ring/active.png');
    this.soundNotificationService.notificationVolumeDown();
  }

  private onCallDisconnected(event) {
    // event - the instance of VoxImplant.CallEvents.Disconnected class, use event.call to get the instance of VoxImplant.Call for this call
    // call was disconnected
    console.log("onCallDisconnected: ", event, this.currentCall.state());
    this.deactivateCurrentCall();
  }

  private onCallFailed(event) {
    // event - the instance of VoxImplant.CallEvents.Failed class, use event.call to get the instance of VoxImplant.Call for this call, or event.code and event.reason to get the status code and the reason of the call failure
    // call failed
    console.log("onCallFailed: ", event, this.currentCall.state());
    this.deactivateCurrentCall();
  }

  private onProgressToneStart(event) {
    // event - the instance of VoxImplant.CallEvents.ProgressToneStart class, use event.call to get the instance of VoxImplant.Call for this call
    // Event dispatched when progress tone playback starts
    console.log("onProgressToneStart: ", event, this.currentCall.state());
  }

  private onProgressToneStop(event) {
    // event - the instance of VoxImplant.CallEvents.ProgressToneStop class, use event.call to get the instance of VoxImplant.Call for this call
    // Event dispatched when progress tone playback stops
    console.log("onProgressToneStop: ", event, this.currentCall.state());
  }

  private onConnectionEstablished() {
    console.log('voximplant connection established');
    this.voximplant.requestOneTimeLoginKey("tsova@call.mover.voximplant.com");
  }

  private onAuthResult(e: any) {
    console.log('voximplant auth...');

    if (e.result) {

      console.log("voximplant was successful authorized");

      this.soundNotificationService.notificationVolumeUp();
      this.voximplant.setOperatorACDStatus(VoxImplant.OperatorACDStatuses.Ready);
      this.checkStatus();
    } else {
      // Authorization failed
      console.log("voximplant authorization failed");

      if (e.code == 302) {
        console.log('request voximplant token...');
        this.requestService.get('/voximplant/token', {
          key: e.key
        }).subscribe(
          r => this.voximplant.loginWithOneTimeKey("tsova@call.mover.voximplant.com", r.body.token),
            () => {
            console.error('error of getting voximplant token');
          }
        );
      }

    }
  }

  private onConnectionFailed() {
    console.log('voximplant connection failed');
    console.log('try to reconnect...');
    this.soundNotificationService.notificationVolumeUp();
    this.stopStatusChecking();
    setTimeout(() => this.connect(), RECONNECT_DELAY);
  }

  private onConnectionClosed() {
    console.log('voximplant connection closed');
    console.log('try to reconnect...');
    this.soundNotificationService.notificationVolumeUp();
    this.stopStatusChecking();
    setTimeout(() => this.connect(), RECONNECT_DELAY);
  }

  private onCallStateUpdated(state: any) {
    console.log('Call state:', state);
    this.callState = state.new;
    if(state.new === 'ENDED') {
      this.decline();
    }
  }

  private onIncomingCall(event: any) {
    if(this.soundNotificationService.isMuted())
      return;

    this.currentCall = event.call;
    this.incomingCall = true;
    this.callState = this.currentCall.state();
    this.soundNotificationService.notificationVolumeDown();
    console.log('Call state is', this.callState);

    console.log("IncomingCall", this.currentCall.number(), event);
    this.currentCall.addEventListener(VoxImplant.CallEvents.Connected, e => this.onCallConnected(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.Disconnected, e => this.onCallDisconnected(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.Failed, e => this.onCallFailed(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.ProgressToneStart, e => this.onProgressToneStart(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.ProgressToneStop, e => this.onProgressToneStop(e));
    this.currentCall.addEventListener(VoxImplant.CallEvents.StateUpdated, e => this.onCallStateUpdated(e));


    if(!this.soundNotificationService.isMuted())
      this.ringtone.play();

    this.callNumber = this.currentCall.number().match(/^.*:(.*?)@.*/)[1];
    this.callIsActive = true;
    this.phoneToStream();
  }

}
