
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {firstValueFrom, Observable} from "rxjs";
import {CompanyClient} from "../_models/company-client";
import {UserStat} from "../_models/user-stat";
import {Account} from "../_models/account";
import {DateTime} from "date-time-js";
import {HttpResponse} from "@angular/common/http";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

@Injectable()
export class CompanyClientService {
  private companyClientsPromise: Promise<CompanyClient[]>;

  constructor(private _requestService: RequestWithErrorHandlerService) {
  }

  getCompanyClients(): Observable<CompanyClient[]> {
    if(this.companyClientsPromise == null) {
      this.companyClientsPromise = firstValueFrom(this._requestService
        .get('/company_clients.json').pipe(
        map(r => r.body as CompanyClient[]))
      );
    }

    return fromPromise(this.companyClientsPromise);
  }

  getUsers(company: CompanyClient): Observable<UserStat[]> {
    return this._requestService
      .get(`/company_clients/${company.id}/users.json`).pipe(
      map(r => r.body as UserStat[]))
    ;
  }

  addUser(company: CompanyClient, account: Account): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`/company_clients/${company.id}/users.json`, this.accountToData(account, true))
    ;
  }

  editUser(company: CompanyClient, account: Account): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`/company_clients/${company.id}/users/${account.id}.json`, this.accountToData(account, true))
    ;
  }

  updateUserActive(company: CompanyClient, account: Account, active: boolean): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`/company_clients/${company.id}/users/${account.id}/active.json`, {
        isActive: active
      })
      ;
  }

  updateUserRole(company: CompanyClient, account: Account, role: string): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`/company_clients/${company.id}/users/${account.id}/role.json`, { role });
  }

  getEmailsForReport(company: CompanyClient): Observable<string[]> {
    return this._requestService
      .get(`/company_clients/${company.id}/emails_for_report.json`).pipe(
      map(r => r.body as string[]))
      ;
  }

  updateEmailsForReport(company: CompanyClient, emails: string[]): Observable<HttpResponse<any>> {
    return this._requestService.put(`/company_clients/${company.id}/emails_for_report.json`, { emails });
  }

  addRegistrationParams(type: string): Observable<HttpResponse<any>> {
    return this._requestService.post(`/company_clients/registration/${type}/params.json`, {});
  }

  private accountToData(account: Account, enabledPhone: boolean): any {
    let birthday = new DateTime(account.birthday);
    let birthdayData = {
      year: birthday.format('yyyy'),
      month: birthday.format('M'),
      day: birthday.format('d')
    };

    return {
      simple_account: {
        name: account.name,
        surname: account.surname,
        patronymic: account.patronymic,
        birthday: birthdayData,
        email: account.email,
        phone: enabledPhone ? account.phone : undefined,
        mainCompanyClient: account.main_company_client ? 'On' : undefined
      }
    };
  }
}
