<div class="col-xs-8 col-md-8 col-centered no-padding row">
  <h1 *ngIf="userInfoService.isAvailableRouteOptimization() && (isFilteredOnce || !tasks.filter.isEmpty() || tasks.tasks.length > 0)">Маршрутизация</h1>
  <h1 *ngIf="!userInfoService.isAvailableRouteOptimization() && (isFilteredOnce || !tasks.filter.isEmpty() || tasks.tasks.length > 0)">Заказы</h1>
  <form class="form-inline orders-forms orders-form-box" *ngIf="isFilteredOnce || !tasks.filter.isEmpty() || tasks.tasks.length > 0">
    <div class="form-group">
      <div class="labeled-select">
        <label for="order-status">Статус заказа</label>
        <select name="status"
                [(ngModel)]="statusFilter"
                (ngModelChange)="onStatusFilterChange()"
                class="orders-control orders-status-select"
                id="order-status"
        >
          <option value="" selected="selected">Любой</option>
          <option *ngFor="let s of availableStatuses" [value]="s[0]">{{ s[1] }}</option>
        </select>
      </div>
    </div>
    <div class="form-group search-box">
      <input
              type="text"
              class="orders-search orders-control"
              name="trackNumber"
              [(ngModel)]="trackNumberFilter"
              (ngModelChange)="onTrackNumberChange()"
              placeholder="Трек-номер"
              size="50">
    </div>
    <div class="form-group search-box">
      <input
              type="text"
              class="orders-search orders-control"
              name="orderCode"
              [(ngModel)]="orderCodeFilter"
              (ngModelChange)="onOrderCodeChange()"
              placeholder="Номер заказа"
              size="50">
    </div>
    <div class="separator"></div>
    <div class="form-group">
      <div class="date-interval">
        <div class="title">Дата подачи</div>
        <div class="inputs">
          <div class="form-group date">
            <order-panel-datepicker [(value)]="arrivalStart" [datepicker]="arrivalStartDatePickerOptions" [timepicker]="false" (dateChange)="onChangeArrivalDateFilter()" name="after" ngDefaultControl verticalStretch="true"></order-panel-datepicker>
          </div>
          <div class="form-group date">
            <order-panel-datepicker [(value)]="arrivalEnd" [datepicker]="arrivalEndDatePickerOptions" [timepicker]="false" (dateChange)="onChangeArrivalDateFilter()" name="before" ngDefaultControl verticalStretch="true"></order-panel-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group search-box">
      <input
              type="text"
              class="orders-search orders-control cargo-search"
              name="cargo"
              [(ngModel)]="cargoFilter"
              (ngModelChange)="onChangeCargoFilter()"
              placeholder="Наименование груза, адрес доставки или артикул"
              size="100">
    </div>
    <div class="form-group" *ngIf="!tasks.filter.isEmpty()">
      <div class="filter-controls-box">
        <span class="clean-filter" (click)="onCleanFilter()">очистить фильтр</span>
      </div>
    </div>
  </form>
  <div class="navigation" *ngIf="!tasks.filter.isEmpty() || tasks.tasks.length > 0">
    <div>
      <button *ngIf="ozonEnabled" class="import-button" type="button" (click)="onClickOzonImportButton()">
        <img src="/assets/images/ozon/ozon-logo.svg" alt="Ozon" height="16"> импорт из Ozon
      </button>
    </div>
    <pager [list]="tasks" (onPage)="onPaginationPage($event)"></pager>
  </div>
  <table class="deliveries-table" *ngIf="tasks.tasks.length > 0">
    <thead>
    <tr class="deliveries-table-header">
      <th>Номер</th>
      <th>Город</th>
      <th>Дата</th>
      <th>Стоимость</th>
      <th>Статус</th>
      <th>Адреса</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let task of tasks.tasks" class="delivery-table-row" [routerLink]="['/complex-deliveries', task.id]">
      <td class="delivery-table-status delivery-number" [routerLink]="['/complex-deliveries', task.id]">{{ task.id }}</td>
      <td>{{ task.city?.name }}</td>
      <td>{{ task.date | dt:'dd.MM.yy' }}</td>
      <td>{{ task.cost }} р.</td>
      <td>{{ task.status|optimizationStatus }}</td>
      <td><complex-delivery-compact-destinations [task]="task"></complex-delivery-compact-destinations></td>
    </tr>
    </tbody>
  </table>
  <div class="show-more-btn">
    <show-more [list]="tasks" (onPage)="onShowMorePage($event)" #showMoreOrders></show-more>
  </div>
  <div class="list-empty" *ngIf="isNewButtonActive">
    <div class="new-order-button" (click)="onStartNewOrder()">
      <div class="icon"><span class="glyphicon glyphicon-plus"></span></div>
      <div class="title">Новый заказ</div>
    </div>
  </div>
</div>

<ozon-import-dialog *ngIf="ozonEnabled"></ozon-import-dialog>
