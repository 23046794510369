import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {JivoSiteService} from "../_services/jivo-site.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'jivo-site',
  templateUrl: './jivo-site.component.html',
  styleUrls: ['./jivo-site.component.css']
})
export class JivoSiteComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private jivoSiteService: JivoSiteService
  ) { }

  ngOnInit() {
    const textScript = this.renderer.createElement('script');
    textScript.src = this.jivoSiteService.chatUrl;

    this.renderer.appendChild(this.document.body, textScript);

    this.jivoSiteService.setupUserData();
  }
}
