import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {CallsListFilter} from "../_models/calls-list-filter";
import {Observable} from "rxjs";
import {CallsList} from "../_models/calls-list";
import {DateUtils} from "../_utils/date-utils";
import {Call} from "../_models/call";

const PAGE_SIZE = 20;

@Injectable()
export class CallService {
  constructor(private _requestService: RequestWithErrorHandlerService) {
  }

  getCalls(page?: number, filter?: CallsListFilter): Observable<CallsList> {
    let filterClone = filter && filter.clone() || new CallsListFilter();

    return this._requestService
      .get('/support/me/calls.json', {
        fromDate: filter && filter.fromDate ? DateUtils.formatDate(filter.fromDate) + " 00:00:00" : null,
        toDate: filter && filter.toDate ? DateUtils.formatDate(filter.toDate) + " 23:59:59" : null,
        number: filter && filter.number || null,
        surname: filter && filter.surname || null,
        offset: (page || 0) * PAGE_SIZE
      }).pipe(
        map(r => new CallsList(
          page || 0,
          PAGE_SIZE,
          parseInt(r.headers.get('X-Total-Count')),
          filterClone,
          r.body as Call[]
        )));
  }
}
