<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row">
  <div class="no-padding employees-forms employees-form-box">
    <form class="form-inline employees-forms">
      <div class="form-group search-box">
        <input
          type="text"
          class="employees-search employees-control"
          name="email"
          [(ngModel)]="email"
          (ngModelChange)="onChangeFilter()"
          placeholder="Email"
          size="50">
      </div>
      <div class="form-group search-box">
        <input
          type="text"
          class="employees-search employees-control"
          name="phone"
          [(ngModel)]="phone"
          (ngModelChange)="onChangeFilter()"
          placeholder="Телефон"
          size="11">
      </div>
      <div class="form-group search-box">
        <input
          type="text"
          class="employees-search employees-control"
          name="surname"
          [(ngModel)]="surname"
          (ngModelChange)="onChangeFilter()"
          placeholder="Фамилия"
          size="20">
      </div>

    </form>
  </div>

  <div class="list-controls">
    <pager [list]="employees" (onPage)="onPaginationPage($event)"></pager>
    <div class="control" [routerLink]="['/employees/new']">
      <div class="add-employee"><span class="glyphicon glyphicon-plus"></span> Добавить водителя</div>
    </div>
  </div>

  <div class="employees-container">
    <table class="employees-table">
      <thead class="employees-table-header">
      <tr>
        <th rowspan="2">e-mail</th>
        <th rowspan="2">Телефон</th>
        <th rowspan="2">Имя</th>
        <th rowspan="2">Онлайн</th>
        <th rowspan="2">Статус</th>
        <th rowspan="2" class="text-center">Активирован</th>
        <th class="text-center" colspan="2">Экипаж</th>
      </tr>
      <tr>
        <th>Машина</th>
        <th class="text-center">Грузчики</th>
      </tr>
      </thead>
      <tbody *ngFor="let employee of employees.employees" class="order-table-row"
             [routerLink]="['/employees', employee.account.employer.id]">
      <tr>
        <td>{{ employee.email }}</td>
        <td class="phone">{{ employee.phone|formatPhone }}</td>
        <td>{{ employee.account|fullName }}</td>
        <td>
          <div class="online-date" *ngIf="employee.online_at">
            <div class="online-flag" [class.online]="isOnline(employee)" [class.offline]="!isOnline(employee)"></div>
            {{ employee.online_at|dt:"dd.MM.yy HH:mm" }}</div>
        </td>
        <td
          class="status status-{{ employee.account.employer.status }}">{{ employee.account.employer.status|employerStatus }}</td>
        <td rowspan="2" class="text-center"><span *ngIf="employee.is_active"
                                                  class="glyphicon glyphicon-ok enabled"></span></td>
        <td rowspan="2"><span
          *ngIf="employees.hasCrewForEmployee(employee)">{{ employees.getCrewForEmployee(employee).transport|transportDescription }}</span>
        </td>
        <td rowspan="2" class="text-center"><span
          *ngIf="employees.hasCrewForEmployee(employee)">{{ employees.getCrewForEmployee(employee).loaders }}<span
          *ngIf="employees.getCrewForEmployee(employee).self_loader" title="сам грузчик">+1</span></span></td>
      </tr>
      <tr class="last-subrow">
        <td colspan="5" class="comment">{{ employee.account.employer.comment }}</td>
      </tr>
      </tbody>
    </table>
    <div class="show-more-btn" *ngIf="showMore">
      <show-more [list]="employees" (onPage)="onShowMorePage($event)"></show-more>
    </div>
  </div>
</div>
