import {Injectable} from "@angular/core";
import {UserInfoService} from "./user-info.service";
import {UserNameUtils} from "../_utils/user-name-utils";
import {LogoutService} from "./logout.service";

@Injectable()
export class JivoSiteService {
  chatUrl = '//code-sb1.jivosite.com/widget/Zmqdh41vjT';

  constructor(private userInfoService: UserInfoService, private logoutService: LogoutService) {
  }

  setupUserData(): void {
    if(!window['jivo_api']) {

      console.log('Wait for a JivoSite chat initialization...');
      setTimeout(() => this.setupUserData(), 5000);

    } else {

      console.log('Setup contact info for a JivoSite chat');

      let userInfo = this.userInfoService.userInfo;

      window['jivo_api'].setContactInfo({
        name: UserNameUtils.SNPToName(userInfo.account),
        email: userInfo.account.email,
        phone: userInfo.account.phone
      });

      if(userInfo.account.company_client) {
        let { id, name } = userInfo.account.company_client;
        window['jivo_api'].setCustomData([{
          title: 'Id компании',
          content: "" + id
        },{
          title: 'Наименование компании',
          content: name
        }]);
      }

      this.logoutService.getUserLogoutObservable().subscribe(() => this.logout());

    }
  }

  logout(): void {
    if(window['jivo_api'])
      window['jivo_api'].clearHistory();
  }
}
