import {Uploader} from "./uploader";
import {CompleteAllUploadsHandler} from "./complete-all-uploads-handler";
import {FileErrorHandler} from "./file-error-handler";
import {UploadError} from "./upload-error";

export class UploadUnit implements CompleteAllUploadsHandler, FileErrorHandler {
  private completeUploadHandler: CompleteAllUploadsHandler;
  private errorUploadHandler: FileErrorHandler;

  readyForUpload = false;
  uploading = false;

  private notUploaded: File[];

  constructor(private _uploader: Uploader) {
    this._uploader.subscribeOnCompleteAll(this);
    this._uploader.subscribeOnFileError(this);
  }

  upload(completeUploadHandler: CompleteAllUploadsHandler, errorUploadHandler: FileErrorHandler): void {
    this.completeUploadHandler = completeUploadHandler;
    this.errorUploadHandler = errorUploadHandler;

    this.notUploaded = [];

    if(this.readyForUpload) {
      this.uploading = true;
      this._uploader.uploadAll();
    } else {
      this.onCompleteAllUploads();
    }
  }

  onCompleteAllUploads() {
    this.uploading = false;

    this._uploader.addToQueue(this.notUploaded);

    if(this.completeUploadHandler)
      this.completeUploadHandler.onCompleteAllUploads();
  }

  onFileUploadError(error: UploadError) {
    this.uploading = false;

    this.notUploaded.push(error.file._file);

    if(this.errorUploadHandler)
      this.errorUploadHandler.onFileUploadError(error);
  }


  get uploader(): Uploader {
    return this._uploader;
  }
}
