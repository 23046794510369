import {debounceTime, distinctUntilChanged, finalize, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {GeoService} from "../_services/geo.service";
import {Address} from "../_models/address";
import {DestinationPoint} from "../_models/destination-point";
import {AutocompleteComponent} from "angular-ng-autocomplete";

@Component({
  selector: 'address-field',
  templateUrl: './address-field.component.html',
  styleUrls: ['./address-field.component.css']
})
export class AddressFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() point: DestinationPoint;
  @Input() isValid = true;
  @Input() disabled = false;
  @Input() context: string;
  @Input() inputClass: string = 'text-input full-width';
  @Input() placeholder: string = 'Адрес';
  @Input() minAddrLengthToSearch = 4;
  @Output() onChange = new EventEmitter<DestinationPoint>();
  @Output() onFocus = new EventEmitter<Subject<DestinationPoint>>();
  @Output() onBlur = new EventEmitter<void>();
  @Output() onKeyUp = new EventEmitter<any>();
  @ViewChild(AutocompleteComponent) autocompleteComponent: AutocompleteComponent;

  addresses: Address[] = [];
  selected: Address;
  isAccuracyAddress = false;
  isSearching = false;

  private searchStream = new Subject<string>();
  private pointsListener = new Subject<DestinationPoint>();
  private accuracyAddressParser = new RegExp('^(.+)\\s*\\(([0-9.]+),\\s*([0-9.]+)\\)$');
  private blurListener: any|null;
  private isLoadingPlace = false;

  constructor(private geoService: GeoService) {
  }

  ngOnInit() {
    if(this.point) {
      this.selected = new Address();
      this.selected.latitude = this.point.lat;
      this.selected.longitude = this.point.lon;
      this.selected.formated_address = this.point.addr;
    }

    this.initSearch();
    this.initPointsListener();
  }

  ngAfterViewInit(): void {
    let _this = this;
    this.blurListener = function() {
      _this.onBlurField();
    };
    this.autocompleteComponent.searchInput.nativeElement.addEventListener('blur', this.blurListener);
  }

  ngOnDestroy(): void {
    if(this.blurListener && this.autocompleteComponent && this.autocompleteComponent.searchInput) {
      this.autocompleteComponent.searchInput.nativeElement.removeEventListener('blur', this.blurListener);
      console.log('removed blur listener from address field');
    }
  }

  customFilter(items: Address[], query: string): Address[] {
    return items;
  }

  private processAccuracyAddress(s: string): boolean {
    console.log('check ' + s);
    let result = this.accuracyAddressParser.exec(s);
    if(result === null) {
      // console.log('not accuracy ' + s);
      this.isAccuracyAddress = false;
      return false;
    }

    console.log('is accuracy');

    this.isAccuracyAddress = true;
    this.selected = new Address();
    this.selected.formated_address = result[0];
    // console.log(result[0]);
    this.selected.latitude = Number.parseFloat(result[2]);
    this.selected.longitude = Number.parseFloat(result[3]);
    this.onSelectAddress(this.selected);
    return true;
  }

  private initSearch() {
    this.searchStream = new Subject<string>();
    this.searchStream.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(t => {
        this.isSearching = true;
        return this.geoService.getAddresses(t, this.context).pipe(finalize(() => this.isSearching = false));
      })
    ).subscribe({
      next: addresses => this.addresses = addresses,
      error: e => {
        console.log(e);
        this.initSearch();
      }
    });
  }

  private initPointsListener() {
    this.pointsListener.subscribe(p => {
      this.selected = new Address();
      this.selected.latitude = p.lat;
      this.selected.longitude = p.lon;
      this.selected.formated_address = p.addr;

      this.onSelectAddress(this.selected);
    });
  }

  private cleanFieldIf(): void {
    setTimeout(() => {
      if(this.isLoadingPlace) {
        this.cleanFieldIf();
        return;
      }

      if(!this.selected || (!this.selected.latitude && !this.selected.google_place_id)) {
        this.selected = new Address();
        this.addresses = [];
      }
    }, 250);
  }

  onSelectAddress(address: Address) {
    console.log(address);
    if(address.google_place_id) {
      this.isLoadingPlace = true;
      this.geoService
        .getGooglePlace(address.google_place_id, this.context)
        .pipe(finalize(() => this.isLoadingPlace = false))
        .subscribe({
          next: address => {
            let point = new DestinationPoint();
            point.lat = address.latitude;
            point.lon = address.longitude;
            point.addr = address.formated_address;
            this.onChange.emit(point);
            this.cleanFieldIf();
          },
          error: () => {}
        });
    } else {
      let point = new DestinationPoint();
      point.lat = address.latitude;
      point.lon = address.longitude;
      point.addr = address.formated_address;
      this.onChange.emit(point);
    }
  }

  onFocusField() {
    console.log('focus address field');
    this.onFocus.emit(this.pointsListener);
  }

  onBlurField() {
    console.log('blur address field');

    this.cleanFieldIf();

    this.onBlur.emit();
  }

  onKeyUpField(event: any) {
    console.log('key up');
    this.onKeyUp.emit(event);
  }

  onChangeAddressSearch(value: Address | string): void {
    if(typeof value != 'string')
      return;

    if(value == '') {
      this.selected = new Address();
      this.addresses = [];
    } else if(value.length < this.minAddrLengthToSearch) {
      this.addresses = [];
    } else if(!this.processAccuracyAddress(value)) {
      this.searchStream.next(value);
    }
  }

  onClearAddress(): void {
    this.selected = new Address();
    this.addresses = [];
  }
}
