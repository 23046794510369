<ng-template ngFor let-pc [ngForOf]="calculation.point_calculations" let-i="index">
  <tr class="header">
    <th colspan="2">{{ i + 1 }}. {{ pc.addr }}</th>
  </tr>
  <tr *ngIf="pc.point_price_with_discount > 0">
    <th>Минимальная стоимость точки</th>
    <td>{{ pc.point_price_with_discount }} р. <s *ngIf="pc.point_price_with_discount != pc.point_price" class="old-price">{{ pc.point_price }} р.</s></td>
  </tr>
  <tr *ngIf="pc.full_time > 0">
    <th>Время пребывания на точке</th>
    <td>{{ pc.full_time }} мин.</td>
  </tr>
  <tr *ngIf="pc.included_time > 0">
    <th>Включённое время</th>
    <td>{{ pc.included_time }} мин.</td>
  </tr>
  <tr *ngIf="pc.additional_time > 0">
    <th>Дополнительное время</th>
    <td>{{ pc.additional_time }} мин. Х {{ pc.additional_minute_price }} р. = {{ pc.additional_time_cost }} р.</td>
  </tr>
  <tr *ngIf="pc.additional_time_pack_cost > 0">
    <th>Дополнительные работы с грузом</th>
    <td>{{ pc.additional_time_pack_cost }} р.</td>
  </tr>
  <tr *ngIf="pc.billable_feed_distance > 0">
    <th>Подача</th>
    <td>{{ pc.billable_feed_distance }} км. * {{ pc.feed_mileage_price }} р. = {{ pc.feed_cost }}</td>
  </tr>
  <tr *ngIf="pc.unloading_zone_price > 0">
    <th>Зона разгрузки {{ pc.unloading_zone_start }}-{{ pc.unloading_zone_end }} км.</th>
    <td [attr.title]="pc.is_discounted_unloading_zone_price ? 'Цена из пакета' : null">{{ pc.unloading_zone_price }} р.<sup *ngIf="pc.is_discounted_unloading_zone_price">*</sup></td>
  </tr>
  <ng-template ngIf [ngIf]="pc.loaders_calculation.total_cost > 0">
    <tr  *ngIf="pc.loaders_calculation.delivery_to_price > 0">
      <th>Разгрузка ({{ pc.loaders_calculation.delivery_to|deliveryTo }})</th>
      <td>{{ pc.loaders_calculation.delivery_to_price }} р.</td>
    </tr>
    <tr *ngIf="pc.loaders_calculation.billable_floors > 0">
      <th>Доплата за {{ pc.loaders_calculation.billable_floors }} эт.</th>
      <td>{{ pc.loaders_calculation.billable_floors }} эт. Х {{ pc.loaders_calculation.floor_price }} р. = {{ pc.loaders_calculation.lifting_cost }} р.</td>
    </tr>
  </ng-template>
  <tr *ngIf="pc.total_cost > 0" class="sum">
    <th>Итоговая стоимость точки</th>
    <td>{{ pc.total_cost }} р.</td>
  </tr>
</ng-template>
<tr class="header">
  <th colspan="2">Итог по точкам</th>
</tr>
<tr>
  <th>Суммарная стоимость точек</th>
  <td>{{ calculation.total_cost }} р.</td>
</tr>
<ng-template ngIf [ngIf]="calculation.over_max_distance_cost > 0">
  <tr class="header">
    <th colspan="2">Превышение максимального пробега</th>
  </tr>
  <tr>
    <th>Максимальный пробег</th>
    <td>{{ calculation.max_distance }} км</td>
  </tr>
  <tr>
    <th>Превышение пробега</th>
    <td>{{ calculation.over_max_distance }} км</td>
  </tr>
  <tr>
    <th>Стоимость превышения пробега</th>
    <td>округление({{ calculation.over_max_distance }} км / {{ calculation.over_max_distance_step }} км + 0.5) * {{ calculation.over_max_distance_step_price }} р. = <span class="text-nowrap">{{ calculation.over_max_distance_cost }} р.</span></td>
  </tr>
</ng-template>
