
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {CrewsList} from "../_models/crews-list";
import {Crew} from "../_models/crew";
import {CrewsListFilter} from "../_models/crews-list-filter";
import {ApiUtilsService} from "./api-utils.service";
import {Freighter} from "../_models/freighter";
import {DateTime} from "date-time-js";
import {Order} from "../_models/order";
import {Employer} from "../_models/employer";
import {Transport} from "../_models/transport";
import {HttpResponse} from "@angular/common/http";

const PAGE_SIZE = 7;

@Injectable()
export class CrewService {
  constructor(private _requestService: RequestWithErrorHandlerService, private apiUtilsService: ApiUtilsService) {
  }

  getCrews(freighter: Freighter, page?: number, filter?: CrewsListFilter, pageSize?: number): Observable<CrewsList> {
    pageSize = pageSize || PAGE_SIZE;
    let filterClone = filter && filter.clone() || new CrewsListFilter();

    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/crews.json`, {
        'employer[status]': filter && filter.status || null,
        'transport[type]': filter && filter.transportBody || null,
        'transport[capacity]': filter && filter.transportCapacity || null,
        compact: true,
        date: filter && filter.date && (new DateTime(filter.date)).format('yyyy-MM-dd HH:mm:ss') || null,
        search: filter && filter.search || null,
        offset: (page || 0) * pageSize,
        size: pageSize
      }).pipe(
      map(r => new CrewsList(
        page || 0,
        pageSize,
        parseInt(r.headers.get('X-Total-Count')),
        filterClone,
        r.body.crews as Crew[])
      ));
  }

  getFastList(search: string): Observable<Crew[]> {
    return this._requestService
      .get(`/freighter/all/crews/fast_list.json`, {s: search}).pipe(
      map(r => r.body as Crew[]))
      ;
  }

  getFastListForFreighter(freighter: Freighter, search: string): Observable<Crew[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/crews/fast_list.json`, {s: search}).pipe(
      map(r => r.body as Crew[]))
      ;
  }

  getCrew(freighter: Freighter, id: number): Observable<Crew> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/crews/${id}.json`).pipe(
      map(r => {
        let data = r.body;
        let crew = data.crew as Crew;
        crew.orders = data.orders as Order[];
        return crew;
      }))
      ;
  }

  countEmployersWithoutCrews(freighter: Freighter, date: Date): Observable<number> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/count/without_crews.json`, {
        date: (new DateTime(date)).format('yyyy-MM-dd HH:mm:ss')
      }).pipe(
      map(r => r.body.count as number))
      ;
  }

  getSingleCrew(freighterId: number, crewId: number): Observable<Crew> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighterId)}/crews/` + crewId + `}.json`).pipe(
      map(r => r.body.crew as Crew));
  }

  addCrew(freighter: Freighter, crew: Crew): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/crews.json`, CrewService.crewToData(crew))
      ;
  }

  updateCrew(freighter: Freighter, crew: Crew): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/crews/${crew.id}.json`, CrewService.crewToData(crew))
      ;
  }

  /**
   * Расформировать экипаж
   *
   * @param freighter
   * @param crew
   */
  disbandCrew(freighter: Freighter, crew: Crew): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/crews/${crew.id}.json`)
      ;
  }

  findCrew(freighter: Freighter, employer: Employer, transport: Transport): Observable<Crew> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/crews/find.json`, {
        transport: transport.id,
        employer: employer.id
      }).pipe(
      map(r => r.body as Crew))
    ;
  }

  private static crewToData(crew: Crew): any {
    return {
      crew: {
        employer: crew.employer.id,
        transport: crew.transport.id,
        loaders: crew.loaders,
        selfLoader: crew.self_loader,
        startDate: crew.start_date,
        paymentDistributionSchema: crew.payment_distribution_schema ? crew.payment_distribution_schema.id : null,
        endDate: crew.end_date,
        tariffTiers: crew.tariff_tiers ? crew.tariff_tiers.map(t => t.id) : [],
        taxi: crew.taxi
      }
    };
  }
}
