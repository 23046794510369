<form (submit)="onSave()">
  <div>
    <label for="loaders">Количество грузчиков</label><br>
  </div>
  <div class="flex-form">
    <div>
      <input type="number" id="loaders" name="loaders" [(ngModel)]="loaders" min="0" max="50">
    </div>
    <div>
      <div>
        <label>
          <input type="checkbox" [(ngModel)]="assembly" name="assembly">Необходима сборка/разборка
        </label>
      </div>
    </div>
  </div>
  <button type="submit" class="save-btn">Сохранить</button>
</form>
