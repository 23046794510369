
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {ApiUtilsService} from "./api-utils.service";
import {Freighter} from "../_models/freighter";
import {Employer} from "../_models/employer";
import {EmployeesList} from "../_models/employees-list";
import {Crew} from "../_models/crew";
import {Auth} from "../_models/auth";
import {EmployerContacts} from "../_models/employer-contacts";
import {Passport} from "../_models/passport";
import {DriverLicense} from "../_models/driver-license";
import {EmployeeDevice} from "../_models/employees/employee-device";
import {LegalEntity} from "../_models/legal-entity";
import {EmployeesFilter} from "../_models/employees/employees-filter";
import {DateTime} from "date-time-js";
import {LegalEntityUtils} from "../_utils/legal-entity-utils";
import {Order} from "../_models/order";
import {HttpResponse} from "@angular/common/http";

const PAGE_SIZE = 12;

@Injectable()
export class EmployeeService {
  constructor(private _requestService: RequestWithErrorHandlerService, private apiUtilsService: ApiUtilsService) {
  }

  getFreighterEmployees(freighter: Freighter, page?: number, filter?: EmployeesFilter): Observable<EmployeesList> {
    let filterClone = filter && filter.clone() || new EmployeesFilter();

    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers.json`, Object.assign({
        offset: (page || 0) * PAGE_SIZE,
        size: PAGE_SIZE
      }, filter && this.createParamsFromFilter(filter) || {})).pipe(
      map(r => new EmployeesList(
        page || 0,
        PAGE_SIZE,
        parseInt(r.headers.get('X-Total-Count')),
        filterClone,
        r.body.users as Auth[],
        r.body.crews as Crew[]
      )));
  }

  getEmployee(freighter: Freighter, employeeId: number): Observable<Auth> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employeeId}.json`).pipe(
      map(r => r.body.user as Auth));
  }

  getEmployeeCrew(employee: Employer): Observable<Crew> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(employee.freighter.id)}/employers/${employee.id}/crew.json`).pipe(
      map(r => r.body as Crew));
  }

  getEmployeesAcceptedOrders(freighter: Freighter, employeeId: number): Observable<Order[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employeeId}/orders/accepted.json`).pipe(
      map(r => r.body as Order[]));
  }

  updateStatus(employer: Employer, status: string): Observable<HttpResponse<any>> {
    return this._requestService.put(`/employers/${employer.id}/status.json`, {status});
  }

  getFastList(search: string): Observable<Employer[]> {
    return this._requestService
      .get('/support/me/fast_list/employers.json', {s: search}).pipe(
      map(r => r.body as Employer[]))
      ;
  }

  getFastListForCustomer(search: string): Observable<Employer[]> {
    return this._requestService
      .get('/customer/me/fast_list/employers.json', {s: search}).pipe(
      map(r => r.body as Employer[]))
      ;
  }

  getFastListForFreighter(freighter: Freighter, search: string): Observable<Employer[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/fast_list.json`, {s: search}).pipe(
      map(r => r.body as Employer[]))
      ;
  }

  updateStatusByFreighter(freighter: Freighter, employer: Employer, status: string): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employer.id}/status.json`, {
        status
      })
      ;
  }

  addEmployee(freighter: Freighter, employee: Auth, featuresFilter: string[] = []): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers.json`,
        this.employeeToData(employee, featuresFilter)
      );
  }

  updateEmployee(freighter: Freighter, employee: Auth, featuresFilter: string[] = []): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employee.account.employer.id}.json`,
        this.employeeToData(employee, featuresFilter)
      );
  }

  updateEmployeeContacts(freighter: Freighter, employerId: number, contacts: EmployerContacts): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employerId}/contacts.json`,
        this.contactsToData(contacts)
      );
  }

  updateEmployeePassport(freighter: Freighter, employerId: number, passport: Passport): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employerId}/passport.json`,
        this.passportToData(passport)
      );
  }

  updateEmployeeDriverLicense(freighter: Freighter, employerId: number, license: DriverLicense): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employerId}/driver_license.json`,
        this.driverLicenseToData(license)
      );
  }

  updateEmployeeDevice(freighter: Freighter, employerId: number, device: EmployeeDevice): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employerId}/device.json`,
        this.deviceToData(device)
      );
  }

  updateEmployeeLegalEntity(freighter: Freighter, employerId: number, legalEntity: LegalEntity): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employerId}/legal_entity.json`,
        this.legalEntityToData(legalEntity)
      );
  }

  unlinkEmployeeLegalEntity(freighter: Freighter, employerId: number): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employerId}/legal_entity.json`,
      );
  }

  deleteEmployeePhoto(freighter: Freighter, employee: Auth): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employee.account.employer.id}/photo.json`)
  }

  deletePassportPhoto(freighter: Freighter, employee: Auth, type: string): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employee.account.employer.id}/passport/photo/${type}.json`)
  }

  deleteDriverLicensePhoto(freighter: Freighter, employee: Auth, type: string): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employee.account.employer.id}/driver_license/photo/${type}.json`)
  }

  deleteTaxpayerCertificateFile(freighter: Freighter, employee: Auth, type: string): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/employers/${employee.account.employer.id}/taxpayer_certificate/file/${type}.json`)
  }

  private contactsToData(contacts: EmployerContacts): any {
    return {
      employer_contacts: {
        whatsapp: contacts.whatsapp,
        telegram: contacts.telegram,
        viber: contacts.viber,
        addPhone: contacts.add_phone,
        addWhatsapp: contacts.add_whatsapp,
        addTelegram: contacts.add_telegram,
        addViber: contacts.add_viber
      }
    }
  }

  private passportToData(passport: Passport): any {
    return {
      employer_passport: {
        type: passport.type,
        series: passport.series,
        number: passport.number,
        issuedBy: passport.issued_by,
        issuedDate: passport.issued_date,
        residency: passport.residency,
        residencePermit: passport.residence_permit,
        specialistVisa: passport.specialist_visa,
        workPermit: passport.work_permit,
        patent: passport.patent,
      }
    };
  }

  private driverLicenseToData(driverLicense: DriverLicense): any {
    return {
      driver_license: {
        type: driverLicense.type,
        categories: driverLicense.categories
      }
    };
  }

  private deviceToData(device: EmployeeDevice): any {
    return {
      employer_device: {
        type: device.type,
        brand: device.brand,
        model: device.model,
        operator: device.operator,
      }
    };
  }

  private employeeToData(employee: Auth, featuresFilter: string[] = []): any {
    return {
      featuresFilter,
      employer: {
        isActive: employee.is_active,
        email: employee.email,
        phone: employee.phone,
        account: {
          name: employee.account.name,
          surname: employee.account.surname,
          patronymic: employee.account.patronymic,
          gender: employee.account.gender,
          birthday: employee.account.birthday,
          city: employee.account.city,
          district: employee.account.district,
          vicinity: employee.account.vicinity,
          subwayStation: employee.account.subway_station,
          employer: {
            features: EmployeeService.applyFilterFeatures(employee.account.employer.features, featuresFilter),
            comment: employee.account.employer.comment,
            drivingExperience: employee.account.employer.driving_experience,
            mainWorkTransport: employee.account.employer.main_work_transport && employee.account.employer.main_work_transport.id,
            paymentDistributionSchema: employee.account.employer.payment_distribution_schema && employee.account.employer.payment_distribution_schema.id
          }
        }
      }
    };
  }

  private legalEntityToData(entity: LegalEntity): any {
    return {
      freighter_legal_entity: LegalEntityUtils.legalEntityData(entity)
    };
  }

  private createParamsFromFilter(filter: EmployeesFilter): any {
    return {
      email: filter.email || null,
      phone: filter.phone || null,
      surname: filter.surname || null,
      withoutCrew: filter.withoutCrew ? 1 : 0,
      crewDate: new DateTime(filter.crewDate).format('yyyy-MM-dd HH:mm:ss')
    };
  }

  private static applyFilterFeatures(features: any, featuresFilter: string[]): any {
    if(featuresFilter.length == 0)
      return features;

    let filteredFeatures = {};
    for(let name of featuresFilter)
      filteredFeatures[name] = features[name];

    return filteredFeatures;
  }
}
