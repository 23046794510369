import { Component, OnInit } from '@angular/core';
import {IntercomService} from '../_services/intercom.service'

@Component({
  selector: 'app-intercom',
  templateUrl: './intercom.component.html',
  styleUrls: ['./intercom.component.css']
})
export class IntercomComponent implements OnInit {

  private appId:number;
  constructor(private _intercomService: IntercomService) { }

  ngOnInit() {
    this.appId = parseInt(this._intercomService.appId);
  }

  getAppId(){
    return this.appId;
  }

}
