
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../_services/alert.service";
import {LoaderService} from "../_services/loader.service";
import {FreighterTransportTariffService} from "../_services/freighter-transport-tariff.service";
import {FreighterTransportTariff} from "../_models/freighter-transport-tariff";

@Component({
  selector: 'app-edit-transport-tariff',
  templateUrl: './edit-transport-tariff.component.html',
  styleUrls: ['./edit-transport-tariff.component.css']
})
export class EditTransportTariffComponent implements OnInit {
  tariff = new FreighterTransportTariff();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private freighterTransportTariffService: FreighterTransportTariffService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        params => {
          this.loadTariff(+params['id']);
        },
        e => console.error(e)
      );
  }

  loadTariff(id: number) {
    this.loaderService.show();
    this.freighterTransportTariffService
      .getTariff(id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        t => this.tariff = t,
        r => {
          if(r.status === 404)
            this.alertService.error('Тариф не найден', true);
        }
      );
  }

  submit() {
    this.loaderService.show();
    this.freighterTransportTariffService
      .updateTariff(this.tariff).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success('Тариф изменён', true);
          this.router.navigate(['/tariffs/transport']);
        },
        () => {}
      )
  }

  onSubmit() {
    this.submit();
  }
}
