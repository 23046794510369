import { Component, OnInit } from '@angular/core';
import {LoaderService} from "../_services/loader.service";

const XMAS_MODE_START = '12-08';
const XMAS_MODE_END = '01-15';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  show: boolean = false;
  text: string;
  xmasMode = false;

  constructor(private loaderService: LoaderService) {
    const now = new Date();
    const curYear = now.getFullYear();
    const nextYear = curYear + 1;
    const xmasModeStart = new Date(`${curYear}-${XMAS_MODE_START}T00:00:00`);
    const xmasModeEnd = new Date(`${nextYear}-${XMAS_MODE_END}T00:00:00`);
    this.xmasMode = now.getTime() >= xmasModeStart.getTime() && now.getTime() <= xmasModeEnd.getTime();
  }

  ngOnInit(): void {
    this.loaderService.getStateListener().subscribe(state => {
      if(typeof state === 'boolean') {
        this.show = state
        if(!state)
          this.text = null;
      } else if(typeof state === 'string') {
        this.text = state;
      }
    });
  }

}
