
import {switchMap, finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Editable} from "../_models/editable";
import {LegalEntity} from "../_models/legal-entity";
import {Freighter} from "../_models/freighter";
import {FreighterClient} from "../_models/freighter-client";
import {LegalEntityService} from "../_services/legal-entity.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {FreighterClientService} from "../_services/freighter-client.service";
import {HttpErrorResponse} from "@angular/common/http";
import {FormErrors} from "../_models/form-errors";

@Component({
  selector: 'freighter-client-legal-entity-form',
  templateUrl: './freighter-client-legal-entity-form.component.html',
  styleUrls: ['./freighter-client-legal-entity-form.component.css']
})
export class FreighterClientLegalEntityFormComponent implements OnInit {
  @Input() legalEntity: Editable<LegalEntity>;
  @Input() freighter: Freighter;
  @Input() client: FreighterClient;
  @Input() readOnly: boolean;
  @Input() linkMode: boolean;

  @Output() cancel = new EventEmitter<void>();
  @Output() saved = new EventEmitter<LegalEntity>();

  constructor(
    private freighterClientService: FreighterClientService,
    private legalEntityService: LegalEntityService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
  }

  private applyToFreighterClient(): void {
    this.loaderService.show();

    this.freighterClientService
      .linkToFreighterClient(this.freighter, this.client.id, this.legalEntity.record.id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success(`Юр. лицо ${ this.legalEntity.record.short_name || this.legalEntity.record.name } прикреплено к клиенту`, true)
          this.onSaved();
        },
        () => {}
      )
  }

  private saveAndApplyToFreighterClient(): void {
    this.loaderService.show();

    this.freighterClientService
      .addAndLinkLegalEntity(this.freighter, this.client.id, this.legalEntity.record).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success(`Юр. лицо "${this.legalEntity.record.short_name || this.legalEntity.record.name}" добавлено`, true);
          this.onSaved();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.legalEntity.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  private update(): void {
    this.loaderService.show();

    this.freighterClientService
      .updateLegalEntity(this.freighter, this.client.id, this.legalEntity.record).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success(`Юр. лицо "${this.legalEntity.record.short_name || this.legalEntity.record.name}" изменено`, true);
          this.onSaved();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.legalEntity.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  private updateAndLink(): void {
    this.loaderService.show();

    this.legalEntityService
      .updateFreighterLegalEntity(this.freighter, this.legalEntity.record).pipe(
      finalize(() => this.loaderService.hide()),
      switchMap(() => this.freighterClientService.linkToFreighterClient(this.freighter, this.client.id, this.legalEntity.record.id)),)
      .subscribe(
        () => {
          this.alertService.success(`Юр. лицо ${ this.legalEntity.record.short_name || this.legalEntity.record.name } прикреплено к клиенту`, true);
          this.onSaved();
        },
        () => {}
      );
  }

  onSaved(): void {
    this.saved.emit(this.legalEntity.record);
  }

  onSave(): void {
    if(this.readOnly)
      this.applyToFreighterClient();
    else if(this.legalEntity.isNew)
      this.saveAndApplyToFreighterClient();
    else if(this.linkMode)
      this.updateAndLink();
    else
      this.update();
  }

  onCancel() {
    this.cancel.emit();

    return false;
  }
}
