
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderPeriod} from "../_models/order-period";
import {Order} from "../_models/order";
import {Crew} from "../_models/crew";
import {Employer} from "../_models/employer";
import {Transport} from "../_models/transport";
import {LoaderService} from "../_services/loader.service";
import {OrderService} from "../_services/order.service";
import {UserInfoService} from "../_services/user-info.service";

@Component({
  selector: 'crew-editor',
  templateUrl: './crew-editor.component.html',
  styleUrls: ['./crew-editor.component.css']
})
export class CrewEditorComponent implements OnInit {
  @Input() order: Order;
  @Input() period: OrderPeriod;
  @Input() editCrew: boolean;

  @Output() onUpdated = new EventEmitter<OrderPeriod>();

  employer: Employer;
  transport: Transport;
  crew: Crew;
  onlyActive: boolean;

  crewsListShown = false;

  constructor(private loaderService: LoaderService,
              private userService: UserInfoService,
              private orderService: OrderService) {
  }

  ngOnInit() {
    this.employer = this.period.employer;
    this.transport = this.period.transport;
  }

  onSelectCrew(crew: Crew) {
    this.crewsListShown = false;
    this.crew = crew;
    this.employer = crew.employer;
    this.transport = crew.transport;

    if(confirm('Подтверждаете изменение экипажа?'))
      this.onSave();
  }

  onSave() {
    this.period.employer = this.employer;
    this.period.transport = this.transport;

    this.loaderService.show();
    this.orderService
      .updatePeriodCrew(this.order, this.period, this.crew).pipe(
      finalize(() => {
        $('#edit-crew-modal').modal('hide');
        this.loaderService.hide();
      }))
      .subscribe(
        () => this.onUpdated.emit(this.period),
        () => {
        }
      );
  }
}
