import {Point} from "../_models/point";

export class MapUtils {
  static point2Map(point: Point): google.maps.LatLngLiteral {
    return {lat: parseFloat(point.lat + ''), lng: parseFloat(point.lng + '')};
  }

  static points2Map(points: Point[]): google.maps.LatLngLiteral[] {
    return points.map(MapUtils.point2Map);
  }
}