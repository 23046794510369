<div class="modal fade" tabindex="-1" role="dialog" id="edit-complex-delivery" #editComplexDelivery>
  <div class="modal-dialog" role="document" *ngIf="optimizationTask">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Заказ #{{ optimizationTask.id }} на {{ optimizationTask.date | dt:'dd.MM.yy' }}</h4>
          <div class="subtitle" *ngIf="optimizationTask.delivery_schema">{{ optimizationTask.delivery_schema.name }}</div>
        </div>
        <div class="controls">
          <button type="button" class="save-btn copy-btn pull-right" (click)="onCreateCopy()" title="Создать копию" *ngIf="mode == 'edit'">Копировать</button>
          <button type="button" class="save-btn import-btn pull-right" (click)="onChangeMode()" *ngIf="optimizationTask.status != 'executed' && !optimizationTask.imported_from">
            <span>
              <span *ngIf="mode == 'edit'">Импорт</span>
              <span *ngIf="mode == 'import'">Редактор</span>
              <span class="glyphicon glyphicon-refresh"></span>
            </span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="form-row" *ngIf="optimizationTask">
          <div class="column storehouse">
            <div class="fields-row select">
              <div class="label">
                <label>Склад</label>
              </div>
              <div class="input">
                <select name="storehouse" [(ngModel)]="storehouseId" [disabled]="optimizationTask.status == 'executed'">
                  <option [value]="storehouse.id" *ngFor="let storehouse of storehouses">{{ storehouse.city.name }}, {{ storehouse.address }}</option>
                  <option value="-1">- добавить новый склад -</option>
                </select>
              </div>
            </div>
            <storehouse-creator *ngIf="storehouseId == -1"></storehouse-creator>
          </div>
        </div>
        <complex-delivery-form
          *ngIf="mode == 'edit'"
          [optimizationTask]="optimizationTask"
          [destinationId]="destinationId"
          [saveEvent]="saveEvent"
          [registerFormEvent]="registerFormListener"
          [geoContext]="optimizationTask.city && optimizationTask.city.geo_context"
          (saved)="onFormSaved()"
        ></complex-delivery-form>
        <complex-delivery-import-form
          *ngIf="mode == 'import'"
          [optimizationTask]="optimizationTask"
          [registerFormEvent]="registerFormListener"
          (saved)="onFormSaved()"
        ></complex-delivery-import-form>
        <div class="summary-separator" *ngIf="optimizationTask.status != 'executed'"></div>
        <div class="form-row date-form" *ngIf="optimizationTask.status != 'executed' && !isRouteOptimizationAvailable">
          <div class="column">
            <date-timeslot label="Время отгрузки"
                           [(dateTimeslot)]="desiredArrival"
                           info="Желаемое время отгрузки перевозчику"
                           storagePrefix="desired_arrival_"
                           [taskToRestore]="optimizationTask"
                           (dateTimeslotChange)="onChangeDesiredArrival()"
                           *ngIf="enabledDesiredArrival && !isExpressMode"></date-timeslot>
            <date-timeslot
                    *ngIf="enabledDeliveryDateTime && isExpressMode"
                    label="Доставка клиенту"
                    info="Дата и время, когда планируется доставка груза клиенту"
                    [(dateTimeslot)]="deliveryDateTime"
                    [fixedTimeSlots]="expressDeliveryTimeSlots"
                    [isDateDisabled]="true"
                    (dateTimeslotChange)="onChangeDeliveryDateTime()"
            ></date-timeslot>
          </div>
        </div>
        <div class="summary" *ngIf="optimizationTask.status != 'executed'">
          <div class="controls" *ngIf="!isRouteOptimizationAvailable && optimizationTask.delivery_schema?.is_multipoint_allowed && (optimizationTask.destinations.length > 1 || mode == 'import')">
            <label><input type="checkbox" name="route_optimization_enabled" [(ngModel)]="isRouteOptimizationEnabled"> Разрешаю менять порядок доставки</label>
          </div>
          <div *ngIf="!(!isRouteOptimizationAvailable && optimizationTask.delivery_schema?.is_multipoint_allowed && (optimizationTask.destinations.length > 1 || mode == 'import'))"></div><!-- этот div нужен, чтобы кнопка "Подтвердить" всегда была справа -->
          <div class="controls">
            <button type="button" class="save-btn" *ngIf="optimizationTask.status != 'executed'" (click)="onAccept()">Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
