import {OrderDraft} from "../../_models/order-draft";
import {Order} from "../../_models/order";
import {OptimizationTaskTransport} from "../../_models/optimization-task-transport";

/**
 * Состояние поиска транспорта
 */
export class TransportSearchState {
  draft: OrderDraft;
  order: Order;
  transport: OptimizationTaskTransport;
}
