
import {finalize, map, mergeMap, switchMap} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Editable} from "../_models/editable";
import {Account} from "../_models/account";
import {UserFormComponent} from "../user-form/user-form.component";
import {FormErrors} from "../_models/form-errors";
import {CompanyClient} from "../_models/company-client";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {CompanyClientService} from "../_services/company-client.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {UserInfoService} from "../_services/user-info.service";
import {from, Subject} from "rxjs";

interface SaveCommand {
}

class SaveAccountCommand implements SaveCommand {
  constructor(public account: Editable<Account>) {
  }
}

class SaveRoleCommand implements SaveCommand {
  constructor(
    public account: Account,
    public role: string
  ) {}
}

class SavedAllCommand implements SaveCommand {
}

class UpdateResult {
  constructor(
    public response: HttpResponse<any>|null,
    public command: SaveCommand
  ) {}
}

@Component({
  selector: 'user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.css']
})
export class UserEditorComponent implements OnInit {
  @ViewChild('editUserDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(UserFormComponent, { static: true }) userForm: UserFormComponent;

  @Output() onUserUpdated = new EventEmitter<Account>();

  account = new Editable<Account>(new Account(), new FormErrors());
  role: string;
  roleEnabled: boolean;
  phoneEnabled: boolean;

  private company: CompanyClient;

  private saveStream: Subject<SaveCommand>;
  private saveCommandsCount = 0;
  private errorsCount = 0;

  constructor(
    private companyClientService: CompanyClientService,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.initSaveStream()
  }

  private initSaveStream() {
    this.saveStream = new Subject<SaveCommand>();
    this.saveCommandsCount = 0;
    this.errorsCount = 0;

    let commandMap = mergeMap(command => {
      if(this.saveCommandsCount === 0)
        this.errorsCount = 0;

      this.saveCommandsCount ++;

      if(command instanceof SaveAccountCommand) {
        this.loaderService.show();
        return this.companyClientService
        .editUser(this.company, command.account.record).pipe(
          finalize(() => this.loaderService.hide()),
          map(response => new UpdateResult(response, command))
        );
      } else if(command instanceof SaveRoleCommand) {
        this.loaderService.show();
        return this.companyClientService
        .updateUserRole(this.company, command.account, command.role).pipe(
          finalize(() => this.loaderService.hide()),
          map(response => new UpdateResult(response, command))
        );
      } else if(command instanceof SavedAllCommand) {
        return from([new UpdateResult(null, command)])
      }
    });
    this.saveStream
      .pipe(commandMap)
      .subscribe({
        next: result => {
          if(result.command instanceof SaveAccountCommand) {
            this.alertService.success('Пользователь изменён');
          } else if(result.command instanceof SaveRoleCommand) {
            this.alertService.success('Роль пользователя изменена');
          } else if(result.command instanceof SavedAllCommand) {
            this.onUserUpdated.emit(this.account.record);
          }
          this.saveCommandsCount --;
          if(this.saveCommandsCount === 0 && this.errorsCount === 0)
            this.hideDialog();
        },
        error: r => {
          if(r instanceof HttpErrorResponse) {
            this.errorsCount ++;

            if(r.status === 400) {
              this.account.errors = r.error as FormErrors;
              this.alertService.error('Не все данные заполнены корректно');
            }
            if(r.status === 405) {
              this.account.errors = r.error as FormErrors;
              this.alertService.error('Изменение роли недопустимо');
            }
          }
          this.initSaveStream();
        }
      })
    ;
  }

  showForCompanyAndUser(company: CompanyClient, account: Account, role: string) {
    this.company = company;
    this.role = role;
    this.roleEnabled = (this.userInfoService.isCompanyClientManager() || this.userInfoService.isPrivilegedUser()) &&
      [ 'ROLE_PARTNER_MANAGER', 'ROLE_CUSTOMER', 'ROLE_DELIVERY_MANAGER' ].indexOf(role) != -1
    ;
    this.phoneEnabled = account.phone == null;

    let accountClone = JSON.parse(JSON.stringify(account)) as Account;
    this.account = new Editable<Account>(accountClone, new FormErrors());

    $(this.dialogEl.nativeElement).modal('show');
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private updateAccount(account: Editable<Account>): void {
    this.saveStream.next(new SaveAccountCommand(account))
  }

  private updateRole(role: string): void {
    this.saveStream.next(new SaveRoleCommand(this.account.record, role));
  }

  onSaveAccount(account: Editable<Account>): void {
    this.updateAccount(account);
  }

  onSaveRole(role: string): void {
    this.updateRole(role);
  }

  onSavedAll(): void {
    this.saveStream.next(new SavedAllCommand());
  }
}
