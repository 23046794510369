import { Component, OnInit } from '@angular/core';
import {Editable} from "../_models/editable";
import {Transport} from "../_models/transport";
import {FormErrors} from "../_models/form-errors";
import {Freighter} from "../_models/freighter";
import {UserInfoService} from "../_services/user-info.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-transport',
  templateUrl: './new-transport.component.html',
  styleUrls: ['./new-transport.component.css']
})
export class NewTransportComponent implements OnInit {
  transport = new Editable(new Transport(), new FormErrors(), true);
  freighter: Freighter;

  constructor(
    private userInfoService: UserInfoService,
    private router: Router
  ) {
    this.transport.record.features = {
      Tip_kuzova: '',
      capacity: ''
    };
  }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();
    this.transport.record.freighter = this.freighter;
  }

  onUpdated(transport: Transport): void {
    this.router.navigate([`/transports`, transport.id]);
  }

  onBackToList(): void {
    this.router.navigate([`/transports`]);
  }
}
