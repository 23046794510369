import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DeliveryProduct} from "../_models/delivery-product";
import {DeliveryProductEditorComponent} from "../delivery-product-editor/delivery-product-editor.component";

@Component({
  selector: 'delivery-product-dialog',
  templateUrl: './delivery-product-dialog.component.html',
  styleUrls: ['./delivery-product-dialog.component.css']
})
export class DeliveryProductDialogComponent implements OnInit, OnDestroy {
  @Output() onLinesUpdated = new EventEmitter<DeliveryProduct>();
  @ViewChild('productDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(DeliveryProductEditorComponent) productsEditor: DeliveryProductEditorComponent;

  deliveryProduct: DeliveryProduct;

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  showDialog(deliveryProduct: DeliveryProduct): void {
    this.deliveryProduct = JSON.parse(JSON.stringify(deliveryProduct));

    $(this.dialogEl.nativeElement).modal('show');
    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  onSave(): void {
    if(!this.productsEditor.isAllLinesValid()) {
      alert("Не все поля заполнены правильно");
      return;
    }

    this.deliveryProduct.lines = this.productsEditor.getValidLines();
    this.hideDialog();
    this.onLinesUpdated.emit(this.deliveryProduct);
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
