export class SummaryReport {
  total: number = 0;
  paid_sum: number = 0;
  payment_waiting_sum: number = 0;
  no_payment_sum: number = 0;
  cash_sum: number = 0;
  card_sum: number = 0;
  bank_sum: number = 0;
  account_sum: number = 0;
  employee_payouts_sum: number = 0;
  service_fees_sum: number = 0;
  payouts_sum: number = 0;
  mover_total_profit_sum: number = 0;
  mover_expected_profit_sum: number = 0;
  mover_received_sum: number = 0;
  debt_to_mover_sum: number = 0;
  paid_orders_count: number = 0;
  payment_waiting_orders_count: number = 0;
  no_payment_orders_count: number = 0;
  cash_orders_count: number = 0;
  card_orders_count: number = 0;
  bank_orders_count: number = 0;
  account_orders_count: number = 0;
  employee_tax_sum: number = 0;
  expected_client_payments_sum = 0;
  expected_employee_payments_sum = 0;
  received_client_payments_sum = 0;
  received_employee_payments_sum = 0;
  received_account_employee_payments_sum = 0;
}
