<div class="form-group">
  <label>Выберите услугу</label>
  <select class="form-control" [(ngModel)]="serviceId" name="serviceId" (ngModelChange)="onChangeServiceId()">
    <option *ngFor="let s of services" [value]="s.id">{{ s.name }}</option>
  </select>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="form-group" [class.has-error]="!serviceCode || serviceCode == ''">
      <label>Артикул</label>
      <input type="text" class="form-control" name="serviceCode" [(ngModel)]="serviceCode">
    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group" [class.has-error]="!serviceName || serviceName == ''">
      <label>Наименование услуги</label>
      <input type="text" class="form-control" name="serviceName" [(ngModel)]="serviceName">
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="form-group">
      <label>Цена для клиента</label>
      <div class="input-group">
        <div class="input-group-addon">₽</div>
        <input type="text" class="form-control" name="priceForClient" [(ngModel)]="priceForClient">
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label>Цена для водителя</label>
      <div class="input-group">
        <div class="input-group-addon">₽</div>
        <input type="text" class="form-control" name="priceForDriver" [(ngModel)]="priceForDriver">
      </div>
    </div>
  </div>
</div>
<div class="form-group" [class.has-error]="servicesCount < 1">
  <label>Количество</label>
  <input type="number" class="form-control" name="servicesCount" [(ngModel)]="servicesCount">
</div>
