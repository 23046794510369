import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {TimeSlot} from "../_models/time-slot";
import {map} from "rxjs/operators";

@Injectable()
export class ExpressDeliveryTimeSlotService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getTimeSlots(): Observable<TimeSlot[]> {
    return this._requestService
      .get('/delivery/slots/express.json')
      .pipe(map(r => r.body as TimeSlot[]))
      ;
  }
}