
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {firstValueFrom, Observable} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

@Injectable()
export class InsuranceLimitsService {
  private limitsPromise: Promise<any>;

  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getLimits(): Observable<any> {
    if(this.limitsPromise == null) {
      this.limitsPromise = firstValueFrom(this._requestService
        .get('/insurances/limits.json').pipe(
        map(r => r.body))
      );
    }
    return fromPromise(this.limitsPromise);
  }
}
