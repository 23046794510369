
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {City} from "../_models/city";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {firstValueFrom, Observable, Subject} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

const ACTIVE_CITY_STORAGE = 'city';

@Injectable()
export class CityService {
  private citiesPromise: Promise<City[]>;
  private _isActiveCityLoaded = false;
  private _activeCity: City;
  private changeCityStream = new Subject<City>();

  constructor(private _requestService: RequestWithErrorHandlerService) {
    this.initActiveCity();
  }

  private initActiveCity(): void {
    let storedActiveCity: number = parseInt(sessionStorage.getItem(ACTIVE_CITY_STORAGE) || '0');
    if(!storedActiveCity) {
      this._isActiveCityLoaded = true;
      return;
    }

    this.getCities()
      .subscribe(
        cities => {
          this._activeCity = cities.find(city => city.id == storedActiveCity);
          this._isActiveCityLoaded = true;
          this.changeCityStream.next(this._activeCity);
        },
        () => {}
      );
  }

  get activeCity(): City {
    return this._activeCity;
  }

  set activeCity(value: City) {
    this._activeCity = value;

    if(value) {
      sessionStorage.setItem(ACTIVE_CITY_STORAGE, value.id + '');
    } else {
      sessionStorage.removeItem(ACTIVE_CITY_STORAGE);
    }

    this.changeCityStream.next(this._activeCity);
  }

  getCities(refresh = false): Observable<City[]> {
    if(refresh)
      this.citiesPromise = null;

    if(this.citiesPromise == null) {
      this.citiesPromise = firstValueFrom(this._requestService
        .get('/cities.json').pipe(
        map(r => r.body as City[]))
      );
    }

    return fromPromise(this.citiesPromise);
  }

  createChangeActiveCityObservable(): Observable<City> {
    return this.changeCityStream.asObservable();
  }

  get isActiveCityLoaded(): boolean {
    return this._isActiveCityLoaded;
  }
}
