
import {of as observableOf, Observable, Subject} from 'rxjs';

import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {
  Component, EventEmitter, OnInit, Output, Input, AfterViewInit, ViewChild, ElementRef,
  OnChanges
} from '@angular/core';
import {CrewsList} from "../_models/crews-list";
import {CrewService} from "../_services/crew.service";
import {LoaderService} from "../_services/loader.service";
import {Page} from "../pager/page";
import {EMPLOYER_STATUSES} from "../_maps/employer-statuses";
import {CrewsListFilter} from "../_models/crews-list-filter";
import {State} from "../_storage/state";
import {Crew} from "../_models/crew";
import {TRANSPORT_BODIES} from "../_maps/transport-bodies";
import {CAPACITIES} from "../_maps/capacities";
import {Freighter} from "../_models/freighter";
import {Employer} from "../_models/employer";
import {ObjectComparator} from '../_utils/ObjComparator'
import {ShowMoreComponent} from '../show-more/show-more.component'
import {UserInfoService} from "../_services/user-info.service";

const FILTER_STORAGE = 'crews_list_filter';
const PAGE_STORAGE = 'crews_list_page';

class ListCommand {
  constructor(private _filter?: CrewsListFilter, private _page?: number) {
  }

  get filter(): CrewsListFilter {
    return this._filter;
  }

  get page(): number {
    return this._page;
  }

  equals(cmd: ListCommand): boolean {
    return this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'crews-list',
  templateUrl: './crews-list.component.html',
  styleUrls: ['./crews-list.component.css']
})
export class CrewsListComponent implements OnInit, OnChanges {

  @ViewChild(ShowMoreComponent, { static: true }) showMore: ShowMoreComponent;

  @Input() freighter: Freighter;
  @Input() employer: Employer;
  @Input() onlyActive: Boolean;
  @Output() onSelect = new EventEmitter<Crew>();

  crews = CrewsList.empty();
  employerStatuses = EMPLOYER_STATUSES;
  transportBodies = TRANSPORT_BODIES;
  capacities = CAPACITIES;
  oldFilter: CrewsListFilter;

  private listCommands = new Subject<ListCommand>();

  constructor(private crewsService: CrewService, private loaderService: LoaderService, private userService: UserInfoService) {
  }

  ngOnInit() {
    if (!this.userService.isCustomer()) {
      this.initCrews();
      this.restoreFilter();
      this.restorePage();
      this.loadCrews();
    }
  }

  ngOnChanges() {
    this.onlyActive == true ? this.crews.filter.status = 'free' : this.crews.filter.status = '';
    this.onChangeFilter();

  }

  loadCrews() {
    this.listCommands.next(new ListCommand(this.crews.filter, this.crews.page));
  }

  private initCrews() {
    this.listCommands = new Subject<ListCommand>();
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        return this.crewsService.getCrews(this.freighter, command.page, command.filter).pipe(finalize(() => this.loaderService.hide()));
      }),
      catchError(e => {
        console.log(e);
        this.initCrews();
        return observableOf<CrewsList>(CrewsList.empty())
      }),)
      .subscribe(list => {
        // Подменяем фильтр на текущий, чтобы не было перезаписи полей фильтра.
        list.filter = this.crews.filter.clone();
        if (ObjectComparator.Compare(this.oldFilter, list.filter)) {

          this.crews = list.concat(this.crews.crews);
        }
        else {
          this.crews = list;
        }
        // this.savePage();
        this.saveFilter();
      });
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.crews.page.toString());
  }

  private restorePage() {
    this.crews.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  private saveFilter() {
    sessionStorage.setItem(FILTER_STORAGE, this.crews.filter.getState().state);
  }

  private restoreFilter() {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if (state)
      this.crews.filter = CrewsListFilter.fromState(new State(state));
    this.oldFilter = this.crews.filter;
  }

  onPage(page: Page) {
    this.listCommands.next(new ListCommand(this.crews.filter.clone(), page.num));
  }

  onChangeFilter() {
    this.showMore.resetCurrPage();
    this.listCommands.next(new ListCommand(this.crews.filter.clone()));
  }

  onChangeSearch(newValue) {
    this.crews.filter.search = newValue;
    this.onChangeFilter();
  }

  onSelectCrew(crew: Crew) {
    this.onSelect.emit(crew);
  }

  saveFilterState() {
    this.oldFilter = this.crews.filter;
  }
}
