import {OnlineFilterItem} from "./online-filter-item";
import {FilterType} from "./filter-type";
import {OnlineFilter} from "./online-filter";
import {DateTime} from "date-time-js";

enum DateUnit {
  MINUTES, HOURS, DAYS, WEEKS, MONTHS, YEARS
}

const dateTimeUnits = {
  MINUTES: 'minute',
  HOURS: 'hour',
  DAYS: 'day',
  WEEKS: 'week',
  MONTHS: 'month',
  YEARS: 'year'
};

export class OnlineFilterUtils {
  static getFilterItems(): OnlineFilterItem[] {
    return [
      new OnlineFilterItem(FilterType.NONE, "Игнорировать"),
      new OnlineFilterItem(FilterType.WITHIN_15_MINUTES, "Присутствовал в течении 15 минут"),
      new OnlineFilterItem(FilterType.WITHIN_30_MINUTES, "Присутствовал в течении 30 минут"),
      new OnlineFilterItem(FilterType.WITHIN_1_HOUR, "Присутствовал в течении 1 часа"),
      new OnlineFilterItem(FilterType.WITHIN_2_HOUR, "Присутствовал в течении 2 часов"),
      new OnlineFilterItem(FilterType.WITHIN_4_HOUR, "Присутствовал в течении 4 часов"),
      new OnlineFilterItem(FilterType.WITHIN_8_HOUR, "Присутствовал в течении 8 часов"),
      new OnlineFilterItem(FilterType.WITHIN_12_HOUR, "Присутствовал в течении 12 часов"),
      new OnlineFilterItem(FilterType.WITHIN_1_DAY, "Присутствовал в течении 1 дня"),
      new OnlineFilterItem(FilterType.WITHIN_2_DAYS, "Присутствовал в течении 2 дней"),
      new OnlineFilterItem(FilterType.WITHIN_4_DAYS, "Присутствовал в течении 4 дней"),
      new OnlineFilterItem(FilterType.WITHIN_1_WEEK, "Присутствовал в течении 1 недели"),
      new OnlineFilterItem(FilterType.WITHIN_2_WEEK, "Присутствовал в течении 2 недель"),
      new OnlineFilterItem(FilterType.WITHIN_1_MONTH, "Присутствовал в течении 1 месяца"),
      new OnlineFilterItem(FilterType.WITHIN_2_MONTH, "Присутствовал в течении 2 месяцев"),
      new OnlineFilterItem(FilterType.WITHIN_6_MONTH, "Присутствовал в течении 6 месяцев"),
      new OnlineFilterItem(FilterType.WITHIN_1_YEAR, "Присутствовал в течении 1 года"),
      new OnlineFilterItem(FilterType.MORE_15_MINUTES, "Отсутствовал более 15 минут"),
      new OnlineFilterItem(FilterType.MORE_30_MINUTES, "Отсутствовал более 30 минут"),
      new OnlineFilterItem(FilterType.MORE_1_HOUR, "Отсутствовал более 1 часа"),
      new OnlineFilterItem(FilterType.MORE_2_HOUR, "Отсутствовал более 2 часов"),
      new OnlineFilterItem(FilterType.MORE_4_HOUR, "Отсутствовал более 4 часов"),
      new OnlineFilterItem(FilterType.MORE_8_HOUR, "Отсутствовал более 8 часов"),
      new OnlineFilterItem(FilterType.MORE_12_HOUR, "Отсутствовал более 12 часов"),
      new OnlineFilterItem(FilterType.MORE_1_DAY, "Отсутствовал более 1 дня"),
      new OnlineFilterItem(FilterType.MORE_2_DAYS, "Отсутствовал более 2 дней"),
      new OnlineFilterItem(FilterType.MORE_4_DAYS, "Отсутствовал более 4 дней"),
      new OnlineFilterItem(FilterType.MORE_1_WEEK, "Отсутствовал более 1 недели"),
      new OnlineFilterItem(FilterType.MORE_2_WEEK, "Отсутствовал более 2 недель"),
      new OnlineFilterItem(FilterType.MORE_1_MONTH, "Отсутствовал более 1 месяца"),
      new OnlineFilterItem(FilterType.MORE_2_MONTH, "Отсутствовал более 2 месяцев"),
      new OnlineFilterItem(FilterType.MORE_6_MONTH, "Отсутствовал более 6 месяцев"),
      new OnlineFilterItem(FilterType.MORE_1_YEAR, "Отсутствовал более 1 года"),
      new OnlineFilterItem(FilterType.NEVER, "Никогда")
    ]
  }

  static buildFilter(type: FilterType): OnlineFilter {
    switch (type) {
      case FilterType.NONE:
        return new OnlineFilter();
      case FilterType.WITHIN_15_MINUTES:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(15, DateUnit.MINUTES));
      case FilterType.WITHIN_30_MINUTES:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(30, DateUnit.MINUTES));
      case FilterType.WITHIN_1_HOUR:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(1, DateUnit.HOURS));
      case FilterType.WITHIN_2_HOUR:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(2, DateUnit.HOURS));
      case FilterType.WITHIN_4_HOUR:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(4, DateUnit.HOURS));
      case FilterType.WITHIN_8_HOUR:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(8, DateUnit.HOURS));
      case FilterType.WITHIN_12_HOUR:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(12, DateUnit.HOURS));
      case FilterType.WITHIN_1_DAY:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(1, DateUnit.DAYS));
      case FilterType.WITHIN_2_DAYS:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(2, DateUnit.DAYS));
      case FilterType.WITHIN_4_DAYS:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(4, DateUnit.DAYS));
      case FilterType.WITHIN_1_WEEK:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(1, DateUnit.WEEKS));
      case FilterType.WITHIN_2_WEEK:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(2, DateUnit.WEEKS));
      case FilterType.WITHIN_1_MONTH:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(1, DateUnit.MONTHS));
      case FilterType.WITHIN_2_MONTH:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(2, DateUnit.MONTHS));
      case FilterType.WITHIN_6_MONTH:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(6, DateUnit.MONTHS));
      case FilterType.WITHIN_1_YEAR:
        return new OnlineFilter(OnlineFilterUtils.createFilterDate(1, DateUnit.YEARS));
      case FilterType.MORE_15_MINUTES:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(15, DateUnit.MINUTES));
      case FilterType.MORE_30_MINUTES:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(30, DateUnit.MINUTES));
      case FilterType.MORE_1_HOUR:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(1, DateUnit.HOURS));
      case FilterType.MORE_2_HOUR:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(2, DateUnit.HOURS));
      case FilterType.MORE_4_HOUR:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(4, DateUnit.HOURS));
      case FilterType.MORE_8_HOUR:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(8, DateUnit.HOURS));
      case FilterType.MORE_12_HOUR:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(12, DateUnit.HOURS));
      case FilterType.MORE_1_DAY:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(1, DateUnit.DAYS));
      case FilterType.MORE_2_DAYS:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(2, DateUnit.DAYS));
      case FilterType.MORE_4_DAYS:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(4, DateUnit.DAYS));
      case FilterType.MORE_1_WEEK:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(1, DateUnit.WEEKS));
      case FilterType.MORE_2_WEEK:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(2, DateUnit.WEEKS));
      case FilterType.MORE_1_MONTH:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(1, DateUnit.MONTHS));
      case FilterType.MORE_2_MONTH:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(2, DateUnit.MONTHS));
      case FilterType.MORE_6_MONTH:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(6, DateUnit.MONTHS));
      case FilterType.MORE_1_YEAR:
        return new OnlineFilter(undefined, OnlineFilterUtils.createFilterDate(1, DateUnit.YEARS));
      case FilterType.NEVER:
        return new OnlineFilter(undefined, undefined, true);
    }
  }

  private static createFilterDate(value: number, unit: DateUnit): Date {
    return new DateTime().subtract(value, dateTimeUnits[DateUnit[unit]]).toDate();
  }
}
