export const TIME_UNITS = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years',
};

export const TIME_UNIT_TRANSLATES = {
  days: 'день',
  weeks: 'неделя',
  months: 'месяц',
  years: 'год',
};
