import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable()
export class NewOrderService {
  private newOrderStartStream = new Subject<void>();

  getObservable(): Observable<void> {
    return this.newOrderStartStream.asObservable();
  }

  startNewOrder(): void {
    this.newOrderStartStream.next();
  }
}