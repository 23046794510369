import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "formatDuration"})
export class FormatDurationPipe implements PipeTransform {
  transform(minutes: number): string {
    let displayHours = Math.floor(minutes / 60);
    let displayMinutes = (Math.ceil(minutes) - displayHours * 60) + "";
    if(displayMinutes.length == 1)
      displayMinutes = "0" + displayMinutes;

    return `${displayHours}:${displayMinutes}`;
  }
}
