import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {throttleTime} from "rxjs/operators";

const NEW_ORDER_THROTTLE_DURATION = 5000;
const NOTIFICATION_HIGH_VOLUME = 1;
const NOTIFICATION_LOW_VOLUME = 0.05;
const AUTO_MUTE_INTERVAL_TO = 5000;
const AUTO_MUTE_INTERVAL_FROM = 1000;
const ALL_MUTE_TTL = 10000;
const MAIN_NOTIFICATION_CHANNEL_NAME = 'mover_main_notification';
const MAIN_NOTIFICATION_CHECK_INTERVAL = 5000;
const STORAGE_NOTIFICATION_SERVICE = 'main_notification_service';
const STORAGE_MAIN_NOTIFICATION_SERVICE = 'main_notification_service';
const STORAGE_MAIN_NOTIFICATION_SERVICE_TIME = 'main_notification_service_time';

declare var BroadcastChannel: any;

@Injectable()
export class SoundNotificationService {
  private newSearchSound = new Audio("/assets/sounds/sonic_ring.mp3");
  private newOrderSound = new Audio("/assets/sounds/girlscream.mp3");
  private simpleNotifySound = new Audio('/assets/sounds/filling-your-inbox.mp3');
  private attentionSound = new Audio('/assets/sounds/attention.mp3')

  private newOrderSoundStream = new Subject<void>();

  private notificationServiceId: number;
  private muted = false;
  private autoMuteTimer: any;
  private mainNotificationChannel: any;
  private mainNotificationToken = 0;
  private mainNotificationLastTime = 0;

  constructor() {
    this.newSearchSound.loop = false;
    this.newOrderSound.loop = false;
    this.simpleNotifySound.loop = false;
    this.attentionSound.loop = false;

    this.initNewOrderSoundStream();
    this.notificationVolumeUp();
    if(typeof BroadcastChannel !== 'undefined')
      this.initMainNotificationServiceOverBroadcast();
    else
      this.initMainNotificationService();
  }

  private initMainNotificationServiceOverBroadcast(): void {
    this.generateMainNotificationToken();
    this.mainNotificationChannel = new BroadcastChannel(MAIN_NOTIFICATION_CHANNEL_NAME);
    this.mainNotificationChannel.onmessage = message => this.onMainNotificationListener(message);
    this.autoMuteTimer = setInterval(() => this.checkForMainNotification(), MAIN_NOTIFICATION_CHECK_INTERVAL);
  }

  private onMainNotificationListener(message: MessageEvent): void {
    let token = message.data;
    if(token == -1 || token >= this.mainNotificationToken)
      this.mute();

    this.mainNotificationLastTime = new Date().getTime();
  }

  private checkForMainNotification(): void {
    if(!this.isMuted()) {
      this.makeAsMainNotification(false);
      return ;
    }

    if(new Date().getTime() - this.mainNotificationLastTime < ALL_MUTE_TTL) {
      return;
    }

    this.shallowUnmute();
    this.generateMainNotificationToken();
    this.makeAsMainNotification(false);
  }

  private makeAsMainNotification(force: boolean): void {
    if (force)
      this.mainNotificationToken = -1;

    this.mainNotificationChannel.postMessage(this.mainNotificationToken);
  }

  private generateMainNotificationToken(): void {
    this.mainNotificationToken = Math.random() * 1000000;
    console.log(this.mainNotificationToken);
  }

  private initMainNotificationService(): void {
    let storedId = sessionStorage.getItem(STORAGE_NOTIFICATION_SERVICE);

    if(storedId === null || storedId === '') {
      this.notificationServiceId = Math.round(Math.random() * 100000);
      sessionStorage.setItem(STORAGE_NOTIFICATION_SERVICE, this.notificationServiceId.toString());
    } else {
      this.notificationServiceId = parseInt(storedId);
    }

    this.autoMute();
  }

  private initNewOrderSoundStream(): void {
    this.newOrderSoundStream.pipe(
      throttleTime(NEW_ORDER_THROTTLE_DURATION)
    ).subscribe(() => this.newOrderSound.play());
  }

  private autoMute(): void
  {
    if(this.autoMuteTimer)
      clearTimeout(this.autoMuteTimer);

    let storedMainService = localStorage.getItem(STORAGE_MAIN_NOTIFICATION_SERVICE);
    let storedMainServiceTime = localStorage.getItem(STORAGE_MAIN_NOTIFICATION_SERVICE_TIME);

    if(storedMainService === null || storedMainServiceTime === null) {
      this.unmute();
    } else {
      let mainServiceId = parseInt(storedMainService);
      let mainServiceTime = parseInt(storedMainServiceTime);
      if(mainServiceId === this.notificationServiceId || new Date().getTime() - mainServiceTime > ALL_MUTE_TTL)
        this.unmute();
      else
        this.mute();
    }

    this.autoMuteTimer = setTimeout(
      () => this.autoMute(),
      Math.round((Math.random() * (AUTO_MUTE_INTERVAL_TO - AUTO_MUTE_INTERVAL_FROM) + AUTO_MUTE_INTERVAL_FROM))
    );
  }

  mute(): void {
    this.muted = true;
    document.getElementById('favicon').setAttribute('href', '/assets/images/mute/muted-logo.png');
  }

  unmute(): void {
    this.shallowUnmute();
    if(typeof BroadcastChannel !== 'undefined') {
      this.makeAsMainNotification(true);
    } else {
      localStorage.setItem(STORAGE_MAIN_NOTIFICATION_SERVICE, this.notificationServiceId.toString());
      localStorage.setItem(STORAGE_MAIN_NOTIFICATION_SERVICE_TIME, new Date().getTime().toString());
    }
  }

  private shallowUnmute(): void {
    this.muted = false;
    document.getElementById('favicon').setAttribute('href', '/assets/images/mute/unmuted-logo.png');
  }

  isMuted(): boolean {
    return this.muted;
  }

  playNewSearchSound(): void {
    if(!this.muted)
      this.newSearchSound.play();
  }

  playSimpleNotifySound(): void {
    if(!this.muted)
      this.simpleNotifySound.play();
  }

  playNewOrderSound(): void {
    if(!this.muted)
      this.newOrderSoundStream.next();
  }

  playAttentionSound(): void {
    if(!this.muted)
      this.attentionSound.play();
  }

  notificationVolumeUp(): void {
    this.newSearchSound.volume = NOTIFICATION_HIGH_VOLUME;
    this.newOrderSound.volume = NOTIFICATION_HIGH_VOLUME;
    this.simpleNotifySound.volume = NOTIFICATION_HIGH_VOLUME;
    this.attentionSound.volume = NOTIFICATION_HIGH_VOLUME;
  }

  notificationVolumeDown(): void {
    this.newSearchSound.volume = NOTIFICATION_LOW_VOLUME;
    this.newOrderSound.volume = NOTIFICATION_LOW_VOLUME;
    this.simpleNotifySound.volume = NOTIFICATION_LOW_VOLUME;
    this.attentionSound.volume = NOTIFICATION_LOW_VOLUME;
  }
}
