<div class="modal fade" tabindex="-1" role="dialog" #transportDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Выбор машины</h4>
        </div>
      </div>
      <div class="modal-body">
        <transport-selector [freighter]="freighter" [date]="date" [employer]="employer"
                            (selected)="onSelected($event)"></transport-selector>
      </div>
    </div>
  </div>
</div>
