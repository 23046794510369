
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {CompanyClientLegalEntity} from "../_models/company-client-legal-entity";
import {LegalEntityService} from "../_services/legal-entity.service";
import {ActivatedRoute} from "@angular/router";
import {LoaderService} from "../_services/loader.service";
import {Editable} from "../_models/editable";
import {FormErrors} from "../_models/form-errors";
import {TitleService} from "../_services/title.service";

@Component({
  selector: 'app-edit-legal-entity',
  templateUrl: './edit-legal-entity.component.html',
  styleUrls: ['./edit-legal-entity.component.css']
})
export class EditLegalEntityComponent implements OnInit {
  legalEntity = new Editable(new CompanyClientLegalEntity(), new FormErrors());

  constructor(
    private legalEntityService: LegalEntityService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        params => this.loadEntity(+params['id']),
        e => console.error(e)
      );
  }

  private loadEntity(id: number) {
    this.loaderService.show();

    this.legalEntityService
      .getCompanyLegalEntity(id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        e => {
          this.legalEntity = new Editable(e, new FormErrors(), false, true);
          this.titleService.changeTitle(`${e.legal_entity.name} - Юр. лицо`)
        },
        () => {}
      )
    ;
  }
}
