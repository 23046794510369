<div class="filters">
  <div class="all" *ngIf="freighters.length == 0 && employers.length == 0">поиск по всем перевозчикам MOVER</div>
  <div class="selected">
    <div class="freighter item" *ngFor="let f of freighters">
      <span class="name">{{ f.name }}</span>
    </div>
    <div class="employer item" *ngFor="let e of employers">
      <span class="name">{{ e.account|fullName }}</span>
    </div>
    <div class="crew item" *ngFor="let c of crews">
      <span class="name">{{ c.employer.account.surname }} / {{ c.transport|transportDescription }}</span>
    </div>
    <div class="edit item" (click)="freightersListDialog.showDialog(freighters, employers, crews)">
      <span class="action">Выбрать из списка</span>
    </div>
    <div class="edit item" [class.active]="isCrewsShownOnMap" (click)="onToggleShowCrewsOnMap()">
      <span class="action"><span class="glyphicon glyphicon-screenshot"></span> Выбрать на карте</span>
    </div>
  </div>
  <form class="wide-tooltip" [class.hidden]="!enabledAutoAssigment">
    <div class="checkbox">
      <label>
        <input type="checkbox" name="autoAssigment" [(ngModel)]="autoAssigment">
        Автоназначение экипажа
        <span class="glyphicon glyphicon-question-sign info-icon" data-toggle="tooltip" data-placement="right"
              title="Назначение экипажа с водителем на заказ вне зависимости от его статуса и положения" #autoAcceptInfo></span>
      </label>
    </div>
  </form>
</div>
<div class="controls">
  <button class="save-btn" (click)="onSave()">Сохранить</button>
</div>
