
import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime, map} from 'rxjs/operators';

import {throwError as observableThrowError, Subject} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {EmployeesList} from "../_models/employees-list";
import {LoaderService} from "../_services/loader.service";
import {UserInfoService} from "../_services/user-info.service";
import {EmployeeService} from "../_services/employer.service";
import {Page} from "../pager/page";
import {Auth} from "../_models/auth";
import {EmployeesFilter} from "../_models/employees/employees-filter";
import {State} from "../_storage/state";

const PAGE_STORAGE = "employees_list_page";
const FILTER_STORAGE = "employees_list_filter";
/**
 * Время (в милисекундах), в течение которого считается, что водитель был онлайн
 */
const ONLINE_TIME_BOUNDARY = 20 * 60 * 1000;

class ListCommand {
  constructor(
    private _filter?: EmployeesFilter,
    private _page?: number,
    private _source?: string,
    private _force?: boolean
  ) {}

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  get force(): boolean {
    return this._force;
  }

  get filter(): EmployeesFilter {
    return this._filter;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {
  employees = EmployeesList.empty();
  private listCommands: Subject<ListCommand>;
  private oldFilter = new EmployeesFilter();
  showMore: boolean;

  email: string;
  phone: string;
  surname: string;
  active: string;

  constructor(
    private employeeService: EmployeeService,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.initEmployees();
    this.restorePage();
    this.restoreFilter();
    this.loadEmployees(this.employees.page, this.employees.filter);
  }

  private initEmployees(): void {
    this.listCommands = new Subject<ListCommand>();
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        return this.employeeService
          .getFreighterEmployees(this.userInfoService.getFreighter(), command.page, command.filter).pipe(
          map(list => [ list, command ])).pipe(
          finalize(() => this.loaderService.hide()))
          ;
      }),
      catchError(e => {
        console.log(e);
        this.initEmployees();
        return observableThrowError(e);
      }),)
      .subscribe(listAndCommand => {
        let list = listAndCommand[0] as EmployeesList;
        let command = listAndCommand[1] as ListCommand;

        if(command.source === 'button') {
          this.employees = list.concat(this.employees)
        } else {
          this.employees = list;
        }

        this.initMoreButton();
        this.savePage();
        this.saveFilter();
      });
  }

  private loadEmployees(page?: number, filter?: EmployeesFilter, source?: string, force?: boolean) {
    this.listCommands.next(new ListCommand(filter?.clone(), page, source, force));
  }

  private reloadEmployees(): void {
    this.loadEmployees(this.employees.page, this.employees.filter, null, true);
  }

  private initMoreButton(): void {
    this.showMore = this.employees.hasMore();
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.employees.page.toString());
  }

  private restorePage() {
    this.employees.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  private saveFilter() {
    sessionStorage.setItem(FILTER_STORAGE, this.employees.filter.getState().state);

    this.oldFilter = this.employees.filter.clone();
  }

  private restoreFilter() {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if(state)
      this.employees.filter = EmployeesFilter.fromState(new State(state));

    this.email = this.employees.filter.email;
    this.phone = this.employees.filter.phone;
    this.surname = this.employees.filter.surname;

    this.oldFilter = this.employees.filter;
  }

  isOnline(employee: Auth): boolean {
    if(!employee.online_at)
      return false;

    let onlineAt = new Date(employee.online_at);
    let curTime = new Date();
    let timeDiff = curTime.getTime() - onlineAt.getTime();

    return timeDiff < ONLINE_TIME_BOUNDARY;
  }

  onShowMorePage(page: Page) {
    this.loadEmployees(page.num, this.employees.filter, "button");
  }

  onPaginationPage(page: Page) {
    this.loadEmployees(page.num, this.employees.filter, "pager");
  }

  onChangeFilter(): void {
    let filter = new EmployeesFilter(
      this.email,
      this.phone,
      this.surname
    );
    if(!filter.equals(this.oldFilter)) {
      console.log('filter changed');
      this.listCommands.next(new ListCommand(filter.clone()));
    }
  }
}
