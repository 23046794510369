import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";
import {ApiUtilsService} from "../_services/api-utils.service";
import {environment} from "../../environments/environment";
import {Image} from "../_models/image";
import {Committable} from "../_committable/committable";
import {CommitCollection} from "../_committable/commit-collection";
import {LoaderService} from "../_services/loader.service";
import {Uploader} from "../_upload/uploader";
import {CompleteAllUploadsHandler} from "../_upload/complete-all-uploads-handler";
import {FileErrorHandler} from "../_upload/file-error-handler";
import {OrderDraftService} from "../_services/order-draft.service";
import {firstValueFrom} from "rxjs";
import {UploadError} from "../_upload/upload-error";
import {TokenService} from "../_services/token.service";
import {UploaderService} from "../_services/uploader.service";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'draft-photo-editor',
  templateUrl: './draft-photo-editor.component.html',
  styleUrls: ['./draft-photo-editor.component.css']
})
export class DraftPhotoEditorComponent implements OnInit, OnDestroy, Committable, CompleteAllUploadsHandler, FileErrorHandler {
  @Input() draft: OrderDraft;
  @Input() commitCollection: CommitCollection;
  @Output() onCommitted = new EventEmitter<OrderDraft>();
  @Output() onCommitError = new EventEmitter<DraftPhotoEditorComponent>();
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  uploader: Uploader|null = null;

  photos: Image[];
  needSave = false;

  private needUpload = false;
  private needUploadWithCommit = false;
  private photosForDelete: Image[] = [];
  private photosForDeleteWithCommit: Image[] = [];
  private uploadErrors: UploadError[] = [];

  constructor(
    private apiUtilsService: ApiUtilsService,
    private draftService: OrderDraftService,
    private loaderService: LoaderService,
    private tokenService: TokenService,
    private uploaderService: UploaderService
  ) {
  }

  ngOnInit() {
    if(this.commitCollection != null)
      this.commitCollection.register(this);

    this.photos = [].concat(this.draft.photos || []);
    this.needUploadWithCommit = this.needUpload = false;
    this.photosForDelete = [];
    this.photosForDeleteWithCommit = [];

    this.initUploader();
  }

  private initUploader(): void {
    this.uploader = new Uploader({
      allowedMimeType: ['image/gif', 'image/jpeg', 'image/png'],
      itemAlias: 'photo',
      url: `${environment.apiEndpoint}${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${this.draft.id}/photos.json`
    });
    this.uploader.subscribeOnCompleteAll(this);
    this.uploader.subscribeOnFileError(this);
  }

  private upload() {
    this.uploadErrors = [];

    if(this.uploader.queue.length == 0) {
      this.onCompleteAllUploads();
      return;
    }

    console.log('upload photos');

    this.uploaderService.prepareToUpload(
      `${environment.apiEndpoint}${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${this.draft.id}/photos.json`,
      this.uploader
    );
    this.uploader.uploadAll();
  }

  private emitUpdate() {
    this.onUpdated.next(this.draft);
  }

  hasUploadErrors() {
    return this.uploadErrors.length > 0;
  }

  getUploadErrors() {
    return this.uploadErrors;
  }

  onFileSelected() {
    this.needUpload = true;
    this.needSave = true;
  }

  onDelete(photo: Image) {
    this.photosForDelete.push(photo);

    let index = this.photos.findIndex(p => p.url == photo.url);
    this.photos.splice(index, 1);
    this.needSave = true;
  }

  commit() {
    console.log('commit photos');

    this.loaderService.show();
    this.upload();
  }

  private deletePhotos() {
    if(this.photosForDeleteWithCommit.length == 0) {
      this.onCommitComplete();
      return;
    }

    console.log('remove photos');
    console.log(this.photosForDeleteWithCommit);

    let deleteChain: Promise<void|HttpResponse<any>> = Promise.resolve();
    for(let image of this.photosForDeleteWithCommit) {
      deleteChain = deleteChain.then(() => {
        console.log(`remove photo ${image.filename}`);
        return firstValueFrom(this.draftService.deletePhoto(this.draft, image));
      });
    }

    deleteChain
      .then(() => this.onCommitComplete())
      .catch(e => {
        console.log('photo remove error');
        console.log(e);
      });
  }

  onSave() {
    this.needUploadWithCommit = this.needUpload;
    this.photosForDeleteWithCommit = [].concat(this.photosForDelete);
    this.emitUpdate();
    this.needSave = false;
  }

  onCompleteAllUploads() {
    if(this.hasUploadErrors()) {
      this.loaderService.hide();
      this.uploader.clearQueue();
      this.onCommitError.emit(this);
    } else {
      this.deletePhotos();
    }
  }

  onFileUploadError(error: UploadError) {
    console.log('upload error');
    console.log(error);

    this.uploadErrors.push(error);
  }

  onCommitComplete() {
    this.loaderService.hide();

    this.onCommitted.emit(this.draft);
  }

  ngOnDestroy(): void {
    this.commitCollection.unregister(this);
  }
}
