/**
 * Статистика по подобранным машинам
 */
export class SearchStat {
  /**
   * Общее количество машин
   */
  transportsCount = 0;
  /**
   * Количество подобранных машин
   */
  foundTransports = 0;
  /**
   * Количество завершённых рейсов
   */
  completed = 0;
  /**
   * Общая стоимость
   */
  cost = 0;
}
