<form *ngIf="legalEntity" (submit)="onSave()">
  <div class="registration__tabs-items">
    <div class="registration__tabs-item">

      <div class="registration__groups border">
        <span class="registration__label">Данные компании</span>
        <div class="registration__group" *ngIf="!readOnly">
          <div class="form-group">
            <company-search-field (onSelect)="onApplySelectedCompany($event)"
                                  (onAutocompleteActive)="onAutocompleteActive($event)"></company-search-field>
            <span class="input-hint">Название организации или ИНН</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-4">
              <div class="form-group">
                <select class="form-control" id="type" [(ngModel)]="legalEntity.record.legal_entity.type" name="type"
                        required [disabled]="readOnly">
                  <option value="ltd">ООО</option>
                  <option value="pe">ИП</option>
                  <option value="self_employed">Самозанятый</option>
                </select>
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.type"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="type"></input-error>
                <span class="input-hint">Например ООО или ИП</span>
              </div>
            </div>
            <div class="col col-8">
              <div class="form-group">
                <input class="form-control" placeholder="Отображаемый бренд" id="shortName"
                       [(ngModel)]="legalEntity.record.legal_entity.short_name" name="shortName" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.shortName"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="shortName"></input-error>
                <span class="input-hint">Краткое наименование</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="Официальное название организации" id="name"
                   [(ngModel)]="legalEntity.record.legal_entity.name" name="name" required [readOnly]="readOnly">
            <input-error [formErrors]="legalEntity.errors" field="legalEntity.name"></input-error>
            <input-error [formErrors]="legalEntity.errors" field="name"></input-error>
            <span class="input-hint">Например ООО «Пример»</span>
          </div>
        </div>
        <div class="registration__group" *ngIf="isRegistrationMode">
          <div class="form-group">
            <input class="form-control" placeholder="E-mail" id="email" type="email"
                   [(ngModel)]="legalEntity.record.legal_entity.email" name="email" required [readOnly]="readOnly">
            <input-error [formErrors]="legalEntity.errors" field="email"></input-error>
            <span class="input-hint">Электронная почта для отправки отчётов, актов и счетов</span>
          </div>
        </div>
      </div>
      <div class="registration__groups">
        <span class="registration__label">Руководитель</span>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="ФИО" id="director"
                   [(ngModel)]="legalEntity.record.legal_entity.director" name="director" [readOnly]="readOnly">
            <span class="input-hint">Фамилия Имя Отчество</span>
          </div>
        </div>
      </div>
      <div class="registration__groups border">
        <span class="registration__label">Лицо с правом подписи</span>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="ФИО" id="signatory_authority"
                   [(ngModel)]="legalEntity.record.legal_entity.signatory_authority" name="signatory_authority"
                   [readOnly]="readOnly">
            <span class="input-hint">Фамилия Имя Отчество</span>
          </div>
        </div>
      </div>
      <div class="registration__groups border">
        <span class="registration__label">Реквизиты</span>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="Фактический адрес" id="actual_address"
                   [(ngModel)]="legalEntity.record.legal_entity.actual_address" name="actual_address"
                   [readOnly]="readOnly">
            <input-error [formErrors]="legalEntity.errors" field="legalEntity.actualAddress"></input-error>
            <input-error [formErrors]="legalEntity.errors" field="actualAddress"></input-error>
            <span class="input-hint">Фактический адрес</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="Юридический адрес" id="legal_address"
                   [(ngModel)]="legalEntity.record.legal_entity.legal_address" name="legal_address"
                   [readOnly]="readOnly">
            <input-error [formErrors]="legalEntity.errors" field="legalEntity.legalAddress"></input-error>
            <input-error [formErrors]="legalEntity.errors" field="legalAddress"></input-error>
            <span class="input-hint">Юридический адрес</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="ИНН" id="inn" [(ngModel)]="legalEntity.record.legal_entity.inn"
                   name="inn" required [readOnly]="readOnly">
            <input-error [formErrors]="legalEntity.errors" field="legalEntity.inn"></input-error>
            <input-error [formErrors]="legalEntity.errors" field="inn"></input-error>
            <span class="input-hint">ИНН</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="КПП" id="kpp" [(ngModel)]="legalEntity.record.legal_entity.kpp"
                       name="kpp" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.kpp"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="kpp"></input-error>
                <span class="input-hint">КПП</span>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="ОГРН" id="ogrn"
                       [(ngModel)]="legalEntity.record.legal_entity.ogrn" name="ogrn" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.ogrn"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="ogrn"></input-error>
                <span class="input-hint">ОГРН</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="ОКТМО" id="oktmo"
                       [(ngModel)]="legalEntity.record.legal_entity.oktmo" name="oktmo" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.oktmo"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="oktmo"></input-error>
                <span class="input-hint">ОКТМО</span>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="ОКПО" id="okpo"
                       [(ngModel)]="legalEntity.record.legal_entity.okpo" name="okpo" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.okpo"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="okpo"></input-error>
                <span class="input-hint">ОКПО</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group" *ngIf="!readOnly">
          <div class="form-group">
            <bank-search-field (onSelect)="onApplySelectedBank($event)"
                               (onAutocompleteActive)="onAutocompleteActive($event)"></bank-search-field>
            <span class="input-hint">Название организации или ИНН</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="Название банка" id="bank"
                   [(ngModel)]="legalEntity.record.legal_entity.bank" name="bank" [readOnly]="readOnly">
            <input-error [formErrors]="legalEntity.errors" field="legalEntity.bank"></input-error>
            <input-error [formErrors]="legalEntity.errors" field="bank"></input-error>
            <span class="input-hint">Название банка</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="ИНН банка" id="bankInn"
                       [(ngModel)]="legalEntity.record.legal_entity.bank_inn" name="bankInn" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.bankInn"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="bankInn"></input-error>
                <span class="input-hint">ИНН банка</span>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="БИК Банка" id="bik"
                       [(ngModel)]="legalEntity.record.legal_entity.bik" name="bik" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.bik"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="bik"></input-error>
                <span class="input-hint">БИК Банка</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="Расчётный счёт" id="checking_account"
                       [(ngModel)]="legalEntity.record.legal_entity.checking_account" name="checking_account"
                       [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.checkingAccount"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="checkingAccount"></input-error>
                <span class="input-hint">Расчётный счёт</span>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="Корреспондентский счёт" id="correspondent_account"
                       [(ngModel)]="legalEntity.record.legal_entity.correspondent_account" name="correspondent_account"
                       [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legalEntity.correspondentAccount"></input-error>
                <input-error [formErrors]="legalEntity.errors" field="correspondentAccount"></input-error>
                <span class="input-hint">Корреспондентский счёт</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group" *ngIf="!isRegistrationMode">
          <div class="row">
            <div class="col col-6">
              <div class="inp-checkbox registration__check">
                <input type="checkbox" [(ngModel)]="legalEntity.record.active" name="active" id="active"
                       [readOnly]="readOnly"> Активное
                <label for="active">
                  <span class="checkbox__box"></span>
                  Юр. лицо по умолчанию, выбираемое для заказов
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="registration__btn" *ngIf="!readOnly">
        <div class="row">
          <div class="col col-6">
            <button class="btn btn-primary btn-block registration__btn-save" type="submit"
                    [disabled]="isAutocompleteActive">
              <span *ngIf="isRegistrationMode">Стать юр. лицом</span>
              <span *ngIf="!isRegistrationMode">Сохранить</span>
            </button>
          </div>
          <div class="col col-6">
            <a href="#" class="registration__btn-reset" (click)="onCancel()">Отменить</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
