import {Component, OnInit, ViewChild} from '@angular/core';
import {UsersFilter} from "../_models/users/users-filter";
import {UserStat} from "../_models/user-stat";
import {UserListAction} from "../users-list/user-list-action";
import {MessageDialogComponent} from "../message-dialog/message-dialog.component";
import {SendBalanceDialogComponent} from "../send-balance-dialog/send-balance-dialog.component";

@Component({
  selector: 'app-message-distribution',
  templateUrl: './message-distribution.component.html',
  styleUrls: ['./message-distribution.component.css']
})
export class MessageDistributionComponent implements OnInit {
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;
  @ViewChild(SendBalanceDialogComponent, { static: true }) balanceDialog: SendBalanceDialogComponent;

  actions: UserListAction[] = [
    new UserListAction(
      '/assets/images/messaging/sms.png',
      'SMS всем найденным',
      (filter, totalCount) => this.onSendSmsToFiltered(filter, totalCount)
    ),
    new UserListAction(
      '/assets/images/messaging/push.png',
      'PUSH всем найденным',
      (filter, totalCount) => this.onSendPushToFiltered(filter, totalCount)
    ),
    new UserListAction(
      '/assets/images/messaging/whatsapp.png',
      'В Whatsapp всем найденным',
      (filter, totalCount) => this.onSendForWhatsappToFiltered(filter, totalCount)
    ),
    new UserListAction(
      '/assets/images/messaging/sms.png',
      'SMS выделенным',
      null,
      users => this.onSendSmsToSelected(users)
    ),
    new UserListAction(
      '/assets/images/messaging/push.png',
      'PUSH выделенным',
      null,
      users => this.onSendPushToSelected(users)
    ),
    new UserListAction(
      '/assets/images/messaging/whatsapp.png',
      'В Whatsapp выделенным',
      null,
      users => this.onSendForWhatsappToSelected(users)
    ),
    new UserListAction(
      '/assets/images/messaging/balance-report.png',
      'Отправить отчёт по балансу',
      null,
      users => this.onSendBalanceReportForSelected(users)
    )
  ];

  constructor() { }

  ngOnInit() {
  }

  private showDialogForFilter(title: string, filter: UsersFilter, messageType: string) {
    this.messageDialog.showDialogForFilter(title, filter, messageType);
  }

  private showDialogForUsers(title: string, users: UserStat[], messageType: string) {
    this.messageDialog.showDialogForUsers(title, users, messageType);
  }

  onSendPushToFiltered(usersFilter: UsersFilter, totalCount: number): void {
    console.log('push to filtered', usersFilter);
    this.showDialogForFilter(`Отправка PUSH всем найденным (${totalCount}) пользователям`, usersFilter, 'push');
  }

  onSendSmsToFiltered(usersFilter: UsersFilter, totalCount: number): void {
    console.log('sms to filtered', usersFilter);
    this.showDialogForFilter(`Отправка SMS всем найденным (${totalCount}) пользователям`, usersFilter, 'sms');
  }

  onSendForWhatsappToFiltered(usersFilter: UsersFilter, totalCount: number): void {
    console.log('for Whatsapp to filtered', usersFilter);
    this.showDialogForFilter(`Отправка в Whatsapp всем найденным (${totalCount}) пользователям`, usersFilter, 'whatsapp');
  }

  onSendPushToSelected(users: UserStat[]): void {
    console.log('push to selected', users);
    this.showDialogForUsers(`Отправка PUSH выбранным (${users.length}) пользователям`, users, 'push');
  }

  onSendSmsToSelected(users: UserStat[]): void {
    console.log('sms to selected', users) ;
    this.showDialogForUsers(`Отправка SMS выбранным (${users.length}) пользователям`, users, 'sms');
  }

  onSendForWhatsappToSelected(users: UserStat[]): void {
    console.log('for Whatsapp to selected', users);
    this.showDialogForUsers(`Отправка в Whatsapp выбранным (${users.length}) пользователям`, users, 'whatsapp');
  }

  onSendBalanceReportForSelected(users: UserStat[]): void {
    this.balanceDialog.showDialogForUsers(users);
  }
}
