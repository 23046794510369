import {Driver} from "./driver";
import {Transport} from "./transport";
import {Order} from "./order";
import {StringPoint} from "../string-point";

export class Crew {
  point: StringPoint;
  driver: Driver;
  transport: Transport;
  arrival_time: number;
  order: Order;
  found_at: number;
  version: number;
  icon: string;

  static copy(from: Crew, to: Crew): void {
    if(!Crew.isEqual(from, to))
      Crew.incVersion(to);

    to.point = from.point;
    to.driver = from.driver;
    to.transport = from.transport;
    to.arrival_time = from.arrival_time;
    to.order = from.order;
    to.found_at = from.found_at;
  }

  private static isEqual(crew1: Crew, crew2: Crew): boolean {
    return crew1.point.lat == crew2.point.lat &&
      crew1.point.lon == crew2.point.lon &&
      crew1.arrival_time == crew2.arrival_time &&
      crew1.order.id == crew2.order.id &&
      crew1.order.status == crew2.order.status;
  }

  static incVersion(crew: Crew): void {
    crew.version = (crew.version || 0) + 1;
  }
}
