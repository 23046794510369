import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IntervalFilter} from "../_models/users/interval-filter";
import {isNumeric} from "../_utils/number-utils";

@Component({
  selector: 'interval-filter',
  templateUrl: './interval-filter.component.html',
  styleUrls: ['./interval-filter.component.css']
})
export class IntervalFilterComponent implements OnInit, OnChanges {
  @Input() filter: IntervalFilter;
  @Output() filterChange = new EventEmitter<IntervalFilter>();

  from: string;
  to: string;
  including: boolean;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.from = this.filter.from != undefined && this.filter.from.toString() || "";
    this.to = this.filter.to != undefined && this.filter.to.toString() || "";
    this.including = this.filter.including;
  }

  onChangeFilter() {
    let from = this.from.trim();
    let to = this.to.trim();

    let numericFrom: number = (from === "" || !isNumeric(from)) ? undefined : parseFloat(from);
    let numericTo: number = (to === "" || !isNumeric(to)) ? undefined : parseFloat(to);

    this.filterChange.emit(new IntervalFilter(numericFrom, numericTo, this.including));
  }
}
