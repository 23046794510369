import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DeliveryHistoryRecord} from "../_models/delivery-history-record";

@Component({
  selector: 'delivery-history',
  templateUrl: './delivery-history.component.html',
  styleUrls: ['./delivery-history.component.css']
})
export class DeliveryHistoryComponent implements OnInit, OnChanges {
  @Input() history: DeliveryHistoryRecord[] = [];
  @Input() loading = false;
  @Input() productVisible = false;

  historySum = new DeliveryHistoryRecord();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calcHistoryTotal();
  }

  private calcHistoryTotal(): void {
    this.historySum = new DeliveryHistoryRecord();

    for(let record of this.history) {
      for(let component in record) {
        if(!record.hasOwnProperty(component) || component == 'reason')
          continue;

        let value = record[component];
        if(value)
          this.historySum[component] = (this.historySum[component] || 0) + value;
      }
    }
  }
}
