<div class="modal fade" tabindex="-1" role="dialog" #draftSpecialDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Вид новой заявки</h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="specials">
          <div
            class="special"
            *ngFor="let draftType of activeDraftTypes"
            (mouseover)="onHoverDraftType(draftType.identifier)"
            (mouseout)="onMouseOutFromDraftType()"
            (click)="onSelectDraftType(draftType)"
          >
            <div class="icon text" *ngIf="!draftType.img">{{ draftType.name }}</div>
            <div class="icon" *ngIf="draftType.img"><img [src]="draftType.img" [alt]="draftType.name" [title]="draftType.name"></div>
          </div>
        </div>
        <div class="description" [ngSwitch]="activeDraftType">
          <div *ngSwitchDefault>
            <h3>&nbsp;</h3>
            <p>&nbsp;</p>
          </div>
          <div *ngSwitchCase="'fix'">
            <h3>Фиксированный тариф</h3>
            <p>
              В фиксированном тарифе фиксируется стоимость пути.
            </p>
            <p>
              Финальная стоимость может увеличиться только за счёт превышения включённого времени работы/ожидания на точках.
            </p>
          </div>
          <div *ngSwitchCase="'none_fix'">
            <h3>Не фиксированный тариф</h3>
            <p>
              В не фиксированном тарифе стоимость зависит от времени работы и от протяженности пути.
            </p>
            <p>
              Стоимость может увеличиться, если пройденный путь или время работы/ожидания превысят расчётные значения.
            </p>
          </div>
          <div *ngSwitchCase="'avito'">
            <h3>Avito</h3>
            <p>
              Создание заявки в режиме Avito.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
