import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Order} from "../_models/order";
import {OrderCalculation} from "../_models/calculation.delivery/order-calculation";
import {ViewSession} from "./view-session";
import {DeliveryCalculationService} from "../_services/delivery-calculation.service";
import {DeliveryHistoryService} from "../_services/delivery-history.service";
import {LoaderService} from "../_services/loader.service";
import {Destination} from "../_models/destination";

@Component({
  selector: 'order-delivery-history',
  templateUrl: './order-delivery-history.component.html',
  styleUrls: ['./order-delivery-history.component.css']
})
export class OrderDeliveryHistoryComponent implements OnInit {
  @ViewChild('issuesDialog', { static: true }) dialogEl: ElementRef;

  order: Order;
  viewSession: ViewSession;

  constructor(
    private deliveryHistoryService: DeliveryHistoryService,
    private deliveryCalculationService: DeliveryCalculationService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
  }

  private loadCalculation() {
    let viewSession = new ViewSession(this.deliveryCalculationService.createOrderCalculationForOrder(this.order));
    this.viewSession = viewSession;

    let loader = Promise.resolve(viewSession);
    for(let destinationCalculation of viewSession.calculation.destinationCalculations) {
      loader = loader.then(s => {
        if(s.interrupted)
          return s;

        return this.deliveryHistoryService
          .listHistoryAtDestination(destinationCalculation.destination)
          .toPromise()
          .then(records => {
            destinationCalculation.historyRecords = records;
            s.loadingProgress --;
            return s;
          })
        ;
      })
    }

    loader.then(s => {
      this.deliveryCalculationService.calcOrderTotal(s.calculation);

      s.loading = false;
      this.loaderService.hide();
    }).catch(() => {
      viewSession.loading = false;
      this.loaderService.hide();
    });
  }

  private interruptLoading() {
    if(this.viewSession)
      this.viewSession.interrupted = true;
  }

  show(order: Order) {
    this.interruptLoading();

    this.order = order;

    $(this.dialogEl.nativeElement).modal('show');
    this.loadCalculation();
  }

  hide() {
    this.interruptLoading();
    $(this.dialogEl.nativeElement).modal('hide');
  }

  showDestinationDetails(destination: Destination): void {
    this.viewSession.showDestinationDetails(destination);
  }

  hideDestinationDetails(destination: Destination): void {
    this.viewSession.hideDestinationDetails(destination);
  }

  isDestinationDetailsVisible(destination: Destination): boolean {
    return this.viewSession.isDestinationDetailVisible(destination);
  }

  switchDestinationDetailsVisible(destination: Destination): void {
    if(this.isDestinationDetailsVisible(destination))
      this.hideDestinationDetails(destination);
    else
      this.showDestinationDetails(destination);
  }
}
