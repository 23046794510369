<div class="pager-box" [class.vertical-stretch]="verticalStretch" [class.no-pages]="!hasPages" *ngIf="hasPages || !hideIfNoPages">
  <div class="go-first pager-btn" [class.disabled]="isFirstPage">
    <a href="#" (click)="onClickPage(0)" title="Первая страница">
      <img src="/assets/images/pager/ic-16-double-chevron.svg" class="prev">
    </a>
  </div>
  <div class="go-previous pager-btn" [class.disabled]="!hasPrev">
    <a href="#" (click)="onClickPage(list.page - 1)" title="Предыдущая страница">
      <img src="/assets/images/pager/ic-16-chevron.svg" class="prev">
    </a>
  </div>

  <div class="pager-counter" *ngIf="hasPages">
    <span class="first-number">{{ rangeStart }}...{{ rangeEnd }}</span> <span class="from-pages">из {{ list.totalCount }}</span>
  </div>

  <div class="pager-counter" *ngIf="!hasPages">
    <span>найдено: </span> <span class="first-number">{{ list.totalCount }}</span>
  </div>

  <div class="go-next pager-btn" [class.disabled]="!hasNext">
    <a href="#" (click)="onClickPage(list.page + 1)" title="Следующая страница">
      <img src="/assets/images/pager/ic-16-chevron.svg" class="next">
    </a>
  </div>
  <div class="go-last pager-btn" [class.disabled]="isLastPage">
    <a href="#" (click)="onClickPage(lastPage)" title="Последняя страница">
      <img src="/assets/images/pager/ic-16-double-chevron.svg" class="next">
    </a>
  </div>
</div>
