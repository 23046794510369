<div class="col-xs-5 col-md-5 col-centered col-slider no-padding row">
  <h1>Схемы распределения средств</h1>
  <div class="list-controls">
    <div class="control">
      <div class="add-schema" routerLink="/payment-distribution-schemas/new"><span class="glyphicon glyphicon-plus"></span> Добавить схему</div>
    </div>
  </div>
  <div class="schemas-container">
    <table class="schemas-table">
      <thead class="schemas-table-header">
      <tr>
        <th>Название</th>
        <th>Тип исполнителя</th>
        <th class="text-center">По умолчанию</th>
        <th width="10%"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let schema of schemas">
        <td class="content" [routerLink]="[ '/payment-distribution-schemas', schema.id ]">
          {{ schema.name }}
          <div *ngIf="schema.description">({{ schema.description }})</div>
        </td>
        <td class="content" [routerLink]="[ '/payment-distribution-schemas', schema.id ]">{{ schema.executor_type|executorEntityType }}</td>
        <td class="text-center content" [routerLink]="[ '/payment-distribution-schemas', schema.id ]"><span *ngIf="schema.default_schema" class="glyphicon glyphicon-ok"></span></td>
        <td class="text-nowrap">
          <button type="button" class="btn btn-default btn-xs" *ngIf="schema.is_removable" (click)="onRemoveSchema(schema)"><span class="glyphicon glyphicon-trash"></span></button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
