import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FreighterClient} from "../_models/freighter-client";
import {Freighter} from "../_models/freighter";
import {FreighterClientService} from "../_services/freighter-client.service";

@Component({
  selector: 'freighter-client-editor',
  templateUrl: './freighter-client-editor.component.html',
  styleUrls: ['./freighter-client-editor.component.css']
})
export class FreighterClientEditorComponent implements OnInit, OnChanges {
  @Input() client?: FreighterClient;
  @Input() freighter: Freighter;
  @Input() enabledNoSelection = true;
  @Output() clientChange = new EventEmitter<FreighterClient>();

  editableClient: FreighterClient;
  clients: FreighterClient[] = [];

  constructor(private freighterClientService: FreighterClientService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['client']) {
      this.editableClient = this.client ? JSON.parse(JSON.stringify(this.client)) as FreighterClient : new FreighterClient();
      if(!this.editableClient.id && this.editableClient.id != 0)
        this.clearClient();

      this.loadClients();
    }
  }

  private loadClients(): void {
    this.freighterClientService
      .getAllClients(this.freighter)
      .subscribe(
        clients => this.clients = clients,
        () => {}
      )
    ;
  }

  private clearClient(): void {
    this.editableClient.id = 0;
    this.editableClient.name = undefined;
    this.editableClient.contact_name = undefined;
    this.editableClient.contact_phone = undefined;
  }

  private syncWithSelectedClient(): void {
    if(this.editableClient.id == 0) {
      this.clearClient();
      return;
    }

    this.editableClient = this.clients.find(c => c.id == this.editableClient.id);
  }

  onSelectClient(): void {
    this.syncWithSelectedClient();
  }

  onSave() {
    this.clientChange.emit(this.editableClient);
  }
}
