import {debounceTime, distinctUntilChanged, finalize, map, switchMap} from 'rxjs/operators';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OptimizationTaskService} from "../_services/optimization-task.service";
import {LoaderService} from "../_services/loader.service";
import {OptimizationTaskList} from "../_models/optimization-task-list";
import {Page} from "../pager/page";
import {CityService} from "../_services/city.service";
import {Subject, Subscription} from "rxjs";
import {UserInfoService} from "../_services/user-info.service";
import {OzonImportDialogComponent} from "../ozon-import-dialog/ozon-import-dialog.component";
import {OptimizationTask} from "../_models/optimization-task";
import {TitleService} from "../_services/title.service";
import {OptimizationTaskListFilter} from "../_models/optimization-task-list-filter";
import {State} from "../_storage/state";
import {ObjectComparator} from "../_utils/ObjComparator";
import {NewOrderService} from "../_services/new-order.service";

const FILTER_STORAGE = 'optimization_task_list_filter';
const PAGE_STORAGE = 'optimization_task_list_page';

export class ListCommand {
  constructor(private _filter?: OptimizationTaskListFilter, private _page?: number, private _source?: string) {
  }

  get filter(): OptimizationTaskListFilter {
    return this._filter;
  }

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  equals(cmd: ListCommand): boolean {
    return this.page === cmd.page && this.filter.equals(cmd.filter);
  }

  getClone(): ListCommand {
    return new ListCommand(this.filter.clone(), this.page, this.source);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    console.log(a, b);
    return a.equals(b);
  }
}

@Component({
  selector: 'app-complex-deliveries',
  templateUrl: './complex-deliveries.component.html',
  styleUrls: ['./complex-deliveries.component.css']
})
export class ComplexDeliveriesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(OzonImportDialogComponent) ozonImportDialog: OzonImportDialogComponent;

  tasks = OptimizationTaskList.empty();
  ozonEnabled = false;
  statusFilter: string;
  availableStatuses: [string, string][] = [];
  trackNumberFilter: string;
  orderCodeFilter: string;
  arrivalStart: Date;
  arrivalEnd: Date;
  cargoFilter: string;
  isNewButtonActive = false;
  isFilteredOnce = false;

  arrivalStartDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'от'
  };
  arrivalEndDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'до'
  };

  private listStream = new Subject<ListCommand>();

  private oldFilter: OptimizationTaskListFilter;

  private changeCitySubscription: Subscription;

  constructor(
    private optimizationTaskService: OptimizationTaskService,
    private cityService: CityService,
    private newOrderService: NewOrderService,
    private loaderService: LoaderService,
    public userInfoService: UserInfoService,
    private titleService: TitleService
  ) { }

  ngAfterViewInit(): void {
    // setTimeout(() => this.ozonImportDialog.show(), 2000);
  }

  ngOnInit() {
    this.initListStream();
    this.initFilters();
    this.initOzon();
    this.initTitle();
    this.restoreFilter();
    this.refreshFilters();
    this.restorePage();
    if(this.cityService.isActiveCityLoaded)
      this.loadTasks();
  }

  private initTitle(): void {
    if(!this.userInfoService.isAvailableRouteOptimization())
      this.titleService.changeTitle('Заказы');
  }

  private initCityFilter(): void {
    this.changeCitySubscription = this.cityService.createChangeActiveCityObservable()
      .subscribe(
        () => {
          this.tasks = OptimizationTaskList.empty();
          this.loadTasks();
        }
      );
  }

  private initFilters(): void {
    this.initCityFilter();
    this.initStatusesFilter();
    this.initTrackNumberFilter();
    this.initOrderCodeFilter();
  }

  private initStatusesFilter(): void {
    if(this.userInfoService.isAvailableRouteOptimization()) {
      this.availableStatuses = [
        [ 'no_calc', 'Редактирование' ],
        [ 'wait_calc,calculating', 'Оптимизация' ],
        [ 'no_transport,priority_point_unreachable,cant_optimize,error,no_stock', 'Ошибка оптимизации' ]
      ];
    } else {
      this.availableStatuses = [[ 'no_calc', 'Редактирование' ]];
    }

    this.availableStatuses.push(
      [ 'executed', 'Отправлен на исполнение' ],
      [ '_searching', 'Подбор исполнителей' ],
      [ '_found', 'Исполнитель назначен' ],
      [ '_executing', 'Выполняется доставка' ],
      [ '_completed_partially', 'Частично завершённые' ],
      [ '_completed', 'Полностью завершённые' ]
    );
  }

  private initTrackNumberFilter(): void {
    this.trackNumberFilter = '';
  }

  private initOrderCodeFilter(): void {
    this.orderCodeFilter = '';
  }

  private refreshFilters(): void {
    this.refreshStatusFilter();
    this.refreshTrackNumberFilter();
    this.refreshOrderCodeFilter();
    this.refreshArrivalDatesFilter();
    this.refreshCargoFilter();
  }

  private refreshStatusFilter(): void {
    let statuses = this.tasks.filter.status ? this.tasks.filter.status.split(',') : [];
    let executionStatuses = this.tasks.filter.executionStatus
      ? this.tasks.filter.executionStatus.split(',').map(s => '_' + s)
      : [];

    statuses.push(...executionStatuses);

    this.statusFilter = statuses.join(',');

    console.log(this.statusFilter);
  }

  private refreshTrackNumberFilter(): void {
    this.trackNumberFilter = this.tasks.filter.trackNumber;
  }

  private refreshOrderCodeFilter(): void {
    this.orderCodeFilter = this.tasks.filter.orderCode;
  }

  private refreshArrivalDatesFilter(): void {
    this.arrivalStart = this.tasks.filter.arrivalStartDate;
    this.arrivalEnd = this.tasks.filter.arrivalEndDate;
  }

  private refreshCargoFilter(): void {
    this.cargoFilter = this.tasks.filter.cargo;
  }

  private initListStream(): void {
    this.listStream = new Subject<ListCommand>();
    this.listStream.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        this.isNewButtonActive = false;
        return this.optimizationTaskService
          .getTasks(command.page, command.filter)
          .pipe(
            map(list => { return {list, command}}),
            finalize(() => {
              this.loaderService.hide();
            })
          )
          ;
      }),
    ).subscribe({
        next: ({list, command}) => {
          // list.tasks = [];
          // list.totalCount = 0;

          list.tasks.forEach(t => this.extendStatus(t));

          if(command.source == 'button' && ObjectComparator.Compare(this.oldFilter, list.filter)) {
            this.tasks = list.concat(this.tasks.tasks);
          } else {
            this.tasks = list;
          }

          this.oldFilter = list.filter.clone();

          if(!this.isFilteredOnce && !list.filter.isEmpty())
            this.isFilteredOnce = true;

          this.isNewButtonActive = !this.isFilteredOnce && list.filter.isEmpty() && list.tasks.length === 0;

          this.savePage();
          this.saveFilter();
        },
        error: () => this.initListStream()
      }
    )
    ;
  }

  private loadTasks() {
    this.listStream.next(new ListCommand(this.tasks.filter.clone(), this.tasks.page));
  }

  private loadFirstPage() {
    this.tasks.page = 0;
    this.loadTasks();
  }

  private extendStatus(task: OptimizationTask): void {
    if(task.status == 'executed' && task.execution_status)
      task.status = '_' + task.execution_status;
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.tasks.page.toString());
  }

  private cleanFilter(): void {
    this.tasks.filter = new OptimizationTaskListFilter();
    this.refreshFilters();
    this.loadFirstPage();
  }

  private restorePage() {
    this.tasks.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  private initOzon(): void {
    let company = this.userInfoService.userInfo.account.company_client;
    let trackingServices = company && company.tracking_services || [];

    this.ozonEnabled = trackingServices.indexOf('ozon') >= 0;
  }

  private saveFilter(): void {
    sessionStorage.setItem(FILTER_STORAGE, this.tasks.filter.getState().state);
  }

  private restoreFilter(): void {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if(state)
      this.tasks.filter = OptimizationTaskListFilter.fromState(new State(state));

    this.oldFilter = this.tasks.filter.clone();
  }

  private applyStatusFilter(): void {
    let selectedStatuses = this.statusFilter.split(',');
    let statuses: string[] = [];
    let executionStatuses: string[] = [];
    for(let status of selectedStatuses) {
      if(status.startsWith('_'))
        executionStatuses.push(status.substring(1));
      else
        statuses.push(status);
    }
    this.tasks.filter.status = statuses.join(',');
    this.tasks.filter.executionStatus = executionStatuses.join(',');
  }

  private applyTrackNumberFilter(): void {
    this.tasks.filter.trackNumber = this.trackNumberFilter;
  }

  private applyOrderCodeFilter(): void {
    this.tasks.filter.orderCode = this.orderCodeFilter;
  }

  private applyArrivalDatesFilter(): void {
    this.tasks.filter.arrivalStartDate = this.arrivalStart;
    this.tasks.filter.arrivalEndDate = this.arrivalEnd;
  }

  private applyCargoFilter(): void {
    this.tasks.filter.cargo = this.cargoFilter;
  }

  onPaginationPage(page: Page) {
    this.tasks.page = page.num;
    this.loadTasks();
  }

  onClickOzonImportButton() {
    this.ozonImportDialog.show();
  }

  onShowMorePage(page: Page) {
    this.listStream.next(new ListCommand(this.tasks.filter.clone(), page.num, 'button'));
  }

  onStatusFilterChange(): void {
    this.applyStatusFilter();
    this.loadFirstPage();
  }

  onTrackNumberChange(): void {
    this.applyTrackNumberFilter();
    this.loadFirstPage();
  }

  onOrderCodeChange(): void {
    this.applyOrderCodeFilter();
    this.loadFirstPage();
  }

  onChangeArrivalDateFilter() {
    this.applyArrivalDatesFilter();
    this.loadFirstPage();
  }

  onChangeCargoFilter() {
    this.applyCargoFilter();
    this.loadFirstPage();
  }

  onCleanFilter() {
    this.cleanFilter();
  }

  onStartNewOrder() {
    this.newOrderService.startNewOrder();
  }

  ngOnDestroy(): void {
    this.changeCitySubscription.unsubscribe();
  }
}
