
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {SupportNotificationsList} from "../_models/support-notifications-list";
import {SupportNotification} from "../_models/support-notification";

const PAGE_SIZE = 20;

@Injectable()
export class SupportNotificationService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getNotifications(page?: number): Observable<SupportNotificationsList> {
    let searchParams = {};

    searchParams['offset'] = (page || 0) * PAGE_SIZE;
    searchParams['size'] = PAGE_SIZE;

    return this._requestService
      .get('/support/me/notifications.json', searchParams).pipe(
      map(r => new SupportNotificationsList(
        page || 0,
        PAGE_SIZE,
        parseInt(r.headers.get('X-Total-Count')),
        r.body as SupportNotification[]
      )))
      ;
  }

}
