import { EventEmitter } from '@angular/core';

export interface buttonInterface {
  text: string;
  link?: any;
  type: string;
  clickEvent?: EventEmitter<{}>;


}

export class noticeButton implements buttonInterface {
  text: string;
  link?: any;
  type: string;
  clickEvent?: EventEmitter<{}>;

  constructor(text: string, type: string, link: any) {
    this.text = text;
    this.type = type;
    this.link = link
  }
}

export class callButton implements buttonInterface {
  text: string;
  link?: any;
  type: string;
  clickEvent?: EventEmitter<{}>;

  constructor(text: string, public phone: string) {
    this.text = text;
    this.type = 'call';
  }
}

export class acceptOrderButton implements buttonInterface
{
  text: string;
  type: string;
  orderId: number;
  clickEvent?: EventEmitter<{}>;

  constructor(text: string, orderId: number)
  {
    this.text = text;
    this.type='acceptOrder';
    this.orderId = orderId;
  }
}
