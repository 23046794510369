import {Pipe, PipeTransform} from "@angular/core";
import {Transport} from "../_models/transport";
import {TransportUtils} from "../_utils/transport-utils";

@Pipe({name: "transportSize"})
export class TransportSizePipe implements PipeTransform {
  transform(transport: Transport): string {
    return TransportUtils.getTransportSizeDescription(transport);
  }
}
