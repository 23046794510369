<form>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-addon"><span class="glyphicon glyphicon-search"></span></div>
      <address-field (onChange)="onAddressFound($event)"
                     inputClass="form-control"
                     placeholder="Адрес склада"
                     (onFocus)="onSearchFieldFocused()"
                     (onBlur)="onSearchFieldBlurred()"
                     (onKeyUp)="onSearchFieldKeyUp()"
      ></address-field>
    </div>
    <div class="map">
      <div class="center-marker-container" [class.slit-out-vertical]="!isCenterMarkerVisible" [class.slit-in-vertical]="isCenterMarkerVisible">
        <div class="marker"></div>
      </div>
      <google-map
              *ngIf="mapService.isInit"
              [options]="{ scrollwheel: false, panControl: true }"
              [center]="{ lat, lng }"
              [zoom]="zoom"
              width="100%"
              height="100%"
              (centerChanged)="onCenterChange()"></google-map>
    </div>
  </div>
  <div class="form-group" [class.has-error]="!isAddressValid">
    <label>Адрес склада</label>
    <input type="text" class="form-control" [(ngModel)]="addr" name="addr">
  </div>
  <div class="form-group" [class.has-error]="!isCoordinatesValid">
    <label>Координаты (широта, долгота)</label>
    <input type="text" class="form-control" name="coordinates" [(ngModel)]="coordinates" (ngModelChange)="onChangeCoordinates()" pattern="(-?\d+\.?\d*),\s*(-?\d+\.?\d*)">
  </div>
  <div class="row">
    <div class="col col-lg-3">
      <div class="form-group" [class.has-error]="!isOpenAfterValid">
        <label>Время начала работы склада</label>
        <input type="text" class="form-control" size="5" placeholder="09:00" name="openAfter" [(ngModel)]="openAfter" (ngModelChange)="onChangeInput()" pattern="\d{2}:\d{2}">
      </div>
    </div>
    <div class="col col-lg-3">
      <div class="form-group" [class.has-error]="!isOpenBeforeValid">
        <label>Время окончания работы склада</label>
        <input type="text" class="form-control" size="5" placeholder="19:00" name="openBefore" [(ngModel)]="openBefore" (ngModelChange)="onChangeInput()" pattern="\d{2}:\d{2}">
      </div>
    </div>
    <div class="col col-lg-6">
      <div class="form-group" [class.has-error]="!isLoadingUnloadingDurationValid">
        <label>Средняя продолжительность погрузочно-разгрузочных работ в минутах</label>
        <input type="number" class="form-control" min="15" step="15" size="3" placeholder="15" name="loadingUnloadingDuration" [(ngModel)]="loadingUnloadingDuration" (ngModelChange)="onChangeInput()" pattern="\d{1,3}">
      </div>
    </div>
  </div>
</form>
