import {Account} from "./account";

export class Auth {
  id: number;
  email: string;
  phone: string;
  is_active: boolean;
  updated_at: string;
  online_at: string;
  account: Account;
  role: string;
}
