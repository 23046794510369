
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {OzonService} from "../_services/ozon.service";
import {ParcelInfo} from "../_models/ozon/parcel-info";
import {LoaderService} from "../_services/loader.service";
import {DeliveryProductTrackingStat} from "../_models/delivery-product-tracking-stat";
import {DeliveryTrackingService} from "../_services/delivery-tracking.service";
import {AlertService} from "../_services/alert.service";
import {OptimizationTaskImportResult} from "../_models/ozon/optimization-task-import-result";
import {ClipboardService} from "../_services/clipboard.service";

class Parcel {
  stat: DeliveryProductTrackingStat[] = [];
  importResult: OptimizationTaskImportResult|null = null;
  disableSelection = false;

  constructor(public info: ParcelInfo, public selected: boolean = false) {
  }
}

@Component({
  selector: 'ozon-parcels-list',
  templateUrl: './ozon-parcels-list.component.html',
  styleUrls: ['./ozon-parcels-list.component.css']
})
export class OzonParcelsListComponent implements OnInit, OnChanges {
  @Input() importResult: OptimizationTaskImportResult[] = [];
  @Input() disableSelectionForImported: boolean = false;
  @Input() multiselect: boolean = false;
  @Output() selectParcels = new EventEmitter<ParcelInfo[]>();
  @Output() clickOnTask = new EventEmitter<number>();

  parcels: Parcel[] = [];
  singleSelected: number|null = null;

  constructor(private ozonService: OzonService,
              private deliveryTrackingService: DeliveryTrackingService,
              private clipboardService: ClipboardService,
              private alertService: AlertService,
              private loaderService: LoaderService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['importResult'])
      this.applyImportResult();
  }

  loadNewParcels(): void {
    this.loaderService.show();
    this.ozonService
      .getNewParcels().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        parcels => {
          this.parcels = parcels.map(parcel => new Parcel(parcel));
          this.singleSelected = null;
          this.onSelectionChanged();
          this.loadParcelsStat();
          this.applyImportResult();
        },
        () => {
        }
      );
  }

  private loadParcelsStat(): void {
    if(this.parcels.length == 0)
      return;

    this.loaderService.show();
    this.deliveryTrackingService
      .getDeliveryProductsTrackingStat('ozon', this.parcels.map(p => p.info.posting_number)).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        stat => {
          this.applyStat(stat);
          this.onSelectionChanged();
        },
        () => {
        }
      );
  }

  private applyStat(stats: DeliveryProductTrackingStat[]): void {
    let statMap = new Map<string, DeliveryProductTrackingStat[]>();

    for(let stat of stats) {
      let key = stat.external_track_number;
      if(!statMap.has(key))
        statMap.set(key, []);

      statMap.get(key).push(stat);
    }

    for(let parcel of this.parcels) {
      let key = parcel.info.posting_number;
      if(statMap.has(key)) {
        let stats = statMap.get(key);
        parcel.stat = stats;
        if(this.disableSelectionForImported) {
          for(const stat of stats) {
            if(stat.task_id) {
              parcel.disableSelection = true;
              parcel.selected = false;
              break;
            }
          }
        }
      }
    }
  }

  private applyImportResult(): void {
      let importResultMap = new Map<string, OptimizationTaskImportResult>();

      for(let result of this.importResult) {
          importResultMap.set(result.posting_number, result);
      }

      for(let parcel of this.parcels) {
          let key = parcel.info.posting_number;
          if(importResultMap.has(key))
              parcel.importResult = importResultMap.get(key);
      }
  }

  onSelectionChanged(): void {
    this.selectParcels.next(this.parcels.filter(p => p.selected).map(p => p.info));
  }

  onClickRow(index: number): void {
    let parcel = this.parcels[index];
    if(!this.multiselect && parcel.disableSelection)
      return;

    if(this.multiselect) {
      parcel.selected = parcel.disableSelection ? false : !parcel.selected;
    } else {
      for(const p of this.parcels) {
        p.selected = false;
      }
      parcel.selected = true;
      this.singleSelected = index;
    }

    this.onSelectionChanged();
  }

  onClickTrackNumber(trackNumber: string): void {
    this.clipboardService.saveToClipboard(trackNumber, `Трек-номер "${trackNumber}" скопирован в буфер обмена`);
  }

  onClickTaskLink(taskId: number): void {
    this.clickOnTask.next(taskId);
  }
}
