import {City} from "./city";

export class Storehouse {
  id: number;
  address: string;
  lat: number;
  lon: number;
  city: City;
  open_after: string;
  open_before: string;
  loading_unloading_duration: number;
}
