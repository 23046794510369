import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Insurance} from "../_models/insurance";
import {OrderDraft} from "../_models/order-draft";
import {InsuranceEditorComponent} from "../insurance-editor/insurance-editor.component";

@Component({
  selector: 'draft-insurance-editor',
  templateUrl: './draft-insurance-editor.component.html',
  styleUrls: ['./draft-insurance-editor.component.css']
})
export class DraftInsuranceEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  @ViewChild(InsuranceEditorComponent, { static: true }) insuranceEditor: InsuranceEditorComponent;

  insurances: Insurance[] = [];

  constructor() { }

  ngOnInit() {
    this.insurances = JSON.parse(JSON.stringify(this.draft.insurances)) as Insurance[];
  }

  onSave() {
    if(this.insuranceEditor.validate()) {
      this.draft.insurances = this.insuranceEditor.insurances;

      this.onUpdated.emit(this.draft);
    }
  }
}
