import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {City} from "../_models/city";
import {CityService} from "../_services/city.service";

@Component({
  selector: 'city-editor',
  templateUrl: './city-editor.component.html',
  styleUrls: ['./city-editor.component.css']
})
export class CityEditorComponent implements OnInit {
  @Input() city: City;
  @Output() cityChange = new EventEmitter<City>();

  cities: City[] = [];
  selected: number;

  constructor(private cityService: CityService) { }

  ngOnInit() {
    console.log(this.city);
    this.selected = this.city ? this.city.id : null;
    this.initCities();
  }

  private initCities(): void {
    this.cityService.getCities()
      .subscribe(cities => {
        this.cities = cities;
        if(!this.selected && cities.length > 0)
          this.selected = cities[0].id;
      }, () => {})
    ;
  }

  onSave(): void {
    for(let city of this.cities) {
      if(city.id == this.selected) {
        this.cityChange.emit(city);
        break;
      }
    }
  }
}
