
import {of as observableOf, Observable, Subject} from 'rxjs';

import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {CallsListFilter} from "../_models/calls-list-filter";
import {LoaderService} from "../_services/loader.service";
import {CallsList} from "../_models/calls-list";
import {State} from "../_storage/state";
import {DateTime} from "date-time-js";
import {ObjectComparator} from "../_utils/ObjComparator";
import {CallService} from "../_services/call.service";
import {Page} from "../pager/page";
import {Call} from "../_models/call";
import {VoximplantService} from "../_services/voximplant.service";
import {CallRecord} from "../_models/call-record";
import {AudioPlayerService} from "../audio-player/service/audio-player.service";
import {Track} from "../audio-player/model/track";
import {UserNameUtils} from "../_utils/user-name-utils";
import {ROLES} from "../_maps/roles";

const PAGE_STORAGE = "calls_list_page";
const FILTER_STORAGE = "calls_list_filter";
const REFRESH_LIST_INTERVAL = 60;

declare var moment: any;

class ListCommand {
  constructor(
    private _filter?: CallsListFilter,
    private _page?: number,
    private _source?: string,
    private _force?: boolean
  ) {}

  get filter(): CallsListFilter {
    return this._filter;
  }

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  get force(): boolean {
    return this._force;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'app-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.css']
})
export class CallsComponent implements OnInit, OnDestroy {
  calls: CallsList = CallsList.empty();

  fromDate: Date;
  toDate: Date;
  number: string;
  surname: string;

  showMore: boolean;

  fromDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'от'
  };

  toDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'до'
  };

  private oldFilter = new CallsListFilter();
  private listCommands = new Subject<ListCommand>();

  private refreshListTimer: any;

  constructor(
    private callsService: CallService,
    private loaderService: LoaderService,
    private voximplantService: VoximplantService,
    private audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit() {
    this.restoreFilter();
    this.restorePage();
    this.initCalls();
    this.loadCalls(this.calls.page, this.calls.filter);
  }

  private initCalls() {
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        console.log(`Filter to list`, command.filter);
        return this.callsService
          .getCalls(command.page, command.filter).pipe(
          map(list => [list, command])).pipe(
          finalize(() => this.loaderService.hide()));
      }),
      catchError(e => {
        console.log(e);
        this.initCalls();
        return observableOf(CallsList.empty());
      }),)
      .subscribe(listAndCommand => {
        let list: CallsList = listAndCommand[0];
        let command: ListCommand = listAndCommand[1];

        console.log(command.source, this.oldFilter, list.filter);
        if (
          command.source == "button" &&
          ObjectComparator.Compare(this.oldFilter, list.filter)
        ) {
          this.calls = list.concat(this.calls.calls);
        } else {
          this.calls = list;
        }

        this.initMoreButton();
        this.saveFilter();
        this.savePage();
        this.initAutoRefreshList();
      });
  }

  private restoreFilter() {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if(state)
      this.calls.filter = CallsListFilter.fromState(new State(state));

    this.fromDate = this.calls.filter.fromDate || new DateTime().subtract(7, 'day').toDate();
    this.toDate = this.calls.filter.toDate;
    this.number = this.calls.filter.number;
    this.surname = this.calls.filter.surname;

    this.calls.filter.fromDate = this.fromDate;
    this.calls.filter.toDate = this.toDate;

    this.oldFilter = this.calls.filter;
  }

  private restorePage() {
    this.calls.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  private loadCalls(page?: number, filter?: CallsListFilter, source?: string, force?: boolean) {
    this.listCommands.next(new ListCommand(filter?.clone(), page, source, force));
  }

  private initMoreButton(): void {
    this.showMore = this.calls.hasMore();
  }

  private saveFilter() {
    sessionStorage.setItem(FILTER_STORAGE, this.calls.filter.getState().state);

    this.oldFilter = this.calls.filter.clone();
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.calls.page.toString());
  }

  private reloadCalls(): void {
    this.loadCalls(this.calls.page, this.calls.filter, null, true);
  }

  private initAutoRefreshList() {
    this.stopAutoRefreshList();
    this.refreshListTimer = setTimeout(() => this.onAutoRefreshList(), REFRESH_LIST_INTERVAL * 1000);
  }

  private stopAutoRefreshList() {
    if(this.refreshListTimer)
      clearTimeout(this.refreshListTimer);

    this.refreshListTimer = null;
  }

  private onAutoRefreshList() {
    if(this.calls.page == 0)
      this.reloadCalls();
  }

  private playCallRecord(record: CallRecord): void {
    let tracks = this.createPlayTracks();

    this.audioPlayerService.play(tracks, this.findRecordInTracks(tracks, record));
  }

  private createPlayTracks(): Track[] {
    let tracks: Track[] = [];

    for (let call of this.calls.calls) {
      let userName = call.account
        ? UserNameUtils.getAccountFullName(call.account, call.number)
        : call.number;

      let formattedTime = new DateTime(new Date(call.time)).format('yyyy-MM-dd HH:mm:ss');
      let callType = call.type == 'incoming' ? 'входящий' : 'исходящий';
      let accountType = call.account ? ROLES[call.account.auth.role].toLowerCase() : 'неизвестный';
      let cover = call.account ? call.account.picture : undefined;

      for (let record of call.records) {
        let track = new Track();
        track.name = `${formattedTime}, ${callType}`;
        track.cover = cover;
        track.url = record.url;
        track.artist = `${userName} (${accountType})`;

        tracks.unshift(track);
      }
    }

    return tracks;
  }

  private findRecordInTracks(tracks: Track[], record: CallRecord): number {
    return tracks.findIndex(t => t.url === record.url);
  }

  asDuration(seconds: number): string {
    let duration = moment.duration(seconds, 'seconds');
    let digitOptions = { minimumIntegerDigits: 2 };

    return [
      duration.hours().toLocaleString('ru-RU', digitOptions),
      duration.minutes().toLocaleString('ru-RU', digitOptions),
      duration.seconds().toLocaleString('ru-RU', digitOptions)
    ].join(':');
  }

  onPaginationPage(page: Page) {
    this.loadCalls(page.num, this.calls.filter, "pager");
  }

  onShowMorePage(page: Page) {
    this.loadCalls(page.num, this.calls.filter, "button");
  }

  onChangeFilter() {
    let filter = new CallsListFilter(
      this.fromDate,
      this.toDate,
      this.number,
      this.surname
    );

    if(!filter.equals(this.oldFilter)) {
      console.log('filter changed');
      this.listCommands.next(new ListCommand(filter.clone()));
    }
  }

  onCall(call: Call) {
    if(confirm(`Вы уверены, что хотите позвонить по номеру ${call.number}?`))
      this.voximplantService.call(call.number);
  }

  onPlayCallRecord(record: CallRecord): void {
    this.playCallRecord(record);
  }

  ngOnDestroy(): void {
    this.stopAutoRefreshList();
  }
}
