<div class="count">
  Количество:
  <button type="button" class="btn btn-link btn-xs" (click)="onSwitchView($event)" title="Показать/скрыть адреса">{{ task.destinations.length - 1 }} ...</button>
</div>
<div class="destinations" *ngIf="showDestinations">
  <div class="destination" *ngFor="let destination of task.destinations">
    <span class="glyphicon glyphicon-ok delivered" title="Доставлено" *ngIf="destination.delivered"></span>
    <span class="glyphicon glyphicon-remove undelivered" title="Не доставлено" *ngIf="destination.undelivered"></span>
    <span class="slot">[{{ destination.time_slot_begin | dt:'H:mm' }} - {{ destination.time_slot_end | dt:'H:mm' }}]</span>
    <span class="code" *ngIf="destination.order_code">- {{ destination.order_code }} -</span>
    <span class="address">{{ destination.destination.addr }}</span>
  </div>
</div>
