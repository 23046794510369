
import {catchError, finalize, map} from 'rxjs/operators';

import {throwError as observableThrowError, Observable, firstValueFrom} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UploadChain} from "../_upload/upload-chain";
import {UploadUnit} from "../_upload/upload-unit";
import {Uploader} from "../_upload/uploader";
import {Editable} from "../_models/editable";
import {Employee} from "../_models/employees/employee";
import {Freighter} from "../_models/freighter";
import {EmployeeService} from "../_services/employer.service";
import {LoaderService} from "../_services/loader.service";
import {UserEmployeeConverter} from "../_utils/employees/user-employee-converter";
import {HttpErrorResponse} from "@angular/common/http";
import {FormErrors} from "../_models/form-errors";
import {AlertService} from "../_services/alert.service";
import {Auth} from "../_models/auth";
import {EmployerContacts} from "../_models/employer-contacts";
import {CompleteAllUploadsHandler} from "../_upload/complete-all-uploads-handler";
import {ChainedPhotoFieldComponent} from "../chained-photo-field/chained-photo-field.component";
import {environment} from "../../environments/environment";
import {ApiUtilsService} from "../_services/api-utils.service";
import {UploadError} from "../_upload/upload-error";
import {Passport} from "../_models/passport";
import {UpdateUploadStatusHandler} from "../_upload/update-upload-status-handler";
import {UploadStatus} from "../_upload/upload-status";
import {DriverLicense} from "../_models/driver-license";
import {EmployeeDevice} from "../_models/employees/employee-device";
import {InnDialogComponent} from "../inn-dialog/inn-dialog.component";
import {LegalEntityService} from "../_services/legal-entity.service";
import {LegalEntity} from "../_models/legal-entity";
import {Transport} from "../_models/transport";
import {TransportSelectorDialogComponent} from "../transport-selector-dialog/transport-selector-dialog.component";
import {UploaderService} from "../_services/uploader.service";
import {UserInfoService} from "../_services/user-info.service";
import {PaymentDistributionSchemaService} from "../_services/payment-distribution-schema.service";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";

const driverLicenseCategories = ['a', 'b', 'c', 'd', 'e'];

@Component({
  selector: 'employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})
export class EmployeeFormComponent implements OnInit, OnChanges {
  @Input() employee: Editable<Employee>;
  @Input() freighter: Freighter;

  @Output() saved = new EventEmitter<Employee>();
  @Output() back = new EventEmitter<void>();

  @ViewChild('employeePhoto', { static: true }) employeePhotoField: ChainedPhotoFieldComponent;

  @ViewChild('passportFirstPage') passportFirstPageField: ChainedPhotoFieldComponent;
  @ViewChild('passportSecondPage') passportSecondPageField: ChainedPhotoFieldComponent;
  @ViewChild('passportRegistrationPage') passportRegistrationPageField: ChainedPhotoFieldComponent;
  @ViewChild('passportTempRegistrationPhoto') passportTempRegistrationPhotoField: ChainedPhotoFieldComponent;

  @ViewChild('selfieFirstPage') selfieWithFirstPageField: ChainedPhotoFieldComponent;
  @ViewChild('selfieSecondPage') selfieWithSecondPageField: ChainedPhotoFieldComponent;

  @ViewChild('driverLicenseFront', { static: true }) driverLicenseFrontField: ChainedPhotoFieldComponent;
  @ViewChild('driverLicenseBack', { static: true }) driverLicenseBackField: ChainedPhotoFieldComponent;

  @ViewChild('taxpayerCertificateFront', { static: true }) taxpayerCertificateFrontField: ChainedPhotoFieldComponent;

  @ViewChild('innDialog', { static: true }) innDialog: InnDialogComponent;

  @ViewChild('transportsDialog', { static: true }) transportDialog: TransportSelectorDialogComponent;

  employeePhotoUploadChain = new UploadChain();
  passportUploadChain = new UploadChain();
  driverLicenseUploadChain = new UploadChain();
  taxpayerCertificateUploadChain = new UploadChain();

  driverLicenseCategories: any = {};

  paymentDistributionSchemas: PaymentDistributionSchema[] = [];
  paymentDistributionSchema = new PaymentDistributionSchema();

  private employeeConverter = new UserEmployeeConverter();

  private passportPhotoIdentifiers = [
    ['first_page', 'passportFirstPageField', 'первая страница'],
    ['registration_page', 'passportSecondPageField', 'страница регистрации'],
    ['second_page', 'passportRegistrationPageField', 'вторая страница'],
    ['temp_registration_photo', 'passportTempRegistrationPhotoField', 'временная регистрация'],
    ['selfie_with_first_page', 'selfieWithFirstPageField', 'селфи с первой страницей'],
    ['selfie_with_second_page', 'selfieWithSecondPageField', 'селфи со второй страницей'],
  ];

  private driverLicensePhotoIdentifiers = [
    ['front', 'driverLicenseFrontField', 'лицевая сторона'],
    ['back', 'driverLicenseBackField', 'обратная сторона'],
  ];

  private taxpayerCertificateFileIdentifiers = [
    ['front', 'taxpayerCertificateFrontField', 'лицевая сторона'],
  ];

  legalEntityDisabled = true;
  isEditAllowed = false;

  constructor(
    private employeeService: EmployeeService,
    private legalEntityService: LegalEntityService,
    private paymentDistributionSchemaService: PaymentDistributionSchemaService,
    private apiUtilsService: ApiUtilsService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private uploaderService: UploaderService,
    private userInfoService: UserInfoService,
  ) {
    this.paymentDistributionSchema.id = 0;

    this.initPassportUploadChain();
    this.initDriverLicenseUploadChain();
    this.initTaxpayerCertificateUploadChain();
    this.initEmployeePhotoUploadChain();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareDriverLicenseCategoriesForEdit();

    this.employee.record.driver_license.type = this.employee.record.driver_license.type || '';
    this.employee.record.device.type = this.employee.record.device.type || '';
    this.employee.record.device.operator = this.employee.record.device.operator || '';
    this.employee.record.account.gender = this.employee.record.account.gender || '';

    this.initLegalEntity();
    this.initControls();
    this.initPaymentDistributionSchemas();
  }

  private initControls(): void {
    this.isEditAllowed = this.employee.record.freighter.id == this.userInfoService.getFreighter().id;
  }

  private initLegalEntity(): void {
    this.legalEntityDisabled = !(
      this.employee.record.legal_entity.freighter && this.employee.record.legal_entity.freighter.id === this.freighter.id
    );
  }

  private initPaymentDistributionSchemas(): void {
    this.paymentDistributionSchemaService
      .getFreighterPrivateSchemas(this.userInfoService.getFreighter())
      .subscribe(
        schemas => {
          this.paymentDistributionSchemas = schemas;
          this.preparePaymentDistributionSchemaForEdit();
        },
        () => {}
      )
    ;
  }

  private initDriverLicenseCategories(): void {
    this.driverLicenseCategories = {};
    for(let category of driverLicenseCategories)
      this.driverLicenseCategories[category] = false;
  }

  private preparePaymentDistributionSchemaForEdit(): void {
    this.paymentDistributionSchema = this.employee.record.payment_distribution_schema;
    if(!this.paymentDistributionSchema) {
      this.paymentDistributionSchema = new PaymentDistributionSchema();
      this.paymentDistributionSchema.id = 0;
    }
  }

  private prepareDriverLicenseCategoriesForEdit(): void {
    this.initDriverLicenseCategories();
    for(let category of this.employee.record.driver_license.categories)
      this.driverLicenseCategories[category] = true;
  }

  private preparePaymentDistributionSchemaForSave(): void {
    this.employee.record.payment_distribution_schema = this.paymentDistributionSchema.id == 0 ? null : this.paymentDistributionSchema;
  }

  private prepareDriverLicenseCategoriesForSave(): void {
    let categories: string[] = [];
    for(let category of driverLicenseCategories) {
      if(this.driverLicenseCategories[category])
        categories.push(category);
    }
    this.employee.record.driver_license.categories = categories;
  }

  private add() {
    this.loaderService.show();
    this.employee.isSubmitting = true;

    let employee = this.employeeConverter.convertEmployeeToUser(this.employee.record);

    this.loaderService.showText('Отправка базовой информации о водителе...');

    this.employeeService
      .addEmployee(this.freighter, employee, this.employeeConverter.getFeatureNames()).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.employee.isSubmitting = false;
      }))
      .subscribe(
        r => this.loadEmployeeAndUploadRestData(r.body.id),
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.employee.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      )
  }

  private update() {
    this.loaderService.show();
    this.employee.isSubmitting = true;

    let employee = this.employeeConverter.convertEmployeeToUser(this.employee.record);

    this.loaderService.showText('Отправка базовой информации о водителе...');

    this.employeeService
      .updateEmployee(this.freighter, employee, this.employeeConverter.getFeatureNames()).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.employee.isSubmitting = false;
      }))
      .subscribe(
        () => {
          this.updateRestEmployeeData(employee);
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.employee.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      )
  }

  private loadEmployeeAndUploadRestData(employeeId: number): void {
    this.loaderService.show();

    this.loaderService.showText('Водитель добавлен. Загружаем остальные данные...');

    this.employeeService
      .getEmployee(this.freighter, employeeId).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        e => {
          let newEmployee = this.employeeConverter.convertUserToEmployee(e);
          this.employee.record.id = newEmployee.id;
          this.employee.record.account.id = newEmployee.account.id;
          this.employee.record.account.employer.id = newEmployee.account.employer.id;
          this.employee.isNew = false;

          setTimeout(() => this.updateRestEmployeeData(
            this.employeeConverter.convertEmployeeToUser(this.employee.record)),
            500
          );
        }
      )
  }

  private updateRestEmployeeData(employee: Auth): void {
    this.loaderService.show();
    this.employee.isSubmitting = true;

    Promise.resolve(employee)
      .then(e => this.updateContacts(e))
      .then(e => this.uploadEmployeePhoto(e))
      .then(e => this.updatePassport(e))
      .then(e => this.uploadPassportPhotos(e))
      .then(e => this.updateDriverLicense(e))
      .then(e => this.uploadDriverLicensePhotos(e))
      .then(e => this.uploadTaxpayerCertificateFiles(e))
      .then(e => this.updateDevice(e))
      .then(e => this.updateLegalEntity(e))
      .then(() => this.loaderService.hide())
      .then(() => this.employee.isSubmitting = true)
      .then(() => this.alertService.success('Данные водителя сохранены', true))
      .then(() => this.completeSaving())
      .catch(r => {
        this.loaderService.hide();
        this.employee.isSubmitting = false;
        console.error(r);
      })
    ;
  }

  private updateContacts(employee: Auth): Promise<Auth> {
    this.loaderService.showText('Обновление контактов...');

    return firstValueFrom(this.employeeService
      .updateEmployeeContacts(this.freighter, employee.account.employer.id, employee.account.employer.contacts && employee.account.employer.contacts[0] || new EmployerContacts()).pipe(
        catchError(r => this.handleServerError(r, 'Контактные данные заполнены неправильно')),
        map(() => employee)
      ));
  }

  private updatePassport(employee: Auth): Promise<Auth> {
    this.loaderService.showText('Обновление паспортных данных...');

    return firstValueFrom(this.employeeService
      .updateEmployeePassport(this.freighter, employee.account.employer.id, employee.account.employer.passports && employee.account.employer.passports[0] || new Passport()).pipe(
        catchError(r => this.handleServerError(r, 'Паспортные данные заполнены неправильно')),
        map(() => employee)
      ));
  }

  private updateDriverLicense(employee: Auth): Promise<Auth> {
    this.loaderService.showText('Обновление данных о водительском удостоверении...');

    return firstValueFrom(this.employeeService
      .updateEmployeeDriverLicense(this.freighter, employee.account.employer.id, employee.account.employer.driver_license && employee.account.employer.driver_license[0] || new DriverLicense()).pipe(
        catchError(r => this.handleServerError(r, 'Данные о водительском удостоверении заполнены неправильно')),
        map(() => employee)
      ));
  }

  private updateDevice(employee: Auth): Promise<Auth> {
    this.loaderService.showText('Обновление данных об устройстве водителя...');

    return firstValueFrom(this.employeeService
      .updateEmployeeDevice(this.freighter, employee.account.employer.id, employee.account.employer.devices && employee.account.employer.devices[0] || new EmployeeDevice()).pipe(
        catchError(r => this.handleServerError(r, 'Данные об устройстве водителя заполнены неправильно')),
        map(() => employee)
      ));
  }

  private updateLegalEntity(employee: Auth): Promise<Auth> {
    this.loaderService.showText('Обновление данных о юр. лице...');

    if(!employee.account.employer.legal_entity.inn || employee.account.employer.legal_entity.inn === '')
      return this.unlinkLegalEntity(employee);

    return firstValueFrom(this.employeeService
      .updateEmployeeLegalEntity(this.freighter, employee.account.employer.id, employee.account.employer.devices && employee.account.employer.legal_entity || new LegalEntity()).pipe(
        catchError(r => this.handleServerError(r, 'Данные юр. лица заполнены неправильно')),
        map(() => employee)
      ));
  }

  private unlinkLegalEntity(employee: Auth): Promise<Auth> {
    return firstValueFrom(this.employeeService
      .unlinkEmployeeLegalEntity(this.freighter, employee.account.employer.id).pipe(
      map(() => employee)));
  }

  private uploadEmployeePhoto(employee: Auth): Promise<Auth> {
    this.loaderService.showText('Загрузка фото водителя...');

    return new Promise((resolve, reject) => {
      this.uploaderService.prepareToUpload(
        `${environment.apiEndpoint}${this.apiUtilsService.getPrefixByFreighterId(this.freighter.id)}/employers/${this.employee.record.account.employer.id}/photo.json`,
        this.employeePhotoUploadChain.getUnit('employee_photo').uploader
      );

      let _form = this;
      this.employeePhotoUploadChain.upload(
        new class implements CompleteAllUploadsHandler {
          onCompleteAllUploads() {
            if(_form.employeePhotoUploadChain.hasErrors()) {
              let error = _form.employeePhotoUploadChain.getUploadError('employee_photo');
              _form.alertService.error('Ошибка загрузки фото водителя');
              reject(error);
            } else {
              _form.employeePhotoField.reset();
              resolve(employee);
            }
          }
        }
      );
    });
  }

  private uploadPassportPhotos(employee: Auth): Promise<Auth> {
    for(let identifiers of this.passportPhotoIdentifiers) {
      let type = identifiers[0];
      this.uploaderService.prepareToUpload(
        `${environment.apiEndpoint}${this.apiUtilsService.getPrefixByFreighterId(this.freighter.id)}/employers/${this.employee.record.account.employer.id}/passport/photo/${type}.json`,
        this.passportUploadChain.getUnit(type).uploader
      );
    }

    return new Promise((resolve, reject) => {
      let _form = this;
      this.passportUploadChain.upload(
        new class implements CompleteAllUploadsHandler {
          onCompleteAllUploads() {
            for(let identifiers of _form.passportPhotoIdentifiers) {
              let type = identifiers[0];
              if(!_form.passportUploadChain.hasError(type)) {
                let field = _form.getPassportPhotoField(type);
                if(field)
                  field.reset();
              }
            }
            if(_form.passportUploadChain.hasErrors()) {
              _form.alertService.error('Ошибка загрузки фото удостоверения личности');
              reject();
            } else {
              resolve(employee);
            }
          }
        },
        new class implements UpdateUploadStatusHandler {
          onUpdateUploadStatus(status: UploadStatus): void {
            let photoName = _form.getPassportPhotoName(status.identifier);
            _form.loaderService.showText(
              `Загрузка фото удостоверения личности '${photoName}' (${status.currentUploadNumber} из ${status.allUploadCount})...`
            );
          }
        }
      );
    });
  }

  private uploadDriverLicensePhotos(employee: Auth): Promise<Auth> {
    for(let identifiers of this.driverLicensePhotoIdentifiers) {
      let type = identifiers[0];
      this.uploaderService.prepareToUpload(
        `${environment.apiEndpoint}${this.apiUtilsService.getPrefixByFreighterId(this.freighter.id)}/employers/${this.employee.record.account.employer.id}/driver_license/photo/${type}.json`,
        this.driverLicenseUploadChain.getUnit(type).uploader
      );
    }

    return new Promise((resolve, reject) => {
      let _form = this;
      this.driverLicenseUploadChain.upload(
        new class implements CompleteAllUploadsHandler {
          onCompleteAllUploads() {
            for(let identifiers of _form.driverLicensePhotoIdentifiers) {
              let type = identifiers[0];
              if(!_form.driverLicenseUploadChain.hasError(type)) {
                let field = _form.getDriverLicensePhotoField(type);
                if(field)
                  field.reset();
              }
            }
            if(_form.driverLicenseUploadChain.hasErrors()) {
              _form.alertService.error('Ошибка загрузки фото водительского удостоверения');
              reject();
            } else {
              resolve(employee);
            }
          }
        },
        new class implements UpdateUploadStatusHandler {
          onUpdateUploadStatus(status: UploadStatus): void {
            let photoName = _form.getDriverLicensePhotoName(status.identifier);
            _form.loaderService.showText(
              `Загрузка фото водительского удостоверения '${photoName}' (${status.currentUploadNumber} из ${status.allUploadCount})...`
            );
          }
        }
      );
    });
  }

  private uploadTaxpayerCertificateFiles(employee: Auth): Promise<Auth> {
    for(let identifiers of this.taxpayerCertificateFileIdentifiers) {
      let type = identifiers[0];
      this.uploaderService.prepareToUpload(
        `${environment.apiEndpoint}${this.apiUtilsService.getPrefixByFreighterId(this.freighter.id)}/employers/${this.employee.record.account.employer.id}/taxpayer_certificate/file/${type}.json`,
        this.taxpayerCertificateUploadChain.getUnit(type).uploader
      );
    }

    return new Promise((resolve, reject) => {
      let _form = this;
      this.taxpayerCertificateUploadChain.upload(
        new class implements CompleteAllUploadsHandler {
          onCompleteAllUploads() {
            for(let identifiers of _form.taxpayerCertificateFileIdentifiers) {
              let type = identifiers[0];
              if(!_form.taxpayerCertificateUploadChain.hasError(type)) {
                let field = _form.getTaxpayerCertificateFileField(type);
                if(field)
                  field.reset();
              }
            }
            if(_form.taxpayerCertificateUploadChain.hasErrors()) {
              _form.alertService.error('Ошибка загрузки файла со справкой налогоплательщика');
              reject();
            } else {
              resolve(employee);
            }
          }
        },
        new class implements UpdateUploadStatusHandler {
          onUpdateUploadStatus(status: UploadStatus): void {
            let photoName = _form.getTaxpayerCertificateFileName(status.identifier);
            _form.loaderService.showText(
              `Загрузка фото со справкой налогоплательщика '${photoName}' (${status.currentUploadNumber} из ${status.allUploadCount})...`
            );
          }
        }
      );
    });
  }

  private handleServerError(response: any, badRequestMessage: string): Observable<never> {
    if((response instanceof HttpErrorResponse && response.status === 400) || response instanceof UploadError) {
      this.alertService.error(badRequestMessage);
    }

    return observableThrowError(() => response);
  }

  private removeEmployeePhoto(): void {
    this.loaderService.show();

    let employee = this.employeeConverter.convertEmployeeToUser(this.employee.record);
    this.employeeService
      .deleteEmployeePhoto(this.freighter, employee).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.completeSaving(),
        () => {}
      )
  }

  private removePassportPhoto(type: string): void {
    this.loaderService.show();

    let employee = this.employeeConverter.convertEmployeeToUser(this.employee.record);
    this.employeeService
      .deletePassportPhoto(this.freighter, employee, type).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.completeSaving(),
        () => {}
      )
  }

  private removeDriverLicensePhoto(type: string): void {
    this.loaderService.show();

    let employee = this.employeeConverter.convertEmployeeToUser(this.employee.record);
    this.employeeService
      .deleteDriverLicensePhoto(this.freighter, employee, type).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.completeSaving(),
        () => {}
      )
  }

  private removeTaxpayerCertificateFile(type: string): void {
    this.loaderService.show();

    let employee = this.employeeConverter.convertEmployeeToUser(this.employee.record);
    this.employeeService
      .deleteTaxpayerCertificateFile(this.freighter, employee, type).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.completeSaving(),
        () => {}
      )
  }

  private initPassportUploadChain(): void {
    for(let identifiers of this.passportPhotoIdentifiers) {
      let identifier = identifiers[0];
      this.passportUploadChain.addUnit(identifier, new UploadUnit(new Uploader({
        allowedMimeType: ['image/gif', 'image/jpeg', 'image/png'],
        itemAlias: 'file',
        url: ''
      })));
    }
  }

  private initDriverLicenseUploadChain(): void {
    for(let identifiers of this.driverLicensePhotoIdentifiers) {
      let identifier = identifiers[0];
      this.driverLicenseUploadChain.addUnit(identifier, new UploadUnit(new Uploader({
        allowedMimeType: ['image/gif', 'image/jpeg', 'image/png'],
        itemAlias: 'file',
        url: ''
      })));
    }
  }

  private initTaxpayerCertificateUploadChain(): void {
    for(let identifiers of this.taxpayerCertificateFileIdentifiers) {
      let identifier = identifiers[0];
      this.taxpayerCertificateUploadChain.addUnit(identifier, new UploadUnit(new Uploader({
        allowedMimeType: ['image/gif', 'image/jpeg', 'image/png'],
        itemAlias: 'file',
        url: ''
      })));
    }
  }

  private initEmployeePhotoUploadChain(): void {
    this.employeePhotoUploadChain.addUnit('employee_photo', new UploadUnit(new Uploader({
      allowedMimeType: ['image/gif', 'image/jpeg', 'image/png'],
      itemAlias: 'employerPhoto[pictureFile]',
      url: ''
    })));
  }

  private completeSaving(): void {
    this.saved.emit(this.employee.record);
  }

  private showInnDialog(): void {
    this.innDialog.showDialog();
  }

  private applyInn(inn: string): void {
    this.employee.record.legal_entity = new LegalEntity();
    this.employee.record.legal_entity.inn = inn;

    this.loaderService.show();
    this.legalEntityService
      .findLegalEntityByInn(this.freighter, inn).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        e => {
          if(e === null) {
            this.alertService.clear();
            this.legalEntityDisabled = false;
          } else {
            this.employee.record.legal_entity = e;
            this.initLegalEntity();
          }
        },
        () => {}
      );
  }

  private unlinkInn(): void {
    this.employee.record.legal_entity = new LegalEntity();
    this.legalEntityDisabled = true;
  }

  private prepareToSave(): void {
    delete this.employee.record.account.employer.features.empty;

    this.prepareDriverLicenseCategoriesForSave();
    this.preparePaymentDistributionSchemaForSave();
  }

  private getPassportPhotoField(type: string): ChainedPhotoFieldComponent|undefined {
    for(let identifiers of this.passportPhotoIdentifiers) {
      if(identifiers[0] === type)
        return this[identifiers[1]];
    }
    return undefined;
  }

  private getDriverLicensePhotoField(type: string): ChainedPhotoFieldComponent|undefined {
    for(let identifiers of this.driverLicensePhotoIdentifiers) {
      if(identifiers[0] === type)
        return this[identifiers[1]];
    }
    return undefined;
  }

  private getTaxpayerCertificateFileField(type: string): ChainedPhotoFieldComponent|undefined {
    for(let identifiers of this.taxpayerCertificateFileIdentifiers) {
      if(identifiers[0] === type)
        return this[identifiers[1]];
    }
    return undefined;
  }

  private getPassportPhotoName(type: string): string {
    for(let identifiers of this.passportPhotoIdentifiers) {
      if(identifiers[0] === type)
        return identifiers[2];
    }
  }

  private getDriverLicensePhotoName(type: string): string {
    for(let identifiers of this.driverLicensePhotoIdentifiers) {
      if(identifiers[0] === type)
        return identifiers[2];
    }
  }

  private getTaxpayerCertificateFileName(type: string): string {
    for(let identifiers of this.taxpayerCertificateFileIdentifiers) {
      if(identifiers[0] === type)
        return identifiers[2];
    }
  }

  isPassportTypeOf(types: string[]): boolean {
    return types.indexOf(this.employee.record.passport.type) != -1;
  }

  onBack(): boolean {
    this.back.emit();

    return false;
  }

  onSave(): void {
    this.prepareToSave();

    if(this.employee.isNew)
      this.add();
    else
      this.update();
  }

  onDeleteEmployeePhoto(): void {
    this.removeEmployeePhoto();
  }

  onDeletePassportPhoto(type: string): void {
    this.removePassportPhoto(type);
  }

  onDeleteDriverLicensePhoto(type: string): void {
    this.removeDriverLicensePhoto(type);
  }

  onDeleteTaxpayerCertificateFile(type: string): void {
    this.removeTaxpayerCertificateFile(type);
  }

  onShowInnDialog(): void {
    this.showInnDialog();
  }

  onSelectInn(inn: string): void {
    console.log(`Selected inn: ${inn}`);

    if(inn && inn !== '')
      this.applyInn(inn);
    else
      this.unlinkInn();
  }

  onUnlinkInn(): void {
    if(confirm('Отсоеденить юр. лицо от водителя и сделать его физ. лицом?')) {
      this.unlinkInn();
    }
  }

  onMainWorkTransportSelected(transport: Transport): void {
    this.employee.record.mainWorkTransport = transport;
  }

  onSelectMainWorkTransport(): void {
    this.transportDialog.showDialog();
  }
}
