import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from "../_services/alert.service";
import {LoginResult, LoginService, RestorePasswordResult} from "../_services/login.service";
import {UserInfoService} from "../_services/user-info.service";
import {VoximplantService} from "../_services/voximplant.service";

const CODE_LENGTH = 4;

@Component({
  styleUrls: [ './login.component.css' ],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  phone = "";
  password = "";
  loading = false;
  returnUrl: string;
  informationFrom = false;
  emailMode = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private loginService: LoginService,
    private userInfoService: UserInfoService,
    private voximplantService: VoximplantService
  ) { }

  ngOnInit() {
    // reset images status
    // this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.phone = this.loginService.restorePhone() || "";
    this.emailMode = !/^\d+$/.test(this.phone);

    console.log("Restored phone: " + this.phone);
  }

  onSubmit() {
    this.loginService.savePhone(this.phone);
    console.log("Saved phone: " + this.phone);

    this.alertService.clear();
    this.loading = true;

    this.loginService.login(this.phone, this.password)
      .then(r => {
        this.loading = false;
        switch (r) {
          case LoginResult.NotFound:
            this.alertService.warning('Пользователь не найден');
            return;
          case LoginResult.AccessDenied:
            this.alertService.warning('У вас нет доступа');
            return;
          case LoginResult.SystemError:
            this.alertService.error('На сервере произошла ошибка. Попробуйте позже или обратитесь к администратору');
            break;
          case LoginResult.IncorrectPassword:
            this.alertService.warning('Неверный пароль');
            break;
          case LoginResult.Ok:
            this.loading = true;
            this.userInfoService
              .setup()
              .then(() => {
                if(this.userInfoService.isPrivilegedUser())
                  this.voximplantService.init();
              })
              .then(() => {
                this.loading = false;
                this.router.navigate([this.returnUrl]);
              })
              .catch(e => {
                this.loading = false;
                this.alertService.error(e.message)
              });
            break;
        }
      })
      .catch(e => {
        this.loading = false;
        this.alertService.error(`Неожиданная ошибка (${e.message()}). Обратитесь в службу техподдержки`);
        console.log(e);
      })
    ;
  }

  goReset() {this.informationFrom = true;}
  outReset() {this.informationFrom = false;}


  onRequestCode() {
    this.loading = true;
    return this.loginService
      .restorePassword(this.phone)
      .then(r => {
        this.loading = false;
        switch (r) {
          case RestorePasswordResult.UnknownPhone:
            this.alertService.warning('Пользователь не найден. Проверьте корректность номера телефона или зарегистрируйтесь.');
            return;
          case RestorePasswordResult.SystemError:
            this.alertService.error('На сервере произошла ошибка. Попробуйте позже или обратитесь к администратору');
            break;
          case RestorePasswordResult.Ok:
            this.alertService.success('На указанный номер отправлен sms с кодом');
            break;
        }
      })
      .catch(e => {
        this.loading = false;
        this.alertService.error(`Неожиданная ошибка (${e.message()}). Обратитесь в службу техподдержки`);
        console.log(e);
      });
    };

  onChangeEmailMode(emailMode: boolean): void {
    this.emailMode = emailMode;

    if(!emailMode && !/^\d+$/.test(this.phone))
      this.phone = '';
  }
}
