import {Connection} from "../connection";
import {CarFeed} from "../messages/car-feed";
import {FeedTransport} from "../../_models/feed-transport";
import {UpFeed} from "../messages/up-feed";
import {Subject} from "rxjs";
import {Message} from "../messages/message";

export class ScreenConnection extends Connection {
  public message: Subject<Message> = new Subject();

  protected onMessage(data: any) {
    switch(data['action']) {
      case 'carfeed':
        var message = new CarFeed();
        message.transports = data['data'] as FeedTransport[];
        this.message.next(message);
        break;
      case 'upfeed':
        var message = new UpFeed();
        message.transports = data['data'] as FeedTransport[];
        this.message.next(message);
        break;
    }
  }
}
