import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderPeriod} from "../_models/order-period";
import {Order} from "../_models/order";
import {TransportTariff} from "../_models/transport-tariff";
import {LoaderTariff} from "../_models/loader-tariff";
import {AssemblerTariff} from "../_models/assembler-tariff";
import {LiftingTariff} from "../_models/lifting-tariff";
import {OrderService} from "../_services/order.service";
import {LoaderService} from "../_services/loader.service";
import {Observable} from "rxjs";
import {FreighterTransportTariff} from "../_models/freighter-transport-tariff";
import {FreighterLoaderTariff} from "../_models/freighter-loader-tariff";
import {FreighterLoaderTariffService} from "../_services/freighter-loader-tariff.service";
import {FreighterTransportTariffService} from "../_services/freighter-transport-tariff.service";
import {UserInfoService} from "../_services/user-info.service";

@Component({
  selector: 'tariff-editor',
  templateUrl: './tariff-editor.component.html',
  styleUrls: ['./tariff-editor.component.css']
})
export class TariffEditorComponent implements OnInit {
  @Input() order: Order;
  @Input() period: OrderPeriod;
  @Output() onUpdated = new EventEmitter<OrderPeriod>();

  transportTariff: TransportTariff;
  loaderTariff: LoaderTariff;
  assemblerTariff: AssemblerTariff;
  liftingTariff: LiftingTariff;

  transportTariffs: FreighterTransportTariff[] = [];
  loaderTariffs: FreighterLoaderTariff[] = [];

  transportTariffId: string = "";
  loaderTariffId: string = "";

  constructor(
    private orderService: OrderService,
    private loaderService: LoaderService,
    private freighterTransportTariffService: FreighterTransportTariffService,
    private freighterLoaderTariffService: FreighterLoaderTariffService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit() {
    this.transportTariff = this.period.tariff || new TransportTariff();
    this.loaderTariff = this.period.loader_tariff;
    this.assemblerTariff = this.period.assembler_tariff;
    this.liftingTariff = this.period.lifting_tariff;

    this.initTariffLists();
  }

  private initTariffLists() {
    if(!this.userInfoService.isPrivilegedUser()) {
      this.initTransportTariffList();
      this.initLoaderTariffList();
    }
  }

  private initTransportTariffList() {
    this.freighterTransportTariffService
      .getTariffs()
      .subscribe(
        tariffs => this.transportTariffs = tariffs,
        () => {}
      );
  }

  private initLoaderTariffList() {
    this.freighterLoaderTariffService
      .getTariffs()
      .subscribe(
        tariffs => this.loaderTariffs = tariffs,
        () => {}
      );
  }

  private applyTransportTariff(tariff: FreighterTransportTariff) {
    this.transportTariff.min_price = tariff.min_price;
    this.transportTariff.min_hours = tariff.min_hours;
    this.transportTariff.additional_hour_price = tariff.additional_hour_price;
    this.transportTariff.after_mkad_km_price = tariff.after_mkad_km_price;
    this.transportTariff.ttk_price = tariff.ttk_price;
    this.transportTariff.hydroelevator_hour_price = tariff.hydroelevator_hour_price;
  }

  private applyLoaderTariff(tariff: FreighterLoaderTariff) {
    this.loaderTariff.min_price = tariff.min_price;
    this.loaderTariff.min_hours = tariff.min_hours;
    this.loaderTariff.additional_hour_price = tariff.additional_hour_price;
    this.loaderTariff.on_the_way_hour_price = tariff.on_the_way_hour_price;

    this.assemblerTariff.min_price = tariff.assembler_min_price;
    this.assemblerTariff.min_hours = tariff.assembler_min_hours;
    this.assemblerTariff.additional_hour_price = tariff.assembler_additional_hour_price;

    this.liftingTariff.min_price = tariff.lifting_min_price;
    this.liftingTariff.min_hours = tariff.lifting_min_hours;
    this.liftingTariff.additional_hour_price = tariff.lifting_additional_hour_price;
  }

  onSave() {
    this.loaderService.show();
    this.orderService
      .updateTransportTariff(this.order, this.period, this.transportTariff)
      .toPromise()
      .then(() => this.orderService.updateLoaderTariff(this.order, this.period, this.loaderTariff).toPromise())
      .then(() => this.orderService.updateAssemblerTariff(this.order, this.period, this.assemblerTariff).toPromise())
      .then(() => this.orderService.updateLiftingTariff(this.order, this.period, this.liftingTariff).toPromise())
      .then(() => {
        this.loaderService.hide();
        this.onUpdated.emit(this.period);
      })
      .catch(() => this.loaderService.hide());
  }

  onSelectTransportTariff() {
    console.log(`Selected #${this.transportTariffId} transport tariff`);

    if(this.transportTariffId === '')
      return;

    let id = +this.transportTariffId;
    let tariff = this.transportTariffs.find(tariff => tariff.id === id);

    if(tariff)
      this.applyTransportTariff(tariff);
  }

  onSelectLoaderTariff() {
    console.log(`Selected #${this.loaderTariffId} loader tariff`);

    if(this.loaderTariffId === '')
      return;

    let id = +this.loaderTariffId;
    let tariff = this.loaderTariffs.find(tariff => tariff.id === id);

    if(tariff)
      this.applyLoaderTariff(tariff);
  }
}
