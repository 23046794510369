import {Transport} from "../_models/transport";
import {TRANSPORT_BODIES} from "../_maps/transport-bodies";
import {CAPACITIES} from "../_maps/capacities";

export class TransportUtils {
  static getTransportDescription(transport: Transport): string {
    if(!transport)
      return '-';

    if(transport.is_virtual)
      return 'данные не доступны';

    return `${transport.car_model},`
      + ` ${this.transformBody(transport.features.Tip_kuzova)},`
      + ` ${this.transformCapacity(transport.features.capacity)},`
      + ` ${transport.features.Dlina ? transport.features.Dlina + 'м' : '-'},`
      + ` ${transport.features.Razreshen_v_ezd_v_TTK ? 'ТТК,' : ''}`
      + ` ${transport.features.number || '-'}`
      ;
  }

  static getTransportExtendedDescription(transport: Transport): string {
    if(!transport)
      return '-';

    if(transport.is_virtual)
      return 'данные не доступны';

    return `${transport.car_model},`
      + ` ${this.transformBody(transport.features.Tip_kuzova)},`
      + ` ${this.transformCapacity(transport.features.capacity)},`
      + ` ${transport.features.Razreshen_v_ezd_v_TTK ? 'ТТК,' : ''}`
      + ` ${transport.features.number || '-'}, `
      + TransportUtils.getTransportSizeExtendedDescription(transport) + ','
      + ` ${transport.features.Ob_em_m3}м³`
      ;
  }

  static getTransportSizeDescription(transport: Transport): string {
    if(transport.is_virtual)
      return 'данные не доступны';

    return (transport.features.Dlina || '-')
      + ' x ' + (transport.features.Shirina || '-')
      + ' x ' + (transport.features.Vyisota || '-');
  }

  static getTransportSizeExtendedDescription(transport: Transport): string {
    if(transport.is_virtual)
      return 'данные не доступны';

    return (transport.features.Dlina || '-')
      + 'м x ' + (transport.features.Shirina || '-')
      + 'м x ' + (transport.features.Vyisota || '-') + 'м';
  }

  private static transformBody(value): string {
    return value && TRANSPORT_BODIES[value] ? TRANSPORT_BODIES[value] : '-';
  }

  private static transformCapacity(value): string {
    return value && CAPACITIES[value] ? CAPACITIES[value] + 'т' : '-';
  }
}
