import {Destination} from "../destination";
import {DeliveryHistoryRecord} from "../delivery-history-record";

/**
 * Калькуляция доставки по адресу
 */
export class DestinationCalculation {
  /**
   * Точка периода
   */
  destination: Destination;
  /**
   * Записи истории выполнения
   */
  historyRecords: DeliveryHistoryRecord[];
  /**
   * Итог по истории
   */
  total: DeliveryHistoryRecord;
}
