<form>
  <div class="list-controls">
    <div class="btn-group list-type" role="group">
      <button type="button" class="btn btn-default" [class.active]="!filter.withoutCrew" (click)="onSwitchWithoutCrewFilter()">Все</button>
      <button type="button" class="btn btn-default" [class.active]="filter.withoutCrew" (click)="onSwitchWithoutCrewFilter()">Без экипажа</button>
    </div>
    <div class="search-field form-group">
      <input class="form-control" type="text" name="surname" placeholder="Фамилия" [(ngModel)]="filter.surname"
             (ngModelChange)="onChangeFilter()">
    </div>
    <div class="search-field form-group">
      <input class="form-control" type="text" name="phone" placeholder="Телефон" [(ngModel)]="filter.phone"
             (ngModelChange)="onChangeFilter()">
    </div>
  </div>
</form>

<div class="list-controls">
  <pager [list]="employees" (onPage)="onPaginationPage($event)"></pager>
</div>

<div class="employees-container">
  <table class="employees-table">
    <thead class="employees-table-header">
    <tr>
      <th>Имя</th>
      <th>Телефон</th>
      <th>e-mail</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let employee of employees.employees" (click)="onSelect(employee)">
      <td>{{ employee.account|fullName }}</td>
      <td>{{ employee.account.phone|formatPhone }}</td>
      <td>{{ employee.account.email }}</td>
    </tr>
    </tbody>
  </table>
</div>
