
import {of as observableOf, Observable, Subject} from 'rxjs';

import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Account} from "../_models/account";
import {InvoiceService} from "../_services/invoice.service";
import {Page} from "../pager/page";
import {Order} from "../_models/order";
import {InvoicePresentersList} from "../_models/invoice-presenters-list";

class ListCommand {
  constructor(
    private _page?: number,
    private _force?: boolean
  ) {}

  get page(): number {
    return this._page;
  }

  get force(): boolean {
    return this._force;
  }

  equals(cmd: ListCommand): boolean {
    console.log('list commands compare:', !cmd._force && this._page === cmd.page);
    return !cmd._force && this._page === cmd.page;
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'account-invoices',
  templateUrl: './account-invoices.component.html',
  styleUrls: ['./account-invoices.component.css']
})
export class AccountInvoicesComponent implements OnInit, OnChanges {
  @Input() account: Account;
  @Output() onOpenOrder = new EventEmitter<Order>();

  private listCommands = new Subject<ListCommand>();

  invoices: InvoicePresentersList = InvoicePresentersList.empty();
  loading = false;

  constructor(private invoiceService: InvoiceService) { }

  ngOnInit() {
    this.initInvoices();
    this.loadInvoices(0, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadInvoices(0, true);
  }

  private initInvoices(): void {
    this.listCommands.pipe(
      debounceTime(250),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loading = true;
        this.invoices = InvoicePresentersList.empty();
        return this.invoiceService
          .getLastAccountInvoices(this.account, command.page).pipe(
          finalize(() => this.loading = false));
      }),
      catchError(e => {
        console.error(e);
        this.initInvoices();
        return observableOf(InvoicePresentersList.empty());
      }),)
      .subscribe(list => this.invoices = list)
    ;
  }

  private loadInvoices(page?: number, force?: boolean): void {
    if(force) {
      this.invoices = InvoicePresentersList.empty();
      this.loading = true;
    }

    this.listCommands.next(new ListCommand(page, force));
  }

  onPaginationPage(page: Page) {
    this.loadInvoices(page.num);
  }

  onClickOrderLink(order: Order): void {
    this.onOpenOrder.emit(order);
  }
}
