import {Invoice} from "../_models/invoice";
import {OrderPaymentState} from "../_models/order-payment-state";
import {PAYMENT_TYPES} from "../_maps/payment-types";
import {PAYMENT_STATUSES} from "../_maps/payment-statuses";
import {OrderPaymentStatus} from "../_enumerations/order-payment-status";

export class InvoiceUtils {
  /**
   * Преобразует счета в состояния оплаты заказа
   *
   * @param invoices
   * @return Map<номер заказа, состояние оплаты>
   */
  static invoicesToStates(invoices: Invoice[]): Map<number, OrderPaymentState> {
    let states = new Map<number, OrderPaymentState>();

    for(let invoice of invoices) {
      if(!states.has(invoice.order.id)) {
        let state = new OrderPaymentState();
        state.orderId = invoice.order.id;

        states.set(state.orderId, state)
      }

      this.applyInvoiceToOrderPaymentState(states.get(invoice.order.id), invoice);
    }

    return states;
  }

  /**
   * Применение счёта к состоянию оплаты заказа
   *
   * @param state
   * @param invoice
   */
  private static applyInvoiceToOrderPaymentState(state: OrderPaymentState, invoice: Invoice): void {
    state.invoices.push(invoice);

    if(invoice.payment_type == PAYMENT_TYPES.ORDER && invoice.amount < 0) {
      this.updateStateStatus(state, 'paymentStatus', 'declinePaymentReason', invoice);
      state.paymentDate = new Date(invoice.pay_before_at);
    }

    if(invoice.payment_type == PAYMENT_TYPES.ORDER_FEE && invoice.amount < 0) {
      this.updateStateStatus(state, 'feeStatus', 'declineFeeReason', invoice);
      state.feeDate = new Date(invoice.pay_before_at);
      state.feeAmount += -invoice.amount
    }

    if(invoice.payment_type == PAYMENT_TYPES.EMPLOYEE_PAYOUT_CONSOLIDATION && invoice.amount > 0) {
      this.updateStateStatus(state, 'payoutStatus', 'declinePayoutReason', invoice);
      state.payoutAmount += invoice.amount;
    }
  }

  private static updateStateStatus(state: OrderPaymentState, stateProperty: string, declineReasonProperty: string, invoice: Invoice): void {
    switch (invoice.status) {
      case PAYMENT_STATUSES.COMPLETED:
        state[stateProperty] = OrderPaymentStatus.COMPLETED;
        break;
      case PAYMENT_STATUSES.AUTHORIZED:
        state[stateProperty] = OrderPaymentStatus.AUTHORIZED;
        break;
      case PAYMENT_STATUSES.DECLINED:
        state[stateProperty] = OrderPaymentStatus.DECLINED;
        state[declineReasonProperty] = invoice.reason;
        break;
      default:
        state[stateProperty] = OrderPaymentStatus.NOT_PAID;
    }
  }
}
