
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Inject, Input, NgZone, OnInit, Output} from '@angular/core';
import {Order} from "../_models/order";
import {OrderService} from "../_services/order.service";
import {LoaderService} from "../_services/loader.service";
import {Image} from "../_models/image";
import {environment} from "../../environments/environment";
import {AlertService} from "../_services/alert.service";
import {ApiUtilsService} from "../_services/api-utils.service";

@Component({
  selector: 'photo-comment-editor',
  templateUrl: './photo-comment-editor.component.html',
  styleUrls: ['./photo-comment-editor.component.css']
})
export class PhotoCommentEditorComponent implements OnInit {
  @Input() order: Order;
  @Output() onUpdated = new EventEmitter<Order>();
  @Output() onUpdatedPhoto = new EventEmitter<Order>();

  comment: string;
  photos: Image[];

  constructor(
    private orderService: OrderService,
    private loaderService: LoaderService,
    @Inject(NgZone) private zone: NgZone,
    private alertService: AlertService,
    private apiUtilsService: ApiUtilsService
  ) {}

  ngOnInit() {
    // this.options = new UploaderOptions({
    //   url: `${environment.apiEndpoint}${this.apiUtilsService.getPrefixForOrder(this.order)}/orders/${this.order.id}/photos.json`,
    //   autoUpload: true,
    //   calculateSpeed: true,
    //   fieldName: 'photo',
    //   cors: true,
    //   withCredentials: true,
    //   maxUploads: 5,
    //   fieldReset: true,
    //   filterExtensions: true,
    //   allowedExtensions: [ 'jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG' ]
    // });
    this.comment = this.order.draft.comment;
    this.photos = this.order.draft.photos;
  }

  handleUpload(data: any) {
    setTimeout(() => {
      this.zone.run(() => {
        if(!data.done)
          return;

        let response: any;
        if (data && data.response) {
          response = JSON.parse(data.response);
        }
        if(data.status == 400) {
          this.alertService.warning(`Ошибка загрузки фотографии: '${response.message}'`)
        } else if(data.status != 200) {
          this.alertService.error("На сервере произошла ошибка. Повторите попытку или обратитесь в техподдержку.")
        } else {
          this.photos.push(response.file as Image);
          this.onUpdatedPhoto.emit(this.order);
        }

        this.loaderService.hide();
      });
    });
  }

  deletePhoto(photo: Image) {
    this.loaderService.show();
    this.orderService
      .deletePhoto(this.order, photo).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.deleteLocalPhoto(photo);
          this.onUpdatedPhoto.emit(this.order);
        },
        r => {
          if(r.status == 404) {
            this.alertService.warning('Фотография не найдена');
          }
        }
      )
  }

  private deleteLocalPhoto(photo: Image) {
    let i = this.photos.indexOf(photo);
    if(i >= 0)
      this.photos.splice(i, 1);
  }

  onBeforeUpload() {
    this.alertService.clear();
    this.loaderService.show();
  }

  onSave() {
    this.order.draft.comment = this.comment;

    this.loaderService.show();
    this.orderService
      .updateOrderComment(this.order, this.comment).pipe(
      finalize(() => this.loaderService.hide()))
      .toPromise()
      .then(() => this.onUpdated.emit(this.order))
      .catch(() => {});
  }

  onDelete(photo: Image) {
    if(confirm("Удалить фото? В дальнейшем, его невозможно будет восстановить"))
      this.deletePhoto(photo);
  }
}
