<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row">
  <div class="no-padding crews-forms crews-form-box">
    <form class="form-inline crews-forms">
      <div class="form-group search-box">
        <input
          type="text"
          class="crews-search crews-control"
          name="search"
          [(ngModel)]="filter.search"
          (ngModelChange)="onChangeFilter()"
          placeholder="Поиск"
          size="50">
      </div>
      <div class="form-group">
        <div class="labeled-select">
          <label for="status">Статус водителя</label>
          <select class="form-control" id="status" [(ngModel)]="filter.status" (change)="onChangeFilter()"
                  name="status">
            <option value="" selected="selected">Любой</option>
            <option *ngFor="let status of employerStatuses|mapToIterable" [value]="status.key"
                    class="opt-lost">{{ status.val }}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="labeled-select">
          <label for="body">Кузов</label>
          <select class="form-control" id="body" [(ngModel)]="filter.transportBody" (change)="onChangeFilter()"
                  name="body">
            <option value="" selected="selected">Любой</option>
            <option *ngFor="let status of transportBodies|mapToIterable" [value]="status.key"
                    class="opt-lost">{{ status.val }}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="labeled-select">
          <label for="body">Грузоподъёмность</label>
          <select class="form-control" id="capacity" [(ngModel)]="filter.transportCapacity" (change)="onChangeFilter()"
                  name="capacity">
            <option value="" selected="selected">Любая</option>
            <option *ngFor="let status of capacities|mapToIterable" [value]="status.key"
                    class="opt-lost">{{ status.val }} т
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>

  <div class="no-padding crews-form-box availability-box">
    <div class="title">Доступность экипажей</div>
    <div class="crews-forms">
      <form class="form-inline crews-forms">
        <div class="form-group date-box">
          <input
            type="date"
            class="crews-search crews-control"
            name="date"
            [(ngModel)]="dateFilter"
            (ngModelChange)="onChangeFilter(true)"
            size="12">
        </div>
        <div class="form-group time-box">
          <input
            type="time"
            class="crews-search crews-control"
            name="time"
            [(ngModel)]="timeFilter"
            (ngModelChange)="onChangeFilter(true)"
            size="6">
        </div>
        <div class="time-controls">
          <button type="button" (click)="onTimeSyncToggle()" class="btn btn-default" [class.active]="syncTime"
                  title="Синхронизировать с текущим временем">
            <img src="/assets/images/crew/time-sync.png" alt="">
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="list-controls">
    <pager [list]="crews" (onPage)="onPaginationPage($event)"></pager>
    <div class="control" [routerLink]="['/crews/new']" [queryParams]="{ date: filter.date.getTime() }">
      <div class="add-crew"><span class="glyphicon glyphicon-plus"></span> Добавить экипаж ({{ countOfEmployerWithoutCrews }})</div>
    </div>
  </div>

  <div class="crews-container">
    <table class="crews-table">
      <thead class="crews-table-header">
      <tr>
        <th rowspan="2">Водитель</th>
        <th rowspan="2">Онлайн</th>
        <th rowspan="2">Статус</th>
        <th rowspan="2">Машина</th>
        <th rowspan="2" class="text-center">Грузчики</th>
        <th colspan="2" class="text-center">Время действия</th>
      </tr>
      <tr>
        <th class="text-center">Начало</th>
        <th class="text-center">Окончание</th>
      </tr>
      </thead>
      <tbody *ngFor="let crew of crews.crews" class="order-table-row" [class.other-freighter]="freighterId != crew.freighter.id"
             [routerLink]="['/crews', crew.id]" [queryParams]="{ date: filter.date.getTime() }">
      <tr *ngIf="freighterId != crew.freighter.id">
        <td colspan="7" class="freighter-row"><span>{{ crew.freighter.name }}</span></td>
      </tr>
      <tr>
        <td>
          <div class="name">{{ crew.employer.account|fullName }}</div>
          <div class="freighter" *ngIf="freighterId != crew.employer.freighter.id">[{{ crew.employer.freighter.name }}]</div>
          <div class="phone">{{ crew.employer.account.phone|formatPhone }}</div>
          <div class="email">{{ crew.employer.account.email }}</div>
        </td>
        <td>
          <div class="online-date" *ngIf="crew.employer.account.auth.online_at">
            <div class="online-flag" [class.online]="isOnline(crew.employer.account.auth)"
                 [class.offline]="!isOnline(crew.employer.account.auth)"></div>
            {{ crew.online_at|dt:"dd.MM.yy HH:mm" }}</div>
        </td>
        <td class="status status-{{ crew.employer.status }}">
          {{ crew.employer.status|employerStatus }}
          <div *ngIf="crew.taxi">
            <div class="taxi">Такси</div>
          </div>
        </td>
        <td rowspan="2">
          {{ crew.transport|transportDescription }}
          <div class="freighter" *ngIf="freighterId != crew.transport.freighter.id">[{{ crew.transport.freighter.name }}]</div>
          <div *ngIf="crew.tariff_tiers" class="taxi-tariffs">
            <taxi-tariff-informer *ngFor="let tier of crew.tariff_tiers" [tier]="tier"></taxi-tariff-informer>
          </div>
        </td>
        <td rowspan="2" class="text-center">{{ crew.loaders }}<span
          *ngIf="crew.self_loader" title="сам грузчик">+1</span></td>
        <td rowspan="2" class="date-time text-center">{{ crew.start_date|dt:"dd.MM.yy HH:mm" }}</td>
        <td rowspan="2" class="date-time text-center">{{ crew.end_date|dt:"dd.MM.yy HH:mm" }}</td>
      </tr>
      <tr class="last-subrow">
        <td colspan="3" class="comment">{{ crew.employer.comment }}</td>
      </tr>
      </tbody>
    </table>
    <div class="show-more-btn" *ngIf="showMore">
      <show-more [list]="crews" (onPage)="onShowMorePage($event)"></show-more>
    </div>
  </div>
</div>
