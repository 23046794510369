<div class="control-btn-row" *ngIf="!enabledRollback">
<button class="control-btn" type="button" [disabled]="!enabledButtons.restart"
        [class.active]="activeButtons.restart" title="Перезапустить заказ" (click)="onRestart()">
  <i class="material-icons">refresh</i>
</button>
<button class="control-btn" type="button" *ngIf="visibleButtons.stop"
        [disabled]="!enabledButtons.stop" [class.active]="activeButtons.stop" title="Отменить заказ" (click)="onStop('canceled_by_client')">
  <i class="material-icons">stop</i>
</button>
<button class="control-btn" type="button" *ngIf="visibleButtons.stopScheduled"
        data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false"
        [disabled]="!enabledButtons.stop" [class.active]="activeButtons.stop" title="Отменить заказ">
  <i class="material-icons">stop</i>
</button>
<ul class="dropdown-menu stop-menu" *ngIf="visibleButtons.stopScheduled">
  <li><button type="button" class="btn btn-link" (click)="onStop('canceled_by_client')"><i class="material-icons icon">restart_alt</i><span class="text">Остановить и запланировать новый подбор</span></button></li>
  <li><button type="button" class="btn btn-link" (click)="onStopAndCancelSchedule('canceled_by_client')"><i class="material-icons icon">block</i><span class="text">Остановить и отменить повторный подбор</span></button></li>
</ul>
<button  class="control-btn" type="button" [disabled]="!enabledButtons.pause"
        [class.active]="activeButtons.pause" title="Приостановить/возобновить заказ" (click)="onPause()">
  <i class="material-icons">pause</i>
</button>
<button class="control-btn" type="button" [disabled]="!enabledButtons.play"
        [class.active]="activeButtons.play" title="Запустить заказ" (click)="onPlay()">
  <i class="material-icons">play_arrow</i>
</button>
</div>

<div class="control-btn-row" *ngIf="enabledRollback">
  <button class="control-btn" type="button" title="Откатить заказ" (click)="onRollback()"><img src="/assets/images/order-actions/rollback.png" alt="Откатить заказ"></button>
</div>
