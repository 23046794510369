import {DateTime} from "date-time-js";
import {City} from "../_models/city";

export class DateUtils {
  private static _currentTimeZoneOffset: number|null = null;

  static get currentTimeZoneOffset(): number {
    if(DateUtils._currentTimeZoneOffset === null)
      DateUtils._currentTimeZoneOffset = -new Date().getTimezoneOffset();

    return DateUtils._currentTimeZoneOffset;
  }

  static convertDate(date: Date): string {
    return date.getFullYear() +
      '-' + DateUtils.pad(date.getMonth() + 1) +
      '-' + DateUtils.pad(date.getDate()) +
      'T' + DateUtils.pad(date.getHours()) +
      ':' + DateUtils.pad(date.getMinutes()) +
      ':' + DateUtils.pad(date.getSeconds());
  }

  static roundMinutesToHour(minutes: number): number {
    let hours = Math.floor(minutes / 60);
    if(minutes - hours * 60 > 10)
      hours ++;

    return hours;
  }

  private static pad(number: number): string {
    if (number < 10) {
      return '0' + number;
    }
    return number + "";
  }

  static isEqualDates(date1?: Date, date2?: Date): boolean {
    if(date1 == null && date2 == null)
      return true;

    if(date1 == null || date2 == null)
      return false;

    let date1Str = new DateTime(date1).format('yyyy-MM-dd');
    let date2Str = new DateTime(date2).format('yyyy-MM-dd');

    return date1Str == date2Str;
  }

  static formatDate(date: Date): string {
    return new DateTime(date).format('yyyy-MM-dd');
  }

  /**
   * Преобразует значение time zone, полученное из параметров города в смещение в минутах
   *
   * @param cityTimeZone
   */
  static cityTimeZoneToOffset(cityTimeZone: number): number {
    let hours = Math.floor(cityTimeZone / 100);
    let minutes = cityTimeZone - hours * 100;

    return hours * 60 + minutes;
  }

  static offsetToCityTimeZone(offset: number): number {
    let hours = Math.floor(offset / 60);
    let minutes = offset - hours * 60;
    return hours * 100 + minutes;
  }

  static offsetTimeByCityTimeZone(date: Date, city: City, direction = 1): Date {
    let cityOffset = DateUtils.cityTimeZoneToOffset(city.time_zone);
    let offsetDiff = cityOffset - this.currentTimeZoneOffset;
    return new DateTime(new Date(date)).add(offsetDiff * direction, 'minute').toDate();
  }
}
