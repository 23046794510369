import {DeliveryProductLine} from "./delivery-product-line";
import {DeliveryTrack} from "./delivery-track";

export class DeliveryProduct {
  id: number;
  code: string;
  lines: DeliveryProductLine[];
  name: string;
  count: number;
  cost: number;
  delivery_cost: number;
  discount: number;
  floor: number;
  pay_method: string;
  external_pay_link: boolean;
  assembly: boolean;
  paid: boolean;
  tracks: DeliveryTrack[];
  length: number;
  width: number;
  height: number;
  weight: number;
}
