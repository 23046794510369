import {OptimizationTaskDestination} from "./optimization-task-destination";
import {OptimizationTaskTransport} from "./optimization-task-transport";
import {OptimizationTaskFailedDestination} from "./optimization-task-failed-destination";
import {City} from "./city";
import {DeliverySchema} from "./delivery-schema";

export class OptimizationTask {
  id: number;
  date: string;
  cost: number;
  status: string;
  execution_status: string;
  optimization_type: string;
  optimization_progress: number;
  strategy: string;
  destinations: OptimizationTaskDestination[] = [];
  fail_destinations: OptimizationTaskFailedDestination[] = [];
  transports: OptimizationTaskTransport[];
  optimization_stage: string;
  error_text: string;
  city: City;
  connect_not_optimized_points: boolean;
  lock_for_external: boolean;
  storehouse_id: number;
  delivery_schema: DeliverySchema;
  optimization_estimated_complete_time: string;
  imported_from: string;
}
