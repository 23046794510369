<div *ngFor="let destination of period.destinations; let i = index;">
  <div class="flex-row">
    <a class="address-header" data-toggle="collapse" href="#address-collapse-{{i}}" aria-expanded="true"
    >Адрес {{ i + 1}} </a>
    <i class="material-icons">keyboard_arrow_down</i>
  </div>
  <div class="collapse-body collapse in" id="address-collapse-{{i}}">
    <div class="flex-centered flex-end delete-row">
      <span (click)="onDelete(i)">Удалить</span>
    </div>
    <div class="flex-centered flex-space-between">
      <address-field class="address-field" [point]="destination.destination"
                     (onChange)="destination.destination = $event"
                     (onFocus)="onFocusAddr($event)"
                     [context]="order.draft.city && order.draft.city.geo_context"></address-field>
      <input type="number" class="text-input floor-input" name="floor" [(ngModel)]="destination.floor"
             placeholder="Этаж">
    </div>
    <div class="form-group">
      <span class="destination-options" title="Показать/скрыть редактор опций"
            (click)="toggleOptionEditorVisibility(destination)">
        {{ getOptionsAsString(destination, 'опции') }}
      </span>
    </div>
    <div class="form-group destination-options-editor" *ngIf="isVisibleOptionsEditor(destination)">
      <table class="table">
        <tr>
          <td>
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="destination.elevator" name="elevator"> Грузовой лифт
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="destination.lifting" name="lifting"> Требуется подъём/спуск
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="destination.loading" name="loading"> Погрузка
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="destination.unloading" name="unloading"> Разгрузка
              </label>
            </div>
          </td>
        </tr>
        <tr *ngIf="order.draft.delivery">
          <td>
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="destination.unloading_internet_shop_order"
                       name="unloading_order_internet_shop_order">
                Разгрузка заказа из интернет-магазина
              </label>
            </div>
          </td>
          <td *ngIf="i == 0">
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="destination.required_additional_time"
                       name="required_additional_time">
                Требуется дополнительное время на работу с грузом
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label for="delivery-to-{{i}}">Место забора/доставки</label>
            <select class="form-control" [(ngModel)]="destination.delivery_to" id="delivery-to-{{i}}"
                    name="deliveryTo{{i}}">
              <option value="">не указано</option>
              <option *ngFor="let v of deliveryVariants|mapToIterable" [value]="v.key">{{ v.val }}</option>
            </select>
          </td>
        </tr>
      </table>

    </div>

    <div class="legal-entity">
      <div class="title">Юридическое лицо</div>
      <div *ngIf="destination.client_legal_entity" class="name">
        <button class="btn btn-xs btn-danger" title="Убрать" (click)="onUnselectLegalEntity(destination)"><span class="glyphicon glyphicon-remove"></span></button>
        {{ destination.client_legal_entity.name }} ({{ destination.client_legal_entity.inn }})
      </div>
      <button class="select-button" (click)="onOpenLegalEntitySelectorFor(destination)">Выбрать</button>
    </div>

    <div class="flex-centered flex-space-between">
      <label>Контактное лицо
        <input class="text-input contact-name" type="text" name="contact_name[{{i}}]" [(ngModel)]="destination.contact_name"></label>
      <label>Контактный телефон
        <input class="text-input" type="text" name="contact_phone[{{i}}]" [(ngModel)]="destination.contact_phone"></label>
    </div>

    <div class="checkbox sms-action">
      <label>
        <input type="checkbox" class="checkbox" name="contact_send_sms[{{i}}]" [(ngModel)]="destination.contact_send_sms">
        Отправить смс-уведомление контакту
      </label>
    </div>

    <div class="form-group">
      <label>Коментарий</label><Br>
      <textarea class="text-input full-width text-area" name="comment[{{i}}]" [(ngModel)]="destination.comment" rows="2"
                placeholder="Введите комментарий"></textarea>
    </div>

    <div class="cargo">
      <div class="title">Груз</div>
      <button class="select-button" (click)="onShowDeliveryProductDialog(destination)">Изменить состав ({{ destination.delivery_product ? (destination.delivery_product.lines || []).length : 0 }})</button>
    </div>
  </div>
  <div class="flex-row flex-start">
    <button class="btn btn-default btn-xs glyphicon glyphicon-chevron-up" type="button" *ngIf="i != 0" title="Выше"
            (click)="onUp(i)"></button>
    <button class="btn btn-default btn-xs glyphicon glyphicon-chevron-down" type="button"
            *ngIf="i < period.destinations.length - 1" title="Ниже" (click)="onDown(i)"></button>
  </div>
</div>
<div class="flex-row flex-start">
  <button class="save-btn add-btn" type="button" (click)="onAdd()"><i class="material-icons">add</i></button>
  <button class="save-btn" type="button" (click)="onSave()">Сохранить</button>
</div>
