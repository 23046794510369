<form (submit)="onSave()">
  <div class="checkbox">
    <label>
      <input type="checkbox" name="autoAcceptTaxi" [(ngModel)]="autoAcceptTaxi"> автоподтверждение заказа
    </label>
  </div>
  <div class="checkbox" *ngIf="enabledExternalSearch">
    <label>
      <input type="checkbox" name="autoExternalSearch" [(ngModel)]="autoExternalSearch"> автопоиск такси у партнёров
    </label>
  </div>
  <div class="form-group">
    <button class="save-btn" type="submit">Сохранить</button>
  </div>
</form>
