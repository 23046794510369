import {OrdersReport} from "./orders-report";
import {DraftsReport} from "./drafts-report";
import {ClientNegativeBalanceReport} from "./client-negative-balance-report";
import {ExecutorEarningsReport} from "./executor-earnings-report";
import {ExecutorPositiveBalanceReport} from "./executor-positive-balance-report";
import {AdjustmentsReport} from "./adjustments-report";

export class SummaryReportV2 {
  orders_report = new OrdersReport();
  drafts_report = new DraftsReport();
  client_negative_balance_report = new ClientNegativeBalanceReport();
  executor_earnings_report = new ExecutorEarningsReport();
  executor_positive_balance_report = new ExecutorPositiveBalanceReport();
  adjustments_report = new AdjustmentsReport();

  static applyAdjustments(report: SummaryReportV2): SummaryReportV2 {
    report.orders_report.total += report.adjustments_report.client_adjustments;
    report.executor_earnings_report.executor_earnings_sum += report.adjustments_report.freighter_adjustments;
    report.orders_report.mover_fee_sum += report.adjustments_report.mover_adjustments;

    return report;
  }
}
