import {Injectable} from "@angular/core";
import {Uploader} from "../_upload/uploader";
import {TokenService} from "./token.service";
import {environment} from "../../environments/environment";

@Injectable()
export class UploaderService {
  constructor(private tokenService: TokenService) {
  }

  prepareToUpload(url: string, uploader: Uploader): void {
    let headers = [];
    if(!environment.production)
      headers.push({ name: 'X-Test', value: true });
    if(this.tokenService.token)
      headers.push({ name: 'X-Token', value: this.tokenService.token });

    uploader.setOptions({url, headers});
  }
}
