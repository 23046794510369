import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserStat} from "../_models/user-stat";

@Component({
  selector: 'send-balance-dialog',
  templateUrl: './send-balance-dialog.component.html',
  styleUrls: ['./send-balance-dialog.component.css']
})
export class SendBalanceDialogComponent implements OnInit, OnDestroy {
  @ViewChild('balanceDialog', { static: true }) dialogEl: ElementRef;

  users: UserStat[];

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  private showDialog() {
    $(this.dialogEl.nativeElement).modal('show');

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  private hideDialog() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  showDialogForUsers(users: UserStat[]) {
    this.users = users;

    this.showDialog();
  }

  onCancel() {
    this.hideDialog();
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
