
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderPeriod} from "../_models/order-period";
import {firstValueFrom, Subject} from "rxjs";
import {DestinationPoint} from "../_models/destination-point";
import {Destination} from "../_models/destination";
import {OrderService} from "../_services/order.service";
import {Order} from "../_models/order";
import {HttpResponse} from "@angular/common/http";
import {LoaderService} from "../_services/loader.service";
import {DestinationOptionsPipe} from "../_pipes/destination-options.pipe";
import {DELIVERY_TO} from "../_maps/delivery-to";
import {LegalEntity} from "../_models/legal-entity";
import {UserInfoService} from "../_services/user-info.service";
import {FreighterClientService} from "../_services/freighter-client.service";
import {LegalEntitySelectorDialogComponent} from "../legal-entity-selector-dialog/legal-entity-selector-dialog.component";
import {DeliveryProduct} from "../_models/delivery-product";

@Component({
  selector: 'route-editor',
  templateUrl: './route-editor.component.html',
  styleUrls: ['./route-editor.component.css']
})
export class RouteEditorComponent implements OnInit {
  @Input() order: Order;
  @Input() period: OrderPeriod;
  @Input() legalEntitySelectorDialog: LegalEntitySelectorDialogComponent;
  @Output() onFocusedAddr = new EventEmitter<Subject<DestinationPoint>>();
  @Output() onUpdated = new EventEmitter<OrderPeriod>();
  @Output() onRequestDeliveryProductDialog = new EventEmitter<DeliveryProduct>();

  private removed: Destination[] = [];

  private destinationOptionsPipe: DestinationOptionsPipe;
  private showOptionsEditor = new WeakMap<Destination, boolean>();
  private activeDestination: Destination;

  deliveryVariants = DELIVERY_TO;

  constructor(
    private orderService: OrderService,
    private userInfoService: UserInfoService,
    private freighterClientService: FreighterClientService,
    private loaderService: LoaderService,
  ) {
    this.destinationOptionsPipe = new DestinationOptionsPipe();
  }

  ngOnInit() {
    this.initDeliveryTo();
  }

  private initDeliveryTo(): void {
    for (let destination of this.period.destinations) {
      if(!destination.delivery_to) {
        destination.delivery_to = '';
      }
    }
  }

  getOptionsAsString(destination: Destination, empty: string): any {
    return this.destinationOptionsPipe.transform(destination, empty);
  }

  isVisibleOptionsEditor(destination: Destination): boolean {
    return this.showOptionsEditor.has(destination) && this.showOptionsEditor.get(destination);
  }

  toggleOptionEditorVisibility(destination): void {
    if(!this.showOptionsEditor.has(destination))
      this.showOptionsEditor.set(destination, false);

    this.showOptionsEditor.set(destination, !this.showOptionsEditor.get(destination));
  }

  private move(pos: number, offset: number) {
    let newPos = pos + offset;
    let t = this.period.destinations[newPos];
    this.period.destinations[newPos] = this.period.destinations[pos];
    this.period.destinations[pos] = t;
  }

  private prepareToSave() {
    for(let i = 0; i < this.period.destinations.length; i ++) {
      if(i == 0)
        this.period.destinations[i].type = 'from';
      else
        this.period.destinations[i].type = 'to';
    }
  }

  private deleteRemoved(prevOperations: Promise<HttpResponse<any>>): Promise<HttpResponse<any>> {
    for(let destination of this.removed) {
      // пропускаем удалённые, но несохранённые на сервере
      if(!destination.id)
        continue;

      prevOperations = prevOperations.then(
        () => firstValueFrom(this.orderService.removePeriodDestination(this.order, this.period, destination))
      );
    }

    return prevOperations;
  }

  private save(prevOperations: Promise<HttpResponse<any>>): Promise<HttpResponse<any>> {
    let sort = 0;
    for(let destination of this.period.destinations) {
      if(destination.id) {
        let curSort = sort;
        prevOperations = prevOperations.then(
          () => firstValueFrom(this.orderService.updatePeriodDestination(this.order, this.period, destination, curSort))
        );
      } else {
        let curSort = sort;
        prevOperations = prevOperations.then(
          () => firstValueFrom(this.orderService.addPeriodDestination(this.order, this.period, destination, curSort))
        );
      }

      sort ++;
    }

    return prevOperations;
  }

  onFocusAddr(focusedAddr: Subject<DestinationPoint>) {
    this.onFocusedAddr.emit(focusedAddr);
  }

  onUp(i: number) {
    this.move(i, -1);
  }

  onDown(i: number) {
    this.move(i, 1);
  }

  onDelete(i: number) {
    this.removed.push(this.period.destinations.splice(i, 1)[0]);
  }

  onAdd() {
    let destination = new Destination();
    destination.destination = new DestinationPoint();
    destination.destination.addr = '';

    this.period.destinations.push(destination);
  }

  onSave() {
    this.loaderService.show();

    this.prepareToSave();
    let removeOperations = this.deleteRemoved(Promise.resolve(new HttpResponse()));
    let saveOperations = this.save(removeOperations);

    saveOperations
      .then(() => {
        this.loaderService.hide();
        this.onUpdated.emit(this.period);
      })
      .catch(() => this.loaderService.hide());
  }

  onOpenLegalEntitySelectorFor(destination: Destination): void {
    this.activeDestination = destination;
    this.legalEntitySelectorDialog.showDialog();
  }

  onSelectLegalEntity(entity: LegalEntity): void {
    this.activeDestination.client_legal_entity = entity;

    if(this.userInfoService.isFreighter()) {
      let destination = this.activeDestination;

      this.loaderService.show();
      this.freighterClientService
        .getClientsByLegalEntity(this.userInfoService.getFreighter(), entity).pipe(
        finalize(() => this.loaderService.hide()))
        .subscribe(
          clients => {
            if(clients.length > 0) {
              let client = clients[0];
              destination.contact_phone = client.contact_phone;
              destination.contact_name = client.contact_name;
            }
          }
        )
    }

    this.activeDestination = null;
  }

  onUnselectLegalEntity(destination: Destination): void {
    destination.client_legal_entity = null;
  }

  onShowDeliveryProductDialog(destination: Destination): void {
    this.activeDestination = destination;
    if(!destination.delivery_product) {
      destination.delivery_product = new DeliveryProduct();
      destination.delivery_product.lines = [];
    }
    this.onRequestDeliveryProductDialog.emit(destination.delivery_product);
  }

  onUpdateDeliveryProduct(product: DeliveryProduct): void {
    this.activeDestination.delivery_product = product;
  }
}
