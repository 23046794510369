export class CompanyDetails {
  id?: string;
  name?: string;
  short_name?: string;
  type?: string;
  inn?: string;
  kpp?: string;
  ogrn?: string;
  bik?: string;
  bank?: string;
  bank_inn?: string;
  correspondent_account?: string;
  checking_account?: string;
  legal_address?: string;
  director?: string;
  oktmo?: string;
  okpo?: string;
}
