<div class="col-md-5 col-centered">
  <h1>Тарифы грузчиков</h1>
  <div class="row">
    <div class="col-md-6">
      <a routerLink="/tariffs/loaders/new" class="btn btn-default" type="button"><span
        class="glyphicon glyphicon-plus"></span> Добавить тариф</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <table class="table table-striped">
        <thead>
        <tr>
          <th width="10%">#</th>
          <th>Название</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tariff of tariffs">
          <td>{{ tariff.id }}</td>
          <td>{{ tariff.name }}</td>
          <td width="15%" class="text-nowrap">
            <a [routerLink]="['/tariffs/loaders', tariff.id]" class="btn btn-default btn-xs glyphicon glyphicon-pencil"
               title="Изменить"></a>
            <button type="button" class="btn btn-default btn-xs glyphicon glyphicon-trash" title="Удалить"
                    (click)="onDelete(tariff)"></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
