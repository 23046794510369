import {Injectable} from "@angular/core";
// import {PushNotificationsService} from "angular2-notifications";
import {CarFoundMessage} from '../_websocket/messages/car-found';
import {wsTaxiClientMessage} from '../_models/ws-taxi-client'
import {TaxiConnection} from "../_websocket/connections/taxi-connection";
import {CrewService} from './crew.service';
import {asyncScheduler, Subject} from "rxjs";
import {NotificationEvent} from '../notifications/notification-event';
import {noticeInterface} from '../notifications/notice.interface'
import {taxiNotice} from '../notifications/types/taxi-notice'
import {WebSocketService} from './web-socket.service';
import {OrderService} from './order.service';
import {Order} from "../_models/order";
import {Router} from "@angular/router";
import {UserInfoService} from "./user-info.service";
import {TaxiNewSearch} from "../_websocket/messages/taxi-new-search";
import {OrderDraftService} from "./order-draft.service";
import {OrderDraft} from "../_models/order-draft";
import {SoundNotificationService} from "./sound-notification.service";
import {newTaxiOrder} from "../_websocket/messages/taxi-order";
import {TaxiNotFound} from "../_websocket/messages/taxi-not-found";
import {VoximplantService} from "./voximplant.service";
import {UserService} from "./user.service";
import {UserLastData} from "../_models/users/user-last-data";
import {SearchDuration} from "../_websocket/messages/search-duration";
import {OrdersConnection} from "../_websocket/connections/orders-connection";
import {OrderCrewChanged} from "../_websocket/messages/order-crew-changed";
import {TaxiSearchUpdated} from "../_websocket/messages/taxi-search-updated";
import {SEARCH_TYPES} from "../_maps/search-types";
import {TaxiSearchError} from "../_websocket/messages/taxi-search-error";
import {OrderExternalExecutionStatusChanged} from "../_websocket/messages/order-external-execution-status-changed";
import {OrderExternalStatusChanged} from "../_websocket/messages/order-external-status-changed";
import {NoPoints} from "../_websocket/messages/no-points";
import {concatMap, throttleTime} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {ExternalCancel} from "../_websocket/messages/external-cancel";
import {ArrivalTimeChanged} from "../_websocket/messages/arrival-time-changed";

const NOTIFICATION_STORAGE_NAME = 'notifications';
const NEW_ORDERS_BUCKET_PERIOD = 20 * 1000;
const NEW_ORDERS_BUCKET_BOUND = 10;

@Injectable()
export class NotificationService {
  constructor(
    // private _desktopNotificationService: PushNotificationsService,
    private _crewService: CrewService,
    private _webSocketService: WebSocketService,
    private _orderService: OrderService,
    private draftService: OrderDraftService,
    private userInfoService: UserInfoService,
    private userService: UserService,
    private soundNotificationService: SoundNotificationService,
    private voximplantService: VoximplantService,
    private _router: Router
  ) {
    this.initNewOrderNotificationStream();
    this.initNewOrdersBucketStream();
    this.restoreNotifications();
    voximplantService.getIncomingObservable().subscribe(phone => this.onCall(phone));
    voximplantService.getAnswerObservable().subscribe(phone => this.onCallAnswer(phone));
  }

  private changeListener: Subject<NotificationEvent> = new Subject<NotificationEvent>();
  private taxiConnection: TaxiConnection;
  private ordersConnection: OrdersConnection;
  private notificationsList: noticeInterface[] = [];

  private newOrderNotificationStream: Subject<newTaxiOrder>;
  private newOrdersBucketStream: Subject<newTaxiOrder>;
  private draftOrdersCount = new Map<number, number>();
  private tooLongNotices = new Map<number, noticeInterface>();
  private newSearchNotices = new Map<number, noticeInterface>();
  private notFoundNotices = new Map<number, noticeInterface>();
  private externalStatusesNotices = new Map<number, noticeInterface>();
  private taxiSearchErrorNotices = new Map<number, noticeInterface>();

  activeNotificationsList = false;
  private hasAttentions = false;

  getListener() {
    return this.changeListener;
  }

  getTaxiConnection() {
    return this.taxiConnection;
  }

  getNotificationsList() {
    return this.notificationsList;
  }

  getSoundNotificationService(): SoundNotificationService {
    return this.soundNotificationService;
  }

  initTaxiConnection() {
    this.taxiConnection = this._webSocketService.createTaxiConnection();
    this.taxiConnection.start();
    this.taxiConnection.message.subscribe(message => {
      this.handleTaxiMessage(message);
    });
  }

  initOrdersConnection() {
    this.ordersConnection = this._webSocketService.createOrdersConnection();
    this.ordersConnection.start();
    this.ordersConnection.message.subscribe(message => {
      this.handleOrdersMessage(message);
    });
  }

  private initNewOrdersBucketStream(): void {
    this.newOrdersBucketStream = new Subject<newTaxiOrder>();
    this.newOrdersBucketStream.pipe(
      throttleTime(NEW_ORDERS_BUCKET_PERIOD, asyncScheduler, {
        leading: false,
        trailing: true
      })
    ).subscribe(m => this.createNewOrdersBucketNotification(m.draftId, this.draftOrdersCount.get(m.draftId)))
  }

  private initNewOrderNotificationStream(): void {
    this.newOrderNotificationStream = new Subject<newTaxiOrder>();
    this.newOrderNotificationStream.pipe(
      concatMap(m => this._orderService.getOrderForSupport(m.orderId, 'notification'))
    ).subscribe(o => this.createNewOrderNotification(o), () => this.initNewOrderNotificationStream())
    ;
  }

  closeTaxiConnection() {
    this.taxiConnection.close();
  }

  set(notice: noticeInterface, to: boolean) {
    this.changeListener.next({command: 'set', notice: notice, add: to});
    return notice
  }

  changeOrderStatus(orderId: number, freighterId: number, status: string, notice: noticeInterface) {
    this.changeListener.next({
      command: 'changeOrderStatus',
      orderId: orderId,
      freighterId: freighterId,
      status: status,
      notice: notice
    })
  }

  createTaxiNotification(message, order: Order) {
    this.soundNotificationService.playSimpleNotifySound();

    let lastPeriod = order.periods[order.periods.length - 1];
    let employeeData = lastPeriod.employer.account;
    let taxi = new taxiNotice();
    taxi.title = 'Водитель принял заказ';
    taxi.addOrder(order);
    taxi.dateCreated = new Date();
    taxi.addAcceptButton('Подтвердить');
    taxi.addButton('К заказу', 'router', ["/orders", message.freighter, order.id]);
    return this.set(taxi, true)
  }

  createChangeOrderCrewNotification(message, order: Order) {
    this.soundNotificationService.playSimpleNotifySound();

    let taxi = new taxiNotice();
    taxi.title = `В заказе №${order.id} изменился экипаж`;
    taxi.addOrder(order);
    taxi.dateCreated = new Date();
    taxi.addButton('К заказу', 'router', ["/orders", message.freighterId, order.id]);
    taxi.setAlertClass();
    return this.set(taxi, true)
  }

  createNoPointsNotification(message, order: Order) {
    this.soundNotificationService.playAttentionSound();

    let taxi = new taxiNotice();
    taxi.title = `В заказе №${order.id} нет точек`;
    taxi.bodyText = 'От водителя не поступают данные о местоположении.';
    taxi.addOrder(order);
    taxi.dateCreated = new Date();
    taxi.addButton('К заказу', 'router', ["/orders", message.freighterId, order.id]);
    taxi.setAttentionClass();
    return this.set(taxi, true)
  }

  createExternalCancelNotification(message, order: Order) {
    this.soundNotificationService.playAttentionSound();

    let taxi = new taxiNotice();
    taxi.title = `Заказ №${order.id} был отменён`;
    taxi.bodyText = 'Заказ был отменён перевозчиком';
    taxi.addOrder(order);
    taxi.dateCreated = new Date();
    taxi.addButton('К заказу', 'router', ["/orders", message.freighterId, order.id]);
    taxi.setAttentionClass();
    return this.set(taxi, true)
  }

  createChangeArrivalTimeNotification(message: ArrivalTimeChanged, order: Order) {
    this.soundNotificationService.playSimpleNotifySound();

    let taxi = new taxiNotice();
    taxi.title = `В заказе №${order.id} изменилось время подачи`;
    taxi.bodyText = message.text;
    taxi.addOrder(order);
    taxi.dateCreated = new Date();
    taxi.addButton('К заказу', 'router', ["/orders", message.freighterId, order.id]);
    taxi.setAlertClass();
    return this.set(taxi, true)
  }

  createChangeOrderExternalStatusNotification(message: OrderExternalStatusChanged|OrderExternalExecutionStatusChanged, order: Order) {
    this.soundNotificationService.playSimpleNotifySound();

    let oldNotice = this.externalStatusesNotices.get(order.id);
    if(oldNotice) {
      oldNotice.autoHide = true;
      this.externalStatusesNotices.delete(order.id);
    }

    let taxi = new taxiNotice();
    taxi.title = `Партнёр изменил статус в заказе №${order.id}`;
    taxi.bodyText = message.text;
    taxi.addOrder(order);
    taxi.dateCreated = new Date();
    taxi.addButton('К заказу', 'router', ["/orders", order.freighter.id, order.id]);
    taxi.setAlertClass();
    taxi.bodyClass = 'external-status';

    this.externalStatusesNotices.set(order.id, taxi);
    this.cleanupNotices(this.externalStatusesNotices);

    return this.set(taxi, true)
  }

  createTaxiNewSearchNotification(draft: OrderDraft) {
    this.soundNotificationService.playNewSearchSound();

    let oldNotice = this.newSearchNotices.get(draft.id);
    if(oldNotice) {
      oldNotice.autoHide = true;
      this.newSearchNotices.delete(draft.id);
    }

    let taxi = new taxiNotice();
    taxi.title = `Поиск по заявке №${draft.id}`;
    taxi.addDraft(draft);
    taxi.dateCreated = new Date();
    taxi.addButton('К заявке', 'router', ["/drafts", draft.id]);
    taxi.setAlertClass();

    this.newSearchNotices.set(draft.id, taxi);
    this.cleanupNotices(this.newSearchNotices);

    return this.set(taxi, true)
  }

  createTaxiNotFoundNotification(draft: OrderDraft) {
    this.soundNotificationService.playSimpleNotifySound();

    let oldNotice = this.notFoundNotices.get(draft.id);
    if(oldNotice) {
      oldNotice.autoHide = true;
      this.notFoundNotices.delete(draft.id);
    }

    let taxi = new taxiNotice();
    taxi.title = `Водитель не найден по заявке №${draft.id}`;
    taxi.addDraft(draft);
    taxi.dateCreated = new Date();
    taxi.addButton('К заявке', 'router', ["/drafts", draft.id]);
    taxi.setAlertClass();

    this.notFoundNotices.set(draft.id, taxi);
    this.cleanupNotices(this.notFoundNotices);

    return this.set(taxi, true)
  }

  createTaxiSearchErrorNotification(draft: OrderDraft) {
    this.soundNotificationService.playSimpleNotifySound();

    let oldNotice = this.taxiSearchErrorNotices.get(draft.id);
    if(oldNotice) {
      oldNotice.autoHide = true;
      this.notFoundNotices.delete(draft.id);
    }

    let taxi = new taxiNotice();
    taxi.title = `При поиске по заявке №${draft.id} произошла ошибка`;
    taxi.addDraft(draft);
    taxi.dateCreated = new Date();
    taxi.addButton('К заявке', 'router', ["/drafts", draft.id]);
    taxi.setAlertClass();

    this.taxiSearchErrorNotices.set(draft.id, taxi);
    this.cleanupNotices(this.taxiSearchErrorNotices);

    return this.set(taxi, true)
  }

  createTaxiSearchUpdatedNotification(draft: OrderDraft, startedSearches: string[], stoppedSearches: string[]) {
    this.soundNotificationService.playSimpleNotifySound();

    let searchMapper = search => {
      return SEARCH_TYPES[search] || search;
    };

    let body: string[] = [];
    if(startedSearches.length > 0)
      body.push('Запущен поиск: ' + startedSearches.map(searchMapper).join(', '));

    if(stoppedSearches.length > 0)
      body.push('Остановлен поиск: ' + stoppedSearches.map(searchMapper).join(', '));

    let taxi = new taxiNotice();
    taxi.title = `Обновлён поиск по заявке №${draft.id}`;
    taxi.bodyText = body.join('<br>');
    taxi.addDraft(draft);
    taxi.dateCreated = new Date();
    taxi.autoHide = true;
    taxi.important = true;
    taxi.addButton('К заявке', 'router', ["/drafts", draft.id]);
    taxi.setInfoClass();

    return this.set(taxi, true)
  }

  createTooLongSearchNotification(draft: OrderDraft, duration: number, restTime: number, message: string) {
    if(restTime < 0)
      this.soundNotificationService.playAttentionSound();
    else
      this.soundNotificationService.playSimpleNotifySound();

    let oldNotice = this.tooLongNotices.get(draft.id);
    if(oldNotice) {
      oldNotice.autoHide = true;
      this.tooLongNotices.delete(draft.id);
    }

    let taxi = new taxiNotice();
    taxi.title = `Поиск по заявке №${draft.id} идёт слишком долго`;
    taxi.bodyText = message;
    taxi.addDraft(draft);
    taxi.dateCreated = new Date();
    taxi.addButton('К заявке', 'router', ["/drafts", draft.id]);
    taxi.setAlertClass();
    if(restTime < 0)
      taxi.setAttentionClass();

    this.tooLongNotices.set(draft.id, taxi);
    this.cleanupNotices(this.tooLongNotices);

    return this.set(taxi, true)
  }

  createNewOrderNotification(order: Order) {
    this.soundNotificationService.playNewOrderSound();

    let taxi = new taxiNotice();
    taxi.title = `Создан заказ №${order.id}`;
    taxi.addOrder(order);
    taxi.dateCreated = new Date();
    taxi.addAcceptButton('Подтвердить');
    taxi.addButton('К заказу', 'router', ["/orders", order.freighter.id, order.id]);
    taxi.autoHide = true;
    taxi.setInfoClass();
    return this.set(taxi, true)
  }

  createNewOrdersBucketNotification(draftId: number, count: number) {
    if(this.userInfoService.isPrivilegedUser())
      this.soundNotificationService.playNewOrderSound();

    let taxi = new taxiNotice();
    taxi.title = `Поиск по заявке №${draftId}`;
    taxi.bodyText = `Найдено ${count} перевозчиков`;
    taxi.dateCreated = new Date();
    taxi.addButton('К заявке', 'router', ["/drafts", draftId]);
    taxi.autoHide = true;
    taxi.setInfoClass();
    return this.set(taxi, true)
  }

  createPhoneNotification(phone: string, userLastData?: UserLastData) {
    let notice = new taxiNotice();
    notice.title = "Вам звонили";
    notice.phone = phone;
    if(userLastData) {
      notice.account = userLastData.account;
    }
    notice.dateCreated = new Date();
    notice.setAlertClass();
    notice.addCallButton("Позвонить", phone);
    return this.set(notice, true)
  }

  private cleanupNotices(notices: Map<number, noticeInterface>): void {
    let toRemove: number[] = [];
    notices.forEach((notice, id) => {
      if(notice.remove)
        toRemove.push(id);
    });
    for (const id of toRemove) {
      notices.delete(id);
    }
  }

  showMockTaxiNotification(order:Order)
  {
    this.createTaxiNotification('Test Notification',order);
  }

  requestDesktopNotificationPermission() {
    // TODO: restore
    // this._desktopNotificationService.requestPermission();
  }

  showTaxiNotification(message) {
    let messageData = message.data.data;
    this._orderService.getOrder(messageData.freighter, messageData.order)
      .subscribe(order => {
          this.createTaxiNotification(messageData, order);
        }
      );
  }

  showNewSearchNotification(message: TaxiNewSearch): void {
    this.draftOrdersCount.set(message.draftId, 0);
    this.draftService.getDraft(message.draftId)
      .subscribe(draft => {
        this.createTaxiNewSearchNotification(draft);
      })
    ;
  }

  showNewOrderNotification(message: newTaxiOrder): void {
    let ordersCount = this.draftOrdersCount.has(message.draftId) ? this.draftOrdersCount.get(message.draftId) : 0;
    ordersCount ++;
    this.draftOrdersCount.set(message.draftId, ordersCount);

    if(ordersCount > NEW_ORDERS_BUCKET_BOUND)
      this.newOrdersBucketStream.next(message);
    else if(this.userInfoService.isPrivilegedUser())
      this.soundNotificationService.playNewOrderSound();
      // this.newOrderNotificationStream.next(message);
  }

  showDesktopNotification(title: string, body: string) {
    // this._desktopNotificationService.create(title, {body: body}).subscribe(
    //   res => {
    //     console.log(res);
    //   },
    //   err => console.log(err)
    // );
  }

  showTaxiNotFoundNotification(message: TaxiNotFound): void {
    this.draftService.getDraft(message.draftId)
      .subscribe(draft => this.createTaxiNotFoundNotification(draft))
    ;
  }

  showTaxiSearchErrorNotification(message: TaxiNotFound): void {
    this.draftService.getDraft(message.draftId)
      .subscribe(draft => this.createTaxiSearchErrorNotification(draft))
    ;
  }

  showTaxiSearchUpdateNotification(message: TaxiSearchUpdated): void {
    this.draftService.getDraft(message.draftId)
      .subscribe(draft => this.createTaxiSearchUpdatedNotification(draft, message.started, message.stopped))
    ;
  }

  showTooLongSearchNotification(message: SearchDuration): void {
    this.draftService.getDraft(message.getDraftId())
      .subscribe(draft => {
        this.createTooLongSearchNotification(draft, message.duration, message.restTime, message.message);
      })
    ;
  }

  showCallNotification(phone: string): void {
    this.userService.getUserLastDataByPhone(phone)
      .subscribe(
        data => this.createPhoneNotification(phone, data),
        r => {
          if(r instanceof HttpErrorResponse && r.status === 404) {
            this.createPhoneNotification(phone, );
          } else {
            throw r;
          }
        }

      )
  }

  showOrderCrewChangedNotification(message: OrderCrewChanged): void {
    this._orderService
      .getOrder(message.freighterId, message.orderId)
      .subscribe(o => this.createChangeOrderCrewNotification(message, o))
    ;
  }

  showOrderExternalStatusChangedNotification(message: OrderExternalStatusChanged|OrderExternalExecutionStatusChanged): void {
    this._orderService
      .getOrder(message.freighterId, message.orderId)
      .subscribe(o => this.createChangeOrderExternalStatusNotification(message, o))
    ;
  }

  showNoPointsNotification(message: NoPoints): void {
    this._orderService
      .getOrder(message.freighterId, message.orderId)
      .subscribe(o => this.createNoPointsNotification(message, o))
    ;
  }

  showExternalCancelNotification(message: ExternalCancel): void {
    this._orderService
      .getOrder(message.freighterId, message.orderId)
      .subscribe(o => this.createExternalCancelNotification(message, o))
    ;
  }

  showChangeArrivalTimeChangedNotification(message: ArrivalTimeChanged): void {
    this._orderService
      .getOrder(message.freighterId, message.orderId)
      .subscribe(o => this.createChangeArrivalTimeNotification(message, o))
    ;
  }

  private handleTaxiMessage(message) {
    if (message instanceof CarFoundMessage) {
      this.showTaxiNotification(message);
    } else if(message instanceof TaxiNotFound) {
      this.showTaxiNotFoundNotification(message);
    } else if(message instanceof TaxiSearchUpdated) {
      this.showTaxiSearchUpdateNotification(message);
    } else if(message instanceof TaxiSearchError) {
      this.showTaxiSearchErrorNotification(message);
    } else if(message instanceof newTaxiOrder) {
      this.showNewOrderNotification(message);
    }

    if(this.userInfoService.isPrivilegedUser()) {
      if (message instanceof TaxiNewSearch) {
        this.showNewSearchNotification(message);
      } else if(message instanceof SearchDuration) {
        this.showTooLongSearchNotification(message);
      }
    }
  }

  private handleOrdersMessage(message): void {
    if(message instanceof OrderCrewChanged) {
      this.showOrderCrewChangedNotification(message);
    } else if(message instanceof OrderExternalStatusChanged || message instanceof OrderExternalExecutionStatusChanged) {
      this.showOrderExternalStatusChangedNotification(message);
    } else if(message instanceof NoPoints) {
      this.showNoPointsNotification(message);
    } else if(message instanceof ExternalCancel) {
      this.showExternalCancelNotification(message);
    } else if(message instanceof ArrivalTimeChanged) {
      this.showChangeArrivalTimeChangedNotification(message);
    }
  }

  private removeNoticesByPhone(phone: string): void {
    console.log(`remove notices by phone ${phone}`);
    for(let notice of this.notificationsList) {
      if(notice.phone === phone)
        notice.autoHide = true;
    }
  }

  saveNotifications(): void {
    let notifications: noticeInterface[] = this.notificationsList.filter(notice => notice.important);

    sessionStorage.setItem(NOTIFICATION_STORAGE_NAME, JSON.stringify(notifications));
  }

  private restoreNotifications(): void {
    let data = sessionStorage.getItem(NOTIFICATION_STORAGE_NAME);
    if(data !== null) {
      this.notificationsList = JSON.parse(data);
      this.refreshAttentions();
    }
  }

  refreshAttentions(): void {
    for(let notice of this.notificationsList) {
      if(notice.noticeClass == 'attention') {
        this.hasAttentions = true;
        return;
      }
    }
    this.hasAttentions = false;
  }

  getNotificationsCount(): number {
    return this.notificationsList.length;
  }

  existAttentions(): boolean {
    return this.hasAttentions;
  }

  onCall(phone: string) {
    this.showCallNotification(phone);
  }

  onCallAnswer(phone: string) {
    this.removeNoticesByPhone(phone);
  }
}
