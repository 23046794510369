import {Crew} from "./crew";
import {List} from "../pager/list";
import {CrewsListFilter} from "./crews-list-filter";

export class CrewsList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public filter: CrewsListFilter, public crews: Crew[]) {
  }

  static empty(): CrewsList {
    return new CrewsList(0, 0, 0, new CrewsListFilter, []);
  }

  concat(array) {
    this.crews = array.concat(this.crews);
    return this;
  }

  hasMore(): boolean {
    if(this.crews.length < this.pageSize)
      return false;

    let lastEmployeeNumOnPage = this.page * this.pageSize + this.pageSize - 1;
    return lastEmployeeNumOnPage < this.totalCount - 1;
  }
}
