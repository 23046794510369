import {List} from "../pager/list";
import {Transport} from "./transport";
import {Crew} from "./crew";
import {TransportsFilter} from "./transports-filter";

export class TransportsList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public filter: TransportsFilter, public transports: Transport[], public transportCrews: Crew[]) {
  }

  static empty(): TransportsList {
    return new TransportsList(0, 0, 0, new TransportsFilter(), [], []);
  }

  concat(list: TransportsList): TransportsList {
    this.transports = list.transports.concat(this.transports);
    this.transportCrews = list.transportCrews.concat(this.transportCrews);
    return this;
  }

  hasMore(): boolean {
    if(this.transports.length < this.pageSize)
      return false;

    let lastTransportNumOnPage = this.page * this.pageSize + this.pageSize - 1;
    return lastTransportNumOnPage < this.totalCount - 1;
  }

}
