import {Component, Input, OnInit} from '@angular/core';
import {DeliveryProduct} from "../_models/delivery-product";
import {Order} from "../_models/order";
import {OrderPeriod} from "../_models/order-period";
import {Destination} from "../_models/destination";

@Component({
  selector: 'delivery-product-wrapper',
  templateUrl: './delivery-product-wrapper.component.html',
  styleUrls: ['./delivery-product-wrapper.component.css']
})
export class DeliveryProductWrapperComponent implements OnInit {
  @Input() order: Order;
  @Input() period: OrderPeriod;
  @Input() destination: Destination;
  @Input() product: DeliveryProduct;

  visible = false;

  constructor() { }

  ngOnInit() {
    this.restoreVisibleState();
  }

  private saveVisibleState() {
    if(this.order == null)
      return;

    sessionStorage.setItem(this.generateVisibleStateKey(), this.visible ? '1' : '0');
  }

  private restoreVisibleState() {
    if(this.order == null)
      return;

    this.visible = sessionStorage.getItem(this.generateVisibleStateKey()) === '1';
  }

  onClickMore() {
    this.visible = !this.visible;

    this.saveVisibleState();

    return false;
  }

  private generateVisibleStateKey() {
    return `product_visible_${this.order.id}_${this.period.id}_${this.product.id}`;
  }
}
