
import {of as observableOf, Subject} from 'rxjs';

import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UsersFilter} from "../_models/users/users-filter";
import {UsersList} from "../_models/users/users-list";
import {UserService} from "../_services/user.service";
import {LoaderService} from "../_services/loader.service";
import {State} from "../_storage/state";
import {ObjectComparator} from "../_utils/ObjComparator";
import {Page} from "../pager/page";
import {UserInfoService} from "../_services/user-info.service";
import {UserStat} from "../_models/user-stat";
import {IntervalFilter} from "../_models/users/interval-filter";
import {UserListAction} from "./user-list-action";
import {BankCard} from "../_models/bank-card";
import {BankCardUtils} from "../_utils/bank-card-utils";
import {VoximplantService} from "../_services/voximplant.service";
import {RelativeTimeFilter} from "../_models/users/relative-time-filter";
import {TIME_UNITS} from "../_maps/time-units";
import {UserDetailDialogComponent} from "../user-detail-dialog/user-detail-dialog.component";
import {TariffTier} from "../_models/tariff-tier";
import {TariffService} from "../_services/tariff.service";
import {Employer} from "../_models/employer";
import {EmployeeService} from "../_services/employer.service";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {FilterType, FilterTypeUtils} from "../_utils/online-filter/filter-type";
import {OnlineFilterItem} from "../_utils/online-filter/online-filter-item";
import {OnlineFilterUtils} from "../_utils/online-filter/online-filter-utils";
import {DateTime} from "date-time-js";
import {Account} from "../_models/account";
import {BankCardService} from "../_services/bank-card.service";

declare var moment: any;

const PAGE_STORAGE = "users_list_page";
const FILTER_STORAGE = "users_list_filter";
const FREIGHTER_ROLES = [ 'ROLE_DRIVER', 'ROLE_DRIVER_MANAGER', 'ROLE_COMPANY' ];
const DRIVER_ROLES = [ 'ROLE_DRIVER', 'ROLE_DRIVER_MANAGER' ];

class ListCommand {
  constructor(
    private _filter?: UsersFilter,
    private _page?: number,
    private _source?: string,
    private _force?: boolean
  ) {}

  get filter(): UsersFilter {
    return this._filter;
  }

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  get force(): boolean {
    return this._force;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  @Input() actions: UserListAction[];
  @ViewChild(UserDetailDialogComponent, { static: true }) userDetailDialog: UserDetailDialogComponent;

  actionsForFiltered: UserListAction[] = [];
  actionsForSelected: UserListAction[] = [];
  hasActions = false;

  users: UsersList = UsersList.empty();

  private listCommands = new Subject<ListCommand>();
  private oldFilter = new UsersFilter();
  selectAll: boolean = false;

  userSelects = new Map<number, boolean>();
  selectedUsers: number = 0;
  hasSelection: boolean = false;
  showMore: boolean;

  taxiTiers: TariffTier[] = [];
  onlineFilters: OnlineFilterItem[] = OnlineFilterUtils.getFilterItems();

  accountType: string;
  email: string;
  phone: string;
  surname: string;
  active: string;
  balance: IntervalFilter;
  balanceFilterFolded = true;
  deviceType: string;
  ordersCount: IntervalFilter;
  ordersCountFilterFolded = true;
  company: string;
  lastOrderDate: RelativeTimeFilter;
  employerStatus: string;
  taxiTariff: string = "0";
  online: string = this.serializeOnlineFilter(FilterType.NONE);
  registeredAfter: Date;
  registeredBefore: Date;
  registeredFilterFolded = true;
  isEnabledAddFakeCard = false;

  registeredAfterDatePickerOptions = {
    label: 'с'
  };

  registeredBeforeDatePickerOptions = {
    label: 'по'
  };

  constructor(
    private userService: UserService,
    private userInfoService: UserInfoService,
    private voximplantService: VoximplantService,
    private tariffService: TariffService,
    private employeeService: EmployeeService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private bankCardService: BankCardService
  ) { }

  ngOnInit() {
    this.initActions();
    this.restoreFilter();
    this.restorePage();
    this.initUsers();
    this.initTiers();
    this.initControls();
    this.loadUsers(this.users.page, this.users.filter);
  }

  private initControls(): void {
    this.isEnabledAddFakeCard = this.userInfoService.isSuperUser();
  }

  private initActions(): void {
    let actions = this.actions || [];
    this.actionsForFiltered = [];
    this.actionsForSelected = [];
    this.hasActions = false;

    if(this.users.totalCount == 0)
      return;

    for(let action of actions) {
      if(action.callbackForFiltered)
        this.actionsForFiltered.push(action);
      if(this.hasSelection && action.callbackForSelected)
        this.actionsForSelected.push(action);
    }

    this.hasActions = this.actionsForFiltered.length > 0 || this.actionsForSelected.length > 0;
  }

  private initUsers() {
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        return this.userService
          .getUsers(command.page, command.filter).pipe(
          map(list => [list, command])).pipe(
          finalize(() => this.loaderService.hide()));
      }),
      catchError(e => {
        console.log(e);
        return observableOf<UsersList>(UsersList.empty());
      }),)
      .subscribe(listAndCommand => {
        let list: UsersList = listAndCommand[0];
        let command: ListCommand = listAndCommand[1];

        if (
          command.source == "button" &&
          ObjectComparator.Compare(this.oldFilter, list.filter)
        ) {
          this.users = list.concat(this.users.users);
        } else {
          this.resetSelects();
          this.users = list;
        }

        this.initSelects();
        this.initMoreButton();
        this.saveFilter();
        this.savePage();
      });
  }

  private initTiers(): void {
    this.tariffService
      .getTaxiTiers(false)
      .subscribe(
        tiers => this.taxiTiers = tiers,
        () => {}
      )
    ;
  }

  private getSelectedUsersId(): number[] {
    let selected: number[] = [];
    this.userSelects.forEach((sel, id) => {
      if(sel)
        selected.push(id);
    });

    return selected;
  }

  private getSelectedUsers(): UserStat[] {
    let selectedIds = this.getSelectedUsersId();
    return this.users.users.filter(u => selectedIds.indexOf(u.account.id) >= 0);
  }

  private loadUsers(page?: number, filter?: UsersFilter, source?: string, force?: boolean) {
    this.listCommands.next(new ListCommand(filter?.clone() || new UsersFilter(), page, source, force));
  }

  private reloadUsers(): void {
    this.loadUsers(this.users.page, this.users.filter, null, true);
  }

  private initMoreButton(): void {
    this.showMore = this.users.hasMore();
  }

  private resetSelects() {
    this.userSelects.clear();
  }

  private initSelects() {
    for(let user of this.users.users) {
      if(!this.userSelects.has(user.account.id) || this.selectAll)
        this.userSelects.set(user.account.id, this.selectAll);
    }
    this.syncSelected();
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.users.page.toString());
  }

  private restorePage() {
    this.users.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  private saveFilter() {
    sessionStorage.setItem(FILTER_STORAGE, this.users.filter.getState().state);

    this.oldFilter = this.users.filter.clone();
  }

  private restoreFilter() {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if(state)
      this.users.filter = UsersFilter.fromState(new State(state));

    this.applyFilter(this.users.filter);

    this.oldFilter = this.users.filter;
  }

  private applyFilter(filter: UsersFilter): void {
    this.accountType = filter.accountType;
    this.email = filter.email;
    this.phone = filter.phone;
    this.surname = filter.surname;
    this.active = filter.active;
    this.balance = filter.balance && filter.balance.clone() || new IntervalFilter();
    this.deviceType = filter.deviceType;
    this.ordersCount = filter.ordersCount && filter.ordersCount.clone() || new IntervalFilter();
    this.company = filter.company;
    this.lastOrderDate = filter.lastOrderDate;
    this.employerStatus = filter.employerStatus;
    this.taxiTariff = filter.taxiTariff == null ? "0" : filter.taxiTariff.toString();
    this.online = FilterTypeUtils.serialize(filter.online);
    this.registeredAfter = filter.registeredAfter ? new Date(filter.registeredAfter.getTime()) : null;
    this.registeredBefore = filter.registeredBefore ? new Date(filter.registeredBefore.getTime()) : null;
  }

  private syncSelected() {
    this.selectedUsers = 0;
    this.userSelects.forEach(selected => this.selectedUsers += selected ? 1 : 0);

    this.hasSelection = this.selectAll || this.selectedUsers > 0;

    this.initActions();
  }

  private showUserDialog(user: UserStat) {
    this.userDetailDialog.showFor(user);
  }

  private switchEmployerStatus(employer: Employer): void {
    this.loaderService.show();
    this.employeeService
      .updateStatus(employer, employer.status === 'rest' ? 'free' : 'rest').pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.reloadUsers(),
        r => {
          if(r instanceof HttpErrorResponse) {
            if(r.status === 405) {
              this.alertService.warning("Невозможно поменять статус водителя (он на заказе?)");
              this.reloadUsers();
            } else if(r.status === 405) {
              this.alertService.warning("Невозможно поменять статус водителя (у него задолженность)");
              this.reloadUsers();
            }
          } else {
            throw r;
          }
        }
      )
  }

  private cleanFilter(): void {
    this.applyFilter(new UsersFilter());
    this.onChangeFilter();
  }

  serializeOnlineFilter(type: FilterType): string {
    return FilterTypeUtils.serialize(type);
  }

  isCardExpired(card: BankCard): boolean {
    return BankCardUtils.isCardExpired(card);
  }

  getOrdersCountFilterInfo(): string {
    let components = this.buildComponentsOfIntervalFilterInfo(this.ordersCount);

    if(!this.lastOrderDate.isEmpty()) {
      components.push('за ' + moment.duration(this.lastOrderDate.value, this.lastOrderDate.unit).humanize({
        M: 12, d: 31
      }));
    }

    return components.join(' ');
  }

  getBalanceFilterInfo(): string {
    return this.buildComponentsOfIntervalFilterInfo(this.balance).join(' ');
  }

  getRegisteredFilterInfo(): string {
    let components: string[] = [];

    if(this.registeredAfter)
      components.push('с', new DateTime(this.registeredAfter).format('dd.MM.yy'))

    if(this.registeredBefore)
      components.push('по', new DateTime(this.registeredBefore).format('dd.MM.yy'))

    return components.join(' ');
  }

  isFreighter(user: UserStat): boolean {
    return FREIGHTER_ROLES.indexOf(user.role) != -1;
  }

  isDriver(user: UserStat): boolean {
    return DRIVER_ROLES.indexOf(user.role) != -1;
  }

  private buildComponentsOfIntervalFilterInfo(filter: IntervalFilter): string[] {
    let components: string[] = [];
    if(filter.from != null)
      components.push(`от ${filter.from}`);
    if(filter.to != null)
      components.push(`до ${filter.to}`);
    if(filter.including)
      components.push('включительно')

    return components;
  }

  private addFakeBankCard(account: Account): void {
    this.loaderService.show();
    this.bankCardService
      .addFakeCard(account).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.reloadUsers(),
        () => {}
      )
  }

  onPaginationPage(page: Page) {
    this.loadUsers(page.num, this.users.filter, "pager");
  }

  onShowMorePage(page: Page) {
    this.loadUsers(page.num, this.users.filter, "button");
  }

  onClickSelectAll(): void {
    this.selectAll = !this.selectAll;

    this.userSelects.forEach((isSelected, id) => this.userSelects.set(id, this.selectAll));
    this.syncSelected();

    console.log(this.userSelects);
  }

  onSelectUser(user: UserStat): void {
    this.userSelects.set(user.account.id, !this.userSelects.get(user.account.id));

    this.selectAll = false;
    this.syncSelected();
  }

  onChangeFilter() {
    console.log(this.online);

    let filter = new UsersFilter(
      this.accountType,
      this.email,
      this.phone,
      this.surname,
      this.active,
      this.balance.clone(),
      this.deviceType,
      this.ordersCount.clone(),
      this.company,
      this.lastOrderDate.clone(),
      this.employerStatus,
      this.registeredAfter ? new Date(this.registeredAfter.getTime()) : null,
      this.registeredBefore ? new Date(this.registeredBefore.getTime()) : null,
      this.taxiTariff === "0" ? undefined : parseInt(this.taxiTariff),
      FilterTypeUtils.deserialize(this.online)
    );

    console.log(this.ordersCount, this.oldFilter.ordersCount);

    if(!filter.equals(this.oldFilter)) {
      console.log('filter changed');
      this.listCommands.next(new ListCommand(filter));
    }
  }

  onAddLastOrderPeriod() {
    this.lastOrderDate = new RelativeTimeFilter(1, TIME_UNITS.MONTHS);
    this.onChangeFilter();
  }

  onRemoveLastOrderPeriod() {
    this.lastOrderDate = new RelativeTimeFilter();
    this.onChangeFilter();
  }

  onCall(user: UserStat) {
    if(confirm(`Вы уверены, что хотите позвонить по номеру ${user.account.phone}?`))
      this.voximplantService.call(user.account.phone);
  }

  onShowUserDetail(user: UserStat): void {
    this.showUserDialog(user);
  }

  onSwitchEmployerStatus(employer: Employer): void {
    if(confirm("Подтверждаете смену статуса водителя?"))
      this.switchEmployerStatus(employer);
  }

  onCleanFilter(): void {
    this.cleanFilter();
  }

  onAddFakeCard(account: Account): void {
    if(confirm('Добавить пустую карту?'))
      this.addFakeBankCard(account);
  }
}
