import {catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap} from 'rxjs/operators';

import {Subject, throwError as observableThrowError} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {FreighterClientsList} from "../_models/freighter-clients-list";
import {LoaderService} from "../_services/loader.service";
import {FreighterClientService} from "../_services/freighter-client.service";
import {UserInfoService} from "../_services/user-info.service";
import {Page} from "../pager/page";
import {FreighterClient} from "../_models/freighter-client";
import {AlertService} from "../_services/alert.service";

const PAGE_STORAGE = "freighter_clients_list_page";
const PAGE_SIZE = 20;

class ListCommand {
  constructor(
    private _page?: number,
    private _source?: string,
    private _force?: boolean
  ) {}

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  get force(): boolean {
    return this._force;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page;
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'app-freighter-clients',
  templateUrl: './freighter-clients.component.html',
  styleUrls: ['./freighter-clients.component.css']
})
export class FreighterClientsComponent implements OnInit {
  transports: FreighterClientsList = FreighterClientsList.empty();
  private listCommands: Subject<ListCommand>;
  showMore: boolean;

  clients = FreighterClientsList.empty();

  constructor(
    private freighterClientService: FreighterClientService,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.initClients();
    this.restorePage();
    this.loadClients();
  }

  private initClients(): void {
    this.listCommands = new Subject<ListCommand>();
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        return this.freighterClientService
          .getClients(this.userInfoService.getFreighter(), command.page, PAGE_SIZE).pipe(
          map(list => [ list, command ])).pipe(
          finalize(() => this.loaderService.hide()))
          ;
      }),
      catchError(e => {
        console.log(e);
        this.initClients();
        return observableThrowError(e);
      }),)
      .subscribe(listAndCommand => {
        let list = listAndCommand[0] as FreighterClientsList;
        let command = listAndCommand[1] as ListCommand;

        if(command.source === 'button') {
          this.clients = list.concat(this.clients)
        } else {
          this.clients = list;
        }

        this.initMoreButton();
        this.savePage();
      });
  }

  private deleteClient(client: FreighterClient): void {
    this.loaderService.show();

    this.freighterClientService
      .removeClient(this.userInfoService.getFreighter(), client).pipe(
      finalize(() => {
        this.loaderService.hide();
      }))
      .subscribe(
        () => {
          this.alertService.success(`Клиент "${client.name}" удалён`, true);
          this.reloadClients();
        },
        () => {}
      );
  }

  private loadClients(page?: number, source?: string, force?: boolean) {
    this.listCommands.next(new ListCommand(page, source, force));
  }

  private reloadClients(): void {
    this.loadClients(this.clients.page, null, true);
  }

  private initMoreButton(): void {
    this.showMore = this.transports.hasMore();
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.clients.page.toString());
  }

  private restorePage() {
    this.clients.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  onShowMorePage(page: Page) {
    this.loadClients(page.num, "button");
  }

  onPaginationPage(page: Page) {
    this.loadClients(page.num, "pager");
  }

  onDeleteClient(client: FreighterClient): void
  {
    if(confirm(`Вы уверены, что хотите удалить компанию "${client.name}"?\nДанное действие необратимо!`))
      this.deleteClient(client);
  }
}
