<div class="client-form-container">
  <h1 *ngIf="client.isNew">Новый клиент</h1>
  <h1 *ngIf="!client.isNew">{{ client.record.name }}</h1>
  <form (submit)="onSubmit()">
    <div class="form-group">
      <label for="clientName">Наименование клиента</label>
      <input type="text" class="form-control" id="clientName" [(ngModel)]="client.record.name" name="name" required>
      <input-error [formErrors]="client.errors" field="name"></input-error>
    </div>
    <div class="form-group">
      <label for="contactName">Контактное лицо</label>
      <input type="text" class="form-control" id="contactName" [(ngModel)]="client.record.contact_name" name="contactName">
      <input-error [formErrors]="client.errors" field="contactName"></input-error>
    </div>
    <div class="form-group">
      <label for="contactPhone">Контактный телефон</label>
      <input type="text" class="form-control" id="contactPhone" [(ngModel)]="client.record.contact_phone" name="contactPhone">
      <input-error [formErrors]="client.errors" field="contactPhone"></input-error>
    </div>
    <div class="client-controls">
      <button type="button" class="btn btn-default" (click)="onBack()">&lt; К списку</button>
      <button type="submit" class="btn btn-primary" [disabled]="client.isSubmitting">Сохранить</button>
    </div>
  </form>
</div>
