export class ValidateState {
  timeSlotBegin = false;
  timeSlotBeginMsg = '';

  timeSlotEnd = false;
  timeSlotEndMsg = '';

  destination = false;
  destinationMsg = '';

  clientName = false;
  clientNameMsg = '';

  clientPhone = false;
  clientPhoneMsg = '';

  isValid() {
    return this.timeSlotBegin && this.timeSlotEnd && this.destination && this.clientName && this.clientPhone;
  }
}
