
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {FreighterTransportTariff} from "../_models/freighter-transport-tariff";
import {FreighterTransportTariffService} from "../_services/freighter-transport-tariff.service";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-transport-tariff',
  templateUrl: './new-transport-tariff.component.html',
  styleUrls: ['./new-transport-tariff.component.css']
})
export class NewTransportTariffComponent implements OnInit {
  tariff: FreighterTransportTariff;

  constructor(
    private freighterTransportTariffService: FreighterTransportTariffService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.tariff = new FreighterTransportTariff();
  }

  submit() {
    this.loaderService.show();
    this.freighterTransportTariffService
      .addTariff(this.tariff).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success('Тариф добавлен', true);
          this.router.navigate(['/tariffs/transport']);
        },
        () => {}
      )
  }

  onSubmit() {
    this.submit();
  }
}
