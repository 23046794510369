import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {DeliveryProduct} from "../_models/delivery-product";
import {DeliveryProductLine} from "../_models/delivery-product-line";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

const REQUIRED_FIELDS = ['name'];
const FIXABLE_NUMBER_FIELDS = ['cost', 'weight'];
const EMPTY_LINE_CONTROL_INTERVAL = 2000;

@Component({
  selector: 'delivery-product-editor',
  templateUrl: './delivery-product-editor.component.html',
  styleUrls: ['./delivery-product-editor.component.css']
})
export class DeliveryProductEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() deliveryProduct: DeliveryProduct;
  @Input() totalEnabled = true;
  @Input() title: string;
  @Input() readOnly = false;
  @Output() linesChanged = new EventEmitter<DeliveryProductLine[]>;

  lines: DeliveryProductLine[] = [];

  private emptyLineControlTimer: any;

  private linesChangeStream = new Subject<void>();

  constructor(@Inject(NgZone) private zone: NgZone) {
    this.initLinesChangeStream();
  }

  ngOnInit() {
    this.startEmptyLineControlTimer();
  }

  private initLinesChangeStream(): void {
    this.linesChangeStream
      .pipe(debounceTime(1000))
      .subscribe(() => this.linesChanged.emit(this.getValidLines()))
    ;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('change product');
    if(changes['deliveryProduct']) {
      this.lines = this.deliveryProduct.lines || [];
      this.onControlEmptyLine();
    }
  }

  getValidLines(): DeliveryProductLine[] {
    let validLines: DeliveryProductLine[] = [];
    for(let line of this.lines) {
      if(!this.isLineEmpty(line))
        validLines.push(this.fixNumbers(line));
    }
    return validLines;
  }

  isAllLinesValid(): boolean {
    for(let line of this.lines) {
      if(!this.isLineEmpty(line) && !this.isLineValid(line))
        return false;
    }
    return true;
  }

  private isLineValid(line: DeliveryProductLine): boolean {
    for(const fieldName of REQUIRED_FIELDS) {
      const value = (line[fieldName] || '') as any;
      if(value['trim'] && value.trim() == '' || !value)
        return false;
    }
    return true;
  }

  private startEmptyLineControlTimer(): void {
    if(this.emptyLineControlTimer)
      this.stopEmptyLineControlTimer();

    this.emptyLineControlTimer = setInterval(() => this.onControlEmptyLine(), EMPTY_LINE_CONTROL_INTERVAL);
  }

  private stopEmptyLineControlTimer(): void {
    if(this.emptyLineControlTimer)
      clearInterval(this.emptyLineControlTimer);

    this.emptyLineControlTimer = null;
  }

  private fixNumbers(line: DeliveryProductLine): DeliveryProductLine {
    for(const numberField of FIXABLE_NUMBER_FIELDS) {
      let value = line[numberField] as (string | null);
      if(value != null && value.replace)
        value = value.replace(',', '.').replace(/[^\d.]/, '');

      line[numberField] = (value == null && value != '') ? null : parseFloat(value);
    }
    return line;
  }

  private addEmptyLine(): void {
    this.lines.push(new DeliveryProductLine());
  }

  private removeLastLine(): void {
    this.lines.pop();
  }

  private isLineEmpty(line: DeliveryProductLine): boolean {
    for(const fieldName in line) {
      if(line.hasOwnProperty(fieldName) && line[fieldName])
        return false;
    }
    return true;
  }

  private isLastLineEmpty(): boolean {
    return this.isLineEmpty(this.lines[this.lines.length - 1]);
  }

  private isPenultimateLineEmpty(): boolean {
    return this.isLineEmpty(this.lines[this.lines.length - 2]);
  }

  private isRequiredNewLine(): boolean {
    return !this.readOnly && (this.lines.length == 0 || !this.isLastLineEmpty());
  }

  private isRequiredRemoveLastLine(): boolean {
    return this.lines.length > 1 && this.isLastLineEmpty() && this.isPenultimateLineEmpty();
  }

  private onControlEmptyLine(): void {
    this.zone.run(() => {
      if(this.isRequiredNewLine())
        this.addEmptyLine();
      else if(this.isRequiredRemoveLastLine())
        this.removeLastLine();
    });
  }

  onRemoveLine(n: number): void {
    this.lines.splice(n, 1);
    this.onLinesChanged();
  }

  onLinesChanged(): void {
    this.linesChangeStream.next();
  }

  ngOnDestroy(): void {
    this.stopEmptyLineControlTimer();
  }
}
