import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from "../_models/message";

@Component({
  selector: 'message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.css']
})
export class MessageFormComponent implements OnInit {
  @Input() message: Message;
  @Output() onSendMessage = new EventEmitter<Message>();

  constructor() { }

  ngOnInit() {
  }

  onSubmit() {
    this.onSendMessage.emit(this.message);
  }
}
