import {DestinationPoint} from "./destination-point";
import {DeliveryTrack} from "./delivery-track";

export class OptimizationTaskDestination {
  id: number;
  time_slot_begin: string;
  time_slot_end: string;
  order_code: string;
  amo_crm_lead_id: number;
  delivered: boolean;
  undelivered: boolean;
  external_link: number;
  order_cost: number;
  order_description: string;
  delivery_cost: number;
  discount: number;
  length: number;
  width: number;
  height: number;
  weight: number;
  assembly: boolean;
  assembly_count: number;
  comment: string;
  client_name: string;
  client_phone: string;
  client_email: string;
  pay_method: string;
  external_pay_link: boolean;
  priority: number = 0;
  floor: number;
  has_freight_elevator: boolean;
  destination: DestinationPoint;
  attention: string;
  stock: boolean;
  delivery_to: string;
  tracks: DeliveryTrack[];
  is_contacts_hidden: boolean;
  listPos: number;
}
