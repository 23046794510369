import {State} from "../_storage/state";
import {DateUtils} from "../_utils/date-utils";
import {Freighter} from "./freighter";
import {Employer} from "./employer";

export class OrderListFilter {
  private static emptyFilter: OrderListFilter;

  constructor(
    public status = "",
    public search = "",
    public companyClient: number = 0,
    public debt: number = null,
    public payMethod: string = "",
    public after: Date = null,
    public before: Date = null,
    public freighters: Freighter[] = [],
    public employers: Employer[] = [],
    public trackNumber?: string,
    public cargoCode?: string
  ) {}

  getState(): State {
    return new State(JSON.stringify({
      status: this.status,
      search: this.search,
      after: this.after,
      before: this.before,
      companyClient: this.companyClient,
      debt: this.debt,
      payMethod: this.payMethod,
      freighters: this.freighters,
      employers: this.employers,
      trackNumber: this.trackNumber,
      cargoCode: this.cargoCode
    }));
  }

  equals(filter: OrderListFilter): boolean {
    return this.status === filter.status
      && this.search === filter.search
      && DateUtils.isEqualDates(this.after, filter.after)
      && DateUtils.isEqualDates(this.before, filter.before)
      && this.companyClient === filter.companyClient
      && this.debt === filter.debt
      && this.payMethod === filter.payMethod
      && this.isFreightersEquals(this.freighters, filter.freighters)
      && this.isEmployersEquals(this.employers, filter.employers)
      && this.trackNumber  === filter.trackNumber
      && this.cargoCode  === filter.cargoCode
    ;
  }

  isEmpty(): boolean {
    return this.equals(OrderListFilter.getEmptyFilter());
  }

  private static getEmptyFilter(): OrderListFilter {
    OrderListFilter.emptyFilter = OrderListFilter.emptyFilter || new OrderListFilter();
    return OrderListFilter.emptyFilter;
  }

  private isFreightersEquals(freighters1: Freighter[], freighters2: Freighter[]): boolean {
    if(freighters1.length != freighters2.length)
      return false;

    for(let freighter of freighters1) {
      if(freighters2.findIndex(f => f.id == freighter.id) == -1)
        return false;
    }

    return true;
  }

  private isEmployersEquals(employers1: Employer[], employers2: Employer[]): boolean {
    if(employers1.length != employers2.length)
      return false;

    for(let employer of employers1) {
      if(employers2.findIndex(f => f.id == employer.id) == -1)
        return false;
    }

    return true;
  }

  clone(): OrderListFilter {
    return new OrderListFilter(this.status, this.search, this.companyClient, this.debt, this.payMethod, this.after, this.before, this.freighters, this.employers, this.trackNumber, this.cargoCode);
  }

  static fromState(state: State): OrderListFilter {
    let data = JSON.parse(state.state);
    let status = data['status'] || "";
    let search = data['search'] || "";
    let after = data['after'] == null ? null : new Date(data.after);
    let before = data['before'] == null ? null : new Date(data.before);
    let companyClient = data['companyClient'] == null ? null : data['companyClient'];
    let debt = (data['debt'] == null && data['debt'] !== 0) ? null : data['debt'];
    let payMethod = data['payMethod'] || '';
    let freighters = data['freighters'] || [];
    let employers = data['employers'] || [];
    let trackNumber = data['trackNumber'] || "";
    let cargoCode = data['cargoCode'] || "";

    return new OrderListFilter(status, search, companyClient, debt, payMethod, after, before, freighters, employers, trackNumber, cargoCode);
  }

}
