export const ROLES = {
  ROLE_CUSTOMER: 'Пользователь',
  ROLE_COMPANY: 'Компания-перевозчик',
  ROLE_COMPANY_MANAGER: 'Менеджер компании-перевозчика',
  ROLE_DRIVER: 'Водитель',
  ROLE_DRIVER_MANAGER: 'Водитель-менеджер',
  ROLE_PRIVATE_DRIVER: 'Частник',
  ROLE_ADMIN: 'Админ',
  ROLE_MANAGER: 'Менеджер',
  ROLE_SUPPORT: 'Поддержка',
  ROLE_DELIVERY_API_CLIENT: 'Клиент API доставки',
  ROLE_DELIVERY_MANAGER: 'Менеджер доставки',
  ROLE_PARTNER_API_CLIENT: 'Клиент API компании-клиента',
  ROLE_PARTNER_MANAGER: 'Менеджер компании-клиента',
}
