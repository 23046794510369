import {FileUploader} from "ng2-file-upload";
import {EventEmitter} from "@angular/core";
import {FileItem} from "ng2-file-upload/file-upload/file-item.class";
import {CompleteAllUploadsHandler} from "./complete-all-uploads-handler";
import {FileErrorHandler} from "./file-error-handler";
import {ParsedResponseHeaders} from "ng2-file-upload/file-upload/file-uploader.class";
import {UploadError} from "./upload-error";

export class Uploader extends FileUploader {
  private completeAllEventEmitter = new EventEmitter<void>();
  private fileErrorEventEmitter = new EventEmitter<UploadError>();

  subscribeOnCompleteAll(handler: CompleteAllUploadsHandler) {
    this.completeAllEventEmitter.subscribe(() => handler.onCompleteAllUploads());
  }

  subscribeOnFileError(handler: FileErrorHandler) {
    this.fileErrorEventEmitter.subscribe(e => handler.onFileUploadError(e));
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.fileErrorEventEmitter.emit(new UploadError(item, response, status, headers));

    return super.onErrorItem(item, response, status, headers);
  }

  onCompleteAll(): any {
    this.completeAllEventEmitter.emit();

    return super.onCompleteAll();
  }
}
