import {Pipe, PipeTransform} from "@angular/core";
import {OrderDraft} from "../_models/order-draft";
import {DraftUtils} from "../_utils/draft-utils";

@Pipe({name: "draftLengths"})
export class DraftLengthsPipe implements PipeTransform {
  transform(draft: OrderDraft): string {
    return DraftUtils.getLengths(draft).join(', ')
      || (
        draft.extra_search_params && draft.extra_search_params.length_groups && draft.extra_search_params.length_groups.length > 0
        ? 'определится после сохранения'
        : 'любая'
      );
  }
}
