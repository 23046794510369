import {Connection} from "../connection";
import {Subject} from "rxjs";
import {Message} from "../messages/message";
import {OrderChanged} from "../messages/order-changed";

export class OrderConnection extends Connection {
  public message: Subject<Message> = new Subject();

  constructor(url: string, private orderId: number) {
    super(url);
  }

  protected init() {
    console.log('Init order connection...');

    this.sendMessage(JSON.stringify({
      "action": "listen_order"
    }));
  }

  protected onMessage(data: any) {
    if(data['type'] === 'order' && data['action'] === 'changed' && data['data'].order === this.orderId)
      this.message.next(new OrderChanged(this.orderId, data['data'].draft));
  }
}
