import {DateTime} from "date-time-js";

export class TimeUtils {
  static formatSlot(start: string, end: string): string {
    let startTime = new DateTime(new Date(start));
    let endTime = new DateTime(new Date(end));

    return `${startTime.format('HH:mm')}-${endTime.format('HH:mm')}`;
  }
}
