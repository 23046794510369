import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Order} from "../_models/order";
import {MapTransport} from "../_models/map-transport";
import {Point} from "../_models/point";
import {MapService} from "../_services/map.service";
import {OrderDraft} from "../_models/order-draft";
import {SubsearchState} from "../_models/subsearch-state";
import {Crew} from "../_models/search-state/crew";
import {EmployeeService} from "../_services/employer.service";
import {UserInfoService} from "../_services/user-info.service";
import {Freighter} from "../_models/freighter";
import {VoximplantService} from "../_services/voximplant.service";
import {CrewService} from "../_services/crew.service";
import {DisplayedMarker} from "../_models/map/displayed.marker";
import {DisplayedClusterArea} from "../_models/map/displayed-cluster-area";
import {Route} from "../_models/map/route";
import {GeoUtils} from "../_utils/geo-utils";
import {GoogleMap, MapInfoWindow, MapMarker} from "@angular/google-maps";
import {interval, take} from "rxjs";

const CAR_ICONS_PATH = '/assets/images/cars/wait/';
/**
 * Шаг в минутах между временными маркерами маршрута
 */
export const ROUTE_TIME_MARKERS_STEP = 30;
const MIN_TIME_MARKERS_COUNT = 2;
const OPEN_MILESTONE_WINDOWS_INTERVAL = 375;

class DestinationMarker {
  constructor(
    public position: google.maps.LatLngLiteral,
    public label: string,
    public title: string,
  ) {}
  
}

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChildren('carWindow', { read: MapInfoWindow}) carWindows: QueryList<MapInfoWindow>;
  @ViewChildren('carWindow', { read: ElementRef}) carWindowElements: QueryList<ElementRef>;
  @ViewChildren('carMarker', { read: MapMarker }) carMarkers: QueryList<MapMarker>;
  @ViewChild('orderRouteActivePointWindow', { read: MapInfoWindow }) orderRouteActivePointWindow: MapInfoWindow;
  @ViewChildren('routeMilestoneWindow', { read: MapInfoWindow }) routeMilestoneWindows: QueryList<MapInfoWindow>;
  @ViewChild('trackActivePointWindow', { read: MapInfoWindow }) trackActivePointWindow: MapInfoWindow;
  @ViewChildren('trackMilestoneWindow', { read: MapInfoWindow }) trackMilestoneWindows: QueryList<MapInfoWindow>;

  lat: number = 55.753040;
  lng: number = 37.622002;
  zoom: number = 11;
  mapCenter: google.maps.LatLngLiteral = {
    lat: this.lat,
    lng: this.lng
  }

  isVisible = false;
  mapOptions: google.maps.MapOptions = {}

  order: Order;
  orderDestinations: DestinationMarker[] = [];
  draft: OrderDraft;
  draftDestinations: DestinationMarker[] = [];
  draftPoints: google.maps.LatLngLiteral[] = [];
  cars: MapTransport[] = [];
  showTrack = false;
  trackActivePoint: Point;
  isTrackActivePointInfoWindowActive = false;
  searchState: SubsearchState;
  searchCenter: google.maps.LatLngLiteral|null = null;
  foundCrewsMap = {};
  foundCrews: Crew[] = [];
  isPrivilegedUser: boolean;
  displayedMarkers: DisplayedMarker[] = [];
  displayedClusterAreas: DisplayedClusterArea[] = [];
  routes: Route[] = [];
  orderRoute: Point[] = [];
  orderRouteMilestones: Point[] = [];
  isRoutePointInfoWindowActive = false;
  orderRouteActivePoint: Point;

  private lastTrackMilestonesCount = 0;

  constructor(
    public mapService: MapService,
    private crewService: CrewService,
    private employerService: EmployeeService,
    userInfoService: UserInfoService,
    public voximplantService: VoximplantService,
  ) {
    this.orderRouteActivePoint = new Point();
    this.orderRouteActivePoint.lat = 0;
    this.orderRouteActivePoint.lon = 0;

    this.trackActivePoint = new Point();
    this.trackActivePoint.lat = 0;
    this.trackActivePoint.lon = 0;

    this.isPrivilegedUser = userInfoService.isPrivilegedUser();

    this.initUpdateIconsTimer();
    this.initReopenTrackMilestoneWindowsTimer();

    mapService.getVisibilityObservable().subscribe(visibility => {
      if(visibility)
        this.show();
      else
        this.hide();
    });

    mapService.getMapPositionObservable().subscribe(p => {
      this.zoom = p.zoom || 11;

      this.lat = p.lat;
      this.lng = p.lng;

      this.mapCenter = {
        lat: this.lat,
        lng: this.lng
      }
    });

    mapService.getCleanObservable().subscribe(() => {
      this.order = null;
      this.orderDestinations = [];
      this.draft = null;
      this.draftDestinations = [];
      this.draftPoints = [];
      this.cars = [];
      this.showTrack = false;
      this.searchState = null;
      this.searchCenter = null;
      this.displayedMarkers = [];
      this.displayedClusterAreas = [];
      this.routes = [];
      this.orderRoute = [];
      this.orderRouteMilestones = [];
      this.isRoutePointInfoWindowActive = false;
      this.isTrackActivePointInfoWindowActive = false;
      this.trackActivePoint = null;
      this.lastTrackMilestonesCount = 0;
      this.cleanFoundCrews();
    });

    mapService.getOrderObservable().subscribe(o => this.setupOrder(o));
    mapService.getDraftObservable().subscribe(d => this.setupDraft(d));

    mapService.getTransportsObservable().subscribe(cars => {
      this.cars = cars;
      if(this.showTrack)
        this.openAllTrackMilestoneWindowsIf();
    });

    mapService.getShowTrackObservable().subscribe(show => {
      this.showTrack = show;
      if(show)
        this.openAllTrackMilestoneWindows();
    });

    mapService.getSearchStateObservable().subscribe(state => this.setupSearchState(state));

    mapService.getFoundCrewsObservable().subscribe(c => {
      if(c === null)
        this.cleanFoundCrews();
      else
        this.addFoundCrew(c);
    });

    mapService.getDisplayedMarkersObservable().subscribe(m => this.displayedMarkers = m);
    mapService.getDisplayedClusterAreasObservable().subscribe(a => this.displayedClusterAreas = a);
    mapService.getRoutesObservable().subscribe(r => this.routes = r);
  }

  private initUpdateIconsTimer(): void {
    setTimeout(() => {
      this.foundCrews.forEach(c => this.updateIcon(c));
      this.initUpdateIconsTimer();
    }, 10000);
  }

  private initReopenTrackMilestoneWindowsTimer(): void {
    setTimeout(() => {
      if(this.showTrack)
        this.openAllTrackMilestoneWindowsIf();

      this.initReopenTrackMilestoneWindowsTimer();
    }, 10000);
  }

  ngOnInit() {
  }

  private loadTransportData(car: MapTransport): void {
    let freighter = new Freighter();
    freighter.id = car.feedTransport.freighter_id;

    this.crewService
      .findCrew(freighter, car.feedTransport.employer, car.feedTransport.transport)
      .subscribe(
        crew => {
          this.employerService
            .getEmployeesAcceptedOrders(crew.employer.freighter, crew.employer.id)
            .subscribe(
              orders => {
                car.feedTransport.transport = crew.transport;
                car.feedTransport.employer = crew.employer;
                car.feedTransport.acceptedOrders = orders;
                car.isDataLoaded = true;
                car.buildTitle();
                car.buildExecutionIndicator();
              },
              () => {}
            )
        },
        () => {}
      )
    ;
  }

  private setupDraft(draft: OrderDraft): void {
    this.draft = draft;

    if(this.draft.destinations) {
      this.draftDestinations = draft.destinations.map((d, i) => new DestinationMarker(
        {lat: d.destination.lat, lng: d.destination.lon},
        i + 1 + '',
        d.destination.addr
      ));
    } else {
      this.draftDestinations = [];
    }

    if(draft.points) {
      this.draftPoints = draft.points.map(p => {
        return {
          lat: p.lat,
          lng: p.lng
        }
      })
    } else {
      this.draftPoints = [];
    }
  }

  private setupSearchState(searchState: SubsearchState): void {

    this.searchState = searchState;
    if(searchState) {
      this.searchCenter = {
        lat: parseFloat(searchState.point.lat),
        lng: parseFloat(searchState.point.lon)
      }
    }
  }
  
  private setupOrder(order: Order): void {
    this.order = order;
    this.setupOrderDestinations(order);
    this.setupOrderRoute(order);
    this.setupOrderRouteMilestones();
  }

  private setupOrderDestinations(order: Order): void {
    this.orderDestinations = [];
    let destinationNum = 1;
    for(const period of order.periods) {
      for(const destination of period.destinations) {
        this.orderDestinations.push(new DestinationMarker(
          { lat: destination.destination.lat, lng: destination.destination.lon },
          (destinationNum ++) + '',
          destination.destination.addr
        ));
      }
    }
  }

  private setupOrderRoute(order: Order): void {
    this.orderRoute = order.periods[0].points;
  }

  private setupOrderRouteMilestones(): void {
    this.orderRouteMilestones = [];
    if(this.orderRoute.length == 0 || !this.orderRoute[0].t)
      return;

    const intervalInSec = ROUTE_TIME_MARKERS_STEP * 60;

    let nextMilestone = this.orderRoute[0].t + intervalInSec;
    nextMilestone -= nextMilestone % intervalInSec;
    for(let i = 0, l = this.orderRoute.length; i < l; i++) {
      let point = this.orderRoute[i];
      if(point.t >= nextMilestone) {
        this.orderRouteMilestones.push(Point.clone(point));
        nextMilestone = point.t + intervalInSec - point.t % intervalInSec;
        // console.log('milestone created', point.t, this.nextMilestone)
      }
    }

    if(this.orderRouteMilestones.length < MIN_TIME_MARKERS_COUNT)
      this.orderRouteMilestones = [];

    if(this.orderRouteMilestones.length > 0)
      this.openAllRouteMilestoneWindows();
  }

  private openAllRouteMilestoneWindows(): void {
    interval(OPEN_MILESTONE_WINDOWS_INTERVAL)
      .pipe(take(this.orderRouteMilestones.length))
      .subscribe(i => this.openRouteMilestoneWindow(i))
    ;
  }

  private openAllTrackMilestoneWindowsIf(): void {
    if(this.countTrackMileStones() !== this.lastTrackMilestonesCount)
      this.openAllTrackMilestoneWindows();
  }

  private openAllTrackMilestoneWindows(): void {
    this.lastTrackMilestonesCount = this.countTrackMileStones();
    interval(OPEN_MILESTONE_WINDOWS_INTERVAL)
      .pipe(take(this.lastTrackMilestonesCount))
      .subscribe(i => this.openTrackMilestoneWindow(i))
    ;
  }

  private countTrackMileStones(): number {
    return this.cars.reduce((acc, car) => acc + car.path.milestones.length, 0);
  }

  private show(): void {
    this.isVisible = true;
    this.mapService.initMap();
  }

  private hide(): void {
    this.isVisible = false;
  }

  private addFoundCrew(crew: Crew): void {
    if(this.foundCrewsMap[crew.order.id])
      Crew.copy(crew, this.foundCrewsMap[crew.order.id]);
    else
      this.addNewFoundCrew(crew);
  }

  private addNewFoundCrew(crew: Crew): void {
    this.foundCrewsMap[crew.order.id] = crew;
    crew.icon = this.getIconForFoundCrew(crew);
    this.foundCrews.push(crew);
  }

  private cleanFoundCrews(): void {
    this.foundCrewsMap = {};
    this.foundCrews = [];
  }

  private updateIcon(crew: Crew): void {
    let newIcon = this.getIconForFoundCrew(crew);
    if(newIcon != crew.icon)
      Crew.incVersion(crew);

    crew.icon = newIcon;
  }

  private getIconForFoundCrew(crew: Crew): string {
    if(crew.order.status == 'not_agree')
      return CAR_ICONS_PATH + 'not-agree.png';

    let waitIcon = 0;
    let curTime = new Date().getTime() / 1000;
    let timeDiff = curTime - crew.found_at;
    if(timeDiff < 60) {
      let rest = 60 - timeDiff;
      waitIcon = Math.round(rest / 15) * 15;
    }


    return CAR_ICONS_PATH + waitIcon.toString() + '.png';
  }

  trackForFoundCrew(index: number, crew: Crew): string {
    return crew.order.id.toString()+ '_' + (crew.version || 0).toString();
  }

  trackForCars(index: number, transport: MapTransport): string {
    return transport.feedTransport.transport.id.toString() + '_' + (transport.version || 0).toString();
  }

  private findNearestRoutePoint(route: Point[], target: Point): Point {
    let minDistance = Infinity;
    let nearestPoint: Point | null = null;

    for (const point of route) {
      const distance = GeoUtils.calcDistance(target.lat, target.lng, point.lat, point.lng);
      if (distance < minDistance) {
        minDistance = distance;
        nearestPoint = point;
      }
    }

    return nearestPoint;
  }

  private openRouteMilestoneWindow(num: number): void {
    if(num >= this.routeMilestoneWindows.length)
      return;

    this.routeMilestoneWindows.get(num).position = { lat: this.orderRouteMilestones[num].lat, lng: this.orderRouteMilestones[num].lng }
    this.routeMilestoneWindows.get(num).open(undefined, false);
  }

  private openTrackMilestoneWindow(num: number): void {
    if(num >= this.trackMilestoneWindows.length)
      return;

    this.trackMilestoneWindows.get(num).open(undefined, false);
  }

  onClickMap(event: google.maps.MapMouseEvent) {
    let point = new Point();
    point.lat = event.latLng.lat();
    point.lon = event.latLng.lng();

    this.mapService.dispatchMapClick(point);

    this.isRoutePointInfoWindowActive = false;
    this.isTrackActivePointInfoWindowActive = false;

    this.orderRouteActivePointWindow?.close();
    this.trackActivePointWindow?.close();
  }

  onBoundsChange(): void {
    this.mapService.dispatchMapBoundsChange(this.map.getBounds());
  }

  onCarClick(car: MapTransport, carNum: number): void {
    // console.log(this.carWindows);
    // console.log(this.carWindowElements);

    for(let i = 0; i < this.carWindowElements.length; i++) {
      let el = this.carWindowElements.get(i);
      if(el.nativeElement['car-num'] == carNum) {
        this.carWindows.get(i).open(this.carMarkers.get(carNum), true)
      }
    }

    if(!car.isDataLoaded && car.selectable)
      this.loadTransportData(car);
  }

  onSelectCar(car: MapTransport): void {
    this.mapService.selectTransport(car);
  }

  onClickDestination(destinationId: number): void {
    this.mapService.dispatchDisplayedMarkerClick(destinationId);
  }

  onClickOrderRoute(e: google.maps.PolyMouseEvent): void {
    if(!this.orderRoute[0].t)
      return;

    let clickPoint = new Point();
    clickPoint.lat = e.latLng.lat();
    clickPoint.lon = e.latLng.lng();

    this.isRoutePointInfoWindowActive = true;
    this.orderRouteActivePoint = Point.clone(this.findNearestRoutePoint(this.orderRoute, clickPoint));
    this.orderRouteActivePointWindow.position = { lat: this.orderRouteActivePoint.lat, lng: this.orderRouteActivePoint.lng };
    this.orderRouteActivePointWindow.open();
  }

  onClickTrack(car: MapTransport, e: google.maps.PolyMouseEvent): void {
    let clickPoint = new Point();
    clickPoint.lat = e.latLng.lat();
    clickPoint.lon = e.latLng.lng();

    this.isTrackActivePointInfoWindowActive = true;
    this.trackActivePoint = Point.clone(this.findNearestRoutePoint(car.path.points, clickPoint));
    this.trackActivePointWindow.position = { lat: this.trackActivePoint.lat, lng: this.trackActivePoint.lng };
    this.trackActivePointWindow.open();
  }
}
