import {Connection} from "../connection";
import {Subject} from "rxjs";
import {Message} from "../messages/message";
import {CarFoundMessage} from "../messages/car-found";
import {type} from "os";
import {newTaxiOrder} from '../messages/taxi-order';
import {taxiNoAnswer} from '../messages/taxi-no-answer'
import {TaxiNotFound} from "../messages/taxi-not-found";
import {TaxiNewSearch} from "../messages/taxi-new-search";
import {TaxiSearchState} from "../messages/taxi-search-state";
import {SearchState} from "../../_models/search-state";
import {SearchPaused} from "../messages/search-paused";
import {SearchResumed} from "../messages/search-resumed";
import {TaxiSearchError} from "../messages/taxi-search-error";
import {SearchDuration} from "../messages/search-duration";
import {TaxiSearchUpdated} from "../messages/taxi-search-updated";
import {SearchStopped} from "../messages/search-stopped";


export class TaxiConnection extends Connection {
  public message: Subject<Message> = new Subject();


  constructor(url: string) {
    super(url);
  }

  protected init() {
    console.log('Init taxi connection...');

    this.sendMessage(JSON.stringify({
      "action": "listen_taxi"
    }));
  }

  protected onMessage(data: any) {
    // console.log(data);
    if (data['type'] == 'taxi') {
      switch (data['action']) {
        case 'new_order':
          this.message.next(new newTaxiOrder(data.data.order, data.data.draft));
          break;
        case 'found':
          this.message.next(new CarFoundMessage(data, data.data.draft));
          break;
        case 'no_answer':
          this.message.next(new taxiNoAnswer(data.data.order, data.data.draft));
          break;
        case 'not_found':
          this.message.next(new TaxiNotFound(data.data.draft));
          break;
        case 'paused':
          this.message.next(new SearchPaused(data.data.draft));
          break;
        case 'stopped':
          this.message.next(new SearchStopped(data.data.draft));
          break;
        case 'resumed':
          this.message.next(new SearchResumed(data.data.draft));
          break;
        case 'new_search':
          this.message.next(new TaxiNewSearch(data.data.draft));
          break;
        case 'search_state':
          this.message.next(new TaxiSearchState(data.data as SearchState));
          break;
        case 'error':
          this.message.next(new TaxiSearchError(data.data.draft));
          break;
        case 'search_duration':
          this.message.next(new SearchDuration(data.data.draft, data.data.duration, data.data.restTime, data['text']));
          break;
        case 'search_updated':
          this.message.next(new TaxiSearchUpdated(data.data.draft, data.data.started, data.data.stopped));
          break;
      }
    }

  }
}
