<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row">
  <h1 *ngIf="client">{{ client.name }}</h1>
  <div class="small text-center" *ngIf="!entity.isLoaded">загрузка...</div>
  <freighter-client-legal-entity-form *ngIf="entity.isLoaded && client"
                                      [legalEntity]="entity"
                                      (cancel)="onBack()"
                                      (saved)="onSaved()"
                                      [readOnly]="readOnly"
                                      [freighter]="freighter"
                                      [linkMode]="true"
                                      [client]="client"></freighter-client-legal-entity-form>
</div>
