
import {finalize} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {OzonParcelsListComponent} from "../ozon-parcels-list/ozon-parcels-list.component";
import {ParcelInfo} from "../_models/ozon/parcel-info";
import {Storehouse} from "../_models/storehouse";
import {StorehouseService} from "../_services/storehouse.service";
import {LoaderService} from "../_services/loader.service";
import {OzonService} from "../_services/ozon.service";
import {OptimizationTaskImportResult} from "../_models/ozon/optimization-task-import-result";
import {Router} from "@angular/router";
import {DateTime} from "date-time-js";
import {StorehouseCreatorComponent} from "../storehouse-creator/storehouse-creator.component";
import {OptimizationTaskTransport} from "../_models/optimization-task-transport";

const DATE_REGEXP = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
const TIME_REGEXP = /^([0-9]{2}):([0-9]{2})(?::([0-9]{2}))?$/;

@Component({
  selector: 'ozon-import-dialog',
  templateUrl: './ozon-import-dialog.component.html',
  styleUrls: ['./ozon-import-dialog.component.css']
})
export class OzonImportDialogComponent implements OnInit, OnDestroy {
  @Output() onImported = new EventEmitter<OptimizationTaskImportResult[]>();

  @ViewChild('ozonImportDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(OzonParcelsListComponent, { static: true }) listComponent: OzonParcelsListComponent;
  @ViewChild(StorehouseCreatorComponent) storehouseCreator: StorehouseCreatorComponent;

  importEnabled = false;
  selectedParcels: ParcelInfo[] = [];
  storehouseId: number = 0;
  storehouses: Storehouse[] = [];
  newStoreHouseMode = false;
  arrivalDate = '';
  arrivalTimeSlotStart = '';
  arrivalTimeSlotEnd = '';
  isArrivalDateValid = true;
  isArrivalTimeSlotStartValid = true;
  isArrivalTimeSlotEndValid = true;
  isStorehouseValid = true;
  transportMode = false;
  transport: OptimizationTaskTransport|null = null;

  lastImportResult: OptimizationTaskImportResult[] = [];
  private modalWasInit = false;
  private onHiddenCallback: () => void|null = null;
  private wasShown = false;

  constructor(private storehouseService: StorehouseService,
              private loaderService: LoaderService,
              private router: Router,
              private ozonService: OzonService) {
  }

  ngOnInit() {
    this.arrivalDate = new DateTime().format('yyyy-MM-dd');
  }

  isValid(): boolean {
    return (
        this.transportMode
        || (
          this.isArrivalDateValid
          && this.isArrivalTimeSlotStartValid
          && this.isArrivalTimeSlotEndValid
          && this.isStorehouseValid
        )
      )
      && this.selectedParcels.length > 0
      ;
  }

  private validate(): void {
    if(this.transportMode)
      return;

    this.isArrivalDateValid = DATE_REGEXP.test(this.arrivalDate);
    this.isArrivalTimeSlotStartValid = TIME_REGEXP.test(this.arrivalTimeSlotStart);
    this.isArrivalTimeSlotEndValid = TIME_REGEXP.test(this.arrivalTimeSlotEnd);
    this.isStorehouseValid = this.storehouseId > 0
      || this.storehouseId == -1 && this.storehouseCreator && this.storehouseCreator.isValid();
  }

  private initStorehouses(): void {
    this.storehouses = [];

    this.storehouseService
      .getAvailableStorehouses()
      .subscribe(
        s => {
          this.storehouses = s;
          if(s.length == 0)
            this.storehouseId = -1;
          else
            this.applyLastStorehouse();
        },
        () => {
        }
      )
    ;
  }

  private applyLastStorehouse(): void {
    this.storehouseService
      .getLastByOptimizationTasks()
      .subscribe(
        s => {
          if(s)
            this.storehouseId = s.id;
        },
        () => {}
      )
  }

  private syncImportControls(): void {
    this.validate();
    this.importEnabled = this.isValid();
    this.newStoreHouseMode = this.storehouseId == -1;
  }

  show(): void {
    this.transportMode = false;
    this.prepareAndShow();
  }

  showForTransport(transport: OptimizationTaskTransport): void {
    this.transportMode = true;
    this.transport = transport;
    this.prepareAndShow();
  }

  private prepareAndShow() {
    this.selectedParcels = [];
    this.initStorehouses();
    this.syncImportControls();

    this.listComponent.loadNewParcels();

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => this.onHidden())
    }

    this.wasShown = true;
    $(this.dialogEl.nativeElement).modal('show');
  }

  hide(callback?: () => void): void {
    this.onHiddenCallback = callback;
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private import(): void {
    if(this.storehouseId > 0) {
      this.rawImport();
    } else if(this.storehouseId == -1) {
      this.storehouseCreator.save(storehouse => {
        this.storehouses.push(storehouse);
        this.storehouseId = storehouse.id;
        this.rawImport();
      })
    }
  }

  private rawImport(): void {
    let storehouse = this.storehouses.find(s => s.id == this.storehouseId);
    let postingNumbers = this.selectedParcels.map(p => p.posting_number);

    let dateComponents = this.arrivalDate.split('-');
    let timeSlotStartComponents = TIME_REGEXP.exec(this.arrivalTimeSlotStart);
    let timeSlotEndComponents = TIME_REGEXP.exec(this.arrivalTimeSlotEnd);
    let [year, month, day ] = dateComponents.map(c => parseInt(c));
    let [ startHour, startMinute] = [ parseInt(timeSlotStartComponents[1]), parseInt(timeSlotStartComponents[2]) ];
    let [ endHour, endMinute] = [ parseInt(timeSlotEndComponents[1]), parseInt(timeSlotEndComponents[2]) ];
    let arrivalStartDate = new Date(year, month - 1, day, startHour, startMinute, 0);
    let arrivalEndDate = new Date(year, month - 1, day, endHour, endMinute, 0);

    if(arrivalEndDate.getTime() < arrivalStartDate.getTime())
      arrivalEndDate = new DateTime(arrivalEndDate).add(1, 'day').toDate();

    this.loaderService.show();
    this.loaderService.showText('Импорт заказов из Ozon');
    this.ozonService
      .importToOptimizationTask(storehouse, arrivalStartDate, arrivalEndDate, postingNumbers)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: r => {
          this.lastImportResult = r;
          this.listComponent.loadNewParcels();
          this.onImported.next(r);
        }
        ,
        error: () => {
        }
      })
    ;
  }

  private importToTransport(): void {
    let postingNumbers = this.selectedParcels.map(p => p.posting_number);
    this.loaderService.show();
    this.loaderService.showText('Импорт заказов из Ozon');
    this.ozonService
      .importToOptimizationTaskTransport(this.transport, postingNumbers)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: r => {
          this.lastImportResult = r;
          this.listComponent.loadNewParcels();
          this.onImported.next(r);
        },
        error: () => {
        }
      })
    ;
  }

  onSelectionChanged(parcels: ParcelInfo[]) {
    this.selectedParcels = parcels;
    this.syncImportControls();
  }

  onSelectStorehouse() {
    this.syncImportControls();
  }

  onImport() {
    if(this.transportMode)
      this.importToTransport();
    else
      this.import();
  }

  onClickTask(taskId: number) {
    this.hide(() => this.router.navigate(['/complex-deliveries', taskId]));
  }

  onHidden(): void {
    if(this.onHiddenCallback) {
      this.onHiddenCallback();
      this.onHiddenCallback = null;
    }
    this.transport = null;
    this.wasShown = false;
  }

  onChangeInput(): void {
    this.syncImportControls();
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hide();
  }
}
