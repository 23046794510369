import {State} from "../_storage/state";

export class CrewsListFilter {
  constructor(
    public status = "",
    public search = "",
    public transportBody = "",
    public transportCapacity = "",
    public date: Date = new Date()
  ) {}

  getState(): State {
    return new State(JSON.stringify({
      status: this.status,
      search: this.search,
      transportBody: this.transportBody,
      transportCapacity: this.transportCapacity,
      date: this.date.toString()
    }));
  }

  equals(filter: CrewsListFilter): boolean {

    return this.status === filter.status
      && this.search === filter.search
      && this.transportBody === filter.transportBody
      && this.transportCapacity === filter.transportCapacity
      && this.date.getTime() === filter.date.getTime()
      ;
  }

  clone(): CrewsListFilter {
    return new CrewsListFilter(
      this.status,
      this.search,
      this.transportBody,
      this.transportCapacity,
      new Date(this.date.getTime())
    );
  }

  static fromState(state: State): CrewsListFilter {
    let data = JSON.parse(state.state);
    let status = data.status || "";
    let search = data.search || "";
    let transportBody = data.transportBody || "";
    let transportCapacity = data.transportCapacity || "";
    let date = data.date && new Date(data.date) || new Date();

    return new CrewsListFilter(status, search, transportBody, transportCapacity, date);
  }

}
