import {Order} from "../_models/order";
import {DraftUtils} from "./draft-utils";

export class OrderUtils {
  static isTest(order: Order): boolean {
    let isTestDraft = order.draft && DraftUtils.isTest(order.draft);
    let isTestEmployer = false;

    if(order.periods) {
      for (let period of order.periods) {
        if(period.employer && period.employer.account && period.employer.account.is_test) {
          isTestEmployer = true;
          break;
        }
      }
    }

    return isTestDraft || isTestEmployer;
  }
}
