<div class="modal fade" tabindex="-1" role="dialog" #legalEntitiesDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Выбор юридического лица</h4>
        </div>
      </div>
      <div class="modal-body">
        <legal-entities-selector (selected)="onSelected($event)"></legal-entities-selector>
      </div>
    </div>
  </div>
</div>
