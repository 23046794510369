
import {of as observableOf, Observable, Subject} from 'rxjs';

import {finalize, catchError, switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TransportsFilter} from "../_models/transports-filter";
import {Freighter} from "../_models/freighter";
import {Employer} from "../_models/employer";
import {TransportsList} from "../_models/transports-list";
import {LoaderService} from "../_services/loader.service";
import {Page} from "../pager/page";
import {Auth} from "../_models/auth";
import {TransportService} from "../_services/transport.service";
import {Transport} from "../_models/transport";

class ListCommand {
  constructor(
    private _filter?: TransportsFilter,
    private _page?: number,
    private _force?: boolean
  ) {}

  get page(): number {
    return this._page;
  }

  get force(): boolean {
    return this._force;
  }

  get filter(): TransportsFilter {
    return this._filter;
  }

  equals(cmd: ListCommand): boolean {
    return !cmd._force && this._page === cmd.page && this._filter.equals(cmd.filter);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'transport-selector',
  templateUrl: './transport-selector.component.html',
  styleUrls: ['./transport-selector.component.css']
})
export class TransportSelectorComponent implements OnInit, OnChanges {
  @Input() freighter: Freighter;
  @Input() date: Date;
  @Input() employer: Employer;
  @Output() selected = new EventEmitter<Transport>();

  transports = TransportsList.empty();
  filter = new TransportsFilter();
  private listCommands: Subject<ListCommand>;
  private oldFilter = new TransportsFilter();

  constructor(
    private transportService: TransportService,
    private loaderService: LoaderService
  ) {
    this.transports.filter = this.filter.clone();
  }

  ngOnInit() {
    this.initTransports();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter.crewDate = this.date || new Date();
    this.transports.filter.crewDate = new Date(this.filter.crewDate.toString());
  }

  private initTransports(): void {
    this.listCommands = new Subject<ListCommand>();
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        console.log('load transports...');
        this.loaderService.show();
        return this.transportService
          .getFreighterTransports(this.freighter, command.page, command.filter).pipe(
          finalize(() => this.loaderService.hide()))
          ;
      }),
      catchError(e => {
        console.log(e);
        this.initTransports();
        return observableOf<TransportsList>(TransportsList.empty())
      }),)
      .subscribe(list => {
        this.transports = list
        this.oldFilter = list.filter.clone();
      });
  }

  private loadTransports(page?: number, filter?: TransportsFilter, force?: boolean) {
    this.listCommands.next(new ListCommand(filter?.clone(), page, force));
  }

  reloadTransports(): void {
    this.loadTransports(this.transports.page, this.transports.filter, true);
  }

  onPaginationPage(page: Page) {
    this.loadTransports(page.num, this.transports.filter);
  }

  onChangeFilter(): void {
    if(!this.filter.equals(this.oldFilter)) {
      console.log('filter changed');
      this.listCommands.next(new ListCommand(this.filter.clone()));
    }
  }

  onSwitchWithoutCrewFilter(): void {
    this.filter.withoutCrew = !this.filter.withoutCrew;
    this.onChangeFilter();
  }

  onSwitchEmployerFilter(): void {
    if(this.filter.ofEmployer)
      this.filter.ofEmployer = null;
    else
      this.filter.ofEmployer = this.employer.id;

    this.onChangeFilter();
  }

  onClickAllFilter(): void {
    this.filter.withoutCrew = false;
    this.filter.ofEmployer = null;
    this.onChangeFilter();
  }

  onSelect(transport: Transport): void {
    this.selected.emit(transport);
  }
}
