import {UploadError} from "./upload-error";

export class UploadErrorMessageBuilder {
  static build(error: UploadError) {
    let message = [ error.file.file.name, ': ' ];
    if(error.status == 400) {
      let response = JSON.parse(error.response);
      message.push(response.message);
    } else {
      message.push(`неизвестная ошибка со статусом ${error.status}`)
    }

    return message.join('');
  }
}
