import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserStat} from "../_models/user-stat";

@Component({
  selector: 'user-detail-dialog',
  templateUrl: './user-detail-dialog.component.html',
  styleUrls: ['./user-detail-dialog.component.css']
})
export class UserDetailDialogComponent implements OnInit, OnDestroy {
  @ViewChild('userDetailDialog', { static: true }) dialogEl: ElementRef;

  user: UserStat;

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  showFor(user: UserStat): void {
    this.user = user;

    $(this.dialogEl.nativeElement).modal('show');

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hide(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hide();
  }
}
