
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {ApiUtilsService} from "./api-utils.service";
import {Observable} from "rxjs";
import {OrderDraft} from "../_models/order-draft";
import {Destination} from "../_models/destination";
import {Account} from "../_models/account";
import {Order} from "../_models/order";
import {DeliverySchedule} from "../_models/delivery-schedule";
import {DraftsList} from "../_models/drafts-list";
import {DraftListFilter} from "../_models/draft-list-filter";
import {SEARCH_STATUSES_GROUPS} from "../_maps/search-statuses";
import {Image} from "../_models/image";
import {DateUtils} from "../_utils/date-utils";
import {environment} from "../../environments/environment";
import {OrderList} from "../_models/order-list";
import {OrderListFilter} from "../_models/order-list-filter";
import {CityService} from "./city.service";
import {ExternalSearchExecutionDescriptor} from "../_models/external-search-execution-descriptor";
import {EXTRA_SEARCH_MAP} from "../_maps/extra-search-map";
import {DraftSpecial} from "../_models/draft-special";
import {FreighterClient} from "../_models/freighter-client";
import {FreighterClientService} from "./freighter-client.service";
import {City} from "../_models/city";
import {HttpResponse} from "@angular/common/http";

export const PAGE_SIZE = 10;

@Injectable()
export class OrderDraftService {
  constructor(private _requestService: RequestWithErrorHandlerService, private apiUtilsService: ApiUtilsService, private cityService: CityService) { }

  getDrafts(page?: number, filter?: DraftListFilter): Observable<DraftsList> {
    let filterClone = filter && filter.clone() || new DraftListFilter();

    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts.json`, {
        searchStatus: filter && filter.searchStatus && SEARCH_STATUSES_GROUPS[filter.searchStatus] && SEARCH_STATUSES_GROUPS[filter.searchStatus].statuses.join(',') || null,
        after: filter && filter.after ? DateUtils.formatDate(filter.after) : null,
        before: filter && filter.before ? DateUtils.formatDate(filter.before) : null,
        client: filter && filter.client,
        draftId: filter && filter.draftId,
        search: filter && filter.search,
        companyClient: filter && filter.companyClient,
        scheduledAfter: filter && filter.scheduledAfter ? DateUtils.formatDate(filter.scheduledAfter) : null,
        scheduledBefore: filter && filter.scheduledBefore ? DateUtils.formatDate(filter.scheduledBefore) : null,
        cargoCode: filter && filter.cargoCode,
        trackNumber: filter && filter.trackNumber,
        city: this.cityService.activeCity && this.cityService.activeCity.id,
        offset: (page || 0) * PAGE_SIZE,
        size: PAGE_SIZE,
        view: 'for_orders'
      }).pipe(
      map(r => new DraftsList(
        page || 0,
        PAGE_SIZE,
        parseInt(r.headers.get('X-Total-Count')),
        filterClone,
        r.body.drafts as OrderDraft[])
      ));
  }

  getDraft(id: number): Observable<OrderDraft> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${id}.json`, {
        view: 'for_orders'
      }).pipe(
      map(r => r.body.draft as OrderDraft));
  }

  getAcceptedOrder(id: number): Observable<Order> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${id}/accepted.json`, {
        view: 'for_orders'
      }).pipe(
      map(r => r.body.order as Order));
  }

  getAgreedOrder(id: number): Observable<Order> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${id}/agreed.json`, {
        view: 'for_orders'
      }).pipe(
      map(r => r.body.order as Order));
  }

  getCompletedOrder(id: number): Observable<Order> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${id}/completed.json`, {
        view: 'for_orders'
      }).pipe(
      map(r => r.body.order as Order));
  }

  getDraftOrders(id: number, page: number): Observable<OrderList> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${id}/orders.json`, {
        view: 'for_orders',
        offset: (page || 0) * PAGE_SIZE,
        size: PAGE_SIZE
      }).pipe(
      map(r => new OrderList(
        page,
        PAGE_SIZE,
        parseInt(r.headers.get('X-Total-Count')), new OrderListFilter(),
        r.body.orders as Order[])
      ));
  }

  addDraft(draft: OrderDraft, trackingServiceIdentifier?: string, special?: DraftSpecial): Observable<HttpResponse<any>> {
    let data = OrderDraftService.draftToData(draft, special);
    data.trackingService = trackingServiceIdentifier;

    return this._requestService.post(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts.json`, data);
  }

  updateDraft(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}.json`, OrderDraftService.draftToData(draft));
  }

  importComplexDeliveryOrder(draft: OrderDraft, complexDeliveryId: number, complexDeliveryOrderId: string, draftDestinationId?: number, after?: boolean): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/importer/complex_delivery/order.json`, {
        complexDelivery: complexDeliveryId,
        complexDeliveryOrder: complexDeliveryOrderId,
        draftDestination: draftDestinationId,
        after: after ? 1 : 0
      });
  }

  updateNotes(id: number, notes: string): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${id}/notes.json`, {
        notes
      });
  }

  updateFreighterFilters(draft: OrderDraft): Observable<HttpResponse<any>> {
    let data = {};
    OrderDraftService.draftFreighterFiltersToData(draft, data);
    OrderDraftService.draftEmployerFiltersToData(draft, data);
    OrderDraftService.draftCrewFiltersToData(draft, data);
    OrderDraftService.autoAssigmentCrewToData(draft, data);

    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/freighter_filters.json`, data);
  }

  updateSearchParams(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/search_params.json`, {
        autoAcceptTaxi: draft.auto_accept_taxi,
        autoExternalSearch: draft.auto_external_search
      });
  }

  updateIntercomDialog(id: number, link: string): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${id}/intercom_dialog.json`, {
        link
      });
  }

  scheduleDelivery(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/delivery/schedule.json`, {});
  }

  startSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/taxi/search.json`, {});
  }

  scheduleTaxiSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/taxi/search/schedule.json`, {});
  }

  restartSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/taxi/search/restart.json`, {});
  }

  startSearchViaLinks(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`/support/me/orders/drafts/${draft.id}/taxi/search_via_links.json`, {});
  }

  stopSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/taxi/search/stop.json`, {});
  }

  pauseSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/taxi/search/pause.json`, {});
  }

  requestSearchState(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForDraft(draft)}/orders/drafts/${draft.id}/taxi/search/state.json`, {});
  }

  deletePhoto(draft: OrderDraft, image: Image): Observable<HttpResponse<any>> {
    return this._requestService.delete(`${this.apiUtilsService.getPrefixForDrafts()}/orders/drafts/${draft.id}/photos.json`, {
      filename: image.filename
    });
  }

  getLinkForEmployer(draft: OrderDraft): string {
    return `${environment.apiEndpoint}/employer/me/drafts/${draft.id}.html`;
  }

  getDraftLink(draft: OrderDraft): Observable<string> {
    return this._requestService
      .get(`/support/me/orders/drafts/${draft.id}/link.json`).pipe(
      map(r => r.body as string))
    ;
  }

  createDraftLink(draft: OrderDraft): Observable<string> {
    return this._requestService
      .post(`/support/me/orders/drafts/${draft.id}/link.json`, {}).pipe(
      map(r => r.body as string))
      ;
  }

  requestCalculation(draft: OrderDraft, special?: DraftSpecial): Observable<any> {
    return this._requestService
      .post(
        '/orders/calculator/taxi.json',
        Object.assign(OrderDraftService.draftToData(draft, special), { points: true, withCalculation: true })
      ).pipe(
      map(r => r.body))
      ;
  }

  getExternalSearchExecutions(draft: OrderDraft): Observable<ExternalSearchExecutionDescriptor[]> {
    return this._requestService
      .get(`/support/me/orders/drafts/${draft.id}/taxi/search/external/executions.json`).pipe(
      map(r => r.body as ExternalSearchExecutionDescriptor[]))
    ;
  }

  private static draftToData(draft: OrderDraft, special?: DraftSpecial): any {
    let data = {
      taxi: 1,
      nearest: 0,
      city: draft.city && draft.city.id,
      delivery: draft.delivery ? 1 : 0,
      payMethod: draft.pay_method,
      payMethodOption: draft.pay_method_option,
      loaders: draft.loaders,
      assembly: draft.assembly,
      comment: draft.comment,
      cost: draft.cost,
      client: OrderDraftService.clientToData(draft.client),
      freighterClient: draft.freighter_client && OrderDraftService.freighterClientToData(draft.freighter_client),
      legalEntity: draft.legal_entity ? draft.legal_entity.id : null,
      deliverySchedule: draft.delivery_schedule ? OrderDraftService.deliveryScheduleToData(draft.delivery_schedule, draft.city) : null,
      deliverySchema: draft.delivery_schema && draft.delivery_schema.id,
      deliveryCompanyClient: draft.delivery_company_client && draft.delivery_company_client.id,
      deliveryStorehouseArrival: draft.delivery_storehouse_arrival && draft.delivery_storehouse_arrival !== ''
        ? OrderDraftService.convertDate(new Date(draft.delivery_storehouse_arrival), draft.city)
        : null
    };

    if(special) {
      data['special'] = special.special;
      data['specialSchema'] = special.schema;
    }

    if(draft.taxi_schema) {
      data['taxiSchema'] = {
        transport: draft.taxi_schema,
        loaders: draft.taxi_loaders_schema || null
      };
    }

    OrderDraftService.autoAcceptTaxiToData(draft, data);
    OrderDraftService.autoExternalSearchToData(draft, data);
    OrderDraftService.draftFeaturesToData(draft, data);
    OrderDraftService.draftExtraToData(draft, data);
    OrderDraftService.draftFreighterFiltersToData(draft, data);
    OrderDraftService.draftEmployerFiltersToData(draft, data);
    OrderDraftService.draftCrewFiltersToData(draft, data);
    OrderDraftService.autoAssigmentCrewToData(draft, data);
    OrderDraftService.insurancesToData(draft, data);
    OrderDraftService.paymentDistributionSchemaToData(draft, data);

    this.draftTariffsToData(draft, data);

    if(draft.destinations.length > 0)
      data['from'] = OrderDraftService.destinationToData(draft.destinations[0], draft.city);

    if(draft.destinations.length > 1)
      data['to'] = draft.destinations.slice(1).map(d => OrderDraftService.destinationToData(d, draft.city));

    return data;
  }

  private static draftTariffsToData(draft: OrderDraft, data: any): void {
    if(draft.tariffs && draft.tariffs.length > 0) {
      let tariff = draft.tariffs[0];
      data.tariff = {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price,
        afterMkadKmPrice: tariff.after_mkad_km_price,
        ttkPrice: tariff.ttk_price,
        hydroelevatorHourPrice: tariff.hydroelevator_hour_price
      };
    }
    if(draft.loader_tariffs && draft.loader_tariffs[0]) {
      let tariff = draft.loader_tariffs[0];
      data.loaderTariff = {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price,
        onTheWayHourPrice: tariff.on_the_way_hour_price
      };
    }
    if(draft.assembler_tariffs && draft.assembler_tariffs[0]) {
      let tariff = draft.assembler_tariffs[0];
      data.assemblerTariff = {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price
      }
    }
    if(draft.lifting_tariffs && draft.lifting_tariffs[0]) {
      let tariff = draft.lifting_tariffs[0];
      data.liftingTariff = {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price
      }
    }
  }

  private static draftFreighterFiltersToData(draft: OrderDraft, data: any): void {
    if(draft.freighter_filters == null)
      return;

    let ids = draft.freighter_filters.map(f => f.freighter.id);
    data['freighterFilters'] = ids.join(',');
  }

  private static draftEmployerFiltersToData(draft: OrderDraft, data: any): void {
    if(draft.employer_filters == null)
      return;

    let ids = draft.employer_filters.map(f => f.employer.id);
    data['employerFilters'] = ids.join(',');
  }

  private static draftCrewFiltersToData(draft: OrderDraft, data: any): void {
    if(draft.crew_filters == null)
      return;

    let ids = draft.crew_filters.map(f => f.crew.id);
    data['crewFilters'] = ids.join(',');
  }

  private static autoAssigmentCrewToData(draft: OrderDraft, data: any): void {
    if(draft.auto_assigment_crew != null)
      data['autoAssigmentCrew'] = draft.auto_assigment_crew;
  }

  private static insurancesToData(draft: OrderDraft, data: any): void {
    data['insurance'] = draft.insurances.map(i => {
      return {
        id: i.id,
        section: i.section,
        cargoDescription: i.cargo_description,
        cargoCost: i.cargo_cost,
      }
    });
  }

  private static autoAcceptTaxiToData(draft: OrderDraft, data: any): void {
    data.autoAcceptTaxi = draft.auto_accept_taxi;
  }

  private static autoExternalSearchToData(draft: OrderDraft, data: any): void {
    data.autoExternalSearch = draft.auto_external_search;
  }

  private static paymentDistributionSchemaToData(draft: OrderDraft, data: any): void {
    if(!draft.payment_distribution_schemas || draft.payment_distribution_schemas.length == 0)
      return;

    data['paymentDistributionSchema'] = draft.payment_distribution_schemas[0].schema.id;
  }

  /**
   * Запись свойств черновика в объект данных запроса
   *
   * @param {OrderDraft} draft
   * @param {any} data
   */
  private static draftFeaturesToData(draft: OrderDraft, data: any) {
    if(!draft.features)
      return;

    data['features'] = {};
    for(let feature of draft.features) {
      data['features'][feature.feature.identifier] = {};

      data['features'][feature.feature.identifier]['value'] = feature.value;
      data['features'][feature.feature.identifier]['count'] = feature.count;
    }
  }

  /**
   * Запись дополнительных параметров поиска в объект данных для запроса
   *
   * @param {OrderDraft} draft
   * @param {any} data
   */
  private static draftExtraToData(draft: OrderDraft, data: any) {
    if(!draft.extra_search_params)
      return;

    data['extra'] = {};

    if(draft.extra_search_params.tariff_tier)
      data['extra']['tier'] = draft.extra_search_params.tariff_tier.identifier;

    data['extra']['lengthGroups'] = draft.extra_search_params.tariff_tier ? draft.extra_search_params.length_groups.join(',') : '';

    for(let prop in EXTRA_SEARCH_MAP) {
      if(EXTRA_SEARCH_MAP.hasOwnProperty(prop))
        data['extra'][prop] = draft.extra_search_params[EXTRA_SEARCH_MAP[prop]];
    }
  }

  private static destinationToData(destination: Destination, city: City|null): any {
    return {
      id: destination.id,
      addr: destination.destination.addr,
      lat: destination.destination.lat,
      lon: destination.destination.lon,
      elevator: destination.elevator,
      lifting: destination.lifting,
      floor: destination.floor,
      loading: destination.loading,
      unloading: destination.unloading,
      unloadingInternetShopOrder: destination.unloading_internet_shop_order,
      requiredAdditionalTime: destination.required_additional_time,
      deliveryTo: destination.delivery_to,
      contactName: destination.contact_name,
      contactPhone: destination.contact_phone,
      contactSendSms: destination.contact_send_sms,
      arrivalTime: destination.arrival_time ? OrderDraftService.convertDate(new Date(destination.arrival_time), city) : null,
      arrivalTimeEnd: destination.arrival_time_end ? OrderDraftService.convertDate(new Date(destination.arrival_time_end), city) : null,
      comment: destination.comment,
      clientLegalEntity: destination.client_legal_entity && destination.client_legal_entity.id || null,
      productLines: destination.delivery_product && destination.delivery_product.lines,
      shipment: destination.delivery_product && {
        length: destination.delivery_product.length,
        width: destination.delivery_product.width,
        height: destination.delivery_product.height,
        weight: destination.delivery_product.weight
      }
    };
  }

  private static deliveryScheduleToData(schedule: DeliverySchedule, city: City|null): any {
    return {
      type: schedule.type,
      start: OrderDraftService.convertDate(new Date(schedule.start), city),
      end: schedule.end != null ? OrderDraftService.convertDate(new Date(schedule.end), city) : null,
      storehouseTime: OrderDraftService.convertDate(new Date(schedule.storehouse_time), city),
      searchHoursBefore: schedule.search_hours_before,
      mode: schedule.mode,
      period: schedule.period,
      weekDays: schedule.week_days,
      day: schedule.day,
      weekPeriod: schedule.week_period,
      weekDay: schedule.week_day
    };
  }

  private static clientToData(client: Account): any {
    if(!client.phone || client.phone === '')
      return null;

    return {
      phone: client.phone,
      name: client.name,
      surname: client.surname,
      patronymic: client.patronymic
    }
  }

  private static freighterClientToData(client: FreighterClient): any {
    return FreighterClientService.clientToData(client);
  }

  private static convertDate(date: Date, city: City|null): string {
    if(city)
      date = DateUtils.offsetTimeByCityTimeZone(date, city);

    return DateUtils.convertDate(date);
  }
}
