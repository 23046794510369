import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../_services/notification.service'
import {TaxiConnection} from "../_websocket/connections/taxi-connection";
import {newTaxiOrder} from "../_websocket/messages/taxi-order";
import {taxiNoAnswer} from '../_websocket/messages/taxi-no-answer'
import {Router} from '@angular/router'
import {Subject} from "rxjs";
import {NotificationEvent} from "../notifications/notification-event";
import {Dictionary} from '../_utils/Dictionary.type'

@Component({
  selector: 'app-new-order-counter',
  templateUrl: './new-order-counter.component.html',
  styleUrls: ['./new-order-counter.component.css']
})
export class NewOrderCounterComponent implements OnInit {

  private newOrders = new Dictionary<Array<number>>();
  private taxiConnection: TaxiConnection;
  ordersCounter: number;
  notificationChangeListener: Subject<NotificationEvent>;
  private isWorking: boolean = false;

  constructor(private _notificationService: NotificationService, private  _router: Router) {
  }

  ngOnInit() {
    this.ordersCounter = 0;
    this.notificationChangeListener = this._notificationService.getListener();
    if (this.isWorking === false) {
      this.initSubscriptions();
    }
  }

  initSubscriptions() {
    this.isWorking = true;
    this.notificationChangeListener.subscribe(message => {
      switch (message.command) {
        case 'clearNewOrdersCounter':
          this.clearOrdersList();
          break;
        case 'removeDraftGroup':
          this.removeDraftGroup(message);
          break;
        case 'setupOrderCounter':
          this.taxiConnection = this._notificationService.getTaxiConnection();
          this.taxiConnection.message.subscribe(
            taxiMessage => {
              this.handleMessage(taxiMessage);
            }
          );
          break;
      }
    });


  }

  refreshOrders() {
    if (this.ordersCounter > 0) {
      switch (this._router.url) {
        case '/calendar':
          this.refreshCalendarView();
          break;
        case '/orders':
          this.refreshListView();
          break;
        default:
          this._router.navigate(['/orders']).then(
            () =>
              this._notificationService.getListener().next({command: 'refreshListView'})
          );
      }
      this.clearOrdersList();
    }
  }

  handleMessage(message) {
    if (message instanceof newTaxiOrder) {
      this.addOrderToDict(message);
    }

    if (message instanceof taxiNoAnswer) {
      this._notificationService.getListener().next({command: 'markAsNoAnswer', orderId: message.orderId})
    }

  }

  addOrderToDict(message) {
    if (message instanceof newTaxiOrder) {
      let draftId = message.draftId.toString();
      let orderId = message.orderId;
      if (!this.newOrders.containsKey(draftId)) {
        this.newOrders.add(draftId, []);
      }
      this.newOrders.get(draftId).push(orderId);
      this.ordersCounter++;
    }

  }

  removeDraftGroup(message) {
    let draftId = message.notice.order.draft.id;
    if (this._notificationService.getNotificationsList().indexOf(message.notice) > -1) {
      let service = this._notificationService;
      setTimeout(function () {
        service.set(message.notice, false);
      }, 3000);
    }
    if (typeof this.newOrders.get(draftId) == 'undefined') {
      return false;
    }
    this.ordersCounter -= this.newOrders.get(draftId).length;
    this.newOrders.remove(draftId.toString());
  }

  refreshListView() {
    this._notificationService.getListener().next({command: 'refreshListView'})
  }

  refreshCalendarView() {
    this._notificationService.getListener().next({command: 'refreshCalendarView'})
  }

  clearOrdersList() {
    this.newOrders.clear();
    this.ordersCounter = 0;
  }

}
