<div *ngIf="order.status == 'agree'">
  <button class="btn btn-warning" type="button" (click)="onDeclineOrder()"><span class="glyphicon glyphicon-remove"></span> Отклонить заказ</button>
  <button class="btn btn-primary" type="button" (click)="onAcceptOrder()"><span class="glyphicon glyphicon-play"></span> Принять заказ</button>
</div>
<div *ngIf="order.draft.delivery_status == 'wait_hand_accept'">
  <button class="btn btn-primary" type="button" (click)="onMoneyReturnAccept()"><span class="glyphicon glyphicon-play"></span> Деньги получены</button>
</div>
<div *ngIf="history.length > 0">
  <h4>Выполнение заказа</h4>
  <ul class="list-group execution">
    <li class="list-group-item" *ngFor="let row of history">
      <span class="time">{{ row.created_at | dt:'H:mm' }}</span>
      {{ row.execution_status | executionStatus }}
    </li>
  </ul>
</div>
