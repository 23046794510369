<div class="list" *ngIf="issues.length > 0">
  <table class="table table-condensed table-responsive table-striped">
    <thead>
    <tr>
      <th>Дата</th>
      <th>Описание</th>
      <th>Автор</th>
      <th *ngIf="productVisible">Заказ</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let issue of issues">
      <td class="date">{{ issue.created_at|dt:"dd.MM.yy HH:mm" }}</td>
      <td>{{ issue.description }}</td>
      <td class="author">
        <div class="name">{{ issue.author|fullName }}</div>
        <div class="phone" *ngIf="issue.author.phone">{{ issue.author.phone|formatPhone }}</div>
      </td>
      <td *ngIf="productVisible">
        {{ issue.delivery_product.code }}
        <delivery-track [track]="track" small *ngFor="let track of issue.delivery_product.tracks"></delivery-track>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div *ngIf="issues.length == 0" class="empty-list">
  <div class="message" *ngIf="loading">Загружается</div>
  <div class="message" *ngIf="!loading">Нет проблем</div>
</div>
