
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {PaymentDistributionSchemaService} from "../_services/payment-distribution-schema.service";
import {LoaderService} from "../_services/loader.service";
import {UserInfoService} from "../_services/user-info.service";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";

@Component({
  selector: 'app-payment-distribution-schemas',
  templateUrl: './payment-distribution-schemas.component.html',
  styleUrls: ['./payment-distribution-schemas.component.css']
})
export class PaymentDistributionSchemasComponent implements OnInit {
  schemas: PaymentDistributionSchema[] = [];


  constructor(private paymentDistributionSchemaService: PaymentDistributionSchemaService,
              private userInfoService: UserInfoService,
              private loaderService: LoaderService) { }

  ngOnInit() {
    this.loadSchemas();
  }

  private loadSchemas(): void {
    this.loaderService.show();
    this.paymentDistributionSchemaService
      .getFreighterPrivateSchemas(this.userInfoService.getFreighter()).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        schemas => this.schemas = schemas,
        () => {}
      )
    ;
  }

  private removeSchema(schema: PaymentDistributionSchema): void {
    this.loaderService.show();
    this.paymentDistributionSchemaService
      .deleteSchema(this.userInfoService.getFreighter(), schema).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadSchemas(),
        () => {}
      )
    ;
  }

  onRemoveSchema(schema: PaymentDistributionSchema): void
  {
    if(confirm("Подтверждаете удаление?"))
      this.removeSchema(schema);
  }
}
