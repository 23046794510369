<form (submit)="onSubmit()">
  <div class="message__tabs-items">
    <div class="message__tabs-item">
      <div class="message__groups">
        <div class="message__group">
          <div class="row">
            <div class="col col-12">
              <div class="form-group">
                <input class="form-control" placeholder="Название рассылки" name="name" [(ngModel)]="message.name" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <div class="form-group">
                <textarea rows="10" class="form-control" placeholder="Текст сообщения" name="text" [(ngModel)]="message.text" required></textarea>
                <p class="help-block">Количество символов: {{ message.text?.length || 0 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="message__btn">
        <div class="row">
          <div class="col col-6">
            <button class="btn btn-primary btn-block message__btn-save" type="submit">
              Отправить
            </button>
          </div>
          <div class="col col-6">
            <button class="message__btn-reset btn-block" type="button" data-dismiss="modal">Отменить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
