import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormErrors} from "../_models/form-errors";

@Component({
  selector: 'input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.css']
})
export class InputErrorComponent implements OnChanges {
  @Input() formErrors: FormErrors;
  @Input() field: string;
  errors: string[];
  private mapping: Object;

  constructor() {
    this.mapping = this.getErrorMapping();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.errors = this.getErrorsByField().map(msg => this.mapping[msg] || msg);
  }

  private getErrorsByField(): string[] {
    if(!this.formErrors || !this.formErrors.errors || !this.formErrors.errors.children)
      return [];

    let fieldDefine = this.field.split('.');
    let errors: any = this.formErrors.errors;

    for(let n = 0; n < fieldDefine.length; n ++) {
      if(!errors.children[fieldDefine[n]])
        return [];

      errors = errors.children[fieldDefine[n]];
      if(!errors)
        return [];
    }

    return errors.errors || [];
  }

  protected getErrorMapping(): Object {
    return {
      bad_email: 'Неверный e-mail',
      user_exists: 'Такой пользователь уже есть',
      need_phone_or_email: 'Требуется указать e-mail или телефон'
    }
  }
}
