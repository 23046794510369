
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Freighter} from "../_models/freighter";
import {Editable} from "../_models/editable";
import {FreighterClient} from "../_models/freighter-client";
import {LoaderService} from "../_services/loader.service";
import {AlertService} from "../_services/alert.service";
import {FreighterClientService} from "../_services/freighter-client.service";
import {HttpErrorResponse} from "@angular/common/http";
import {FormErrors} from "../_models/form-errors";

@Component({
  selector: 'freighter-client-form',
  templateUrl: './freighter-client-form.component.html',
  styleUrls: ['./freighter-client-form.component.css']
})
export class FreighterClientFormComponent implements OnInit {
  @Input() freighter: Freighter;
  @Input() client: Editable<FreighterClient>;

  @Output() back = new EventEmitter<void>();
  @Output() saved = new EventEmitter<FreighterClient>();

  constructor(
    private freighterClientService: FreighterClientService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
  }

  private add(): void {
    this.loaderService.show();
    this.client.isSubmitting = true;

    this.freighterClientService
      .addClient(this.freighter, this.client.record).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.client.isSubmitting = false;
      }))
      .subscribe(
        r => {
          this.alertService.success(`Клиент "${this.client.record.name}" добавлен`, true);
          this.client.record.id = +r.body.id;
          this.onSaved();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.client.errors = r.error as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  private update(): void {
    this.loaderService.show();
    this.client.isSubmitting = true;

    this.freighterClientService
      .updateClient(this.freighter, this.client.record).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.client.isSubmitting = false;
      }))
      .subscribe(
        () => {
          this.alertService.success(`Клиент "${this.client.record.name}" изменён`, true);
          this.onSaved();
        },
        r => {
          if(r instanceof HttpErrorResponse && r.status === 400) {
            this.client.errors = r.error  as FormErrors;
            this.alertService.error('Не все данные заполнены корректно');
          }
        }
      );
  }

  onSubmit(): void {
    if(this.client.isNew)
      this.add();
    else
      this.update();
  }

  onBack(): void {
    this.back.emit();
  }

  private onSaved(): void {
    this.saved.emit(this.client.record);
  }
}
