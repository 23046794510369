
import {finalize} from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {OptimizationTask} from "../_models/optimization-task";
import {OptimizationTaskDestination} from "../_models/optimization-task-destination";
import {ComplexDeliveryFormRowComponent} from "../complex-delivery-form-row/complex-delivery-form-row.component";
import {List} from "../pager/list";
import {Page} from "../pager/page";
import {ComplexDeliveryForm} from "./complex-delivery-form";
import {OptimizationTaskService} from "../_services/optimization-task.service";
import {LoaderService} from "../_services/loader.service";
import {UserInfoService} from "../_services/user-info.service";
import {DateTime} from "date-time-js";

const DESTINATIONS_PAGE_SIZE = 5;
const DEFAULT_SLOT_BEGIN = '12:00';
const DEFAULT_SLOT_END = '18:00';

class DestinationsList implements List {
  constructor(
    private _destinations: OptimizationTaskDestination[] = [],
    private originalNumbers: number[] = [],
    public page: number = 0,
    public totalCount: number = 0,
    public pageSize: number = 0
  ) {
  }

  getOriginalNumber(index: number): number {
    return this.originalNumbers[index];
  }

  get destinations(): OptimizationTaskDestination[] {
    return this._destinations;
  }

  static createFromDestinationsArray(destinations: OptimizationTaskDestination[] = [], page: number = 0, totalCount: number = 0, pageSize: number = 0): DestinationsList {
    let originalNumbers: number[] = [];
    for(let n = page * pageSize; n < page * pageSize + pageSize; n ++)
      originalNumbers.push(n);

    return new DestinationsList(destinations, originalNumbers, page, totalCount, pageSize);
  }
}

@Component({
  selector: 'complex-delivery-form',
  templateUrl: './complex-delivery-form.component.html',
  styleUrls: ['./complex-delivery-form.component.css']
})
export class ComplexDeliveryFormComponent implements OnInit, OnChanges, ComplexDeliveryForm {
  @Input() optimizationTask: OptimizationTask;
  @Input() destinationId: number;
  @Input() saveEvent: EventEmitter<void>;
  @Input() registerFormEvent: EventEmitter<ComplexDeliveryForm>;
  @Input() geoContext: string;
  @Input() storehouseId: number;
  @Output() saved = new EventEmitter<number>();
  @Output() rowUpdated = new EventEmitter<OptimizationTaskDestination>();
  @Output() taskChanged = new EventEmitter<OptimizationTask>();
  @ViewChildren(ComplexDeliveryFormRowComponent) rowComponents: QueryList<ComplexDeliveryFormRowComponent>;

  editable = false;
  executed = false;

  destinationsList = new DestinationsList();
  destinationsHasPages = false;

  isSlotsEnabled = false;
  isAssemblyEnabled = false;

  private destinationsToRemove: OptimizationTaskDestination[] = [];

  constructor(
    private optimizationTaskService: OptimizationTaskService,
    private loaderService: LoaderService,
    private userService: UserInfoService
  ) { }

  ngOnInit() {
    this.registerForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.executed = this.optimizationTask.status == 'executed';
    this.editable = !this.executed && !this.optimizationTask.imported_from;
    this.isAssemblyEnabled =  this.userService.isAvailableRouteOptimization();
    this.initSlots();
    this.cleanDestinationsToRemove();
    this.initDestinationsList(this.destinationsList.page);
  }

  private initSlots(): void {
    if(this.userService.isAvailableRouteOptimization()) {
      this.isSlotsEnabled = true;
    } else {
      this.isSlotsEnabled = false;
      if(!this.optimizationTask.delivery_schema?.is_express) {
        let dateStr = new DateTime().format('yyyy-MM-dd');
        let timeSlotBeginStr = `${dateStr}T${DEFAULT_SLOT_BEGIN}`;
        let timeSlotEndStr = `${dateStr}T${DEFAULT_SLOT_END}`;
        this.optimizationTask.destinations.forEach(d => {
          d.time_slot_begin = timeSlotBeginStr;
          d.time_slot_end = timeSlotEndStr;
        });
      }
    }
  }

  private cleanDestinationsToRemove(): void {
    this.destinationsToRemove = [];
    console.log('cleaned list of destinations for removing');
  }

  private initDestinationsList(page: number = 0): void {
    if(this.optimizationTask.destinations.length > 20 && !this.destinationId) {
      let pageOffset = page * DESTINATIONS_PAGE_SIZE;
      this.destinationsList = DestinationsList.createFromDestinationsArray(
        this.optimizationTask.destinations.slice(pageOffset, pageOffset + DESTINATIONS_PAGE_SIZE),
        page,
        this.optimizationTask.destinations.length,
        DESTINATIONS_PAGE_SIZE
      );
      this.destinationsHasPages = true;
    } else {
      this.destinationsList = DestinationsList.createFromDestinationsArray(
        this.optimizationTask.destinations,
        page,
        this.optimizationTask.destinations.length,
        this.optimizationTask.destinations.length
      );
      this.destinationsHasPages = false;
    }
  }

  private registerForm() {
    if(this.registerFormEvent != null)
      this.registerFormEvent.emit(this);
  }

  private removeRow(index: number) {
    let removed = this.optimizationTask.destinations.splice(index, 1);
    this.destinationsToRemove.push(...removed);
    console.log('scheduled to remove', removed);
    this.initDestinationsList(this.destinationsList.page);
  }

  validate(): boolean {
    // TODO: нужно переделать валидацию
    // Исходный вариант был рассчитан на то, что все строки будут отображаться разом.
    // После введения постраничного листания данные не будут проверяться, если они окажутся на неактивной странице.

    if(!this.rowComponents)
      return true;

    return !this.rowComponents.some(r => !r.validate());
  }

  validateForCalculation(): boolean {
    if(!this.rowComponents)
      return false;

    return !this.rowComponents.some(r => !r.validateForCalculation());
  }

  submit(): void {
    if(this.optimizationTask.id) {
      if(this.editable)
        this.updateTask();
      else if(!this.executed)
        this.removeDestinations();
    } else {
      this.addTask();
    }
  }

  private updateTask(): void {
    console.log('updating task');

    this.loaderService.show();
    this.optimizationTaskService
      .editTask(this.optimizationTask).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => this.saved.emit(this.optimizationTask.id),
        error: () => {}
      })
    ;
  }

  private removeDestinations(): void {
    if(this.destinationsToRemove.length == 0) {
      this.saved.emit(this.optimizationTask.id);
      return;
    }

    console.log('removing scheduled destinations', this.destinationsToRemove);

    this.loaderService.show();
    this.optimizationTaskService
      .removeTaskDestinations(...this.destinationsToRemove).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => {
          this.cleanDestinationsToRemove();
          this.saved.emit(this.optimizationTask.id)
        },
        error: () => {}
      })
    ;
  }

  private addTask(): void {
    this.loaderService.show();
    this.optimizationTaskService
      .addTask(this.optimizationTask).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe({
        next: id => this.saved.emit(id),
        error: () => {}
      })
    ;
  }

  onAddDestination() {
    this.optimizationTask.destinations.push(new OptimizationTaskDestination());
    this.initSlots();
    this.initDestinationsList(this.destinationsList.page);
  }

  onRemoveRow(index: number) {
    this.removeRow(index);
    this.taskChanged.emit(this.optimizationTask);
  }

  onUpdateRow(destination: OptimizationTaskDestination): void {
    this.rowUpdated.emit(destination);
    this.taskChanged.emit(this.optimizationTask);
  }

  onChangePage(page: Page) {
    this.initDestinationsList(page.num);
  }
}
