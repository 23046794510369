import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";
import {DeliverySchedule} from "../_models/delivery-schedule";
import {DateUtils} from "../_utils/date-utils";

class Day {
  constructor(private _name: string, private _selected: boolean = false) {
  }

  get name(): string {
    return this._name;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }
}

@Component({
  selector: 'draft-schedule-editor',
  templateUrl: './draft-schedule-editor.component.html',
  styleUrls: ['./draft-schedule-editor.component.css']
})
export class DraftScheduleEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Input() isTimeOffsetRequired: boolean;
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  schedule: DeliverySchedule;
  deliveryStorehouseArrival: Date;
  start: Date;
  end: Date;
  type: string = 'once';
  storehouseTime: Date;
  everyday = {
    mode: 'weekdays',
    period: 1,
    periods: []
  };
  everyWeek = {
    period: 1,
    periods: [],
    weekDays: [ new Day('Пн', true), new Day('Вт'), new Day('Ср'), new Day('Чт'), new Day('Пт'), new Day('Сб'), new Day('Вс') ]
  };
  everyMonth = {
    period: 1,
    periods: [],
    mode: 'by_date',
    day: 1,
    days: [],
    weekPeriod: 1,
    weekPeriods: [],
    weekDay: 1
  };
  searchHoursBefore = 1;
  searchHours = [];

  datePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru'
  };
  startDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'Начало'
  };
  endDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'Окончание'
  };
  timePickerOptions = {
    showMeridian: false,
    defaultTime: false
  };
  storeHouseTimePickerOptions = {
    showMeridian: false,
    defaultTime: false,
    label: 'Прибытие на склад'
  };

  constructor() {
    for(let i = 1; i <= 14; i ++)
      this.everyday.periods.push(i);

    for(let i = 1; i <= 24; i ++)
      this.searchHours.push(i);

    for(let i = 1; i <= 8; i ++)
      this.everyWeek.periods.push(i);

    for(let i = 1; i <= 8; i ++)
      this.everyMonth.periods.push(i);

    for(let i = 1; i <= 31; i ++)
      this.everyMonth.days.push(i);

    for(let i = 1; i <= 5; i ++)
      this.everyMonth.weekPeriods.push(i);
  }

  ngOnInit() {
    this.deliveryStorehouseArrival = this.draft.delivery_storehouse_arrival
      ? new Date(this.draft.delivery_storehouse_arrival)
      : new Date();

    this.initSchedule();
  }

  private initSchedule() {
    if(this.draft.delivery_schedule == null) {
      this.schedule = new DeliverySchedule();
      this.type = 'once';
    } else {
      this.schedule = this.draft.delivery_schedule;
      this.initCommonParamsOfSchedule();
      switch(this.type) {
        case 'once':
          this.initOnceSchedule();
          break;
        case 'everyday':
          this.initEverydaySchedule();
          break;
        case 'every_week':
          this.initEveryWeekSchedule();
          break;
        case 'every_month':
          this.initEveryMonthSchedule();
          break;
      }
    }
  }

  private initCommonParamsOfSchedule() {
    this.type = this.draft.delivery_schedule.type;
    this.start = this.schedule.start && DateUtils.offsetTimeByCityTimeZone(new Date(this.schedule.start), this.draft.city);
    this.end = this.schedule.end && DateUtils.offsetTimeByCityTimeZone(new Date(this.schedule.end), this.draft.city);
    this.storehouseTime = DateUtils.offsetTimeByCityTimeZone(new Date(this.schedule.storehouse_time), this.draft.city);
    this.searchHoursBefore = this.schedule.search_hours_before;
  }

  private initOnceSchedule() {

  }

  private initEverydaySchedule() {
    this.everyday.mode = this.schedule.mode || 'weekdays';
    this.everyday.period = this.schedule.period || 1;
  }

  private initEveryWeekSchedule() {
    this.everyWeek.period = this.schedule.period || 1;

    let selectedDays = DraftScheduleEditorComponent.weekDaysBitsToArray(this.schedule.week_days || 1);
    for(let i = 0; i < selectedDays.length; i ++)
      this.everyWeek.weekDays[i].selected = selectedDays[i];
  }

  private initEveryMonthSchedule() {
    this.everyMonth.period = this.schedule.period || 1;
    this.everyMonth.mode = this.schedule.mode || 'by_date';
    this.everyMonth.day = this.schedule.day || 1;
    this.everyMonth.weekPeriod = this.schedule.week_period || 1;
    this.everyMonth.weekDay = this.schedule.week_day || 1;
  }

  private prepareScheduleToSave() {
    this.prepareCommonScheduleParamsToSave();
    switch(this.type) {
      case 'once':
        this.prepareOnceScheduleToSave();
        break;
      case 'everyday':
        this.prepareEverydayScheduleToSave();
        break;
      case 'every_week':
        this.prepareEveryWeekScheduleToSave();
        break;
      case 'every_month':
        this.prepareEveryMonthScheduleToSave();
        break;
    }
  }

  private prepareCommonScheduleParamsToSave() {
    console.log(this.schedule.start);
    console.log(this.schedule.storehouse_time);

    this.schedule.type = this.type;
    this.schedule.start = this.start && DateUtils.offsetTimeByCityTimeZone(this.start, this.draft.city, -1).toString();
    this.schedule.end = this.end && DateUtils.offsetTimeByCityTimeZone(this.end, this.draft.city, -1).toString();
    this.schedule.storehouse_time = this.storehouseTime && DateUtils.offsetTimeByCityTimeZone(this.storehouseTime, this.draft.city, -1).toString();
    this.schedule.search_hours_before = this.searchHoursBefore;
  }

  private prepareOnceScheduleToSave() {
    this.schedule.end = null;
  }

  private prepareEverydayScheduleToSave() {
    this.schedule.mode = this.everyday.mode;
    this.schedule.period = this.everyday.period;
  }

  private prepareEveryWeekScheduleToSave() {
    this.schedule.period = this.everyWeek.period;
    this.schedule.week_days = DraftScheduleEditorComponent.daysToWeekDaysBits(this.everyWeek.weekDays);
  }

  private prepareEveryMonthScheduleToSave() {
    this.schedule.period = this.everyMonth.period;
    this.schedule.mode = this.everyMonth.mode;
    this.schedule.day = this.everyMonth.day;
    this.schedule.week_period = this.everyMonth.weekPeriod;
    this.schedule.week_day = this.everyMonth.weekDay;
  }

  onSave() {
    this.draft.delivery_storehouse_arrival = this.deliveryStorehouseArrival ? this.deliveryStorehouseArrival.toString() : null;

    this.prepareScheduleToSave();
    this.draft.delivery_schedule = this.schedule;

    console.log(this.schedule);

    this.onUpdated.emit(this.draft);
  }

  private static weekDaysBitsToArray(value: number): boolean[] {
    let result: boolean[] = [];
    let offsets = [ 6, 0, 1, 2, 3, 4, 5 ];
    let mask = 1;

    for(let i = 0; i < 7; i ++) {
      result[offsets[i]] = (value & mask) != 0;
      mask <<= 1;
    }

    return result;
  }

  private static daysToWeekDaysBits(days: Day[]): number {
    let bits = [
      0b10,
      0b100,
      0b1000,
      0b10000,
      0b100000,
      0b1000000,
      0b1
    ];

    let result = 0;

    for(let i = 0; i < days.length; i ++) {
      if(days[i].selected)
        result |= bits[i];
    }

    return result;
  }
}
