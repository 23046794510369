import {Transport} from "./transport";
import {Employer} from "./employer";
import {Order} from "./order";
import {TariffTier} from "./tariff-tier";
import {Freighter} from "./freighter";
import {PaymentDistributionSchema} from "./payment-distribution-schema";

export class Crew {
  id: number;
  loaders: number;
  self_loader: boolean;
  taxi: boolean;
  start_date: string;
  end_date: string;
  lat: number;
  lon: number;
  last_point_at: string;
  online_at: string;
  created_at: string;
  transport: Transport;
  employer: Employer;
  orders: Order[];
  tariff_tiers: TariffTier[];
  freighter: Freighter;
  payment_distribution_schema: PaymentDistributionSchema;
}
