import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FreighterTransportTariff} from "../_models/freighter-transport-tariff";

@Component({
  selector: 'transport-tariff-form',
  templateUrl: './transport-tariff-form.component.html',
  styleUrls: ['./transport-tariff-form.component.css']
})
export class TransportTariffFormComponent implements OnInit {
  @Input() tariff: FreighterTransportTariff;
  @Output() onSubmit = new EventEmitter<FreighterTransportTariff>();

  constructor() { }

  ngOnInit() {
  }

  onSave() {
    this.onSubmit.emit(this.tariff);
  }
}
