<div class="form-group">
  <label>Выберите товар</label>
  <select class="form-control" [(ngModel)]="productId" name="productId" (ngModelChange)="onChangeProductId()">
    <option *ngFor="let s of products" [value]="s.id">{{ s.name }}</option>
  </select>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="form-group" [class.has-error]="!productCode || productCode == ''">
      <label>Артикул</label>
      <input type="text" class="form-control" name="productCode" [(ngModel)]="productCode">
    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group" [class.has-error]="!productName || productName == ''">
      <label>Наименование товара</label>
      <input type="text" class="form-control" name="productName" [(ngModel)]="productName">
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="form-group">
      <label>Цена для клиента</label>
      <div class="input-group">
        <div class="input-group-addon">₽</div>
        <input type="text" class="form-control" name="priceForClient" [(ngModel)]="priceForClient">
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label>Цена для водителя</label>
      <div class="input-group">
        <div class="input-group-addon">₽</div>
        <input type="text" class="form-control" name="priceForDriver" [(ngModel)]="priceForDriver">
      </div>
    </div>
  </div>
</div>
<div class="form-group" [class.has-error]="productsCount < 1">
  <label>Количество</label>
  <input type="number" class="form-control" name="productsCount" [(ngModel)]="productsCount">
</div>
