<div class="schema-form-container">
  <h1 *ngIf="schema.isNew">Новая схема</h1>
  <h1 *ngIf="!schema.isNew">{{ schema.record.name }}</h1>
  <form (submit)="onSubmit()">
    <div class="form-group">
      <label for="name">Наименование схемы</label>
      <input type="text" class="form-control" id="name" [(ngModel)]="schema.record.name" name="name" required>
      <input-error [formErrors]="schema.errors" field="name"></input-error>
    </div>
    <div class="form-group">
      <label for="schemaType">Тип схемы</label>
      <select class="form-control" [(ngModel)]="schema.record.schema_type" name="schemaType" id="schemaType" required>
        <option *ngFor="let s of schemaTypes" [value]="s.identifier">{{ s.name }}</option>
      </select>
      <input-error [formErrors]="schema.errors" field="schemaType"></input-error>
    </div>
    <div class="form-group">
      <label for="executorType">Тип исполнителя</label>
      <select class="form-control" [(ngModel)]="schema.record.executor_type" name="executorType" id="executorType" required>
        <option *ngFor="let s of executorTypes|mapToIterable" [value]="s.key">{{ s.val }}</option>
      </select>
      <input-error [formErrors]="schema.errors" field="executorType"></input-error>
    </div>
    <div class="form-group">
      <label class="checkbox-inline">
        <input type="checkbox" [(ngModel)]="schema.record.default_schema" name="defaultSchema"> Схема по умолчанию для указанного типа исполнителя
      </label>

    </div>
    <div class="schema-controls">
      <button type="button" class="btn btn-default" (click)="onBack()">&lt; К списку</button>
      <button type="submit" class="btn btn-primary" [disabled]="schema.isSubmitting">Сохранить</button>
    </div>
  </form>
</div>
