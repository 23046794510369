<div class="notice-box class-{{ item.noticeClass }}"
     [class.nb-accept-animation]="item.ajax"
     [class.nb-ajax-done]="item.ajaxDone"
     [class.nb-status-error]="item.statusError"
     [class.nb-no-answer]="item.noAnswer"
     [class.hidden-box]="hidden"
>
  <div class="notice-title" *ngIf="item.title" (dblclick)="remove()">
    <div>{{item.title}}</div>
    <div>
      {{item.dateCreated | dt:"HH:mm"}}
      <span class="glyphicon glyphicon-remove close-button" title="Закрыть" (click)="remove()"></span>
    </div>
  </div>
  <div *ngIf="item.type.name == 'taxi' && item.order">
  <div class="driver-row">
    <div class="driver-avatar" *ngIf="item.order.periods[0].employer.account.picture"><img class="driver-img" [attr.src]="item.order.periods[0].employer.account.picture" alt=""></div>
    <div class="driver-data">{{item.order.periods[0].employer.account.name}} {{item.order.periods[0].employer.account.surname}}</div>
  </div>
    <div class="driver-row">
      <div class="transport-row">
        {{item.order.periods[0].transport.car_model}}, {{item.order.periods[0].transport.features.Tip_kuzova}}, {{item.order.periods[0].transport.features.Dlina}} м,
        <span *ngIf="item.order.periods[0].transport.features.Razreshen_v_ezd_v_TTK == true">TTK, </span>{{item.order.periods[0].transport.features.number}}
      </div>
    </div>
  </div>
  <div *ngIf="item.type.name == 'taxi' && item.draft">
    <div class="draft-row">
      <div class="client-avatar" *ngIf="item.draft.client.picture"><img class="client-img" [attr.src]="item.draft.client.picture" alt=""></div>
      <div class="client-data">
        <div>{{ item.draft.client|fullName }}</div>
        <div *ngIf="item.draft.client.company_client">[{{ item.draft.client.company_client.name }}]</div>
      </div>
    </div>
  </div>
  <div *ngIf="item.account">
    <div class="draft-row">
      <div class="client-avatar" *ngIf="item.account.picture"><img class="client-img" [attr.src]="item.account.picture" alt=""></div>
      <div class="client-data">
        <div>{{ item.account|fullName }}</div>
        <div *ngIf="item.account.company_client">[{{ item.account.company_client.name }}]</div>
      </div>
    </div>
  </div>
  <div *ngIf="item.phone">
    <div class="draft-row">
      <div class="client-data">
        <div>{{ item.phone|formatPhone }}</div>
      </div>
    </div>
  </div>
  <div class="notice-body" *ngIf="item.bodyText" [innerHTML]="item.bodyText" [className]="item.bodyClass">

  </div>
  <div class="notice-buttons" *ngIf="!item.statusError && !item.noAnswer">
    <div *ngFor="let btn of item.buttons">

      <button
        *ngIf="btn.type =='router'"
        (click)="onClickRouteButton(btn.link)"
        class="notify-link">
        {{btn.text}}
      </button>

      <button
        *ngIf="btn.type =='external'"
        [attr.href]="btn.link"
        class="notify-link">
        {{btn.text}}
      </button>

      <button
        *ngIf="btn.type == 'acceptOrder' "
        (click)="acceptOrder($event,item.orderId,item.freighterId)"
        class="notify-link accept-btn"
      >
        {{ btn.text}}
      </button>
      <button
        *ngIf="btn.type == 'declineOrder' "
        (click)="acceptOrder($event,item.orderId,item.freighterId)"
        class="notify-link decline-btn"
      >
        {{ btn.text}}
      </button>
      <button
        *ngIf="btn.type == 'call'"
        class="notify-link"
        (click)="onCall(btn.phone)"
        >{{ btn.text}}</button>
    </div>
  </div>
</div>
