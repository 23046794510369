<div class="detail-container" *ngIf="task">
  <div class="delivery-content">
    <div class="panel panel-default delivery-detail no-bottom-delivery">
      <div class="panel-heading meta-heading">
        <div class="flex-centered back-box"><a routerLink="/complex-deliveries" class="flex-centered" role="button" title="Назад к списку маршрутизаций">
          <i class="material-icons nav-icon">keyboard_arrow_left
          </i>
        </a>
        </div>
        <div class="main-controls">
          <span class="delivery-title">
            Заказ <strong>#{{ task.id }}</strong>
            <span *ngIf="task.city" class="city">
              (<span class="city-name">{{ task.city.name }}</span><span class="city-time-zone" *ngIf="!(task.city|myTz)">{{ task.city|cityTz }}</span>)
            </span>
            <br>
            на {{ task.date | dt:'dd.MM.yy' }}
            {{ task.optimization_type | optimizationType }}
          </span>
          <button type="button" class="edit-btn" (click)="onEditTask()" [disabled]="updatingType">
            <span *ngIf="task.status != 'executed'">Изменить</span>
            <span *ngIf="task.status == 'executed'">Просмотр</span>
          </button>
          <button type="button" class="save-btn" (click)="onExecute()" [disabled]="!isExecutionEnabled" *ngIf="isExecutionVisible">Запуск подбора</button>
        </div>
      </div>
      <div class="panel-body meta-body">
        <ng-scrollbar autoHeightDisabled="false">
        <div class="panel-body">
          <div class="panel-row">
            <div class="pr-title">
              Схема доставки
            </div>
          </div>
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body">
                {{ task.delivery_schema?.name }}
                <span *ngIf="!task.delivery_schema?.is_multipoint_allowed">(только один адрес доставки)</span>
<!--                <select class="form-control" name="deliverySchema" [(ngModel)]="deliverySchemaId" (ngModelChange)="onSelectDeliverySchema()" disabled>-->
<!--                  <option *ngFor="let schema of deliverySchemas" [value]="schema.id" [disabled]="isMultiPoint && !schema.is_multipoint_allowed">-->
<!--                    {{ schema.name }}<span *ngIf="!schema.is_multipoint_allowed"> (только один адрес доставки)</span>-->
<!--                  </option>-->
<!--                </select>-->
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body" *ngIf="isAllowedRouteOptimization">
          <div class="panel-row">
            <div class="pr-title">
              Статус
            </div>
          </div>
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body">
                <div>
                  {{ task.status | optimizationStatus }}
                  <span *ngIf="task.status == 'wait_calc' || task.status == 'calculating'">(<a (click)="onReload()" class="reload-link">обновить</a>; ожидание расчёта - {{ timerString }})</span>
                </div>
                <div *ngIf="task.status == 'calculating'" [ngSwitch]="task.optimization_stage" class="optimization-stage">
                  <div *ngIf="task.optimization_stage" [ngSwitch]="task.optimization_stage">
                    <span *ngSwitchCase="'prepare_moving_table'">Построение таблицы перемещений ({{ (task.optimization_progress || 0)|round }}%)...</span>
                    <span *ngSwitchCase="'optimization'">
                      Поиск оптимальных маршрутов
                      ({{ (task.optimization_progress || 0)|round }}%<span *ngIf="task.optimization_estimated_complete_time">;
                      время окончания: ~{{ task.optimization_estimated_complete_time|dt:'H:mm' }}</span>)...
                    </span>
                  </div>
                </div>
                <div *ngIf="task.status != 'success' && task.status != 'executed' && task.error_text">
                  <pre>{{ task.error_text }}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body" *ngIf="isAllowedRouteOptimization && ['executed', 'calculating', 'wait_calc'].indexOf(task.status) == -1">
          <div class="panel-row">
            <div class="br-box panel-click">
              <div class="pr-body">
                <div class="optimization-controls">
                  <div>
                    <button type="button" class="save-btn request-calc-btn" (click)="onRequestCalc()" [disabled]="updatingType || updatingConnectingOfNotOptimizedPoints || task.destinations.length < 2">Выполнить оптимизацию</button>
                  </div>
                  <div class="type">
                    <div class="types-list">
                      <div class="radio"><label><input type="radio" (ngModelChange)="onChangeOptimizationType('from_storehouse')" name="optimization_type" value="from_storehouse" [(ngModel)]="task.optimization_type"> со склада</label></div>
                      <div class="radio"><label><input type="radio" (ngModelChange)="onChangeOptimizationType('to_storehouse')" name="optimization_type" value="to_storehouse" [(ngModel)]="task.optimization_type"> на склад</label></div>
                    </div>
                  </div>
                  <div class="other">
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="connect_not_optimized_points"
                          [(ngModel)]="task.connect_not_optimized_points"
                          [disabled]="updatingConnectingOfNotOptimizedPoints"
                          (ngModelChange)="onUpdateConnectingOfNotOptimizedPoints()"
                        > объединять выпавшие точки
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="lock_for_external"
                          [(ngModel)]="task.lock_for_external"
                          (ngModelChange)="onUpdateLockForExternal()"
                        > прекратить приём точек
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body" (mouseenter)="onMouseEnterTotal()">
          <div class="panel-row" [class.panel-click]="task.transports && task.transports.length != 0" (click)="onSwitchLoadedDataVisibility()">
            <div class="pr-title">
              Адреса для доставки
            </div>
            <div *ngIf="task.transports && task.transports.length != 0">
              <span class="glyphicon hider"
                    [class.glyphicon-chevron-down]="loadedDataVisible"
                    [class.glyphicon-chevron-up]="!loadedDataVisible"
                    ></span>
            </div>
          </div>
          <div *ngIf="loadedDataVisible">
            <div class="panel-row">
              <div class="br-box">
                <div class="pr-body">
                  <div class="fail-destination" *ngIf="task.fail_destinations && task.fail_destinations.length > 0">
                    Не принято - {{ task.fail_destinations.length }}.
                  </div>
                  <ul>
                    <li class="fail-destination" *ngFor="let destination of task.fail_destinations">
                      <div>
                        <strong>
                          <a *ngIf="destination.external_link" [href]="destination.external_link" target="_blank">
                            <span class="order-code" *ngIf="destination.order_code">[{{ destination.order_code }}] -</span>
                            {{ destination.description }}
                          </a>
                          <span *ngIf="!destination.external_link">
                            <span class="order-code" *ngIf="destination.order_code">[{{ destination.order_code }}] -</span>
                            {{ destination.description }}
                          </span>
                        </strong>
                      </div>
                    </li>
                  </ul>
                  <div>Принято - {{ task.destinations.length - 1 }}.</div>
                  <ol>
                    <li
                      class="destination"
                      *ngFor="let destination of destinationsList.destinations; let i = index;"
                      [value]="destination.listPos"
                      (mouseover)="onHoverDestination(destination.id)"
                      (click)="onEditDestination(destination.id)"
                    >
                      <div>
                        <span class="glyphicon glyphicon-ok delivered" title="Доставлено" *ngIf="destination.delivered"></span>
                        <span class="glyphicon glyphicon-remove undelivered" title="Не доставлено" *ngIf="destination.undelivered"></span>
                        <strong>
                          <span class="order-code" *ngIf="destination.order_code">[{{ destination.order_code }}] -</span>
                          {{ destination.time_slot_begin | dt:'H:mm' }}-{{ destination.time_slot_end | dt:'H:mm' }}
                          - {{ destination.destination.addr }}
                        </strong>
                      </div>
                    </li>
                  </ol>
                  <pager [list]="destinationsList" [hideIfNoPages]="true" (onPage)="onChangeDestinationsPage($event)"></pager>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="panel-body" (mouseenter)="onMouseEnterTotal()" *ngIf="task.transports.length > 1">
            <div class="panel-row">
              <div class="pr-title">
                Итоговые данные
              </div>
            </div>
            <div class="panel-row">
              <div class="br-box panel-click">
                <div class="pr-body">
                  <div *ngIf="task.transports.length > 0">Количество маршрутов - {{ task.transports.length }}.</div>
                  <div *ngIf="singlePathsCount > 0">Количество одиночных маршрутов - {{ singlePathsCount }}.</div>
                  <div *ngIf="weakPointsCount > 0">Количество выпавших точек - {{ weakPointsCount }}.</div>
                  <div>Стоимость всех маршрутов - {{ task.cost }}р.</div>
                  <div *ngIf="task.status == 'executed'" class="search-stat">
                    <div *ngIf="task.transports.length > 0">Количество машин - {{ searchStat.transportsCount }}.</div>
                    <div
                      [class.zero]="searchStat.foundTransports == 0"
                      [class.not-completed]="searchStat.foundTransports != searchStat.transportsCount && searchStat.foundTransports > 0"
                      [class.completed]="searchStat.foundTransports == searchStat.transportsCount"
                    >Подобрано перевозчиков - {{ searchStat.foundTransports }}.</div>
                    <div
                      [class.zero]="searchStat.completed == 0"
                      [class.not-completed]="searchStat.completed != searchStat.transportsCount && searchStat.completed > 0"
                      [class.completed]="searchStat.completed == searchStat.transportsCount"
                    >Завершено рейсов - {{ searchStat.completed }}.</div>
                    <div>Стоимость перевозки - {{ searchStat.cost }}р.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-body"  *ngIf="task.transports.length > 1 || isTransportsEditable">
            <div class="panel-row" *ngIf="task.transports.length > 1">
              <form class="filter">
                <div class="pr-title transports-controls">
                  <div class="roll-all">
                    <span class="roll-control" (click)="rollAllTransports(!rolledUpAll)">
                      <span *ngIf="rolledUpAll">Развернуть всё</span>
                      <span *ngIf="!rolledUpAll">Свернуть всё</span>
                    </span>
                  </div>
                  <div *ngIf="task.status == 'executed'">
                    <label><input type="checkbox" [(ngModel)]="showStopped" name="showStopped" (ngModelChange)="onUpdateTransportsFilter()"> остановлен</label>
                  </div>
                  <div *ngIf="task.status == 'executed'">
                    <label><input type="checkbox" [(ngModel)]="showInSearch" name="showInSearch" (ngModelChange)="onUpdateTransportsFilter()"> поиск</label>
                  </div>
                  <div *ngIf="task.status == 'executed'">
                    <label><input type="checkbox" [(ngModel)]="showInExecuting" name="showInExecuting" (ngModelChange)="onUpdateTransportsFilter()"> выполняется</label>
                  </div>
                  <div *ngIf="task.status == 'executed'">
                    <label><input type="checkbox" [(ngModel)]="showCompleted" name="showCompleted" (ngModelChange)="onUpdateTransportsFilter()"> выполнен</label>
                  </div>
                </div>
              </form>
            </div>
            <div class="panel-row" *ngIf="isTransportsEditable">
              <div class="actions-container">
                <div class="actions">
                  <div class="action" (click)="onAddTransport()">добавить рейс</div>
                </div>
              </div>
            </div>
          </div>
          <form>
          <div *ngFor="let transport of task.transports; let i = index;">
            <div class="panel-body" (mouseenter)="onMouseEnterToTransport(i)" [class.hidden]="!isVisible(transport)">
            <div class="panel-row">
              <div class="pr-title">
                <div class="transport">
                  <div class="params">
                    <label class="transport-name">
                      <span class="selector" *ngIf="isTransportSelectable(transport)">
                        <input
                          type="checkbox"
                          [ngModel]="isTransportSelected(transport)"
                          (ngModelChange)="onChangeTransportSelection(transport, $event)"
                          [name]="'transport' + transport.id"
                          [id]="'transport' + transport.id"
                        >
                      </span>
                      <span>
                          Рейс [{{ i + 1 }}]
                        <span class="weak-path glyphicon glyphicon-exclamation-sign" title="маршрут из выпавших точек" *ngIf="transport.used_weak_path"></span>
                        <span *ngIf="transport.created_manually">
                          - <span title="минимальные размеры машины (ДхШхВ)">{{ (transport.body_length || 0) / 100 }} м x {{ (transport.body_width || 0) / 100 }} м x {{ (transport.body_height || 0) / 100 }} м</span>
                          / <span title="минимальный объём">{{ ((transport.body_volume || 0) / 1000000) | humanNumber }} м<sup>3</sup></span>
                          / <span title="минимальная грузоподъемность">{{ ((transport.min_capacity || 0) / 1000) | humanNumber }} т</span>
                          <span *ngIf="transportWithActiveTierSelector == transport.id">/
                            <select name="tier" [(ngModel)]="selectedTierId" (ngModelChange)="onSelectedTransportTier(transport)">
                              <option *ngFor="let tier of tiers" [value]="tier.id + ''" [class.not-matched-tariff]="!isTransportTariffMatched(transport, tier)">{{ tier.name }}</option>
                            </select>
                          </span>
                          <span *ngIf="transportWithActiveTierSelector != transport.id" [title]="getTariffTitle(transport)">
                            <span *ngIf="transport.selected_tier">/
                              <span
                                class="tier"
                                (click)="onActivateTierSelector(transport, $event)"
                                [class.not-matched-tariff]="!isTransportTariffMatched(transport, transport.selected_tier)"
                                [class.selectable]="!isExecuted(transport)"
                              >
                                {{ transport.selected_tier.name }}
                              </span>
                            </span>
                            <span *ngIf="!transport.selected_tier">/ <span class="tier not-matched-tariff" (click)="onActivateTierSelector(transport, $event)">тариф</span></span>
                          </span>
                        </span>
                      </span>
                      <span class="roll-control" (click)="rollTransport(transport, !isTransportRolledUp(transport))" [ngSwitch]="isTransportRolledUp(transport)">
                        <span *ngSwitchCase="true">Развернуть</span>
                        <span *ngSwitchCase="false">Свернуть</span>
                      </span>
                    </label>
                    <div class="total-cost">
                      Итоговая стоимость:
                      <span *ngIf="!isTransportCostEditorActive(transport)" (click)="onActivateTransportCostEditor(transport)" [class.editable]="transport.used_weak_path" [attr.title]="transport.used_weak_path ? 'изменить' : null">{{ transport.cost }}р.</span>
                      <span *ngIf="isTransportCostEditorActive(transport)">
                        <input
                          type="text"
                          size="5"
                          class="editor form-control input-sm"
                          [name]="'transport-cost-' + transport.id"
                          [(ngModel)]="transport.customCost"
                          (keyup.enter)="onApplyTransportCost(transport)"
                          (keyup.escape)="onDeactivateTransportCostEditor(transport)"
                        >
                        <button type="button" class="btn btn-success btn-xs" (click)="onApplyTransportCost(transport)"><span class="glyphicon glyphicon-ok"></span></button>
                        <button type="button" class="btn btn-danger btn-xs" (click)="onDeactivateTransportCostEditor(transport)"><span class="glyphicon glyphicon-remove"></span></button>
                      </span>
                    </div>
                    <div class="driver-earned" *ngIf="showDriverEarn">Водитель заработал: ~<span [class.negative]="transport.driver_earned <= 0">{{ transport.driver_earned }}</span>р.</div>
                    <div class="self-driver" *ngIf="transport.used_weak_path">
                      <span class="select-control" >
                        <span *ngIf="transport.employer" title="отменить назначение" (click)="onCancelSelfDriver(transport)">
                          Перевозчик: {{ transport.employer.account|fullName }}
                          <span class="glyphicon glyphicon-remove"></span>
                        </span>
                        <span *ngIf="!transport.employer" (click)="onSelectSelfDriver(transport)">назначить своего перевозчика</span>
                      </span>
                    </div>
                  </div>
                  <!--div>Тариф: {{ transport.tariff_identifier }}</div-->
                  <div class="search-state" *ngFor="let state of getSearchStateByTransport(transport)|toArray">
                    <div class="draft-state" *ngIf="state.draft">
                      <div class="indicator"
                           [class.stopped]="deliveryStatusGroups.stopped.indexOf(state.draft.delivery_status) != -1"
                           [class.search]="deliveryStatusGroups.search.indexOf(state.draft.delivery_status) != -1"
                           [class.execution]="deliveryStatusGroups.execution.indexOf(state.draft.delivery_status) != -1"
                           [class.completed]="deliveryStatusGroups.completed.indexOf(state.draft.delivery_status) != -1"
                      ></div>
                      <div *ngIf="!state.order && userInfoService.isAvailableRouteOptimization()"><a [routerLink]="['/drafts', state.draft.id]">заявка №{{ state.draft.id }}</a></div>
                      <div *ngIf="state.order"><a [routerLink]="['/orders', state.order.freighter.id, state.order.id]"><span *ngIf="!userInfoService.isAvailableRouteOptimization()">детали</span><span *ngIf="userInfoService.isAvailableRouteOptimization()">заказ №{{ state.order.id }}</span></a></div>
                      <div class="status">{{ state.draft.delivery_status|deliveryStatus }}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="calcTransportDistance(transport) > 0">
                  Суммарный пробег: {{ distanceForView(calcTransportDistance(transport)) }}км.
                </div>
                <div class="small">
                  <i *ngIf="transport.calculation_data.cost">Рассчитанная стоимость: {{ transport.calculation_data.cost | accuracyPrice }}р.</i>
                  <div>
                    <i class="text-nowrap" *ngIf="transport.calculation_data.driving_cost">Стоимость в пути: {{ transport.calculation_data.driving_cost | accuracyPrice }}р.</i>
                    <i *ngIf="transport.calculation_data.downtime_cost > 0" class="text-nowrap">Стоимость ожидания: {{ transport.calculation_data.downtime_cost | accuracyPrice }}р. за {{ transport.calculation_data.downtime }} мин.</i>
                    <i class="text-nowrap" *ngIf="transport.calculation_data.surcharges">Доплаты: {{ transport.calculation_data.surcharges | accuracyPrice }}р.</i>
                  </div>
                </div>
                <driving-stat [stat]="transport.calculation_data.driving_data"></driving-stat>
              </div>
            </div>
            <div class="panel-row transport" *ngIf="transport.arrival_slot_start && transport.arrival_slot_end">
              <div class="pr-title">
                Подача
                <span [class.editable]="!isExecuted(transport)" *ngIf="!isTransportArrivalEditing(transport)" (click)="onStartTransportArrivalEditing(transport)">
                  {{ transport.arrival_slot_start | withCityTz:task.city | dt:'dd.MM.yy' }}
                  с {{ transport.arrival_slot_start | withCityTz:task.city | dt:'HH:mm' }}
                  по {{ transport.arrival_slot_end | withCityTz:task.city | dt:'HH:mm' }}
                </span>
                <span class="editor" *ngIf="isTransportArrivalEditing(transport)">
                  <input type="date" name="arrivalDate[{{transport.id}}]" [(ngModel)]="getTransportArrivalData(transport).date">
                  с <input type="time" name="arrivalStartTime[{{transport.id}}]" pattern="[0-9]{2}:[0-9]{2}" [(ngModel)]="getTransportArrivalData(transport).timeStart">
                  по <input type="time" name="arrivalEndTime[{{transport.id}}]" pattern="[0-9]{2}:[0-9]{2}" [(ngModel)]="getTransportArrivalData(transport).timeEnd">
                  <button type="button" class="btn btn-xs" (click)="onUpdateTransportArrival(transport)"><span class="glyphicon glyphicon-ok" title="Сохранить"></span></button>
                  <button type="button" class="btn btn-xs btn-danger" *ngIf="!isTransportArrivalNew(transport)" (click)="onClearTransportArrival(transport)"><span class="glyphicon glyphicon-trash" title="Удалить данные о подаче"></span></button>
                  <button type="button" class="btn btn-xs" (click)="onStopTransportArrivalEditing(transport)" title="Отменить"><span class="glyphicon glyphicon-remove"></span></button>
                </span>
              </div>
            </div>
            <div class="panel-row transport" *ngIf="!transport.arrival_slot_start && !transport.arrival_slot_end && transport.created_manually && !isExecuted(transport)">
              <div class="pr-title">
                <span class="editable" (click)="onAddTransportArrival(transport)">Указать время подачи на склад</span>
              </div>
            </div>
            <div class="panel-row transport" [class.hidden]="!isTransportRolledUp(transport)">
              <div class="pr-title">Количество адресов: {{ transport.destinations.length - 1 }}</div>
            </div>
            <div class="panel-row transport" [class.rolled-up]="isTransportRolledUp(transport)">
              <div class="br-box panel-click">
                <div class="pr-body">
                  <ol cdkDropList [cdkDropListData]="transport.destinations" (cdkDropListDropped)="onSortCompleted(transport, $event)" [cdkDropListSortPredicate]="sortPredicate">
                    <li *ngFor="let destination of transport.destinations; let i = index"
                        class="destination"
                        (mouseover)="onHoverDestination(destination.destination.id)"
                        (click)="onEditDestination(destination.destination.id)"
                        cdkDrag
                        [cdkDragData]="destination"
                        [cdkDragDisabled]="!(transport.created_manually && !task.imported_from && !getSearchStateByTransport(transport))">
                      <div>
                        <span cdkDragHandle *ngIf="transport.created_manually && !task.imported_from && !getSearchStateByTransport(transport)"
                              class="sortable-handle"><img src="/assets/images/sort-handle.png" alt="сортировка"
                                                           title="нажмите и перенесите в нужное место"
                                                           draggable="false"></span>&nbsp;
                        <span class="glyphicon glyphicon-ok delivered" title="Доставлено"
                              *ngIf="destination?.destination.delivered"></span>
                        <span class="glyphicon glyphicon-remove undelivered" title="Не доставлено"
                              *ngIf="destination?.destination.undelivered"></span>
                        <strong>
                          <span class="order-code"
                                *ngIf="destination?.destination.order_code">[{{ destination?.destination.order_code }}] -</span>
                          {{ destination?.arrival_time | dt:'H:mm' }}
                          ({{ destination?.destination.time_slot_begin | dt:'H:mm' }}
                          -{{ destination.destination.time_slot_end | dt:'H:mm' }})
                          - {{ destination.destination.destination.addr }}
                        </strong>
                      </div>
                      <div *cdkDragPreview>
                        <strong>
                          <span class="order-code"
                                *ngIf="destination?.destination.order_code">[{{ destination?.destination.order_code }}] -</span>
                          {{ destination?.arrival_time | dt:'H:mm' }}
                          ({{ destination?.destination.time_slot_begin | dt:'H:mm' }}
                          -{{ destination.destination.time_slot_end | dt:'H:mm' }})
                          - {{ destination.destination.destination.addr }}
                        </strong>

                      </div>
                      <div *ngIf="!destination?.destination.stock && destination?.delivery_cost">
                        Стоимость доставки:
                        <span *ngIf="hasDeliveryCostDiff(destination)">{{ destination?.delivery_cost }}Р. <span
                          class="old-cost">{{ destination.destination.delivery_cost + destination.calculation.assemblyCost + (destination.calculation.liftingCost || 0) }}
                          Р.</span></span>
                        <span *ngIf="!hasDeliveryCostDiff(destination)">{{ destination?.delivery_cost }}Р.</span>
                      </div>
                      <div *ngIf="destination?.driving_time">
                        <i>
                          Время в пути до этого адреса: {{ destination?.driving_time | dt:"H:mm" }}
                        </i>
                        <i *ngIf="destination?.downtime">(простой - {{ destination?.downtime }} мин.)</i>
                      </div>
                      <driving-stat [stat]="destination.driving_data" *ngIf="destination"></driving-stat>
                      <div class="driver-earned" *ngIf="destination?.driver_earned && showDriverEarn">Водитель
                        заработал: ~<span
                          [class.negative]="destination?.driver_earned <= 0">{{ destination?.driver_earned }}</span>р.
                      </div>
                    </li>
                  </ol>
                  <div class="transport-controls" *ngIf="transport.created_manually && !isExecuted(transport)">
                    <span *ngIf="transport.destinations.length > 2" class="control" (click)="onOptimizeTransportRoute(transport)">оптимизировать рейс</span>
                    <span *ngIf="ozonEnabled" class="control" (click)="onClickOzonImportButton(transport)">
                      <img src="/assets/images/ozon/ozon-logo.svg" alt="Ozon" height="16"> импорт из Ozon
                    </span>
                    <span
                      *ngIf="transportDraftCalculations.get(transport.id).calculation"
                      class="control"
                      (click)="onToggleDraftCalculation(transport)"
                      [class.opened]="transportDraftCalculations.get(transport.id).isOpened"
                    >калькуляция</span>
                    <span *ngIf="existFreeDestinations && isTransportsEditable" class="control" (click)="onAddDestinationForTransport(transport)">добавить адреса</span>
                    <span *ngIf="transport.destinations.length > 1 && isTransportsEditable" class="control" (click)="onRemoveTransportDestinations(transport)">удалить адреса</span>
                    <span *ngIf="isTransportsEditable" class="control remove" (click)="onRemoveTransport(transport)">удалить рейс</span>
                  </div>
                  <div class="transport-controls" *ngIf="!transport.created_manually || isExecuted(transport)">
                    <span
                            *ngIf="transportDraftCalculations.get(transport.id).calculation"
                            class="control"
                            (click)="onToggleDraftCalculation(transport)"
                            [class.opened]="transportDraftCalculations.get(transport.id).isOpened"
                    >калькуляция</span>
                  </div>
                  <div *ngIf="transportDraftCalculations.get(transport.id).isOpened">
                    <calculation
                      [calculation]="transportDraftCalculations.get(transport.id).calculation.calculation"
                      [destinations]="transportDraftCalculations.get(transport.id).calculation.destinations"
                      [perMinuteBilling]="transportDraftCalculations.get(transport.id).calculation.perMinuteBilling"
                    ></calculation>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </form>
        </div>
        </ng-scrollbar>
      </div>
    </div>
  </div>
</div>
<edit-complex-delivery [optimizationTask]="taskForEdit" [destinationId]="destinationForEdit" (onSaved)="onTaskSaved()"></edit-complex-delivery>
<freighters-fast-list-dialog [singleSelectMode]="true" (savedEmployers)="onChangeEmployer($event)"></freighters-fast-list-dialog>
<complex-delivery-destinations-selector-dialog (selected)="onSelectDestinations($event)"></complex-delivery-destinations-selector-dialog>
<ozon-import-dialog *ngIf="ozonEnabled" (onImported)="onOzonParcelImported()"></ozon-import-dialog>
