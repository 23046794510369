export class FreighterTransportTariff {
  id: number;
  name: string;
  min_price: number;
  min_hours: number;
  after_mkad_km_price: number;
  additional_hour_price: number;
  ttk_price: number;
  hydroelevator_hour_price: number = 0;
}
