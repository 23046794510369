import {Message} from "./message";

export class OrderCrewChanged implements Message {
  constructor(
    public orderId: number,
    public draftId: number,
    public freighterId: number,
    public employerId: number,
    public transportId: number,
    public employerData: any,
    public transportData: any
  ) {}

}
