<div class="col-xs-8 col-md-8 col-centered col-slider no-padding row">
  <h1>Юридические лица</h1>
  <table class="table legal-entities-table table-hover">
    <thead>
    <tr>
      <th>Наименование</th>
      <th>ИНН</th>
      <th class="text-center">Активный</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entity of entities" [class.active]="entity.active">
      <td>{{ entity.legal_entity.name }}</td>
      <td>{{ entity.legal_entity.inn }}</td>
      <td class="text-center"><span *ngIf="entity.active">Да</span></td>
      <td>
        <div *ngIf="userInfoService.isPrivilegedUser() || userInfoService.isCompanyClientManager()" class="controls">
          <a [routerLink]="['/legal-entities/', entity.id]" class="btn btn-link" title="Изменить"><span class="glyphicon glyphicon-pencil"></span></a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="list_controls" *ngIf="userInfoService.isPrivilegedUser() || userInfoService.isCompanyClientManager()">
    <a [routerLink]="['/legal-entities/new']" class="btn btn-default"><span class="glyphicon glyphicon-plus"></span> Добавить</a>
  </div>
</div>
