import {catchError, map} from 'rxjs/operators';

import {Observable, of, throwError as observableThrowError} from 'rxjs';
import {Injectable} from "@angular/core";
import {DeliverySchema} from "../_models/delivery-schema";
import {RequestService} from "./request.service";
import {OrderDraft} from "../_models/order-draft";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class DeliveryService {
  constructor(private _requestService: RequestService) {
  }

  getSchemas(): Observable<DeliverySchema[]> {
    return this._requestService
      .get('/delivery/schemas.json').pipe(
        map(r => r.body as DeliverySchema[]));
  }

  getSchemasByDraft(draft: OrderDraft): Observable<DeliverySchema[]> {
    return this._requestService
      .get(`/delivery/schemas/by/draft/${draft.id}.json`).pipe(
        map(r => r.body as DeliverySchema[]));
  }

  updateDeliveryStatus(draft: OrderDraft, status: string) {
    return this._requestService
      .put(`/delivery/${draft.id}/status.json`, {
        value: status
      });
  }

  getDefaultSchemaForNewDraft(): Observable<DeliverySchema | null> {
    return this._requestService
      .get('/customer/me/delivery_schemas/default/new/draft.json').pipe(
        map(r => r.body as DeliverySchema),
        catchError(r => {
          if(r instanceof HttpErrorResponse && r.status === 404)
            return of(null);

          return observableThrowError(() => r);
        })
      )
      ;
  }
}
