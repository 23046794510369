import {TariffTier} from "./tariff-tier";

export class ExtraSearchParams {
  length_groups: number[];
  tariff_tier: TariffTier;
  min_capacity: number;
  min_length: number;
  min_width: number;
  min_height: number;
}
