<div class="crew-form-container">
  <h1 *ngIf="crew.isNew">Новый экипаж</h1>
  <h1 *ngIf="!crew.isNew">Экипаж: {{ crew.record.employer.account|fullName }} / {{ crew.record.transport|transportDescription }}</h1>

  <div *ngIf="!crew.isNew" class="crew-widgets">
    <div class="widget employee-status-widget">
      <div class="indicator status-{{ crew.record.employer.status }}"></div>
      <div class="title">Статус</div>
      <div class="switcher" *ngIf="crew.record.employer.status != 'work'" (click)="onSwitchStatus()" title="Переключить статус"><img src="/assets/images/crew/button.png" alt=""></div>
      <div class="status status-{{ crew.record.employer.status }}">{{ crew.record.employer.status|employerStatus }}</div>
    </div>
    <div class="widget order-widget" *ngFor="let order of crew.record.orders">
      <div class="indicator order-status-{{ order.status }}"></div>
      <div class="title">Заказ №{{ order.draft.id }} <span class="small">({{ order.id }})</span></div>
      <div class="order-link"><a [routerLink]="[ '/orders', freighter.id, order.id ]" title="Перейти к заказу"><img src="/assets/images/crew/order.png" alt=""></a></div>
      <div class="status order-status-{{ order.status }}">{{ order.status|orderStatus }}</div>
    </div>
  </div>

  <form (submit)="onSubmit()">
    <h2>Водитель</h2>
    <div class="driver crew-component">
      <div class="controls" *ngIf="isEditAllowed && isChangeEmployerAllowed">
        <button type="button" class="btn btn-default btn-xs" (click)="onSelectEmployee()"><img
          src="/assets/images/crew/driver.png" alt=""> Выбрать
        </button>
      </div>
      <div *ngIf="crew.record.employer">
        <table class="component-table">
          <thead>
          <tr class="component-table-header">
            <th>Имя</th>
            <th>Телефон</th>
            <th>E-mail</th>
            <th>Статус</th>
            <th class="text-center">Активирован</th>
          </tr>
          </thead>
          <tbody [routerLink]="[ '/employees', crew.record.employer.id ]">
          <tr>
            <td>{{ crew.record.employer.account|fullName }}</td>
            <td>{{ crew.record.employer.account.phone|formatPhone }}</td>
            <td><a *ngIf="crew.record.employer.account.email"
                   [href]="'mailto:' + crew.record.employer.account.email">{{ crew.record.employer.account.email }}</a>
            </td>
            <td
              class="status status-{{ crew.record.employer.status }}">{{ crew.record.employer.status|employerStatus }}</td>
            <td rowspan="2" class="text-center"><span *ngIf="crew.record.employer.account.auth.is_active"
                                                      class="glyphicon glyphicon-ok enabled"></span></td>
          </tr>
          <tr class="last-subrow">
            <td colspan="4" class="comment">{{ crew.record.employer.comment }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h2>Машина</h2>
    <div class="transport crew-component">
      <div class="controls" *ngIf="isEditAllowed">
        <button type="button" class="btn btn-default btn-xs" (click)="onSelectTransport()"><img
          src="/assets/images/crew/transport.png" alt=""> Выбрать
        </button>
      </div>
      <div *ngIf="crew.record.transport">
        <table class="component-table">
          <thead>
          <tr class="component-table-header">
            <th>Марка</th>
            <th>Кузов</th>
            <th>Номер</th>
            <th class="text-center">Грузоподъёмность, т</th>
            <th class="text-center">Размеры, м х м х м</th>
            <th class="text-center">Объём, м<sup>3</sup></th>
            <th class="text-center">Въезд в центр</th>
            <th class="text-center">Наличие гидроборта</th>
          </tr>
          </thead>
          <tbody [routerLink]="[ '/transports', crew.record.transport.id ]">
          <tr>
            <td>{{ crew.record.transport.car_model }}</td>
            <td>{{ crew.record.transport|transportBody }}</td>
            <td>{{ crew.record.transport.features.number }}</td>
            <td class="text-center">{{ crew.record.transport|transportCapacity }}</td>
            <td class="text-center">{{ crew.record.transport|transportSize }}</td>
            <td class="text-center">{{ crew.record.transport.features.Ob_em_m3 }}</td>
            <td rowspan="2" class="text-center"><span *ngIf="crew.record.transport.features.Razreshen_v_ezd_v_TTK"
                                                      class="glyphicon glyphicon-ok enabled"></span></td>
            <td rowspan="2" class="text-center"><span *ngIf="crew.record.transport.features.Nalichie_gidroborta"
                                                      class="glyphicon glyphicon-ok enabled"></span></td>
          </tr>
          <tr class="last-subrow">
            <td colspan="6" class="comment">{{ crew.record.transport.features.comment }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h2>Группа</h2>
    <div class="tiers">
      <div class="tier" *ngFor="let optionalTier of optionalTiers">
        <div class="checkbox">
          <label><input type="checkbox"
                        [disabled]="!isEditAllowed"
                        name="tier[{{ optionalTier.tier.id }}]"
                        [(ngModel)]="optionalTier.selected"> {{ optionalTier.tier.name }}</label>
        </div>
      </div>
      <div class="tier" *ngIf="optionalTiers.length == 0">
        Не удалось определить
      </div>
    </div>
    <h2>Грузчики</h2>
    <div class="row">
      <div class="col-sm-4">
        <div class="checkbox">
          <label><input type="checkbox" name="selfLoader" [(ngModel)]="crew.record.self_loader" [disabled]="!isEditAllowed"> Водитель является грузчиком</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-5 loaders-count">
        <input type="number" class="form-control" [(ngModel)]="crew.record.loaders" name="loaders" required [disabled]="!isEditAllowed">
        <label class="control-label">количество грузчиков, исключая водителя</label>
      </div>
    </div>
    <h2>Схема распределения средств</h2>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <select name="paymentDistributionSchema" class="form-control" [(ngModel)]="paymentDistributionSchema.id" [disabled]="!isEditAllowed">
            <option value="0">- использовать схему водителя -</option>
            <option *ngFor="let schema of paymentDistributionSchemas" [value]="schema.id">{{ schema.name }}</option>
          </select>
          <input-error [formErrors]="crew.errors" field="payment_distribution_schema"></input-error>
        </div>
      </div>
    </div>
    <h2>Такси MOVER</h2>
    <div class="row">
      <div class="col-sm-4">
        <div class="checkbox">
          <label><input type="checkbox" name="taxi" [(ngModel)]="crew.record.taxi" [disabled]="!isEditAllowed"> Участвует в поиске такси</label>
        </div>
      </div>
    </div>
    <h2>Период действия</h2>
    <div class="row">
      <div class="col-sm-4">
        <label>Начало</label>
        <date-time [(value)]="startDate" required [disabled]="!crew.isNew"></date-time>
      </div>
      <div class="col-sm-4">
        <label>Окончание</label>
        <date-time [(value)]="endDate" required [disabled]="!isEditAllowed"></date-time>
      </div>
    </div>
    <div class="crew-controls">
      <button type="button" class="btn btn-default" (click)="onBack()">&lt; К списку</button>
      <button type="submit" class="btn btn-primary" [disabled]="crew.isSubmitting" *ngIf="isEditAllowed">Сохранить</button>
      <button type="button" class="btn btn-danger disband" *ngIf="!crew.isNew && isEditAllowed" (click)="onDisband()">Расформировать</button>
    </div>
  </form>
</div>

<employee-selector-dialog #employeesDialog [freighter]="freighter" [date]="date"
                          (selected)="onEmployeeSelected($event)"></employee-selector-dialog>
<transport-selector-dialog #transportsDialog [freighter]="freighter" [date]="date"
                           (selected)="onTransportSelected($event)"
                           [employer]="crew.record.employer"></transport-selector-dialog>
