import {DeliveryReason} from "./delivery-reason";

export class DeliveryHistoryRecord {
  reason: DeliveryReason;
  cargo_cost: number;
  cargo_discount: number;
  delivery_cost_for_client: number;
  delivery_cost_for_shop: number;
  delivery_cost_for_freighter: number;
}
