
import {finalize, switchMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {FreighterTransportTariff} from "../_models/freighter-transport-tariff";
import {FreighterTransportTariffService} from "../_services/freighter-transport-tariff.service";
import {LoaderService} from "../_services/loader.service";

@Component({
  selector: 'app-transport-tariffs',
  templateUrl: './transport-tariffs.component.html',
  styleUrls: ['./transport-tariffs.component.css']
})

export class TransportTariffsComponent implements OnInit {
  tariffs: FreighterTransportTariff[] = [];

  private reloadStream = new Subject<void>();

  constructor(
    private freighterTransportTariffService: FreighterTransportTariffService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.reloadStream.pipe(
      switchMap(() => {
        this.loaderService.show();
        return this.freighterTransportTariffService
          .getTariffs().pipe(
          finalize(() => this.loaderService.hide()))
      }))
      .subscribe(
        tariffs => this.tariffs = tariffs,
        () => {}
      );

    this.reloadTariffs();
  }

  reloadTariffs() {
    this.reloadStream.next();
  }

  deleteTariff(tariff: FreighterTransportTariff) {
    this.loaderService.show();
    this.freighterTransportTariffService
      .deleteTariff(tariff).pipe(
      finalize(() => this.loaderService.hide()))
      .toPromise()
      .then(() => this.reloadTariffs())
      .catch(() => {});
  }

  onDelete(tariff: FreighterTransportTariff) {
    if(confirm("Подтверждаете удаление тарифа?"))
      this.deleteTariff(tariff);
  }
}
