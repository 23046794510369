import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FreightersFastListDialogComponent} from "../freighters-fast-list-dialog/freighters-fast-list-dialog.component";
import {Freighter} from "../_models/freighter";
import {Employer} from "../_models/employer";
import {OrderDraft} from "../_models/order-draft";
import {Subscription} from "rxjs";
import {MapTransport} from "../_models/map-transport";
import {ScreenUtils} from "../_utils/screen/screen-utils";
import {CrewsMapService} from "../_services/crews-map.service";
import {Crew} from "../_models/crew";
import {CrewService} from "../_services/crew.service";

@Component({
  selector: 'freighter-filters-editor',
  templateUrl: './freighter-filters-editor.component.html',
  styleUrls: ['./freighter-filters-editor.component.css']
})
export class FreighterFiltersEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  // Пришлось подключить диалог через input, т.к. он криво отображается в редакторе черновика, если он находится внутри
  // этого компонента.
  @Input() freightersListDialog: FreightersFastListDialogComponent;
  @Input() draft: OrderDraft;
  @Input() replaceEmployerWithCrew = false;

  @Output() saved = new EventEmitter<[Freighter[], Employer[], Crew[], boolean]>();

  @ViewChild('autoAcceptInfo', { static: true }) autoAcceptInfoEl: ElementRef;

  freighters: Freighter[] = [];
  employers: Employer[] = [];
  crews: Crew[] = [];
  autoAssigment = false;
  enabledAutoAssigment = false;
  isCrewsShownOnMap = false;

  private crewSelectSubscription: Subscription;

  constructor(private crewsMapService: CrewsMapService, private crewService: CrewService) { }

  ngOnInit() {
    if(this.draft.freighter_filters)
      this.freighters = this.draft.freighter_filters.map(filter => filter.freighter);
    else
      this.freighters = [];

    if(this.draft.employer_filters)
      this.employers = this.draft.employer_filters.map(filter => filter.employer);
    else
      this.employers = [];

    if(this.draft.crew_filters)
      this.crews = this.draft.crew_filters.map(filter => filter.crew);
    else
      this.crews = [];

    this.autoAssigment = this.draft.auto_assigment_crew;

    this.initAutoAssigment();
  }

  ngAfterViewInit(): void {
    $(this.autoAcceptInfoEl.nativeElement).tooltip({
      delay: 500
    });
  }

  applyFreighters(freighters: Freighter[]): void {
    this.freighters = freighters;
    this.initAutoAssigment();
  }

  applyEmployers(employers: Employer[]): void {
    this.employers = employers;
    this.initAutoAssigment();
  }

  applyCrews(crews: Crew[]): void {
    this.crews = crews;
    this.initAutoAssigment();
  }

  private initAutoAssigment(): void {
    this.enabledAutoAssigment = this.employers.length == 1 && this.crews.length == 0
      || this.crews.length == 1 && this.employers.length == 0;
  }

  private save(): void {
    this.saved.emit([this.freighters, this.employers, this.crews, this.autoAssigment]);
  }

  private showCrewsOnMap(): void {
    this.crewsMapService.showCrewsOnMap(ScreenUtils.buildTransportParams(this.draft));
    this.crewSelectSubscription = this.crewsMapService.getSelectCrewObservable()
      .subscribe(car => this.onSelectTransportOnMap(car))
    ;
  }

  private removeCrewsFromMap(): void {
    this.crewSelectSubscription.unsubscribe();
    this.crewsMapService.removeCrewsFromMap();
  }

  private addEmployerFromMapTransport(car: MapTransport): void {
    for(let employer of this.employers) {
      if(employer.id == car.feedTransport.employer.id)
        return;
    }

    this.employers.push(car.feedTransport.employer);
    this.initAutoAssigment();
  }

  private addCrewFromMapTransport(car: MapTransport): void {
    let freighter = new Freighter();
    freighter.id = car.feedTransport.freighter_id;

    this.crewService
      .getCrew(freighter, car.feedTransport.id)
      .subscribe(
        crew => {
          if(this.crews.filter(c => c.id == crew.id).length == 0) {
            this.crews.push(crew);
            this.initAutoAssigment();
          }
        },
        () => {}
      )
    ;
  }

  onSave(): void {
    this.save();
  }

  onToggleShowCrewsOnMap(): void {
    this.isCrewsShownOnMap = !this.isCrewsShownOnMap;
    if(this.isCrewsShownOnMap)
      this.showCrewsOnMap();
    else
      this.removeCrewsFromMap();
  }

  onSelectTransportOnMap(car: MapTransport): void {
    if(this.replaceEmployerWithCrew)
      this.addCrewFromMapTransport(car);
    else
      this.addEmployerFromMapTransport(car);
  }

  ngOnDestroy(): void {
    if(this.isCrewsShownOnMap)
      this.removeCrewsFromMap();
  }
}
