<div *ngIf="informationFrom === false" class="card card-container">
  <h2 class='login_title text-center'>Вход</h2>
  <hr>

  <form class="form-signin" (submit)="onSubmit()" #theForm="ngForm">
    <p class="input_title login_mode">
      <span [class.not-active]="emailMode" (click)="onChangeEmailMode(false)">Телефон</span>
      |
      <span [class.not-active]="!emailMode" (click)="onChangeEmailMode(true)">E-mail</span></p>
    <input *ngIf="!emailMode" name="phone" mask="+0 (000) 000 00 00" [showMaskTyped]="true" [(ngModel)]="phone" class="login_box" placeholder="Введите логин" type="text" required autofocus>
    <input *ngIf="emailMode" name="phone" [(ngModel)]="phone" class="login_box" placeholder="Введите логин" type="text" required autofocus>
    <p class="input_title" *ngIf="!emailMode">Код</p>
    <p class="input_title" *ngIf="emailMode">Пароль</p>
    <input name="password" [(ngModel)]="password" type="password" class="login_box code_box" [placeholder]="emailMode ? 'Введите пароль' : 'Введите код'" required>
    <div class="request_code">
      <span [class.hidden]="emailMode" (click)="onRequestCode()">запросить код</span>
    </div>
    <button class="btn btn-lg btn-primary" type="submit" [disabled]="!theForm.form.valid || loading">Вход<span *ngIf="loading">...</span></button>
  </form><!-- /form -->
  <div class="text-center">
    <a class="reset__button" href="https://reg.mover24.ru">Зарегистрироваться</a>
  </div>
</div><!-- /card-container -->

<div *ngIf="informationFrom === true" class="card card-container">
  <h2 class='login_title text-center'>Восстановление</h2>
  <hr>
  <form class="form-signin" (submit)="onRequestCode()" #theForm="ngForm">
    <p class="input_title">Телефон</p>
    <input name="phone" [(ngModel)]="phone" class="login_box" placeholder="Введите логин" type="text" required autofocus>
    <button class="btn btn-lg btn-primary" type="submit" >Восстановление</button>
     <button class="reset__button" (click)="outReset()">Назад</button>
  </form><!-- /form -->
</div><!-- /card-container -->

