
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError, Observable, of} from 'rxjs';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {CompanyClientLegalEntity} from "../_models/company-client-legal-entity";
import {LegalEntity} from "../_models/legal-entity";
import {ApiUtilsService} from "./api-utils.service";
import {Freighter} from "../_models/freighter";
import {LegalEntityUtils} from "../_utils/legal-entity-utils";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class LegalEntityService {
  constructor(private _requestService: RequestWithErrorHandlerService, private apiUtilsService: ApiUtilsService) { }

  getCompanyLegalEntities(companyId: number): Observable<CompanyClientLegalEntity[]> {
    return this._requestService
      .get(`/company_client/${companyId}/legal_entities.json`).pipe(
      map(r => r.body as CompanyClientLegalEntity[]))
    ;
  }

  getCompanyLegalEntity(legalEntityId: number): Observable<CompanyClientLegalEntity> {
    return this._requestService
      .get(`/legal_entities/${legalEntityId}.json`).pipe(
      map(r => r.body as CompanyClientLegalEntity))
    ;
  }

  addCompanyLegalEntity(companyId: number, legalEntity: CompanyClientLegalEntity): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`/company_client/${companyId}/legal_entities.json`, LegalEntityService.companyClientLegalEntityToData(legalEntity))
    ;
  }

  updateCompanyLegalEntity(legalEntity: CompanyClientLegalEntity): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`/legal_entities/${legalEntity.id}.json`, LegalEntityService.companyClientLegalEntityToData(legalEntity))
      ;
  }

  updateFreighterLegalEntity(freighter: Freighter, legalEntity: LegalEntity): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/legal_entity/${legalEntity.id}.json`, LegalEntityService.legalEntityToData(legalEntity))
      ;
  }

  findLegalEntityByInn(freighter: Freighter, inn: string): Observable<LegalEntity|null> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/legal_entity.json`, { inn }).pipe(
      map(r => r.body as LegalEntity),
      catchError(r => {
        if(r instanceof HttpResponse && r.status === 404)
          return of(null);

        return  observableThrowError(() => r);
      })
      )
    ;
  }

  findLegalEntitiesForFreighter(freighter: Freighter, str: string): Observable<LegalEntity[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/legal_entities.json`, { s: str }).pipe(
      map(r => r.body as LegalEntity[]))
      ;
  }

  findLegalEntitiesForSupport(str: string): Observable<LegalEntity[]> {
    return this._requestService
      .get(`/support/me/legal_entities.json`, { s: str }).pipe(
      map(r => r.body as LegalEntity[]))
      ;
  }

  findLegalEntitiesForCustomer(str: string): Observable<LegalEntity[]> {
    return this._requestService
      .get(`/customer/me/legal_entities.json`, { s: str }).pipe(
      map(r => r.body as LegalEntity[]))
      ;
  }

  getForFreighterClient(freighter: Freighter, clientId: number): Observable<LegalEntity[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${clientId}/legal_entities.json`).pipe(
      map(r => r.body as LegalEntity[]))
      ;
  }

  getByInnForFreighterClient(freighter: Freighter, clientId: number, inn: string): Observable<LegalEntity> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${clientId}/legal_entities/by/inn.json`, {
        inn
      }).pipe(
      map(r => r.body as LegalEntity))
      ;
  }

  registerCompany(legalEntity: LegalEntity): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `/company_clients/registration/common.json`,
        LegalEntityService.legalEntityToRegisterData(legalEntity)
      )
      ;
  }

  private static companyClientLegalEntityToData(legalEntity: CompanyClientLegalEntity): any {
    return {
      company_client_legal_entity: {
        active: legalEntity.active,
        legalEntity: LegalEntityUtils.legalEntityData(legalEntity.legal_entity)
      }
    }
  }

  private static legalEntityToData(entity: LegalEntity): any {
    return {
      freighter_legal_entity: LegalEntityUtils.legalEntityData(entity)
    };
  }

  private static legalEntityToRegisterData(entity: LegalEntity): any {
    return {
      companyRegistration: { ...LegalEntityUtils.legalEntityData(entity), email: entity.email }
    };
  }
}
