import {List} from "../pager/list";
import {OptimizationTask} from "./optimization-task";
import {OptimizationTaskListFilter} from "./optimization-task-list-filter";

export class OptimizationTaskList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public filter: OptimizationTaskListFilter, public tasks: OptimizationTask[]) {
  }

  static empty(): OptimizationTaskList {
    return new OptimizationTaskList(0, 0, 0, new OptimizationTaskListFilter(), []);
  }

  concat(tasks: OptimizationTask[]) {
    this.tasks = tasks.concat(this.tasks);
    return this;
  }
}
