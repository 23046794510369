import {Auth} from "../../_models/auth";
import {Employee} from "../../_models/employees/employee";
import {Phone} from "../../_models/employees/phone";
import {Passport} from "../../_models/passport";
import {DriverLicense} from "../../_models/driver-license";
import {EmployeeDevice} from "../../_models/employees/employee-device";
import {LegalEntity} from "../../_models/legal-entity";
import {Employer} from "../../_models/employer";
import {EmployeeIndividual} from "../../_models/employees/employee-individual";
import {EmployerContacts} from "../../_models/employer-contacts";
import {TaxpayerCertificate} from "../../_models/taxpayer-certificate";

export class UserEmployeeConverter {
  private individualFieldsMapping = {
    INN: 'inn',
    SNILS: 'snils',
    Nomer_raschetnogo_scheta_kartyi_: 'checking_account',
    BIK_banka: 'bik',
    Sberbank: 'bank_card'
  }

  getFeatureNames(): string[] {
    let names: string[] = [];

    for (let name in this.individualFieldsMapping)
      names.push(name);

    return names;
  }

  convertEmployeeToUser(employee: Employee): Auth {
    let user = new Auth();

    user.phone = employee.main_phone.phone;
    user.email = employee.email;
    user.is_active = employee.active;

    user.account = employee.account;
    user.account.employer.comment = employee.comment;

    let contacts = new EmployerContacts();
    contacts.telegram = employee.main_phone.telegram;
    contacts.viber = employee.main_phone.viber;
    contacts.whatsapp = employee.main_phone.whatsapp;
    contacts.add_phone = employee.additional_phone.phone;
    contacts.add_telegram = employee.additional_phone.telegram;
    contacts.add_viber = employee.additional_phone.viber;
    contacts.add_whatsapp = employee.additional_phone.whatsapp;

    user.account.employer.contacts = [ contacts ];
    user.account.employer.passports = [ employee.passport ];
    user.account.employer.driver_license = [ employee.driver_license ];
    user.account.employer.taxpayer_certificate = [ employee.taxpayer_certificate ];
    user.account.employer.driving_experience = employee.driving_experience;
    user.account.employer.devices = [ employee.device ];
    user.account.employer.legal_entity = employee.legal_entity;
    user.account.employer.freighter = employee.freighter;

    this.applyEmployeeIndividual(user.account.employer, employee.individual);

    user.account.employer.main_work_transport = employee.mainWorkTransport;

    user.account.employer.payment_distribution_schema = employee.payment_distribution_schema;

    return user;
  }

  convertUserToEmployee(user: Auth): Employee {
    let employer = user.account.employer;

    let employee = new Employee();
    employee.id = user.id;
    employee.active = user.is_active;
    employee.comment = employer.comment;
    employee.email = user.email;

    let mainPhone = new Phone();
    mainPhone.phone = user.phone;

    let additionalPhone = new Phone();

    if(employer.contacts && employer.contacts.length > 0) {
      let contacts = employer.contacts[0];
      mainPhone.telegram = contacts.telegram;
      mainPhone.viber = contacts.viber;
      mainPhone.whatsapp = contacts.whatsapp;

      additionalPhone.phone = contacts.add_phone;
      additionalPhone.telegram = contacts.add_telegram;
      additionalPhone.viber = contacts.add_viber;
      additionalPhone.whatsapp = contacts.add_whatsapp;
    }

    employee.main_phone = mainPhone;
    employee.additional_phone = additionalPhone;

    employee.account = user.account;

    employee.passport = employer.passports && employer.passports.length > 0 ? employer.passports[0] : new Passport();

    employee.driver_license = employer.driver_license && employer.driver_license.length > 0
      ? employer.driver_license[0]
      : new DriverLicense();

    employee.taxpayer_certificate = employer.taxpayer_certificate && employer.taxpayer_certificate.length > 0
      ? employer.taxpayer_certificate[0]
      : new TaxpayerCertificate();

    employee.device = employer.devices && employer.devices.length > 0 ? employer.devices[0] : new EmployeeDevice();

    employee.legal_entity = employer.legal_entity || new LegalEntity();

    employee.individual = this.extractEmployeeIndividual(employer);

    employee.driving_experience = employer.driving_experience;

    employee.mainWorkTransport = employer.main_work_transport;

    employee.freighter = employer.freighter;

    employee.payment_distribution_schema = employer.payment_distribution_schema;

    return employee;
  }

  private extractEmployeeIndividual(employer: Employer): EmployeeIndividual {
    let individual = new EmployeeIndividual();
    if(employer.features) {
      for (let feature in this.individualFieldsMapping) {
        individual[this.individualFieldsMapping[feature]] = employer.features[feature];
      }
    }
    return individual;
  }

  private applyEmployeeIndividual(employer: Employer, individual: EmployeeIndividual): void {
    if(!employer.features)
      employer.features = {};

    for (let feature in this.individualFieldsMapping) {
      employer.features[feature] = individual[this.individualFieldsMapping[feature]];
    }
  }
}
