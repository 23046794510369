<div>
  <div class="registration__tabs-items" *ngIf="user">
    <div class="registration__tabs-item">
      <div class="registration__groups">
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="ФИО" [ngModel]="user.account|fullName" name="name" readonly>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <div class="date-control__wrapper">
                  <div class="date-control__wrapper form-control date-control">
                    <input
                      placeholder="День рождения"
                      name="birthday"
                      [ngModel]="user.account.birthday|dt:'dd.MM.yyyy'"
                      readonly
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="registration__groups">
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input type="email" class="form-control" placeholder="Email" [ngModel]="user.account.email" name="email"
                       readonly>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input type="phone" class="form-control" placeholder="Номер телефона" [ngModel]="user.account.phone"
                       name="phone" readonly>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>

    <!-- Nav tabs -->
    <ul class="nav nav-tabs" role="tablist">
      <li role="presentation" class="active"><a #balanceTab href="#balance" aria-controls="balance" role="tab"
                                                data-toggle="tab">Баланс</a></li>
      <li role="presentation" [class.invisible]="!user || !user.account.employer"><a #driverPaymentDetailsTab
                                                                                     href="#driver-payment-details"
                                                                                     aria-controls="driver-payment-details"
                                                                                     role="tab" data-toggle="tab">Платёжные
        реквизиты</a></li>
      <li role="presentation" [class.invisible]="!user || !user.transport"><a #transportDetailsTab
                                                                              href="#transport-details"
                                                                              aria-controls="transport-details"
                                                                              role="tab" data-toggle="tab">Транспорт</a>
      </li>
      <li role="presentation"
          [class.invisible]="!user || !hasUserPassport(user)">
        <a #passportDetailsTab href="#passport-details" aria-controls="passport-details" role="tab" data-toggle="tab">Паспорт</a>
      </li>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content">
      <div role="tabpanel" class="tab-pane active" id="balance">
        <div *ngIf="isBalanceTabActive() && user">
          <h2 class="balance">{{ user.account.sum|humanNumber }} ₽</h2>
          <h3>Последние операции</h3>
          <account-invoices [account]="user.account" (onOpenOrder)="_onOpenOrder($event)"></account-invoices>
        </div>
      </div>
      <div role="tabpanel" class="tab-pane" id="driver-payment-details">
        <div *ngIf="isDriverPaymentDetailsTabActive() && user && user.account.employer">
          <driver-payment-details [employer]="user.account.employer"></driver-payment-details>
        </div>
      </div>
      <div role="tabpanel" class="tab-pane" id="transport-details">
        <div *ngIf="isTransportDetailsTabActive() && user && user.transport">
          <table class="table tariff-table" *ngIf="user.taxi_tariffs && user.taxi_tariffs.length > 0">
            <tbody>
            <tr>
              <td>Тарифы</td>
              <td class="tariffs-cell">
                <div class="taxi-tariffs">
                  <taxi-tariff-informer *ngFor="let tariff of user.taxi_tariffs"
                                        [tariff]="tariff"></taxi-tariff-informer>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <transport-details [transports]="user.transports"></transport-details>
        </div>
      </div>
      <div role="tabpanel" class="tab-pane" id="passport-details">
        <passport-view
          *ngIf="isPassportDetailsTabActive() && user && hasUserPassport(user)"
          [passport]="user.account.employer.passports[0]"></passport-view>
      </div>
    </div>

  </div>
</div>
