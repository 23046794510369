import {OrderDraft} from "./order-draft";
import {OrderPeriod} from "./order-period";
import {Freighter} from "./freighter";
import {OrderBill} from "./order-bill";
import {OrderAct} from "./order-act";
import {PromoCodeInstance} from "./promo-code-instance";
import {Insurance} from "./insurance";
import {OrderPaymentDistribution} from "./order-payment-distribution";

export class Order {
  id: number;
  status: string;
  created_at: string;
  first_cost: number;
  cost: number;
  discount: number;
  fixed_discount: number;
  total_cost: number;
  pay_method: string;
  pay_method_option: string;
  payed_from_account_sum: number;
  payment_link: string;
  for_payment: number;
  payment_status: string;
  draft: OrderDraft;
  periods: OrderPeriod[];
  pending: boolean;
  taximeter: number;
  taximeter_duration: number;
  taximeter_distance: number;
  taximeter_out_mkad_distance: number;
  freighter: Freighter;
  notes: string;
  driver_intercom_dialog: string;
  bills: OrderBill[];
  acts: OrderAct[];
  write_off_employer_fee: boolean;
  calculation: any;
  promo_code_instances: PromoCodeInstance[];
  tags: string[];
  insurances: Insurance[];
  payment_distribution_schemas: OrderPaymentDistribution[];
}
