import {Pipe, PipeTransform} from "@angular/core";
import {OrderPaymentStatus} from "../_enumerations/order-payment-status";
import {
  CLIENT_PAYMENT_STATUS,
  EMPLOYEE_FEE_PAYMENT_STATUS,
  EMPLOYEE_PAYMENT_STATUS,
  ORDER_PAYMENT_STATUS
} from "../_maps/order-payment-status";

@Pipe({name: 'orderPaymentStatus'})
export class OrderPaymentStatusPipe implements PipeTransform {
  transform(status: OrderPaymentStatus, mode?: string): string {
    switch (mode) {
      case 'client':
        return CLIENT_PAYMENT_STATUS[status];
      case 'employee':
        return EMPLOYEE_PAYMENT_STATUS[status];
      case 'employee_fee':
        return EMPLOYEE_FEE_PAYMENT_STATUS[status];
      default:
        return ORDER_PAYMENT_STATUS[status];
    }
  }
}
