import {State} from "../_storage/state";
import {DateUtils} from "../_utils/date-utils";

export class DraftListFilter {
  private static emptyFilter: DraftListFilter;

  constructor(
    public searchStatus: string = 'all',
    public after?: Date,
    public before?: Date,
    public client?: string,
    public draftId?: number,
    public search?: string,
    public companyClient?: number,
    public scheduledAfter?: Date,
    public scheduledBefore?: Date,
    public trackNumber?: string,
    public cargoCode?: string
  ) {}

  getState(): State {
    return new State(JSON.stringify({
      searchStatus: this.searchStatus,
      after: this.after,
      before: this.before,
      client: this.client,
      draftId: this.draftId,
      search: this.search,
      companyClient: this.companyClient,
      scheduledAfter: this.scheduledAfter,
      scheduledBefore: this.scheduledBefore,
      trackNumber: this.trackNumber,
      cargoCoder: this.cargoCode
    }));
  }

  equals(filter: DraftListFilter): boolean {
    let result = this.searchStatus === filter.searchStatus
      && this.client === filter.client
      && DateUtils.isEqualDates(this.after, filter.after)
      && DateUtils.isEqualDates(this.before, filter.before)
      && this.draftId === filter.draftId
      && this.search === filter.search
      && this.companyClient === filter.companyClient
      && DateUtils.isEqualDates(this.scheduledAfter, filter.scheduledAfter)
      && DateUtils.isEqualDates(this.scheduledBefore, filter.scheduledBefore)
      && this.trackNumber === filter.trackNumber
      && this.cargoCode === filter.cargoCode
    ;

    // console.log(`${this.client} == ${filter.client}`);
    // console.log(result);

    return result;
  }

  isEmpty(): boolean {
    return this.equals(DraftListFilter.getEmptyFilter());
  }

  clone(): DraftListFilter {
    return new DraftListFilter(
      this.searchStatus,
      this.after,
      this.before,
      this.client,
      this.draftId,
      this.search,
      this.companyClient,
      this.scheduledAfter,
      this.scheduledBefore,
      this.trackNumber,
      this.cargoCode
    );
  }

  private static getEmptyFilter(): DraftListFilter {
    DraftListFilter.emptyFilter = DraftListFilter.emptyFilter || new DraftListFilter();
    return DraftListFilter.emptyFilter;
  }

  static fromState(state: State): DraftListFilter {
    let data = JSON.parse(state.state);
    // console.log(data);
    let searchStatus = data['searchStatus'] || "";
    let after = data['after'] == null ? null : new Date(data.after);
    let before = data['before'] == null ? null : new Date(data.before);
    let client = data['client'] || null;
    let draftId = data['draftId'] || null;
    let search = data['search'] || null;
    let companyClient = data['companyClient'] || null;
    let scheduledAfter = data['scheduledAfter'] == null ? null : new Date(data.scheduledAfter);
    let scheduledBefore = data['scheduledBefore'] == null ? null : new Date(data.scheduledBefore);
    let trackNumber = data['trackNumber'] || '';
    let cargoCoder = data['cargoCoder'] || '';

    return new DraftListFilter(searchStatus,
      after,
      before,
      client,
      draftId,
      search,
      companyClient,
      scheduledAfter,
      scheduledBefore,
      trackNumber,
      cargoCoder
    );
  }
}
