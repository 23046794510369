<div class="modal fade" tabindex="-1" role="dialog" #ozonImportDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Импорт посылок из Ozon</h4>
        </div>
      </div>
      <div class="modal-body">
        <ozon-parcels-list
          [importResult]="lastImportResult"
          (selectParcels)="onSelectionChanged($event)"
          (clickOnTask)="onClickTask($event)"
          [disableSelectionForImported]="!transportMode"
          [multiselect]="transportMode"
        ></ozon-parcels-list>
      </div>
      <div class="panel-footer time-controls" *ngIf="!transportMode">
        <label>Дата подачи машины</label>
        <div class="form-group" [class.has-error]="!isArrivalDateValid">
          <input type="date" class="form-control" name="arrivalDate" [(ngModel)]="arrivalDate" (ngModelChange)="onChangeInput()">
        </div>
        <label>Время подачи машины</label>
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-addon">с</div>
            <input type="time" class="form-control" name="arrivalTimeSlotStart" [(ngModel)]="arrivalTimeSlotStart" (ngModelChange)="onChangeInput()" required>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-addon">до</div>
            <input type="time" class="form-control" name="arrivalTimeSlotEnd" [(ngModel)]="arrivalTimeSlotEnd" (ngModelChange)="onChangeInput()" required>
          </div>
        </div>
      </div>
      <div class="panel-footer stock-controls" *ngIf="!transportMode">
        <label>Склад</label>
        <div class="stock">
          <select name="storehouse" [(ngModel)]="storehouseId" (ngModelChange)="onSelectStorehouse()">
            <option value="0">- выберите склад -</option>
            <option [value]="storehouse.id" *ngFor="let storehouse of storehouses">{{ storehouse.city.name }}, {{ storehouse.address }}</option>
            <option value="-1">- добавить новый склад -</option>
          </select>
        </div>
      </div>
      <div class="panel-footer" *ngIf="newStoreHouseMode && !transportMode">
        <storehouse-creator (changed)="onChangeInput()"></storehouse-creator>
      </div>
      <div class="panel-footer import-controls">
        <div class="controls">
          <button class="save-btn" [disabled]="!importEnabled" [attr.title]="storehouseId == 0 ? 'Укажите склад' : null" (click)="onImport()">Импорт ({{ selectedParcels.length }})</button>
          <button class="reject-btn" data-dismiss="modal">Отменить</button>
        </div>
      </div>
    </div>
  </div>
</div>
