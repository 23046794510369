
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {UsersFilter} from "../_models/users/users-filter";
import {Observable} from "rxjs";
import {UsersList} from "../_models/users/users-list";
import {UserStat} from "../_models/user-stat";
import {IntervalFilter} from "../_models/users/interval-filter";
import {Message} from "../_models/message";
import {RelativeTimeFilter} from "../_models/users/relative-time-filter";
import {UserLastData} from "../_models/users/user-last-data";
import {FilterType} from "../_utils/online-filter/filter-type";
import {OnlineFilterUtils} from "../_utils/online-filter/online-filter-utils";
import {DateTime} from "date-time-js";
import {HttpResponse} from "@angular/common/http";

const PAGE_SIZE = 15;

@Injectable()
export class UserService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getUsers(page?: number, filter?: UsersFilter): Observable<UsersList> {
    let filterClone = filter && filter.clone() || new UsersFilter();

    let searchParams = filter ? this.createParamsFromFilter(filter) : {};

    searchParams['offset'] = (page || 0) * PAGE_SIZE;
    searchParams['size'] = PAGE_SIZE;

    return this._requestService
      .get('/accounts.json', searchParams).pipe(
      map(r => new UsersList(
        page || 0,
        PAGE_SIZE,
        parseInt(r.headers.get('X-Total-Count')),
        filterClone,
        r.body as UserStat[]
      )))
    ;
  }

  sendMessageByFilter(message: Message, filter: UsersFilter): Observable<HttpResponse<any>> {
    return this._requestService
      .post('/messages.json', {
        message: this.messageToParams(message),
        filter: filter
      })
    ;
  }

  sendMessageByUsers(message: Message, users: UserStat[]): Observable<HttpResponse<any>> {
    let accountsId = users.map(u => u.account.id);

    return this._requestService
      .post('/messages.json', {
        message: this.messageToParams(message),
        accounts: accountsId
      })
    ;
  }

  getUserLastDataByPhone(phone: string): Observable<UserLastData> {
    return this._requestService
      .get(`/support/me/users/phone/${phone}/info.json`).pipe(
      map(r => r.body as UserLastData))
    ;
  }

  private messageToParams(message: Message): any {
    return {
      name: message.name,
      type: message.type,
      messageText: message.text
    };
  }

  private createParamsFromFilter(filter: UsersFilter): any {
    let searchParams = {
      accountType: filter.accountType || null,
      email: filter.email || null,
      phone: filter.phone || null,
      surname: filter.surname || null,
      active: filter.active || null,
      deviceType: filter.deviceType || null,
      company: filter.company || null,
      employerStatus: filter.employerStatus || null,
      taxiTariff: filter.taxiTariff || null
    };
    searchParams = this.applyIntervalFilter(searchParams, 'balance', filter.balance);
    searchParams = this.applyIntervalFilter(searchParams, 'ordersCount', filter.ordersCount);
    searchParams = this.applyRelativeTimeFilter(searchParams, 'lastOrderDate', filter.lastOrderDate);
    searchParams = this.applyOnlineFilter(searchParams, filter.online);
    searchParams = this.applyDateIntervalFilter(searchParams, 'registered', filter.registeredAfter, filter.registeredBefore);

    return searchParams;
  }

  private applyDateIntervalFilter(baseParams: any, filterName: string, after?: Date, before?: Date): any {
    if(after) {
      let name = `${filterName}[after]`;
      baseParams[name] = new DateTime(after).format('yyyy-MM-dd');
    }
    if(before) {
      let name = `${filterName}[before]`;
      baseParams[name] = new DateTime(before).format('yyyy-MM-dd');
    }

    return baseParams;
  }

  private applyIntervalFilter(baseParams: any, filterName: string, filter: IntervalFilter): any {
    let applied = false;
    if(filter.from !== undefined) {
      let name = `${filterName}[from]`;
      baseParams[name] = filter.from;
      applied = true;
    }
    if(filter.to !== undefined) {
      let name = `${filterName}[to]`;
      baseParams[name] = filter.to;
      applied = true;
    }

    if(applied) {
      let name = `${filterName}[operator]`;
      baseParams[name] = filter.including ? 'including' : 'not_including';
    }

    return baseParams;
  }

  private applyRelativeTimeFilter(baseParams: any, filterName: string, filter: RelativeTimeFilter): any {
    if(filter.value !== undefined && filter.unit !== undefined) {

      let valueName = `${filterName}[value]`;
      baseParams[valueName] = filter.value;

      let unitName = `${filterName}[unit]`;
      baseParams[unitName] = filter.unit;

    }

    return baseParams;
  }

  private applyOnlineFilter(baseParams: any, onlineFilter: FilterType): any {
    let filter = OnlineFilterUtils.buildFilter(onlineFilter);
    if(filter.isEmpty())
      return baseParams;

    baseParams['online[from]'] = filter.from && new DateTime(filter.from).format('yyyy-MM-dd HH:mm:ss');
    baseParams['online[to]'] = filter.to && new DateTime(filter.to).format('yyyy-MM-dd HH:mm:ss');
    baseParams['online[never]'] = filter.never;

    return baseParams;
  }

  get requestService(): RequestWithErrorHandlerService {
    return this._requestService;
  }
}
