import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FreighterLoaderTariff} from "../_models/freighter-loader-tariff";

@Component({
  selector: 'loader-tariff-form',
  templateUrl: './loader-tariff-form.component.html',
  styleUrls: ['./loader-tariff-form.component.css']
})
export class LoaderTariffFormComponent implements OnInit {
  @Input() tariff: FreighterLoaderTariff;
  @Output() onSubmit = new EventEmitter<FreighterLoaderTariff>();

  constructor() { }

  ngOnInit() {
  }

  onSave() {
    this.onSubmit.emit(this.tariff);
  }
}
