import {Pipe, PipeTransform} from "@angular/core";
import {OrderDraft} from "../_models/order-draft";
import {DraftUtils} from "../_utils/draft-utils";
import {CAPACITIES} from "../_maps/capacities";

@Pipe({name: "draftCapacities"})
export class DraftCapacitiesPipe implements PipeTransform {
  transform(draft: OrderDraft): string {
    return DraftUtils.getCapacities(draft).map(c => CAPACITIES[c] || c).join(' т., ') + ' т.';
  }
}
