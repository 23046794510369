import {Message} from './message'
import {TaxiMessage} from "./taxi-message";

export class CarFoundMessage implements TaxiMessage
{
    constructor(public data:MessageEvent, public draftId: number)
    {

    }

    getDraftId(): number {
      return this.draftId;
    }

}
