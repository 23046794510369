
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Account} from "../_models/account";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class AccountService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getAccountByName(name: string): Observable<Account> {
    return this._requestService
      .get('/account/by/name.json', { name }).pipe(
      map(r => r.body as Account))
    ;
  }

  updateSelfAccount(account: Account): Observable<HttpResponse<any>> {
    return this._requestService
      .put('/me.json', {
        name: account.name,
        surname: account.surname,
        patronymic: account.patronymic,
        birthday: account.birthday,
        gender: account.gender,
        email: account.email,
        city: account.city
      })
    ;
  }
}
