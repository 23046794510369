export class FreighterLoaderTariff {
  id: number;
  name: string;
  min_price: number;
  min_hours: number;
  additional_hour_price: number;
  on_the_way_hour_price: number;
  assembler_min_price: number;
  assembler_min_hours: number;
  assembler_additional_hour_price: number;
  lifting_min_price: number;
  lifting_min_hours: number;
  lifting_additional_hour_price: number;
}
