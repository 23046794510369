<div class="notifications-box" [class.visible]="_notificationService.activeNotificationsList">
    <div class="list-controls">
      <button class="btn btn-default" (click)="onHideAll()" *ngIf="notifications.length > 0">
        <span class="glyphicon glyphicon-eye-close"></span>
        Скрыть все
      </button>
      <button class="btn btn-default" [routerLink]="[ '/support-notifications' ]" *ngIf="isSupportNotificationsEnabled">
        <span class="glyphicon glyphicon-th-list"></span>
        Показать все
      </button>
    </div>
    <notice
            *ngFor="let notice of notifications"
            [item]=notice
    ></notice>
</div>
