import {Injectable} from "@angular/core";
import {OrderCalculation} from "../_models/calculation.delivery/order-calculation";
import {DestinationCalculation} from "../_models/calculation.delivery/destination-calculation";
import {DeliveryHistoryRecord} from "../_models/delivery-history-record";
import {Order} from "../_models/order";

/**
 * Сервис для работы с калькуляциями доставки
 */
@Injectable()
export class DeliveryCalculationService {
  /**
   * Расчёт итоговых значений калькуляции заказа
   *
   * @param orderCalculation
   */
  calcOrderTotal(orderCalculation: OrderCalculation): void {
    for(let calculation of orderCalculation.destinationCalculations)
      this.calcDestinationTotal(calculation);

    orderCalculation.total = DeliveryCalculationService.calcHistoryTotal(
      orderCalculation.destinationCalculations.map(c => c.total)
    );
  }

  /**
   * Расчёт итоговых значений кулькуляции адреса
   *
   * @param destinationCalculation
   */
  calcDestinationTotal(destinationCalculation: DestinationCalculation): void {
    destinationCalculation.total = DeliveryCalculationService.calcHistoryTotal(destinationCalculation.historyRecords);
  }

  /**
   * Создание калькуляци для заказа
   *
   * @param order
   */
  createOrderCalculationForOrder(order: Order): OrderCalculation {
    let calculation = new OrderCalculation();
    calculation.order = order;
    calculation.destinationCalculations = [];

    for(let period of order.periods) {
      for(let destination of period.destinations) {
        let destinationCalculation = new DestinationCalculation();
        destinationCalculation.destination = destination;
        destinationCalculation.historyRecords = [];

        calculation.destinationCalculations.push(destinationCalculation);
      }
    }

    return calculation;
  }

  private static calcHistoryTotal(records: DeliveryHistoryRecord[]): DeliveryHistoryRecord {
    let totalRecord = new DeliveryHistoryRecord();

    for(let record of records) {
      for(let component in record) {
        if(!record.hasOwnProperty(component) || component == 'reason')
          continue;

        let value = record[component];
        if(value)
          totalRecord[component] = (totalRecord[component] || 0) + value;
      }
    }

    return totalRecord;
  }
}
