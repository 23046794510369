
import {finalize, switchMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {FreighterLoaderTariff} from "../_models/freighter-loader-tariff";
import {Subject} from "rxjs";
import {FreighterLoaderTariffService} from "../_services/freighter-loader-tariff.service";
import {LoaderService} from "../_services/loader.service";

@Component({
  selector: 'app-loader-tariffs',
  templateUrl: './loader-tariffs.component.html',
  styleUrls: ['./loader-tariffs.component.css']
})
export class LoaderTariffsComponent implements OnInit {
  tariffs: FreighterLoaderTariff[] = [];

  private reloadStream = new Subject<void>();

  constructor(
    private freighterLoaderTariffService: FreighterLoaderTariffService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.reloadStream.pipe(
      switchMap(() => {
        this.loaderService.show();
        return this.freighterLoaderTariffService
          .getTariffs().pipe(
          finalize(() => this.loaderService.hide()))
      }))
      .subscribe(
        tariffs => this.tariffs = tariffs,
        () => {}
      );

    this.reloadTariffs();
  }

  reloadTariffs() {
    this.reloadStream.next();
  }

  deleteTariff(tariff: FreighterLoaderTariff) {
    this.loaderService.show();
    this.freighterLoaderTariffService
      .deleteTariff(tariff).pipe(
      finalize(() => this.loaderService.hide()))
      .toPromise()
      .then(() => this.reloadTariffs())
      .catch(() => {});
  }

  onDelete(tariff: FreighterLoaderTariff) {
    if(confirm("Подтверждаете удаление тарифа?"))
      this.deleteTariff(tariff);
  }
}
