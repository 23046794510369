export const PAYMENT_STATUSES = {
  ISSUED: 'issued',
  AUTHENTICATION: 'authentication',
  AWAITING_AUTHENTICATION: 'awaiting_authentication',
  AUTHORIZED: 'authorized',
  COMPLETING: 'completing',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DECLINED: 'declined',
  REFUNDED: 'refunded',
};

export const PAYMENT_STATUS_TRANSLATES = {
  'awaiting': 'ожидание оплаты',
  'issued': 'счёт выставлен',
  'authentication': 'холдирование',
  'awaiting_authentication': 'ожидание холдирования',
  'authorized': 'средства захолдир',
  'completing': 'завершение операции',
  'completed': 'операция завершена',
  'cancelled': 'операция отменена',
  'declined': 'операция отклонена',
  'refunded': 'средства возвращены',
};
