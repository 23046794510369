import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {OptimizationTaskDestination} from "../_models/optimization-task-destination";
import {
  ComplexDeliveryDestinationsSelectorComponent
} from "../complex-delivery-destinations-selector/complex-delivery-destinations-selector.component";

@Component({
  selector: 'complex-delivery-destinations-selector-dialog',
  templateUrl: './complex-delivery-destinations-selector-dialog.component.html',
  styleUrls: ['./complex-delivery-destinations-selector-dialog.component.css']
})
export class ComplexDeliveryDestinationsSelectorDialogComponent implements OnInit, OnDestroy {
  @Output() selected = new EventEmitter<OptimizationTaskDestination[]>();
  @ViewChild('destinationsDialog', { static: true }) dialogEl: ElementRef;
  @ViewChild(ComplexDeliveryDestinationsSelectorComponent) selectorComponent: ComplexDeliveryDestinationsSelectorComponent;

  destinations: OptimizationTaskDestination[] = [];
  selectedDestinations: OptimizationTaskDestination[] = [];
  title: string;
  actionName: string;
  selectAllByDefault: boolean = true;

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  showDialog(destinations: OptimizationTaskDestination[], title: string, actionName: string, selectAll: boolean = true) {
    this.destinations = destinations;
    $(this.dialogEl.nativeElement).modal('show');
    this.title = title;
    this.actionName = actionName;
    this.selectAllByDefault = selectAll;
    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      })
    }
  }

  private hideDialog() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  onSelect(): void {
    this.hideDialog();

    this.selected.emit(this.selectedDestinations);
  }

  onSelectionChange(destinations: OptimizationTaskDestination[]): void {
    this.selectedDestinations = destinations;
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
