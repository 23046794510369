import {Injectable} from "@angular/core";
import {MapService} from "./map.service";
import {WebSocketService} from "./web-socket.service";
import {UserInfoService} from "./user-info.service";
import {MapTransport} from "../_models/map-transport";
import {Observable, Subject, Subscription} from "rxjs";
import {ScreenConnectionWithTransportParams} from "../_websocket/connections/screen-connection-with-transport-params";
import {Message} from "../_websocket/messages/message";
import {UpFeed} from "../_websocket/messages/up-feed";
import {CarFeed} from "../_websocket/messages/car-feed";
import {Point} from "../_models/point";
import {ScreenTransportParams} from "../_models/screen-transport-params";
import {debounceTime} from "rxjs/operators";

@Injectable()
export class CrewsMapService {
  private transports = new Map<number, MapTransport>();

  private mapBoundsChangeSubscription: Subscription;
  private selectTransportOnMapSubscription: Subscription;
  private screenBoundsChangeStream = new Subject<google.maps.LatLngBounds>();

  private crewsConnection: ScreenConnectionWithTransportParams;

  private transportParams: ScreenTransportParams;

  private selectCrewStream = new Subject<MapTransport>();

  constructor(private mapService: MapService, private websocketService: WebSocketService, private userInfoService: UserInfoService) {
    this.initScreenBoundsChanges();
  }

  showCrewsOnMap(transportParams: ScreenTransportParams): void {
    this.transportParams = transportParams;

    this.crewsConnection = this.userInfoService.isPrivilegedUser()
      ? this.websocketService.createSupportScreenConnection()
      : this.websocketService.createFreighterScreenConnection();

    this.crewsConnection.start();
    this.crewsConnection.message.subscribe(m => this.onCrewsScreenMessage(m))

    this.selectTransportOnMapSubscription = this.mapService.getSelectTransportObservable().subscribe(
      car => this.onSelectTransportOnMap(car)
    );

    this.onUpdateScreenBounds(this.mapService.getMapBounds());
    this.mapBoundsChangeSubscription = this.mapService.getMapBoundsChangeObservable().subscribe(
      bounds => this.onUpdateScreenBounds(bounds)
    );
  }

  removeCrewsFromMap(): void {
    this.mapBoundsChangeSubscription.unsubscribe();
    this.selectTransportOnMapSubscription.unsubscribe();
    this.crewsConnection.close();
    this.mapService.removeTransports();
    this.transports.clear();
  }

  getSelectCrewObservable(): Observable<MapTransport> {
    return this.selectCrewStream.asObservable();
  }

  private initScreenBoundsChanges(): void {
    this.screenBoundsChangeStream = new Subject<google.maps.LatLngBounds>();
    this.screenBoundsChangeStream
      .pipe(debounceTime(1000))
      .subscribe({
        next: bounds => this.updateScreenBounds(bounds),
        error: () => this.initScreenBoundsChanges(),
      });
  }

  private onCrewsScreenMessage(message: Message): void {
    if(message instanceof UpFeed)
      this.processUpdateOfCarsFeed(message);
    else if(message instanceof CarFeed)
      this.processCarsFeed(message);
  }

  private onSelectTransportOnMap(car: MapTransport): void {
    this.selectCrewStream.next(car);
  }

  private onUpdateScreenBounds(bounds: google.maps.LatLngBounds): void {
    this.screenBoundsChangeStream.next(bounds);
  }

  private processCarsFeed(carFeed: CarFeed): void {
    let newTransports = new Map<number, MapTransport>();

    for(let feedTransport of carFeed.transports) {
      newTransports.set(
        feedTransport.transport.id,
        this.transports.get(feedTransport.transport.id) || MapTransport.createFromFeedTransport(feedTransport, true)
      );
    }

    this.transports = newTransports;

    this.refreshMapCars();
  }

  private refreshMapCars(): void {
    this.mapService.displayTransports(Array.from(this.transports.values()));
  }

  private processUpdateOfCarsFeed(carFeed: UpFeed): void {
    let refreshRequired = false;
    for(let feedTransport of carFeed.transports) {
      if(this.transports.has(feedTransport.transport.id)) {
        this.transports.get(feedTransport.transport.id).updateFromFeedTransport(feedTransport);
      } else {
        this.transports.set(feedTransport.transport.id, MapTransport.createFromFeedTransport(feedTransport, true));
        refreshRequired = true;
      }
    }

    if(refreshRequired)
      this.refreshMapCars();
  }

  private updateScreenBounds(bounds: google.maps.LatLngBounds): void {
    let northEastPoint = new Point();
    northEastPoint.lat = bounds.getNorthEast().lat();
    northEastPoint.lng = bounds.getNorthEast().lng();

    let southWestPoint = new Point();
    southWestPoint.lat = bounds.getSouthWest().lat();
    southWestPoint.lng = bounds.getSouthWest().lng();

    this.crewsConnection.registerScreen(northEastPoint, southWestPoint, this.transportParams);
  }

}
