
import {finalize} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserInfoService} from "../_services/user-info.service";
import {Account} from "../_models/account";
import {CompanyClient} from "../_models/company-client";
import {LegalEntityService} from "../_services/legal-entity.service";
import {LoaderService} from "../_services/loader.service";
import {CompanyClientLegalEntity} from "../_models/company-client-legal-entity";
import {AlertService} from "../_services/alert.service";
import {LegalEntity} from "../_models/legal-entity";
import {LogoutService} from "../_services/logout.service";
import {AccountService} from "../_services/account.service";
import {HttpErrorResponse} from "@angular/common/http";
import {DateTime} from "date-time-js";
import {Uploader} from "../_upload/uploader";
import {CompleteAllUploadsHandler} from "../_upload/complete-all-uploads-handler";
import {FileErrorHandler} from "../_upload/file-error-handler";
import {UploadError} from "../_upload/upload-error";
import {environment} from "../../environments/environment";
import {UserStat} from "../_models/user-stat";
import {CompanyClientService} from "../_services/company-client.service";
import {BankCard} from "../_models/bank-card";
import {BankCardService} from "../_services/bank-card.service";
import {NewUserComponent} from "../new-user/new-user.component";
import {UserNameUtils} from "../_utils/user-name-utils";
import {UserEditorComponent} from "../user-editor/user-editor.component";
import {BankCardUtils} from "../_utils/bank-card-utils";
import {UploaderService} from "../_services/uploader.service";
import {ActivatedRoute, Params} from "@angular/router";
import {Subscription} from "rxjs";
import {ClipboardService} from "../_services/clipboard.service";
import {PromoService} from "../_services/promo.service";
import {PromoCodePresenter} from "../_models/promo-code-presenter";
import {TrackingService} from "../_models/tracking-service";
import {TrackingServiceService} from "../_services/tracking-service.service";

class Email {
  constructor(public email = '') {
  }
}

class Marketplace {
  isActive: boolean = false;
  name: string;
  logoUrl: string;
  trackingService: TrackingService;
}

const AVAILABLE_MARKETPLACES = [
  [ 'Ozon', '/assets/images/ozon/ozon-logo.svg', 'ozon' ]
];

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy, CompleteAllUploadsHandler, FileErrorHandler {
  @ViewChild(NewUserComponent) newUserDialog: NewUserComponent;
  @ViewChild(UserEditorComponent) userEditDialog: UserEditorComponent;

  account: Account;
  company: CompanyClient;
  accountPicture: string;
  userName: string;
  legalEntities: CompanyClientLegalEntity[] = [];
  companyUsers: UserStat[] = [];
  uploadPhotoActive = false;
  bankCards: BankCard[] = [];
  emails: Email[] = [];
  emailErrors = new Set<string>();
  isEmailsAllowed = false;
  promocodeApplying = false;
  promocodeError = '';
  promocodeSuccess = false;
  marketplaces: Marketplace[] = [];

  uploader = new Uploader({
    allowedMimeType: ['image/gif', 'image/jpeg', 'image/png'],
    itemAlias: 'accountPhoto[pictureFile]',
    url: ''
  });

  private needUploadPhoto = false;
  private uploadErrors: UploadError[] = [];
  private queryParamsSubscribe: Subscription;
  activePromoCode: PromoCodePresenter|null = null;

  constructor(
    private route: ActivatedRoute,
    public userInfoService: UserInfoService,
    private legalEntityService: LegalEntityService,
    private accountService: AccountService,
    private companyClientService: CompanyClientService,
    private bankCardService: BankCardService,
    private logoutService: LogoutService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private uploaderService: UploaderService,
    private clipboardService: ClipboardService,
    private promoService: PromoService,
    private trackingService: TrackingServiceService
  ) {
    this.uploader.subscribeOnCompleteAll(this);
    this.uploader.subscribeOnFileError(this);
  }

  ngOnInit() {
    this.queryParamsSubscribe = this.route.queryParams.subscribe((params: Params) => {
      this.initAddCardResult(params);
    });

    this.setupAccount();
    this.loadPromoCode();
  }

  private initAddCardResult(params: Params): void {
    let addCardStatus = params['addCard'];
    if(!addCardStatus)
      return;

    let isAddCardFail = addCardStatus == 'fail';
    if(isAddCardFail) {
      let addCardFailMessage = (params['failMessage'] || '').replaceAll('+', ' ');
      if(addCardFailMessage != '')
        addCardFailMessage += '.';

      this.alertService.error(`При добавлении карты произошла ошибка. ${addCardFailMessage}`);
    } else {
      this.alertService.success('Карта прикреплена.');
    }

  }

  private setupAccount() {
    this.account = this.userInfoService.userInfo.account;
    this.company = this.userInfoService.userInfo.account.company_client;
    this.applyAccountPicture();
    this.applyUserName();

    this.initMarketplaces();
    this.initLegalEntities();
    this.initCompanyUsers();
    this.initBankCards();
    this.initEmailsForReport();
  }

  private initLegalEntities() {
    if(this.company == null) {
      this.legalEntities = [];
      return;
    }

    this.loaderService.show();
    this.legalEntityService
      .getCompanyLegalEntities(this.company.id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        entities => this.applyLegalEntities(entities),
        e => {
          if(e instanceof HttpErrorResponse) {
            const message = e.error.message;
            this.alertService.error(`Ошибка загрузки юр. лиц (${message})`)
          }
        }
      )
    ;
  }

  private initMarketplaces(): void {
    if(!this.userInfoService.isCompanyClientManager())
      return;

    this.trackingService
      .getAvailableTrackingServices(true)
      .subscribe({
        next: services => this.applyAvailableTrackingServices(services),
        error: () => {}
      })
  }

  private applyAvailableTrackingServices(availableTrackingServices: TrackingService[]): void {
    let availableTrackingServiceIdentifiers = availableTrackingServices.map(d => d.identifier);

    this.marketplaces = AVAILABLE_MARKETPLACES.map(d => {
      let [name, logoUrl, trackingServiceIdentifier] = d;
      let marketplace = new Marketplace();
      marketplace.name = name;
      marketplace.logoUrl = logoUrl;

      let trackingService = new TrackingService();
      trackingService.name = name;
      trackingService.identifier = trackingServiceIdentifier;

      marketplace.trackingService = trackingService;
      marketplace.isActive = availableTrackingServiceIdentifiers.includes(trackingServiceIdentifier);

      return marketplace;
    });
  }

  private applyLegalEntities(legalEntities: CompanyClientLegalEntity[]): void {
    this.legalEntities = legalEntities;
    if(legalEntities.length == 0)
      return;

    let activeIndex = legalEntities.findIndex(e => e.active);
    if(activeIndex != -1)
      legalEntities.unshift(legalEntities.splice(activeIndex, 1)[0]);
  }

  private initCompanyUsers() {
    if(this.company == null || (!this.userInfoService.isCompanyClientManager() && !this.userInfoService.isPrivilegedUser())) {
      this.companyUsers = [];
      return;
    }

    this.loaderService.show();
    this.companyClientService
      .getUsers(this.company).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        users => this.companyUsers = users,
        e => {
          if(e instanceof HttpErrorResponse) {
            const message = e.error.message;
            this.alertService.error(`Ошибка загрузки пользователей (${message})`)
          }
        }
      )
    ;
  }

  private initEmailsForReport(): void {
    if(this.company == null || !this.userInfoService.isCompanyClientManager()) {
      this.isEmailsAllowed = false;
      this.emails = [];
      return;
    }

    this.isEmailsAllowed = true;

    this.loaderService.show();
    this.companyClientService
      .getEmailsForReport(this.company).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        emails => {
          this.emails = emails.map(email => new Email(email));
          this.syncEmailsControls();
        },
        () => {}
      )
    ;
  }

  private initBankCards() {
    this.loaderService.show();
    this.bankCardService
      .getMyCards().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        cards => this.bankCards = cards,
        () => {}
      )
    ;
  }

  private removeCard(card: BankCard) {
    this.loaderService.show();
    this.bankCardService
      .removeCard(card).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success('Карта удалена');
          this.initBankCards();
        },
        e => {
          if(e instanceof HttpErrorResponse && e.status == 405)
            this.alertService.warning('Карта не может быть удалена, так как она требуется для оплаты активного заказа.')
        }
      );
  }

  private activateCard(card: BankCard) {
    this.loaderService.show();
    this.bankCardService
      .activateCard(card).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.initBankCards();
        },
        () => {}
      );
  }

  private logout() {
    this.loaderService.show();
    this.logoutService
      .logout()
      .subscribe(
        () => location.href = '/login',
        () => {}
      )
    ;
  }

  private saveProfile() {
    this.parseUserName();
    this.parseBirthday();

    this.loaderService.show();
    this.loaderService.showText('Сохранение общей информации...')
    this.accountService
      .updateSelfAccount(this.account).pipe(
      finalize(() => this.loaderService.hide()))
      .toPromise()
      .then(() => {
        if(!this.isEmailsAllowed)
          return;

        this.emailErrors.clear();

        this.loaderService.show();
        this.loaderService.showText('Обновление электронной почты...')
        return this.companyClientService
          .updateEmailsForReport(this.company, this.emails.map(email => email.email).filter(email => email.trim() != '')).pipe(
          finalize(() => this.loaderService.hide()))
          .toPromise()
        ;
      })
      .then(() => {
        this.alertService.success("Профиль сохранён.");
        this.uploadPhoto();
      })
      .catch(e => {
        if(e instanceof HttpErrorResponse) {
          if(e.status === 400) {
            const response = e.error;
            this.alertService.warning(`Профиль не сохранён. ${response.message}`);

            if(response['invalid_emails'])
              this.emailErrors = new Set<string>(response['invalid_emails']);
          }
        }
      })
      ;
  }

  private uploadPhoto() {
    if(!this.needUploadPhoto)
      return;

    console.log('upload photos');

    this.loaderService.show();
    this.loaderService.showText("Загрузка фото...");
    this.uploaderService.prepareToUpload(
      `${environment.apiEndpoint}/me/photo.json`,
      this.uploader
    );
    this.cleanUploadError();
    this.uploader.uploadAll();
  }

  private parseUserName(): void {
    UserNameUtils.nameToSNP(this.userName, this.account);
  }

  private parseBirthday(): void {
    if((<any>this.account.birthday) instanceof Date) {
      this.account.birthday = DateTime.format(this.account.birthday, 'yyyy-MM-dd');
    }
  }

  getLegalEntityName(entity: LegalEntity): string {
    return entity.short_name || entity.name;
  }

  private applyAccountPicture(): void {
    if(this.account.picture_sizes && this.account.picture_sizes["320"]) {
      this.accountPicture = `url(${this.account.picture_sizes["320"]})`;
    } else {
      this.accountPicture = null;
    }
  }

  private applyUserName(): void {
    this.userName = UserNameUtils.SNPToName(this.account);
  }

  private reloadAccount(): void {
    this.loaderService.show();
    this.userInfoService
      .setup()
      .then(() => this.loaderService.hide())
      .then(() => this.setupAccount())
    ;
  }

  private switchUserActivation(user: UserStat) {
    this.loaderService.show();
    this.companyClientService
      .updateUserActive(this.company, user.account, !user.active).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUsersUpdated(),
        () => {}
      )
    ;
  }

  private syncEmailsControls(): void {
    if(!this.isEmailsAllowed) {
      this.emails = [];
      return;
    }

    if(this.emails.every(email => email.email.trim() !== '') || this.emails.length == 0)
      this.emails.push(new Email());
  }

  private removeEmail(index: number): void {
    this.emails.splice(index, 1);
    this.syncEmailsControls();
  }

  private enterPromocode(code: string): void {
    this.promocodeError = '';
    this.promocodeApplying = true;
    this.promocodeSuccess = false;
    this.promoService
      .apply(code)
      .pipe(finalize(() => this.promocodeApplying = false))
      .subscribe({
        next: () => {
          this.promocodeSuccess = true;
        },
        error: r => {
          if(r instanceof HttpErrorResponse) {
            switch(r.status) {
              case 404:
                this.promocodeError = 'Промокод не найден';
                this.alertService.clear();
                break;
              case 405:
                this.promocodeError = 'Промокод уже был использован';
                this.alertService.clear();
                break;
              case 409:
                this.promocodeError = 'Нельзя ввести промокод, пока активен текущий';
                this.alertService.clear();
                break;
            }
          }
        }
      })
    ;
  }

  private loadPromoCode(): void {
    this.promoService
      .list(['entered', 'applied'])
      .subscribe({
        next: codes => this.activePromoCode = codes.length == 0 ? null : codes[0],
        error: () => {}
      })
    ;
  }

  private connectToMarketplace(marketplace: Marketplace): void {
    this.loaderService.show();
    this.companyClientService
      .addRegistrationParams(marketplace.trackingService.identifier)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => {
          this.loaderService.show();
          this.userInfoService
            .reload()
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe({
              next: () => {
                this.initMarketplaces();
              },
              error: () => {}
            })
          ;
        },
        error: () => {}
      })
  }

  hasUploadErrors() {
    return this.uploadErrors.length > 0;
  }

  isCardExpired(card: BankCard): boolean {
    return BankCardUtils.isCardExpired(card);
  }

  openNewUserDialog(): void {
    if(this.company == null)
      return;

    this.newUserDialog.showForCompany(this.company);
  }

  openEditUserDialog(user: UserStat): void {
    this.userEditDialog.showForCompanyAndUser(this.company, user.account, user.role);
  }

  onSaveProfile() {
    console.log('birthday:', this.account.birthday);
    this.saveProfile();
  }

  private cleanUploadError() {
    this.uploadErrors = [];
  }

  onLogout(): boolean {
    this.logout();
    return false;
  }

  onFileSelected(): void {
    console.log('file was selected');
    this.needUploadPhoto = true;
  }

  onCompleteAllUploads() {
    this.loaderService.hide();
    if(this.hasUploadErrors()) {
      this.uploader.clearQueue();
      let result = JSON.parse(this.uploadErrors[0].response);
      this.alertService.error(`Ошибка загрузки фото: ${result.message} (${result.code})`);
    } else {
      this.alertService.success("Профиль сохранён");
      this.uploadPhotoActive = false;
      this.needUploadPhoto = false;
      this.reloadAccount();
    }
  }

  onFileUploadError(error: UploadError) {
    console.log('upload error');
    console.log(error);

    this.uploadErrors.push(error);
  }

  onOpenNewUserDialog(): void {
    this.openNewUserDialog();
  }

  onUsersUpdated(): void {
    this.initCompanyUsers();
  }

  onEditUser(user: UserStat): void {
    this.openEditUserDialog(user);
  }

  onSwitchUserActivation(user: UserStat): void {
    if(confirm(user.active ? 'Деактивировать пользователя?' : 'Aктивировать пользователя?'))
      this.switchUserActivation(user);
  }

  onRemoveBankCard(card: BankCard): void {
    if(confirm('Удалить карту?'))
        this.removeCard(card);
  }

  onActivateCard(card: BankCard): void {
    this.activateCard(card);
  }

  onAddCard(): void {
    this.bankCardService.addCard();
  }

  onClickPromoCode(): void {
    this.clipboardService.saveToClipboard(this.account.referral_promo, `Код '${this.account.referral_promo}' скопирован в буфер обмена`);
  }

  onChangeEmail(): void {
    this.syncEmailsControls();
  }

  onRemoveEmail(index: number): void {
    this.removeEmail(index);
  }

  onEnterPromocode(code: string): void {
    if(code != '')
      this.enterPromocode(code);
  }

  onConnectToMarketplace(marketplace: Marketplace): void {
    this.connectToMarketplace(marketplace);
  }

  ngOnDestroy(): void {
    this.queryParamsSubscribe.unsubscribe();
  }
}
