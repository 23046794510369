<tr>
  <th>Включённое время работы на точках</th>
  <td>{{ calculation.min_duration }} мин.</td>
</tr>
<tr *ngIf="calculation.additional_time_cost > 0">
  <th>Дополнительное время</th>
  <td>
    {{ calculation.additional_period_price|formatCost }} X {{ calculation.count_additional_periods }} по {{ calculation.additional_period_tariffication }} мин.
    = {{ calculation.additional_time_cost|formatCost }}
  </td>
</tr>
<tr *ngIf="calculation.tariff_price > 0">
  <th>Расстояние</th>
  <td>{{ calculation.tariff_price|formatCost }} за {{ calculation.tariff_distance }} км</td>
</tr>
<tr *ngIf="calculation.additional_distance_cost > 0">
  <th>Дополнительное расстояние</th>
  <td>
    {{ calculation.additional_distance_step_price|formatCost }} x {{ calculation.count_additional_distance_steps }} по {{ calculation.additional_distance_step }} км
    = {{ calculation.additional_distance_cost|formatCost }}
  </td>
</tr>
