<div class="form-row">
  <div class="column">
    <div class="address-field fields-row">
      <address-field
        [point]="destination.destination"
        (onChange)="onChangeAddress($event)"
        [isValid]="validateState.destination"
        [disabled]="!editable"
        [context]="geoContext"
        placeholder="Адрес доставки"
      ></address-field>
      <div *ngIf="!validateState.destination" class="error">{{ validateState.destinationMsg }}</div>
    </div>
    <div class="time-slot fields-row" *ngIf="slotEnabled">
      <div>
        <order-panel-datepicker
                [(value)]="timeSlotBegin"
                [isValid]="validateState.timeSlotBegin"
                [readonly]="!editable"
                [timepicker]="timeSlotBeginPickerOptions"
                (valueChange)="onChange()"
                name="slotBegin"
                required
                ngDefaultControl
        ></order-panel-datepicker>
        <div *ngIf="!validateState.timeSlotBegin" class="error">{{ validateState.timeSlotBeginMsg }}</div>
      </div>
      <div>
        <order-panel-datepicker
          [(value)]="timeSlotEnd"
          [isValid]="validateState.timeSlotEnd"
          [readonly]="!editable"
          [timepicker]="timeSlotEndPickerOptions"
          (valueChange)="onChange()"
          name="slotEnd"
          required
          ngDefaultControl
        ></order-panel-datepicker>
        <div *ngIf="!validateState.timeSlotEnd" class="error">{{ validateState.timeSlotEndMsg }}</div>
      </div>
    </div>
    <div class="fields-row">
      <textarea [readonly]="!editable" name="comment" [(ngModel)]="destination.comment" class="text-area text-input full-width" rows="5" placeholder="Комментарий к заказу"></textarea>
    </div>
    <div class="tracks" *ngIf="destination.tracks && destination.tracks.length > 0">
      <div class="header">Отслеживание</div>
      <div *ngFor="let track of destination.tracks" class="track-number-container">
        {{ trackingServiceIdentifierToName(track.service) }}:
        <span class="track-number" title="трек-номер Mover`а" (click)="onClickTrackNumber(track.internal_track_number)">{{ track.internal_track_number }}</span>
        <span *ngIf="track.external_track_number">
                    <span class="glyphicon glyphicon-link"></span>
                    <span class="track-number" title="трек-номер '{{ trackingServiceIdentifierToName(track.service) }}'" (click)="onClickTrackNumber(track.external_track_number)">{{ track.external_track_number }}</span>
                  </span>
      </div>
    </div>
  </div>
  <div class="column">
    <!--<div class="fields-row">-->
      <!--<input type="text" name="order_code" [(ngModel)]="destination.order_code" class="text-input full-width" placeholder="id Груза">-->
    <!--</div>-->
    <div class="fields-row cargo-props">
      <div>
        <input type="text" name="length" [readonly]="!editable" [(ngModel)]="destination.length" placeholder="Д" title="длина в см" class="text-input full-width" (ngModelChange)="onChange()">
      </div>
      <div>
        <input type="text" name="width" [readonly]="!editable" [(ngModel)]="destination.width" placeholder="Ш" title="ширина в см" class="text-input full-width" (ngModelChange)="onChange()">
      </div>
      <div>
        <input type="text" name="height" [readonly]="!editable" [(ngModel)]="destination.height" placeholder="В" title="высота в см" class="text-input full-width" (ngModelChange)="onChange()">
      </div>
      <div>
        <input type="text" name="weight" [readonly]="!editable" [(ngModel)]="destination.weight" placeholder="Вес" title="вес в кг" class="text-input full-width" (ngModelChange)="onChange()">
      </div>
    </div>
    <div class="fields-row cost">
      <div class="input">
        <input type="text" name="order_code" [readonly]="!editable" [(ngModel)]="destination.order_code" placeholder="Номер заказа" title="Номер заказа" class="text-input full-width" (ngModelChange)="onChange()">
      </div>
      <div class="external-link">
        <a [href]="destination.external_link" target="_blank" *ngIf="destination.external_link" title="Перейти к сделке"><span class="glyphicon glyphicon-new-window"></span></a>
      </div>
    </div>
    <div class="fields-row cost">
      <div class="input">
        <input type="text" name="cargo_cost" [readonly]="!editable" [(ngModel)]="destination.order_cost" placeholder="Стоимость груза" title="Стоимость груза" class="text-input full-width" (ngModelChange)="onChange()">
      </div>
      <div class="currency">₽</div>
    </div>
    <div class="fields-row cost">
      <div class="input">
        <input type="text" name="delivery_cost" [readonly]="!editable" [(ngModel)]="destination.delivery_cost" placeholder="Стоимость доставки для клиента" title="Стоимость доставки для клиента" class="text-input full-width" (ngModelChange)="onChange()">
      </div>
      <div class="currency">₽</div>
    </div>
    <div class="fields-row cost">
      <div class="input">
        <input type="text" name="discount" [readonly]="!editable" [(ngModel)]="destination.discount" placeholder="Скидка на заказ" title="Скидка на заказ" class="text-input full-width" (ngModelChange)="onChange()">
      </div>
      <div class="currency">₽</div>
    </div>
    <div class="fields-row assembly" *ngIf="assemblyEnabled">
      <div class="input">
        <input type="number" name="assembly_count" [readonly]="!editable" [(ngModel)]="destination.assembly_count" (ngModelChange)="onChangeAssemblyCount()" placeholder="Количество" title="Количество сборок" class="text-input full-width">
      </div>
      <label><input type="checkbox" name="assembly" [disabled]="!editable" [(ngModel)]="destination.assembly" (ngModelChange)="onChangeAssembly()"> Нужна сборка</label>
    </div>
    <div class="fields-row">
<!--      <textarea name="order_description" [readonly]="!editable" [(ngModel)]="destination.order_description" class="text-area text-input full-width" rows="5" placeholder="Состав заказа" (ngModelChange)="onChange()" (click)="onEditCargo()"></textarea>-->
      <button class="cargo-btn" [class.active]="isCargoOpened && !isCargoClosing" (click)="onEditCargo()"><div class="caption"><span class="glyphicon glyphicon-list-alt"></span> груз ({{ cargoCount }})</div></button>
    </div>
  </div>
  <div class="column">
    <div class="fields-row">
      <input type="text" name="client_name" [readonly]="!editable" [(ngModel)]="destination.client_name" class="text-input full-width" placeholder="ФИО клиента" (ngModelChange)="onChange()">
      <div *ngIf="!validateState.clientName" class="error">{{ validateState.clientNameMsg }}</div>
    </div>
    <div class="fields-row">
      <input type="text" name="client_phone" mask="+0 (000) 000 00 00" [showMaskTyped]="true" [readonly]="!editable" [(ngModel)]="destination.client_phone" class="text-input full-width" [placeholder]="phonePlaceholder" (ngModelChange)="onChange()">
      <div *ngIf="!validateState.clientPhone" class="error">{{ validateState.clientPhoneMsg }}</div>
    </div>
    <div class="fields-row">
      <input type="text" name="client_email" [readonly]="!editable" [(ngModel)]="destination.client_email" class="text-input full-width" [placeholder]="emailPlaceholder" (ngModelChange)="onChange()">
    </div>
    <div class="fields-row">
      <div class="input">
        <select class="form-control" [(ngModel)]="deliveryTo" name="deliveryTo" [disabled]="!editable" (ngModelChange)="onChange()">
          <option value="">- место доставки -</option>
          <option *ngFor="let v of deliveryVariants|mapToIterable" [value]="v.key">{{ v.val }}</option>
        </select>
      </div>
    </div>
    <div class="fields-row floor">
      <div class="input">
        <input type="text" name="floor" [readonly]="!editable" [(ngModel)]="destination.floor" class="text-input full-width" placeholder="Этаж" title="Этаж" (ngModelChange)="onChange()">
      </div>
      <label><input type="checkbox" name="has_freight_elevator" [disabled]="!editable" [(ngModel)]="destination.has_freight_elevator" (ngModelChange)="onChange()"> Есть грузовой лифт</label>
    </div>
<!--    <div class="fields-row select">-->
<!--      <div class="label">-->
<!--        <label>Приоритет</label>-->
<!--      </div>-->
<!--      <div class="input">-->
<!--        <select name="priority" [disabled]="!editable" [(ngModel)]="destination.priority">-->
<!--          <option value="0">Обычный</option>-->
<!--          <option value="1">Высокий</option>-->
<!--        </select>-->
<!--      </div>-->
<!--    </div>-->
    <div class="fields-row select">
      <div class="label">
        <label>Способ оплаты</label>
      </div>
      <div class="input">
        <select name="pay_method" [disabled]="!editable" [(ngModel)]="payMethod" (ngModelChange)="onChange()">
          <option value="">Оплачено</option>
          <option value="cash">Наличные</option>
          <option value="card">Банковская карта</option>
          <option value="bank">Банковский перевод</option>
        </select>
      </div>
    </div>
    <div class="fields-row" *ngIf="payMethod == 'card'">
      <label><input type="checkbox" name="external_pay_link" [disabled]="!editable" [(ngModel)]="destination.external_pay_link" (ngModelChange)="onChange()"> Внешняя платёжная ссылка</label>
    </div>
    <div class="fields-row controls">
      <button *ngIf="!executed" class="remove-btn" type="button" title="Удалить" (click)="onRemove()"><span class="glyphicon glyphicon-trash"></span></button>
    </div>
  </div>
</div>

<div class="form-row cargo-editor" [class.swing-in-top-fwd]="!isCargoClosing" [class.swing-out-top-bck]="isCargoClosing" *ngIf="isCargoOpened">
  <delivery-product-editor
          [deliveryProduct]="deliveryProduct"
          [totalEnabled]="false"
          (linesChanged)="onCargoChanged($event)"
          [readOnly]="!editable"
  ></delivery-product-editor>
</div>