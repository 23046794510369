import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "humanNumber"})
export class HumanNumberPipe implements PipeTransform {
  transform(value: number, defaultValue: any = 0, plus = false): any {
    if(value == null)
      return defaultValue;

    let sign = value < 0 ? '-' : (plus ? '+' : '');
    let roundedValue = (Math.round(Math.abs(value) * 100) / 100);
    let digits = Math.floor(roundedValue).toString().split('');
    let result: String[] = [];
    for(let i = 0, n = digits.length; i < n; i ++) {
      let digit = digits.pop();
      if(i != 0 && i % 3 == 0)
        result.unshift(' ');

      result.unshift(digit.toString());
    }

    let fraction = Math.round((roundedValue - Math.floor(roundedValue)) * 100);
    let fractionStr = fraction > 0 ? fraction.toString() : '';
    if(fractionStr.length == 1)
      fractionStr = '0' + fractionStr;

    return sign + result.join('') + (fractionStr.length != 0 ? ',' + fractionStr : '');
  }
}
