import {UsersFilter} from "./users-filter";
import {UserStat} from "../user-stat";

export class UsersList {
  constructor(public page: number, public pageSize: number, public totalCount: number, public filter: UsersFilter, public users: UserStat[]) {
  }

  static empty(): UsersList {
    return new UsersList(0, 0, 0, new UsersFilter(), []);
  }

  concat(array) {
    this.users = array.concat(this.users);
    return this;
  }

  hasMore(): boolean {
    if(this.users.length < this.pageSize)
      return false;

    let lastUserNumOnPage = this.page * this.pageSize + this.pageSize - 1;
    return lastUserNumOnPage < this.totalCount - 1;
  }
}
