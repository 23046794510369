export class Offset {
  constructor(private _left: number = 0, private _top: number = 0) {}

  get left(): number {
    return this._left;
  }

  get top(): number {
    return this._top;
  }
}

export class ElementUtils {
  /**
   * Вычисление смещения элемента относительно родительского элемента
   *
   * @param element
   * @param rootElement
   */
  static getRelativeOffset(element: HTMLElement, rootElement: HTMLElement): Offset {
    let left = 0;
    let top = 0;

    do {
      left += element.offsetLeft;
      top += element.offsetTop;

      element = element.offsetParent as HTMLElement;
    } while(element && element !== rootElement);

    // console.log(element);
    // console.log(`${left},${top}`);

    return new Offset(left, top);
  }
}
