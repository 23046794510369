import {DateTime} from "date-time-js";

export class DateTimeslot {
  date: Date|null = null;
  timeBegin: Date|null = null;
  timeEnd: Date|null = null;

  isValid(): boolean {
    return this.date !== null && this.timeBegin !== null && this.timeEnd !== null;
  }

  getFormattedDate(): string|null {
    return this.date ? new DateTime(this.date).format('yyyy-MM-dd') : null;
  }

  getFormattedTimeBegin(): string|null {
    return this.timeBegin ? new DateTime(this.timeBegin).format('HH:mm') : null;
  }

  getFormattedTimeEnd(): string|null {
    return this.timeEnd ? new DateTime(this.timeEnd).format('HH:mm') : null;
  }

  getCombinedTimeBegin(): Date|null {
    if(!this.date || !this.timeBegin)
      return null;

    let date = new Date(this.date);
    date.setHours(this.timeBegin.getHours(), this.timeBegin.getMinutes(), this.timeBegin.getSeconds());
    return date;
  }

  getCombinedTimeEnd(): Date|null {
    if(!this.date || !this.timeEnd)
      return null;

    let date = new Date(this.date);
    date.setHours(this.timeEnd.getHours(), this.timeEnd.getMinutes(), this.timeEnd.getSeconds());
    return date;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  static deserialize(serialized: string): DateTimeslot {
    let deserialized = JSON.parse(serialized);
    let result = new DateTimeslot();
    result.date = deserialized.date ? new Date(deserialized.date) : null;
    result.timeBegin = deserialized.timeBegin ? new Date(deserialized.timeBegin) : null;
    result.timeEnd = deserialized.timeEnd ? new Date(deserialized.timeEnd) : null;
    return result;
  }
}