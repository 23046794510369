
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {EmployeeService} from "../_services/employer.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../_services/loader.service";
import {Freighter} from "../_models/freighter";
import {UserInfoService} from "../_services/user-info.service";
import {Editable} from "../_models/editable";
import {Employee} from "../_models/employees/employee";
import {FormErrors} from "../_models/form-errors";
import {UserEmployeeConverter} from "../_utils/employees/user-employee-converter";
import {TransportUtils} from "../_utils/transport-utils";
import {TitleService} from "../_services/title.service";
import {UserNameUtils} from "../_utils/user-name-utils";

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css']
})
export class EditEmployeeComponent implements OnInit {
  employee = new Editable(new Employee(), new FormErrors());
  freighter: Freighter;

  private employeeConverter = new UserEmployeeConverter();

  constructor(
    private employeeService: EmployeeService,
    private userInfoService: UserInfoService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        params => this.loadEmployee(+params['id']),
        e => console.error(e)
      );
  }


  private loadEmployee(id: number): void {
    this.loaderService.show();

    this.employeeService
      .getEmployee(this.userInfoService.getFreighter(), id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        e => {
          let employee = this.employeeConverter.convertUserToEmployee(e);
          this.employee = new Editable(employee, new FormErrors(), false, true);
          this.freighter = this.userInfoService.getFreighter();
          this.titleService.changeTitle(UserNameUtils.getAccountFullName(employee.account));
        },
        () => {}
      )
    ;
  }

  private reloadEmployee(): void {
    this.loadEmployee(this.employee.record.account.employer.id);
  }

  onBackToList(): void {
    this.router.navigate([`/employees`]);
  }

  onSaved(): void {
    this.reloadEmployee();
  }
}
