import {Subscription} from "rxjs";
import {webSocket, WebSocketSubject} from "rxjs/webSocket";

const PING_PERIOD = 5000;

export class Connection {
    private ws: WebSocketSubject<any>;
    private socket: Subscription;
    private connected = false;
    private pingTimer: any = null;

    constructor(private url: string) {
    }

    public close(): void {
        if (!this.connected)
            return;

        this.connected = false;
        this.socket.unsubscribe();
        this.ws.complete();

        console.log('Web socket connection closed.');
    }

    public sendMessage(message: string): void {
        this.ws.next(message);
    }

    public start(): void {
        console.log(`Connecting to ${this.url}...`);

        this.connected = true;

        this.ws = webSocket({
          url: this.url,
          serializer: data => data
        });

        this.socket = this.ws.subscribe({

            next: data => {
              if (data[0] === 'pong')
                  return;

              this.onMessage(data);
            },
            error: e => {
              this.socket.unsubscribe();

              console.log(e);
              console.log('Web socket connection error.');

              setTimeout(() => {
                  if (this.connected)
                      this.start();
              }, 5000);

            },
            complete: () => {
            }

        });

        this.initPing();
        this.init();
    }

    reconnect() {
        this.close();
        this.start();
    }

    private initPing() {
        if (this.pingTimer)
            clearTimeout(this.pingTimer);

        this.pingTimer = setTimeout(() => {
            if (this.connected) {
                this.sendMessage('ping');
                this.initPing();
            }
        }, PING_PERIOD);
    }

    protected init() {
    }

    protected onMessage(data: any) {

    }

    public isConnected(){
        return this.connected;
    }
}
