import {Call} from "./call";
import {List} from "../pager/list";
import {CallsListFilter} from "./calls-list-filter";

export class CallsList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public filter: CallsListFilter, public calls: Call[]) {
  }

  static empty(): CallsList {
    return new CallsList(0, 0, 0, new CallsListFilter(), [])
  }

  concat(array) {
    this.calls = array.concat(this.calls);
    return this;
  }

  hasMore(): boolean {
    if(this.calls.length < this.pageSize)
      return false;

    let lastUserNumOnPage = this.page * this.pageSize + this.pageSize - 1;
    return lastUserNumOnPage < this.totalCount - 1;
  }
}
