import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class CrossAuthService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  crossLogin(userId: number, token: string): Observable<HttpResponse<any>> {
    return this._requestService.post('/cross_auth/login.json', { userId, token });
  }
}
