
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {CompanyClient} from "../_models/company-client";
import {Observable} from "rxjs";
import {CompanyClientProduct} from "../_models/company-client-product";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";

@Injectable()
export class CompanyClientProductService {
  constructor(private _requestProduct: RequestWithErrorHandlerService) {
  }

  getProducts(company: CompanyClient): Observable<CompanyClientProduct[]> {
    return this._requestProduct
      .get(`/company_clients/${company.id}/products.json`).pipe(
      map(r => r.body as CompanyClientProduct[]))
      ;

  }
}
