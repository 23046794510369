import {AdjustmentCost} from "./adjustment-cost";

export class OrderAdjustmentCostState {
  adjustments: AdjustmentCost[] = [];
  clientSum: number = 0;
  freighterSum: number = 0;
  moverSum: number = 0;
  hasAdjustments = false;

  constructor(public orderId: number) {
  }

  private adjustmentsMap = new Map<number, AdjustmentCost>();

  add(adjustment: AdjustmentCost): void {
    this.adjustmentsMap.set(adjustment.id, adjustment);
  }

  compile(): void {
    this.adjustments = Array.from(this.adjustmentsMap.values());
    this.clientSum = this.adjustments.filter(a => a.type === 'client').reduce((sum, a) => sum + a.value, 0);
    this.freighterSum = this.adjustments.filter(a => a.type === 'freighter').reduce((sum, a) => sum + a.value, 0);
    this.moverSum = this.adjustments.filter(a => a.type === 'mover').reduce((sum, a) => sum + a.value, 0);

    this.hasAdjustments = this.adjustments.length > 0;
  }
}
