import {OptimizationTaskDestination} from "../../_models/optimization-task-destination";
import {OptimizationTaskTransportDestination} from "../../_models/optimization-task-transport-destination";
import {TimeUtils} from "../../_utils/time-utils";
import {DateTime} from "date-time-js";
import {MarkersCollection} from "./markers-collection";
import {plural} from "ru-plurals";

export class Marker {
  private static availableIcons = {
    // '09:00-13:00': '/assets/images/pins/blue{selected}.png',
    '12:00-16:00': '/assets/images/pins/green{selected}.png',
    '16:00-20:00': '/assets/images/pins/orange{selected}.png',
    '18:00-22:00': '/assets/images/pins/purple{selected}.png',
    '20:00-23:59': '/assets/images/pins/violet{selected}.png',

    '09:00-14:00': '/assets/images/pins/blue{selected}.png',
    '14:00-19:00': '/assets/images/pins/orange{selected}.png',
    '19:00-23:59': '/assets/images/pins/violet{selected}.png',

    '09:00-13:00': '/assets/images/pins/blue{selected}.png',
    '13:00-18:00': '/assets/images/pins/orange{selected}.png',
    '18:00-23:00': '/assets/images/pins/violet{selected}.png',

    '17:00-21:50': '/assets/images/pins/violet{selected}.png',
    '17:00-22:00': '/assets/images/pins/violet{selected}.png',
  };

  private static clusterIcon = '/assets/images/pins/cluster.png';

  private static defaultIcon = '/assets/images/pins/green{selected}.png';

  icon: string;
  title: string;
  label: string;
  lat: number;
  lon: number;
  visible = true;
  clusterRadius: number;

  private _isCluster = false;
  private _clusterMarkers: MarkersCollection;

  private _selected = false;
  private destination: OptimizationTaskDestination;

  private pointToCheckBoundsTmp = {lat: 0, lng: 0};
  private pointToCheckBounds: google.maps.LatLng;

  constructor() {
    this.pointToCheckBounds = this.pointToCheckBoundsTmp as any as google.maps.LatLng;
  }

  applyCluster(clusterMarkers: MarkersCollection): void {
    let sumLat = 0, sumLon = 0;
    clusterMarkers.forEach((marker) => {
      sumLat += marker.lat;
      sumLon += marker.lon;
    });

    let center = clusterMarkers.calcCenter();
    this.lat = center.lat;
    this.lon = center.lon;
    this.clusterRadius = center.radius;

    this._isCluster = true;

    this._clusterMarkers = clusterMarkers;

    this.initIcon();
    this.initTitle();
  }

  applyDestination(destination: OptimizationTaskDestination): void {
    this.destination = destination;
    this.lat = destination.destination.lat;
    this.lon = destination.destination.lon;

    this.initIcon();
    this.initTitle();
  }

  applyTransportDestination(destination: OptimizationTaskTransportDestination): void {
    this.applyDestination(destination.destination);

    this.title = '[' + new DateTime(new Date(destination.arrival_time)).format('HH:mm') + '] - ' + this.title;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
    this.initIcon();
  }

  isInBounds(bounds: google.maps.LatLngBounds): boolean {
    this.pointToCheckBoundsTmp.lat = this.lat;
    this.pointToCheckBoundsTmp.lng = this.lon;
    return bounds.contains(this.pointToCheckBounds);
  }

  private initIcon(): void {
    if(this._isCluster) {
      this.icon = Marker.clusterIcon;
    } else {
      let slot = TimeUtils.formatSlot(this.destination.time_slot_begin, this.destination.time_slot_end);
      this.icon = Marker.availableIcons[slot] || Marker.defaultIcon;
    }

    if(!this.icon)
      return;

    let selectSuffix = this._selected ? '-selected' : '';
    this.icon = this.icon.replace('{selected}', selectSuffix);
  }

  private initTitle(): void {
    if(this._isCluster) {
      let pointsPlural = plural('точка', 'точки', 'точек');
      this.title = this._clusterMarkers.markers.size + ' ' + pointsPlural(this._clusterMarkers.markers.size);

      return;
    }

    let title: string[] = [];
    title.push(
      '['
      + new DateTime(new Date(this.destination.time_slot_begin)).format('HH:mm')
      + '-'
      + new DateTime(new Date(this.destination.time_slot_end)).format('HH:mm')
      + ']'
    );

    if(this.destination.order_code)
      title.push(this.destination.order_code);

    title.push(this.destination.destination.addr);

    this.title = title.join(' - ');
  }

  get isCluster(): boolean {
    return this._isCluster;
  }

  get clusterMarkers(): MarkersCollection {
    return this._clusterMarkers;
  }

  copyTo(marker: Marker): void{
    marker.icon = this.icon;
    marker.title = this.title;
    marker.label = this.label;
    marker.lat = this.lat;
    marker.lon = this.lon;
    marker.clusterRadius = this.clusterRadius;
    marker.visible = this.visible;

    marker._isCluster = this._isCluster;
    marker._clusterMarkers = this._clusterMarkers;

    marker._selected = this._selected;
    marker.destination = this.destination;
  }
}
