import {Injectable} from "@angular/core";
import {Track} from "../model/track";
import {Subject, Observable} from "rxjs";
import {PlayData} from "../model/play-data";

@Injectable()
export class AudioPlayerService {
  private playStream = new Subject<PlayData>();

  play(tracks: Track[], trackNum?: number): void {
    this.playStream.next(new PlayData(tracks, trackNum || 0));
  }

  getPlayObservable(): Observable<PlayData> {
    return this.playStream.asObservable();
  }
}
