import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {OptimizationTaskDestination} from "../_models/optimization-task-destination";
import {Selectable} from "../_models/selectable";

@Component({
  selector: 'complex-delivery-destinations-selector',
  templateUrl: './complex-delivery-destinations-selector.component.html',
  styleUrls: ['./complex-delivery-destinations-selector.component.css']
})
export class ComplexDeliveryDestinationsSelectorComponent implements OnInit, OnChanges {
  @Input() destinations: OptimizationTaskDestination[];
  @Input() selectAllByDefault = true;
  @Output() selectedDestinations = new EventEmitter<OptimizationTaskDestination[]>();

  selectableDestinations: Selectable<OptimizationTaskDestination>[] = [];
  allSelected: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allSelected = this.selectAllByDefault;
    this.selectableDestinations = this.destinations.map(d => new Selectable<OptimizationTaskDestination>(d, this.allSelected));
    this.sendSelectedDestinations();
  }

  private updateAllSelected() {
    this.allSelected = this.selectableDestinations.every(s => s.selected)
  }

  private selectAll(): void {
    this.selectableDestinations.forEach(s => s.selected = true);
    this.sendSelectedDestinations();
  }

  private unselectAll(): void {
    this.selectableDestinations.forEach(s => s.selected = false);
    this.sendSelectedDestinations();
  }

  private sendSelectedDestinations(): void {
    let selectedDestinations = this.selectableDestinations.filter(s => s.selected).map(s => s.v);
    this.selectedDestinations.emit(selectedDestinations);
  }

  onClickDestination(destination: Selectable<OptimizationTaskDestination>): void {
    destination.selected = !destination.selected;
    this.updateAllSelected();
    this.sendSelectedDestinations();
  }

  onClickAllSelected(): void {
    if(this.allSelected)
      this.unselectAll();
    else
      this.selectAll();
  }
}
