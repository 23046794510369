import { Component, OnInit } from '@angular/core';
import {Freighter} from "../_models/freighter";
import {Editable} from "../_models/editable";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {FormErrors} from "../_models/form-errors";
import {UserInfoService} from "../_services/user-info.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-payment-distribution-schema',
  templateUrl: './new-payment-distribution-schema.component.html',
  styleUrls: ['./new-payment-distribution-schema.component.css']
})
export class NewPaymentDistributionSchemaComponent implements OnInit {
  freighter: Freighter;

  schema = new Editable(new PaymentDistributionSchema(), new FormErrors(), true);

  constructor(private userInfoService: UserInfoService, private router: Router) { }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();
    this.schema.record.executor_type = 'any';
  }

  onBack(): void {
    this.router.navigate([`/payment-distribution-schemas`]);
  }

  onSaved(): void {
    this.router.navigate([`/payment-distribution-schemas`]);
  }
}
