import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'adjustments-sum',
  templateUrl: './adjustments-sum.component.html',
  styleUrls: ['./adjustments-sum.component.css']
})
export class AdjustmentsSumComponent implements OnInit {
  @Input() sum: number;

  constructor() { }

  ngOnInit() {
  }

}
