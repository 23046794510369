import {Account} from "./account";
import {Transport} from "./transport";
import {TaxiTariff} from "./taxi-tariff";

export class UserStat {
  account: Account;
  orders_count: number;
  driver_orders_count: number;
  last_order_date: string;
  driver_last_order_date: string;
  devices: string[];
  active: boolean;
  role: string;
  online_at: string;
  transport: Transport;
  transports: Transport[];
  taxi_tariffs: TaxiTariff[];
}
