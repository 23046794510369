import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {OrderArea} from "../_models/order-area";

@Component({
  selector: 'order-areas-info',
  templateUrl: './order-areas-info.component.html',
  styleUrls: ['./order-areas-info.component.css']
})
export class OrderAreasInfoComponent implements OnInit, OnChanges {
  @Input() areas: OrderArea[];

  areasForView: OrderArea[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.areasForView = this.areas.filter(o => o.distance > 0);
  }

}
