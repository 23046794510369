
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Freighter} from "../_models/freighter";
import {Observable} from "rxjs";
import {FreighterClientsList} from "../_models/freighter-clients-list";
import {ApiUtilsService} from "./api-utils.service";
import {FreighterClient} from "../_models/freighter-client";
import {LegalEntity} from "../_models/legal-entity";
import {LegalEntityUtils} from "../_utils/legal-entity-utils";
import {HttpResponse} from "@angular/common/http";

const PAGE_SIZE = 20;

@Injectable()
export class FreighterClientService {
  constructor(private _requestService: RequestWithErrorHandlerService, private apiUtilsService: ApiUtilsService) {
  }

  getClients(freighter: Freighter, page?: number, pageSize?: number): Observable<FreighterClientsList> {
    pageSize = pageSize || PAGE_SIZE;

    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients.json`, {
        offset: (page || 0) * pageSize,
        size: pageSize
      }).pipe(
      map(r => new FreighterClientsList(
        page || 0,
        pageSize,
        parseInt(r.headers.get('X-Total-Count')),
        r.body as FreighterClient[]
      )));
  }

  getAllClients(freighter: Freighter): Observable<FreighterClient[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/all.json`).pipe(
      map(r => r.body as FreighterClient[]))
    ;
  }

  getClientsByLegalEntity(freighter: Freighter, legalEntity: LegalEntity): Observable<FreighterClient[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/legal_entites/${legalEntity.id}/clients.json`).pipe(
      map(r => r.body as FreighterClient[]))
      ;
  }

  getSimpleClientsList(freighter: Freighter): Observable<FreighterClient[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/simple.json`).pipe(
      map(r => r.body as FreighterClient[]))
      ;
  }

  getClient(freighter: Freighter, clientId: number): Observable<FreighterClient> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${clientId}.json`).pipe(
      map(r => r.body as FreighterClient))
      ;
  }

  addClient(freighter: Freighter, client: FreighterClient): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients.json`, FreighterClientService.clientToFormData(client))
      ;
  }

  updateClient(freighter: Freighter, client: FreighterClient): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${client.id}.json`, FreighterClientService.clientToFormData(client))
      ;
  }

  removeClient(freighter: Freighter, client: FreighterClient): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${client.id}.json`)
      ;
  }

  addAndLinkLegalEntity(freighter: Freighter, clientId: number, legalEntity: LegalEntity): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${clientId}/legal_entities.json`,
        FreighterClientService.legalEntityToData(legalEntity)
      );
  }

  getLegalEntity(freighter: Freighter, clientId: number, entityId: number): Observable<LegalEntity> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${clientId}/legal_entities/${entityId}.json`).pipe(
      map(r => r.body as LegalEntity))
      ;
  }

  updateLegalEntity(freighter: Freighter, clientId: number, legalEntity: LegalEntity): Observable<HttpResponse<any>> {
    return this._requestService
      .put(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${clientId}/legal_entities/${legalEntity.id}.json`,
        FreighterClientService.legalEntityToData(legalEntity)
      );
  }

  unlinkLegalEntity(freighter: Freighter, clientId: number, entityId: number): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${clientId}/legal_entities/${entityId}.json`
      );
  }

  linkToFreighterClient(freighter: Freighter, clientId: number, entityId: number): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/clients/${clientId}/legal_entities/link.json`, {
        legalEntityId: entityId
      })
      ;
  }

  private static clientToFormData(client: FreighterClient): any {
    let clientData = FreighterClientService.clientToData(client);
    delete clientData['id'];

    return {
      freighter_client: clientData
    }
  }

  static clientToData(client: FreighterClient): any {
    return {
      id: client.id,
      name: client.name,
      contactName: client.contact_name,
      contactPhone: client.contact_phone
    }
  }

  private static legalEntityToData(entity: LegalEntity): any {
    return {
      freighter_legal_entity: LegalEntityUtils.legalEntityData(entity)
    };
  }
}
