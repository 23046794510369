<form (submit)="onSave()">
  <div class="form-group">
    <select class="form-control" name="status" [(ngModel)]="status">
      <option value="checking">заказ проверяется</option>
      <option value="negotiation">заказ согласовывается с клиентом</option>
      <option value="agree">компания готова выполнить заказ</option>
      <option value="accepted" *ngIf="!order.pending && order.status == 'agree'">заказ подтверждён клиентом</option>
      <option value="not_agree">компания не готова выполнить заказ</option>
      <option value="canceled_by_freighter">заказ отменён компанией</option>
      <option value="canceled_by_employer">заказ отменён водителем</option>
      <option value="canceled_by_client">заказ отменён клиентом</option>
    </select>
  </div>
  <button type="submit" class="btn btn-warning" [disabled]="status == order.status">Применить</button>
</form>
