
import {finalize} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {OrderService} from "../_services/order.service";
import {LoaderService} from "../_services/loader.service";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../_services/alert.service";
import {City} from "../_models/city";
import {CityService} from "../_services/city.service";

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css']
})
export class NewOrderComponent implements OnInit {

  startDate: Date = new Date();
  endDate: Date = new Date();
  clientPhone: string = '';
  clientName: string = '';
  error: string;
  datePickerOptions = {};
  timePickerOptions = {};
  cities: City[] = [];
  selectedCity: number;

  constructor(private router: Router,
              private orderService: OrderService,
              private loaderService: LoaderService,
              private cityService: CityService,
              private alertService: AlertService
  ) {
    this.startDate.setSeconds(0);
    this.endDate.setTime(this.startDate.getTime() + 60 * 60 * 2 * 1000);
  }

  ngOnInit() {
    this.initCities();
  }

  private initCities(): void {
    this.cityService.getCities()
      .subscribe(cities => {
        this.cities = cities.filter(city => !city.default);
        if(!this.selectedCity && cities.length > 0)
          this.selectedCity = cities[0].id;
      }, () => {})
    ;
  }

  protected validate(): boolean {
    this.error = "";

    if (this.startDate.getTime() > this.endDate.getTime()) {
      this.alertService.warning('Дата окончания должна быть позже даты начала');
      return false;
    }

    return true;
  }

  onAdd() {
    if (!this.validate())
      return;

    this.loaderService.show();
    this.orderService
      .addOrder(this.selectedCity, this.clientPhone, this.clientName, this.startDate, this.endDate).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        id => {
          this.hideNewOrderModal();
          this.router.navigate([`/orders/0/${id}`]);
        },
        e => {
          if (e instanceof HttpErrorResponse) {
            if (e.status == 400) {
              this.alertService.warning(e.error.message);
            }
          }
        }
      );
  }

  hideNewOrderModal() {
    $('#new-order').modal('hide');
  }
}
