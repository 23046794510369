<div class="form-inline ng2-datetime" [class.vertical-stretch]="verticalStretch" [class.cleanable]="showCleanButton && !readonly" [class.mask-visible]="maskAlwaysVisible">
  <div class="input-group date" *ngIf="datepicker" [class.with-label]="datepicker.label" [class.is-invalid]="!isValid">
    <label *ngIf="datepicker.label">{{ datepicker.label }}:</label>
    <input type="date"
           [readonly]="readonly"
           [required]="required"
           [(ngModel)]="dateModel"
           (ngModelChange)="onChangeInput()"
           [name]="name + '_date'"
           (focus)="onFocus()"
           (blur)="onBlur()"
           [class.active]="isFocused || dateModel"
    />

    <div [hidden]="datepicker.hideIcon || true" class="datepicker-icon" *ngIf="!readonly">
      <i class="material-icons">today</i>
    </div>

    <div class="clean-btn" [class.show-clean]="showCleanButton" [class.hide-clean]="!showCleanButton" (click)="onCleanDateModel()" *ngIf="isCleanButtonActivated && !readonly ">
      <span class="glyphicon glyphicon-remove"></span>
    </div>
  </div>
  <div class="input-group bootstrap-timepicker timepicker" [class.with-label]="timepicker.label" *ngIf="timepicker" [class.is-invalid]="!isValid" [class.mask-visible]="maskAlwaysVisible">
    <label *ngIf="timepicker.label">{{ timepicker.label }}:</label>
    <input type="time" class="input-small"
           [readonly]="readonly"
           [required]="required"
           [(ngModel)]="timeModel"
           (ngModelChange)="onChangeInput()"
           [name]="name + '_time'"
           (focus)="onFocus()"
           (blur)="onBlur()"
           [class.active]="isFocused || dateModel"
    />

    <span [hidden]="timepicker.hideIcon || true" class="datepicker-icon" *ngIf="!readonly">
       <i class="material-icons">access_time</i>
    </span>

    <div class="clean-btn" [class.show-clean]="showCleanButton" [class.hide-clean]="!showCleanButton" (click)="onCleanTimeModel()" *ngIf="isCleanButtonActivated && !readonly">
      <span class="glyphicon glyphicon-remove"></span>
    </div>
  </div>
  <button *ngIf="hasClearButton && !readonly" type="button" (click)="clearModels()" title="Очистить"><span class="glyphicon glyphicon-erase"></span></button>
</div>
