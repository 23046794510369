import {State} from "../_storage/state";

export class TransportsFilter {
  constructor(
    public transportNumber = "",
    public withoutCrew = false,
    public crewDate = new Date(),
    public ofEmployer?: number
  ) {}

  getState(): State {
    return new State(JSON.stringify({
      email: this.transportNumber,
      withoutCrew: this.withoutCrew,
      crewDate: this.crewDate.toString(),
      ofEmployer: this.ofEmployer
    }));
  }

  equals(filter: TransportsFilter): boolean {
    return this.transportNumber === filter.transportNumber
      && this.withoutCrew === filter.withoutCrew
      && this.crewDate.getTime() === filter.crewDate.getTime()
      && this.ofEmployer === filter.ofEmployer
      ;
  }

  clone(): TransportsFilter {
    return new TransportsFilter(
      this.transportNumber,
      this.withoutCrew,
      new Date(this.crewDate.toString()),
      this.ofEmployer
    );
  }

  static fromState(state: State): TransportsFilter {
    let data = JSON.parse(state.state);

    let email = data.email || "";
    let withoutCrew = !!(data.withoutCrew || false);
    let crewDate = data.crewDate && new Date(data.crewDate) || new Date();
    let ofEmployer = data.ofEmployer && parseInt(data.ofEmployer) || null;

    return new TransportsFilter(
      email,
      withoutCrew,
      crewDate,
      ofEmployer
    );
  }

}
