<div class="modal fade" tabindex="-1" role="dialog" #modal>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Новый заказ</h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="methods">
          <div class="manually" (mouseover)="onHoverMethod('manually')" (click)="onSelectMethod('manually')">
            <div class="logo"><img ngSrc="/assets/images/actions/manually.png" width="125" height="55" alt="Создать вручную" title="Создать вручную"></div>
            <div class="subscript">Создать вручную</div>
          </div>
          <div class="file-import" (mouseover)="onHoverMethod('file')" (click)="onSelectMethod('file')">
            <div class="logo"><img ngSrc="/assets/images/actions/import.png" width="125" height="55" alt="импорт из файла" title="импорт из файла"></div>
            <div class="subscript">Импорт из файла</div>
          </div>
          <div class="separator"></div>
          <div class="import" [class.coming-soon]="service.comingSoon" *ngFor="let service of availableServicesWithImport" (mouseover)="onHoverMethod(service.identifier)" (click)="onSelectMethod(service.identifier)">
            <div class="logo" *ngIf="service.logo"><img [src]="service.logo" width="125" [alt]="service.subscript" [title]="service.subscript"/></div>
<!--            <div *ngIf="service.comingSoon" class="watermark">Скоро</div>-->
            <div class="subscript">{{ service.subscript }}</div>
          </div>
        </div>
        <div class="method-description">
          <div *ngIf="methodDescription">
            <h5>{{ methodDescription.title }}</h5>
            <p>{{ methodDescription.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>