<div class="details" *ngIf="legalEntity">
  <table class="table" [class.compact]="compact">
    <tbody>
    <tr *ngIf="connectionType">
      <td>Тип подключения</td>
      <td>{{ connectionType }}</td>
    </tr>
    <tr *ngIf="legalEntity.type">
      <td>Вид юридического лица</td>
      <td>{{ legalEntity.type|legalEntityType }}</td>
    </tr>
<!--    <tr *ngIf="legalEntity.short_name">-->
<!--      <td>Краткое наименование</td>-->
<!--      <td>{{ legalEntity.short_name }}</td>-->
<!--    </tr>-->
    <tr *ngIf="legalEntity.name">
      <td>Наименование</td>
      <td>{{ legalEntity.name }}</td>
    </tr>
    <tr *ngIf="legalEntity.inn">
      <td>ИНН</td>
      <td>{{ legalEntity.inn }}</td>
    </tr>
    <tr *ngIf="legalEntity.kpp">
      <td>КПП</td>
      <td>{{ legalEntity.kpp }}</td>
    </tr>
    <tr *ngIf="legalEntity.ogrn">
      <td>ОГРН</td>
      <td>{{ legalEntity.ogrn }}</td>
    </tr>
    <tr *ngIf="legalEntity.oktmo">
      <td>ОКТМО</td>
      <td>{{ legalEntity.oktmo }}</td>
    </tr>
    <tr *ngIf="legalEntity.okpo">
      <td>ОКПО</td>
      <td>{{ legalEntity.okpo }}</td>
    </tr>
    <tr *ngIf="legalEntity.bik">
      <td>БИК</td>
      <td>{{ legalEntity.bik }}</td>
    </tr>
    <tr *ngIf="legalEntity.bank">
      <td>Наименование банка</td>
      <td>{{ legalEntity.bank }}</td>
    </tr>
    <tr *ngIf="legalEntity.bank_inn">
      <td>ИНН банка</td>
      <td>{{ legalEntity.bank_inn }}</td>
    </tr>
    <tr *ngIf="legalEntity.correspondent_account">
      <td>Корреспондентский счёт</td>
      <td>{{ legalEntity.correspondent_account }}</td>
    </tr>
    <tr *ngIf="legalEntity.checking_account">
      <td>Расчётный счёт</td>
      <td>{{ legalEntity.checking_account }}</td>
    </tr>
    <tr *ngIf="legalEntity.actual_address">
      <td>Фактический адрес</td>
      <td>{{ legalEntity.actual_address }}</td>
    </tr>
    <tr *ngIf="legalEntity.legal_address">
      <td>Юридический адрес</td>
      <td>{{ legalEntity.legal_address }}</td>
    </tr>
    <tr *ngIf="legalEntity.signatory_authority">
      <td>Лицо с правом подписи</td>
      <td>{{ legalEntity.signatory_authority }}</td>
    </tr>
    </tbody>
  </table>
</div>
