import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Uploader} from "../_upload/uploader";
import {UploadError} from "../_upload/upload-error";
import {UploadErrorMessageBuilder} from "../_upload/upload-error-message-builder";

@Component({
  selector: 'photo-field',
  templateUrl: './photo-field.component.html',
  styleUrls: ['./photo-field.component.css']
})
export class PhotoFieldComponent implements OnInit {
  @Input() photo: string;
  @Input() title: string;
  @Input() identifier: string;
  @Input() uploader: Uploader;
  @Input() uploadError: UploadError;

  @Output() selectedFile = new EventEmitter<string>();
  @Output() removeFile = new EventEmitter<string>();

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  getUploadErrorMessage(): string {
    return UploadErrorMessageBuilder.build(this.uploadError);
  }

  reset(): void {
    this.fileInput.nativeElement.value = '';
  }

  onFileSelected(files: File[]): void {
    if(files.length > 0)
      this.selectedFile.emit(this.identifier);
  }

  onDelete(): void {
    if(confirm(`Подтверждаете удаление фото "${this.title}?"`))
      this.removeFile.emit(this.identifier);
  }
}
