import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {RelativeTimeFilter} from "../_models/users/relative-time-filter";
import {TIME_UNIT_TRANSLATES} from "../_maps/time-units";
import {isNumeric} from "../_utils/number-utils";

@Component({
  selector: 'relative-time-filter',
  templateUrl: './relative-time-filter.component.html',
  styleUrls: ['./relative-time-filter.component.css']
})
export class RelativeTimeFilterComponent implements OnInit, OnChanges {
  @Input() filter: RelativeTimeFilter;
  @Output() filterChange = new EventEmitter<RelativeTimeFilter>();

  value: string;
  unit: string;

  units = TIME_UNIT_TRANSLATES;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.value = this.filter.value != undefined && this.filter.value.toString() || "";
    this.unit = this.filter.unit || "";
  }

  onChangeFilter() {
    let value = this.value.trim();
    let numericValue: number = (value === "" || !isNumeric(value)) ? undefined : parseInt(value);

    this.filterChange.emit(new RelativeTimeFilter(numericValue, this.unit));
  }
}
