import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Insurance} from "../_models/insurance";
import {INSURANCE_SECTIONS} from "../_maps/insurance-section";
import {InsuranceLimitsService} from "../_services/insurance-limits.service";

@Component({
  selector: 'insurance-editor',
  templateUrl: './insurance-editor.component.html',
  styleUrls: ['./insurance-editor.component.css']
})
export class InsuranceEditorComponent implements OnInit, OnChanges {
  @Input() insurances: Insurance[] = [];
  @Input() forceInsure = false;

  insure = false;
  insurance = new Insurance();
  insuranceSections = INSURANCE_SECTIONS;
  cargoCostError: string;

  private limits: any = {};

  constructor(private insuranceLimitsService: InsuranceLimitsService) {
    this.insurance.section = 'undefined';
  }

  ngOnInit() {
    this.initLimits();
  }

  private initLimits(): void {
    this.insuranceLimitsService
      .getLimits()
      .subscribe(
        limits => this.limits = limits,
        () => {}
      )
    ;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.insurances.length > 0) {
      this.insure = true;
      this.insurance = this.insurances[0];
    } else if(this.forceInsure) {
      this.insure = true;
      this.insurance = new Insurance();
      this.insurance.section = 'undefined';
      this.insurances = [ this.insurance ];
    }
  }

  validate(): boolean {
    this.cargoCostError = undefined;

    if(!this.insure || this.insurance.section === 'undefined')
      return true;

    let cargoCost = this.insurance.cargo_cost || 0;
    if(cargoCost <= 0) {
      this.cargoCostError = 'укажите стоимость груза';
    }

    let cargoCostLimit = this.limits[this.insurance.section] || 0;
    if(cargoCost > cargoCostLimit) {
      this.cargoCostError = `стоимость груза превышает лимит ${cargoCostLimit} Р`;
    }

    return !this.cargoCostError;
  }

  onToggleInsure(): void {
    this.insurances = this.insure ? [ this.insurance ] : [];
  }
}
