import {TaxiMessage} from "./taxi-message";

export class SearchStopped implements TaxiMessage {
  constructor(public draftId: number) {}

  getDraftId(): number {
    return this.draftId;
  }

}
