
import {finalize} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {LegalEntity} from "../_models/legal-entity";
import {Freighter} from "../_models/freighter";
import {UserInfoService} from "../_services/user-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LegalEntityService} from "../_services/legal-entity.service";
import {LoaderService} from "../_services/loader.service";
import {InnDialogComponent} from "../inn-dialog/inn-dialog.component";
import {FreighterClientService} from "../_services/freighter-client.service";
import {FreighterClient} from "../_models/freighter-client";
import {TitleService} from "../_services/title.service";
import {AlertService} from "../_services/alert.service";

@Component({
  selector: 'app-freighter-client-legal-entities',
  templateUrl: './freighter-client-legal-entities.component.html',
  styleUrls: ['./freighter-client-legal-entities.component.css']
})
export class FreighterClientLegalEntitiesComponent implements OnInit {
  @ViewChild('innDialog', { static: true }) innDialog: InnDialogComponent;

  client: FreighterClient;
  entities: LegalEntity[] = [];
  clientId: number;

  private freighter: Freighter;

  constructor(
    private freighterClientService: FreighterClientService,
    private legalEntityService: LegalEntityService,
    private route: ActivatedRoute,
    private router: Router,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private titleService: TitleService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();

    this.route.params
      .subscribe(
        params => {
          this.clientId = +params['id'];
          this.loadFreighterClient();
          this.loadEntities();
        },
        e => console.error(e)
      );
  }

  private showInnDialogToAdd(): void {
    this.innDialog.showDialog();
  }


  private addByInn(inn: string): void {
    this.router.navigate([`/freighter-clients`, this.clientId, 'legal-entities', 'new', inn]);
  }

  private loadFreighterClient(): void {
    this.loaderService.show();

    this.freighterClientService
      .getClient(this.freighter, this.clientId).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        client => {
          this.client = client;
          this.titleService.changeTitle(`Юр. лица - ${client.name} - Клиенты`);
        },
        () => {}
      )
  }

  private loadEntities(): void {
    this.loaderService.show();

    this.legalEntityService
      .getForFreighterClient(this.freighter, this.clientId).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        entities => this.entities = entities,
        () => {}
      );
  }

  private unlinkEntity(entity: LegalEntity): void {
    let entityName = entity.short_name || entity.name;

    this.loaderService.show();

    this.freighterClientService
      .unlinkLegalEntity(this.freighter, this.clientId, entity.id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success(`Юр. лицо "${entityName}" отвязано от клиента`);
          this.loadEntities();
        },
        () => {}
      )
  }

  onAdd(): void {
    this.showInnDialogToAdd();
  }

  onEnteredInn(inn: string): void {
    this.addByInn(inn);
  }

  onUnlink(entity: LegalEntity): void {
    let name = entity.short_name || entity.name;

    if(confirm(`Отвязать юр. лицо "${name}" от клиента?`))
      this.unlinkEntity(entity);
  }
}
