import {Component, Input} from "@angular/core";

@Component({
  selector: '[by-distance-calculation]',
  templateUrl: './by-distance.calculation.component.html',
  styleUrls: ['./calculation.component.css']
})
export class ByDistanceCalculationComponent {
  @Input() calculation: any;
}
