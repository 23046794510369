import {State} from "../../_storage/state";

export class EmployeesFilter {
  constructor(
    public email = "",
    public phone = "",
    public surname = "",
    public withoutCrew = false,
    public crewDate = new Date()
  ) {}

  getState(): State {
    return new State(JSON.stringify({
      email: this.email,
      phone: this.phone,
      surname: this.surname,
      withoutCrew: this.withoutCrew,
      crewDate: this.crewDate.toString()
    }));
  }

  equals(filter: EmployeesFilter): boolean {
    return this.email === filter.email
      && this.phone === filter.phone
      && this.surname === filter.surname
      && this.withoutCrew === filter.withoutCrew
      && this.crewDate.getTime() === filter.crewDate.getTime()
      ;
  }

  clone(): EmployeesFilter {
    return new EmployeesFilter(
      this.email,
      this.phone,
      this.surname,
      this.withoutCrew,
      new Date(this.crewDate.toString())
    );
  }

  static fromState(state: State): EmployeesFilter {
    let data = JSON.parse(state.state);

    let email = data.email || "";
    let phone = data.phone || "";
    let surname = data.surname || "";
    let withoutCrew = !!(data.withoutCrew || false);
    let crewDate = data.crewDate && new Date(data.crewDate) || new Date();

    return new EmployeesFilter(
      email,
      phone,
      surname,
      withoutCrew,
      crewDate
    );
  }
}
