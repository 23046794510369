import {Injectable} from "@angular/core";
import {Subject, Observable} from "rxjs";

@Injectable()
export class LoaderService {
  private subject: Subject<any> = new Subject<any>();
  private loaderCount = 0;

  show() {
    if(this.loaderCount === 0)
      this.subject.next(true);

    this.loaderCount++;
  }

  showText(text: string) {
    this.subject.next(text);
  }

  hide() {
    if(this.loaderCount > 0)
      this.loaderCount--;

    if(this.loaderCount === 0)
      this.subject.next(false);
  }

  getStateListener(): Observable<boolean> {
    return this.subject.asObservable();
  }
}
