
import {finalize, switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FreighterClientService} from "../_services/freighter-client.service";
import {LegalEntityService} from "../_services/legal-entity.service";
import {LoaderService} from "../_services/loader.service";
import {FreighterClient} from "../_models/freighter-client";
import {UserInfoService} from "../_services/user-info.service";
import {LegalEntity} from "../_models/legal-entity";
import {Observable, Subject} from "rxjs";

@Component({
  selector: 'legal-entities-selector',
  templateUrl: './legal-entity-selector.component.html',
  styleUrls: ['./legal-entity-selector.component.css']
})
export class LegalEntitySelectorComponent implements OnInit {
  @Output() selected = new EventEmitter<LegalEntity>();

  @ViewChild('searchField', { static: true }) searchFieldEl: ElementRef;

  freighterClients: FreighterClient[] = [];
  clientLegalEntities: LegalEntity[] = [];
  foundLegalEntities: LegalEntity[] = [];

  isFreighter = false;
  selectedClientId: string = '0';
  selectedClientLegalEntityId: string = '0';
  search: string;

  private wasInit = false;
  private searchStream = new Subject<string>();

  constructor(
    private freighterClientService: FreighterClientService,
    private legalEntityService: LegalEntityService,
    private loaderService: LoaderService,
    private userInfoService: UserInfoService
  ) {
    this.isFreighter = userInfoService.isFreighter();
  }

  ngOnInit() {
    this.initSearchStream();
  }

  init(): void {
    this.focusOnSearchField();

    if(this.wasInit)
      return;

    if(this.isFreighter)
      this.loadFreighterClients();

    this.wasInit = true;
  }

  private focusOnSearchField(): void {
    setTimeout(() => {
      this.searchFieldEl.nativeElement.focus();
    }, 1000);
  }

  private initSearchStream(): void {
    this.searchStream = new Subject<string>();
    this.searchStream.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchString => {
        this.loaderService.show();
        this.foundLegalEntities = [];

        let result: Observable<LegalEntity[]>;
        if(this.userInfoService.isPrivilegedUser())
          result = this.legalEntityService.findLegalEntitiesForSupport(searchString);
        else if(this.userInfoService.isFreighter())
          result = this.legalEntityService.findLegalEntitiesForFreighter(this.userInfoService.getFreighter(), searchString);
        else
          result = this.legalEntityService.findLegalEntitiesForCustomer(searchString);

        return result.pipe(finalize(() => this.loaderService.hide()))
        ;
      }),)
      .subscribe(
        entities => this.foundLegalEntities = entities,
        () => this.initSearchStream()
      );
  }

  private loadFreighterClients(): void {
    this.loaderService.show();

    this.freighterClientService
      .getSimpleClientsList(this.userInfoService.getFreighter()).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        clients => this.freighterClients = clients,
        () => {}
      );
  }

  private loadClientLegalEntities(): void {
    this.selectedClientLegalEntityId = '0';
    this.clientLegalEntities = [];
    this.cleanSearch();

    if(this.selectedClientId == '0')
      return;

    this.loaderService.show();

    this.legalEntityService
      .getForFreighterClient(this.userInfoService.getFreighter(), parseInt(this.selectedClientId)).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        entities => this.clientLegalEntities = entities,
        () => {}
      );
  }

  private searchLegalEntities(): void {
    this.searchStream.next(this.search);
  }

  private cleanSearch(): void {
    this.search = '';
    this.searchLegalEntities();
  }

  onChangeClient(): void {
    this.loadClientLegalEntities();
  }

  onChangeLegalEntity(): void {
    if(this.selectedClientLegalEntityId == '0') {
      this.cleanSearch();
      return;
    }

    let selectedClientLegalEntityId = parseInt(this.selectedClientLegalEntityId);
    let legalEntity = this.clientLegalEntities.find(e => e.id == selectedClientLegalEntityId);
    this.search = legalEntity && legalEntity.inn || '';
    this.searchLegalEntities();
  }

  onChangeSearchString(): void {
    this.searchLegalEntities();
  }

  onSelectLegalEntity(entity: LegalEntity): void {
    this.selected.emit(entity);
  }
}
