
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {AvailablePayMethod} from "../_models/available-pay-method";

@Injectable()
export class PayMethodsService {
  constructor(private _requestService: RequestWithErrorHandlerService) {
  }

  getMyAvailablePayMethods(): Observable<AvailablePayMethod[]> {
    return this._requestService
      .get(`/customer/me/pay_methods.json`).pipe(
      map(res => res.body as AvailablePayMethod[]))
      ;
  }

  getAvailablePayMethodsForUser(userId: number): Observable<AvailablePayMethod[]> {
    return this._requestService
      .get(`/customer/${userId}/pay_methods.json`).pipe(
      map(res => res.body as AvailablePayMethod[]))
      ;
  }

  getMyAvailablePayMethodsForDeliverySchema(deliverySchemaId: number): Observable<AvailablePayMethod[]> {
    return this._requestService
      .get(`/customer/me/pay_methods/delivery_schemas/${deliverySchemaId}.json`).pipe(
      map(res => res.body as AvailablePayMethod[]))
      ;
  }

  getAvailablePayMethodsForUserAndDeliverySchema(userId: number, deliverySchemaId: number): Observable<AvailablePayMethod[]> {
    return this._requestService
      .get(`/customer/${userId}/pay_methods/delivery_schemas/${deliverySchemaId}.json`).pipe(
      map(res => res.body as AvailablePayMethod[]))
      ;
  }
}
