import {debounceTime, distinctUntilChanged, finalize, switchMap} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompanyDetails} from "../_models/company-details";
import {Subject} from "rxjs";
import {CompanyDetailsService} from "../_services/company-details.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'company-search-field',
  templateUrl: './company-search-field.component.html',
  styleUrls: ['./company-search-field.component.scss']
})
export class CompanySearchFieldComponent implements OnInit {
  @Input() inputClass = 'form-control';
  @Input() placeholder = 'Поиск организации';
  @Output() onSelect = new EventEmitter<CompanyDetails>();
  @Output() onAutocompleteActive = new EventEmitter<boolean>();

  selected: CompanyDetails;
  companyDetails: CompanyDetails[] = [];
  searchCompanyValue: CompanyDetails | string = '';
  isSearching = false;

  private companyDetailsStream = new Subject<string>();
  private deactivateAutocompleteTimer: any;

  constructor(private companyDetailsService: CompanyDetailsService) { }

  ngOnInit() {
    this.initCompanyDetailsStream();
  }

  private initCompanyDetailsStream(): void {
    this.companyDetailsStream = new Subject();
    this.companyDetailsStream
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(search => {
          this.isSearching = true;
          return this.companyDetailsService
            .getCompanyDetails(search)
            .pipe(finalize(() => this.isSearching = false))
            ;
        })
      )
      .subscribe({
        next: details => this.companyDetails = details,
        error: e => {
          if(e instanceof HttpErrorResponse && e.status == 404) {
            this.companyDetails = [];
          } else {
            console.error(e);
          }
          this.initCompanyDetailsStream();
        }
      })
    ;
  }

  customFilter(items: CompanyDetails[], query: string): CompanyDetails[] {
    return items;
  }

  onSelectCompanyDetails(details: CompanyDetails): void {
    this.onSelect.emit(details);
  }

  onChangeCompanySearch(value: CompanyDetails | string): void {
    if(typeof value != 'string')
      return;

    if(value == '') {
      this.searchCompanyValue = new CompanyDetails();
      this.companyDetails = [];
    } else {
      this.companyDetailsStream.next(value);
    }
  }

  onOpenAutocomplete(): void {
    console.log('autocomplete opened');
    this.onAutocompleteActive.next(true);
  }

  onCloseAutocomplete(): void {
    console.log('autocomplete closed');

    // здесь происходит странное, т.к. компонент для автокомплита не всегда вызывает событие открытия панели
    this.onAutocompleteActive.emit(true);

    if(this.deactivateAutocompleteTimer)
      clearTimeout(this.deactivateAutocompleteTimer);

    this.deactivateAutocompleteTimer = setTimeout(() => {
      console.log('autocomplete deactivated');
      this.onAutocompleteActive.emit(false);
      this.deactivateAutocompleteTimer = null;
    }, 1000);
  }
}
