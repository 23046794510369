
import {finalize} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {LegalEntityService} from "../_services/legal-entity.service";
import {LoaderService} from "../_services/loader.service";
import {CompanyClientLegalEntity} from "../_models/company-client-legal-entity";
import {Router} from "@angular/router";
import {Editable} from "../_models/editable";
import {AlertService} from "../_services/alert.service";
import {HttpErrorResponse} from "@angular/common/http";
import {FormErrors} from "../_models/form-errors";
import {UserInfoService} from "../_services/user-info.service";
import {CompanyDetails} from "../_models/company-details";
import {BankDetails} from "../_models/bank-detail";

@Component({
  selector: 'legal-entity-form',
  templateUrl: './legal-entity-form.component.html',
  styleUrls: ['./legal-entity-form.component.css']
})
export class LegalEntityFormComponent implements OnInit {
  @Input() legalEntity: Editable<CompanyClientLegalEntity>;

  readOnly: boolean;
  isAutocompleteActive = false;
  isRegistrationMode = false;

  constructor(
    private legalEntityService: LegalEntityService,
    private router: Router,
    private loaderService: LoaderService,
    private userInfoService: UserInfoService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.readOnly = !this.userInfoService.isPrivilegedUser() && !this.userInfoService.isCompanyClientManager() && !this.legalEntity.isNew;
    this.isRegistrationMode = !this.userInfoService.userInfo.account.company_client;
  }

  private update(): void {
    this.loaderService.show();

    this.legalEntityService
      .updateCompanyLegalEntity(this.legalEntity.record).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success('Юридическое лицо обновлено', true);
          this.goToList();
        },
        r => {
          if(r instanceof HttpErrorResponse) {
            if(r.status === 400) {
              this.legalEntity.errors = r.error as FormErrors;
              this.alertService.error('Не все данные заполнены корректно');
            } else if(r.status === 409) {
              this.alertService.error('Указанный ИНН уже используется');
            }
          }
        }
      );
  }

  private add(): void {
    this.loaderService.show();

    this.legalEntityService
      .addCompanyLegalEntity(this.userInfoService.userInfo.account.company_client.id, this.legalEntity.record).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success('Юридическое лицо добавлено', true);
          this.goToList();
        },
        r => {
          if(r instanceof HttpErrorResponse) {
            if(r.status === 400) {
              this.legalEntity.errors = r.error as FormErrors;
              this.alertService.error('Не все данные заполнены корректно');
            } else if(r.status === 409) {
              this.alertService.error('Указанный ИНН уже используется');
            }
          }
        }
      );
  }

  private registerCompany(): void {
    this.loaderService.show();
    this.legalEntityService
      .registerCompany(this.legalEntity.record.legal_entity)
      .pipe(
        finalize(() => this.loaderService.hide())
      )
      .subscribe({
        next: () => {
          location.href = '/user-profile';
        },
        error: r => {
          if(r instanceof HttpErrorResponse) {
            if(r.status === 400) {
              this.legalEntity.errors = r.error as FormErrors;
              this.alertService.error('Не все данные заполнены корректно');
            } else if(r.status === 409) {
              this.alertService.error('Такая компания уже существует. Попросите менеджера компании, чтобы он добавил вас список пользователей.');
            } else if(r.status === 405) {
              this.alertService.error('На этого пользователя уже зарегистрирована компания. Авторизуйтесь в панели управления, чтобы получить доступ к этой компании.');
            }
          }
        }
      })
    ;
  }

  private applyCompanyDetails(company: CompanyDetails): void {
    let le = this.legalEntity.record.legal_entity;

    le.name = company.name;
    le.short_name = company.short_name;
    le.type = company.type;
    le.inn = company.inn;
    le.kpp = company.kpp;
    le.ogrn = company.ogrn;
    le.bik = company.bik;
    le.okpo = company.okpo;
    le.bank = company.bank;
    le.bank_inn = company.bank_inn;
    le.correspondent_account = company.correspondent_account;
    le.checking_account = company.checking_account;
    le.legal_address = company.legal_address;
    le.director = company.director;
    le.oktmo = company.oktmo;
  }

  private applyBankDetails(bank: BankDetails): void {
    let le = this.legalEntity.record.legal_entity;

    le.bank = bank.name;
    le.bik = bank.bic;
    le.bank_inn = bank.inn;
    le.correspondent_account = bank.correspondent_account;
  }

  private goToList(): void {
    this.router.navigate([`/user-profile`]);
  }

  onSave(): void {
    if(this.readOnly)
      return;

    if(this.legalEntity.record.id)
      this.update();
    else if(this.isRegistrationMode)
      this.registerCompany();
    else
      this.add();
  }

  onCancel(): boolean {
    this.goToList();
    return false;
  }

  onApplySelectedCompany(company: CompanyDetails): void {
    this.applyCompanyDetails(company);
  }

  onApplySelectedBank(bank: BankDetails): void {
    this.applyBankDetails(bank);
  }

  onAutocompleteActive(isActive: boolean): void {
    this.isAutocompleteActive = isActive;
  }
}
