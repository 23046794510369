import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {List} from "./list";
import {Page} from "./page";

@Component({
  selector: 'pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit, OnChanges {
  @Input() list: List;
  @Input() verticalStretch = false;
  @Input() hideIfNoPages: boolean = false;
  @Output() onPage = new EventEmitter<Page>();
  hasPrev: boolean = false;
  hasNext: boolean = false;
  isFirstPage: boolean = false;
  isLastPage: boolean = false;
  lastPage: number;
  totalPages: number;
  rangeStart: number;
  rangeEnd: number;
  hasPages: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasNext = this.hasPrev = false;
    this.hasPages = this.list.totalCount > this.list.pageSize;

    this.hasPrev = this.list.page > 0;
    this.hasNext = (this.list.page + 1) * this.list.pageSize < this.list.totalCount;
    this.lastPage = Math.ceil(this.list.totalCount / this.list.pageSize) - 1;
    this.rangeStart = this.list.page * this.list.pageSize + 1;
    this.rangeEnd = Math.min(this.rangeStart + this.list.pageSize - 1, this.list.totalCount);

    this.isFirstPage = this.list.page == 0;
    this.isLastPage = this.list.page == this.lastPage;
  }

  onClickPage(num: number) {
    if(num >= 0 && num <= this.lastPage)
      this.onPage.emit(new Page(num));

    return false;
  }
}
