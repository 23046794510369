import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";

@Component({
  selector: 'draft-search-params-editor',
  templateUrl: './draft-search-params-editor.component.html',
  styleUrls: ['./draft-search-params-editor.component.css']
})
export class DraftSearchParamsEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Input() enabledExternalSearch = true;
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  autoAcceptTaxi: boolean;
  autoExternalSearch: boolean;

  constructor() { }

  ngOnInit() {
    this.autoAcceptTaxi = this.draft.auto_accept_taxi;
    this.autoExternalSearch = this.draft.auto_external_search;
  }

  onSave() {
    this.draft.auto_accept_taxi = this.autoAcceptTaxi;
    this.draft.auto_external_search = this.autoExternalSearch;

    this.onUpdated.emit(this.draft);
  }
}
