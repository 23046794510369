
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {UserInfoService} from "../_services/user-info.service";
import {NotificationService} from "../_services/notification.service";

@Injectable()
export class AuthGuard  {

  constructor(private router: Router, private userInfoService: UserInfoService, private _notificationService: NotificationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userInfoService
      .isPresentLazy().pipe(
      map(present => {
        if(!present) {
          console.log('User info was not present.');
          // not logged in so redirect to login page with the return url
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        } else if(state.url == '/orders' && this.userInfoService.isDeliveryManager()) {
          this.router.navigate(['/complex-deliveries'])
        }
        return present;
      }));
  }

}
