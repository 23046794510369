<div class="fields-row select">
  <div class="label">
    <label>
      <span>{{ label }}</span>
      <tooltip *ngIf="info" class="info" [title]="info"><span class="glyphicon glyphicon-info-sign"></span></tooltip>
    </label>
  </div>
  <div class="input date">
    <order-panel-datepicker
            [(value)]="dateTimeslot.date"
            [datepicker]="desiredArrivalDatePickerOptions"
            [timepicker]="false"
            [maskAlwaysVisible]="true"
            [readonly]="isDateDisabled"
            (valueChange)="onChangeTimeSlot()"
            name="desiredArrivalDate"
    ></order-panel-datepicker>
  </div>
  <div class="input time" *ngIf="!fixedTimeSlots">
    <order-panel-datepicker
            [(value)]="dateTimeslot.timeBegin"
            [datepicker]="false"
            [timepicker]="desiredArrivalTimeBeginPickerOptions"
            [maskAlwaysVisible]="true"
            (valueChange)="onChangeTimeSlot()"
            name="desiredArrivalTimeBegin"
    ></order-panel-datepicker>
  </div>
  <div class="input time" *ngIf="!fixedTimeSlots">
    <order-panel-datepicker
            [(value)]="dateTimeslot.timeEnd"
            [datepicker]="false"
            [timepicker]="desiredArrivalTimeEndPickerOptions"
            [maskAlwaysVisible]="true"
            (valueChange)="onChangeTimeSlot()"
            name="desiredArrivalTimeEnd"
    ></order-panel-datepicker>
  </div>
  <div class="input time" *ngIf="fixedTimeSlots">
    <select name="slotNumber" [(ngModel)]="slotNumber" (ngModelChange)="onSelectTimeSlot()">
      <option *ngFor="let time of fixedTimeSlots; let i = index" [value]="i">{{ time.start + ' - ' + time.end }}</option>
    </select>
  </div>
</div>
