
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {Storehouse} from "../_models/storehouse";
import {City} from "../_models/city";
import {Order} from "../_models/order";

@Injectable()
export class StorehouseService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getAvailableStorehouses(city?: City): Observable<Storehouse[]> {
    let params = {};
    if(city)
      params['city'] = city.id;

    return this._requestService
      .get('/customer/me/storehouses.json', params).pipe(
      map(r => r.body as Storehouse[]))
    ;
  }

  getAvailableStorehousesByOrder(order: Order): Observable<Storehouse[]> {
    return this._requestService
      .get(`/support/me/orders/${order.id}/storehouses/available.json`).pipe(
      map(r => r.body as Storehouse[]))
      ;
  }

  getLastByOptimizationTasks(): Observable<Storehouse|null> {
    return this._requestService
      .get('/customer/me/storehouses/last/by/optimization_tasks.json').pipe(
      map(r => r.body.storehouse as Storehouse|null))
    ;
  }

  createStorehouse(storehouse: Storehouse): Observable<Storehouse> {
    return this._requestService
      .post('/customer/me/storehouses.json', {
        storehouse: StorehouseService.storehouseToData(storehouse)
      }).pipe(
        map(r => r.body.storehouse as Storehouse))
        ;
  }

  private static storehouseToData(storehouse: Storehouse): any {
    return {
      address: storehouse.address,
      lat: storehouse.lat,
      lon: storehouse.lon,
      openAfter: storehouse.open_after + ':00',
      openBefore: storehouse.open_before + ':00',
      loadingUnloadingDuration: storehouse.loading_unloading_duration,
    };
  }
}
