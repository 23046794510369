import {Component, Input, OnInit} from '@angular/core';
import {Transport} from "../_models/transport";

@Component({
  selector: 'transport-details',
  templateUrl: './transport-details.component.html',
  styleUrls: ['./transport-details.component.css']
})
export class TransportDetailsComponent implements OnInit {
  @Input() transports: Transport[];

  constructor() { }

  ngOnInit() {
  }

}
