
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {Freighter} from "../_models/freighter";
import {UserInfoService} from "../_services/user-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Editable} from "../_models/editable";
import {LegalEntity} from "../_models/legal-entity";
import {FormErrors} from "../_models/form-errors";
import {LoaderService} from "../_services/loader.service";
import {FreighterClientService} from "../_services/freighter-client.service";
import {FreighterClient} from "../_models/freighter-client";
import {TitleService} from "../_services/title.service";

@Component({
  selector: 'app-edit-freighter-client-legal-entity',
  templateUrl: './edit-freighter-client-legal-entity.component.html',
  styleUrls: ['./edit-freighter-client-legal-entity.component.css']
})
export class EditFreighterClientLegalEntityComponent implements OnInit {
  entity = new Editable(new LegalEntity(), new FormErrors());
  client: FreighterClient;
  readOnly: boolean;
  freighter: Freighter;

  private clientId: number;
  private entityId: number;

  constructor(
    private freighterClientService: FreighterClientService,
    private route: ActivatedRoute,
    private router: Router,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();

    this.route.params
      .subscribe(
        params => {
          this.clientId = +params['id'];
          this.entityId = +params['entityId']
          this.loadClient();
          this.loadEntity();
        },
        e => console.error(e)
      );
  }

  private loadClient(): void {
    this.loaderService.show();

    this.freighterClientService
      .getClient(this.freighter, this.clientId).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        client => {
          this.client = client;
          this.setupTitle();
        },
        () => {}
      );
  }

  private loadEntity(): void {
    this.loaderService.show();

    this.freighterClientService
      .getLegalEntity(this.freighter, this.clientId, this.entityId).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        entity => {
          this.entity = new Editable(entity, new FormErrors(), false, true);
          this.readOnly = !entity.freighter || this.freighter.id != entity.freighter.id;
          this.setupTitle();
        },
        r => {}
      )
  }

  private setupTitle(): void {
    let clientName = this.client && this.client.name || '...';
    let entityName = this.entity.record.short_name || this.entity.record.name || '...';

    this.titleService.changeTitle(`${entityName} - Юр. лица - ${clientName} - Клиенты`);
  }

  private backToList(): void {
    this.router.navigate([`/freighter-clients`, this.clientId, 'legal-entities']);
  }

  onBack(): void {
    this.backToList();
  }

  onSaved(): void {
    this.backToList();
  }
}
