
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Freighter} from "../_models/freighter";
import {firstValueFrom, Observable} from "rxjs";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {ApiUtilsService} from "./api-utils.service";
import {PaymentDistributionSchemaType} from "../_models/payment-distribution-schema-type";
import {HttpResponse} from "@angular/common/http";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

@Injectable()
export class PaymentDistributionSchemaService {
  private schemaTypesPromises: Map<number, Promise<PaymentDistributionSchemaType[]>> = new Map();

  constructor(private _requestService: RequestWithErrorHandlerService, private apiUtilsService: ApiUtilsService) {}

  getFreighterPrivateSchemas(freighter: Freighter): Observable<PaymentDistributionSchema[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/payment_distribution_schemas/private.json`).pipe(
      map(r => r.body as PaymentDistributionSchema[]))
    ;
  }

  getSchema(id: number, freighter: Freighter): Observable<PaymentDistributionSchema> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/payment_distribution_schemas/${id}.json`).pipe(
      map(r => r.body as PaymentDistributionSchema))
    ;
  }

  getSchemaTypes(freighter: Freighter): Observable<PaymentDistributionSchemaType[]> {
    if(!this.schemaTypesPromises.has(freighter.id)) {
      this.schemaTypesPromises.set(freighter.id, firstValueFrom(this._requestService
        .get(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/payment_distribution_schemas/types.json`).pipe(
        map(r => r.body as PaymentDistributionSchemaType[]))
      ));
    }

    return fromPromise(this.schemaTypesPromises.get(freighter.id));
  }

  addSchema(freighter: Freighter, schema: PaymentDistributionSchema): Observable<HttpResponse<any>> {
    return this._requestService
      .post(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/payment_distribution_schemas.json`,
        PaymentDistributionSchemaService.schemaToData(schema)
      )
      ;
  }

  updateSchema(freighter: Freighter, schema: PaymentDistributionSchema): Observable<HttpResponse<any>> {
    return this._requestService
      .put(
        `${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/payment_distribution_schemas/${schema.id}.json`,
        PaymentDistributionSchemaService.schemaToData(schema)
      )
    ;
  }

  deleteSchema(freighter: Freighter, schema: PaymentDistributionSchema): Observable<HttpResponse<any>> {
    return this._requestService
      .delete(`${this.apiUtilsService.getPrefixByFreighterId(freighter.id)}/payment_distribution_schemas/${schema.id}.json`)
    ;
  }

  static schemaToData(schema: PaymentDistributionSchema): any {
    return {
      'payment_distribution_schema': {
        name: schema.name,
        schemaType: schema.schema_type,
        executorType: schema.executor_type,
        defaultSchema: schema.default_schema,
      }
    }
  };
}
