
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {PromoCodePresenter} from "../_models/promo-code-presenter";
import {Account} from "../_models/account";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class PromoService {
  constructor(private _requestService: RequestWithErrorHandlerService) {}

  apply(code: string): Observable<HttpResponse<any>> {
    return this._requestService.post('/me/promo.json', {code});
  }

  applyForAccount(account: Account, code: string): Observable<HttpResponse<any>> {
    return this._requestService.post(`/${account.id}/promo.json`, {code});
  }

  list(types: string[]): Observable<PromoCodePresenter[]> {
    return this._requestService
      .get('/me/promo.json', {
        include: types.join(',')
      }).pipe(
      map(r => r.body.promo_codes as PromoCodePresenter[]))
      ;
  }

  listForAccount(account: Account, types: string[]): Observable<PromoCodePresenter[]> {
    return this._requestService
      .get(`/${account.id}/promo.json`, {
        include: types.join(',')
      }).pipe(
      map(r => r.body.promo_codes as PromoCodePresenter[]))
      ;
  }
}
