export const CAPACITIES = {
  "0_5":"0,5",
  "0_7":"0,7",
  "0_8":"0,8",
  "_1":"1",
  "1_5":"1,5",
  "_2":"2",
  "2_5":"2,5",
  "_3":"3",
  "3_5":"3,5",
  "_4":"4",
  "4_5":"4,5",
  "_5":"5",
  "5_5":"5,5",
  "_6":"6",
  "6_5":"6,5",
  "_7":"7"
};
