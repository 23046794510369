import {Component, Input, OnInit} from '@angular/core';
import {TaxiTariff} from "../_models/taxi-tariff";
import {TariffTier} from "../_models/tariff-tier";

@Component({
  selector: 'taxi-tariff-informer',
  templateUrl: './taxi-tariff-informer.component.html',
  styleUrls: ['./taxi-tariff-informer.component.css']
})
export class TaxiTariffInformerComponent implements OnInit {
  @Input() tariff: TaxiTariff;
  @Input() tier: TariffTier;

  constructor() { }

  ngOnInit() {
    if(!this.tariff && this.tier) {
      this.tariff = new TaxiTariff();
      this.tariff.tier = this.tier;
    }
  }

}
