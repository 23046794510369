import {Component, Input} from "@angular/core";

@Component({
  selector: '[by-points-loaders-calculation]',
  templateUrl: './by-points.loaders.calculation.component.html',
  styleUrls: ['./calculation.component.css']
})
export class ByPointsLoadersCalculationComponent {
  @Input() calculation: any;
  @Input() tariff: any;
  @Input() loadersCount: number;
}
