import {PipeTransform, Pipe} from "@angular/core";
import {OrderPeriod} from "../_models/order-period";
import {DateUtils} from "../_utils/date-utils";
import {FormatDurationPipe} from "./format-duration.pipe";

declare var moment: any;

@Pipe({name: 'periodDuration'})
export class PeriodDurationPipe implements PipeTransform {
  private formatDurationPipe = new FormatDurationPipe();

  transform(period: OrderPeriod): string {
    let start = new moment(period.start);
    let end = new moment(period.end);

    let duration = moment.duration(end.diff(start));

    return this.formatDurationPipe.transform(duration.asMinutes());
  }
}
