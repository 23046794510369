
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../_services/loader.service";
import {TariffService} from "../_services/tariff.service";
import {TRANSPORT_BODIES} from "../_maps/transport-bodies";
import {OrderDraft} from "../_models/order-draft";
import {DraftUtils} from "../_utils/draft-utils";
import {TariffTier} from "../_models/tariff-tier";
import {Point} from "../_models/point";
import {UserInfoService} from "../_services/user-info.service";
import {FreighterService} from "../_services/freighter.service";
import {Observable} from "rxjs";
import {DeliverySchema} from "../_models/delivery-schema";
import {EXTRA_SEARCH_MAP} from "../_maps/extra-search-map";

class TransportBodyData {
  constructor(private _identifier: string, private _name: string, private _lengths: number[], public selected = false) {}

  get identifier(): string {
    return this._identifier;
  }

  get name(): string {
    return this._name;
  }

  get lengths(): number[] {
    return this._lengths;
  }
}

class Length {
  selected = true;

  constructor(private _length: number) {}

  get length(): number {
    return this._length;
  }
}

@Component({
  selector: 'draft-transport-features-editor',
  templateUrl: './draft-transport-features-editor.component.html',
  styleUrls: ['./draft-transport-features-editor.component.css']
})
export class DraftTransportFeaturesEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Input() updated = false;
  @Input() point: Point;
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  tiers: Object[] = [];
  selectedTier: string;
  tierBodies: TransportBodyData[] = [];
  lengths: Length[] = [];

  private selectedBodies: string[];
  private selectedLengths: number[];
  volume: number;
  hydroElevator: boolean;
  minCapacity: number;
  minLength: number;
  minWidth: number;
  minHeight: number;

  constructor(
    private tariffService: TariffService,
    private freighterService: FreighterService,
    private loaderService: LoaderService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit() {
    setTimeout(() => this.loadTiers(), 100);
    this.initSelectedBodies();
    this.initSelectedLengths();
    this.initVolume();
    this.initHydroElevator();
    this.initRanges();
  }

  private initSelectedBodies() {
    this.selectedBodies = DraftUtils.getBodyTypes(this.draft);
  }

  private initSelectedLengths() {
    this.selectedLengths = this.draft.extra_search_params && this.draft.extra_search_params.length_groups || [];
  }

  private initVolume() {
    let draftVolume = DraftUtils.getFeatureValue(this.draft, 'Ob_em_m3');
    this.volume = draftVolume !== '' ? parseFloat(draftVolume) : null;
  }

  private initHydroElevator() {
    this.hydroElevator = DraftUtils.getHydroElevator(this.draft);
  }

  private initRanges(): void {
    let extraParams = this.draft.extra_search_params || {};

    for(let prop in EXTRA_SEARCH_MAP) {
      if(EXTRA_SEARCH_MAP.hasOwnProperty(prop))
        this[prop] = extraParams[EXTRA_SEARCH_MAP[prop]] || null;
    }
  }

  private loadTiers() {
    this.loaderService.show();

    let loader: Observable<any[]> = this.userInfoService.isFreighter()
      ? this.freighterService.getTiers(
        this.userInfoService.userInfo.account.freighter,
        this.draft.delivery_schema ? [this.draft.delivery_schema] : null,
        this.point
      )
      : this.tariffService.getTier(this.point);

    loader.pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        tiers => {
          this.tiers = tiers;
          this.onLoadTiers();
        },
        () => {}
      )
    ;
  }

  private applySelectedTier() {
    this.applyTransportBodies();
    this.applySelectedBodies();
    this.saveSelectedBodies();
    this.applyLengths();
  }

  private applyDefaultSelectedTier() {
    this.applyTransportBodies();
    this.saveSelectedBodies();
    this.applyDefaultLengths();
  }

  private applyTransportBodies() {
    this.tierBodies = [];
    let tier = this.findSelectedTier();
    for (let carTypeData of tier['car_types']) {
      this.tierBodies.push(this.createTransportBodyTypeFromCarType(carTypeData));
    }
  }

  private applySelectedBodies() {
    for(let body of this.tierBodies)
      body.selected = this.selectedBodies.indexOf(body.identifier) > -1;
  }

  private saveSelectedBodies() {
    this.selectedBodies = this.tierBodies.filter(b => b.selected).map(b => b.identifier);
    console.log(this.selectedBodies);
  }

  private applySelectedLengths() {
    console.log(this.lengths);
    console.log(this.selectedLengths);
    for(let length of this.lengths)
      length.selected = this.selectedLengths.indexOf(length.length) > -1;
  }

  private saveSelectedLengths() {
    this.selectedLengths = this.lengths.filter(l => l.selected).map(l => l.length);
    console.log(this.selectedLengths);
  }

  private createTransportBodyTypeFromCarType(data: Object): TransportBodyData {
    return new TransportBodyData(
      data['type'],
      TRANSPORT_BODIES[data['type']] || data['type'],
      data['length_groups'].map(g => g['group']),
      data['default']
    );
  }

  private applyLengths() {
    if(this.hasSelectedBodies())
      this.applyBodiesLengths();
    else
      this.applyAllLengths();

    this.applySelectedLengths();
    this.saveSelectedLengths();
  }

  private applyDefaultLengths() {
    if(this.hasSelectedBodies())
      this.applyBodiesLengths();
    else
      this.applyAllLengths();

    this.saveSelectedLengths();
  }

  private applyBodiesLengths() {
    this.lengths = Array.from(new Set(this.tierBodies
      .filter(b => b.selected)
      .map(b => b.lengths)
      .reduce((prevLengths, currentLengths) => prevLengths.concat(currentLengths), [])))
      .sort()
      .map(l => new Length(l))
    ;
  }

  private applyAllLengths() {
    let tier = this.findSelectedTier();
    this.lengths = tier['length_groups'].map(g => new Length(g['group']));
  }

  private hasSelectedBodies() {
    return this.tierBodies.some(b => b.selected);
  }

  private initSelectedTier() {
    this.selectedTier = this.draft.extra_search_params
      && this.draft.extra_search_params.tariff_tier
      && this.draft.extra_search_params.tariff_tier.identifier
      || null;

    if(this.selectedTier == null)
      this.selectedTier = this.tiers[0]['identifier'];

    console.log(`active tier is ${this.selectedTier}`);
  }

  private findSelectedTier(): Object {
    return this.tiers.find(t => t['identifier'] === this.selectedTier);
  }

  /**
   * Поиск данных кузова по идентификатору
   *
   * @param {string} identifier
   * @return {TransportBodyData|undefined}
   */
  private findBody(identifier: string): TransportBodyData|undefined {
    return this.tierBodies.find(b => b.identifier == identifier);
  }

  private saveTierToDraft() {
    let tierData = this.findSelectedTier();
    let tier = new TariffTier();
    tier.identifier = tierData['identifier'];
    tier.name = tierData['name'];

    DraftUtils.getExtraSearchParams(this.draft).tariff_tier = tier;
    DraftUtils.setCapacities(this.draft, tierData['capacities']);
  }

  private saveBodyTypesToDraft() {
    DraftUtils.setBodyTypes(this.draft, this.selectedBodies);
  }

  private saveLengthsToDraft() {
    DraftUtils.getExtraSearchParams(this.draft).length_groups = this.selectedLengths;
    DraftUtils.setLengths(this.draft, []);
  }

  private saveVolume() {
    if(this.volume) {
      // Несмотря на типизацию, тут может оказаться строка. Потому выполняем дополнительную обработку.
      let parsedVolume = parseFloat(this.volume.toString());
      if(isNaN(parsedVolume))
        parsedVolume = 0;

      DraftUtils.setFeatureValue(this.draft, 'Ob_em_m3', parsedVolume.toString());
    } else {
      DraftUtils.removeFeature(this.draft, 'Ob_em_m3');
    }
  }

  private saveRanges(): void {
    let extraSearchParams = DraftUtils.getExtraSearchParams(this.draft);
    for(let prop in EXTRA_SEARCH_MAP) {
      if(EXTRA_SEARCH_MAP.hasOwnProperty(prop))
        extraSearchParams[EXTRA_SEARCH_MAP[prop]] = this[prop];
    }
  }

  private saveHydroElevator() {
    DraftUtils.setHydroElevator(this.draft, this.hydroElevator);
  }

  private onLoadTiers() {
    this.initSelectedTier();
    if(this.draft.id == null && !this.updated)
      this.applyDefaultSelectedTier();
    else
      this.applySelectedTier();
  }

  onChangeTier() {
    this.applyDefaultSelectedTier();
  }

  onChangeBodySelection() {
    this.saveSelectedBodies();
    this.applyLengths();
  }

  onChangeLengthSelection() {
    this.saveSelectedLengths();
  }

  onSave() {
    this.saveTierToDraft();
    this.saveBodyTypesToDraft();
    this.saveLengthsToDraft();
    this.saveVolume();
    this.saveHydroElevator();
    this.saveRanges();

    this.onUpdated.emit(this.draft);
  }
}
