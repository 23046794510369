<form (submit)="onSave()">
  <div class="registration__tabs-items">
    <div class="registration__tabs-item">
      <div class="registration__groups border">
        <span class="registration__label">Данные компании</span>
        <div class="registration__group">
          <div class="row">
            <div class="col col-4">
              <div class="form-group">
                <select class="form-control" id="type" [(ngModel)]="legalEntity.record.type" name="type" required [disabled]="readOnly">
                  <option value="ltd">ООО</option>
                  <option value="pe">ИП</option>
                  <option value="self_employed">Самозанятый</option>
                </select>
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.type"></input-error>
                <span class="input-hint">Например ООО или ИП</span>
              </div>
            </div>
            <div class="col col-8">
              <div class="form-group">
                <input class="form-control" placeholder="Официальное название организации" id="name" [(ngModel)]="legalEntity.record.name" name="name" required [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.name"></input-error>
                <span class="input-hint">Например ООО «Пример»</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="Отображаемый бренд" id="shortName" [(ngModel)]="legalEntity.record.short_name" name="shortName" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.short_name"></input-error>
                <span class="input-hint">Краткое наименование</span>
              </div>
            </div>
            <!--            <div class="col col-6">-->
            <!--              <span class="registration__hint">Подсказка<br>В две строки</span>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="registration__groups border">
        <span class="registration__label">Лицо с правом подписи</span>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="ФИО" id="signatory_authority" [(ngModel)]="legalEntity.record.signatory_authority" name="signatory_authority" [readOnly]="readOnly">
            <span class="input-hint">Фамилия Имя Отчество</span>
          </div>
        </div>
      </div>
      <div class="registration__groups border">
        <span class="registration__label">Реквизиты</span>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="Фактический адрес" id="actual_address" [(ngModel)]="legalEntity.record.actual_address" name="actual_address" [readOnly]="readOnly">
            <input-error [formErrors]="legalEntity.errors" field="legal_entity.actual_address"></input-error>
            <span class="input-hint">Фактический адрес</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="Юридический адрес" id="legal_address" [(ngModel)]="legalEntity.record.legal_address" name="legal_address" [readOnly]="readOnly">
            <input-error [formErrors]="legalEntity.errors" field="legal_entity.legal_address"></input-error>
            <span class="input-hint">Юридический адрес</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="form-group">
            <input class="form-control" placeholder="ИНН" id="inn" [(ngModel)]="legalEntity.record.inn" name="inn" required readonly>
            <span class="input-hint">ИНН</span>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="КПП" id="kpp" [(ngModel)]="legalEntity.record.kpp" name="kpp" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.kpp"></input-error>
                <span class="input-hint">КПП</span>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="ОГРН" id="ogrn" [(ngModel)]="legalEntity.record.ogrn" name="ogrn" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.ogrn"></input-error>
                <span class="input-hint">ОГРН</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="Расчётный счёт" id="checking_account" [(ngModel)]="legalEntity.record.checking_account" name="checking_account" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.checking_account"></input-error>
                <span class="input-hint">Расчётный счёт</span>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="Корреспондентский счёт" id="correspondent_account" [(ngModel)]="legalEntity.record.correspondent_account" name="correspondent_account" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.correspondent_account"></input-error>
                <span class="input-hint">Корреспондентский счёт</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="Название банка" id="bank" [(ngModel)]="legalEntity.record.bank" name="bank" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.bank"></input-error>
                <span class="input-hint">Название банка</span>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="БИК Банка" id="bik" [(ngModel)]="legalEntity.record.bik" name="bik" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.bik"></input-error>
                <span class="input-hint">БИК Банка</span>
              </div>
            </div>
          </div>
        </div>
        <div class="registration__group">
          <div class="row">
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="ОКТМО" id="oktmo" [(ngModel)]="legalEntity.record.oktmo" name="oktmo" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.oktmo"></input-error>
                <span class="input-hint">ОКТМО</span>
              </div>
            </div>
            <div class="col col-6">
              <div class="form-group">
                <input class="form-control" placeholder="ОКПО" id="okpo" [(ngModel)]="legalEntity.record.okpo" name="okpo" [readOnly]="readOnly">
                <input-error [formErrors]="legalEntity.errors" field="legal_entity.okpo"></input-error>
                <span class="input-hint">ОКПО</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="registration__btn" *ngIf="!readOnly">
        <div class="row">
          <div class="col col-6">
            <button class="btn btn-primary btn-block registration__btn-save" type="submit">
              Сохранить
            </button>
          </div>
          <div class="col col-6">
            <a href="#" class="registration__btn-reset" (click)="onCancel()">Отменить</a>
          </div>
        </div>
      </div>
      <div class="registration__btn" *ngIf="readOnly">
        <div class="row">
          <div class="col col-6">
            <button class="btn btn-primary btn-block registration__btn-save" type="submit">
              Применить
            </button>
          </div>
          <div class="col col-6">
            <a href="#" class="registration__btn-reset" (click)="onCancel()">Вернуться к списку</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
