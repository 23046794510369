import {List} from "../pager/list";
import {OrderDraft} from "./order-draft";
import {DraftListFilter} from "./draft-list-filter";

export class DraftsList implements List {
  constructor(public page: number, public pageSize: number, public totalCount: number, public filter: DraftListFilter, public drafts: OrderDraft[]) {
  }

  static empty(): DraftsList {
    return new DraftsList(0, 0, 0, new DraftListFilter(), []);
  }

  concat(array) {
    this.drafts = array.concat(this.drafts);
    return this;
  }

}
