
import {finalize} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Order} from "../_models/order";
import {LoaderService} from "../_services/loader.service";
import {OrderService} from "../_services/order.service";
import {OrderPeriodHistoryRow} from "../_models/order-period-history-row";

@Component({
  selector: 'customer-status-panel',
  templateUrl: './customer-status-panel.component.html',
  styleUrls: ['./customer-status-panel.component.css']
})
export class CustomerStatusPanelComponent implements OnInit, OnChanges {
  @Input() order: Order;
  @Output() onUpdated = new EventEmitter<Order>();

  history: OrderPeriodHistoryRow[];

  constructor(private loaderService: LoaderService, private orderService: OrderService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.history = this.order.periods[0] && this.order.periods[0].history || [];
  }

  acceptOrder() {
    this.loaderService.show();

    this.orderService
      .updateOrderStatus(this.order, 'accepted').pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        () => {}
      )
  }

  declineOrder() {
    this.loaderService.show();

    this.orderService
      .updateOrderStatus(this.order, 'declined').pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        () => {}
      )
  }

  onMoneyReturnAccept() {
    this.loaderService.show();
    this.orderService
      .moneyReturnAccepted(this.order).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onUpdated.emit(this.order),
        error => console.log('Невозможно подвердить передачу денег', error)
      );

  }

  onAcceptOrder() {
    if(confirm("Принять заказ?"))
      this.acceptOrder();
  }

  onDeclineOrder() {
    if(confirm("Отклонить заказ?"))
      this.declineOrder();
  }
}
