import {FilterType} from "./filter-type";

export class OnlineFilterItem {
  constructor(private _value: FilterType, private _name: string) {
  }

  get value(): FilterType {
    return this._value;
  }

  get name(): string {
    return this._name;
  }
}
