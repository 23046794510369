<div class="list-empty" *ngIf="parcels.length == 0">Нет новых посылок</div>

<table class="table table-condensed" *ngIf="parcels.length > 0">
  <thead>
  <tr>
    <th></th>
    <th>Заказ</th>
    <th>Дата доставки</th>
    <th>Получатель</th>
    <th>Стоимость груза</th>
  </tr>
  </thead>
  <tbody *ngFor="let p of parcels; let i = index" (click)="onClickRow(i)">
  <tr>
    <td rowspan="2">
      <div *ngIf="!p.disableSelection">
        <input type="checkbox" name="pselect_{{ i }}" [(ngModel)]="p.selected" *ngIf="multiselect">
        <input type="radio" name="pselect" [value]="i" [(ngModel)]="singleSelected" *ngIf="!multiselect">
      </div>
    </td>
    <td>
      <div class="posting-number">{{ p.info.posting_number }}</div>
      <div class="create-date">{{ p.info.parcel_date_creation|dt:"dd.MM.yy HH:mm" }}</div>
    </td>
    <td>{{ p.info.req_delivery_date|dt:"dd.MM.yy" }}</td>
    <td class="address">
      <div class="address">
        <span class="address" *ngIf="p.info.receiver.address">{{ p.info.receiver.address }}, </span>
      </div>
      <div class="contacts">
        <div class="company" *ngIf="p.info.receiver.company">
          <span class="name">{{ p.info.receiver.company }}</span>
          <span class="inn" *ngIf="p.info.receiver.inn">ИНН: {{ p.info.receiver.inn }}</span>
        </div>
        <div class="contact">
          <span class="name" *ngIf="p.info.receiver.name">{{ p.info.receiver.name }}</span>
          <span class="phone" *ngIf="p.info.receiver.phone">{{ p.info.receiver.phone|formatPhone }}</span>
          <span class="email" *ngIf="p.info.receiver.email">{{ p.info.receiver.email }}</span>
        </div>
      </div>
      <div class="add-info" *ngIf="p.info.address_add_info">{{ p.info.address_add_info }}</div>
    </td>
    <td class="cost">{{ p.info.parcel_declare_value|formatCost }}</td>
  </tr>
  <tr>
    <td colspan="5">
      <div class="error" *ngIf="p.importResult && !p.importResult.success">
        При импорте произошла ошибка: "{{ p.importResult.error }}"
      </div>
      <div class="tracks" *ngIf="p.stat.length > 0">
        <div class="track internal-track" *ngFor="let stat of p.stat" title="внутренний трек-номер" (click)="onClickTrackNumber(stat.internal_track_number)">
          <span>{{ stat.internal_track_number }}</span>
          <span><img src="/assets/images/copy-16.png" alt="копировать"></span>
        </div>
        <div class="track task" *ngFor="let stat of p.stat" title="маршрутизация" (click)="onClickTaskLink(stat.task_id)">
          <span>{{ stat.task_id }}</span>
          <span><img src="/assets/images/shortcut-16.png" alt="ссылка"></span>
        </div>
      </div>
      <div class="parcel-params">
        <div class="size">Размеры: {{ p.info.parcel_length / 10 }}x{{ p.info.parcel_width / 10 }}x{{ p.info.parcel_height / 10 }} см</div>
        <div class="weight">Вес: {{ p.info.parcel_weight }} г</div>
      </div>
      <div class="products">
        <div class="header">Состав заказа:</div>
        <div class="list">
          <div class="product" *ngFor="let product of p.info.products">
            <span class="item" *ngIf="product.item">[{{ product.item }}]</span>
            <span class="description" *ngIf="product.description">{{ product.description }}</span>
            <span class="size">{{ product.length / 10 }}x{{ product.width / 10 }}x{{ product.height / 10 }} см</span>
            <span class="weight">{{ product.weight }} г</span>
            <span class="quantity">{{ product.quantity }} шт</span>
            <span class="cost">{{ product.declare_value|formatCost }}</span>
          </div>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
