import {Injectable} from "@angular/core";
import {UserInfoService} from "./user-info.service";
import {environment} from "../../environments/environment";
import {OrderScreenConnection} from "../_websocket/connections/order-screen-connection";
import {OrderConnection} from "../_websocket/connections/order-connection";
import {TaxiConnection} from "../_websocket/connections/taxi-connection";
import {OrdersConnection} from "../_websocket/connections/orders-connection";
import {FreighterScreenConnection} from "../_websocket/connections/freighter-screen-connection";
import {SupportScreenConnection} from "../_websocket/connections/support-screen-connection";

@Injectable()
export class WebSocketService {
  constructor(
    private userInfoService: UserInfoService
  ) {}

  createScreenOrderConnection(orderId: number): OrderScreenConnection {
    return new OrderScreenConnection(this.createUrl(), orderId);
  }

  createFreighterScreenConnection(): FreighterScreenConnection {
    return new FreighterScreenConnection(this.createUrl());
  }

  createSupportScreenConnection(): SupportScreenConnection {
    return new SupportScreenConnection(this.createUrl());
  }

  createOrderConnection(orderId: number): OrderConnection {
    return new OrderConnection(this.createUrl(), orderId);
  }

  createOrdersConnection(): OrdersConnection {
    return new OrdersConnection(this.createUrl());
  }

  createTaxiConnection() {
    return new TaxiConnection(this.createUrl());
  }

  private createUrl(): string {
    return `${environment.webSocket}?token=${this.userInfoService.token}`;
  }
}
