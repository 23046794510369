import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Destination} from "../_models/destination";

class Additional {
  destination: Destination;
  cost: number;
  duration: number;
  minuteCost: number;
  hasLifting: boolean;
  loading: boolean;
  unloading: boolean;
}

@Component({
  selector: '[lgd-calculation]',
  templateUrl: './lgd.calculation.component.html',
  styleUrls: ['./calculation.component.css']
})
export class LgdCalculationComponent implements OnChanges {
  @Input() calculation: any;
  @Input() tariff: any;
  @Input() destinations: Destination[] = [];

  additional: Additional[] = [];
  additionalTotalCost: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['calculation'])
      this.refreshAdditional();
  }

  private refreshAdditional() {
    this.additional = [];
    let additionalCalculation = this.calculation['additional'] || [];
    for(const row of additionalCalculation) {
      if(!row.cost)
        continue;

      let additional = new Additional();
      additional.destination = this.destinations[row.destination_num] || null;
      additional.cost = row.cost;
      additional.duration = row.duration;
      additional.minuteCost = row.minute_cost;
      additional.hasLifting = row.has_lifting;

      if(additional.destination) {
        additional.loading = (additional.destination.loading || additional.destination.unloading)
          ? additional.destination.loading
          : additionalCalculation.destination_num == 0;
        additional.unloading = (additional.destination.loading || additional.destination.unloading)
          ? additional.destination.unloading
          : additionalCalculation.destination_num != 0;
      }

      this.additional.push(additional);
    }
    this.additionalTotalCost = this.additional.reduce((sum, add) => sum + add.cost, 0);
  }
}
