
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {UserInfoService} from "../_services/user-info.service";
import {Freighter} from "../_models/freighter";
import {ActivatedRoute, Router} from "@angular/router";
import {Editable} from "../_models/editable";
import {FormErrors} from "../_models/form-errors";
import {LoaderService} from "../_services/loader.service";
import {PaymentDistributionSchemaService} from "../_services/payment-distribution-schema.service";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {TitleService} from "../_services/title.service";

@Component({
  selector: 'app-edit-payment-distribution-schema',
  templateUrl: './edit-payment-distribution-schema.component.html',
  styleUrls: ['./edit-payment-distribution-schema.component.css']
})
export class EditPaymentDistributionSchemaComponent implements OnInit {
  freighter: Freighter;
  schema = new Editable(new PaymentDistributionSchema(), new FormErrors());

  constructor(
    private paymentDistributionSchemaService: PaymentDistributionSchemaService,
    private userInfoService: UserInfoService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: TitleService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();

    this.route.params
      .subscribe(
        params => this.loadSchema(+params['id']),
        e => console.error(e)
      );
  }

  private loadSchema(id: number): void {
    this.loaderService.show();

    this.paymentDistributionSchemaService
      .getSchema(id, this.userInfoService.getFreighter()).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        s => {
          this.schema = new Editable(s, new FormErrors(), false, true);
          this.titleService.changeTitle(`Схемы распределения средств - ${s.name}`)
        },
        () => {}
      )
    ;
  }

  onBack(): void {
    this.router.navigate([`/payment-distribution-schemas`]);
  }

  onSaved(): void {
    this.router.navigate([`/payment-distribution-schemas`]);
  }
}
