<form (submit)="onSave()">
<div class="registration__tabs-items">
  <div class="registration__tabs-item">
    <div class="registration__groups">
      <div class="registration__group">
        <div class="row">
          <div class="col col-6">
            <div class="form-group">
              <input class="form-control" placeholder="ФИО" [(ngModel)]="userName" name="name">
              <input-error [formErrors]="account.errors" field="name"></input-error>
              <input-error [formErrors]="account.errors" field="surname"></input-error>
              <input-error [formErrors]="account.errors" field="patronymic"></input-error>
              <span class="input-hint">Как к вам правильно обращаться</span>
            </div>
          </div>
          <div class="col col-6">
            <div class="form-group">
              <div class="date-control__wrapper">
                <div class="date-control__wrapper form-control date-control">
                  <input
                    placeholder="День рождения"
                    name="birthday"
                    [(ngModel)]="account.record.birthday"
                    type="date"
                    min="1960-01-01"
                    max="2018-12-31"
                  >
<!--                  <i class="icon icon-abs icon-date"></i>-->
                  <input-error [formErrors]="account.errors" field="birthday"></input-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="registration__groups">
      <div class="registration__group">
        <div class="row">
          <div class="col col-6">
            <div class="form-group">
              <input type="email" class="form-control" placeholder="Email" [(ngModel)]="account.record.email" name="email">
              <input-error [formErrors]="account.errors" field="email"></input-error>
            </div>
          </div>
          <div class="col col-6">
            <div class="form-group">
              <input
                      class="form-control"
                      placeholder="Номер телефона"
                      mask="0 (000) 000-0000"
                      prefix="+"
                      [(ngModel)]="account.record.phone"
                      name="phone"
                      [disabled]="!phoneEnabled">
              <input-error [formErrors]="account.errors" field="phone"></input-error>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="registration__groups">
      <div class="registration__group">
        <div class="row">
          <div class="col col-6">
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="account.record.main_company_client" name="main_company_client">
                Получает заказы
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="registration__groups" *ngIf="roleEnabled">
      <div class="registration__group">
        <div class="row">
          <div class="col col-6" *ngFor="let v of roles|mapToIterable">
            <div class="radio">
              <label>
                <input type="radio" name="role" [value]="v.key" [(ngModel)]="role">
                {{ v.val[0] }}
              </label>
            </div>
            <span class="input-hint">{{ v.val[1] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="registration__btn">
      <div class="row">
        <div class="col col-6">
          <button class="btn btn-primary btn-block registration__btn-save" type="submit">
            Сохранить
          </button>
        </div>
        <div class="col col-6">
          <button class="registration__btn-reset btn-block" type="button" data-dismiss="modal">Отменить</button>
        </div>
      </div>
    </div>
  </div>
</div>
</form>
