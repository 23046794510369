import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'promo-code-form',
  templateUrl: './promo-code-form.component.html',
  styleUrls: ['./promo-code-form.component.css']
})
export class PromoCodeFormComponent implements OnInit {
  @Input() error: string;
  @Input() success = false;
  @Input() autoEnter = false;
  @Input() showLoader = false;
  @Input() promocode: string = '';
  @Output() enter = new EventEmitter<string>();

  private autoEnterStream = new Subject<string>();

  disabled: boolean = false;

  constructor() { }

  ngOnInit() {
    if(this.autoEnter)
      this.initAutoEnterStream();
  }

  private initAutoEnterStream(): void {
    this.autoEnterStream = new Subject<string>();
    this.autoEnterStream
      .pipe(
        debounceTime(1000),
        distinctUntilChanged((a, b) => a === b)
      )
      .subscribe(code => this.enter.emit(code))
    ;
  }

  onSubmit(): void {
    if(!this.autoEnter)
      this.enter.emit(this.promocode);
  }

  onChangePromocode(): void {
    if(this.autoEnter)
      this.autoEnterStream.next(this.promocode);
  }
}
