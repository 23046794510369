import './rxjs-extensions';

import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule, NgOptimizedImage} from '@angular/common';

import {AlertComponent} from './alert/alert.component';
import {AlertService} from "./_services/alert.service";
import {LoginComponent} from './login/login.component';
import {routing} from "./app.routing";
import {RequestService} from "./_services/request.service";
import {UserInfoService} from "./_services/user-info.service";
import {LoginService} from "./_services/login.service";
import {AuthGuard} from "./_guards/auth.guard";
import {OrdersComponent} from './orders/orders.component';
import {LoaderComponent} from './loader/loader.component';
import {LoaderService} from "./_services/loader.service";
import {HttpErrorHandlerService} from "./_services/http-error-handler.service";
import {RequestWithErrorHandlerService} from "./_services/request-with-error-handler.service";
import {OrderService} from "./_services/order.service";
import {LogoutService} from "./_services/logout.service";
import {PagerComponent} from './pager/pager.component';
import {OrderStatusPipe} from "./_pipes/order-status.pipe";
import {MapToIterablePipe} from "./_pipes/map-to-iterable.pipe";
import {FullNamePipe} from "./_pipes/fullname.pipe";
import {TransportDescriptionPipe} from "./_pipes/transport-description.pipe";
import {OrderDetailComponent} from './order-detail/order-detail.component';
import {PayMethodPipe} from "./_pipes/pay-method.pipe";
import {PeriodDurationPipe} from "./_pipes/period-duration.pipe";
import {ParseFloatPipe} from "./_pipes/parse-float.pipe";
import {TransportBodyPipe} from "./_pipes/transport-body.pipe";
import {TransportCapacityPipe} from "./_pipes/transport-capacity.pipe";
import {TransportSizePipe} from "./_pipes/transport-size.pipe";
import {PeriodEditorComponent} from './period-editor/period-editor.component';
import {PaymentEditorComponent} from './payment-editor/payment-editor.component';
import {RouteEditorComponent} from './route-editor/route-editor.component';
import {AddressFieldComponent} from './address-field/address-field.component';
import {GeoService} from "./_services/geo.service";
import {CrewEditorComponent} from './crew-editor/crew-editor.component';
import {CrewsListComponent} from './crews-list/crews-list.component';
import {CrewService} from "./_services/crew.service";
import {EmployerStatusPipe} from "./_pipes/employer-status.pipe";
import {PhotoCommentEditorComponent} from './photo-comment-editor/photo-comment-editor.component';
import {TariffEditorComponent} from './tariff-editor/tariff-editor.component';
import {NewOrderComponent} from './new-order/new-order.component';
import {OrderParametersEditorComponent} from './order-parameters-editor/order-parameters-editor.component';
import {OrderStatusEditorComponent} from './order-status-editor/order-status-editor.component';
import {WebSocketService} from "./_services/web-socket.service";
import {OrderStatusPanelComponent} from './order-status-panel/order-status-panel.component';
import {ExecutionStatusPipe} from "./_pipes/execution-status.pipe";
import {TaximeterService} from "./_services/taximeter.service";
import {TransportTariffsComponent} from './transport-tariffs/transport-tariffs.component';
import {NewTransportTariffComponent} from './new-transport-tariff/new-transport-tariff.component';
import {TransportTariffFormComponent} from './transport-tariff-form/transport-tariff-form.component';
import {FreighterTransportTariffService} from "./_services/freighter-transport-tariff.service";
import {EditTransportTariffComponent} from './edit-transport-tariff/edit-transport-tariff.component';
import {LoaderTariffsComponent} from './loader-tariffs/loader-tariffs.component';
import {NewLoaderTariffComponent} from './new-loader-tariff/new-loader-tariff.component';
import {LoaderTariffFormComponent} from './loader-tariff-form/loader-tariff-form.component';
import {FreighterLoaderTariffService} from "./_services/freighter-loader-tariff.service";
import {EditLoaderTariffComponent} from './edit-loader-tariff/edit-loader-tariff.component';
import {hoursToSecConvertPipe, MeasurementsConvertPipe} from './_pipes/measurements-convert.pipe';
import {OrderGroupStatusPipe} from './_pipes/order-group-status.pipe';
import {NotificationService} from './_services/notification.service';
import {ApiUtilsService} from "./_services/api-utils.service";
import {NotificationsComponent} from './notifications/notifications.component';
import {NoticeComponent} from './notifications/notice.component';
import {EmployeeService} from './_services/employer.service';
import {NewOrderCounterComponent} from './new-order-counter/new-order-counter.component';
import {CustomerStatusPanelComponent} from './customer-status-panel/customer-status-panel.component';
import {IntercomComponent} from './intercom/intercom.component';
import {IntercomService} from './_services/intercom.service';
import {OrderControlsComponent} from './order-controls/order-controls.component';
import {OrderPanelDatepickerComponent} from './order-panel-datepicker/order-panel-datepicker.component'
import {ShowMoreComponent} from './show-more/show-more.component';
import {CalendarService} from './_services/calendar.service';
import {OrderDraftService} from "./_services/order-draft.service";
import {OrderDraftsComponent} from './order-drafts/order-drafts.component';
import {DeliveryStatusPipe} from "./_pipes/delivery-status.pipe";
import {NewDraftComponent} from './new-draft/new-draft.component';
import {DraftEditorComponent} from './draft-editor/draft-editor.component';
import {ClientEditorComponent} from './client-editor/client-editor.component';
import {DraftParametersEditorComponent} from './draft-parameters-editor/draft-parameters-editor.component';
import {DraftRouteEditorComponent} from './draft-route-editor/draft-route-editor.component';
import {DraftCommentEditorComponent} from './draft-comment-editor/draft-comment-editor.component';
import {DeliveryService} from "./_services/delivery.service";
import {DraftDeliverySchemaEditorComponent} from './draft-delivery-schema-editor/draft-delivery-schema-editor.component';
import {DraftPayEditorComponent} from './draft-pay-editor/draft-pay-editor.component';
import {EditDraftComponent} from './edit-draft/edit-draft.component';
import {DraftScheduleEditorComponent} from './draft-schedule-editor/draft-schedule-editor.component';
import {ScheduleViewComponent} from './schedule-view/schedule-view.component';
import {NewComplexDeliveryComponent} from './new-complex-delivery/new-complex-delivery.component';
import {ComplexDeliveryFormComponent} from './complex-delivery-form/complex-delivery-form.component';
import {ComplexDeliveryFormRowComponent} from './complex-delivery-form-row/complex-delivery-form-row.component';
import {OptimizationTaskService} from "./_services/optimization-task.service";
import {ComplexDeliveriesComponent} from './complex-deliveries/complex-deliveries.component';
import {ComplexDeliveryViewComponent} from './complex-delivery-view/complex-delivery-view.component';
import {EditComplexDeliveryComponent} from './edit-complex-delivery/edit-complex-delivery.component';
import {OptimizationStatusPipe} from "./_pipes/optimization-status.pipe";
import {AccuracyPricePipe} from "./_pipes/accuracy-price.pipe";
import {DrivingStatComponent} from './driving-stat/driving-stat.component';
import {DestinationWithArrivalTimePipe} from "./_pipes/destination-with-arrival-time.pipe";
import {TaxiSearchStatusPipe} from "./_pipes/taxi-search-status.pipe";
import {RoundPipe} from "./_pipes/round.pipe";
import {DraftPhotoEditorComponent} from './draft-photo-editor/draft-photo-editor.component';
import {FileUploadModule} from "ng2-file-upload";
import {DraftTransportFeaturesEditorComponent} from './draft-transport-features-editor/draft-transport-features-editor.component';
import {TariffService} from "./_services/tariff.service";
import {DraftBodyTypesPipe} from "./_pipes/draft-body-types.pipe";
import {DraftLengthsPipe} from "./_pipes/draft-lengths.pipe";
import {DraftCapacitiesPipe} from "./_pipes/draft-capacities.pipe";
import {OptimizationTypePipe} from "./_pipes/optimization-type.pipe";
import {TimeStatusClassPipe} from "./_pipes/time-status-class.pipe";
import {VoximplantService} from "./_services/voximplant.service";
import {VoximplantControlsComponent} from './voximplant-controls/voximplant-controls.component';
import {TimerPipe} from "./_pipes/timer.pipe";
import {CalculationComponent} from './calculation/calculation.component';
import {RoundMinutesToHourPipe} from "./_pipes/round-minutes-to-hour.pipe";
import {FormatDurationPipe} from "./_pipes/format-duration.pipe";
import {LoaderServicePipe} from "./_pipes/loader-service.pipe";
import {FormatCostPipe} from "./_pipes/format-cost.pipe";
import {SearchEmployeeStatusService} from "./_services/search-employee-status.service";
import {DeliveryProductComponent} from './delivery-product/delivery-product.component';
import {DeliveryProductWrapperComponent} from './delivery-product-wrapper/delivery-product-wrapper.component';
import {ComplexDeliveryCompactDestinationsComponent} from './complex-delivery-compact-destinations/complex-delivery-compact-destinations.component';
import {DraftHydroElevatorPipe} from "./_pipes/draft-hydro-elevator.pipe";
import {DraftTransportParameterPipe} from "./_pipes/draft-transport-parameter-pipe";
import {DraftSearchParamsEditorComponent} from './draft-search-params-editor/draft-search-params-editor.component';
import {InvoiceService} from "./_services/invoice.service";
import {OrderPaymentStatusPipe} from "./_pipes/order-payment-status.pipe";
import {HumanNumberPipe} from "./_pipes/human-number.pipe";
import {CompanyClientService} from "./_services/company-client.service";
import {FormatPhonePipe} from "./_pipes/format-phone.pipe";
import {DaysPipe} from "./_pipes/days.pipe";
import {AccountService} from "./_services/account.service";
import {LegalEntityService} from "./_services/legal-entity.service";
import {LegalEntityEditorComponent} from './legal-entity-editor/legal-entity-editor.component';
import {LegalEntitiesComponent} from './legal-entities/legal-entities.component';
import {LegalEntityFormComponent} from './legal-entity-form/legal-entity-form.component';
import {EditLegalEntityComponent} from './edit-legal-entity/edit-legal-entity.component';
import {InputErrorComponent} from "./input-error/input-error.component";
import {NewLegalEntityComponent} from './new-legal-entity/new-legal-entity.component';
import {ToArrayPipe} from "./_pipes/to-array.pipe";
import {IssueService} from "./_services/issue.service";
import {IssueFormComponent} from './issue-form/issue-form.component';
import {NewIssueComponent} from './new-issue/new-issue.component';
import {DeliveryIssuesComponent} from './delivery-issues/delivery-issues.component';
import {DestinationDeliveryIssuesComponent} from './destination-delivery-issues/destination-delivery-issues.component';
import {OrderDeliveryIssuesComponent} from './order-delivery-issues/order-delivery-issues.component';
import {DeliveryHistoryComponent} from './delivery-history/delivery-history.component';
import {DestinationDeliveryHistoryComponent} from './destination-delivery-history/destination-delivery-history.component';
import {DeliveryHistoryService} from "./_services/delivery-history.service";
import {OrderDeliveryHistoryComponent} from './order-delivery-history/order-delivery-history.component';
import {DeliveryCalculationService} from "./_services/delivery-calculation.service";
import {UserProfileComponent} from './user-profile/user-profile.component';
import {App2Component} from "./app2.component";
import {BankCardService} from "./_services/bank-card.service";
import {UserFormComponent} from './user-form/user-form.component';
import {UserEditorComponent} from './user-editor/user-editor.component';
import {NewUserComponent} from './new-user/new-user.component';
import {MessageDistributionComponent} from './message-distribution/message-distribution.component';
import {UsersListComponent} from './users-list/users-list.component';
import {UserService} from "./_services/user.service";
import {IntervalFilterComponent} from './interval-filter/interval-filter.component';
import {MessageFormComponent} from './message-form/message-form.component';
import {MessageDialogComponent} from './message-dialog/message-dialog.component';
import {RelativeTimeFilterComponent} from './relative-time-filter/relative-time-filter.component';
import {SoundNotificationService} from "./_services/sound-notification.service";
import {UserDetailComponent} from './user-detail/user-detail.component';
import {UserDetailDialogComponent} from './user-detail-dialog/user-detail-dialog.component';
import {AccountInvoicesComponent} from './account-invoices/account-invoices.component';
import {PaymentStatusPipe} from "./_pipes/payment-status.pipe";
import {DriverPaymentDetailsComponent} from './driver-payment-details/driver-payment-details.component';
import {LegalEntityTypePipe} from "./_pipes/legal-entity-type.pipe";
import {TransportDetailsComponent} from './transport-details/transport-details.component';
import {TaxiTariffInformerComponent} from './taxi-tariff-informer/taxi-tariff-informer.component';
import {LegalEntityDetailComponent} from './legal-entity-detail/legal-entity-detail.component';
import {TitleService} from "./_services/title.service";
import {SendBalanceDialogComponent} from './send-balance-dialog/send-balance-dialog.component';
import {SendBalanceFormComponent} from './send-balance-form/send-balance-form.component';
import {ReportService} from "./_services/report.service";
import {FreighterService} from "./_services/freighter.service";
import {FreightersFastListDialogComponent} from './freighters-fast-list-dialog/freighters-fast-list-dialog.component';
import {FreighterFiltersEditorComponent} from './freighter-filters-editor/freighter-filters-editor.component';
import {SearchTypesPipe} from "./_pipes/search-types.pipe";
import {TransportsComponent} from './transports/transports.component';
import {TransportService} from "./_services/transport.service";
import {EditTransportComponent} from './edit-transport/edit-transport.component';
import {TransportFormComponent} from './transport-form/transport-form.component';
import {TransportPhotosFormComponent} from './transport-photos-form/transport-photos-form.component';
import {PhotoFieldComponent} from './photo-field/photo-field.component';
import {ChainedPhotoFieldComponent} from './chained-photo-field/chained-photo-field.component';
import {NewTransportComponent} from './new-transport/new-transport.component';
import {EmployeesComponent} from './employees/employees.component';
import {EditEmployeeComponent} from './edit-employee/edit-employee.component';
import {EmployeeFormComponent} from './employee-form/employee-form.component';
import {InnDialogComponent} from './inn-dialog/inn-dialog.component';
import {NewEmployeeComponent} from './new-employee/new-employee.component';
import {CrewsComponent} from './crews/crews.component';
import {NewCrewComponent} from './new-crew/new-crew.component';
import {CrewFormComponent} from './crew-form/crew-form.component';
import {EmployeeSelectorComponent} from './employee-selector/employee-selector.component';
import {EmployeeSelectorDialogComponent} from './employee-selector-dialog/employee-selector-dialog.component';
import {TransportSelectorDialogComponent} from './transport-selector-dialog/transport-selector-dialog.component';
import {TransportSelectorComponent} from './transport-selector/transport-selector.component';
import {DateTimeFieldComponent} from './date-time-field/date-time-field.component';
import {EditCrewComponent} from './edit-crew/edit-crew.component';
import {CityService} from "./_services/city.service";
import {CityEditorComponent} from './city-editor/city-editor.component';
import {DtPipe} from "./_pipes/dt.pipe";
import {DestinationOptionsPipe} from "./_pipes/destination-options.pipe";
import {MapComponent} from './map/map.component';
import {MapService} from "./_services/map.service";
import {CallsComponent} from './calls/calls.component';
import {CallService} from "./_services/call.service";
import {RolePipe} from "./_pipes/role.pipe";
import {AudioPlayerComponent} from './audio-player/audio-player.component';
import {AudioPlayerService} from "./audio-player/service/audio-player.service";
import {OrderAreasInfoComponent} from './order-areas-info/order-areas-info.component';
import {ByZonesCalculationComponent} from "./calculation/by-zones.calculation.component";
import {DeliveryToPipe} from "./_pipes/delivery-to.pipe";
import {FreighterClientsComponent} from './freighter-clients/freighter-clients.component';
import {FreighterClientService} from "./_services/freighter-client.service";
import {NewFreighterClientComponent} from './new-freighter-client/new-freighter-client.component';
import {FreighterClientFormComponent} from './freighter-client-form/freighter-client-form.component';
import {EditFreighterClientComponent} from './edit-freighter-client/edit-freighter-client.component';
import {FreighterClientLegalEntitiesComponent} from './freighter-client-legal-entities/freighter-client-legal-entities.component';
import {NewFreighterClientLegalEntityComponent} from './new-freighter-client-legal-entity/new-freighter-client-legal-entity.component';
import {FreighterClientLegalEntityFormComponent} from './freighter-client-legal-entity-form/freighter-client-legal-entity-form.component';
import {EditFreighterClientLegalEntityComponent} from './edit-freighter-client-legal-entity/edit-freighter-client-legal-entity.component';
import {LegalEntitySelectorComponent} from './legal-entity-selector/legal-entity-selector.component';
import {LegalEntitySelectorDialogComponent} from './legal-entity-selector-dialog/legal-entity-selector-dialog.component';
import {CrewsMapService} from "./_services/crews-map.service";
import {DraftTariffEditorComponent} from './draft-tariff-editor/draft-tariff-editor.component';
import {PassportViewComponent} from './passport-view/passport-view.component';
import {PassportDialogComponent} from './passport-dialog/passport-dialog.component';
import {PassportTypePipe} from "./_pipes/passport-type.pipe";
import {PayMethodOptionPipe} from "./_pipes/pay-method-option.pipe";
import {AdditionalTimeCalculationComponent} from "./calculation/additional-time.calculation.component";
import {ComplexDeliveryImportFormComponent} from './complex-delivery-import-form/complex-delivery-import-form.component';
import {CompanyClientServiceService} from "./_services/company-client-service.service";
import {AddServiceIssueFormComponent} from './add-service-issue-form/add-service-issue-form.component';
import {CompanyClientProductService} from "./_services/company-client-product.service";
import {AddProductIssueFormComponent} from "./add-product-issue-form/add-product-issue-form.component";
import {TrackingServiceService} from "./_services/tracking-service.service";
import {StorehouseService} from "./_services/storehouse.service";
import {TokenService} from "./_services/token.service";
import {UploaderService} from "./_services/uploader.service";
import {ComplexDeliveryDestinationsSelectorComponent} from './complex-delivery-destinations-selector/complex-delivery-destinations-selector.component';
import {ComplexDeliveryDestinationsSelectorDialogComponent} from './complex-delivery-destinations-selector-dialog/complex-delivery-destinations-selector-dialog.component';
import {DeliveryTrackComponent} from './delivery-track/delivery-track.component';
import {CancelReasonService} from "./_services/cancel-reason.service";
import {JivoSiteService} from "./_services/jivo-site.service";
import {JivoSiteComponent} from './jivo-site/jivo-site.component';
import {SupportNotificationsComponent} from './support-notifications/support-notifications.component';
import {SupportNotificationService} from "./_services/support-notification.service";
import {InsuranceEditorComponent} from './insurance-editor/insurance-editor.component';
import {InsuranceSectionPipe} from "./_pipes/insurance-section.pipe";
import {DraftInsuranceEditorComponent} from './draft-insurance-editor/draft-insurance-editor.component';
import {OrderInsuranceEditorComponent} from './order-insurance-editor/order-insurance-editor.component';
import {InsuranceLimitsService} from "./_services/insurance-limits.service";
import {ByDistanceCalculationComponent} from "./calculation/by-distance.calculation.component";
import {DeliveryProductEditorComponent} from './delivery-product-editor/delivery-product-editor.component';
import {DeliveryProductDialogComponent} from './delivery-product-dialog/delivery-product-dialog.component';
import {ByPointsLoadersCalculationComponent} from "./calculation/by-points.loaders.calculation.component";
import {DraftSpecialDialogComponent} from './draft-special-dialog/draft-special-dialog.component';
import {PaymentDistributionSchemasComponent} from './payment-distribution-schemas/payment-distribution-schemas.component';
import {PaymentDistributionSchemaService} from "./_services/payment-distribution-schema.service";
import {ExecutorEntityTypePipe} from "./_pipes/executor-entity-type.pipe";
import {PaymentDistributionSchemaFormComponent} from './payment-distribution-schema-form/payment-distribution-schema-form.component';
import {NewPaymentDistributionSchemaComponent} from './new-payment-distribution-schema/new-payment-distribution-schema.component';
import {EditPaymentDistributionSchemaComponent} from './edit-payment-distribution-schema/edit-payment-distribution-schema.component';
import {FreighterClientEditorComponent} from './freighter-client-editor/freighter-client-editor.component';
import {LgdCalculationComponent} from "./calculation/lgd.calculation.component";
import {OzonParcelsListComponent} from './ozon-parcels-list/ozon-parcels-list.component';
import {OzonImportDialogComponent} from './ozon-import-dialog/ozon-import-dialog.component';
import {OzonService} from "./_services/ozon.service";
import {DeliveryTrackingService} from "./_services/delivery-tracking.service";
import {ClipboardComponent} from './clipboard/clipboard.component';
import {ClipboardService} from "./_services/clipboard.service";
import {CrossAuthComponent} from './cross-auth/cross-auth.component';
import {CrossAuthService} from "./_services/cross-auth.service";
import {PayMethodsService} from "./_services/pay-methods.service";
import {PromoCodeDialogComponent} from './promo-code-dialog/promo-code-dialog.component';
import {PromoCodeFormComponent} from './promo-code-form/promo-code-form.component';
import {PromoService} from "./_services/promo.service";
import {StorehouseCreatorComponent} from './storehouse-creator/storehouse-creator.component';
import {CompanySearchFieldComponent} from './company-search-field/company-search-field.component';
import {CompanyDetailsService} from "./_services/company-details.service";
import {BankSearchFieldComponent} from './bank-search-field/bank-search-field.component';
import {CostAdjustmentComponent} from './cost-adjustment/cost-adjustment.component';
import {AdjustmentService} from "./_services/adjustment.service";
import {AdjustmentsSumComponent} from './adjustments-sum/adjustments-sum.component';
import {WithCityTzPipe} from "./_pipes/with-city-tz.pipe";
import {CityTzPipe} from "./_pipes/city-tz.pipe";
import {MyTzPipe} from "./_pipes/my-tz.pipe";
import {TimestampToDatePipe} from "./_pipes/timestamp-to-date.pipe";
import {HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {NgxUploaderModule} from "@angular-ex/uploader";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {GoogleMapsModule} from "@angular/google-maps";
import {NgScrollbarModule} from "ngx-scrollbar";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {Lgd2CalculationComponent} from "./calculation/lgd2.calculation.component";
import {NgxMaskDirective, provideNgxMask} from "ngx-mask";
import {VozovozCalculationComponent} from "./calculation/vozovoz.calculation.component";
import {DeliverySchemasDialogComponent} from './delivery-schemas-dialog/delivery-schemas-dialog.component';
import {NewComplexDeliveryMethodDialogComponent} from './new-complex-delivery-method-dialog/new-complex-delivery-method-dialog.component';
import {DateTimeslotComponent} from './date-timeslot/date-timeslot.component';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NewOrderService} from "./_services/new-order.service";
import {BrowserAnimationsModule, provideAnimations} from "@angular/platform-browser/animations";
import {ExpressDeliveryTimeSlotService} from "./_services/express-delivery-time-slot.service";
import { TooltipComponent } from './tooltip/tooltip.component';

declare var moment: any;

export function initApp(userInfoService: UserInfoService, voximplantService: VoximplantService) {
  return () => userInfoService.setup()
    .then(() => {
      if(userInfoService.isPrivilegedUser())
        voximplantService.init();
    })
    .catch(e => {
      console.log(e.toString());
      return e;
    });
}

@NgModule({
  declarations: [
    // pipes
    OrderStatusPipe,
    MapToIterablePipe,
    FullNamePipe,
    TransportDescriptionPipe,
    PayMethodPipe,
    PeriodDurationPipe,
    ParseFloatPipe,
    TransportBodyPipe,
    TransportCapacityPipe,
    TransportSizePipe,
    EmployerStatusPipe,
    ExecutionStatusPipe,
    MeasurementsConvertPipe,
    hoursToSecConvertPipe,
    OrderGroupStatusPipe,
    DeliveryStatusPipe,
    OptimizationStatusPipe,
    AccuracyPricePipe,
    DestinationWithArrivalTimePipe,
    TaxiSearchStatusPipe,
    RoundPipe,
    DraftBodyTypesPipe,
    DraftLengthsPipe,
    DraftCapacitiesPipe,
    DraftHydroElevatorPipe,
    OptimizationTypePipe,
    TimeStatusClassPipe,
    TimerPipe,
    RoundMinutesToHourPipe,
    FormatDurationPipe,
    LoaderServicePipe,
    FormatCostPipe,
    DraftTransportParameterPipe,
    OrderPaymentStatusPipe,
    HumanNumberPipe,
    FormatPhonePipe,
    DaysPipe,
    ToArrayPipe,
    PaymentStatusPipe,
    LegalEntityTypePipe,
    SearchTypesPipe,
    DtPipe,
    DestinationOptionsPipe,
    RolePipe,
    DeliveryToPipe,
    PassportTypePipe,
    PayMethodOptionPipe,
    InsuranceSectionPipe,
    ExecutorEntityTypePipe,
    WithCityTzPipe,
    CityTzPipe,
    MyTzPipe,
    TimestampToDatePipe,

    // components
    App2Component,
    AlertComponent,
    LoginComponent,
    OrdersComponent,
    LoaderComponent,
    PagerComponent,
    OrderDetailComponent,
    PeriodEditorComponent,
    PaymentEditorComponent,
    RouteEditorComponent,
    AddressFieldComponent,
    CrewEditorComponent,
    CrewsListComponent,
    PhotoCommentEditorComponent,
    TariffEditorComponent,
    NewOrderComponent,
    OrderParametersEditorComponent,
    OrderStatusEditorComponent,
    OrderStatusPanelComponent,
    TransportTariffsComponent,
    NewTransportTariffComponent,
    TransportTariffFormComponent,
    EditTransportTariffComponent,
    LoaderTariffsComponent,
    NewLoaderTariffComponent,
    NewLoaderTariffComponent,
    LoaderTariffFormComponent,
    EditLoaderTariffComponent,
    NotificationsComponent,
    NoticeComponent,
    NewOrderCounterComponent,
    CustomerStatusPanelComponent,
    IntercomComponent,
    OrderControlsComponent,
    OrderPanelDatepickerComponent,
    ShowMoreComponent,
    OrderDraftsComponent,
    NewDraftComponent,
    DraftEditorComponent,
    ClientEditorComponent,
    DraftParametersEditorComponent,
    DraftRouteEditorComponent,
    DraftCommentEditorComponent,
    DraftDeliverySchemaEditorComponent,
    DraftPayEditorComponent,
    EditDraftComponent,
    DraftScheduleEditorComponent,
    ScheduleViewComponent,
    NewComplexDeliveryComponent,
    ComplexDeliveryFormComponent,
    ComplexDeliveryFormRowComponent,
    ComplexDeliveriesComponent,
    ComplexDeliveryViewComponent,
    EditComplexDeliveryComponent,
    DrivingStatComponent,
    DraftPhotoEditorComponent,
    DraftTransportFeaturesEditorComponent,
    VoximplantControlsComponent,
    CalculationComponent,
    ByZonesCalculationComponent,
    AdditionalTimeCalculationComponent,
    DeliveryProductComponent,
    DeliveryProductWrapperComponent,
    ComplexDeliveryCompactDestinationsComponent,
    DraftSearchParamsEditorComponent,
    LegalEntityEditorComponent,
    LegalEntitiesComponent,
    LegalEntityFormComponent,
    EditLegalEntityComponent,
    InputErrorComponent,
    NewLegalEntityComponent,
    IssueFormComponent,
    NewIssueComponent,
    DeliveryIssuesComponent,
    DestinationDeliveryIssuesComponent,
    OrderDeliveryIssuesComponent,
    DeliveryHistoryComponent,
    DestinationDeliveryHistoryComponent,
    OrderDeliveryHistoryComponent,
    UserProfileComponent,
    UserFormComponent,
    UserEditorComponent,
    NewUserComponent,
    MessageDistributionComponent,
    UsersListComponent,
    IntervalFilterComponent,
    MessageFormComponent,
    MessageDialogComponent,
    RelativeTimeFilterComponent,
    UserDetailComponent,
    UserDetailDialogComponent,
    AccountInvoicesComponent,
    DriverPaymentDetailsComponent,
    TransportDetailsComponent,
    TaxiTariffInformerComponent,
    LegalEntityDetailComponent,
    SendBalanceDialogComponent,
    SendBalanceFormComponent,
    FreightersFastListDialogComponent,
    FreighterFiltersEditorComponent,
    TransportsComponent,
    EditTransportComponent,
    TransportFormComponent,
    TransportPhotosFormComponent,
    PhotoFieldComponent,
    ChainedPhotoFieldComponent,
    NewTransportComponent,
    EmployeesComponent,
    EditEmployeeComponent,
    EmployeeFormComponent,
    InnDialogComponent,
    NewEmployeeComponent,
    CrewsComponent,
    NewCrewComponent,
    CrewFormComponent,
    EmployeeSelectorComponent,
    EmployeeSelectorDialogComponent,
    TransportSelectorDialogComponent,
    TransportSelectorComponent,
    DateTimeFieldComponent,
    EditCrewComponent,
    CityEditorComponent,
    MapComponent,
    CallsComponent,
    RolePipe,
    AudioPlayerComponent,
    OrderAreasInfoComponent,
    FreighterClientsComponent,
    NewFreighterClientComponent,
    FreighterClientFormComponent,
    EditFreighterClientComponent,
    FreighterClientLegalEntitiesComponent,
    NewFreighterClientLegalEntityComponent,
    FreighterClientLegalEntityFormComponent,
    EditFreighterClientLegalEntityComponent,
    LegalEntitySelectorComponent,
    LegalEntitySelectorDialogComponent,
    DraftTariffEditorComponent,
    PassportViewComponent,
    PassportDialogComponent,
    ComplexDeliveryImportFormComponent,
    AddServiceIssueFormComponent,
    AddProductIssueFormComponent,
    ComplexDeliveryDestinationsSelectorComponent,
    ComplexDeliveryDestinationsSelectorDialogComponent,
    DeliveryTrackComponent,
    JivoSiteComponent,
    SupportNotificationsComponent,
    InsuranceEditorComponent,
    DraftInsuranceEditorComponent,
    OrderInsuranceEditorComponent,
    ByDistanceCalculationComponent,
    DeliveryProductEditorComponent,
    DeliveryProductDialogComponent,
    ByPointsLoadersCalculationComponent,
    DraftSpecialDialogComponent,
    PaymentDistributionSchemasComponent,
    PaymentDistributionSchemaFormComponent,
    NewPaymentDistributionSchemaComponent,
    EditPaymentDistributionSchemaComponent,
    FreighterClientEditorComponent,
    LgdCalculationComponent,
    Lgd2CalculationComponent,
    OzonParcelsListComponent,
    OzonImportDialogComponent,
    ClipboardComponent,
    CrossAuthComponent,
    PromoCodeDialogComponent,
    PromoCodeFormComponent,
    StorehouseCreatorComponent,
    CompanySearchFieldComponent,
    BankSearchFieldComponent,
    CostAdjustmentComponent,
    AdjustmentsSumComponent,
    VozovozCalculationComponent,
    DeliverySchemasDialogComponent,
    NewComplexDeliveryMethodDialogComponent,
    DateTimeslotComponent,
    TooltipComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CommonModule,
    AutocompleteLibModule,
    NgxUploaderModule,
    FileUploadModule,
    GoogleMapsModule,
    NgScrollbarModule,
    DragDropModule,
    NgxMaskDirective,
    routing,
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    BrowserAnimationsModule,
  ],
  providers: [
    AuthGuard,
    AlertService,
    LoaderService,
    RequestService,
    HttpErrorHandlerService,
    RequestWithErrorHandlerService,
    OrderService,
    OrderDraftService,
    CrewService,
    UserInfoService,
    LoginService,
    LogoutService,
    GeoService,
    WebSocketService,
    // GoogleMapsAPIWrapper,
    TaximeterService,
    FreighterTransportTariffService,
    FreighterLoaderTariffService,
    ApiUtilsService,
    NotificationService,
    EmployeeService,
    IntercomService,
    CalendarService,
    DeliveryService,
    OptimizationTaskService,
    TariffService,
    VoximplantService,
    SearchEmployeeStatusService,
    InvoiceService,
    CompanyClientService,
    CompanyClientServiceService,
    CompanyClientProductService,
    AccountService,
    LegalEntityService,
    IssueService,
    DeliveryHistoryService,
    DeliveryCalculationService,
    BankCardService,
    UserService,
    SoundNotificationService,
    TitleService,
    ReportService,
    FreighterService,
    TransportService,
    CityService,
    MapService,
    CallService,
    AudioPlayerService,
    FreighterClientService,
    CrewsMapService,
    TrackingServiceService,
    StorehouseService,
    TokenService,
    UploaderService,
    CancelReasonService,
    JivoSiteService,
    SupportNotificationService,
    InsuranceLimitsService,
    PaymentDistributionSchemaService,
    OzonService,
    DeliveryTrackingService,
    ClipboardService,
    CrossAuthService,
    PayMethodsService,
    PromoService,
    CompanyDetailsService,
    AdjustmentService,
    NewOrderService,
    ExpressDeliveryTimeSlotService,
    provideAnimations(),
    provideNgxMask(),
    {
      'provide': APP_INITIALIZER,
      'useFactory': initApp,
      'deps': [UserInfoService, VoximplantService],
      'multi': true
    }
  ],
  bootstrap: [App2Component]
})
export class AppModule {
  constructor() {
    moment.locale('ru_RU');
  }
}
