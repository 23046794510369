<div class="modal fade" tabindex="-1" role="dialog" #issuesDialog>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <h4 class="modal-title">Проблемы на заказе</h4>
        </div>
      </div>
      <div class="modal-body">
        <delivery-issues [issues]="issues" [loading]="loading" [productVisible]="true"></delivery-issues>
      </div>
    </div>
  </div>
</div>
