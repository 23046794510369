<table class="table" *ngIf="transports.length > 1">
  <tbody>
  <tr *ngFor="let transport of transports">
    <td>{{ transport|transportDescription:true }}</td>
  </tr>
  </tbody>
</table>

<table class="table" *ngIf="transports.length == 1">
  <tbody>
  <tr>
    <td>Номер</td>
    <td>{{ transports[0].features.number || '-' }}</td>
  </tr>
  <tr>
    <td>Модель</td>
    <td>{{ transports[0].car_model }}</td>
  </tr>
  <tr>
    <td>Кузов</td>
    <td>{{ transports[0]|transportBody }}</td>
  </tr>
  <tr>
    <td>Грузоподъёмность</td>
    <td>{{ transports[0]|transportCapacity }} т.</td>
  </tr>
  <tr>
    <td>Размеры, Д х Ш х В, м</td>
    <td>{{ transports[0]|transportSize }}</td>
  </tr>
  <tr>
    <td>Объём, м<sup>3</sup></td>
    <td>{{ transports[0].features.Ob_em_m3 }}</td>
  </tr>
  <tr>
    <td>Разрешён въезд в ТТК</td>
    <td><span *ngIf="transports[0].features.Razreshen_v_ezd_v_TTK">Да</span></td>
  </tr>
  </tbody>
</table>
