<ng-template [ngIf]="transportMode">
  <tr>
    <th>Время в пути</th>
    <td>{{ calculation.time_at_way }} мин.</td>
  </tr>
  <tr>
    <th>Оплачиваемое время в пути</th>
    <td>({{ calculation.time_at_way }} мин. - {{ tariff.includedMileageDuration }} бесплатных мин.) * {{ calculation.time_at_way_minute_cost }}&nbsp;р. = {{ calculation.time_at_way_cost }} р.</td>
  </tr>
  <ng-template [ngIf]="calculation.distance_in_base_area > 0">
    <tr class="header">
      <th colspan="2">Внутри зоны "{{ calculation.base_area_name }}"</th>
    </tr>
    <tr>
      <th>Общее расстояние</th>
      <td>{{ calculation.distance_in_base_area }} км</td>
    </tr>
    <tr>
      <th>Оплачиваемое расстояние</th>
      <td>{{ calculation.distance_in_base_area }} км - {{ tariff.freeDestinationInBaseArea }} бесплатных км = {{ calculation.payable_distance_in_base_area }} км</td>
    </tr>
    <tr>
      <th>Стоимость</th>
      <td>{{ calculation.payable_distance_in_base_area }} км * {{ tariff.kmCostInBaseArea }} р. = {{ calculation.distance_in_base_area_cost }} р.</td>
    </tr>
  </ng-template>
  <ng-template [ngIf]="calculation.distance_beyond_base_area > 0">
    <tr class="header">
      <th colspan="2">За пределами зоны "{{ calculation.base_area_name }}"</th>
    </tr>
    <tr>
      <th>Общее расстояние</th>
      <td>{{ calculation.distance_beyond_base_area }} км</td>
    </tr>
    <tr>
      <th>Стоимость</th>
      <td>{{ calculation.distance_beyond_base_area }} км * {{ tariff.kmCostBeyondBaseArea }} р. = {{ calculation.distance_beyond_base_area_cost }} р.</td>
    </tr>
  </ng-template>
  <ng-template [ngIf]="calculation.additional_destinations_cost > 0">
    <tr class="header">
      <th colspan="2">Дополнительные точки</th>
    </tr>
    <tr>
      <th>Количество</th>
      <td>{{ calculation.additional_destinations_count }}</td>
    </tr>
    <tr>
      <th>Стоимость</th>
      <td>{{ calculation.additional_destinations_count }} * {{ tariff.additionalDestinationCost }} р. = {{ calculation.additional_destinations_cost }} р.</td>
    </tr>
  </ng-template>
  <ng-template ngFor let-add [ngForOf]="transportAdditional">
    <tr class="header">
      <th colspan="2">Дополнительно на адресе "{{ add.destination && add.destination.destination.addr }}"</th>
    </tr>
    <tr *ngIf="add.timeCost">
      <th>Дополнительное ожидание</th>
      <td>{{ add.duration }} мин. * {{ add.minuteCost }} р. = {{ add.timeCost }} р.</td>
    </tr>
  </ng-template>
</ng-template>
<ng-template [ngIf]="!transportMode">
  <ng-template ngFor let-add [ngForOf]="loadersAdditional">
    <tr class="header">
      <th colspan="2">Дополнительно на адресе {{ add.destinationNum + 1 }} ({{ add.destination && add.destination.destination.addr }})</th>
    </tr>
    <tr *ngFor="let item of add.items || []">
      <th>Груз {{ item.weight }} кг</th>
      <td>{{ item.count }} шт. * {{ item.price }} р. = {{ item.cost }} р.</td>
    </tr>
    <tr *ngIf="add.additionalItemsCost">
      <th>
        Итоговая стоимость
        <span *ngIf="add.loading"> погрузки</span>
        <span *ngIf="add.unloading"> разгрузки</span>
      </th>
      <td>{{ add.additionalItemsCost }} р.</td>
    </tr>
  </ng-template>
</ng-template>