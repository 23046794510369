import {ScreenConnection} from "./screen-connection";
import {Point} from "../../_models/point";
import {ScreenTransportParams} from "../../_models/screen-transport-params";

export abstract class ScreenConnectionWithTransportParams extends ScreenConnection {
  protected screenName:string;

  private lastBounds: Point[];
  private lastTransportParams: ScreenTransportParams;

  protected init() {
    if(this.lastBounds)
      this.registerScreen(this.lastBounds[0], this.lastBounds[1], this.lastTransportParams);
  }

  registerScreen(northEast: Point, southWest: Point, transportParams?: ScreenTransportParams): void {
    this.lastBounds = [ northEast, southWest ];
    this.lastTransportParams = transportParams;

    this.sendMessage(JSON.stringify({
      action: this.screenName,
      data: {
        lat1: southWest.lat,
        lng1: southWest.lng,
        lat2: northEast.lat,
        lng2: northEast.lng,
        transport: transportParams,
        compact: true
      },
    }));
  }

}
