
import {map} from 'rxjs/operators';
import {RequestService} from "./request.service";
import {Observable} from "rxjs";
import {Address} from "../_models/address";
import {Injectable} from "@angular/core";
import {Point} from "../_models/point";
import {TripPoint} from "../_models/trip-point";

@Injectable()
export class GeoService {
  constructor(private requestService: RequestService) {}

  getAddresses(search: string, context?: string): Observable<Address[]> {
    return this.requestService
      .get('/geo/address.json', { address: search, context }).pipe(
      map(r => r.body as Address[]));
  }

  getLocation(lat: number, lon: number, context?: string): Observable<Address> {
    return this.requestService
      .get('/geo/location.json', { location: `${lat},${lon}`, context }).pipe(
      map(r => r.body as Address));
  }

  getGooglePlace(placeId: string, context?: string): Observable<Address> {
    return this.requestService
      .get('/geo/location/google.json', { placeId, context }).pipe(
      map(r => r.body as Address));
  }

  getRoute(points: Point[], zoom: number = 11): Observable<Point[]> {
    let data = GeoService.prepareLocationsForRequest(points);
    data['zoom'] = zoom;

    return this.requestService
      .get('/geo/route.json', data).pipe(
      map(r => r.body.route_geometry_points as Point[]));
  }

  optimizeRoute(points: Point[]): Observable<TripPoint[]> {
    let data = GeoService.prepareLocationsForRequest(points);

    return this.requestService
      .get('/geo/route/optimize.json', data).pipe(
      map(r => r.body as TripPoint[]));
  }

  private static prepareLocationsForRequest(points: Point[]): Object {
    let result = {};

    for(let i in points) {
      let point = points[i];
      let key = `location[${i}]`;

      result[key] = `${point.lat},${point.lon}`;
    }

    return result;
  }
}
