import {finalize} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {Freighter} from "../_models/freighter";
import {UserInfoService} from "../_services/user-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../_services/loader.service";
import {LegalEntityService} from "../_services/legal-entity.service";
import {Editable} from "../_models/editable";
import {LegalEntity} from "../_models/legal-entity";
import {FormErrors} from "../_models/form-errors";
import {AlertService} from "../_services/alert.service";
import {FreighterClientService} from "../_services/freighter-client.service";
import {FreighterClient} from "../_models/freighter-client";
import {TitleService} from "../_services/title.service";

@Component({
  selector: 'app-new-freighter-client-legal-entity',
  templateUrl: './new-freighter-client-legal-entity.component.html',
  styleUrls: ['./new-freighter-client-legal-entity.component.css']
})
export class NewFreighterClientLegalEntityComponent implements OnInit {
  entity = new Editable(new LegalEntity(), new FormErrors());
  freighter: Freighter;
  client: FreighterClient;
  readOnly = true;

  private clientId: number;
  private inn: string;

  constructor(
    private freighterClientService: FreighterClientService,
    private legalEntityService: LegalEntityService,
    private route: ActivatedRoute,
    private router: Router,
    private userInfoService: UserInfoService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.freighter = this.userInfoService.getFreighter();

    this.route.params
      .subscribe(
        params => {
          this.clientId = +params['id'];
          this.inn = params['inn']
          this.loadClient();
          this.loadEntity();
        },
        e => console.error(e)
      );
  }

  private loadClient(): void {
    this.loaderService.show();

    this.freighterClientService
      .getClient(this.freighter, this.clientId).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        client => {
          this.client = client;
          this.titleService.changeTitle(`Добавление - Юр. лица - ${client.name} - Клиенты`);
        },
        () => {}
      );
  }

  private loadEntity(): void {
    this.entity = new Editable(new LegalEntity(), new FormErrors(), true);

    this.loaderService.show();

    this.legalEntityService
      .findLegalEntityByInn(this.freighter, this.inn).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        entity => {
          if(entity) {
            this.entity = new Editable(entity, new FormErrors(), false, true);
            this.readOnly = !entity.freighter || this.freighter.id != entity.freighter.id;
          } else {
            this.alertService.clear();
            this.readOnly = false;
            this.entity.record.inn = this.inn;
          }
          this.entity.isLoaded = true;
        },
        r => {}
      )
  }

  private backToList(): void {
    this.router.navigate([`/freighter-clients`, this.clientId, 'legal-entities']);
  }

  onBack(): void {
    this.backToList();
  }

  onSaved(): void {
    this.backToList();
  }
}
