import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Employer} from "../_models/employer";
import {Passport} from "../_models/passport";
import {Account} from "../_models/account";

@Component({
  selector: 'passport-dialog',
  templateUrl: './passport-dialog.component.html',
  styleUrls: ['./passport-dialog.component.css']
})
export class PassportDialogComponent implements OnInit, OnDestroy {
  @ViewChild('passportDialog', { static: true }) dialogEl: ElementRef;

  passport: Passport;
  account: Account;

  private wasShown = false;
  private modalWasInit = false;

  constructor() { }

  ngOnInit() {
  }

  private showDialog() {
    $(this.dialogEl.nativeElement).modal('show');

    this.wasShown = true;

    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      });
    }
  }

  hideDialog(): void {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  showDialogForEmployer(employer: Employer) {
    this.showDialog();

    this.passport = employer.passports[0];
    this.account = employer.account;
  }

  ngOnDestroy(): void {
    if(this.wasShown)
      this.hideDialog();
  }
}
