export const COLORS: any = {
  'complete': {
    primary: '#C6C6C6',
    secondary: '#C6C6C6'
  },
  'canceled': {
    primary: '#EB5757',
    secondary: '#EB5757'
  },
  'new': {
    primary: '#6FCF97',
    secondary: '#6FCF97'
  },
  'pending': {
    primary: '#FFC658',
    secondary: '#FFC658'
  },
  'agreement': {
    primary: '#BB6BD9',
    secondary: '#BB6BD9'
  },
  'default': {
    primary: '#ede',
    secondary: '#ede'
  }
};

export const EVENT_STATUS = {
  'new': [
    'new',
    'edit',
    'accepted',
    'checking'
  ]
  ,
  'canceled': [
    'not_agree',
    'declined',
    'canceled_by_freighter',
    'canceled_by_employer',
    'canceled_by_client',
    'canceled_by_system'
  ]
  ,
  'pending': [
    'ready',
    'agree'
  ]
  ,
  'complete': [
    'completed'
    ]
  ,
  'agreement': [
    'negotiation',
  ]
};
