import {State} from "../../_storage/state";
import {IntervalFilter} from "./interval-filter";
import {RelativeTimeFilter} from "./relative-time-filter";
import {FilterType, FilterTypeUtils} from "../../_utils/online-filter/filter-type";

export class UsersFilter {
  constructor(
    public accountType = "",
    public email = "",
    public phone = "",
    public surname = "",
    public active = "",
    public balance= new IntervalFilter(),
    public deviceType = "",
    public ordersCount = new IntervalFilter(),
    public company = "",
    public lastOrderDate = new RelativeTimeFilter(),
    public employerStatus = "",
    public registeredAfter?: Date,
    public registeredBefore?: Date,
    public taxiTariff?: number,
    public online: FilterType = FilterType.NONE
  ) {}

  getState(): State {
    return new State(JSON.stringify({
      accountType: this.accountType,
      email: this.email,
      phone: this.phone,
      surname: this.surname,
      active: this.active,
      balance: this.balance.forState(),
      deviceType: this.deviceType,
      ordersCount: this.ordersCount.forState(),
      company: this.company,
      lastOrderDate: this.lastOrderDate.forState(),
      employerStatus: this.employerStatus,
      taxiTariff: this.taxiTariff,
      online: FilterTypeUtils.serialize(this.online),
      registeredAfter: this.registeredAfter ? this.registeredAfter.toString() : null,
      registeredBefore: this.registeredBefore ? this.registeredBefore.toString() : null,
    }));
  }

  equals(filter: UsersFilter): boolean {
    return this.accountType === filter.accountType
      && this.email === filter.email
      && this.phone === filter.phone
      && this.surname === filter.surname
      && this.active === filter.active
      && this.balance.equals(filter.balance)
      && this.deviceType === filter.deviceType
      && this.ordersCount.equals(filter.ordersCount)
      && this.company === filter.company
      && this.lastOrderDate.equals(filter.lastOrderDate)
      && this.employerStatus === filter.employerStatus
      && this.taxiTariff == filter.taxiTariff
      && this.online === filter.online
      && ((this.registeredAfter != null && filter.registeredAfter != null && this.registeredAfter.getTime() == filter.registeredAfter.getTime()) || this.registeredAfter == null && filter.registeredAfter == null)
      && ((this.registeredBefore != null && filter.registeredBefore != null && this.registeredBefore.getTime() == filter.registeredBefore.getTime()) || this.registeredBefore == null && filter.registeredBefore == null)
    ;
  }

  isEmpty(): boolean {
    return this.equals(new UsersFilter());
  }

  clone(): UsersFilter {
    return new UsersFilter(
      this.accountType,
      this.email,
      this.phone,
      this.surname,
      this.active,
      this.balance.clone(),
      this.deviceType,
      this.ordersCount.clone(),
      this.company,
      this.lastOrderDate.clone(),
      this.employerStatus,
      this.registeredAfter ? new Date(this.registeredAfter.toString()) : null,
      this.registeredBefore ? new Date(this.registeredBefore.toString()) : null,
      this.taxiTariff,
      this.online
    );
  }

  static fromState(state: State): UsersFilter {
    let data = JSON.parse(state.state);

    let accountType = data.accountType || "";
    let email = data.email || "";
    let phone = data.phone || "";
    let surname = data.surname || "";
    let active = data.active || "";
    let balance = data.balance && IntervalFilter.fromState(data.balance) || new IntervalFilter();
    let deviceType = data.deviceType || "";
    let ordersCount = data.ordersCount && IntervalFilter.fromState(data.ordersCount) || new IntervalFilter();
    let company = data.company || "";
    let lastOrderDate = data.lastOrderDate && RelativeTimeFilter.fromState(data.lastOrderDate) || new RelativeTimeFilter();
    let employerStatus = data.employerStatus || "";
    let taxiTariff = data.taxiTariff || null;
    let online = data.online ? FilterTypeUtils.deserialize(data.online) : FilterType.NONE;
    let registeredAfter = data.registeredAfter ? new Date(data.registeredAfter) : null;
    let registeredBefore = data.registeredBefore ? new Date(data.registeredBefore) : null;

    return new UsersFilter(
      accountType,
      email,
      phone,
      surname,
      active,
      balance,
      deviceType,
      ordersCount,
      company,
      lastOrderDate,
      employerStatus,
      registeredAfter,
      registeredBefore,
      taxiTariff,
      online
    );
  }
}
