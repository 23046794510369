import {Pipe, PipeTransform} from "@angular/core";
import {format, plural} from "ru-plurals";

@Pipe({name: 'days'})
export class DaysPipe implements PipeTransform {
  private transformer = format((count, word) => `${count} ${word}`, 'день', 'дня', 'дней');

  transform(days: number): string {
    return this.transformer(days);
  }

}
