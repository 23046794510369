import {EventEmitter} from '@angular/core'
import {noticeTypeInterface} from '../notice.type.interface'
import {noticeInterface} from '../notice.interface'
import {NoticeComponent} from '../notice.component'
import {noticeButton, acceptOrderButton, callButton} from "../button.type";
import {Order} from "../../_models/order";
import {OrderDraft} from "../../_models/order-draft";
import {Account} from "../../_models/account";

class taxiType implements noticeTypeInterface {
  name: string = 'taxi';
}
export class taxiNotice implements noticeInterface {
  type: taxiType = new taxiType();
  dateCreated: Date;
  bodyText: string;
  bodyClass?: string;
  id?: number;
  title?: string;
  buttons?: noticeButton[];
  orderId: number;
  freighterId: number;
  order?: Order;
  draft?: OrderDraft;
  ajax: boolean = false;
  ajaxDone: boolean = false;
  statusError: boolean = false;
  noAnswer: boolean = false;
  noticeClass?: string;
  autoHide = false;
  remove = false;
  important = false;
  phone?: string;
  account?: Account;

  constructor() {
    this.buttons = [];
    console.log('Notice type ->>>',this.type)
  }

  addButton(text: string, type: string, link: any) {
    var button = new noticeButton(text, type, link);
    console.log(button);
    this.buttons.push(button);
  }

  addAcceptButton(text: string) {
    var button = new acceptOrderButton(text, this.orderId);
    this.buttons.push(button);
  }

  addCallButton(text: string, phone: string) {
    var button = new callButton(text, phone);
    this.buttons.push(button);
  }

  addOrder(order: Order) {
    this.order = order;
    this.orderId = order.id;
    this.freighterId = order.freighter.id;
  }

  addDraft(draft: OrderDraft) {
    this.draft = draft;
  }

  setInfoClass() {
    this.noticeClass = 'info';
  }

  setAlertClass() {
    this.noticeClass = 'alert';
    this.important = true;
  }

  setAttentionClass() {
    this.noticeClass = 'attention';
    this.important = true;
  }
}
