import {LegalEntity} from "../_models/legal-entity";

export class LegalEntityUtils {
  static legalEntityData(entity: LegalEntity): any {
    return {
      name: entity.name,
      shortName: entity.short_name,
      type: entity.type,
      inn: entity.inn,
      kpp: entity.kpp,
      ogrn: entity.ogrn,
      oktmo: entity.oktmo,
      okpo: entity.okpo,
      bik: entity.bik,
      bank: entity.bank,
      bankInn: entity.bank_inn,
      correspondentAccount: entity.correspondent_account,
      checkingAccount: entity.checking_account,
      actualAddress: entity.actual_address,
      legalAddress: entity.legal_address,
      signatoryAuthority: entity.signatory_authority,
      director: entity.director
    };
  }
}
