import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Account} from "../_models/account";
import {UserNameUtils} from "../_utils/user-name-utils";
import {Editable} from "../_models/editable";
import {FormErrors} from "../_models/form-errors";

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit, OnChanges {
  @Input() account: Editable<Account>;
  @Input() phoneEnabled: boolean;
  @Input() role: string;
  @Input() roleEnabled: boolean = false;
  @Input() forceRoleSave = false;
  @Output() onSaveAccount = new EventEmitter<Editable<Account>>();
  @Output() onSaveRole = new EventEmitter<string>();
  @Output() onAllSaved = new EventEmitter<void>();

  userName: string;
  private prevRole: string;

  roles = {
    ROLE_PARTNER_MANAGER: ['Менеджер компании', 'Управление пользователями и заказами'],
    ROLE_DELIVERY_MANAGER: ['Менеджер доставки', 'Управление только заказами']
  };

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyUserName();
    this.prevRole = this.role;
  }

  private applyUserName(): void {
    this.userName = UserNameUtils.SNPToName(this.account.record);
  }

  private parseUserName(): void {
    UserNameUtils.nameToSNP(this.userName, this.account.record);
  }

  private save(): void {
    this.parseUserName();

    this.onSaveAccount.emit(this.account);

    if(this.forceRoleSave || this.isRoleChanged())
      this.onSaveRole.emit(this.role);

    this.onAllSaved.emit();
  }

  private isRoleChanged(): boolean {
    return this.roleEnabled && this.role !== this.prevRole;
  }

  onSave(): void {
    this.save();
  }
}
