
import {debounceTime, finalize} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {OptimizationTaskService} from "../_services/optimization-task.service";
import {LoaderService} from "../_services/loader.service";
import {OptimizationTask} from "../_models/optimization-task";
import {Point} from "../_models/point";
import {OptimizationTaskTransport} from "../_models/optimization-task-transport";
import {GeoService} from "../_services/geo.service";
import {AlertService} from "../_services/alert.service";
import {OptimizationTaskTransportDestination} from "../_models/optimization-task-transport-destination";
import {MarkersCollection} from "./markers/markers-collection";
import {TransportSearchState} from "./search-state/transport-search-state";
import {OrderDraft} from "../_models/order-draft";
import {SearchStat} from "./search-state/search-stat";
import {DELIVERY_STATUS_GROUPS} from "../_maps/delivery-statuses";
import {TitleService} from "../_services/title.service";
import {Employer} from "../_models/employer";
import {FreightersFastListDialogComponent} from "../freighters-fast-list-dialog/freighters-fast-list-dialog.component";
import {OptimizationTaskDestination} from "../_models/optimization-task-destination";
import {List} from "../pager/list";
import {Page} from "../pager/page";
import {
  ComplexDeliveryDestinationsSelectorDialogComponent
} from "../complex-delivery-destinations-selector-dialog/complex-delivery-destinations-selector-dialog.component";
import {DeliverySchema} from "../_models/delivery-schema";
import {DeliveryService} from "../_services/delivery.service";
import {UserInfoService} from "../_services/user-info.service";
import {TariffService} from "../_services/tariff.service";
import {TariffTier} from "../_models/tariff-tier";
import {Clusterer} from "./markers/clusterer";
import {BasicClusterer} from "./markers/basic-clusterer";
import {Subject, Subscription} from "rxjs";
import {DestinationPoint} from "../_models/destination-point";
import {Route} from "../_models/map/route";
import {DisplayedMarker} from "../_models/map/displayed.marker";
import {DisplayedClusterArea} from "../_models/map/displayed-cluster-area";
import {MapService} from "../_services/map.service";
import {DateTime} from "date-time-js";
import {City} from "../_models/city";
import {DateUtils} from "../_utils/date-utils";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {OzonImportDialogComponent} from "../ozon-import-dialog/ozon-import-dialog.component";
import {Order} from "../_models/order";

const AUTO_RELOAD_WAIT = 30;
const DESTINATIONS_PAGE_SIZE = 20;
/**
 * Минимальное число точек, необходимое для активации кластеризации
 */
const MIN_DESTINATIONS_TO_CLUSTERIZATION = 150;
const DATE_REGEXP = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
const TIME_REGEXP = /^([0-9]{2}):([0-9]{2})(?::([0-9]{2}))?$/;

enum DestinationsDialogMode {
  Add,
  Remove
}

class DestinationsList implements List {
  constructor(
    private _destinations: OptimizationTaskDestination[] = [],
    public page: number = 0,
    public totalCount: number = 0,
    public pageSize: number = 0
  ) {
  }

  get destinations(): OptimizationTaskDestination[] {
    return this._destinations;
  }
}

class DraftCalculation {
  constructor(public calculation: any|null|undefined, public isOpened = false) {
  }
}

class TransportArrivalData {
  date = '';
  time = '';
  timeStart = '';
  timeEnd = '';
  edit = false;
  isNew = false;

  applyDate(date: Date): void {
    let d = new DateTime(date);
    this.date = d.format('yyyy-MM-dd');
    this.time = d.format('HH:mm:ss');
  }

  applyDateInterval(start: Date, end: Date): void {
    let startDate = new DateTime(start);
    let endDate = new DateTime(end);

    this.date = startDate.format('yyyy-MM-dd');
    this.timeStart = startDate.format('HH:mm:ss');
    this.timeEnd = endDate.format('HH:mm:ss');
  }

  compileDate(): Date|null {
    return TransportArrivalData.rawCompileDate(this.date, this.time);
  }

  compileInterval(): [Date|null, Date|null] {
    let startDate = TransportArrivalData.rawCompileDate(this.date, this.timeStart);
    let endDate = TransportArrivalData.rawCompileDate(this.date, this.timeEnd);

    if(!startDate || !endDate)
        return [ null, null ];

    if(endDate.getTime() < startDate.getTime())
      endDate = new DateTime(endDate).add('1', 'day').toDate();

    return [ startDate, endDate ];
  }

  private static rawCompileDate(date: string, time: string): Date|null {
    let timeMatches = TIME_REGEXP.exec(time);
    if(!timeMatches)
      return null;

    let hours = timeMatches[1];
    let minutes = timeMatches[2];
    let seconds = '00';

    return (DATE_REGEXP.test(date))
      ? new Date(`${date}T${hours}:${minutes}:${seconds}`)
      : null;
  }

  static createFromTransport(transport: OptimizationTaskTransport, city: City): TransportArrivalData {
    let d = new TransportArrivalData();
    if(transport.fixed_arrival_to_stock) {
      d.applyDate(DateUtils.offsetTimeByCityTimeZone(new Date(transport.fixed_arrival_to_stock), city));
    }
    if(transport.arrival_slot_start && transport.arrival_slot_end) {
      d.applyDateInterval(
        DateUtils.offsetTimeByCityTimeZone(new Date(transport.arrival_slot_start), city),
        DateUtils.offsetTimeByCityTimeZone(new Date(transport.arrival_slot_end), city)
      );
    }
    return d;
  }
}


@Component({
  selector: 'app-complex-delivery-view',
  templateUrl: './complex-delivery-view.component.html',
  styleUrls: ['./complex-delivery-view.component.css']
})
export class ComplexDeliveryViewComponent implements OnInit, OnDestroy {
  @ViewChild(FreightersFastListDialogComponent, { static: true }) freighterListDialog: FreightersFastListDialogComponent;
  @ViewChild(ComplexDeliveryDestinationsSelectorDialogComponent, { static: true }) destinationsDialog: ComplexDeliveryDestinationsSelectorDialogComponent
  @ViewChild(OzonImportDialogComponent) ozonImportDialog: OzonImportDialogComponent;

  task: OptimizationTask;
  taskForEdit: OptimizationTask;
  destinationForEdit: number;
  activeTransportMode = false;
  loadedDataVisible = false;
  updatingType = false;
  updatingConnectingOfNotOptimizedPoints = false;
  showDriverEarn = false;
  isExecutionEnabled = false;
  isExecutionVisible = false;
  isTransportsEditable = false;
  isMultiPoint = false;
  ozonEnabled = false;

  lat: number = 55.753040;
  lng: number = 37.622002;
  zoom: number = 11;

  private markers = new MarkersCollection();
  visibleMarkers = new MarkersCollection();
  displayedMarkers: DisplayedMarker[] = [];
  displayedClusterAreas: DisplayedClusterArea[] = [];

  private availableColors = [
    '#00A85E',
    '#F279AE',
    '#333200',
    '#99ADFF',
    '#992255',
    '#FFA099',
    '#ff0f23',
    '#0d0eff',
  ];

  private curColorIndex = 0;

  private prevLoadHasTransports = false;

  private reloadTimer;
  private timerTimer;
  private timerSeconds: number;
  private timerMinutes: number;
  private timerTick = false;
  timerString = '0:00';

  private activeTransport: OptimizationTaskTransport;
  private freeDestinations: OptimizationTaskDestination[];

  colors: string[] = [];

  routes: Route[] = [];

  private transportSearchStates = new Map<number, TransportSearchState>();
  searchStat = new SearchStat();
  singlePathsCount = 0;
  weakPointsCount = 0;

  transportsSelection = new Map<number, boolean>();

  deliveryStatusGroups = DELIVERY_STATUS_GROUPS;

  private rollFlags = new Map<number, boolean>();
  rolledUpAll = false;

  showInSearch = true;
  showInExecuting = true;
  showCompleted = true;
  showStopped = true;

  transportArrivals = new Map<number, TransportArrivalData>();

  activeTransportCostEditors = new Map<number, boolean>();

  destinationsList: DestinationsList = new DestinationsList();
  destinationsDialogMode: DestinationsDialogMode;

  existFreeDestinations = false;

  deliverySchemas: DeliverySchema[] = [];
  deliverySchemaId: string;

  isAllowedRouteOptimization = false;
  isAllowedCustomRoutes = false;

  tiers: TariffTier[] = [];
  transportWithActiveTierSelector: number;
  selectedTierId: string;

  transportDraftCalculations = new Map<number, DraftCalculation>();

  private searchStateTimer;

  private currentBounds: google.maps.LatLngBounds;

  private clusterer: Clusterer = new BasicClusterer();

  private mapBoundsChangeStream = new Subject<google.maps.LatLngBounds>();

  private stockPrevPoint: DestinationPoint|null = null;

  private mapBoundsChangeSubscription: Subscription;
  private markerClicksSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private optimizationTaskService: OptimizationTaskService,
    private deliveryService: DeliveryService,
    private geoService: GeoService,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private titleService: TitleService,
    private tariffService: TariffService,
    public userInfoService: UserInfoService,
    private mapService: MapService,
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        params => this.loadTask(+params['id'])
      );

    this.showDriverEarn = location.search && location.search.indexOf('driverEarn') != -1;

    this.isAllowedRouteOptimization = this.userInfoService.isAvailableRouteOptimization();
    this.isAllowedCustomRoutes = this.userInfoService.isAvailableCustomRoutes();

    // this.initDeliverySchemas();
    this.initTariffTiers();
    this.initRefreshMapBoundsStream();

    this.mapBoundsChangeSubscription = this.mapService.getMapBoundsChangeObservable().subscribe(
      bounds => this.onBoundsChange(bounds)
    );
    this.markerClicksSubscription = this.mapService.getDisplayedMarkersClicksObservable().subscribe(
      id => this.onClickDestination(id)
    );
  }

  private initOzon(): void {
    let company = this.userInfoService.userInfo.account.company_client;
    let trackingServices = company && company.tracking_services || [];

    this.ozonEnabled = trackingServices.indexOf('ozon') >= 0;
  }

  private initMap(): void {
    this.mapService.showMap();
    this.mapService.clean();
    this.initMapLocation();
    this.initOzon();
  }

  private loadTask(id: number, primaryData = false) {
    this.titleService.changeTitle(`#${id} - Маршрутизация`);

    this.stopAutoReload();
    this.stopSearchStateAutoReload();
    this.loaderService.show();
    this.optimizationTaskService
      .getTask(id, 'view', primaryData)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe(
        task => {
          this.task = task;
          this.deliverySchemaId = (task.delivery_schema && task.delivery_schema.id) + '';
          this.initDestinationsList();
          this.initLoadedData();
          this.initMap();
          this.initMarkers();
          this.initTransportSearchStates();
          this.initTransportsSelection();
          this.initTransportArrivals();
          this.initControls();
          this.initFreeDestinations();
          this.initTransportDraftCalculations();
          this.initMultipoint();
          // this.initTransportRolls();
          this.loadRoutes();
          this.setupAutoReload();
        },
        () => {}
      );
  }

  private initDeliverySchemas(): void {
    this.loaderService.show();
    this.deliveryService
      .getSchemas().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        s => this.deliverySchemas = s,
        () => {}
      )
    ;
  }

  private initMultipoint(): void {
    for(const transport of this.task.transports) {
      if(transport.destinations.length > 2) {
        this.isMultiPoint = true;
        return;
      }
    }
    this.isMultiPoint = false;
  }

  private initTariffTiers(): void {
    this.loaderService.show();
    this.tariffService
      .getTaxiTiers(false).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        tiers => this.tiers = tiers,
        () => {}
      )
    ;
  }

  private initDestinationsList() {
    if(this.task.destinations.length > 120) {
      this.destinationsList = new DestinationsList(
        this.task.destinations.slice(0, DESTINATIONS_PAGE_SIZE - 1),
        0,
        this.task.destinations.length,
        DESTINATIONS_PAGE_SIZE
      );
    } else {
      this.destinationsList = new DestinationsList(
        this.task.destinations,
        0,
        this.task.destinations.length,
        this.task.destinations.length
      );
    }
    this.renumberDestinations();
  }

  private renumberDestinations(): void {
    for(let i = 0; i < this.task.destinations.length; i++) {
        this.task.destinations[i].listPos = i + 1;
    }
  }

  private initMapLocation(): void {
    if(this.task.destinations.length > 0) {
      let stock = this.task.destinations[0];
      let stockDestination = stock.destination;
      if(!this.stockPrevPoint || stockDestination.lat != this.stockPrevPoint.lat || stockDestination.lon != this.stockPrevPoint.lon) {
        this.lat = stockDestination.lat;
        this.lng = stockDestination.lon;
        this.stockPrevPoint = stockDestination;
        this.mapService.setMapPosition(this.lat, this.lng, this.zoom);
      }
    }
  }

  private initMarkers(): void {
    this.markers.clear();

    this.initMarkersByDestination();
    if(this.task.transports)
      this.initMarkersByTransportDestinations();

    if(this.destinationForEdit)
      this.markers.select(this.destinationForEdit);

    this.refreshVisibleMarkers();
  }

  private initRefreshMapBoundsStream(): void {
    this.mapBoundsChangeStream = new Subject<google.maps.LatLngBounds>();
    this.mapBoundsChangeStream.pipe(
      debounceTime(250))
      .subscribe(
        bounds => {
          this.currentBounds = bounds;
          this.refreshVisibleMarkers()
        },
        () => this.initRefreshMapBoundsStream()
      );
  }

  private refreshVisibleMarkers(): void {
    let visibleMarkers = this.markers.getCopyWithVisibleMarkers();

    if(this.markers.markers.size > MIN_DESTINATIONS_TO_CLUSTERIZATION) {
      if(!this.activeTransportMode) {
        if(this.currentBounds)
          visibleMarkers = this.clusterer.doClusterization(visibleMarkers, this.currentBounds);
        else
          visibleMarkers.clear();
      }

      visibleMarkers = visibleMarkers.getCopyWithVisibleMarkers(this.currentBounds, 2);
    }

    this.visibleMarkers = visibleMarkers;

    this.refreshDisplayedMarkers();
  }

  private refreshDisplayedMarkers(): void {
    let removed = 0, refreshed = 0, added = 0;

    let refreshedMarkers = new Map<number, boolean>();

    for(let i = 0; i < this.displayedMarkers.length; i ++) {
      let displayedMarker: DisplayedMarker|null = this.displayedMarkers[i];

      while(displayedMarker && !this.visibleMarkers.markers.has(displayedMarker.id)) {
        this.displayedMarkers.splice(i, 1);
        removed ++;
        displayedMarker = i < this.displayedMarkers.length ? this.displayedMarkers[i] : null;
      }

      if(displayedMarker == null)
        break;

      this.visibleMarkers.markers.get(displayedMarker.id).copyTo(displayedMarker.marker);
      refreshedMarkers.set(displayedMarker.id, true);
      refreshed ++;
    }

    this.visibleMarkers.forEach((marker, id) => {
      if(!refreshedMarkers.has(id)) {
        this.displayedMarkers.push(new DisplayedMarker(id, marker));
        added ++;
      }
    });

    console.log(`Display markers stat. Removed: ${removed}. Refreshed: ${refreshed}. Added: ${added}`);

    let areaNum = 0;
    for(const displayedMarker of this.displayedMarkers) {
      let marker = displayedMarker.marker;
      if(marker.isCluster) {
        if(areaNum < this.displayedClusterAreas.length) {
          let area = this.displayedClusterAreas[areaNum];
          area.lat = marker.lat;
          area.lon = marker.lon;
          area.radius = marker.clusterRadius;
        } else {
          this.displayedClusterAreas[areaNum] = new DisplayedClusterArea(marker.lat, marker.lon, marker.clusterRadius);
        }
        areaNum ++;
      }
    }

    if(areaNum < this.displayedClusterAreas.length)
        this.displayedClusterAreas.splice(areaNum);

    this.mapService.displayMarkers(this.displayedMarkers);
    this.mapService.displayClusterAreas(this.displayedClusterAreas);
  }

  private initMarkersByDestination() {
    for(let i in this.task.destinations) {
      let marker = this.markers.addMarkerFromDestination(this.task.destinations[i]);
      marker.label = (1 + parseInt(i)).toString();
    }
  }

  private initMarkersByTransportDestinations() {
    for(let i in this.task.transports) {
      for(let destination of this.task.transports[i].destinations) {
        let marker = this.markers.addMarkerFromTransportDestination(destination);
        marker.label = destination.destination.stock ? "" : (1 + parseInt(i)).toString();
      }
    }
  }

  private initLoadedData() {
    if(!this.loadedDataVisible || (!this.prevLoadHasTransports && this.task.transports && this.task.transports.length > 0))
      this.loadedDataVisible = !this.task.transports || this.task.transports.length == 0;

    this.prevLoadHasTransports = this.task.transports && this.task.transports.length > 0;

    this.singlePathsCount = 0;
    this.weakPointsCount = 0;
    if(this.task.transports) {
      for(let transport of this.task.transports) {
        if(transport.destinations.length <= 2)
          this.singlePathsCount ++;

        if(transport.used_weak_path)
          this.weakPointsCount += transport.destinations.length - 1;
      }
    }
  }

  private initTransportSearchStates() {
    this.transportSearchStates = new Map<number, TransportSearchState>();
    if(!this.task.transports || this.task.status != 'executed')
      return;

    for(let transport of this.task.transports) {
      let transportState = new TransportSearchState();
      transportState.transport = transport;
      this.transportSearchStates.set(transport.id, transportState);
    }

    this.loadDrafts();
    this.loadOrders();
  }

  private initTransportsSelection(): void {
    this.transportsSelection.clear();
    for (const transport of this.task.transports) {
      this.transportsSelection.set(transport.id, true);
    }
  }

  private initTransportArrivals(): void {
    this.transportArrivals.clear();
    for (const transport of this.task.transports) {
      if(transport.fixed_arrival_to_stock || transport.arrival_slot_start && transport.arrival_slot_end)
        this.transportArrivals.set(transport.id, TransportArrivalData.createFromTransport(transport, this.task.city));
    }
  }

  private initControls(): void {
    this.syncExecutionControls();
    this.syncTransportControls();
  }

  private syncTransportControls(): void {
    this.isTransportsEditable = this.isAllowedCustomRoutes && !this.task.imported_from;
  }

  private syncExecutionControls(): void {
    if([ 'success', 'executed' ].indexOf(this.task.status) >= -1) {
      this.isExecutionVisible = false;
      for(const transport of this.task.transports) {
        let isTransportSelectable = this.isTransportSelectable(transport);
        if(isTransportSelectable)
          this.isExecutionVisible = true;

        if(this.transportsSelection.get(transport.id) && this.isVisible(transport) && isTransportSelectable) {
          this.isExecutionEnabled = true;
          return;
        }
      }
    } else {
      this.isExecutionVisible = true;
    }
    this.isExecutionEnabled = false;
  }

  private initTransportRolls(): void {
    this.rollFlags.clear();
    for (const transport of this.task.transports) {
      this.rollFlags.set(transport.id, false);
    }
    this.rolledUpAll = false;
  }

  private initFreeDestinations(): void {
    let transportDestinationIds: number[] = [];
    for(const transport of this.task.transports) {
      for(const transportDestination of transport.destinations) {
        if(!transportDestination.destination.stock)
          transportDestinationIds.push(transportDestination.destination.id);
      }
    }

    this.freeDestinations = this.task.destinations.filter(d => !d.stock && transportDestinationIds.indexOf(d.id) == -1);
    this.existFreeDestinations = this.freeDestinations.length > 0;
  }

  private initTransportDraftCalculations(): void {
    let oldCalculations = this.transportDraftCalculations;
    this.transportDraftCalculations = new Map<number, DraftCalculation>();
    for(const transport of this.task.transports) {
      this.transportDraftCalculations.set(
        transport.id,
        new DraftCalculation(
          transport.draft_calculation,
          oldCalculations.has(transport.id) && oldCalculations.get(transport.id).isOpened
        )
      );
    }
  }

  private loadRoutes() {
    this.curColorIndex = 0;
    this.routes = [];
    this.colors = [];
    this.task.transports.forEach(transport => this.loadRoute(transport));
    this.mapService.displayRoutes(this.routes);
  }

  private loadRoute(transport: OptimizationTaskTransport) {
    let route = new Route();
    route.color = this.availableColors[this.curColorIndex ++];
    this.routes.push(route);

    if(this.curColorIndex >= this.availableColors.length)
      this.curColorIndex = 0;

    let points = transport.destinations.map(d => {
      let point = new Point();

      let p = d.destination.destination;
      point.lat = p.lat;
      point.lon = p.lon;

      return point;
    });

    this.geoService.getRoute(points, 7).subscribe(points => route.points = points);
  }

  private loadDrafts(): void {
    this.loaderService.show();
    this.optimizationTaskService
      .getTaskDrafts(this.task.id).pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: drafts => {
          this.applyDraftsToStates(drafts);
          this.updateSearchStat();
          this.startSearchStateAutoReload();
          this.syncExecutionControls();
        },
        error: () => {

        }
      })
    ;
  }

  private loadOrders(): void {
    this.loaderService.show();
    this.optimizationTaskService
      .getTaskOrders(this.task.id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe({
        next: orders => {
          this.applyOrdersToStates(orders);
        },
        error: () => {

        }
      });
  }

  private loadTaskForDeliveryStatusUpdate(): void {
    this.loaderService.show();
    this.optimizationTaskService
      .getTask(this.task.id).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        task => this.updateDeliveries(task),
        () => {}
      );
  }

  private updateDeliveries(task: OptimizationTask): void {
    let delivered = new Map<number, boolean>();
    let undelivered = new Map<number, boolean>();
    for(let destination of task.destinations) {
      if(destination.delivered)
        delivered.set(destination.id, true);
      if(destination.undelivered)
        undelivered.set(destination.id, true);
    }

    for(let destination of this.task.destinations) {
      if(delivered.has(destination.id))
        destination.delivered = true;
      if(undelivered.has(destination.id))
        destination.undelivered = true;
    }

    for(let transport of this.task.transports) {
      for(let destination of transport.destinations) {
        if(delivered.has(destination.destination.id))
          destination.destination.delivered = true;
        if(undelivered.has(destination.destination.id))
          destination.destination.undelivered = true;
      }
    }
  }

  private editTask() {
    this.loaderService.show();
    this.optimizationTaskService
      .getTask(this.task.id, 'edit').pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        task => {
          this.taskForEdit = task;
          $('#edit-complex-delivery').modal('show');
        },
        () => {}
      );
  }

  private calc(strategy: string) {
    this.loaderService.show();
    this.optimizationTaskService
      .optimizeTask(this.task, strategy).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => this.loadTask(this.task.id)
      )
  }

  private execute() {
    this.loaderService.show();
    let startOnly = [];

    for(const transport of this.task.transports) {
      if(this.isTransportSelected(transport) && this.isVisible(transport) && this.isTransportSelectable(transport))
        startOnly.push(transport.id);
    }

    this.optimizationTaskService
      .executeTask(this.task, ...startOnly).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.alertService.success('Поиск запущен');
          this.loadTask(this.task.id);
        },
        () => {}
      )
  }

  private applyDraftsToStates(drafts: OrderDraft[]): void {
    for(let draft of drafts) {
      if(!draft.optimized_transport)
        continue;

      let transportId = draft.optimized_transport.id;
      if(this.transportSearchStates.has(transportId))
        this.transportSearchStates.get(transportId).draft = draft;
    }
  }

  private applyOrdersToStates(orders: Order[]): void {
    for(let order of orders) {
      if(!order.draft.optimized_transport)
        continue;

      let transportId = order.draft.optimized_transport.id;
      if(this.transportSearchStates.has(transportId))
        this.transportSearchStates.get(transportId).order = order;
    }
  }

  private updateSearchStat(): void {
    this.searchStat.completed = 0;
    this.searchStat.foundTransports = 0;
    this.searchStat.transportsCount = 0;
    this.searchStat.cost = 0;

    this.transportSearchStates.forEach(state => {
      if(!state.draft)
        return;

      this.searchStat.transportsCount ++;
      this.searchStat.cost += state.transport.cost;

      if(DELIVERY_STATUS_GROUPS.execution.indexOf(state.draft.delivery_status) != -1) {
        this.searchStat.foundTransports ++;
      } else if(DELIVERY_STATUS_GROUPS.completed.indexOf(state.draft.delivery_status) != -1) {
        this.searchStat.foundTransports ++;
        this.searchStat.completed ++;
      }
    });
  }

  private updateOptimizationType(type: string) {
    this.loaderService.show();
    this.updatingType = true;
    this.optimizationTaskService
      .updateOptimizationType(this.task, type).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.updatingType = false;
      }))
      .subscribe(
        () => this.onReload(),
        () => {}
      )
  }

  private updateConnectingOfNotOptimizedPoints(value: boolean) {
    this.loaderService.show();
    this.updatingConnectingOfNotOptimizedPoints = true;
    this.optimizationTaskService
      .updateConnectingOfNotOptimizedPoints(this.task, value).pipe(
      finalize(() => {
        this.loaderService.hide();
        this.updatingConnectingOfNotOptimizedPoints = false;
      }))
      .subscribe(
        () => this.onReload(),
        () => {}
      )
  }

  private updateLockForExternal(value: boolean) {
    this.loaderService.show();
    this.optimizationTaskService
      .updateLockForExternal(this.task, value).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.onReload(),
        () => {}
      )
  }

  private updateTransportCost(transport: OptimizationTaskTransport) {
    this.loaderService.show();
    this.optimizationTaskService
      .updateTransportCost(this.task, transport).pipe(
      finalize(() => {
        this.loaderService.hide();
      }))
      .subscribe(
        () => this.onReload(),
        () => {}
      )
    ;
  }

  private updateTransportEmployer(transport: OptimizationTaskTransport) {
    this.loaderService.show();
    this.optimizationTaskService
      .updateTransportEmployer(this.task, transport).pipe(
      finalize(() => {
        this.loaderService.hide();
      }))
      .subscribe(
        () => this.onReload(),
        () => {}
      )
    ;
  }

  private setupAutoReload() {
    if(this.checkForAutoReload())
      this.startAutoReload();
  }

  private checkForAutoReload() {
    return this.task.status == 'wait_calc' || this.task.status == 'calculating';
  }

  private startAutoReload() {
    this.stopAutoReload();

    this.reloadTimer = setTimeout(() => this.onReloadTime(), AUTO_RELOAD_WAIT * 1000);
    this.timerTimer = setInterval(() => this.onTimerTick(), 500);

    this.timerMinutes = Math.floor(AUTO_RELOAD_WAIT / 60);
    this.timerSeconds = AUTO_RELOAD_WAIT - this.timerMinutes * 60;
    this.timerTick = true;
  }

  private stopAutoReload() {
    if(this.reloadTimer != null) {
      clearTimeout(this.reloadTimer);
      this.reloadTimer = null;
    }

    if(this.timerTimer != null) {
      clearInterval(this.timerTimer);
      this.timerTimer = null;
    }
  }

  calcTransportDistance(transport: OptimizationTaskTransport): number {
    return transport.destinations.reduce((sum, destination: OptimizationTaskTransportDestination) => {
      return sum + (destination && destination.driving_data && destination.driving_data['sum_distance'] || 0)
    }, 0);
  }

  isStock(destinationId: number): boolean {
    for(let destination of this.task.destinations) {
      if (destination.id === destinationId) {
        return destination.stock;
      }
    }
    return false;
  }

  distanceForView(distance: number): number {
    return Math.round(distance * 100) / 100;
  }

  private focusMapOnDestination(destinationId: number): void {
    for(let destination of this.task.destinations) {
      if(destination.id === destinationId) {
        this.lat = destination.destination.lat;
        this.lng = destination.destination.lon;
        this.zoom = 15;
        this.mapService.setMapPosition(this.lat, this.lng, this.zoom);
      }
    }
  }

  private focusOnCluster(destinationId: number): void {
    let marker = this.visibleMarkers.getMarker(destinationId);

    this.lat = marker.lat;
    this.lng = marker.lon;
    this.zoom ++;

    this.mapService.setMapPosition(this.lat, this.lng, this.zoom);
  }

  private startSearchStateAutoReload(): void {
    this.stopSearchStateAutoReload();

    if(!this.checkForAutoReloadSearchState())
      return;

    this.searchStateTimer = setTimeout(() => {
      this.loadDrafts();
      this.loadOrders();
      this.loadTaskForDeliveryStatusUpdate();
    }, AUTO_RELOAD_WAIT * 1000)
  }

  private checkForAutoReloadSearchState(): boolean {
    return this.searchStat.completed != this.task.transports.length;
  }

  private stopSearchStateAutoReload(): void {
    if(this.searchStateTimer)
      clearTimeout(this.searchStateTimer);

    this.searchStateTimer = null;
  }

  isTransportRolledUp(transport: OptimizationTaskTransport): boolean {
    return this.rollFlags.get(transport.id);
  }

  rollTransport(transport: OptimizationTaskTransport, up: boolean): void {
    this.rollFlags.set(transport.id, up);
  }

  rollAllTransports(up: boolean): void {
    for (let transport of this.task.transports) {
      this.rollTransport(transport, up);
    }
    this.rolledUpAll = up;
  }

  /**
   * Проверка наличия разности в стоимости доставки между исходной и финальной
   *
   * @param transportDestination
   */
  hasDeliveryCostDiff(transportDestination: OptimizationTaskTransportDestination): boolean {
    if(transportDestination['calculation'] == null || transportDestination.calculation['assemblyCost'] == null)
      return false;

    return transportDestination.delivery_cost
      - (transportDestination.destination.delivery_cost + transportDestination.calculation['assemblyCost'] + (transportDestination.calculation['liftingCost'] || 0)) != 0;
  }

  isTransportSelected(transport: OptimizationTaskTransport): boolean {
    return this.transportsSelection.get(transport.id);
  }

  isTransportSelectable(transport: OptimizationTaskTransport): boolean {
    let state = this.getSearchStateByTransport(transport);
    return !state || !state.draft;
  }

  isVisible(transport: OptimizationTaskTransport): boolean {
    if(this.task.status != 'executed')
      return true;

    let state = this.transportSearchStates.get(transport.id);
    if(!state || !state.draft)
      return this.showInSearch && this.showInExecuting && this.showCompleted && this.showStopped;

    if (this.showInSearch) {
      if(this.deliveryStatusGroups.search.indexOf(state.draft.delivery_status) != -1)
        return true;
    }
    if (this.showInExecuting) {
      if(this.deliveryStatusGroups.execution.indexOf(state.draft.delivery_status) != -1)
        return true;
    }
    if (this.showCompleted) {
      if(this.deliveryStatusGroups.completed.indexOf(state.draft.delivery_status) != -1)
        return true;
    }
    if (this.showStopped) {
      if(this.deliveryStatusGroups.stopped.indexOf(state.draft.delivery_status) != -1)
        return true;
    }

    return false;
  }

  isTransportCostEditorActive(transport: OptimizationTaskTransport): boolean {
    return this.activeTransportCostEditors.get(transport.id) || false;
  }

  private addTransport(): void {
    this.loaderService.show();
    this.optimizationTaskService
      .addTransport(this.task).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => {}
      )
    ;
  }

  private removeTransport(transport: OptimizationTaskTransport): void {
    this.loaderService.show();
    this.optimizationTaskService
      .removeTransport(this.task, transport).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => {}
      )
    ;
  }

  private showDestinationsDialogToAdd(transport: OptimizationTaskTransport): void {
    this.activeTransport = transport;
    this.destinationsDialogMode = DestinationsDialogMode.Add

    this.destinationsDialog.showDialog(this.freeDestinations, 'Добавить адреса', 'Добавить');
  }

  private showDestinationsDialogToRemove(transport: OptimizationTaskTransport): void {
    this.activeTransport = transport;
    this.destinationsDialogMode = DestinationsDialogMode.Remove

    let destinations = transport.destinations.filter(d => !d.destination.stock).map(d => d.destination);
    this.destinationsDialog.showDialog(destinations, 'Удалить адреса', 'Удалить', false);
  }

  private addDestinationsToTransport(transport: OptimizationTaskTransport, destinations: OptimizationTaskDestination[]): void {
    this.loaderService.show();
    this.optimizationTaskService
      .addDestinationsToTransport(this.task, transport, ...destinations).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => {}
      )
    ;
  }

  private removeDestinationsFromTransport(transport: OptimizationTaskTransport, destinations: OptimizationTaskDestination[]): void {
    this.loaderService.show();
    this.optimizationTaskService
      .removeDestinationsFromTransport(this.task, transport, ...destinations).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => {}
      )
    ;
  }

  private updateTaskDeliverySchema(): void {
    let deliverySchema = this.deliverySchemas.find(s => s.id === parseInt(this.deliverySchemaId));
    if(!deliverySchema)
      return;

    this.loaderService.show();
    this.optimizationTaskService
      .updateTaskDeliverySchema(this.task, deliverySchema).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => {}
      )
    ;
  }

  private sortTransportDestinations(transport: OptimizationTaskTransport): void {
    this.loaderService.show();
    this.optimizationTaskService
      .sortTransportDestinations(this.task, transport, ...transport.destinations.map(d => d.destination)).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => {}
      )
    ;
  }

  private selectTransportTier(transport: OptimizationTaskTransport, tier: TariffTier): void {
    this.loaderService.show();
    this.optimizationTaskService
      .selectTransportTier(this.task, transport, tier).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.transportWithActiveTierSelector = null;
          transport.selected_tier = tier;
          this.loadTask(this.task.id);
        },
        () => {}
      )
    ;
  }

  getSearchStateByTransport(transport: OptimizationTaskTransport): TransportSearchState {
    return this.transportSearchStates.get(transport.id);
  }

  isExecuted(transport: OptimizationTaskTransport): boolean {
    return this.transportSearchStates.has(transport.id) && !!this.transportSearchStates.get(transport.id).draft;
  }

  getTariffTitle(transport: OptimizationTaskTransport): string {
    let tariffsList = transport.recommended_tiers.map(tier => tier.name.toLowerCase()).join(', ');

    return `тариф (рекомендуемые: ${tariffsList})`
  }

  isTransportTariffMatched(transport: OptimizationTaskTransport, tier?: TariffTier): boolean {
    if(!tier)
      return false;

    return transport.recommended_tiers.some(t => t.identifier === tier.identifier);
  }

  private updateTransportArrival(transport: OptimizationTaskTransport): void {
    let [ startDate, endDate] = this.transportArrivals.get(transport.id).compileInterval();
    this.applyTransportArrival(transport, null, startDate, endDate);
  }

  private clearTransportArrival(transport: OptimizationTaskTransport): void {
    this.applyTransportArrival(transport);
  }

  private applyTransportArrival(transport: OptimizationTaskTransport, date?: Date, startDate?:  Date, endDate?: Date): void {
    this.loaderService.show();
    this.optimizationTaskService
      .updateTransportArrivalToStorehouse(this.task, transport, date, startDate, endDate).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => {}
      )
    ;
  }

  private optimizeTransportRoute(transport: OptimizationTaskTransport): void {
    this.loaderService.show();
    this.optimizationTaskService
      .optimizeTransportRoute(this.task, transport)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => this.loadTask(this.task.id),
        error: () => {}
      })
    ;
  }

  getTransportArrivalData(transport: OptimizationTaskTransport): TransportArrivalData {
    return this.transportArrivals.get(transport.id);
  }

  private setTransportArrivalEditing(transport: OptimizationTaskTransport, editing: boolean): void {
    let arrivalData = this.transportArrivals.get(transport.id);
    arrivalData.edit = editing;
    this.transportArrivals.set(transport.id, arrivalData);
  }

  isTransportArrivalEditing(transport: OptimizationTaskTransport): boolean {
    return this.transportArrivals.get(transport.id).edit;
  }

  isTransportArrivalNew(transport: OptimizationTaskTransport): boolean {
    return this.transportArrivals.get(transport.id).isNew;
  }

  onStartTransportArrivalEditing(transport: OptimizationTaskTransport): void {
    if(!this.isExecuted(transport))
      this.setTransportArrivalEditing(transport, true);
  }

  onStopTransportArrivalEditing(transport: OptimizationTaskTransport): void {
    let data = this.transportArrivals.get(transport.id);
    if(data.isNew) {
      this.transportArrivals.delete(transport.id);
      transport.fixed_arrival_to_stock = null;
      transport.arrival_slot_start = null;
      transport.arrival_slot_end = null;
    } else {
      data.edit = false;
    }
  }

  onUpdateTransportArrival(transport: OptimizationTaskTransport): void {
    this.updateTransportArrival(transport);
  }

  onAddTransportArrival(transport: OptimizationTaskTransport): void {
    let curDate = new DateTime();
    let hourOffset = curDate.minute() >= 30 ? 2 : 1;

    transport.arrival_slot_start = new DateTime().minute(0).second(0).add(hourOffset, 'hour').toDate().toISOString();
    transport.arrival_slot_end = new DateTime().minute(0).second(0).add(hourOffset + 1, 'hour').toDate().toISOString();
    let arrivalData = TransportArrivalData.createFromTransport(transport, this.task.city);
    arrivalData.isNew = true;
    arrivalData.edit = true;
    this.transportArrivals.set(transport.id, arrivalData);
  }

  onClearTransportArrival(transport: OptimizationTaskTransport): void {
    if(confirm('Удалить время прибытия на склад?'))
      this.clearTransportArrival(transport);
  }

  onEditTask() {
    this.destinationForEdit = null;
    this.editTask();
  }

  onEditDestination(destinationId: number) {
    this.destinationForEdit = destinationId;
    this.focusMapOnDestination(destinationId);
    if(!this.isStock(destinationId))
      this.editTask();

    this.markers.select(destinationId);
  }

  onClickDestination(destinationId: number) {
    console.log(`Clicked destination: ${destinationId}`);

    if(this.visibleMarkers.getMarker(destinationId).isCluster)
      this.onFocusToCluster(destinationId)
    else
      this.onEditDestination(destinationId);
  }

  onFocusToCluster(destinationId: number) {
    this.focusOnCluster(destinationId);
  }

  onTaskSaved() {
    $('#edit-complex-delivery').modal('hide');
    this.loadTask(this.task.id, true);
  }

  onCalc(strategy: string) {
    this.calc(strategy);
  }

  onExecute() {
    if(confirm('Вы подтверждаете запуск поиска?'))
      this.execute();
  }

  onMouseEnterToTransport(transportNum) {
    this.activeTransportMode = true;
    for(let i = 0; i < this.routes.length; i ++)
      this.routes[i].visible = i == transportNum;

    this.markers.hideAll();

    let i = 0;
    for(let destination of this.task.transports[transportNum].destinations) {
      this.markers.show(destination.destination.id);
      this.markers.getMarker(destination.destination.id).label = (++ i).toString();
    }

    this.refreshVisibleMarkers();
  }

  onMouseEnterTotal() {
    if(!this.activeTransportMode)
      return;

    this.activeTransportMode = false;

    for(let i = 0; i < this.routes.length; i ++)
      this.routes[i].visible = false;

    this.markers.showAll();
    this.initMarkers();
  }

  onTimerTick() {
    let components = [ this.timerMinutes, this.timerTick ? ':' : ' ' ];
    let seconds = this.timerSeconds.toString();
    if(seconds.length < 2)
      seconds = '0' + seconds;

    components.push(seconds);

    this.timerString = components.join('');

    if(this.timerTick) {
      this.timerSeconds--;
      if (this.timerSeconds < 0) {
        this.timerSeconds = 59;
        this.timerMinutes--;

        if (this.timerMinutes < 0)
          this.timerMinutes = this.timerSeconds = 0;
      }
    }
    this.timerTick = !this.timerTick;
  }

  onReloadTime() {
    this.loadTask(this.task.id);
  }

  onReload() {
    this.loadTask(this.task.id);
  }

  onRequestCalc() {
    this.loaderService.show();
    this.optimizationTaskService
      .requestCalculation(this.task).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => this.loadTask(this.task.id),
        () => this.loadTask(this.task.id)
      )
  }

  onSwitchLoadedDataVisibility() {
    this.loadedDataVisible = !this.loadedDataVisible || !this.task.transports || this.task.transports.length == 0;
  }

  onHoverDestination(destinationId: number): void {
    this.visibleMarkers.select(destinationId);
  }

  onChangeOptimizationType(type: string) {
    if(confirm("Сменить тип оптимизации? Это сбросит текущую оптимизацию."))
      this.updateOptimizationType(type);
    else
      this.onReload();
  }

  onUpdateConnectingOfNotOptimizedPoints() {
    if(confirm("Сменить режим объединения? Это сбросит текущую оптимизацию."))
      this.updateConnectingOfNotOptimizedPoints(this.task.connect_not_optimized_points);
    else
      this.onReload();
  }

  onUpdateLockForExternal(): void {
    this.updateLockForExternal(this.task.lock_for_external);
  }

  onActivateTransportCostEditor(transport: OptimizationTaskTransport): void {
    if(transport.used_weak_path) {
      this.activeTransportCostEditors.set(transport.id, true);
      transport.customCost = transport.cost;
    }
  }

  onDeactivateTransportCostEditor(transport: OptimizationTaskTransport): void {
    this.activeTransportCostEditors.set(transport.id, false);
  }

  onApplyTransportCost(transport: OptimizationTaskTransport): void {
    this.activeTransportCostEditors.set(transport.id, false);
    transport.cost = transport.customCost;

    this.updateTransportCost(transport);
  }

  onSelectSelfDriver(transport: OptimizationTaskTransport): void {
    this.activeTransport = transport;
    this.freighterListDialog.showDialog([], transport.employer ? [ transport.employer ] : []);
  }

  onCancelSelfDriver(transport: OptimizationTaskTransport): void {
    if(confirm('Отменить назначение водителя?')) {
      transport.employer = null;
      this.updateTransportEmployer(transport);
    }
  }

  onChangeEmployer(employers: Employer[]): void {
    if(employers.length > 0)
      this.activeTransport.employer = employers[0];
    else
      this.activeTransport.employer = null;

    this.updateTransportEmployer(this.activeTransport);
  }

  onChangeTransportSelection(transport: OptimizationTaskTransport, value: boolean): void {
    this.transportsSelection.set(transport.id, value);
    this.syncExecutionControls();
  }

  onUpdateTransportsFilter(): void {
    this.syncExecutionControls();
  }

  onChangeDestinationsPage(page: Page): void {
    let pageOffset = page.num * DESTINATIONS_PAGE_SIZE;
    this.destinationsList = new DestinationsList(
      this.task.destinations.slice(pageOffset, pageOffset + DESTINATIONS_PAGE_SIZE),
      page.num,
      this.task.destinations.length,
      DESTINATIONS_PAGE_SIZE
    );
  }

  onAddTransport(): void {
    this.addTransport();
  }

  onRemoveTransport(transport: OptimizationTaskTransport): void {
    if(confirm('Подтверждаете удаление рейса?'))
      this.removeTransport(transport);
  }

  onAddDestinationForTransport(transport: OptimizationTaskTransport): void {
    this.showDestinationsDialogToAdd(transport);
  }

  onRemoveTransportDestinations(transport: OptimizationTaskTransport): void {
    this.showDestinationsDialogToRemove(transport);
  }

  onSelectDestinations(destinations: OptimizationTaskDestination[]): void {
    switch(this.destinationsDialogMode) {
      case DestinationsDialogMode.Add:
        this.addDestinationsToTransport(this.activeTransport, destinations);
        break;
      case DestinationsDialogMode.Remove:
        this.removeDestinationsFromTransport(this.activeTransport, destinations);
        break;
    }
  }

  sortPredicate(index: number, item: CdkDrag<OptimizationTaskTransportDestination>, drop: CdkDropList): boolean {
    return index > 0;
  }

  onSortCompleted(transport: OptimizationTaskTransport, event: CdkDragDrop<OptimizationTaskTransportDestination[], any>): void {
    console.log('sorted');

    moveItemInArray(transport.destinations, event.previousIndex, event.currentIndex);

    this.sortTransportDestinations(transport);
  }

  onSelectDeliverySchema(): void {
    this.updateTaskDeliverySchema();
  }

  onActivateTierSelector(transport: OptimizationTaskTransport, event: Event): void {
    if(this.isExecuted(transport))
      return;

    this.transportWithActiveTierSelector = transport.id;
    this.selectedTierId = (transport['selected_tier'] && transport.selected_tier.id) + '';

    event.preventDefault();
  }

  onSelectedTransportTier(transport: OptimizationTaskTransport): void {
    let tier = this.tiers.find(t => (t.id + '') == (this.selectedTierId + ''));
    if(tier)
      this.selectTransportTier(transport, tier);
  }

  onBoundsChange(bounds: google.maps.LatLngBounds) {
    if(this.markers.markers.size > MIN_DESTINATIONS_TO_CLUSTERIZATION) {
      this.mapBoundsChangeStream.next(bounds);
    }
  }

  onToggleDraftCalculation(transport: OptimizationTaskTransport): void {
    this.transportDraftCalculations.get(transport.id).isOpened = !this.transportDraftCalculations.get(transport.id).isOpened;
  }

  onClickOzonImportButton(transport: OptimizationTaskTransport) {
    this.ozonImportDialog.showForTransport(transport);
  }

  onOptimizeTransportRoute(transport: OptimizationTaskTransport): void {
    if(confirm('Выполнить оптимизацию рейса?'))
      this.optimizeTransportRoute(transport);
  }

  onOzonParcelImported(): void {
    this.onReload();
  }

  ngOnDestroy(): void {
    this.stopAutoReload();
    this.stopSearchStateAutoReload();
    this.mapService.clean();
    this.mapService.hideMap();
    this.mapBoundsChangeSubscription.unsubscribe();
    this.markerClicksSubscription.unsubscribe();
  }
}
