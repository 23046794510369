<form (submit)="onSave()">
  <!--<div>Время прибытия на склад</div>-->
  <!--<order-panel-datepicker-->
    <!--class="date-time"-->
    <!--[(ngModel)]="deliveryStorehouseArrival"-->
    <!--[datepicker]="datePickerOptions"-->
    <!--[timepicker]="timePickerOptions"-->
    <!--name="deliveryStorehouseArrival"-->
    <!--ngDefaultControl-->
  <!--&gt;</order-panel-datepicker>-->

  <div class="control">
    <order-panel-datepicker
            [(value)]="start"
            [datepicker]="startDatePickerOptions"
            [timepicker]="false"
            name="start"
            required
            ngDefaultControl
    ></order-panel-datepicker>
  </div>

  <div class="control">
    <order-panel-datepicker
            [(value)]="storehouseTime"
            [datepicker]="false"
            [timepicker]="storeHouseTimePickerOptions"
            name="storeHouseTime"
            required
            ngDefaultControl
    ></order-panel-datepicker>
  </div>

  <div class="control">
    Начинать поиск за
    <select [(ngModel)]="searchHoursBefore" name="searchHoursBefore" class="number">
      <option value="{{ h }}" *ngFor="let h of searchHours">{{ h }}</option>
    </select> ч до прибытия на склад
  </div>

<!--  <div class="control">-->
<!--    <div class="form-group">-->
<!--      <select [(ngModel)]="type" name="type">-->
<!--        <option value="once">Однократно</option>-->
<!--        <option value="everyday">Ежедневно</option>-->
<!--        <option value="every_week">Еженедельно</option>-->
<!--        <option value="every_month">Ежемесячно</option>-->
<!--      </select>-->
<!--    </div>-->
<!--  </div>-->

  <div *ngIf="type == 'everyday'">
    <div class="control">
      <label><input type="radio" name="everydayMode" [(ngModel)]="everyday.mode" value="weekdays"> По будням</label>
    </div>
    <div class="control">
      <label><input type="radio" name="everydayMode" [(ngModel)]="everyday.mode" value="every_day"> Каждые</label>
      <select [(ngModel)]="everyday.period" name="period" class="number">
        <option value="{{ p }}" *ngFor="let p of everyday.periods">{{ p }}</option>
      </select> день
    </div>
  </div>

  <div *ngIf="type == 'every_week'">
    <div class="control">
      Каждые
      <select [(ngModel)]="everyWeek.period" name="weekPeriod" class="number">
        <option value="{{ p }}" *ngFor="let p of everyWeek.periods">{{ p }}</option>
      </select> нед.
    </div>
    <div class="control">
      <div class="pretty success smooth" *ngFor="let day of everyWeek.weekDays; let i=index;">
          <input type="checkbox" name="{{ 'day' + i }}" [(ngModel)]="day.selected">
          <label>
            <i class="g-mdi" data-icon="done"></i> {{ day.name }}
          </label>
      </div>
    </div>
  </div>

  <div *ngIf="type == 'every_month'" class="every-month">
    <div class="control">
      <div class="control-label">Каждые</div>
      <div class="control-value">
        <select [(ngModel)]="everyMonth.period" name="monthPeriod" class="number">
          <option value="{{ p }}" *ngFor="let p of everyMonth.periods">{{ p }}</option>
        </select> мес.
      </div>
    </div>
    <div class="control">
      <div class="control-label">
        <label><input type="radio" name="monthMode" [(ngModel)]="everyMonth.mode" value="by_date"> По</label>
      </div>
      <div class="control-value">
        <select [(ngModel)]="everyMonth.day" name="period" class="number">
          <option value="{{ p }}" *ngFor="let p of everyMonth.days">{{ p }}</option>
        </select> числам
      </div>
    </div>
    <div class="control">
      <div class="control-label">
        <label><input type="radio" name="monthMode" [(ngModel)]="everyMonth.mode" value="by_week_day"> В</label>
      </div>
      <div class="control-value">
        <select [(ngModel)]="everyMonth.weekPeriod" name="weekPeriod" class="number">
          <option value="{{ p }}" *ngFor="let p of everyMonth.weekPeriods">{{ p }}</option>
        </select>
        <select [(ngModel)]="everyMonth.weekDay" name="weekDay" class="week-day">
          <option value="1">Понедельник</option>
          <option value="2">Вторник</option>
          <option value="3">Среда</option>
          <option value="4">Четверг</option>
          <option value="5">Пятница</option>
          <option value="6">Суббота</option>
          <option value="0">Воскресенье</option>
        </select>
      </div>
    </div>
  </div>

  <div class="control" *ngIf="type != 'once'">
    <order-panel-datepicker
            [(value)]="end"
            [datepicker]="endDatePickerOptions"
            [timepicker]="false"
            name="end"
            ngDefaultControl
    ></order-panel-datepicker>
  </div>

  <button type="submit" class="save-btn">Сохранить</button>
</form>
