import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {List} from "../pager/list";
import {Page} from "../pager/page";

@Component({
  selector: 'show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.css']
})
export class ShowMoreComponent implements OnInit, OnChanges {
  @Input() list: List;
  @Input() reset: Boolean;

  @Output() onPage = new EventEmitter<Page>();
  lastPage: number;
  currentPage: number = 0;
  hasNext: boolean;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.hasNext = false;
    this.currentPage = this.list.page;
    if (this.list.totalCount <= this.list.pageSize)
      return;
    this.hasNext = (this.list.page + 1) * this.list.pageSize < this.list.totalCount;
    this.lastPage = Math.ceil(this.list.totalCount / this.list.pageSize) - 1;
  }

  onClickPage() {
    this.currentPage++;
    if (this.currentPage <= this.lastPage) {
      this.onPage.emit(new Page(this.currentPage));
    }
    else {
      return false;
    }
  }

  resetCurrPage() {
    this.currentPage = 0;
    let container = document.getElementById('list-scrollbar');
    container.scrollTop = 0;
  }

}
