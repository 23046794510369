import {Component, Input, OnInit} from '@angular/core';
import {TrackingService} from "../_models/tracking-service";
import {DeliveryTrack} from "../_models/delivery-track";
import {TrackingServiceService} from "../_services/tracking-service.service";
import {ClipboardService} from "../_services/clipboard.service";

@Component({
  selector: 'delivery-track',
  templateUrl: './delivery-track.component.html',
  styleUrls: ['./delivery-track.component.css']
})
export class DeliveryTrackComponent implements OnInit {
  @Input() track: DeliveryTrack;
  @Input() small = false;

  private trackingServicesMap = new Map<string, TrackingService>();

  public trackingServices: TrackingService[] = [];

  constructor(private trackingServiceService: TrackingServiceService, private clipboardService: ClipboardService) { }

  ngOnInit() {
    this.initTrackingServices();
  }

  private initTrackingServices(): void {
    this.trackingServicesMap.clear();

    this.trackingServiceService
      .getAvailableTrackingServices()
      .subscribe(
        s => {
          this.trackingServices = s;
          for(let service of s)
            this.trackingServicesMap.set(service.identifier, service);
        },
        () => {}
      )
    ;
  }

  trackingServiceIdentifierToName(trackingServiceIdentifier: string): string {
    return this.trackingServicesMap.has(trackingServiceIdentifier)
      ? this.trackingServicesMap.get(trackingServiceIdentifier).name
      : trackingServiceIdentifier;
  }

  onClickTrackNumber(trackNumber: string): void {
    this.clipboardService.saveToClipboard(trackNumber, `Трек-номер "${trackNumber}" скопирован в буфер обмена`);
  }
}
