<form (submit)="onSave()">
  <div class="form-group">
    <label for="name">Название тарифа</label>
    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="tariff.name" required>
  </div>
  <div class="form-group">
    <label for="min_price">Минимальная стоимость</label>
    <input type="text" class="form-control" id="min_price" name="min_price" [(ngModel)]="tariff.min_price" required>
  </div>
  <div class="form-group">
    <label for="min_hours">Минимальное количество часов</label>
    <input type="text" class="form-control" id="min_hours" name="min_hours" [(ngModel)]="tariff.min_hours" required>
  </div>
  <div class="form-group">
    <label for="additional_hour_price">Стоимость дополнительного часа</label>
    <input type="text" class="form-control" id="additional_hour_price" name="additional_hour_price" [(ngModel)]="tariff.additional_hour_price" required>
  </div>
  <div class="form-group">
    <label for="on_the_way_hour_price">Стоимость часа в пути</label>
    <input type="text" class="form-control" id="on_the_way_hour_price" name="on_the_way_hour_price" [(ngModel)]="tariff.on_the_way_hour_price" required>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading">Надбавка за сборку/разборку</div>
    <div class="panel-body">
      <div class="form-group">
        <label for="assembler_min_price">Минимальная стоимость</label>
        <input type="text" class="form-control" id="assembler_min_price" name="assembler_min_price" [(ngModel)]="tariff.assembler_min_price" required>
      </div>
      <div class="form-group">
        <label for="assembler_min_hours">Минимальное количество часов</label>
        <input type="text" class="form-control" id="assembler_min_hours" name="assembler_min_hours" [(ngModel)]="tariff.assembler_min_hours" required>
      </div>
      <div class="form-group">
        <label for="assembler_additional_hour_price">Стоимость дополнительного часа</label>
        <input type="text" class="form-control" id="assembler_additional_hour_price" name="assembler_additional_hour_price" [(ngModel)]="tariff.assembler_additional_hour_price" required>
      </div>
    </div>
  </div>


  <div class="panel panel-default">
    <div class="panel-heading">Надбавка за подъём/спуск</div>
    <div class="panel-body">
      <div class="form-group">
        <label for="lifting_min_price">Минимальная стоимость</label>
        <input type="text" class="form-control" id="lifting_min_price" name="lifting_min_price" [(ngModel)]="tariff.lifting_min_price" required>
      </div>
      <div class="form-group">
        <label for="lifting_min_hours">Минимальное количество часов</label>
        <input type="text" class="form-control" id="lifting_min_hours" name="lifting_min_hours" [(ngModel)]="tariff.lifting_min_hours" required>
      </div>
      <div class="form-group">
        <label for="lifting_additional_hour_price">Стоимость дополнительного часа</label>
        <input type="text" class="form-control" id="lifting_additional_hour_price" name="lifting_additional_hour_price" [(ngModel)]="tariff.lifting_additional_hour_price" required>
      </div>
    </div>
  </div>

  <button type="submit" class="btn btn-primary">Сохранить</button>
  <a class="btn btn-link" routerLink="/tariffs/loaders">Отменить</a>
</form>
