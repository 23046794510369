<form (submit)="onSave()">
<table class="table tbl-order">
  <thead>
  <tr>
    <td colspan="2">Имя</td>
    <td colspan="2">Телефон</td>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td colspan="2">
      <div class="input-box input-name" [class.has-error]="!nameIsValid">
        <input [(ngModel)]="name" name="name" placeholder="Имя" (ngModelChange)="onChangeName($event)">
      </div>
      <div class="input-box input-name">
        <input [(ngModel)]="surname" name="surname" placeholder="Фамилия">
      </div>
      <div class="input-box input-name">
        <input [(ngModel)]="patronymic" name="patronymic" placeholder="Отчество">
      </div>
    </td>
    <td colspan="2">
      <div class="input-box" [class.has-error]="!phoneIsValid">
        <input [(ngModel)]="phone" name="phone" placeholder="70000000000" (ngModelChange)="onChangePhone($event)">
      </div>
    </td>
  </tr>
  </tbody>
</table>
<hr>
<button class="save-btn" type="submit" [disabled]="!isValid" [class.disabled-btn]="!isValid">Сохранить</button>
</form>
