<form (submit)="onSave()">
  <select class="form-control" name="client" [(ngModel)]="editableClient.id" (ngModelChange)="onSelectClient()">
    <option value="0" *ngIf="enabledNoSelection">- новый клиент -</option>
    <option *ngFor="let client of clients" [value]="client.id">
      {{ client.name }}
      <ng-template ngIf [ngIf]="client.contact_phone">{{ client.contact_phone|formatPhone }}</ng-template>
    </option>
  </select>
  <div *ngIf="editableClient.id == 0" class="new-client-form">
    <div class="form-group">
      <div><label>Наименование клиента</label></div>
      <div><input class="text-input" type="text" name="name" [(ngModel)]="editableClient.name"></div>
    </div>
    <div class="form-group">
      <div class="flex-centered flex-space-between">
        <label class="contact-name">Контактное лицо
          <input class="text-input" type="text" name="contact_name" [(ngModel)]="editableClient.contact_name"></label>
        <label>Контактный телефон
          <input class="text-input" type="text" name="contact_phone" [(ngModel)]="editableClient.contact_phone"></label>
      </div>
    </div>
  </div>
  <div class="flex-row flex-start controls">
    <button class="save-btn" type="submit">Сохранить</button>
  </div>
</form>
